import React from 'react';
import styled from 'styled-components';
import { IconProps } from './types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const SearchIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 15.75L13.2375 12.4875M15 8.25C15 11.5637 12.3137 14.25 9 14.25C5.68629 14.25 3 11.5637 3 8.25C3 4.93629 5.68629 2.25 9 2.25C12.3137 2.25 15 4.93629 15 8.25Z"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SVG>
);
