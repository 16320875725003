import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyPartners = lazy(() =>
  import(/* webpackChunkName: 'Partners' */ './index'),
);

export default function Partners() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyPartners />
    </Suspense>
  );
}
