/*
 * SearchPage Messages
 *
 * This contains all the text for the Search Page container.
 */

import { defineMessages } from 'react-intl';
import {
  BEST_TIME_TO_BOOK_QUOTES,
  BUS,
  BUS_PLURAL,
  CARPOOLING,
  CARPOOLING_PLURAL,
  COMPANY_VERB_1,
  COMPANY_VERB_2,
  COMPANY_VERB_3,
  COMPANY_VERB_4,
  COMPANY_VERB_PLURAL_1,
  COMPANY_VERB_PLURAL_2,
  COMPANY_VERB_PLURAL_3,
  COMPANY_VERB_PLURAL_4,
  DESTINATIONS,
  EMOJI_BUS,
  EMOJI_CARPOOLING,
  EMOJI_MULTIMODAL,
  EMOJI_TRAIN,
  FAQ,
  FORMATTED_TRIPS_1,
  FORMATTED_TRIPS_2,
  FORMATTED_TRIPS_3,
  FORMATTED_TRIPS_4,
  FORMATTED_TRIPS_5,
  FORMATTED_TRIPS_6,
  GENERAL_TEXT_SENTENCE_1_1,
  GENERAL_TEXT_SENTENCE_1_2,
  GENERAL_TEXT_SENTENCE_1_3,
  GENERAL_TEXT_SENTENCES_2_1,
  GENERAL_TEXT_SENTENCES_2_1_DIRECT,
  GENERAL_TEXT_SENTENCES_2_2,
  GENERAL_TEXT_SENTENCES_2_2_DIRECT,
  GENERAL_TEXT_SENTENCES_2_3,
  GENERAL_TEXT_SENTENCES_2_3_DIRECT,
  GENERAL_TEXT_SENTENCES_3_1,
  GENERAL_TEXT_SENTENCES_3_2,
  GENERAL_TEXT_SENTENCES_3_3,
  MAIN_TABLE_HEADER_DISTANCE,
  MAIN_TABLE_HEADER_DURATION,
  MAIN_TABLE_HEADER_PRICE_RANGE,
  MAIN_TABLE_HEADER_TRIP_TYPE,
  MULTIMODAL,
  MULTIMODAL_PLURAL,
  SHORT_FAQ_DAILY_TRIPS_1,
  SHORT_FAQ_DAILY_TRIPS_2,
  SHORT_FAQ_DAILY_TRIPS_3,
  SHORT_FAQ_DEPARTURE_1,
  SHORT_FAQ_DEPARTURE_2,
  SHORT_FAQ_DEPARTURE_3,
  SHORT_FAQ_DURATION_1,
  SHORT_FAQ_DURATION_2,
  SHORT_FAQ_DURATION_3,
  SHORT_FAQ_PRICE_1,
  SHORT_FAQ_PRICE_2,
  SHORT_FAQ_PRICE_3,
  SPECIFIC_COMPANIES_TEXT_1,
  SPECIFIC_COMPANIES_TEXT_2,
  SPECIFIC_COMPANIES_TEXT_3,
  SPECIFIC_COMPANIES_TEXT_4,
  SPECIFIC_COMPANIES_TEXT_5,
  SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS,
  SPECIFIC_COMPANIES_TEXT_HEADERS,
  SPECIFIC_TABLE_HEADER_ALTERNATIVE,
  SPECIFIC_TABLE_HEADER_ARRIVAL,
  SPECIFIC_TABLE_HEADER_COMPANIES,
  SPECIFIC_TABLE_HEADER_DAILY_TRIPS,
  SPECIFIC_TABLE_HEADER_DEPARTURE,
  SPECIFIC_TABLE_HEADER_DURATION,
  SPECIFIC_TABLE_HEADER_FIRST_TRIP,
  SPECIFIC_TABLE_HEADER_ITINERARY,
  SPECIFIC_TABLE_HEADER_LAST_TRIP,
  SPECIFIC_TABLE_HEADER_PRICE_RANGE,
  SPECIFIC_TEXT_1,
  SPECIFIC_TEXT_1_1,
  SPECIFIC_TEXT_1_2,
  SPECIFIC_TEXT_2,
  SPECIFIC_TEXT_2_1,
  SPECIFIC_TEXT_2_2,
  SPECIFIC_TEXT_3,
  SPECIFIC_TEXT_3_1,
  SPECIFIC_TEXT_3_2,
  SPECIFIC_TEXT_4,
  SPECIFIC_TEXT_4_1,
  SPECIFIC_TEXT_4_2,
  SPECIFIC_TEXT_4_3,
  SPECIFIC_TEXT_5,
  SPECIFIC_TEXT_5_1,
  SPECIFIC_TEXT_5_2,
  SPECIFIC_TEXT_5_3,
  SPECIFIC_TEXT_DAILY_TRIPS_1,
  SPECIFIC_TEXT_DAILY_TRIPS_2,
  SPECIFIC_TEXT_DAILY_TRIPS_3,
  SPECIFIC_TEXT_HEADER_1,
  SPECIFIC_TEXT_HEADER_2,
  SPECIFIC_TEXT_HEADER_3,
  SPECIFIC_TEXT_NO_DATA_INTRO_1,
  SPECIFIC_TEXT_NO_DATA_INTRO_2,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_1,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_2,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_3,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_1,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_2,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_1,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_2,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_3,
  TABLES,
  TRAIN,
  TRAIN_PLURAL,
  TRIP_CO2_QUOTES,
} from '../constants';

export const searchScope = 'app.containers.Search';

export const searchMessages = {
  genericTransportType: {
    id: `${searchScope}.genericTransportType`,
    defaultMessage: 'train, bus et covoiturage',
    en: 'train, bus and carpooling',
    es: 'tren, autobús y coche compartido',
    it: 'treno, autobus e carpooling',
    de: 'Zug, Bus und Mitfahrgelegenheit',
    nl: 'trein, bus en carpooling',
  },
  header: {
    id: `${searchScope}.header`,
    defaultMessage: 'Search Page',
    en: 'Search Page',
    es: 'Página de búsqueda',
    it: 'Pagina di ricerca',
    de: 'Suchseite',
    nl: 'Zoekpagina',
  },
  stickyNavButton: {
    id: `${searchScope}.stickyNavButton`,
    defaultMessage: 'Réserver mon trajet',
    en: 'Book a trip',
    es: 'Reservar mi viaje',
    it: 'Prenota un viaggio',
    de: 'Reise buchen',
    nl: 'Boek mijn reis',
  },
  [DESTINATIONS]: {
    id: `${searchScope}.${DESTINATIONS}`,
    defaultMessage: 'À propos',
    en: 'About',
    es: 'Acerca de',
    it: 'Informazioni',
    de: 'Über',
    nl: 'Over',
  },
  [TABLES]: {
    id: `${searchScope}.${TABLES}`,
    defaultMessage: "L'itinéraire",
    en: 'Itinerary',
    es: 'Itinerario',
    it: 'Itinerario',
    de: 'Reiseroute',
    nl: 'Reisroute',
  },
  returnTrip: {
    id: `${searchScope}.returnTrip`,
    defaultMessage:
      'Réservez votre retour {destination} - {origin} en cliquant ici !',
    en: 'Book your return trip {destination} - {origin} here !',
    es: '¡Reserve su viaje de regreso {destination} - {origin} aquí!',
    it: 'Prenota il tuo viaggio di ritorno {destination} - {origin} qui!',
    de: 'Buchen Sie Ihre Rückreise {destination} - {origin} hier!',
    nl: 'Boek hier uw terugreis {destination} - {origin}!',
  },
  returnTripSpecific: {
    id: `${searchScope}.returnTripSpecific`,
    defaultMessage:
      'Réservez votre retour en {transportType} {destination} - {origin} en cliquant ici !',
    en: 'Book your return trip by {transportType} {destination} - {origin} here !',
    es: '¡Reserve su viaje de regreso en {transportType} {destination} - {origin} aquí!',
    it: 'Prenota il tuo viaggio di ritorno in {transportType} {destination} - {origin} qui!',
    de: 'Buchen Sie Ihre Rückreise mit dem {transportType} {destination} - {origin} hier!',
    nl: 'Boek hier uw terugreis per {transportType} {destination} - {origin}!',
  },
  searchHeaderTitle: {
    id: `${searchScope}.searchHeaderTitle`,
    defaultMessage: 'De {origin} à {destination} en {transportType} {extra}',
    en: 'From {origin} to {destination} by {transportType} {extra}',
    es: 'Desde {origin} hasta {destination} en {transportType} {extra}',
    it: 'Da {origin} a {destination} in {transportType} {extra}',
    de: 'Von {origin} nach {destination} mit {transportType} {extra}',
    nl: 'Van {origin} naar {destination} met {transportType} {extra}',
  },
  searchHeaderTitleSpecificExtra: {
    id: `${searchScope}.searchHeaderTitleSpecificExtra`,
    defaultMessage: 'dès {minPrice} en moins de {minDuration}',
    en: 'from {minPrice} in less than {minDuration}',
    it: 'a partire da {minPrice} in meno di {minDuration}',
    es: 'desde {minPrice} en menos de {minDuration}',
    de: 'ab {minPrice} in weniger als {minDuration}',
    nl: 'vanaf {minPrice} in minder dan {minDuration}',
  },
  searchHeaderSubTitle: {
    id: `${searchScope}.searchHeaderSubTitle`,
    defaultMessage: 'Comparez et réservez parmi {availableCompanies}',
    en: 'Compare and book among {availableCompanies}',
    it: 'Confronta e prenota tra {availableCompanies}',
    es: 'Compara y reserva entre {availableCompanies}',
    de: 'Vergleichen und buchen Sie unter {availableCompanies}',
    nl: 'Vergelijk en boek tussen {availableCompanies}',
  },
  searchHeaderSpecificSubTitle: {
    id: `${searchScope}.searchHeaderSpecificSubTitle`,
    defaultMessage:
      'Trouvez votre billet de {transportType} parmi {availableCompanies}',
    en: 'Find your {transportType} ticket among {availableCompanies}',
    it: 'Trova il tuo biglietto {transportType} tra {availableCompanies}',
    es: 'Encuentra tu billete {transportType} entre {availableCompanies}',
    de: 'Finden Sie Ihr {transportType}-Ticket unter {availableCompanies}',
    nl: 'Vind uw {transportType}-ticket tussen {availableCompanies}',
  },
  destinationCardsHeader: {
    id: `${searchScope}.destinationCardsHeader`,
    defaultMessage: 'À propos de votre trajet',
    en: 'About your trip',
    es: 'Acerca de tu viaje',
    it: 'Informazioni sul tuo viaggio',
    de: 'Über Ihre Reise',
    nl: 'Over uw reis',
  },
  faqsHeader: {
    id: `${searchScope}.faqsHeader`,
    defaultMessage:
      'Questions/Réponses pour votre voyage {origin} - {destination}',
    en: 'Frequently Asked Questions about {origin} - {destination}',
    es: 'Preguntas frecuentes sobre tu viaje {origin} - {destination}',
    it: 'Domande frequenti sul tuo viaggio {origin} - {destination}',
    de: 'Häufig gestellte Fragen zu Ihrer Reise von {origin} nach {destination}',
    nl: 'Veelgestelde vragen over uw reis van {origin} naar {destination}',
  },
  [FAQ]: {
    id: `${searchScope}.${FAQ}`,
    defaultMessage: 'FAQ du trajet',
    en: 'Trip FAQ',
    es: 'Preguntas frecuentes sobre el viaje',
    it: 'FAQ sul viaggio',
    de: 'Häufig gestellte Fragen zur Reise',
    nl: 'Veelgestelde vragen over de reis',
  },
  fromLinksHeader: {
    id: `${searchScope}.fromLinksHeader`,
    defaultMessage: 'Trajets pas chers depuis {city}',
    en: 'Cheap trips from {city}',
    es: 'Viajes baratos desde {city}',
    it: 'Viaggi economici da {city}',
    de: 'Günstige Reisen ab {city}',
    nl: 'Goedkope reizen vanuit {city}',
  },
  toLinksHeader: {
    id: `${searchScope}.toLinksHeader`,
    defaultMessage: 'Départs pas chers vers {city}',
    en: 'Cheap trips to {city}',
    es: 'Viajes baratos a {city}',
    it: 'Viaggi economici a {city}',
    de: 'Günstige Reisen nach {city}',
    nl: 'Goedkope reizen naar {city}',
  },
  [MAIN_TABLE_HEADER_DISTANCE]: {
    id: `${searchScope}.${MAIN_TABLE_HEADER_DISTANCE}`,
    defaultMessage: 'Distance',
    en: 'Distance',
    es: 'Distancia',
    it: 'Distanza',
    de: 'Entfernung',
    nl: 'Afstand',
  },
  [MAIN_TABLE_HEADER_DURATION]: {
    id: `${searchScope}.${MAIN_TABLE_HEADER_DURATION}`,
    defaultMessage: 'Durée du trajet',
    en: 'Trip duration',
    es: 'Duración del viaje',
    it: 'Durata del viaggio',
    de: 'Fahrtzeit',
    nl: 'Reistijd',
  },
  mainTableHeaderDurationSpecific: {
    id: `${searchScope}.mainTableHeaderDurationSpecific`,
    defaultMessage: 'Durée du trajet en {transportType}',
    en: 'Trip duration in {transportType}',
    es: 'Duración del viaje en {transportType}',
    it: 'Durata del viaggio in {transportType}',
    de: 'Fahrtzeit im {transportType}',
    nl: 'Reistijd in {transportType}',
  },
  [MAIN_TABLE_HEADER_PRICE_RANGE]: {
    id: `${searchScope}.${MAIN_TABLE_HEADER_PRICE_RANGE}`,
    defaultMessage: 'Gamme de prix',
    en: 'Prices',
    es: 'Precios',
    it: 'Prezzi',
    de: 'Preisspanne',
    nl: 'Prijzen',
  },
  mainTableHeaderPriceRangeSpecific: {
    id: `${searchScope}.mainTableHeaderPriceRangeSpecific`,
    defaultMessage: 'Gamme de prix · {transportType}',
    en: 'Prices · {transportType}',
    es: 'Precios · {transportType}',
    it: 'Prezzi · {transportType}',
    de: 'Preisspanne · {transportType}',
    nl: 'Prijzen · {transportType}',
  },
  mainTablePriceRange: {
    id: `${searchScope}.mainTablePriceRange`,
    defaultMessage: 'De <strong>{min}€</strong> à <strong>{max}€</strong>',
    en: 'From <strong>{min}€</strong> to <strong>{max}€</strong>',
    es: 'Desde <strong>{min}€</strong> hasta <strong>{max}€</strong>',
    it: 'Da <strong>{min}€</strong> a <strong>{max}€</strong>',
    de: 'Von <strong>{min}€</strong> bis <strong>{max}€</strong>',
    nl: 'Van <strong>{min}€</strong> tot <strong>{max}€</strong>',
  },
  [MAIN_TABLE_HEADER_TRIP_TYPE]: {
    id: `${searchScope}.${MAIN_TABLE_HEADER_TRIP_TYPE}`,
    defaultMessage: 'Type de trajet',
    en: 'Trip type',
    es: 'Tipo de viaje',
    it: 'Tipo di viaggio',
    de: 'Reiseart',
    nl: 'Reis type',
  },
  mainTableDirect: {
    id: `${searchScope}.mainTableDirect`,
    defaultMessage: 'Direct',
    en: 'Direct',
    es: 'Directo',
    it: 'Diretto',
    de: 'Direkt',
    nl: 'Direct',
  },
  mainTableStop: {
    id: `${searchScope}.mainTableStop`,
    defaultMessage: '{stopsNb} escale',
    en: '{stopsNb} stop',
    es: '{stopsNb} parada',
    it: '{stopsNb} fermata',
    de: '{stopsNb} Stopp',
    nl: '{stopsNb} stop',
  },
  mainTableStops: {
    id: `${searchScope}.mainTableStops`,
    defaultMessage: '{stopsNb} escales',
    en: '{stopsNb} stops',
    es: '{stopsNb} paradas',
    it: '{stopsNb} fermate',
    de: '{stopsNb} Stopps',
    nl: '{stopsNb} stops',
  },
  detailedItineraryCaption: {
    id: `${searchScope}.detailedItineraryCaption`,
    defaultMessage: 'Itinéraire détaillé',
    en: 'Detailed itinerary',
    es: 'Itinerario detallado',
    it: 'Itinerario dettagliato',
    de: 'Detaillierte Reiseroute',
    nl: 'Gedetailleerde reisroute',
  },
  specificTableStopUnknownStations: {
    id: `${searchScope}.specificTableStopUnknownStations`,
    defaultMessage: '{stopsNb} escale',
    en: '{stopsNb} stop',
    es: '{stopsNb} parada',
    it: '{stopsNb} fermata',
    de: '{stopsNb} Stopp',
    nl: '{stopsNb} stop',
  },
  specificTableStop: {
    id: `${searchScope}.specificTableStop`,
    defaultMessage: '{stopsNb} escale à {stations}',
    en: '{stopsNb} stop at {stations}',
    es: '{stopsNb} parada en {stations}',
    it: '{stopsNb} fermata a {stations}',
    de: '{stopsNb} Stopp bei {stations}',
    nl: '{stopsNb} stop bij {stations}',
  },
  specificTableStops: {
    id: `${searchScope}.specificTableStops`,
    defaultMessage: '{stopsNb} escales à {stations}',
    en: '{stopsNb} stops at {stations}',
    es: '{stopsNb} paradas en {stations}',
    it: '{stopsNb} fermate a {stations}',
    de: '{stopsNb} Stopps bei {stations}',
    nl: '{stopsNb} stops bij {stations}',
  },
  [SPECIFIC_TABLE_HEADER_ALTERNATIVE]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_ALTERNATIVE}`,
    defaultMessage: 'Alternative',
    en: 'Alternative',
    it: 'Alternativa',
    es: 'Alternativa',
    de: 'Alternative',
    nl: 'Alternatief',
  },
  [SPECIFIC_TABLE_HEADER_PRICE_RANGE]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_PRICE_RANGE}`,
    defaultMessage: 'Gamme de prix',
    en: 'Price range',
    es: 'Rango de precios',
    it: 'Intervallo di prezzo',
    de: 'Preisspanne',
    nl: 'Prijzen',
  },
  [SPECIFIC_TABLE_HEADER_DURATION]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_DURATION}`,
    defaultMessage: 'Durée',
    en: 'Duration',
    es: 'Duración',
    it: 'Durata',
    de: 'Dauer',
    nl: 'Duur',
  },
  [SPECIFIC_TABLE_HEADER_ITINERARY]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_ITINERARY}`,
    defaultMessage: 'Itinéraire',
    en: 'Itinerary',
    es: 'Itinerario',
    it: 'Itinerario',
    de: 'Reiseroute',
    nl: 'Reisroute',
  },
  [SPECIFIC_TABLE_HEADER_DEPARTURE]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_DEPARTURE}`,
    defaultMessage: 'Gare de départ',
    en: 'Departure station',
    es: 'Estación de salida',
    it: 'Stazione di partenza',
    de: 'Abfahrtsbahnhof',
    nl: 'Vertrekstation',
  },
  [SPECIFIC_TABLE_HEADER_ARRIVAL]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_ARRIVAL}`,
    defaultMessage: "Gare d'arrivée",
    en: 'Arrival station',
    es: 'Estación de llegada',
    it: 'Stazione di arrivo',
    de: 'Ankunftsstation',
    nl: 'Aankomststation',
  },
  [SPECIFIC_TABLE_HEADER_COMPANIES]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_COMPANIES}`,
    defaultMessage: 'Transporteurs',
    en: 'Companies',
    es: 'Compañías',
    it: 'Compagnie',
    de: 'Unternehmen',
    nl: 'Bedrijven',
  },
  [SPECIFIC_TABLE_HEADER_FIRST_TRIP]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_FIRST_TRIP}`,
    defaultMessage: 'Premier départ',
    en: 'First trip',
    es: 'Primer viaje',
    it: 'Prima partenza',
    de: 'Erste Abfahrt',
    nl: 'Eerste vertrek',
  },
  [SPECIFIC_TABLE_HEADER_LAST_TRIP]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_LAST_TRIP}`,
    defaultMessage: 'Dernier départ',
    en: 'Last trip',
    es: 'Último viaje',
    it: 'Ultima partenza',
    de: 'Letzte Abfahrt',
    nl: 'Laatste vertrek',
  },
  [SPECIFIC_TABLE_HEADER_DAILY_TRIPS]: {
    id: `${searchScope}.${SPECIFIC_TABLE_HEADER_DAILY_TRIPS}`,
    defaultMessage: 'Nombre par jour',
    en: 'Number of daily trips',
    es: 'Número diario de viajes',
    it: 'Numero di viaggi giornalieri',
    de: 'Anzahl der täglichen Fahrten',
    nl: 'Aantal dagelijkse reizen',
  },
  generalTextHeader: {
    id: `${searchScope}.generalTextHeader`,
    defaultMessage:
      '{origin} {destination} en {transportType}: billets, prix et horaires',
    en: '{origin} {destination} by {transportType}: tickets, prices, and schedules',
    it: '{origin} {destination} in {transportType}: biglietti, prezzi e orari',
    es: '{origin} {destination} en {transportType}: billetes, precios y horarios',
    de: '{origin} {destination} mit {transportType}: Tickets, Preise und Fahrpläne',
    nl: '{origin} {destination} met {transportType}: tickets, prijzen en tijden',
  },
  generalSpecificTextHeader: {
    id: `${searchScope}.generalSpecificTextHeader`,
    defaultMessage:
      "{origin} {destination} {transportType} : billets, prix et horaires, plus vite qu'un {transportType}",
    en: '{origin} {destination} {transportType}: tickets, prices, and schedules, faster than a {transportType}',
    it: '{origin} {destination} {transportType}: biglietti, prezzi e orari, più veloce di un {transportType}',
    es: '{origin} {destination} {transportType}: billetes, precios y horarios, más rápido que un {transportType}',
    de: '{origin} {destination} {transportType}: Tickets, Preise und Fahrpläne, schneller als ein {transportType}',
    nl: '{origin} {destination} {transportType}: tickets, prijzen en tijden, sneller dan een {transportType}',
  },
  specificTextHeader: {
    id: `${searchScope}.specificTextHeader`,
    defaultMessage:
      'Comment aller de {origin} à {destination} avec Tictactrip ?',
    en: 'How to travel from {origin} to {destination} with Tictactrip',
    es: 'Cómo viajar de {origin} a {destination} con Tictactrip',
    it: 'Come viaggiare da {origin} a {destination} con Tictactrip',
    de: 'Wie reise ich von {origin} nach {destination} mit Tictactrip?',
    nl: 'Hoe te reizen van {origin} naar {destination} met Tictactrip',
  },
  [GENERAL_TEXT_SENTENCE_1_1]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCE_1_1}`,
    defaultMessage: `Vous cherchez votre billet de {transportType} {formattedTrip1} ? </br></br>
    <strong>{companies}</strong> sont des compagnies de transport qui vous permettent de voyager facilement entre les deux villes. </br></br>
    En seulement {minDuration}, vous pourrez voyager de <strong>{origin}</strong> à <strong>{destination}</strong> et réaliser la distance de <strong>{distance} kilomètres</strong> qui sépare les deux villes.`,
    en: `Are you looking for your {transportType} ticket {formattedTrip1}? </br></br>
    <strong>{companies}</strong> are transport companies that allow you to travel easily between the two cities. </br></br>
    In just {minDuration}, you will be able to travel from <strong>{origin}</strong> to <strong>{destination}</strong> covering a distance of <strong>{distance} kilometers</strong> between the two cities.`,
    it: `Stai cercando il tuo biglietto {transportType} {formattedTrip1}? </br></br>
    Le <strong>{companies}</strong> sono compagnie di trasporto che ti permettono di viaggiare facilmente tra le due città. </br></br>
    In soli {minDuration}, potrai viaggiare da <strong>{origin}</strong> a <strong>{destination}</strong> percorrendo una distanza di <strong>{distance} chilometri</strong> tra le due città.`,
    es: `¿Estás buscando tu boleto de {transportType} {formattedTrip1}? </br></br>
    Las <strong>{companies}</strong> son compañías de transporte que te permiten viajar fácilmente entre las dos ciudades. </br></br>
    En solo {minDuration}, podrás viajar de <strong>{origin}</strong> a <strong>{destination}</strong> cubriendo una distancia de <strong>{distance} kilómetros</strong> entre las dos ciudades.`,
    de: `Suchen Sie Ihr {transportType}-Ticket {formattedTrip1}? </br></br>
    Die <strong>{companies}</strong> sind Transportunternehmen, die es Ihnen ermöglichen, problemlos zwischen den beiden Städten zu reisen. </br></br>
    In nur {minDuration} können Sie von <strong>{origin}</strong> nach <strong>{destination}</strong> reisen und eine Entfernung von <strong>{distance} Kilometern</strong> zwischen den beiden Städten zurücklegen.`,
    nl: `Ben je op zoek naar je {transportType}-ticket {formattedTrip1}? </br></br>
    De <strong>{companies}</strong> zijn transportbedrijven die je in staat stellen om gemakkelijk tussen de twee steden te reizen. </br></br>
    In slechts {minDuration} kun je reizen van <strong>{origin}</strong> naar <strong>{destination}</strong> en een afstand van <strong>{distance} kilometer</strong> tussen de twee steden afleggen.`,
  },
  [GENERAL_TEXT_SENTENCE_1_2]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCE_1_2}`,
    defaultMessage: `Pour un voyage en {transportType} {formattedTrip1} vous avez le choix !<br /><br />
    Un billet de {companies} <strong>{origin}</strong> <strong>{destination}</strong>, le champ des possibles s'offre à vous !<br /><br />
    Évidemment, le prix des billets va varier. Car vous aurez la chance de vous déplacer en seulement {minDuration} entre {origin} et {destination}.<br /><br />
    Au final, ça ne semble pas si long ce trajet {formattedTrip2} pourtant distant de {distance} kilomètres.`,
    en: `For a trip by {transportType} {formattedTrip1}, you have choices!<br /><br />
    A ticket from {companies} <strong>{origin}</strong> <strong>{destination}</strong>, the field of possibilities is open to you!<br /><br />
    Obviously, ticket prices will vary. Because you will have the chance to travel in just {minDuration} between {origin} and {destination}.<br /><br />
    In the end, it doesn't seem that long this journey {formattedTrip2} despite being {distance} kilometers apart.`,
    it: `Per un viaggio in {transportType} {formattedTrip1}, hai delle scelte!<br /><br />
    Un biglietto da {companies} <strong>{origin}</strong> <strong>{destination}</strong>, il campo delle possibilità è aperto per te!<br /><br />
    Ovviamente, i prezzi dei biglietti variano. Perché avrai la possibilità di viaggiare in soli {minDuration} tra {origin} e {destination}.<br /><br />
    Alla fine, non sembra così lungo questo viaggio {formattedTrip2} nonostante la distanza di {distance} chilometri.`,
    es: `Para un viaje en {transportType} {formattedTrip1}, ¡tienes opciones!<br /><br />
    Un boleto de {companies} <strong>{origin}</strong> <strong>{destination}</strong>, ¡el campo de posibilidades está abierto para ti!<br /><br />
    Obviamente, los precios de los boletos variarán. Porque tendrás la oportunidad de viajar en solo {minDuration} entre {origin} y {destination}.<br /><br />
    Al final, no parece tan largo este viaje {formattedTrip2} a pesar de estar a {distance} kilómetros de distancia.`,
    de: `Für eine Reise mit {transportType} {formattedTrip1} haben Sie die Wahl!<br /><br />
    Ein Ticket von {companies} <strong>{origin}</strong> <strong>{destination}</strong>, das Feld der Möglichkeiten steht Ihnen offen!<br /><br />
    Natürlich variieren die Ticketpreise. Denn Sie haben die Möglichkeit, in nur {minDuration} zwischen {origin} und {destination} zu reisen.<br /><br />
    Am Ende scheint diese Reise {formattedTrip2} trotz der Entfernung von {distance} Kilometern nicht so lang.`,
    nl: `Voor een reis met {transportType} {formattedTrip1} heb je keuzes!<br /><br />
    Een ticket van {companies} <strong>{origin}</strong> <strong>{destination}</strong>, het veld van mogelijkheden ligt open voor jou!<br /><br />
    Uiteraard variëren de ticketprijzen. Omdat je de kans hebt om in slechts {minDuration} te reizen tussen {origin} en {destination}.<br /><br />
    Uiteindelijk lijkt deze reis {formattedTrip2} niet zo lang ondanks de afstand van {distance} kilometer.`,
  },
  [GENERAL_TEXT_SENTENCE_1_3]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCE_1_3}`,
    defaultMessage: `Vous partez en séjour à <strong>{destination}</strong> ?<br /><br />
    Choisissez entre un billet de {companies}, puisque toutes ces compagnies de {transportType} desservent ce trajet {formattedTrip1}.<br /><br />
    En plus c'est vraiment parfait pour vous, puisque ces compagnies de {transportType} {formattedTrip1} vous permettent de voyager en seulement {minDuration}.<br /><br />
    Pas si long pour un voyage de {distance} kilomètres au final !`,
    en: `Are you going on a trip to <strong>{destination}</strong>?<br /><br />
    Choose from a ticket by {companies}, as all these {transportType} companies serve this {formattedTrip1} route.<br /><br />
    It's really perfect for you, as these {transportType} companies {formattedTrip1} allow you to travel in just {minDuration}.<br /><br />
    Not that long for a journey of {distance} kilometers in the end!`,
    it: `Stai andando in vacanza a <strong>{destination}</strong>?<br /><br />
    Scegli tra un biglietto di {companies}, poiché tutte queste compagnie di {transportType} servono questa tratta {formattedTrip1}.<br /><br />
    Inoltre è davvero perfetto per te, poiché queste compagnie di {transportType} {formattedTrip1} ti permettono di viaggiare in soli {minDuration}.<br /><br />
    Non così lungo per un viaggio di {distance} chilometri alla fine!`,
    es: `¿Vas de viaje a <strong>{destination}</strong>?<br /><br />
    Elige entre un boleto de {companies}, ya que todas estas compañías de {transportType} sirven esta ruta {formattedTrip1}.<br /><br />
    Además, es realmente perfecto para ti, ya que estas compañías de {transportType} {formattedTrip1} te permiten viajar en solo {minDuration}.<br /><br />
    ¡No tan largo para un viaje de {distance} kilómetros al final!`,
    de: `Fahren Sie in den Urlaub nach <strong>{destination}</strong>?<br /><br />
    Wählen Sie zwischen einem Ticket von {companies}, da all diese {transportType}-Unternehmen diese {formattedTrip1}-Route bedienen.<br /><br />
    Es ist wirklich perfekt für Sie, da diese {transportType}-Unternehmen {formattedTrip1} Ihnen ermöglichen, in nur {minDuration} zu reisen.<br /><br />
    Am Ende nicht so lang für eine Reise von {distance} Kilometern!`,
    nl: `Ga je op reis naar <strong>{destination}</strong>?<br /><br />
    Kies uit een ticket van {companies}, aangezien al deze {transportType}-bedrijven deze {formattedTrip1}-route bedienen.<br /><br />
    Het is echt perfect voor jou, omdat deze {transportType}-bedrijven {formattedTrip1} je in staat stellen om in slechts {minDuration} te reizen.<br /><br />
    Niet zo lang voor een reis van {distance} kilometer uiteindelijk!`,
  },

  [GENERAL_TEXT_SENTENCES_2_1]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_2_1}`,
    defaultMessage: `Malheureusement aucun trajet {formattedTrip1} en {transportType} n'est direct pour le moment, nos compagnies de transport ne le desservent pas sans autres arrêts/escales.<br /><br />
    Mais heureusement, nos combinés exclusifs vous proposent des changements intelligents pour un voyage moins long ou moins cher que la normale !<br /><br />
    Plutôt pratique non ?!`,
    en: `Unfortunately, no {formattedTrip1} {transportType} route is direct at the moment, our transport companies do not serve it without other stops/layovers.<br /><br />
    But fortunately, our exclusive combinations offer you smart transfers for a shorter or cheaper journey than usual!<br /><br />
    Pretty convenient, isn't it?!`,
    it: `Purtroppo nessun percorso {formattedTrip1} in {transportType} è diretto al momento, le nostre compagnie di trasporto non lo servono senza altre fermate/scali.<br /><br />
    Ma fortunatamente, le nostre combinazioni esclusive offrono trasferimenti intelligenti per un viaggio più breve o più economico del solito!<br /><br />
    Abbastanza conveniente, non è vero?!`,
    es: `Desafortunadamente, ninguna ruta {formattedTrip1} {transportType} es directa en este momento, nuestras compañías de transporte no la sirven sin otras paradas/escala.<br /><br />
    Pero afortunadamente, nuestras combinaciones exclusivas te ofrecen transferencias inteligentes para un viaje más corto o más barato de lo habitual!<br /><br />
    ¡Bastante conveniente, no?!`,
    de: `Leider ist keine {formattedTrip1} {transportType} Strecke momentan direkt, unsere Verkehrsunternehmen bedienen sie nicht ohne andere Stopps/Aufenthalte.<br /><br />
    Aber zum Glück bieten unsere exklusiven Kombinationen Ihnen intelligente Transfers für eine kürzere oder günstigere Reise als üblich!<br /><br />
    Ziemlich praktisch, oder?!`,
    nl: `Helaas is geen enkele {formattedTrip1} {transportType} route momenteel direct, onze vervoersbedrijven bedienen deze niet zonder andere stops/overstappen.<br /><br />
    Maar gelukkig bieden onze exclusieve combinaties u slimme transfers voor een kortere of goedkopere reis dan normaal!<br /><br />
    Best handig, toch?!`,
  },
  [GENERAL_TEXT_SENTENCES_2_2]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_2_2}`,
    defaultMessage: `Ce trajet {formattedTrip1} n'est pas encore desservi directement par nos compagnies de {transportType} partenaires...<br /><br />
      On sait que les escales sont une perte de temps que l'on n'apprécie pas...<br /><br />
      C'est pourquoi nous vous proposons des voyages combinés qui diminuent le prix et/ou la durée de votre itinéraire !<br /><br />
      Nous sommes les seuls à le faire, profitez en !`,
    en: `This {formattedTrip1} route is not yet directly served by our partner {transportType} companies...<br /><br />
      We know that layovers are a waste of time that nobody enjoys...<br /><br />
      That's why we offer combined trips that reduce the price and/or duration of your itinerary!<br /><br />
      We are the only ones who do this, take advantage of it!`,
    it: `Questo percorso {formattedTrip1} non è ancora servito direttamente dalle nostre compagnie partner di {transportType}...<br /><br />
      Sappiamo che le soste sono una perdita di tempo che nessuno apprezza...<br /><br />
      Ecco perché offriamo viaggi combinati che riducono il prezzo e/o la durata del tuo itinerario!<br /><br />
      Siamo gli unici a farlo, approfittane!`,
    es: `Esta ruta {formattedTrip1} aún no es servida directamente por nuestras compañías asociadas de {transportType}...<br /><br />
      Sabemos que las escalas son una pérdida de tiempo que nadie disfruta...<br /><br />
      Por eso ofrecemos viajes combinados que reducen el precio y/o la duración de tu itinerario!<br /><br />
      ¡Somos los únicos que hacemos esto, aprovéchalo!`,
    de: `Diese {formattedTrip1} Strecke wird noch nicht direkt von unseren Partnerunternehmen {transportType} bedient...<br /><br />
      Wir wissen, dass Zwischenstopps eine Zeitverschwendung sind, die niemand mag...<br /><br />
      Deshalb bieten wir kombinierte Reisen an, die den Preis und/oder die Dauer Ihrer Route verkürzen!<br /><br />
      Wir sind die einzigen, die das tun, nutzen Sie es!`,
    nl: `Deze {formattedTrip1} route wordt nog niet rechtstreeks bediend door onze partner {transportType} bedrijven...<br /><br />
      We weten dat tussenstops tijdverspilling zijn die niemand leuk vindt...<br /><br />
      Daarom bieden wij gecombineerde reizen aan die de prijs en/of duur van uw reisroute verminderen!<br /><br />
      Wij zijn de enigen die dit doen, profiteer ervan!`,
  },

  [GENERAL_TEXT_SENTENCES_2_3]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_2_3}`,
    defaultMessage: `Triste nouvelle, il n'existe aucune compagnie de transport effectuant un <strong>trajet {formattedTrip1} direct en {transportType} </strong> pour le moment.<br /><br />
      Pourquoi ne pas opter alors pour l'une de nos combinaisons intelligentes <strong>{transportMode1} + {transportMode2} {formattedTrip2}</strong> ?`,
    en: `Sad news, there is no transport company offering a <strong>direct {formattedTrip1} trip by {transportType}</strong> at the moment.<br /><br />
      Why not opt for one of our smart combinations <strong>{transportMode1} + {transportMode2} {formattedTrip2}</strong> instead?`,
    it: `Triste notizia, al momento non esiste alcuna compagnia di trasporto che offra un <strong>viaggio diretto {formattedTrip1} in {transportType}</strong>.<br /><br />
      Perché non optare per una delle nostre combinazioni intelligenti <strong>{transportMode1} + {transportMode2} {formattedTrip2}</strong>?`,
    es: `Triste noticia, no hay ninguna compañía de transporte que ofrezca un <strong>viaje directo {formattedTrip1} en {transportType}</strong> por el momento.<br /><br />
      ¿Por qué no optar por una de nuestras combinaciones inteligentes <strong>{transportMode1} + {transportMode2} {formattedTrip2}</strong>?`,
    de: `Traurige Nachricht, es gibt derzeit kein Transportunternehmen, das eine <strong>direkte {formattedTrip1}-Reise mit {transportType}</strong> anbietet.<br /><br />
      Warum nicht eine unserer intelligenten Kombinationen <strong>{transportMode1} + {transportMode2} {formattedTrip2}</strong> wählen?`,
    nl: `Triest nieuws, er is momenteel geen vervoersmaatschappij die een <strong>directe {formattedTrip1} reis per {transportType}</strong> aanbiedt.<br /><br />
      Waarom niet kiezen voor een van onze slimme combinaties <strong>{transportMode1} + {transportMode2} {formattedTrip2}</strong>?`,
  },
  [GENERAL_TEXT_SENTENCES_2_1_DIRECT]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_2_1_DIRECT}`,
    defaultMessage:
      'Sur notre site vous pourrez trouver des trajets directs en {transportType} <strong>{origin} {destination}</strong> car plusieurs de nos compagnies de transport partenaires les desservent. Plutôt cool non ?!',
    en: 'On our site, you can find direct {transportType} trips <strong>{origin} {destination}</strong> because several of our partner transport companies offer them. Pretty cool, right?!',
    it: 'Sul nostro sito, puoi trovare viaggi diretti in {transportType} <strong>{origin} {destination}</strong> perché diverse delle nostre compagnie di trasporto partner li offrono. Abbastanza fantastico, vero?!',
    es: 'En nuestro sitio, puedes encontrar viajes directos en {transportType} <strong>{origin} {destination}</strong> porque varias de nuestras compañías de transporte asociadas los ofrecen. ¡Bastante genial, ¿no?!',
    de: 'Auf unserer Website können Sie direkte {transportType}-Fahrten <strong>{origin} {destination}</strong> finden, da mehrere unserer Partnerverkehrsunternehmen diese anbieten. Ziemlich cool, oder?!',
    nl: 'Op onze site kun je directe {transportType} reizen <strong>{origin} {destination}</strong> vinden, omdat meerdere van onze partnervervoersbedrijven deze aanbieden. Best cool, toch?!',
  },

  [GENERAL_TEXT_SENTENCES_2_2_DIRECT]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_2_2_DIRECT}`,
    defaultMessage: `Super nouvelle ! Votre trajet <strong>{origin} {destination}</strong> en {transportType} existe en trajet direct sur Tictactrip.<br /><br />
    Autrement dit, vous pouvez réserver dès maintenant votre direct <strong>{formattedTrip}</strong> !`,
    en: `Great news! Your trip <strong>{origin} {destination}</strong> by {transportType} is available as a direct route on Tictactrip.<br /><br />
    In other words, you can book your direct <strong>{formattedTrip}</strong> right now!`,
    it: `Ottima notizia! Il tuo viaggio <strong>{origin} {destination}</strong> in {transportType} è disponibile come percorso diretto su Tictactrip.<br /><br />
    In altre parole, puoi prenotare il tuo diretto <strong>{formattedTrip}</strong> proprio ora!`,
    es: `¡Buenas noticias! Tu viaje <strong>{origin} {destination}</strong> en {transportType} está disponible como ruta directa en Tictactrip.<br /><br />
    En otras palabras, ¡puedes reservar tu directo <strong>{formattedTrip}</strong> ahora mismo!`,
    de: `Tolle Neuigkeiten! Ihre Reise <strong>{origin} {destination}</strong> mit {transportType} ist als Direktverbindung auf Tictactrip verfügbar.<br /><br />
    Mit anderen Worten, Sie können jetzt Ihre direkte <strong>{formattedTrip}</strong> buchen!`,
    nl: `Goed nieuws! Uw reis <strong>{origin} {destination}</strong> met {transportType} is beschikbaar als directe route op Tictactrip.<br /><br />
    Met andere woorden, u kunt uw directe <strong>{formattedTrip}</strong> nu boeken!`,
  },

  [GENERAL_TEXT_SENTENCES_2_3_DIRECT]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_2_3_DIRECT}`,
    defaultMessage:
      "Pas envie de faire d'escale(s) ou même d'arrêt(s) en cours de voyage ? Ça tombe très bien, ce trajet <strong>{formattedTrip} est direct et pas cher</strong>.",
    en: "Don't feel like making stops or layovers during your trip? Perfect, this route <strong>{formattedTrip} is direct and affordable</strong>.",
    it: 'Non ti va di fare fermate o scali durante il viaggio? Perfetto, questo percorso <strong>{formattedTrip} è diretto ed economico</strong>.',
    es: '¿No tienes ganas de hacer paradas o escalas durante tu viaje? Perfecto, esta ruta <strong>{formattedTrip} es directa y asequible</strong>.',
    de: 'Keine Lust auf Zwischenstopps oder Umsteigen während Ihrer Reise? Perfekt, diese Route <strong>{formattedTrip} ist direkt und erschwinglich</strong>.',
    nl: 'Geen zin om te stoppen of over te stappen tijdens je reis? Perfect, deze route <strong>{formattedTrip} is direct en betaalbaar</strong>.',
  },
  [GENERAL_TEXT_SENTENCES_3_1]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_3_1}`,
    defaultMessage:
      "Votre voyage {formattedTrip1} à partir de {only} <strong>{minPrice} €</strong>, c'est plutôt une belle affaire non ?<br /><br />Attention cependant ! Le prix des billets de {transportType} évolue selon le moment de votre réservation.<br /><br />On vous donne plus de détails à la suite concernant le meilleur moment pour réserver votre billet !<br /><br />Vous pouvez aussi utiliser notre comparateur des prix {formattedTrip1} en {transportType}, selon la date de réservation. Il est juste au-dessus, c'est pratique !",
    en: "Your {formattedTrip1} trip from {only} <strong>{minPrice} €</strong>, that's quite a deal, isn't it?<br /><br />But be careful! The price of {transportType} tickets varies depending on when you book.<br /><br />We'll give you more details below on the best time to book your ticket!<br /><br />You can also use our {formattedTrip1} price comparator in {transportType}, based on the booking date. It's right above, it's handy!",
    it: 'Il tuo viaggio {formattedTrip1} a partire da {only} <strong>{minPrice} €</strong>, è davvero un affare, vero?<br /><br />Ma fai attenzione! Il prezzo dei biglietti {transportType} varia a seconda di quando prenoti.<br /><br />Ti daremo maggiori dettagli di seguito sul momento migliore per prenotare il tuo biglietto!<br /><br />Puoi anche utilizzare il nostro comparatore di prezzi {formattedTrip1} in {transportType}, in base alla data di prenotazione. È proprio sopra, è comodo!',
    es: 'Tu viaje {formattedTrip1} desde {only} <strong>{minPrice} €</strong>, es realmente una ganga, ¿verdad?<br /><br />¡Pero ten cuidado! El precio de los billetes de {transportType} varía según cuándo reserves.<br /><br />Te daremos más detalles a continuación sobre el mejor momento para reservar tu billete.<br /><br />También puedes usar nuestro comparador de precios {formattedTrip1} en {transportType}, según la fecha de reserva. Está justo arriba, ¡es práctico!',
    de: 'Ihre {formattedTrip1}-Reise ab {only} <strong>{minPrice} €</strong>, das ist doch ein Schnäppchen, oder?<br /><br />Aber Vorsicht! Der Preis für {transportType}-Tickets variiert je nach Buchungszeitpunkt.<br /><br />Wir geben Ihnen unten weitere Details zum besten Zeitpunkt für die Buchung Ihres Tickets!<br /><br />Sie können auch unseren {formattedTrip1}-Preisvergleich in {transportType} nutzen, basierend auf dem Buchungsdatum. Er ist direkt darüber, das ist praktisch!',
    nl: 'Uw {formattedTrip1}-reis vanaf {only} <strong>{minPrice} €</strong>, dat is nog eens een koopje, toch?<br /><br />Maar pas op! De prijs van {transportType}-tickets varieert afhankelijk van wanneer u boekt.<br /><br />We geven u hieronder meer details over het beste moment om uw ticket te boeken!<br /><br />U kunt ook onze {formattedTrip1}-prijsvergelijker in {transportType} gebruiken, op basis van de boekingsdatum. Hij staat direct hierboven, handig!',
  },

  [GENERAL_TEXT_SENTENCES_3_2]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_3_2}`,
    defaultMessage:
      "Réservez votre <strong> billet {formattedTrip1}</strong> en {transportType} à partir de <strong>{minPrice} € </strong>.<br /><br />Il n'y a pas de raisons d'hésiter, pour un tel trajet qui dure au minimum <strong>{minDuration}</strong>, c'est une aubaine à saisir !<br /><br />Mais si vous voulez être sûr de réserver votre billet au meilleur moment, pour obtenir le meilleur prix, utilisez notre comparateur juste au-dessus !<br /><br />Il vous dira combien de jour à l'avance vous devez réserver pour obtenir le meilleur prix. Pratique ;)",
    en: "Book your <strong>{formattedTrip1} ticket</strong> in {transportType} starting from <strong>{minPrice} € </strong>.<br /><br />There's no reason to hesitate, for such a trip that lasts at least <strong>{minDuration}</strong>, it's a bargain to seize!<br /><br />But if you want to be sure to book your ticket at the best time, to get the best price, use our comparator just above!<br /><br />It will tell you how many days in advance you should book to get the best price. Handy ;)",
    it: "Prenota il tuo <strong>biglietto {formattedTrip1}</strong> in {transportType} a partire da <strong>{minPrice} € </strong>.<br /><br />Non c'è motivo di esitare, per un viaggio del genere che dura almeno <strong>{minDuration}</strong>, è un'affare da cogliere al volo!<br /><br />Ma se vuoi essere sicuro di prenotare il tuo biglietto al momento migliore, per ottenere il prezzo migliore, usa il nostro comparatore appena sopra!<br /><br />Ti dirà quanti giorni in anticipo devi prenotare per ottenere il prezzo migliore. Comodo ;)",
    es: 'Reserve su <strong>billete {formattedTrip1}</strong> en {transportType} a partir de <strong>{minPrice} € </strong>.<br /><br />No hay razón para dudar, para un viaje de tal duración que dura al menos <strong>{minDuration}</strong>, ¡es una ganga que hay que aprovechar!<br /><br />Pero si quieres asegurarte de reservar tu billete en el mejor momento, para obtener el mejor precio, ¡usa nuestro comparador justo arriba!<br /><br />Te dirá cuántos días de antelación debes reservar para obtener el mejor precio. Práctico ;)',
    de: 'Buchen Sie Ihr <strong>{formattedTrip1}-Ticket</strong> in {transportType} ab <strong>{minPrice} € </strong>.<br /><br />Es gibt keinen Grund zu zögern, für eine solche Reise, die mindestens <strong>{minDuration}</strong> dauert, ist es ein Schnäppchen, das man ergreifen sollte!<br /><br />Aber wenn Sie sicher sein wollen, Ihr Ticket zum besten Zeitpunkt zu buchen, um den besten Preis zu erhalten, verwenden Sie unseren Vergleich über Ihnen!<br /><br />Er sagt Ihnen, wie viele Tage im Voraus Sie buchen sollten, um den besten Preis zu erhalten. Praktisch ;)',
    nl: "Boek uw <strong>{formattedTrip1}-ticket</strong> in {transportType} vanaf <strong>{minPrice} € </strong>.<br /><br />Er is geen reden om te aarzelen, voor zo'n reis die minstens <strong>{minDuration}</strong> duurt, is het een koopje om te grijpen!<br /><br />Maar als u zeker wilt zijn van het boeken van uw ticket op het beste moment, om de beste prijs te krijgen, gebruik dan onze vergelijker net bovenaan!<br /><br />Hij vertelt u hoeveel dagen van tevoren u moet boeken om de beste prijs te krijgen. Handig ;)",
  },
  [GENERAL_TEXT_SENTENCES_3_3]: {
    id: `${searchScope}.${GENERAL_TEXT_SENTENCES_3_3}`,
    defaultMessage:
      "Pour un <strong> prix de {only} {minPrice} € </strong>, vous pourrez voyager facilement entre <strong>{origin} et {destination}</strong> en <strong>{minDuration}</strong> minimum en {transportType}.<br /><br />À ce prix là, c'est une occasion à ne pas rater !<br /><br />Mais le meilleur moyen de réserver le billet de {transportType} au meilleur prix, c'est d'utiliser notre comparateur au-dessus.<br /><br />Vous pourrez facilement trouver le meilleur moment pour réserver, en fonction de votre date de départ. Pratique !",
    en: "For a <strong> price of {only} {minPrice} € </strong>, you'll be able to travel easily between <strong>{origin} and {destination}</strong> in <strong>{minDuration}</strong> minimum in {transportType}.<br /><br />At this price, it's an opportunity not to be missed!<br /><br />But the best way to book the {transportType} ticket at the best price is to use our comparator above.<br /><br />You'll easily find the best time to book, based on your departure date. Handy!",
    it: "Per un <strong> prezzo di {only} {minPrice} € </strong>, potrai viaggiare facilmente tra <strong>{origin} e {destination}</strong> in <strong>{minDuration}</strong> minimo in {transportType}.<br /><br />A questo prezzo, è un'occasione da non perdere!<br /><br />Ma il modo migliore per prenotare il biglietto {transportType} al miglior prezzo è utilizzare il nostro comparatore sopra.<br /><br />Troverai facilmente il momento migliore per prenotare, in base alla tua data di partenza. Pratico!",
    es: 'Por un <strong> precio de {only} {minPrice} € </strong>, podrás viajar fácilmente entre <strong>{origin} y {destination}</strong> en un mínimo de <strong>{minDuration}</strong> en {transportType}.<br /><br />¡A este precio, es una oportunidad que no puedes dejar pasar!<br /><br />Pero la mejor manera de reservar el billete de {transportType} al mejor precio es usar nuestro comparador arriba.<br /><br />Podrás encontrar fácilmente el mejor momento para reservar, según tu fecha de salida. ¡Práctico!',
    de: 'Für einen <strong> Preis von {only} {minPrice} € </strong> können Sie leicht zwischen <strong>{origin} und {destination}</strong> in <strong>{minDuration}</strong> Mindestens in {transportType} reisen.<br /><br />Zu diesem Preis ist es eine Gelegenheit, die man nicht verpassen sollte!<br /><br />Aber der beste Weg, das {transportType}-Ticket zum besten Preis zu buchen, ist die Verwendung unseres Vergleichs oben.<br /><br />Sie finden leicht die beste Zeit zum Buchen, basierend auf Ihrem Abreisedatum. Praktisch!',
    nl: 'Voor een <strong> prijs van {only} {minPrice} € </strong>, kunt u gemakkelijk reizen tussen <strong>{origin} en {destination}</strong> in <strong>{minDuration}</strong> minimum in {transportType}.<br /><br />Op deze prijs, is het een kans die je niet mag missen!<br /><br />Maar de beste manier om het {transportType}-ticket tegen de beste prijs te boeken is door onze vergelijker hierboven te gebruiken.<br /><br />U zult gemakkelijk de beste tijd vinden om te boeken, op basis van uw vertrekdatum. Handig!',
  },
  [FORMATTED_TRIPS_1]: {
    id: `${searchScope}.${FORMATTED_TRIPS_1}`,
    defaultMessage: 'de {origin} à {destination}',
    en: 'from {origin} to {destination}',
    es: 'de {origin} a {destination}',
    it: 'da {origin} a {destination}',
    de: 'von {origin} nach {destination}',
    nl: 'van {origin} naar {destination}',
  },
  [FORMATTED_TRIPS_2]: {
    id: `${searchScope}.${FORMATTED_TRIPS_2}`,
    defaultMessage: '{origin}-{destination}',
    en: '{origin}-{destination}',
    es: '{origin}-{destination}',
    it: '{origin}-{destination}',
    de: '{origin}-{destination}',
    nl: '{origin}-{destination}',
  },
  [FORMATTED_TRIPS_3]: {
    id: `${searchScope}.${FORMATTED_TRIPS_3}`,
    defaultMessage: 'de {origin} vers {destination}',
    en: 'from {origin} to {destination}',
    es: 'de {origin} a {destination}',
    it: 'da {origin} a {destination}',
    de: 'von {origin} nach {destination}',
    nl: 'van {origin} naar {destination}',
  },
  [FORMATTED_TRIPS_4]: {
    id: `${searchScope}.${FORMATTED_TRIPS_4}`,
    defaultMessage: '{origin} ➜ {destination}',
    en: '{origin} ➜ {destination}',
    es: '{origin} ➜ {destination}',
    it: '{origin} ➜ {destination}',
    de: '{origin} ➜ {destination}',
    nl: '{origin} ➜ {destination}',
  },
  [FORMATTED_TRIPS_5]: {
    id: `${searchScope}.${FORMATTED_TRIPS_5}`,
    defaultMessage: '{origin} {destination}',
    en: '{origin} {destination}',
    es: '{origin} {destination}',
    it: '{origin} {destination}',
    de: '{origin} {destination}',
    nl: '{origin} {destination}',
  },
  [FORMATTED_TRIPS_6]: {
    id: `${searchScope}.${FORMATTED_TRIPS_6}`,
    defaultMessage: 'depuis {origin} vers {destination}',
    en: 'from {origin} to {destination}',
    es: 'desde {origin} hasta {destination}',
    it: 'da {origin} a {destination}',
    de: 'von {origin} nach {destination}',
    nl: 'vanaf {origin} naar {destination}',
  },
  [SPECIFIC_TEXT_HEADER_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_HEADER_1}`,
    defaultMessage:
      'Itinéraire de {transportMean} de {origin} à {destination} {emoji}',
    en: '{transportMean} itinerary from {origin} to {destination} {emoji}',
    es: 'Itinerario de {transportMean} de {origin} a {destination} {emoji}',
    it: 'Itinerario di {transportMean} da {origin} a {destination} {emoji}',
    de: '{transportMean}-Reise von {origin} nach {destination} {emoji}',
    nl: '{transportMean}-reis van {origin} naar {destination} {emoji}',
  },
  [SPECIFIC_TEXT_HEADER_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_HEADER_2}`,
    defaultMessage:
      'Pour aller de {origin} à {destination} en {transportMean} {emoji}',
    en: 'From {origin} to {destination} by {transportMean} {emoji}',
    es: 'Desde {origin} hasta {destination} en {transportMean} {emoji}',
    it: 'Da {origin} a {destination} in {transportMean} {emoji}',
    de: 'Von {origin} nach {destination} mit {transportMean} {emoji}',
    nl: 'Van {origin} naar {destination} met {transportMean} {emoji}',
  },
  [SPECIFIC_TEXT_HEADER_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_HEADER_3}`,
    defaultMessage: '{transportMean} {origin} {destination} {emoji}',
    en: '{transportMean} {origin} {destination} {emoji}',
    es: '{transportMean} {origin} {destination} {emoji}',
    it: '{transportMean} {origin} {destination} {emoji}',
    de: '{transportMean} {origin} {destination} {emoji}',
    nl: '{transportMean} {origin} {destination} {emoji}',
  },
  specificTextStations: {
    id: `${searchScope}.specificTextStations`,
    defaultMessage: 'Il dessert aussi {stationsTranslated} {stations}.',
    en: 'It also serves {stationsTranslated} {stations}.',
    es: 'También sirve {stationsTranslated} {stations}.',
    it: 'Serve anche {stationsTranslated} {stations}.',
    de: 'Es bedient auch {stationsTranslated} {stations}.',
    nl: 'Het bedient ook {stationsTranslated} {stations}.',
  },
  specificTextItinerary: {
    id: `${searchScope}.specificTextItinerary`,
    defaultMessage: '{origin} {destination} passant par {intermediateCities}',
    en: '{origin} {destination} passing through {intermediateCities}',
    es: '{origin} {destination} pasando por {intermediateCities}',
    it: '{origin} {destination} passando per {intermediateCities}',
    de: '{origin} {destination} über {intermediateCities}',
    nl: '{origin} {destination} via {intermediateCities}',
  },
  specificTextItineraryDirect: {
    id: `${searchScope}.specificTextItineraryDirect`,
    defaultMessage: 'direct {origin} {destination}',
    en: 'direct {origin} {destination}',
    es: 'directo {origin} {destination}',
    it: 'diretto {origin} {destination}',
    de: 'direkt {origin} {destination}',
    nl: 'direct {origin} {destination}',
  },
  specificTextNoData: {
    id: `${searchScope}.specificTextNoData`,
    defaultMessage: `Malheureusement, nos compagnies de {transportMean} partenaires ne proposent pas ce trajet <strong>{formattedTrip}</strong> en <strong>{transportMean}</strong> pour le moment...
      Mais pas d’inquiétudes ! Vous pourrez trouver un trajet à prix mini grâce à nos autres types de transports !`,
    en: `Unfortunately, our {transportMean} companies do not operate {formattedTrip} trips by {transportMean}.
      But don't worry, you can still find the best prices with our other means of transportation`,
    es: `Desafortunadamente, nuestras compañías de {transportMean} asociadas no ofrecen viajes {formattedTrip} en {transportMean} por el momento.
      ¡Pero no te preocupes, aún puedes encontrar los mejores precios con nuestros otros medios de transporte!`,
    it: `Sfortunatamente, le nostre compagnie di {transportMean} partner non offrono viaggi {formattedTrip} in {transportMean} al momento...
      Ma non preoccuparti! Puoi comunque trovare le migliori tariffe con i nostri altri mezzi di trasporto!`,
    de: `Leider bieten unsere {transportMean}-Partnerunternehmen im Moment keine {formattedTrip}-Reisen mit {transportMean} an.
      Aber keine Sorge, Sie können immer noch die besten Preise mit unseren anderen Verkehrsmitteln finden!`,
    nl: `Helaas bieden onze partners in {transportMean} op dit moment geen {formattedTrip}-reizen aan.
      Maar maak je geen zorgen, je kunt nog steeds de beste prijzen vinden met onze andere vervoersmiddelen!`,
  },
  [SPECIFIC_TEXT_NO_DATA_INTRO_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_INTRO_1}`,
    defaultMessage:
      'Voici {itinerariesNb} {itinerariesAvailable} en {transportMean}',
    en: 'Here are {itinerariesNb} {itinerariesAvailable} by {transportMean}',
    es: 'Aquí hay {itinerariesNb} {itinerariesAvailable} en {transportMean}',
    it: 'Ecco {itinerariesNb} {itinerariesAvailable} in {transportMean}',
    de: 'Hier sind {itinerariesNb} {itinerariesAvailable} mit {transportMean}',
    nl: 'Hier zijn {itinerariesNb} {itinerariesAvailable} met {transportMean}',
  },
  [SPECIFIC_TEXT_NO_DATA_INTRO_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_INTRO_2}`,
    defaultMessage:
      'Il existe {itinerariesNb} {itinerariesAvailable} en {transportMean}',
    en: 'There are {itinerariesNb} {itinerariesAvailable} by {transportMean}',
    es: 'Existen {itinerariesNb} {itinerariesAvailable} en {transportMean}',
    it: 'Ci sono {itinerariesNb} {itinerariesAvailable} in {transportMean}',
    de: 'Es gibt {itinerariesNb} {itinerariesAvailable} mit {transportMean}',
    nl: 'Er zijn {itinerariesNb} {itinerariesAvailable} met {transportMean}',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_1}`,
    defaultMessage:
      'Pour aller de {origin} à {destination} en {transportMean}, {companiesSentence} un trajet <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    en: 'To travel from {origin} to {destination} by {transportMean}, {companiesSentence} a journey <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    es: 'Para ir de {origin} a {destination} en {transportMean}, {companiesSentence} un viaje <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    it: 'Per viaggiare da {origin} a {destination} in {transportMean}, {companiesSentence} un viaggio <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    de: 'Um von {origin} nach {destination} mit {transportMean} zu reisen, {companiesSentence} eine Reise <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    nl: 'Om van {origin} naar {destination} te reizen met {transportMean}, {companiesSentence} een reis <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_2}`,
    defaultMessage: `Votre <strong>trajet {formattedTrip} en {transportMean}</strong> est proposé par {companies}.
    C'est un <strong>trajet</strong> <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}`,
    en: `Your <strong>{formattedTrip} journey by {transportMean}</strong> is offered by {companies}.
    It's a <strong>journey</strong> <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}`,
    es: `Tu <strong>{formattedTrip} viaje en {transportMean}</strong> es ofrecido por {companies}.
    Es un <strong>viaje</strong> <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}`,
    it: `Il tuo viaggio <strong>{formattedTrip} in {transportMean}</strong> è offerto da {companies}.
    È un <strong>viaggio</strong> <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}`,
    de: `Ihre <strong>{formattedTrip} Reise mit {transportMean}</strong> wird von {companies} angeboten.
    Es ist eine <strong>Reise</strong> <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}`,
    nl: `Uw <strong>{formattedTrip} reis met {transportMean}</strong> wordt aangeboden door {companies}.
    Het is een <strong>reis</strong> <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}`,
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_3}`,
    defaultMessage:
      'Avec {companies}, vous avez un trajet <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    en: 'With {companies}, you have a <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> journey {stops}',
    es: 'Con {companies}, tienes un viaje <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    it: 'Con {companies}, hai un viaggio <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> {stops}',
    de: 'Mit {companies} haben Sie eine <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> Reise {stops}',
    nl: 'Met {companies} heeft u een <strong>{direct}</strong> <strong>{origin}</strong> <strong>{destination}</strong> reis {stops}',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_1}`,
    defaultMessage: 'de <strong> {distance} kilomètres</strong>.',
    en: ' of <strong> {distance} kilometers</strong>.',
    es: ' de <strong> {distance} kilómetros</strong>.',
    it: ' di <strong> {distance} chilometri</strong>.',
    de: ' von <strong> {distance} Kilometern</strong>.',
    nl: ' van <strong> {distance} kilometer</strong>.',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_2}`,
    defaultMessage:
      '. Ce trajet est long de <strong> {distance} kilomètres</strong>.',
    en: '. This journey is <strong> {distance} kilometers</strong> long.',
    es: '. Este viaje tiene <strong> {distance} kilómetros</strong> de longitud.',
    it: '. Questo viaggio è lungo <strong> {distance} chilometri</strong>.',
    de: '. Diese Reise ist <strong> {distance} Kilometer</strong> lang.',
    nl: '. Deze reis is <strong> {distance} kilometer</strong> lang.',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_1}`,
    defaultMessage: 'qui passe par {stops}',
    en: 'that passes through {stops}',
    es: 'que pasa por {stops}',
    it: 'che passa per {stops}',
    de: 'der durch {stops} führt',
    nl: 'die langs {stops} gaat',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_2}`,
    defaultMessage: 'avec escale à {stops}',
    en: 'with a stopover at {stops}',
    es: 'con escala en {stops}',
    it: 'con scalo a {stops}',
    de: 'mit einem Zwischenstopp in {stops}',
    nl: 'met een tussenstop bij {stops}',
  },
  [SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_3}`,
    defaultMessage: 'qui fait escale à {stops}',
    en: 'that makes a stop at {stops}',
    es: 'que hace una parada en {stops}',
    it: 'che fa una sosta a {stops}',
    de: 'der an {stops} anhält',
    nl: 'die stopt bij {stops}',
  },
  specificTextCarpooling: {
    id: `${searchScope}.specificTextCarpooling`,
    defaultMessage: `Le trajet <strong>{formattedTrip}</strong> en covoiturage est proposé par {companies}.
    Les prix varient entre <strong>{minPrice}€</strong> et <strong>{maxPrice}€</strong> et les trajets durent entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.`,
    en: `The journey <strong>{formattedTrip}</strong> by carpooling is offered by {companies}.
    Prices range from <strong>{minPrice}€</strong> to <strong>{maxPrice}€</strong>, and the trips last between <strong>{minDuration}</strong> and <strong>{maxDuration}</strong>.`,
    es: `El viaje <strong>{formattedTrip}</strong> en coche compartido es ofrecido por {companies}.
    Los precios varían entre <strong>{minPrice}€</strong> y <strong>{maxPrice}€</strong>, y los viajes duran entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>.`,
    it: `Il viaggio <strong>{formattedTrip}</strong> in carpooling è offerto da {companies}.
    I prezzi variano tra <strong>{minPrice}€</strong> e <strong>{maxPrice}€</strong>, e i viaggi durano tra <strong>{minDuration}</strong> e <strong>{maxDuration}</strong>.`,
    de: `Die Fahrt <strong>{formattedTrip}</strong> mit Mitfahrgelegenheit wird von {companies} angeboten.
    Die Preise liegen zwischen <strong>{minPrice}€</strong> und <strong>{maxPrice}€</strong>, und die Fahrten dauern zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>.`,
    nl: `De reis <strong>{formattedTrip}</strong> met carpooling wordt aangeboden door {companies}.
    De prijzen variëren van <strong>{minPrice}€</strong> tot <strong>{maxPrice}€</strong>, en de reizen duren tussen <strong>{minDuration}</strong> en <strong>{maxDuration}</strong>.`,
  },

  specificTextNoDataCarpooling: {
    id: `${searchScope}.specificTextNoDataCarpooling`,
    defaultMessage: `Il n'y a pas d'informations sur covoiturage <strong>{formattedTrip}</strong>.
    Les compagnies de covoiturage Blablacar ou Idvroom n'ont probablement pas d'utilisateurs qui proposent ce trajet.`,
    en: `We have no information concerning <strong>{formattedTrip}</strong> by carpooling.
    Blablacar or Idvroom probably don't have any users offering this trip.`,
    es: `No hay información sobre el viaje compartido <strong>{formattedTrip}</strong>.
    Las empresas de viajes compartidos como Blablacar o Idvroom probablemente no tienen usuarios que ofrezcan este viaje.`,
    it: `Non ci sono informazioni sull'autostop <strong>{formattedTrip}</strong>.
    Le compagnie di autostop come Blablacar o Idvroom probabilmente non hanno utenti che offrono questo viaggio.`,
    de: `Es liegen keine Informationen zum Mitfahren <strong>{formattedTrip}</strong> vor.
    Mitfahrgelegenheiten wie Blablacar oder Idvroom haben wahrscheinlich keine Benutzer, die diese Reise anbieten.`,
    nl: `Er zijn geen gegevens over carpoolen <strong>{formattedTrip}</strong>.
    Carpoolbedrijven zoals Blablacar of Idvroom hebben waarschijnlijk geen gebruikers die deze reis aanbieden.`,
  },
  [SPECIFIC_TEXT_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_1}`,
    defaultMessage: `Un trajet <strong>{itinerary}</strong> a un prix qui varie en temps normal entre <strong>{minPrice}€</strong> et <strong>{maxPrice}€</strong>.
    La durée de trajet peut elle aussi être amenée à varier de <strong>{minDuration}</strong> à <strong>{maxDuration}</strong>.
    En moyenne, pour ce trajet, un simple billet aller vous coûtera <strong>{meanPrice}€</strong>.
    La durée moyenne pour un trajet <strong>{itinerary}</strong> est de <strong>{meanDuration}</strong>.</br>`,
    en: `A journey <strong>{itinerary}</strong> has a price that normally varies between <strong>{minPrice}€</strong> and <strong>{maxPrice}€</strong>.
    The travel duration may also vary from <strong>{minDuration}</strong> to <strong>{maxDuration}</strong>.
    On average, for this journey, a one-way ticket will cost you <strong>{meanPrice}€</strong>.
    The average duration for a <strong>{itinerary}</strong> journey is <strong>{meanDuration}</strong>.</br>`,
    es: `Un viaje <strong>{itinerary}</strong> tiene un precio que normalmente varía entre <strong>{minPrice}€</strong> y <strong>{maxPrice}€</strong>.
    La duración del viaje también puede variar de <strong>{minDuration}</strong> a <strong>{maxDuration}</strong>.
    En promedio, para este viaje, un billete de ida le costará <strong>{meanPrice}€</strong>.
    La duración promedio de un viaje <strong>{itinerary}</strong> es de <strong>{meanDuration}</strong>.</br>`,
    it: `Un viaggio <strong>{itinerary}</strong> ha un prezzo che varia normalmente tra <strong>{minPrice}€</strong> e <strong>{maxPrice}€</strong>.
    La durata del viaggio può anche variare da <strong>{minDuration}</strong> a <strong>{maxDuration}</strong>.
    In media, per questo viaggio, un biglietto di sola andata ti costerà <strong>{meanPrice}€</strong>.
    La durata media per un viaggio <strong>{itinerary}</strong> è di <strong>{meanDuration}</strong>.</br>`,
    de: `Eine Reise <strong>{itinerary}</strong> hat normalerweise einen Preis zwischen <strong>{minPrice}€</strong> und <strong>{maxPrice}€</strong>.
    Die Reisedauer kann ebenfalls von <strong>{minDuration}</strong> bis <strong>{maxDuration}</strong> variieren.
    Im Durchschnitt kostet eine einfache Fahrt für diese Reise <strong>{meanPrice}€</strong>.
    Die durchschnittliche Dauer für eine <strong>{itinerary}</strong> Reise beträgt <strong>{meanDuration}</strong>.</br>`,
    nl: `Een reis <strong>{itinerary}</strong> heeft een prijs die normaal gesproken varieert tussen <strong>{minPrice}€</strong> en <strong>{maxPrice}€</strong>.
    De reisduur kan ook variëren van <strong>{minDuration}</strong> tot <strong>{maxDuration}</strong>.
    Gemiddeld kost een enkele reis voor deze reis <strong>{meanPrice}€</strong>.
    De gemiddelde duur voor een <strong>{itinerary}</strong> reis is <strong>{meanDuration}</strong>.</br>`,
  },

  [SPECIFIC_TEXT_1_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_1_1}`,
    defaultMessage:
      'Ce trajet {formattedTrip1} est proposé par les compagnies {companies}. Il part de {originStation} et arrive à {destinationStation}.',
    en: `This journey {formattedTrip1} is offered by the companies {companies}. It departs from {originStation} and arrives at {destinationStation}.
    {intermediateStations}`,
    es: `Este viaje {formattedTrip1} es ofrecido por las compañías {companies}. Sale de {originStation} y llega a {destinationStation}.
    {intermediateStations}`,
    it: `Questo viaggio {formattedTrip1} è offerto dalle compagnie {companies}. Parte da {originStation} e arriva a {destinationStation}.
    {intermediateStations}`,
    de: `Diese Reise {formattedTrip1} wird von den Unternehmen {companies} angeboten. Sie fährt von {originStation} ab und kommt in {destinationStation} an.
    {intermediateStations}`,
    nl: `Deze reis {formattedTrip1} wordt aangeboden door de bedrijven {companies}. Het vertrekt vanaf {originStation} en komt aan op {destinationStation}.
    {intermediateStations}`,
  },
  [SPECIFIC_TEXT_1_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_1_2}`,
    defaultMessage: `Le premier de ces trajets part chaque jour à <strong>{firstDeparture}</strong>.
    Si vous souhaitez partir plus tard, vous pouvez encore prendre un <strong> {transportMean} {formattedTrip2} </strong> à <strong>{lastDeparture}</strong>.`,
    en: `The first of these journeys departs every day at <strong>{firstDeparture}</strong>.
    If you prefer to leave later, you can still take a <strong> {transportMean} {formattedTrip2} </strong> at <strong>{lastDeparture}</strong>.
    `,
    es: `El primero de estos viajes sale todos los días a las <strong>{firstDeparture}</strong>.
    Si prefieres salir más tarde, aún puedes tomar un <strong> {transportMean} {formattedTrip2} </strong> a las <strong>{lastDeparture}</strong>.
    `,
    it: `Il primo di questi viaggi parte ogni giorno alle <strong>{firstDeparture}</strong>.
    Se preferisci partire più tardi, puoi comunque prendere un <strong> {transportMean} {formattedTrip2} </strong> alle <strong>{lastDeparture}</strong>.
    `,
    de: `Die erste dieser Reisen startet jeden Tag um <strong>{firstDeparture}</strong>.
    Wenn Sie lieber später losfahren möchten, können Sie immer noch eine <strong> {transportMean} {formattedTrip2} </strong> um <strong>{lastDeparture}</strong> nehmen.
    `,
    nl: `De eerste van deze reizen vertrekt elke dag om <strong>{firstDeparture}</strong>.
    Als je liever later vertrekt, kun je nog steeds een <strong> {transportMean} {formattedTrip2} </strong> nemen om <strong>{lastDeparture}</strong>.
    `,
  },

  [SPECIFIC_TEXT_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_2}`,
    defaultMessage: `Il faudra que vous comptiez un prix minimum de <strong>{minPrice}€</strong> pour un trajet <strong>{itinerary}</strong>.
    En fonction de la date de votre recherche sur Tictactrip, le prix du trajet {formattedTrip1} peut aller jusqu'à <strong>{maxPrice}€</strong> (il coûte en moyenne <strong>{meanPrice}€</strong>).`,
    en: `You should expect a minimum price of <strong>{minPrice}€</strong> for a journey <strong>{itinerary}</strong>.
    Depending on the date of your search on Tictactrip, the price for the journey {formattedTrip1} can go up to <strong>{maxPrice}€</strong> (on average, it costs <strong>{meanPrice}€</strong>).`,
    es: `Debe contar con un precio mínimo de <strong>{minPrice}€</strong> para un viaje <strong>{itinerary}</strong>.
    Dependiendo de la fecha de su búsqueda en Tictactrip, el precio del viaje {formattedTrip1} puede llegar hasta <strong>{maxPrice}€</strong> (en promedio, cuesta <strong>{meanPrice}€</strong>).`,
    it: `Dovrai considerare un prezzo minimo di <strong>{minPrice}€</strong> per un viaggio <strong>{itinerary}</strong>.
    In base alla data della tua ricerca su Tictactrip, il prezzo del viaggio {formattedTrip1} può arrivare fino a <strong>{maxPrice}€</strong> (in media, costa <strong>{meanPrice}€</strong>).`,
    de: `Sie müssen mit einem Mindestpreis von <strong>{minPrice}€</strong> für eine Fahrt <strong>{itinerary}</strong> rechnen.
    Abhängig vom Datum Ihrer Suche auf Tictactrip kann der Preis für die Fahrt {formattedTrip1} bis zu <strong>{maxPrice}€</strong> betragen (im Durchschnitt kostet er <strong>{meanPrice}€</strong>).`,
    nl: `U moet rekenen op een minimum prijs van <strong>{minPrice}€</strong> voor een reis <strong>{itinerary}</strong>.
    Afhankelijk van de datum van uw zoekopdracht op Tictactrip kan de prijs voor de reis {formattedTrip1} oplopen tot <strong>{maxPrice}€</strong> (gemiddeld kost het <strong>{meanPrice}€</strong>).`,
  },
  [SPECIFIC_TEXT_2_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_2_1}`,
    defaultMessage: `Le <strong>temps de trajet {formattedTrip2}</strong> est généralement compris entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.
    Celui-ci est effectué et proposé par {companies}.
    Il part en temps normal de {originStation} et arrive à {destinationStation}.
    `,
    en: `The <strong>travel time {formattedTrip2}</strong> is generally between <strong>{minDuration}</strong> and <strong>{maxDuration}</strong>.
    This is carried out and offered by {companies}.
    It usually departs from {originStation} and arrives at {destinationStation}.
    {intermediateStations}
    `,
    es: `El <strong>tiempo de viaje {formattedTrip2}</strong> suele estar comprendido entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>.
    Esto es realizado y ofrecido por {companies}.
    Normalmente parte de {originStation} y llega a {destinationStation}.
    {intermediateStations}
    `,
    it: `Il <strong>tempo di viaggio {formattedTrip2}</strong> è generalmente compreso tra <strong>{minDuration}</strong> e <strong>{maxDuration}</strong>.
    Questo è realizzato e offerto da {companies}.
    Di solito parte da {originStation} e arriva a {destinationStation}.
    {intermediateStations}
    `,
    de: `Die <strong>Reisezeit {formattedTrip2}</strong> liegt in der Regel zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>.
    Dies wird durchgeführt und angeboten von {companies}.
    Es startet normalerweise von {originStation} und kommt in {destinationStation} an.
    {intermediateStations}
    `,
    nl: `De <strong>reistijd {formattedTrip2}</strong> ligt doorgaans tussen <strong>{minDuration}</strong> en <strong>{maxDuration}</strong>.
    Dit wordt uitgevoerd en aangeboden door {companies}.
    Het vertrekt meestal vanuit {originStation} en komt aan in {destinationStation}.
    {intermediateStations}
    `,
  },
  [SPECIFIC_TEXT_2_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_2_2}`,
    defaultMessage: ` Le tout premier départ en <strong> {transportMean} {formattedTrip1}</strong> de la journée s'effectue à <strong>{firstDeparture}</strong> et le dernier est normalement à <strong>{lastDeparture}</strong>.
    La durée moyenne de ce voyage est de <strong>{meanDuration}</strong>.
    `,
    en: `The very first departure of <strong> {transportMean} {formattedTrip1}</strong> of the day takes place at <strong>{firstDeparture}</strong>, and the last one is usually at <strong>{lastDeparture}</strong>.
    The average duration of this journey is <strong>{meanDuration}</strong>.
    `,
    es: `La primera salida del día de <strong> {transportMean} {formattedTrip1}</strong> tiene lugar a las <strong>{firstDeparture}</strong>, y la última suele ser a las <strong>{lastDeparture}</strong>.
    La duración promedio de este viaje es de <strong>{meanDuration}</strong>.
    `,
    it: `La prima partenza del giorno di <strong> {transportMean} {formattedTrip1}</strong> avviene alle <strong>{firstDeparture}</strong>, e l'ultima è di solito alle <strong>{lastDeparture}</strong>.
    La durata media di questo viaggio è di <strong>{meanDuration}</strong>.
    `,
    de: `Die allererste Abfahrt von <strong> {transportMean} {formattedTrip1}</strong> des Tages findet um <strong>{firstDeparture}</strong> statt, und die letzte ist normalerweise um <strong>{lastDeparture}</strong>.
    Die durchschnittliche Dauer dieser Reise beträgt <strong>{meanDuration}</strong>.
    `,
    nl: `Het allereerste vertrek van <strong> {transportMean} {formattedTrip1}</strong> van de dag vindt plaats om <strong>{firstDeparture}</strong>, en de laatste is meestal om <strong>{lastDeparture}</strong>.
    De gemiddelde duur van deze reis is <strong>{meanDuration}</strong>.
    `,
  },

  [SPECIFIC_TEXT_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_3}`,
    defaultMessage: `Le trajet <strong>{itinerary}</strong> est proposé par les compagnies suivantes : {companies}.
    En moyenne, le temps de trajet <strong>{itinerary}</strong> est de <strong>{meanDuration}</strong> , allant de <strong>{minDuration}</strong> à <strong>{maxDuration}</strong> au maximum.
    Son prix fluctue souvent entre <strong>{minPrice}€</strong> et <strong>{maxPrice}€</strong>, pour, en moyenne, un <strong> prix {itinerary}</strong> de <strong>{meanPrice}€</strong>.`,
    en: `The journey <strong>{itinerary}</strong> is offered by the following companies: {companies}.
    On average, the travel time for <strong>{itinerary}</strong> is <strong>{meanDuration}</strong>, ranging from <strong>{minDuration}</strong> to <strong>{maxDuration}</strong> at most.
    The price often fluctuates between <strong>{minPrice}€</strong> and <strong>{maxPrice}€</strong>, with an average <strong>{itinerary}</strong> price of <strong>{meanPrice}€</strong>.`,
    es: `El trayecto <strong>{itinerary}</strong> es ofrecido por las siguientes compañías: {companies}.
    En promedio, el tiempo de viaje para <strong>{itinerary}</strong> es de <strong>{meanDuration}</strong>, oscilando entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong> como máximo.
    El precio suele fluctuar entre <strong>{minPrice}€</strong> y <strong>{maxPrice}€</strong>, con un precio promedio de <strong>{itinerary}</strong> de <strong>{meanPrice}€</strong>.`,
    it: `Il viaggio <strong>{itinerary}</strong> è offerto dalle seguenti compagnie: {companies}.
    In media, il tempo di viaggio per <strong>{itinerary}</strong> è di <strong>{meanDuration}</strong>, variando da <strong>{minDuration}</strong> a <strong>{maxDuration}</strong> al massimo.
    Il prezzo varia spesso tra <strong>{minPrice}€</strong> e <strong>{maxPrice}€</strong>, con un prezzo medio di <strong>{itinerary}</strong> di <strong>{meanPrice}€</strong>.`,
    de: `Die Reise <strong>{itinerary}</strong> wird von den folgenden Unternehmen angeboten: {companies}.
    Im Durchschnitt beträgt die Reisezeit für <strong>{itinerary}</strong> <strong>{meanDuration}</strong> und reicht von <strong>{minDuration}</strong> bis <strong>{maxDuration}</strong> höchstens.
    Der Preis schwankt oft zwischen <strong>{minPrice}€</strong> und <strong>{maxPrice}€</strong>, mit einem durchschnittlichen Preis von <strong>{itinerary}</strong> von <strong>{meanPrice}€</strong>.`,
    nl: `De reis <strong>{itinerary}</strong> wordt aangeboden door de volgende bedrijven: {companies}.
    Gemiddeld is de reistijd voor <strong>{itinerary}</strong> <strong>{meanDuration}</strong>, variërend van <strong>{minDuration}</strong> tot <strong>{maxDuration}</strong> op zijn hoogst.
    De prijs schommelt vaak tussen <strong>{minPrice}€</strong> en <strong>{maxPrice}€</strong>, met een gemiddelde prijs van <strong>{itinerary}</strong> van <strong>{meanPrice}€</strong>.`,
  },
  [SPECIFIC_TEXT_3_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_3_1}`,
    defaultMessage: ` <br> Au départ de {originStation}, vous arriverez normalement à {destinationStation}.
    `,
    en: ` <br> From {originStation}, you will normally arrive at {destinationStation}.
    {intermediateStations}
    `,
    es: ` <br> Desde {originStation}, normalmente llegarás a {destinationStation}.
    {intermediateStations}
    `,
    it: ` <br> Da {originStation}, normalmente arriverai a {destinationStation}.
    {intermediateStations}
    `,
    de: ` <br> Von {originStation} kommend, werden Sie normalerweise in {destinationStation} ankommen.
    {intermediateStations}
    `,
    nl: ` <br> Vanaf {originStation} arriveert u normaal gesproken in {destinationStation}.
    {intermediateStations}
    `,
  },
  [SPECIFIC_TEXT_3_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_3_2}`,
    defaultMessage: `Le tout premier départ journalier du <strong> {transportMean} {formattedTrip1}
    </strong> aura lieu à <strong>{firstDeparture}</strong> alors que le dernier sera à <strong>{lastDeparture}</strong>.`,
    en: `The first daily departure of <strong> {transportMean} {formattedTrip1}
  </strong> will take place at <strong>{firstDeparture}</strong> while the last one will be at <strong>{lastDeparture}</strong>.
    `,
    es: `La primera salida diaria de <strong> {transportMean} {formattedTrip1}
  </strong> será a <strong>{firstDeparture}</strong> mientras que la última será a <strong>{lastDeparture}</strong>.
    `,
    it: `La prima partenza giornaliera di <strong> {transportMean} {formattedTrip1}
  </strong> avrà luogo alle <strong>{firstDeparture}</strong> mentre l'ultima sarà alle <strong>{lastDeparture}</strong>.
    `,
    de: `Die erste tägliche Abfahrt von <strong> {transportMean} {formattedTrip1}
  </strong> findet um <strong>{firstDeparture}</strong> statt, während die letzte um <strong>{lastDeparture}</strong> sein wird.
    `,
    nl: `Het eerste dagelijkse vertrek van <strong> {transportMean} {formattedTrip1}
  </strong> vindt plaats om <strong>{firstDeparture}</strong>, terwijl de laatste om <strong>{lastDeparture}</strong> zal zijn.
    `,
  },

  [SPECIFIC_TEXT_4]: {
    id: `${searchScope}.${SPECIFIC_TEXT_4}`,
    defaultMessage: `Pour un aller {formattedTrip1} en {transportMean}, vous disposez d'un itinéraire {itinerary}
      dont le prix est compris entre <strong>{minPrice}€</strong> et <strong>{maxPrice}€</strong>, et la durée du
      voyage {formattedTrip2} comprise entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.`,
    en: `For a one-way {formattedTrip1} by {transportMean}, you have an {itinerary}
  route with a price ranging from <strong>{minPrice}€</strong> to <strong>{maxPrice}€</strong>, and the travel duration for {formattedTrip2} is between <strong>{minDuration}</strong> and <strong>{maxDuration}</strong>.`,
    es: 'Para un viaje de ida {formattedTrip1} en {transportMean}, tienes una ruta {itinerary} con un precio que oscila entre <strong>{minPrice}€</strong> y <strong>{maxPrice}€</strong>, y la duración del viaje para {formattedTrip2} está entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>.',
    it: `Per un viaggio di andata {formattedTrip1} in {transportMean}, hai un percorso {itinerary}
  con un prezzo che varia da <strong>{minPrice}€</strong> a <strong>{maxPrice}€</strong>, e la durata del viaggio per {formattedTrip2} è compresa tra <strong>{minDuration}</strong> e <strong>{maxDuration}</strong>.`,
    de: 'Für eine einfache Fahrt {formattedTrip1} mit {transportMean} haben Sie eine {itinerary}-Route mit einem Preis zwischen <strong>{minPrice}€</strong> und <strong>{maxPrice}€</strong> und einer Reisedauer von {formattedTrip2} zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>.',
    nl: 'Voor een enkele reis {formattedTrip1} met {transportMean} heb je een {itinerary}-route met een prijs variërend van <strong>{minPrice}€</strong> tot <strong>{maxPrice}€</strong>, en de reisduur voor {formattedTrip2} is tussen <strong>{minDuration}</strong> en <strong>{maxDuration}</strong>.',
  },
  [SPECIFIC_TEXT_4_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_4_1}`,
    defaultMessage:
      'Le prix moyen de réservation pour un billet {itinerary} est de <strong>{meanPrice}€</strong> pour  <strong>{meanDuration}</strong> de temps de trajet moyen. Le prix moyen de réservation pour un billet {itinerary} est de <strong>{meanPrice}€</strong> pour  <strong>{meanDuration}</strong> de temps de trajet moyen.',
    en: 'The average booking price for an {itinerary} ticket is <strong>{meanPrice}€</strong> for an average travel time of <strong>{meanDuration}</strong>.',
    es: 'El precio medio de reserva para un boleto {itinerary} es de <strong>{meanPrice}€</strong> para un tiempo de viaje promedio de <strong>{meanDuration}</strong>.',
    it: 'Il prezzo medio di prenotazione per un biglietto {itinerary} è di <strong>{meanPrice}€</strong> per un tempo di viaggio medio di <strong>{meanDuration}</strong>.',
    de: 'Der durchschnittliche Buchungspreis für ein {itinerary}-Ticket beträgt <strong>{meanPrice}€</strong> für eine durchschnittliche Reisezeit von <strong>{meanDuration}</strong>.',
    nl: 'De gemiddelde boekingsprijs voor een {itinerary}-ticket is <strong>{meanPrice}€</strong> voor een gemiddelde reistijd van <strong>{meanDuration}</strong>.',
  },
  [SPECIFIC_TEXT_4_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_4_2}`,
    defaultMessage: `{companySentence} ce trajet.
    Il part initialement de {originStation} et arrive à destination à {destinationStation}.`,
    en: `{companySentence} this journey.
    It initially departs from {originStation} and arrives at {destinationStation}.
    {intermediateStations}`,
    es: `{companySentence} este viaje.
    Inicialmente sale de {originStation} y llega a {destinationStation}.
    {intermediateStations}`,
    it: `{companySentence} questo viaggio.
    Parte inizialmente da {originStation} e arriva a {destinationStation}.
    {intermediateStations}`,
    de: `{companySentence} diese Reise.
    Es startet ursprünglich von {originStation} und kommt am {destinationStation} an.
    {intermediateStations}`,
    nl: `{companySentence} deze reis.
    Het vertrekt aanvankelijk vanaf {originStation} en komt aan op {destinationStation}.
    {intermediateStations}`,
  },
  [SPECIFIC_TEXT_4_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_4_3}`,
    defaultMessage:
      'Votre première option de la journée est un <strong>{transportMean} {formattedTrip1}</strong> qui part à <strong>{firstDeparture}</strong> alors que la dernière est au départ à <strong>{lastDeparture}</strong>',
    en: `Your first option of the day is a <strong>{transportMean} {formattedTrip1}</strong> departing at <strong>{firstDeparture}</strong> while the last one leaves at <strong>{lastDeparture}</strong>.
    `,
    es: `Su primera opción del día es un <strong>{transportMean} {formattedTrip1}</strong> que parte a las <strong>{firstDeparture}</strong> mientras que la última sale a las <strong>{lastDeparture}</strong>.
    `,
    it: `La tua prima opzione del giorno è un <strong>{transportMean} {formattedTrip1}</strong> che parte alle <strong>{firstDeparture}</strong> mentre l'ultima parte alle <strong>{lastDeparture}</strong>.
    `,
    de: `Ihre erste Option des Tages ist eine <strong>{transportMean} {formattedTrip1}</strong>, die um <strong>{firstDeparture}</strong> abfährt, während die letzte um <strong>{lastDeparture}</strong> abfährt.
    `,
    nl: `Uw eerste optie van de dag is een <strong>{transportMean} {formattedTrip1}</strong> die vertrekt om <strong>{firstDeparture}</strong>, terwijl de laatste vertrekt om <strong>{lastDeparture}</strong>.
    `,
  },
  [SPECIFIC_TEXT_5]: {
    id: `${searchScope}.${SPECIFIC_TEXT_5}`,
    defaultMessage: '{companySentence} un trajet <strong>{itinerary}</strong>.',
    en: '{companySentence} a <strong>{itinerary}</strong> journey.',
    es: '{companySentence} un viaje <strong>{itinerary}</strong>.',
    it: '{companySentence} un viaggio <strong>{itinerary}</strong>.',
    de: '{companySentence} eine <strong>{itinerary}</strong> Reise.',
    nl: '{companySentence} een <strong>{itinerary}</strong> reis.',
  },
  [SPECIFIC_TEXT_5_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_5_1}`,
    defaultMessage: `Vous voulez connaître le prix du trajet {formattedTrip1} ?
    Celui-ci est se trouve dans la fourchette entre <strong>{minPrice}€</strong> et <strong>{maxPrice}€</strong>.`,
    en: `Want to know the price of {formattedTrip1} journey?
    It falls in the range between <strong>{minPrice}€</strong> and <strong>{maxPrice}€</strong>.`,
    es: `¿Quieres saber el precio del viaje {formattedTrip1}?
    Está en el rango entre <strong>{minPrice}€</strong> y <strong>{maxPrice}€</strong>.`,
    it: `Vuoi sapere il prezzo del viaggio {formattedTrip1}?
    Si trova nell'intervallo tra <strong>{minPrice}€</strong> e <strong>{maxPrice}€</strong>.`,
    de: `Möchten Sie den Preis der Reise {formattedTrip1} wissen?
    Es liegt im Bereich zwischen <strong>{minPrice}€</strong> und <strong>{maxPrice}€</strong>.`,
    nl: `Wil je de prijs van de reis {formattedTrip1} weten?
    Het valt in het bereik van <strong>{minPrice}€</strong> tot <strong>{maxPrice}€</strong>.`,
  },
  [SPECIFIC_TEXT_5_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_5_2}`,
    defaultMessage: `La durée d'un trajet {formattedTrip2} est la plupart du temps comprise entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.<br>
    Son lieu de départ est située à {originStation}.
    Ce {transportMean} vous déposera ensuite à {destinationStation}.`,
    en: `The duration of a {formattedTrip2} journey is mostly between <strong>{minDuration}</strong> and <strong>{maxDuration}</strong>.<br>
    Its departure point is located at {originStation}.
    This {transportMean} will then drop you off at {destinationStation}.
    {intermediateStations}
      `,
    es: `La duración de un viaje {formattedTrip2} es en su mayoría entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>.<br>
    Su punto de partida está ubicado en {originStation}.
    Este {transportMean} luego te dejará en {destinationStation}.
    {intermediateStations}
      `,
    it: `La durata di un viaggio {formattedTrip2} è per lo più compresa tra <strong>{minDuration}</strong> e <strong>{maxDuration}</strong>.<br>
    Il suo punto di partenza è situato a {originStation}.
    Questo {transportMean} ti lascerà poi a {destinationStation}.
    {intermediateStations}
      `,
    de: `Die Dauer einer {formattedTrip2}-Reise liegt meist zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>.<br>
    Ihr Abfahrtsort befindet sich in {originStation}.
    Dieses {transportMean} bringt Sie dann nach {destinationStation}.
    {intermediateStations}
      `,
    nl: `De duur van een reis {formattedTrip2} ligt meestal tussen <strong>{minDuration}</strong> en <strong>{maxDuration}</strong>.<br>
    Het vertrekpunt bevindt zich op {originStation}.
    Dit {transportMean} zal je dan afzetten bij {destinationStation}.
    {intermediateStations}
      `,
  },
  [SPECIFIC_TEXT_5_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_5_3}`,
    defaultMessage: `Vous êtes plutôt matinal(e) ? Le tout premier trajet part à <strong>{firstDeparture}</strong>.
    Si, au contraire, vous préférez voyager de nuit ou partir en fin de journée, le tout dernier départ a lieu à <strong>{lastDeparture}</strong>.`,
    en: `Are you an early bird? The very first trip departs at <strong>{firstDeparture}</strong>.
    If, on the contrary, you prefer to travel at night or leave late in the day, the very last departure is at <strong>{lastDeparture}</strong>.`,
    es: `¿Eres madrugador(a)? El primer viaje sale a las <strong>{firstDeparture}</strong>.
    Si, por el contrario, prefieres viajar de noche o salir tarde en el día, el último viaje es a las <strong>{lastDeparture}</strong>.`,
    it: `Sei un mattiniero? Il primo viaggio parte alle <strong>{firstDeparture}</strong>.
    Se, al contrario, preferisci viaggiare di notte o partire tardi nel giorno, l'ultimo viaggio è alle <strong>{lastDeparture}</strong>.`,
    de: `Bist du ein Frühaufsteher? Die allererste Reise beginnt um <strong>{firstDeparture}</strong>.
    Wenn du hingegen lieber nachts reist oder spät am Tag abreist, erfolgt die allerletzte Abfahrt um <strong>{lastDeparture}</strong>.`,
    nl: `Ben je een vroege vogel? De allereerste reis vertrekt om <strong>{firstDeparture}</strong>.
    Als je daarentegen liever 's nachts reist of laat op de dag vertrekt, is de allerlaatste vertrek om <strong>{lastDeparture}</strong>.`,
  },
  [SPECIFIC_TEXT_DAILY_TRIPS_1]: {
    id: `${searchScope}.${SPECIFIC_TEXT_DAILY_TRIPS_1}`,
    defaultMessage:
      'En fonction des jours, vous aurez un choix de <strong>{dailyTrips} {transportMean} {formattedTrip}</strong> par jour.',
    en: 'Depending on the days, you will have a choice of <strong>{dailyTrips} {transportMean} {formattedTrip}</strong> per day.',
    es: 'Según los días, tendrás la opción de <strong>{dailyTrips} {transportMean} {formattedTrip}</strong> por día.',
    it: 'In base ai giorni, avrai la scelta di <strong>{dailyTrips} {transportMean} {formattedTrip}</strong> al giorno.',
    de: 'Je nach den Tagen haben Sie die Wahl zwischen <strong>{dailyTrips} {transportMean} {formattedTrip}</strong> pro Tag.',
    nl: 'Afhankelijk van de dagen heb je de keuze uit <strong>{dailyTrips} {transportMean} {formattedTrip}</strong> per dag.',
  },
  [SPECIFIC_TEXT_DAILY_TRIPS_2]: {
    id: `${searchScope}.${SPECIFIC_TEXT_DAILY_TRIPS_2}`,
    defaultMessage:
      'En règle général, il y a chaque jour <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    en: 'Generally, there is every day <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    es: 'Generalmente, hay cada día <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    it: "In generale, c'è ogni giorno <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.",
    de: 'Im Allgemeinen gibt es jeden Tag <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    nl: 'Over het algemeen is er elke dag <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
  },
  [SPECIFIC_TEXT_DAILY_TRIPS_3]: {
    id: `${searchScope}.${SPECIFIC_TEXT_DAILY_TRIPS_3}`,
    defaultMessage:
      'Chaque jour, vous avez le choix entre <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    en: 'Every day, you have the choice between <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    es: 'Cada día, tienes la opción de <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    it: 'Ogni giorno, hai la scelta tra <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    de: 'Jeden Tag haben Sie die Wahl zwischen <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
    nl: 'Elke dag heb je de keuze uit <strong>{dailyTrips} {transportMean} {formattedTrip}</strong>.',
  },
  priceFaqHeader: {
    id: `${searchScope}.priceFaqHeader`,
    defaultMessage:
      "Quel est le prix d'un billet de {transportMean} {origin} {destination} ?",
    en: 'How much does a {transportMean} {origin} {destination} cost?',
    es: '¿Cuánto cuesta un boleto de {transportMean} {origin} {destination}?',
    it: 'Quanto costa un biglietto {transportMean} {origin} {destination}?',
    de: 'Wie viel kostet eine {transportMean} {origin} {destination} Fahrkarte?',
    nl: 'Hoeveel kost een {transportMean} {origin} {destination} kaartje?',
  },
  priceFaqHeader2: {
    id: `${searchScope}.priceFaqHeader2`,
    defaultMessage:
      'Combien coûte un billet de {transportMean} de {origin} à {destination} ?',
    en: 'How much does a {transportMean} ticket from {origin} to {destination} cost?',
    it: 'Quanto costa un biglietto {transportMean} da {origin} a {destination}?',
    es: '¿Cuánto cuesta un billete de {transportMean} de {origin} a {destination}?',
    de: 'Wie viel kostet ein {transportMean}-Ticket von {origin} nach {destination}?',
    nl: 'Hoeveel kost een {transportMean}-ticket van {origin} naar {destination}?',
  },
  durationFaqHeader: {
    id: `${searchScope}.durationFaqHeader`,
    defaultMessage:
      "Quelle est la durée d'un trajet {origin} {destination} en {transportMean} ?",
    en: 'How long is the trip {origin} {destination} by {transportMean}?',
    es: '¿Cuánto dura el viaje {origin} {destination} en {transportMean}?',
    it: 'Quanto dura il viaggio {origin} {destination} in {transportMean}?',
    de: 'Wie lange dauert die Reise {origin} {destination} mit {transportMean}?',
    nl: 'Hoe lang duurt de reis {origin} {destination} met {transportMean}?',
  },
  durationFaqHeader2: {
    id: `${searchScope}.durationFaqHeader2`,
    defaultMessage:
      'Combien de temps dure le voyage de {origin} à {destination} en {transportMean} ?',
    en: 'How long does the trip from {origin} to {destination} take by {transportMean}?',
    it: 'Quanto tempo impiega il viaggio da {origin} a {destination} in {transportMean}?',
    es: '¿Cuánto tiempo tarda el viaje de {origin} a {destination} en {transportMean}?',
    de: 'Wie lange dauert die Reise von {origin} nach {destination} mit {transportMean}?',
    nl: 'Hoe lang duurt de reis van {origin} naar {destination} met {transportMean}?',
  },
  departureFaqHeader: {
    id: `${searchScope}.departureFaqHeader`,
    defaultMessage:
      'À quelle heure part le {transportMean} {origin} {destination} ?',
    en: 'When is the first {transportMean} {origin} {destination} leaving?',
    es: '¿A qué hora sale el {transportMean} {origin} {destination}?',
    it: 'A che ora parte il {transportMean} {origin} {destination}?',
    de: 'Wann fährt der {transportMean} {origin} {destination} ab?',
    nl: 'Wanneer vertrekt de {transportMean} {origin} {destination}?',
  },
  departureFaqHeader2: {
    id: `${searchScope}.departureFaqHeader2`,
    defaultMessage:
      "Quel est l'horaire de départ du {transportMean} de {origin} à {destination} ?",
    en: 'What is the departure time of the {transportMean} from {origin} to {destination}?',
    it: "Qual è l'orario di partenza del {transportMean} da {origin} a {destination}?",
    es: '¿Cuál es la hora de salida del {transportMean} de {origin} a {destination}?',
    de: 'Was ist die Abfahrtszeit des {transportMean} von {origin} nach {destination}?',
    nl: 'Wat is de vertrektijd van de {transportMean} van {origin} naar {destination}?',
  },
  dailyTripsFaqHeader: {
    id: `${searchScope}.dailyTripsFaqHeader`,
    defaultMessage:
      "Combien y a t'il de trajets {origin} {destination} en {transportMean} par jour ?",
    en: 'How many {transportMean} {origin} {destination} are there every day ?',
    es: '¿Cuántos viajes en {transportMean} hay de {origin} a {destination} cada día?',
    it: 'Quanti viaggi in {transportMean} ci sono da {origin} a {destination} ogni giorno?',
    de: 'Wie viele {transportMean}-Fahrten gibt es täglich von {origin} nach {destination}?',
    nl: 'Hoeveel {transportMean}-reizen zijn er dagelijks van {origin} naar {destination}?',
  },
  dailyTripsFaqHeader2: {
    id: `${searchScope}.dailyTripsFaqHeader2`,
    defaultMessage:
      'Combien de {transportMean} {origin} {destination} par jour ?',
    en: 'How many {transportMean} are there from {origin} to {destination} per day?',
    it: 'Quanti {transportMean} ci sono da {origin} a {destination} al giorno?',
    es: '¿Cuántos {transportMean} hay de {origin} a {destination} por día?',
    de: 'Wie viele {transportMean} gibt es von {origin} nach {destination} pro Tag?',
    nl: 'Hoeveel {transportMean} zijn er van {origin} naar {destination} per dag?',
  },

  [SHORT_FAQ_PRICE_1]: {
    id: `${searchScope}.${SHORT_FAQ_PRICE_1}`,
    defaultMessage: `Le prix minimum d'un <strong>trajet en {transportMean} {formattedTrip1}</strong> est de <strong>{minPrice} €</strong>.
    En fonction du nombre de jours à l'avance de votre recherche, le prix peut monter jusqu'à <strong>{maxPrice} €</strong> si vous vous y prenez au tout dernier moment.
    En moyenne, un <strong>billet de {transportMean} {formattedTrip2}</strong> coûte <strong>{meanPrice} €</strong>.`,
    en: `The minimum price for a <strong>{transportMean} {formattedTrip1} journey</strong> is <strong>{minPrice} €</strong>.
    Depending on the number of days in advance of your search, the price can go up to <strong>{maxPrice} €</strong> if you wait until the last moment.
    On average, a <strong>{transportMean} {formattedTrip2} ticket</strong> costs <strong>{meanPrice} €</strong>.
    `,
    es: `Le prix minimum d'un <strong>trajet en {transportMean} {formattedTrip1} </strong> est de <strong>{minPrice} €</strong>.
    Selon le nombre de jours à l'avance de votre recherche, le prix peut monter jusqu'à <strong>{maxPrice} €</strong> si vous attendez jusqu'au dernier moment.
    En moyenne, un <strong>billet de {transportMean} {formattedTrip2}</strong> coûte <strong>{meanPrice} €</strong>.
    `,
    it: `Il prezzo minimo per un <strong>viaggio in {transportMean} {formattedTrip1}</strong> è di <strong>{minPrice} €</strong>.
    A seconda del numero di giorni in anticipo della tua ricerca, il prezzo può salire fino a <strong>{maxPrice} €</strong> se aspetti fino all'ultimo momento.
    In media, un <strong>biglietto per {transportMean} {formattedTrip2}</strong> costa <strong>{meanPrice} €</strong>.
    `,
    de: `Der Mindestpreis für eine <strong>{transportMean} {formattedTrip1}-Reise</strong> beträgt <strong>{minPrice} €</strong>.
    Abhängig von der Anzahl der Tage im Voraus Ihrer Suche kann der Preis bis zu <strong>{maxPrice} €</strong> steigen, wenn Sie bis zum letzten Moment warten.
    Im Durchschnitt kostet eine <strong>{transportMean} {formattedTrip2}-Fahrkarte</strong> <strong>{meanPrice} €</strong>.
    `,
    nl: `De minimumprijs voor een <strong>reis in {transportMean} {formattedTrip1}</strong> is <strong>{minPrice} €</strong>.
    Afhankelijk van het aantal dagen voorafgaand aan uw zoekopdracht kan de prijs oplopen tot <strong>{maxPrice} €</strong> als u tot het laatste moment wacht.
    Gemiddeld kost een <strong>{transportMean} {formattedTrip2}-ticket</strong> <strong>{meanPrice} €</strong>.
    `,
  },
  [SHORT_FAQ_PRICE_2]: {
    id: `${searchScope}.${SHORT_FAQ_PRICE_2}`,
    defaultMessage: `Votre <strong>trajet en {transportMean} {formattedTrip1}</strong> vous reviendra à un prix minimum de <strong>{minPrice} €</strong>.
    En moyenne, vous pourrez réserver votre billet de {transportMean} pour ce voyage à <strong>{meanPrice} €</strong>, alors que celui-ci peut monter, en période d'inflation à <strong>{maxPrice} €</strong>.
    Le prix de ce trajet dépend en partie du moment où vous faites votre recherche et votre réservation. Plus vous vous y prendrez à l'avance, mieux votre porte-monnaie se portera !`,
    en: `Your <strong>{transportMean} {formattedTrip1} journey</strong> will cost you a minimum of <strong>{minPrice} €</strong>.
    On average, you can book your {transportMean} ticket for this trip at <strong>{meanPrice} €</strong>, while it can rise, during peak times, to <strong>{maxPrice} €</strong>.
    The price of this journey depends in part on when you conduct your search and make your reservation. The earlier you do it, the better for your wallet!
    `,
    es: `Tu <strong>viaje en {transportMean} {formattedTrip1}</strong> te costará un mínimo de <strong>{minPrice} €</strong>.
    En promedio, podrás reservar tu boleto de {transportMean} para este viaje por <strong>{meanPrice} €</strong>, aunque puede aumentar, en períodos de inflación, a <strong>{maxPrice} €</strong>.
    El precio de este viaje depende en parte de cuándo realices tu búsqueda y hagas tu reserva. ¡Cuanto antes lo hagas, mejor para tu bolsillo!
    `,
    it: `Il tuo <strong>viaggio in {transportMean} {formattedTrip1}</strong> costerà almeno <strong>{minPrice} €</strong>.
    In media, puoi prenotare il tuo biglietto di {transportMean} per questo viaggio a <strong>{meanPrice} €</strong>, mentre può salire, durante i periodi di punta, a <strong>{maxPrice} €</strong>.
    Il prezzo di questo viaggio dipende in parte da quando fai la tua ricerca e prenoti. Prima lo fai, meglio è per il tuo portafoglio!
    `,
    de: `Ihre <strong>{transportMean} {formattedTrip1}-Reise</strong> wird Sie mindestens <strong>{minPrice} €</strong> kosten.
    Im Durchschnitt können Sie Ihr {transportMean}-Ticket für diese Reise für <strong>{meanPrice} €</strong> buchen, während es in Spitzenzeiten auf <strong>{maxPrice} €</strong> steigen kann.
    Der Preis dieser Reise hängt teilweise davon ab, wann Sie Ihre Suche durchführen und Ihre Reservierung vornehmen. Je früher Sie es tun, desto besser für Ihre Brieftasche!
    `,
    nl: `Uw <strong>reis in {transportMean} {formattedTrip1}</strong> kost u minimaal <strong>{minPrice} €</strong>.
    Gemiddeld kunt u uw {transportMean}-ticket voor deze reis boeken voor <strong>{meanPrice} €</strong>, terwijl het tijdens piekuren kan stijgen tot <strong>{maxPrice} €</strong>.
    De prijs van deze reis hangt mede af van wanneer u uw zoekopdracht uitvoert en uw reservering maakt. Hoe eerder u het doet, hoe beter voor uw portemonnee!
    `,
  },
  [SHORT_FAQ_PRICE_3]: {
    id: `${searchScope}.${SHORT_FAQ_PRICE_3}`,
    defaultMessage: `Comptez au moins <strong>{minPrice} €</strong> pour un <strong>{transportMean} {formattedTrip1}</strong>.
    En fonction de la période de votre recherche, le prix du <strong>trajet en {transportMean} {formattedTrip2}</strong> peut monter jusqu'à <strong>{maxPrice} €</strong>.
    Le prix moyen du billet est généralement de <strong>{meanPrice} €</strong>.`,
    en: `Expect at least <strong>{minPrice} €</strong> for a <strong>{transportMean} {formattedTrip1}</strong>.
    Depending on the period of your search, the price of the <strong>{formattedTrip2} journey</strong> can rise to <strong>{maxPrice} €</strong>.
    A <strong>{transportMean} {formattedTrip3}</strong> costs an average of <strong>{meanPrice} €</strong>.
    `,
    es: `Cuenta con al menos <strong>{minPrice} €</strong> para un <strong>{transportMean} {formattedTrip1}</strong>.
    Según el periodo de tu búsqueda, el precio del <strong>viaje {formattedTrip2}</strong> puede llegar hasta <strong>{maxPrice} €</strong>.
    Un <strong>{transportMean} {formattedTrip3}</strong> tiene un costo promedio de <strong>{meanPrice} €</strong>.
    `,
    it: `Prevedi almeno <strong>{minPrice} €</strong> per un <strong>{transportMean} {formattedTrip1}</strong>.
    A seconda del periodo della tua ricerca, il prezzo del <strong>viaggio {formattedTrip2}</strong> può salire fino a <strong>{maxPrice} €</strong>.
    Un <strong>{transportMean} {formattedTrip3}</strong> costa in media <strong>{meanPrice} €</strong>.
    `,
    de: `Rechnen Sie mit mindestens <strong>{minPrice} €</strong> für eine <strong>{transportMean} {formattedTrip1}</strong>.
    Abhängig von der Periode Ihrer Suche kann der Preis der <strong>{formattedTrip2}-Reise</strong> auf bis zu <strong>{maxPrice} €</strong> steigen.
    Ein <strong>{transportMean} {formattedTrip3}</strong> kostet im Durchschnitt <strong>{meanPrice} €</strong>.
    `,
    nl: `Reken op minimaal <strong>{minPrice} €</strong> voor een <strong>{transportMean} {formattedTrip1}</strong>.
    Afhankelijk van de periode van je zoekopdracht kan de prijs van de <strong>{formattedTrip2}-reis</strong> oplopen tot <strong>{maxPrice} €</strong>.
    Een <strong>{transportMean} {formattedTrip3}</strong> kost gemiddeld <strong>{meanPrice} €</strong>.
    `,
  },
  [SHORT_FAQ_DURATION_1]: {
    id: `${searchScope}.${SHORT_FAQ_DURATION_1}`,
    defaultMessage: `La durée minimale d'un trajet en <strong>{transportMean} {formattedTrip1} </strong> est de <strong>{minDuration}</strong>.
    La durée maximale du voyage est quant à elle de <strong>{maxDuration}</strong>.
    En moyenne, un <strong>trajet {formattedTrip2} en {transportMean}</strong> dure <strong>{meanDuration}</strong>.`,
    en: `The minimum duration of a journey on <strong>{transportMean} {formattedTrip1}</strong> is <strong>{minDuration}</strong>.
    The maximum duration, on the other hand, is <strong>{maxDuration}</strong>.
    On average, a <strong>{formattedTrip2} journey by {transportMean}</strong> takes <strong>{meanDuration}</strong>.
    `,
    es: `La duración mínima de un viaje en <strong>{transportMean} {formattedTrip1}</strong> es de <strong>{minDuration}</strong>.
    La duración máxima, por otro lado, es de <strong>{maxDuration}</strong>.
    En promedio, un <strong>viaje {formattedTrip2} en {transportMean}</strong> dura <strong>{meanDuration}</strong>.
    `,
    it: `La durata minima di un viaggio in <strong>{transportMean} {formattedTrip1}</strong> è di <strong>{minDuration}</strong>.
    La durata massima, d'altra parte, è di <strong>{maxDuration}</strong>.
    In media, un <strong>viaggio {formattedTrip2} in {transportMean}</strong> dura <strong>{meanDuration}</strong>.
    `,
    de: `Die minimale Dauer einer Reise mit <strong>{transportMean} {formattedTrip1}</strong> beträgt <strong>{minDuration}</strong>.
    Die maximale Dauer hingegen beträgt <strong>{maxDuration}</strong>.
    Im Durchschnitt dauert eine <strong>{formattedTrip2}-Reise mit {transportMean}</strong> <strong>{meanDuration}</strong>.
    `,
    nl: `De minimale duur van een reis met <strong>{transportMean} {formattedTrip1}</strong> is <strong>{minDuration}</strong>.
    De maximale duur is daarentegen <strong>{maxDuration}</strong>.
    Gemiddeld duurt een <strong>{formattedTrip2}-reis met {transportMean}</strong> <strong>{meanDuration}</strong>.
    `,
  },
  [SHORT_FAQ_DURATION_2]: {
    id: `${searchScope}.${SHORT_FAQ_DURATION_2}`,
    defaultMessage: `Votre <strong>trajet en {transportMean} {formattedTrip1} </strong> sera long d'au minimum <strong>{minDuration}</strong>.
    En moyenne, attendez vous à ce que ce trajet dure <strong>{meanDuration}</strong>, avec un temps de trajet maximum de <strong>{maxDuration}</strong>.`,
    en: `Your <strong>{transportMean} {formattedTrip1} journey</strong> will be at least <strong>{minDuration}</strong> long.
    On average, expect this journey to last <strong>{meanDuration}</strong>, with a maximum travel time of <strong>{maxDuration}</strong>.
    `,
    es: `Su <strong>viaje en {transportMean} {formattedTrip1}</strong> será de al menos <strong>{minDuration}</strong>.
    En promedio, espere que este viaje dure <strong>{meanDuration}</strong>, con un tiempo de viaje máximo de <strong>{maxDuration}</strong>.
    `,
    it: `Il vostro <strong>viaggio in {transportMean} {formattedTrip1}</strong> sarà lungo almeno <strong>{minDuration}</strong>.
    In media, prevedete che questo viaggio duri <strong>{meanDuration}</strong>, con un tempo di percorrenza massimo di <strong>{maxDuration}</strong>.
    `,
    de: `Ihre <strong>{transportMean} {formattedTrip1}-Reise</strong> dauert mindestens <strong>{minDuration}</strong>.
    Im Durchschnitt erwarten Sie, dass diese Reise <strong>{meanDuration}</strong> dauert, mit einer maximalen Reisezeit von <strong>{maxDuration}</strong>.
    `,
    nl: `Uw <strong>reis met {transportMean} {formattedTrip1}</strong> duurt minimaal <strong>{minDuration}</strong>.
    Gemiddeld verwacht u dat deze reis <strong>{meanDuration}</strong> duurt, met een maximale reistijd van <strong>{maxDuration}</strong>.
    `,
  },
  [SHORT_FAQ_DURATION_3]: {
    id: `${searchScope}.${SHORT_FAQ_DURATION_3}`,
    defaultMessage:
      "Vous pouvez compter au moins <strong>{minDuration}</strong> pour un <strong>{transportMean} {formattedTrip1}</strong>. Votre voyage de {origin} à {destination} peut durer jusqu'à <strong>{maxDuration}</strong> en fonction de l'heure de la journée et de la compagnie de {transportMean} que vous choisissez. Votre <strong>trajet {formattedTrip2} en {transportMean}</strong> dure en moyenne <strong>{meanDuration}</strong>.",
    en: 'You can expect at least <strong>{minDuration}</strong> for a <strong>{transportMean} {formattedTrip1}</strong>. Your trip from {origin} to {destination} can take up to <strong>{maxDuration}</strong> depending on the time of day and the {transportMean} company you choose. Your <strong>{formattedTrip2} trip in {transportMean}</strong> lasts on average <strong>{meanDuration}</strong>.',
    it: "Puoi aspettarti almeno <strong>{minDuration}</strong> per un <strong>{transportMean} {formattedTrip1}</strong>. Il tuo viaggio da {origin} a {destination} può durare fino a <strong>{maxDuration}</strong> a seconda dell'ora del giorno e della compagnia di {transportMean} che scegli. Il tuo viaggio <strong>{formattedTrip2} in {transportMean}</strong> dura in media <strong>{meanDuration}</strong>.",
    es: 'Puedes esperar al menos <strong>{minDuration}</strong> para un <strong>{transportMean} {formattedTrip1}</strong>. Tu viaje de {origin} a {destination} puede durar hasta <strong>{maxDuration}</strong> dependiendo de la hora del día y de la compañía de {transportMean} que elijas. Tu viaje <strong>{formattedTrip2} en {transportMean}</strong> dura en promedio <strong>{meanDuration}</strong>.',
    de: 'Sie können mit mindestens <strong>{minDuration}</strong> für eine <strong>{transportMean} {formattedTrip1}</strong> rechnen. Ihre Reise von {origin} nach {destination} kann je nach Tageszeit und der {transportMean}-Firma, die Sie wählen, bis zu <strong>{maxDuration}</strong> dauern. Ihre <strong>{formattedTrip2}-Reise in {meanDuration}</strong> dauert im Durchschnitt <strong>{meanDuration}</strong>.',
    nl: 'U kunt minimaal <strong>{minDuration}</strong> verwachten voor een <strong>{transportMean} {formattedTrip1}</strong>. Uw reis van {origin} naar {destination} kan tot <strong>{maxDuration}</strong> duren, afhankelijk van het tijdstip van de dag en het {transportMean}-bedrijf dat u kiest. Uw <strong>{formattedTrip2}-reis in {transportMean}</strong> duurt gemiddeld <strong>{meanDuration}</strong>.',
  },
  [SHORT_FAQ_DEPARTURE_1]: {
    id: `${searchScope}.${SHORT_FAQ_DEPARTURE_1}`,
    defaultMessage:
      'Le premier <strong>{transportMean} {formattedTrip1}</strong> part à <strong>{firstDeparture}</strong> alors que le dernier part à <strong>{lastDeparture}</strong>.',
    en: `The first <strong>{transportMean} {formattedTrip1}</strong> departs at <strong>{firstDeparture}</strong>, while the last one leaves at <strong>{lastDeparture}</strong>.
    `,
    es: `El primer <strong>{transportMean} {formattedTrip1}</strong> parte a las <strong>{firstDeparture}</strong>, mientras que el último sale a las <strong>{lastDeparture}</strong>.
    `,
    it: `Il primo <strong>{transportMean} {formattedTrip1}</strong> parte alle <strong>{firstDeparture}</strong>, mentre l'ultimo parte alle <strong>{lastDeparture}</strong>.
    `,
    de: `Der erste <strong>{transportMean} {formattedTrip1}</strong> fährt um <strong>{firstDeparture}</strong>, während der letzte um <strong>{lastDeparture}</strong> abfährt.
    `,
    nl: `De eerste <strong>{transportMean} {formattedTrip1}</strong> vertrekt om <strong>{firstDeparture}</strong>, terwijl de laatste om <strong>{lastDeparture}</strong> vertrekt.
    `,
  },
  [SHORT_FAQ_DEPARTURE_2]: {
    id: `${searchScope}.${SHORT_FAQ_DEPARTURE_2}`,
    defaultMessage: `Le tout premier trajet en <strong>{transportMean} {formattedTrip1}</strong> part à <strong>{firstDeparture}</strong>.
    Si vous voulez partir plus tard,vous pouvez toujours monter à bord de votre <strong>{transportMean} {formattedTrip2}</strong> à <strong>{lastDeparture}</strong>.
    C'est là le dernier horaire journalier pour ce trajet.`,
    en: `The very first journey by <strong>{transportMean} {formattedTrip1}</strong> departs at <strong>{firstDeparture}</strong>.
    If you want to leave later, you can still take a <strong>{transportMean} {formattedTrip2}</strong> at <strong>{lastDeparture}</strong>.
    This is the last daily schedule for this journey.`,
    es: `El primer viaje en <strong>{transportMean} {formattedTrip1}</strong> sale a las <strong>{firstDeparture}</strong>.
    Si desea salir más tarde, aún puede tomar un <strong>{transportMean} {formattedTrip2}</strong> a las <strong>{lastDeparture}</strong>.
    Este es el último horario diario para este viaje.`,
    it: `Il primo viaggio in <strong>{transportMean} {formattedTrip1}</strong> parte alle <strong>{firstDeparture}</strong>.
    Se vuoi partire più tardi, puoi comunque prendere un <strong>{transportMean} {formattedTrip2}</strong> alle <strong>{lastDeparture}</strong>.
    Questo è l'ultimo orario giornaliero per questo viaggio.`,
    de: `Die allererste Fahrt mit <strong>{transportMean} {formattedTrip1}</strong> startet um <strong>{firstDeparture}</strong>.
    Wenn Sie später fahren möchten, können Sie immer noch eine <strong>{transportMean} {formattedTrip2}</strong> um <strong>{lastDeparture}</strong> nehmen.
    Dies ist der letzte tägliche Fahrplan für diese Fahrt.`,
    nl: `De allereerste rit met <strong>{transportMean} {formattedTrip1}</strong> vertrekt om <strong>{firstDeparture}</strong>.
    Als u later wilt vertrekken, kunt u nog steeds een <strong>{transportMean} {formattedTrip2}</strong> nemen om <strong>{lastDeparture}</strong>.
    Dit is het laatste dagelijkse schema voor deze rit.`,
  },
  [SHORT_FAQ_DEPARTURE_3]: {
    id: `${searchScope}.${SHORT_FAQ_DEPARTURE_3}`,
    defaultMessage:
      'Vous souhaitez connaître les premiers et derniers horaires de départ de votre {transportMean} {formattedTrip1} ? Vous êtes au bon endroit ! Chaque jour, le premier départ du <strong>{transportMean} {formattedTrip2}</strong> a lieu à <strong>{firstDeparture}</strong>. Au contraire, le tout dernier départ se fait à <strong>{lastDeparture}</strong>. Vous êtes plutôt matinal(e) ou toujours à partir au dernier moment ?',
    en: "Would you like to know the first and last departure times of your {transportMean} {formattedTrip1}? You're in the right place! Every day, the first departure of the <strong>{transportMean} {formattedTrip2}</strong> takes place at <strong>{firstDeparture}</strong>. Conversely, the very last departure is at <strong>{lastDeparture}</strong>. Are you more of a morning person or always leaving at the last minute?",
    it: "Vuoi conoscere i primi e gli ultimi orari di partenza del tuo {transportMean} {formattedTrip1}? Sei nel posto giusto! Ogni giorno, la prima partenza del <strong>{transportMean} {formattedTrip2}</strong> avviene alle <strong>{firstDeparture}</strong>. Al contrario, l'ultima partenza è alle <strong>{lastDeparture}</strong>. Sei più un tipo mattiniero o sei sempre in ritardo?",
    es: '¿Le gustaría conocer los primeros y últimos horarios de salida de su {transportMean} {formattedTrip1}? ¡Estás en el lugar correcto! Todos los días, el primer viaje del <strong>{transportMean} {formattedTrip2}</strong> tiene lugar a las <strong>{firstDeparture}</strong>. Por el contrario, el último viaje es a las <strong>{lastDeparture}</strong>. ¿Eres más de madrugar o siempre sales en el último momento?',
    de: 'Möchten Sie die ersten und letzten Abfahrtszeiten Ihres {transportMean} {formattedTrip1} erfahren? Sie sind hier richtig! Jeden Tag findet die erste Abfahrt des <strong>{transportMean} {formattedTrip2}</strong> um <strong>{firstDeparture}</strong> statt. Im Gegenteil, die allerletzte Abfahrt erfolgt um <strong>{lastDeparture}</strong>. Sind Sie eher ein Frühaufsteher oder verlassen Sie sich immer auf den letzten Moment?',
    nl: 'Wilt u de eerste en laatste vertrektijden van uw {transportMean} {formattedTrip1} weten? U bent hier aan het juiste adres! Elke dag vindt de eerste vertrek van de <strong>{transportMean} {formattedTrip2}</strong> plaats om <strong>{firstDeparture}</strong>. Daarentegen vindt het allerlaatste vertrek plaats om <strong>{lastDeparture}</strong>. Bent u meer een ochtendmens of vertrekt u altijd op het laatste moment?',
  },
  [SHORT_FAQ_DAILY_TRIPS_1]: {
    id: `${searchScope}.${SHORT_FAQ_DAILY_TRIPS_1}`,
    defaultMessage:
      'Chaque jour, il y a environ <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong> qui circulent.',
    en: 'Every day, there are approximately <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong>.',
    es: 'Cada día, hay aproximadamente <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong>.',
    it: 'Ogni giorno, ci sono circa <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong>.',
    de: 'Jeden Tag gibt es ungefähr <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong>.',
    nl: 'Elke dag zijn er ongeveer <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong>.',
  },
  [SHORT_FAQ_DAILY_TRIPS_2]: {
    id: `${searchScope}.${SHORT_FAQ_DAILY_TRIPS_2}`,
    defaultMessage:
      "Chaque jour, vous avez le choix entre <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong> qui circulent. Autrement dit, vous avez l'embarras du choix des horaires de départ et d'arrivée. Mais attention, car le prix des billets varie également en fonction !",
    en: 'Every day, you have the choice of <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong> that are in circulation. In other words, you have plenty of departure and arrival times to choose from. But be careful, as the ticket prices also vary accordingly!',
    it: 'Ogni giorno hai la possibilità di scegliere tra <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong> che circolano. In altre parole, hai molte opzioni di orari di partenza e arrivo tra cui scegliere. Ma attenzione, perché anche i prezzi dei biglietti variano di conseguenza!',
    es: 'Cada día tienes la opción de <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong> que circulan. En otras palabras, tienes muchas opciones de horarios de salida y llegada para elegir. ¡Pero cuidado, ya que los precios de los billetes también varían en consecuencia!',
    de: 'Jeden Tag haben Sie die Wahl zwischen <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong>, die verkehren. Mit anderen Worten, Sie haben eine Vielzahl von Abfahrts- und Ankunftszeiten zur Auswahl. Aber Vorsicht, denn auch die Ticketpreise variieren entsprechend!',
    nl: 'Elke dag heeft u de keuze uit <strong>{dailyTrips} {transportMean} {formattedTrip1}</strong> die circuleren. Met andere woorden, u heeft volop vertrek- en aankomsttijden om uit te kiezen. Maar let op, want de ticketprijzen variëren ook dienovereenkomstig!',
  },
  [SHORT_FAQ_DAILY_TRIPS_3]: {
    id: `${searchScope}.${SHORT_FAQ_DAILY_TRIPS_3}`,
    defaultMessage:
      "{dailyTrips} <strong>{transportMean}s {formattedTrip1}</strong> circulent chaque jour. Vous n'avez pas d'excuse pour ne pas voyager.",
    en: '{dailyTrips} <strong>{transportMean}s {formattedTrip1}</strong> circulate every day. You have no excuse not to travel.',
    it: '{dailyTrips} <strong>{transportMean}s {formattedTrip1}</strong> circolano ogni giorno. Non hai scuse per non viaggiare.',
    es: '{dailyTrips} <strong>{transportMean}s {formattedTrip1}</strong> circulan todos los días. No tienes excusa para no viajar.',
    de: '{dailyTrips} <strong>{transportMean}s {formattedTrip1}</strong> verkehren jeden Tag. Sie haben keine Ausrede, nicht zu reisen.',
    nl: '{dailyTrips} <strong>{transportMean}s {formattedTrip1}</strong> circuleren elke dag. Je hebt geen excuus om niet te reizen.',
  },
  [BUS]: {
    id: `${searchScope}.${BUS}`,
    defaultMessage: 'bus',
    en: 'bus',
    es: 'autobús',
    it: 'autobus',
    de: 'Bus',
    nl: 'bus',
  },
  [TRAIN]: {
    id: `${searchScope}.${TRAIN}`,
    defaultMessage: 'train',
    en: 'train',
    es: 'tren',
    it: 'treno',
    de: 'Zug',
    nl: 'trein',
  },
  [MULTIMODAL]: {
    id: `${searchScope}.${MULTIMODAL}`,
    defaultMessage: 'combiné',
    en: 'multimodal',
    es: 'multimodal',
    it: 'multimodale',
    de: 'multimodal',
    nl: 'multimodaal',
  },
  [CARPOOLING]: {
    id: `${searchScope}.${CARPOOLING}`,
    defaultMessage: 'covoiturage',
    en: 'carpooling',
    es: 'coche compartido',
    it: 'carpooling',
    de: 'Mitfahrgelegenheit',
    nl: 'carpoolen',
  },
  [BUS_PLURAL]: {
    id: `${searchScope}.${BUS_PLURAL}`,
    defaultMessage: 'bus',
    en: 'buses',
    es: 'autobuses',
    it: 'autobus',
    de: 'Busse',
    nl: 'bussen',
  },
  [TRAIN_PLURAL]: {
    id: `${searchScope}.${TRAIN_PLURAL}`,
    defaultMessage: 'trains',
    en: 'trains',
    es: 'trenes',
    it: 'treni',
    de: 'Züge',
    nl: 'treinen',
  },
  [CARPOOLING_PLURAL]: {
    id: `${searchScope}.${CARPOOLING_PLURAL}`,
    defaultMessage: 'covoiturages',
    en: 'carpoolings',
    es: 'coches compartidos',
    it: 'carpoolings',
    de: 'Mitfahrgelegenheiten',
    nl: 'carpoolings',
  },
  [MULTIMODAL_PLURAL]: {
    id: `${searchScope}.${MULTIMODAL_PLURAL}`,
    defaultMessage: 'combinés',
    en: 'combined trips',
    es: 'viajes combinados',
    it: 'viaggi combinati',
    de: 'kombinierte Reisen',
    nl: 'gecombineerde reizen',
  },
  of: {
    id: `${searchScope}.of`,
    defaultMessage: 'de',
    en: 'of',
    es: 'de',
    it: 'di',
    de: 'von',
    nl: 'van',
  },
  and: {
    id: `${searchScope}.and`,
    defaultMessage: 'et',
    en: 'and',
    es: 'y',
    it: 'e',
    de: 'und',
    nl: 'en',
  },
  only: {
    id: `${searchScope}.only`,
    defaultMessage: 'seulement',
    en: 'only',
    es: 'solo',
    it: 'solo',
    de: 'nur',
    nl: 'slechts',
  },
  station: {
    id: `${searchScope}.station`,
    defaultMessage: 'la station',
    en: 'station',
    es: 'la estación',
    it: 'la stazione',
    de: 'der Bahnhof',
    nl: 'het station',
  },
  stations: {
    id: `${searchScope}.stations`,
    defaultMessage: 'les stations',
    en: 'stations',
    es: 'las estaciones',
    it: 'le stazioni',
    de: 'die Bahnhöfe',
    nl: 'de stations',
  },
  companyIntro: {
    id: `${searchScope}.companyIntro`,
    defaultMessage: 'la compagnie',
    en: 'the company',
    es: 'la compañía',
    it: "l'azienda",
    de: 'das Unternehmen',
    nl: 'het bedrijf',
  },
  companyIntroPlural: {
    id: `${searchScope}.companyIntroPlural`,
    defaultMessage: 'les compagnies',
    en: 'the companies',
    es: 'las compañías',
    it: 'le aziende',
    de: 'die Unternehmen',
    nl: 'de bedrijven',
  },
  [COMPANY_VERB_1]: {
    id: `${searchScope}.${COMPANY_VERB_1}`,
    defaultMessage: 'propose',
    en: 'offers',
    es: 'ofrece',
    it: 'propone',
    de: 'bietet',
    nl: 'biedt aan',
  },
  [COMPANY_VERB_2]: {
    id: `${searchScope}.${COMPANY_VERB_2}`,
    defaultMessage: 'effectue',
    en: 'operates',
    es: 'realiza',
    it: 'effettua',
    de: 'führt durch',
    nl: 'voert uit',
  },
  [COMPANY_VERB_3]: {
    id: `${searchScope}.${COMPANY_VERB_3}`,
    defaultMessage: 'réalise',
    en: 'accomplishes',
    es: 'realiza',
    it: 'realizza',
    de: 'verwirklicht',
    nl: 'verwezenlijkt',
  },
  [COMPANY_VERB_4]: {
    id: `${searchScope}.${COMPANY_VERB_4}`,
    defaultMessage: 'assure',
    en: 'ensures',
    es: 'asegura',
    it: 'assicura',
    de: 'gewährleistet',
    nl: 'waarborgt',
  },
  [COMPANY_VERB_PLURAL_1]: {
    id: `${searchScope}.${COMPANY_VERB_PLURAL_1}`,
    defaultMessage: 'proposent',
    en: 'offer',
    es: 'ofrecen',
    it: 'propongono',
    de: 'bieten an',
    nl: 'bieden aan',
  },
  [COMPANY_VERB_PLURAL_2]: {
    id: `${searchScope}.${COMPANY_VERB_PLURAL_2}`,
    defaultMessage: 'effectuent',
    en: 'operate',
    es: 'realizan',
    it: 'effettuano',
    de: 'führen durch',
    nl: 'voeren uit',
  },
  [COMPANY_VERB_PLURAL_3]: {
    id: `${searchScope}.${COMPANY_VERB_PLURAL_3}`,
    defaultMessage: 'réalisent',
    en: 'accomplish',
    es: 'realizan',
    it: 'realizzano',
    de: 'verwirklichen',
    nl: 'verwezenlijken',
  },
  [COMPANY_VERB_PLURAL_4]: {
    id: `${searchScope}.${COMPANY_VERB_PLURAL_4}`,
    defaultMessage: 'assurent',
    en: 'ensure',
    es: 'aseguran',
    it: 'assicurano',
    de: 'gewährleisten',
    nl: 'waarborgen',
  },
  itinerariesAvailable: {
    id: `${searchScope}.itinerariesAvailable`,
    defaultMessage: 'itinéraire possible',
    en: 'possible itinerary',
    es: 'itinerario posible',
    it: 'itinerario possibile',
    de: 'mögliche Reiseroute',
    nl: 'mogelijk reisschema',
  },
  itinerariesAvailablePlural: {
    id: `${searchScope}.itinerariesAvailablePlural`,
    defaultMessage: 'itinéraires possibles',
    en: 'possible itineraries',
    es: 'itinerarios posibles',
    it: 'itinerari possibili',
    de: 'mögliche Reiserouten',
    nl: "mogelijke reisschema's",
  },
  cheap: {
    id: `${searchScope}.cheap`,
    defaultMessage: 'pas cher',
    en: 'cheap',
    es: 'económico',
    it: 'economico',
    de: 'günstig',
    nl: 'goedkoop',
  },
  [EMOJI_BUS]: {
    id: `${searchScope}.${EMOJI_BUS}`,
    defaultMessage: `${EMOJI_BUS} Bus`,
    en: `${EMOJI_BUS} Bus`,
    es: `${EMOJI_BUS} Autobús`,
    it: `${EMOJI_BUS} Autobus`,
    de: `${EMOJI_BUS} Bus`,
    nl: `${EMOJI_BUS} Bus`,
  },
  [EMOJI_TRAIN]: {
    id: `${searchScope}.${EMOJI_TRAIN}`,
    defaultMessage: `${EMOJI_TRAIN} Train`,
    en: `${EMOJI_TRAIN} Train`,
    es: `${EMOJI_TRAIN} Tren`,
    it: `${EMOJI_TRAIN} Treno`,
    de: `${EMOJI_TRAIN} Zug`,
    nl: `${EMOJI_TRAIN} Trein`,
  },
  [EMOJI_CARPOOLING]: {
    id: `${searchScope}.${EMOJI_CARPOOLING}`,
    defaultMessage: `${EMOJI_CARPOOLING} Covoiturage`,
    en: `${EMOJI_CARPOOLING} Carpooling`,
    es: `${EMOJI_CARPOOLING} Coche compartido`,
    it: `${EMOJI_CARPOOLING} Carpooling`,
    de: `${EMOJI_CARPOOLING} Mitfahrgelegenheit`,
    nl: `${EMOJI_CARPOOLING} Carpoolen`,
  },
  [EMOJI_MULTIMODAL]: {
    id: `${searchScope}.${EMOJI_MULTIMODAL}`,
    defaultMessage: `${EMOJI_MULTIMODAL} Combiné`,
    en: `${EMOJI_MULTIMODAL} Combined trips`,
    es: `${EMOJI_MULTIMODAL} Viajes combinados`,
    it: `${EMOJI_MULTIMODAL} Viaggi combinati`,
    de: `${EMOJI_MULTIMODAL} Kombinierte Reisen`,
    nl: `${EMOJI_MULTIMODAL} Gecombineerde reizen`,
  },
  findMyTickets: {
    id: `${searchScope}.findMyTickets`,
    defaultMessage: 'Trouver <span class="highlight">mes billets</span>',
    en: 'Find <span class="highlight">my tickets</span>',
    it: 'Trova <span class="highlight">i miei biglietti</span>',
    es: 'Encontrar <span class="highlight">mis billetes</span>',
    de: 'Finde <span class="highlight">meine Tickets</span>',
    nl: 'Vind <span class="highlight">mijn tickets</span>',
  },
  lowestPricesByCustomers: {
    id: `${searchScope}.lowestPricesByCustomers`,
    defaultMessage:
      'Prix les plus bas <span class="highlight">trouvés par nos clients</span>',
    en: 'Lowest prices <span class="highlight">found by our customers</span>',
    it: 'Prezzi più bassi <span class="highlight">trovati dai nostri clienti</span>',
    es: 'Los precios más bajos <span class="highlight">encontrados por nuestros clientes</span>',
    de: 'Niedrigste Preise <span class="highlight">von unseren Kunden gefunden</span>',
    nl: 'Laagste prijzen <span class="highlight">gevonden door onze klanten</span>',
  },
  lowestPricesByCustomersSpecific: {
    id: `${searchScope}.lowestPricesByCustomersSpecific`,
    defaultMessage:
      'Quel jour réserver un billet de {transportType} {origin} - {destination} <span class="highlight">pour payer moins cher ?</span>',
    en: 'What day to book a {transportType} ticket from {origin} to {destination} <span class="highlight">to pay less?</span>',
    it: 'Quale giorno prenotare un biglietto {transportType} da {origin} a {destination} <span class="highlight">per pagare di meno?</span>',
    es: '¿Qué día reservar un boleto de {transportType} de {origin} a {destination}<span class="highlight"> para pagar menos?</span>',
    de: 'Welchen Tag buchen Sie am besten ein {transportType}-Ticket von {origin} nach {destination}, <span class="highlight">um weniger zu zahlen?</span>',
    nl: 'Op welke dag moet je een {transportType}-ticket van {origin} naar {destination} <span class="highlight">boeken om minder te betalen?</span>',
  },
  priceCalendar: {
    id: `${searchScope}.priceCalendar`,
    defaultMessage: 'Calendrier des prix',
    en: 'Price calendar',
    it: 'Calendario dei prezzi',
    es: 'Calendario de precios',
    de: 'Preiskalender',
    nl: 'Prijskalender',
  },
  tripInfo: {
    id: `${searchScope}.tripInfo`,
    defaultMessage: "Infos <span class='highlight'>du trajet</span>",
    en: "Trip <span class='highlight'>info</span>",
    it: "Informazioni <span class='highlight'>sul viaggio</span>",
    es: "Información <span class='highlight'>del viaje</span>",
    de: "Reise <span class='highlight'>informationen</span>",
    nl: "Reis <span class='highlight'>informatie</span>",
  },
  carriers: {
    id: `${searchScope}.carriers`,
    defaultMessage: 'Transporteurs',
    en: 'Carriers',
    it: 'Trasportatori',
    es: 'Transportistas',
    de: 'Anbieter',
    nl: 'Vervoerders',
  },
  searchMyTrip: {
    id: `${searchScope}.searchMyTrip`,
    defaultMessage: 'Rechercher mon trajet',
    en: 'Search my trip',
    it: 'Cerca il mio viaggio',
    es: 'Buscar mi viaje',
    de: 'Meine Reise suchen',
    nl: 'Zoek mijn reis',
  },
  kilometersTraveled: {
    id: `${searchScope}.kilometersTraveled`,
    defaultMessage: '{unit} parcouru par nos voyageurs',
    en: '{unit} traveled by our travelers',
    it: '{unit} percorsi dai nostri viaggiatori',
    es: '{unit} recorridos por nuestros viajeros',
    de: '{unit} von unseren Reisenden zurückgelegt',
    nl: '{unit} gereisd door onze reizigers',
  },
  kilometersTraveledSpecific: {
    id: `${searchScope}.kilometersTraveledSpecific`,
    defaultMessage: '{unit} parcouru en {transportType} par nos voyageurs',
    en: '{unit} traveled in {transportType} by our travelers',
    it: '{unit} percorsi in {transportType} dai nostri viaggiatori',
    es: '{unit} recorridos en {transportType} por nuestros viajeros',
    de: '{unit} im {transportType} von unseren Reisenden zurückgelegt',
    nl: '{unit} gereisd in {transportType} door onze reizigers',
  },
  millionsKmUnit: {
    id: `${searchScope}.millionsKmUnit`,
    defaultMessage: 'millions de Km',
    en: 'millions of Km',
    it: 'milioni di Km',
    es: 'millones de Km',
    de: 'Millionen Kilometer',
    nl: 'miljoenen kilometers',
  },
  busTicket: {
    id: `${searchScope}.busTicket`,
    defaultMessage: 'Billet de bus',
    en: 'Bus ticket',
    it: "Biglietto dell'autobus",
    es: 'Billete de autobús',
    de: 'Busticket',
    nl: 'Busticket',
  },
  trainTicket: {
    id: `${searchScope}.trainTicket`,
    defaultMessage: 'Billet de train',
    en: 'Train ticket',
    it: 'Biglietto del treno',
    es: 'Billete de tren',
    de: 'Zugticket',
    nl: 'Treinticket',
  },
  carpoolingTicket: {
    id: `${searchScope}.carpoolingTicket`,
    defaultMessage: 'Billet de covoiturage',
    en: 'Carpooling ticket',
    it: 'Biglietto di carpooling',
    es: 'Billete de coche compartido',
    de: 'Mitfahrgelegenheits-Ticket',
    nl: 'Carpooling ticket',
  },
  transportTypeTrip: {
    id: `${searchScope}.transportTypeTrip`,
    defaultMessage: '{transportType} {origin} - {destination}',
    en: '{transportType} {origin} - {destination}',
    es: '{transportType} {origin} - {destination}',
    it: '{transportType} {origin} - {destination}',
    de: '{transportType} {origin} - {destination}',
    nl: '{transportType} {origin} - {destination}',
  },
  nextCheapestTrip: {
    id: `${searchScope}.nextCheapestTrip`,
    defaultMessage:
      "Le prochain billet de {transportType} {origin} {destination} le moins cher est le {bestDateToBook}. Réservez le maintenant, avant qu'il n'augmente !",
    en: 'The next cheapest {transportType} ticket from {origin} to {destination} is on {bestDateToBook}. Book it now before the price goes up!',
    es: 'El próximo billete de {transportType} más barato de {origin} a {destination} es el {bestDateToBook}. ¡Resérvalo ahora antes de que suba el precio!',
    it: 'Il prossimo biglietto {transportType} più economico da {origin} a {destination} è il {bestDateToBook}. Prenotalo ora prima che aumenti il prezzo!',
    de: 'Das nächste günstige {transportType}-Ticket von {origin} nach {destination} ist am {bestDateToBook}. Buchen Sie es jetzt, bevor der Preis steigt!',
    nl: 'Het volgende goedkope {transportType}-ticket van {origin} naar {destination} is op {bestDateToBook}. Boek het nu voordat de prijs stijgt!',
  },
  noCheapestTripDataAvailable: {
    id: `${searchScope}.noCheapestTripDataAvailable`,
    defaultMessage:
      "Nous n'avons pas encore de données concernant ce voyage. Lancer une recherche de billet sur Tictactrip nous permettra de récupérer les informations et d'alimenter ce tableau ! Merci par avance pour les prochains voyageurs {origin} {destination}.",
    en: "We don't have data on this trip yet. Searching for tickets on Tictactrip will help us gather information and update this table! Thank you in advance for future travelers from {origin} to {destination}.",
    es: 'Aún no tenemos datos sobre este viaje. Buscar billetes en Tictactrip nos ayudará a recopilar información y actualizar esta tabla. ¡Gracias de antemano para los futuros viajeros de {origin} a {destination}!',
    it: 'Non abbiamo ancora dati su questo viaggio. Cercare biglietti su Tictactrip ci aiuterà a raccogliere informazioni e aggiornare questa tabella. Grazie in anticipo per i futuri viaggiatori da {origin} a {destination}.',
    de: 'Wir haben noch keine Daten zu dieser Reise. Wenn Sie nach Tickets auf Tictactrip suchen, können wir Informationen sammeln und diese Tabelle aktualisieren. Vielen Dank im Voraus für zukünftige Reisende von {origin} nach {destination}.',
    nl: 'We hebben nog geen gegevens over deze reis. Tickets zoeken op Tictactrip helpt ons informatie te verzamelen en deze tabel bij te werken! Alvast bedankt voor toekomstige reizigers van {origin} naar {destination}.',
  },

  [TRIP_CO2_QUOTES[0]]: {
    id: `${searchScope}.${TRIP_CO2_QUOTES[0]}`,
    defaultMessage:
      "Réduire son empreinte carbone en utilisant des moyens de transport responsables, c'est important pour nous (tous) ! <strong>Votre {transportType} {origin} {destination} émet généralement {co2kg}kg CO2 par voyageur.</strong> Bonne nouvelle, c'est toujours moins qu'un avion ou une voiture personnelle !",
    en: "Reducing your carbon footprint by using responsible modes of transport is important for us (all)! Your {transportType} from {origin} to {destination} generally emits {co2kg}kg CO2 per traveler. Good news, it's always less than a plane or personal car!",
    es: 'Reducir su huella de carbono utilizando medios de transporte responsables es importante para nosotros (todos). Su {transportType} de {origin} a {destination} generalmente emite {co2kg}kg de CO2 por viajero. ¡Buena noticia, siempre es menos que un avión o un coche personal!',
    it: "Ridurre la tua impronta di carbonio utilizzando mezzi di trasporto responsabili è importante per noi (tutti). Il tuo {transportType} da {origin} a {destination} emette generalmente {co2kg}kg di CO2 per viaggiatore. Buone notizie, è sempre meno di un aereo o di un'auto personale!",
    de: 'Die Reduzierung Ihres CO2-Fußabdrucks durch die Nutzung verantwortungsvoller Verkehrsmittel ist wichtig für uns (alle)! Ihr {transportType} von {origin} nach {destination} emittiert in der Regel {co2kg}kg CO2 pro Reisendem. Gute Nachricht, es ist immer weniger als ein Flugzeug oder ein persönliches Auto!',
    nl: 'Het verminderen van uw ecologische voetafdruk door verantwoorde vervoerswijzen te gebruiken, is belangrijk voor ons (allemaal)! Uw {transportType} van {origin} naar {destination} stoot doorgaans {co2kg}kg CO2 per reiziger uit. Goed nieuws, het is altijd minder dan een vliegtuig of een eigen auto!',
  },
  [TRIP_CO2_QUOTES[1]]: {
    id: `${searchScope}.${TRIP_CO2_QUOTES[1]}`,
    defaultMessage:
      "<strong>Si vous voyagez en {transportType} {formattedTrip1}, vous émettrez à vous seul {co2kg}kg de CO2.</strong> Le bon côté, c'est que c'est toujours moins que de prendre un avion ou votre propre véhicule. C'est pour cela que nous encourageons au maximum à opter pour les transports en commun {origin} {destination}. PS : vous pouvez comparer les émissions de CO2 avec notre outil en haut de cette page !",
    en: "If you travel by {transportType} {formattedTrip1}, you will emit {co2kg}kg of CO2 on your own. The good side is that it's always less than taking a plane or your own vehicle. That's why we encourage you to opt for public transportation as much as possible {origin} {destination}. PS: You can compare CO2 emissions with our tool at the top of this page!",
    es: 'Si viaja en {transportType} {formattedTrip1}, emitirá {co2kg}kg de CO2 por sí solo. El lado bueno es que siempre es menos que tomar un avión o su propio vehículo. Por eso animamos a optar por el transporte público tanto como sea posible {origin} {destination}. PD: ¡Puede comparar las emisiones de CO2 con nuestra herramienta en la parte superior de esta página!',
    it: 'Se viaggi in {transportType} {formattedTrip1}, emetterai da solo {co2kg}kg di CO2. Il lato positivo è che è sempre meno che prendere un aereo o il tuo veicolo. Ecco perché ti incoraggiamo a optare per i mezzi pubblici il più possibile {origin} {destination}. PS: Puoi confrontare le emissioni di CO2 con il nostro strumento in alto a questa pagina!',
    de: 'Wenn Sie mit {transportType} {formattedTrip1} reisen, werden Sie allein {co2kg}kg CO2 ausstoßen. Die gute Seite ist, dass es immer weniger ist als ein Flugzeug oder Ihr eigenes Fahrzeug zu nehmen. Deshalb empfehlen wir Ihnen, so oft wie möglich auf öffentliche Verkehrsmittel {origin} {destination} zu setzen. PS: Sie können die CO2-Emissionen mit unserem Tool oben auf dieser Seite vergleichen!',
    nl: 'Als u reist met {transportType} {formattedTrip1}, stoot u alleen {co2kg}kg CO2 uit. Het goede nieuws is dat het altijd minder is dan het nemen van een vliegtuig of uw eigen voertuig. Daarom moedigen we u aan om zoveel mogelijk voor het openbaar vervoer te kiezen {origin} {destination}. PS: U kunt de CO2-uitstoot vergelijken met onze tool bovenaan deze pagina!',
  },
  [TRIP_CO2_QUOTES[2]]: {
    id: `${searchScope}.${TRIP_CO2_QUOTES[2]}`,
    defaultMessage:
      "<strong>Votre empreinte carbone personnelle sera de {co2kg}kg de CO2 pour ce voyage en {transportType} {formattedTrip2}.</strong> Oui, c'est nettement mieux que si vous aviez opté pour un avion ou même votre voiture personnelle. D'ailleurs, si vous voulez comparer les émissions de CO2 par type de transport, vous pouvez utiliser notre outil en haut de la page.",
    en: "Your personal carbon footprint will be {co2kg}kg of CO2 for this trip by {transportType} {formattedTrip2}. Yes, it's much better than if you had opted for a plane or even your own car. By the way, if you want to compare carbon emissions by type of transport, you can use our tool at the top of the page.",
    es: 'Su huella de carbono personal será de {co2kg}kg de CO2 para este viaje en {transportType} {formattedTrip2}. Sí, es mucho mejor que si hubiera optado por un avión o incluso su propio coche. Por cierto, si desea comparar las emisiones de carbono por tipo de transporte, puede utilizar nuestra herramienta en la parte superior de la página.',
    it: 'La tua impronta di carbonio personale sarà di {co2kg}kg di CO2 per questo viaggio in {transportType} {formattedTrip2}. Sì, è molto meglio che se avessi optato per un aereo o anche la tua auto. A proposito, se vuoi confrontare le emissioni di carbonio per tipo di trasporto, puoi utilizzare il nostro strumento in cima alla pagina.',
    de: 'Ihr persönlicher CO2-Fußabdruck beträgt {co2kg}kg CO2 für diese Reise mit {transportType} {formattedTrip2}. Ja, das ist viel besser als wenn Sie sich für ein Flugzeug oder sogar Ihr eigenes Auto entschieden hätten. Übrigens, wenn Sie die CO2-Emissionen nach Verkehrsmitteln vergleichen möchten, können Sie unser Tool oben auf der Seite verwenden.',
    nl: 'Uw persoonlijke koolstofvoetafdruk zal {co2kg}kg CO2 bedragen voor deze reis met {transportType} {formattedTrip2}. Ja, het is veel beter dan wanneer u voor een vliegtuig of zelfs uw eigen auto had gekozen. Overigens, als u de CO2-uitstoot per type vervoer wilt vergelijken, kunt u onze tool bovenaan de pagina gebruiken.',
  },
  [BEST_TIME_TO_BOOK_QUOTES[0]]: {
    id: `${searchScope}.${BEST_TIME_TO_BOOK_QUOTES[0]}`,
    defaultMessage:
      "Voyager, ça peut coûter cher... sauf si vous savez exactement quand réserver votre billet de {transportType} {origin} {destination} pour payer moins cher ! Et si nous vous disions que nous avons la solution pour vous ? <strong>Le meilleur moment pour réserver votre billet de {transportType} {formattedTrip3}, c'est {daysBeforeDeparture} jours avant votre date de départ.</strong> Vous économiserez alors jusqu'à {percentageSaved}% sur le prix de votre billet. Belle économie non ?",
    en: "Traveling can be expensive... unless you know exactly when to book your {transportType} {origin} {destination} ticket to pay less! What if we told you we have the solution for you? The best time to book your {transportType} {formattedTrip3} ticket is {daysBeforeDeparture} days before your departure date. You'll save up to {percentageSaved}% on the price of your ticket. Nice savings, isn't it?",
    es: 'Viajar puede ser caro... ¡a menos que sepas exactamente cuándo reservar tu billete de {transportType} {origin} {destination} para pagar menos! ¿Y si te dijéramos que tenemos la solución para ti? El mejor momento para reservar tu billete de {transportType} {formattedTrip3} es {daysBeforeDeparture} días antes de tu fecha de salida. Ahorrarás hasta {percentageSaved}% en el precio de tu billete. ¡Bonitas ahorros, verdad?',
    it: 'Viaggiare può essere costoso... a meno che non sappiate esattamente quando prenotare il vostro biglietto di {transportType} {origin} {destination} per pagare meno! E se vi dicessimo che abbiamo la soluzione per voi? Il momento migliore per prenotare il vostro biglietto di {transportType} {formattedTrip3} è {daysBeforeDeparture} giorni prima della vostra data di partenza. Risparmierete fino al {percentageSaved}% sul prezzo del biglietto. Bei risparmi, vero?',
    de: 'Reisen kann teuer sein... es sei denn, Sie wissen genau, wann Sie Ihr Ticket von {transportType} {origin} {destination} buchen müssen, um weniger zu bezahlen! Was wäre, wenn wir Ihnen sagen würden, dass wir die Lösung für Sie haben? Die beste Zeit, um Ihr Ticket von {transportType} {formattedTrip3} zu buchen, ist {daysBeforeDeparture} Tage vor Ihrem Abreisedatum. Sie sparen bis zu {percentageSaved}% auf den Ticketpreis. Schöne Ersparnisse, nicht wahr?',
    nl: 'Reizen kan duur zijn... tenzij je precies weet wanneer je je ticket van {transportType} {origin} {destination} moet boeken om minder te betalen! Wat als we je vertelden dat we de oplossing voor je hebben? Het beste moment om je ticket van {transportType} {formattedTrip3} te boeken, is {daysBeforeDeparture} dagen voor je vertrekdatum. Je bespaart dan tot {percentageSaved}% op de prijs van je ticket. Mooie besparingen, toch?',
  },
  [BEST_TIME_TO_BOOK_QUOTES[1]]: {
    id: `${searchScope}.${BEST_TIME_TO_BOOK_QUOTES[1]}`,
    defaultMessage:
      "Faites des économies sur le prix de votre billet de {transportType} en réservant à l'avance, au meilleur moment ! C'est l'une des meilleures astuces pour voyager pas cher de {origin} à {destination}. <strong>Pour réduire le prix de votre billet de {percentageSaved}% au maximum, vous devez réserver {daysBeforeDeparture} jours avant votre date de départ.</strong> C'est à ce moment là que vous aurez les meilleurs prix de billets. Pour comparer les dates de réservation et les prix, vous pouvez utiliser notre outil en haut de cette page !",
    en: "Save money on your {transportType} ticket price by booking in advance, at the right time! It's one of the best tips for traveling cheaply from {origin} to {destination}. To reduce the price of your ticket by {percentageSaved}% at the maximum, you should book {daysBeforeDeparture} days before your departure date. That's when you'll get the best ticket prices. To compare booking dates and prices, you can use our tool at the top of this page!",
    es: '¡Ahorra dinero en el precio de tu billete de {transportType} reservando con antelación, en el momento adecuado! Es uno de los mejores consejos para viajar barato de {origin} a {destination}. Para reducir el precio de tu billete en {percentageSaved}% al máximo, debes reservar {daysBeforeDeparture} días antes de tu fecha de salida. Ahí es cuando obtendrás los mejores precios de billetes. Para comparar fechas de reserva y precios, ¡puedes utilizar nuestra herramienta en la parte superior de esta página!',
    it: 'Risparmia denaro sul prezzo del tuo biglietto di {transportType} prenotando in anticipo, al momento giusto! È uno dei migliori consigli per viaggiare a buon mercato da {origin} a {destination}. Per ridurre il prezzo del tuo biglietto di {percentageSaved}% al massimo, dovresti prenotare {daysBeforeDeparture} giorni prima della tua data di partenza. È in quel momento che otterrai i migliori prezzi dei biglietti. Per confrontare le date di prenotazione e i prezzi, puoi utilizzare il nostro strumento in cima a questa pagina!',
    de: 'Sparen Sie Geld beim Ticketpreis für Ihre {transportType}-Fahrt, indem Sie im Voraus zum richtigen Zeitpunkt buchen! Es ist einer der besten Tipps für günstiges Reisen von {origin} nach {destination}. Um den Preis Ihres Tickets um {percentageSaved}% maximal zu reduzieren, sollten Sie {daysBeforeDeparture} Tage vor Ihrem Abreisedatum buchen. Das ist der Zeitpunkt, an dem Sie die besten Ticketpreise erhalten. Um Buchungsdaten und Preise zu vergleichen, können Sie unser Tool oben auf dieser Seite verwenden!',
    nl: 'Bespaar geld op de prijs van uw {transportType}-ticket door op tijd te boeken, op het juiste moment! Het is een van de beste tips om goedkoop te reizen van {origin} naar {destination}. Om de prijs van uw ticket met {percentageSaved}% maximaal te verlagen, moet u {daysBeforeDeparture} dagen voor uw vertrekdatum boeken. Dat is wanneer u de beste ticketprijzen krijgt. Om boekingsdata en prijzen te vergelijken, kunt u onze tool bovenaan deze pagina gebruiken!',
  },
  [BEST_TIME_TO_BOOK_QUOTES[2]]: {
    id: `${searchScope}.${BEST_TIME_TO_BOOK_QUOTES[2]}`,
    defaultMessage:
      "Réduisez le prix de votre billet de {transportType} {formattedTrip1} de {percentageSaved}% (au maximum) si vous réservez au bon moment. En effet, d'après l'ensemble de nos données pour ce voyage, <strong>le meilleur moment pour réserver un {transportType} {origin} {destination}, c'est {daysBeforeDeparture} jours avant votre date de départ.</strong> Et si vous voulez allez plus loin, analysez l'évolution des prix de {transportType} {origin} {destination} avec notre outil en haut de cette page !",
    en: 'Reduce the price of your {transportType} {formattedTrip1} ticket by {percentageSaved}% (at maximum) if you book at the right time. Indeed, according to all our data for this trip, the best time to book a {transportType} {origin} {destination} is {daysBeforeDeparture} days before your departure date. And if you want to go further, analyze the price evolution of {transportType} {origin} {destination} with our tool at the top of this page!',
    es: 'Reduzca el precio de su billete {transportType} {formattedTrip1} en {percentageSaved}% (como máximo) si reserva en el momento adecuado. De hecho, según todos nuestros datos para este viaje, el mejor momento para reservar un {transportType} {origin} {destination} es {daysBeforeDeparture} días antes de su fecha de salida. Y si desea ir más lejos, analice la evolución de precios de {transportType} {origin} {destination} con nuestra herramienta en la parte superior de esta página.',
    it: "Riduci il prezzo del tuo biglietto {transportType} {formattedTrip1} di {percentageSaved}% (al massimo) se prenoti al momento giusto. Infatti, secondo tutti i nostri dati per questo viaggio, il momento migliore per prenotare un {transportType} {origin} {destination} è {daysBeforeDeparture} giorni prima della tua data di partenza. E se vuoi andare oltre, analizza l'evoluzione dei prezzi di {transportType} {origin} {destination} con il nostro strumento in cima a questa pagina!",
    de: 'Reduzieren Sie den Preis Ihres {transportType} {formattedTrip1}-Tickets um {percentageSaved}% (maximal), wenn Sie zum richtigen Zeitpunkt buchen. Tatsächlich ist laut all unseren Daten für diese Reise der beste Zeitpunkt, um ein {transportType} {origin} {destination} zu buchen, {daysBeforeDeparture} Tage bevor Ihr Abreisedatum. Und wenn Sie weiter gehen möchten, analysieren Sie die Preisentwicklung von {transportType} {origin} {destination} mit unserem Tool oben auf dieser Seite!',
    nl: 'Verlaag de prijs van uw {transportType} {formattedTrip1}-ticket met {percentageSaved}% (maximaal) als u op het juiste moment boekt. Volgens al onze gegevens voor deze reis is het beste moment om een {transportType} {origin} {destination} te boeken {daysBeforeDeparture} dagen voor uw vertrekdatum. En als u verder wilt gaan, analyseer dan de prijsontwikkeling van {transportType} {origin} {destination} met onze tool bovenaan deze pagina!',
  },
  bestTimeToBookNoData: {
    id: `${searchScope}.bestTimeToBookNoData`,
    defaultMessage:
      "Nous n'avons pas assez de données pour un voyage {origin} {destination} en {transportType}. <strong>Aidez-nous en faisant une recherche de ce voyage, pour actualiser nos informations !</strong> Plus vous êtes nombreux à faire des recherches et réserver des billets, plus nous pouvons vous aider à voyager moins cher grâce à ces informations !",
    en: "We don't have enough data for a trip from {origin} to {destination} by {transportType}. <strong>Help us by searching for this trip to update our information!</strong> The more people search and book tickets, the more we can help you travel cheaper with this information!",
    es: 'No tenemos suficientes datos para un viaje de {origin} a {destination} en {transportType}. <strong>¡Ayúdenos buscando este viaje para actualizar nuestra información!</strong> Cuantas más personas busquen y reserven billetes, más podremos ayudarle a viajar más barato con esta información.',
    it: 'Non abbiamo abbastanza dati per un viaggio da {origin} a {destination} in {transportType}. <strong>Aiutaci cercando questo viaggio per aggiornare le nostre informazioni!</strong> Più persone cercano e prenotano biglietti, più possiamo aiutarti a viaggiare a prezzi più convenienti con queste informazioni!',
    de: 'Wir haben nicht genügend Daten für eine Reise von {origin} nach {destination} mit {transportType}. <strong>Helfen Sie uns, indem Sie nach dieser Reise suchen, um unsere Informationen zu aktualisieren!</strong> Je mehr Menschen nach Tickets suchen und diese buchen, desto mehr können wir Ihnen helfen, mit diesen Informationen günstiger zu reisen!',
    nl: 'We hebben niet genoeg gegevens voor een reis van {origin} naar {destination} met {transportType}. <strong>Help ons door naar deze reis te zoeken om onze informatie bij te werken!</strong> Hoe meer mensen zoeken en tickets boeken, hoe meer we u kunnen helpen goedkoper te reizen met deze informatie!',
  },
  dailyTransportType: {
    id: `${searchScope}.dailyTransportType`,
    defaultMessage: '{transportType} quotidiens',
    en: 'Daily {transportType}',
    es: '{transportType} diarios',
    it: '{transportType} quotidiani',
    de: 'Tägliche {transportType}',
    nl: 'Dagelijkse {transportType}',
  },
  firstLastDeparture: {
    id: `${searchScope}.firstLastDeparture`,
    defaultMessage: 'Heures de départ',
    en: 'Departure times',
    es: 'Horas de salida',
    it: 'Orari di partenza',
    de: 'Abfahrtszeiten',
    nl: 'Vertrektijden',
  },
  earliestLatestArrival: {
    id: `${searchScope}.earliestLatestArrival`,
    defaultMessage: "Heures d'arrivée",
    en: 'Arrival times',
    es: 'Horas de llegada',
    it: 'Orari di arrivo',
    de: 'Ankunftszeiten',
    nl: 'Aankomsttijden',
  },
  priceRangeMinMaxAvg: {
    id: `${searchScope}.priceRangeMinMaxAvg`,
    defaultMessage: 'Fourchette de prix',
    en: 'Price range',
    es: 'Rango de precios',
    it: 'Fascia di prezzo',
    de: 'Preisspanne',
    nl: 'Prijsklasse',
  },
  durationMinMaxAvg: {
    id: `${searchScope}.durationMinMaxAvg`,
    defaultMessage: 'Durée',
    en: 'Duration',
    es: 'Duración',
    it: 'Durata',
    de: 'Dauer',
    nl: 'Duur',
  },
  possibleDepartureStations: {
    id: `${searchScope}.possibleDepartureStations`,
    defaultMessage: 'Stations de départs',
    en: 'Departure stations',
    es: 'Estaciones de salida',
    it: 'Stazioni di partenza',
    de: 'Abfahrtsstationen',
    nl: 'Vertrekstations',
  },
  possibleArrivalStations: {
    id: `${searchScope}.possibleArrivalStations`,
    defaultMessage: "Stations d'arrivées",
    en: 'Arrival stations',
    es: 'Estaciones de llegada',
    it: 'Stazioni di arrivo',
    de: 'Ankunftsstationen',
    nl: 'Aankomststations',
  },

  dailyTransportTypeText: {
    id: `${searchScope}.dailyTransportTypeText`,
    defaultMessage: '{numberOfTripsPerDay} {transportType} {company} par jour',
    en: '{numberOfTripsPerDay} {transportType} {company} per day',
    es: '{numberOfTripsPerDay} {transportType} {company} por día',
    it: '{numberOfTripsPerDay} {transportType} {company} al giorno',
    de: '{numberOfTripsPerDay} {transportType} {company} pro Tag',
    nl: '{numberOfTripsPerDay} {transportType} {company} per dag',
  },
  firstLastDepartureText: {
    id: `${searchScope}.firstLastDepartureText`,
    defaultMessage:
      'Premier {transportType} : {firstTripDepartureTime} | Dernier {transportType} : {lastTripDepartureTime}',
    en: 'First {transportType}: {firstTripDepartureTime} | Last {transportType}: {lastTripDepartureTime}',
    es: 'Primer {transportType}: {firstTripDepartureTime} | Último {transportType}: {lastTripDepartureTime}',
    it: 'Primo {transportType}: {firstTripDepartureTime} | Ultimo {transportType}: {lastTripDepartureTime}',
    de: 'Erster {transportType}: {firstTripDepartureTime} | Letzter {transportType}: {lastTripDepartureTime}',
    nl: 'Eerste {transportType}: {firstTripDepartureTime} | Laatste {transportType}: {lastTripDepartureTime}',
  },
  earliestLatestArrivalText: {
    id: `${searchScope}.earliestLatestArrivalText`,
    defaultMessage:
      'Arrivée la plus tôt : {firstTripArrivalTime} | Arrivée la plus tard : {lastTripArrivalTime}',
    en: 'Earliest arrival: {firstTripArrivalTime} | Latest arrival: {lastTripArrivalTime}',
    es: 'Llegada más temprano: {firstTripArrivalTime} | Llegada más tarde: {lastTripArrivalTime}',
    it: 'Arrivo più presto: {firstTripArrivalTime} | Arrivo più tardi: {lastTripArrivalTime}',
    de: 'Früheste Ankunft: {firstTripArrivalTime} | Späteste Ankunft: {lastTripArrivalTime}',
    nl: 'Vroegste aankomst: {firstTripArrivalTime} | Laatste aankomst: {lastTripArrivalTime}',
  },
  priceRangeMinMaxAvgText: {
    id: `${searchScope}.priceRangeMinMaxAvgText`,
    defaultMessage: '{minPrice} - {maxPrice} (Moyenne à {meanPrice})',
    en: '{minPrice} - {maxPrice} (Average at {meanPrice})',
    es: '{minPrice} - {maxPrice} (Media en {meanPrice})',
    it: '{minPrice} - {maxPrice} (Media a {meanPrice})',
    de: '{minPrice} - {maxPrice} (Durchschnittlich bei {meanPrice})',
    nl: '{minPrice} - {maxPrice} (Gemiddeld op {meanPrice})',
  },
  durationMinMaxAvgText: {
    id: `${searchScope}.durationMinMaxAvgText`,
    defaultMessage: '{minDuration} - {maxDuration} (Moyenne à {meanDuration})',
    en: '{minDuration} - {maxDuration} (Average at {meanDuration})',
    es: '{minDuration} - {maxDuration} (Media en {meanDuration})',
    it: '{minDuration} - {maxDuration} (Media a {meanDuration})',
    de: '{minDuration} - {maxDuration} (Durchschnittlich bei {meanDuration})',
    nl: '{minDuration} - {maxDuration} (Gemiddeld op {meanDuration})',
  },
  [SPECIFIC_COMPANIES_TEXT_HEADERS[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_HEADERS[0]}`,
    defaultMessage: '{companyName} {formattedTrip1} {emoji}',
    en: '{companyName} {formattedTrip1} {emoji}',
    es: '{companyName} {formattedTrip1} {emoji}',
    it: '{companyName} {formattedTrip1} {emoji}',
    de: '{companyName} {formattedTrip1} {emoji}',
    nl: '{companyName} {formattedTrip1} {emoji}',
  },
  [SPECIFIC_COMPANIES_TEXT_HEADERS[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_HEADERS[1]}`,
    defaultMessage: '{companyName} {origin} {destination} {emoji}',
    en: '{companyName} {origin} {destination} {emoji}',
    es: '{companyName} {origin} {destination} {emoji}',
    it: '{companyName} {origin} {destination} {emoji}',
    de: '{companyName} {origin} {destination} {emoji}',
    nl: '{companyName} {origin} {destination} {emoji}',
  },
  [SPECIFIC_COMPANIES_TEXT_HEADERS[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_HEADERS[2]}`,
    defaultMessage:
      'Le {transportType} {companyName} {origin} {destination} {emoji}',
    en: 'The {transportType} {companyName} {origin} {destination} {emoji}',
    es: 'El {transportType} {companyName} {origin} {destination} {emoji}',
    it: 'Il {transportType} {companyName} {origin} {destination} {emoji}',
    de: 'Der {transportType} {companyName} {origin} {destination} {emoji}',
    nl: 'De {transportType} {companyName} {origin} {destination} {emoji}',
  },
  [SPECIFIC_COMPANIES_TEXT_1[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_1[0]}`,
    defaultMessage:
      'Un trajet en {transportType} {companyName} <strong>{formattedTrip1}</strong> a un prix qui varie en temps normal entre <strong>{minPrice}</strong> et <strong>{maxPrice}</strong>.\n\nLe temps de ce voyage en {transportType} est comprise entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.\n\nLe billet de {transportType} {formattedTrip2} est à <strong>{meanPrice}</strong> en moyenne.\n\nQuant au temps de trajet, il faut compter <strong>{meanDuration}</strong>.',
    en: 'A trip by {transportType} {companyName} <strong>{formattedTrip1}</strong> typically costs between <strong>{minPrice}</strong> and <strong>{maxPrice}</strong>.\n\nThe duration of this journey by {transportType} is between <strong>{minDuration}</strong> and <strong>{maxDuration}</strong>.\n\nThe average ticket price for {transportType} {formattedTrip2} is <strong>{meanPrice}</strong>.\n\nAs for the travel time, it takes about <strong>{meanDuration}</strong>.',
    es: 'Un viaje en {transportType} {companyName} <strong>{formattedTrip1}</strong> cuesta normalmente entre <strong>{minPrice}</strong> y <strong>{maxPrice}</strong>.\n\nLa duración de este viaje en {transportType} está entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>.\n\nEl precio medio del billete de {transportType} {formattedTrip2} es de <strong>{meanPrice}</strong>.\n\nEn cuanto al tiempo de viaje, se tarda aproximadamente <strong>{meanDuration}</strong>.',
    it: 'Un viaggio in {transportType} {companyName} <strong>{formattedTrip1}</strong> costa normalmente tra <strong>{minPrice}</strong> e <strong>{maxPrice}</strong>.\n\nLa durata di questo viaggio in {transportType} è compresa tra <strong>{minDuration}</strong> e <strong>{maxDuration}</strong>.\n\nIl prezzo medio del biglietto di {transportType} {formattedTrip2} è di <strong>{meanPrice}</strong>.\n\nPer quanto riguarda il tempo di percorrenza, ci vogliono circa <strong>{meanDuration}</strong>.',
    de: 'Eine Reise mit {transportType} {companyName} <strong>{formattedTrip1}</strong> kostet normalerweise zwischen <strong>{minPrice}</strong> und <strong>{maxPrice}</strong>.\n\nDie Dauer dieser Reise mit {transportType} liegt zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>.\n\nDer durchschnittliche Ticketpreis für {transportType} {formattedTrip2} beträgt <strong>{meanPrice}€</strong>.\n\nDie Reisezeit beträgt etwa <strong>{meanDuration}</strong>.',
    nl: 'Een reis met {transportType} {companyName} <strong>{formattedTrip1}</strong> kost normaal gesproken tussen <strong>{minPrice}</strong> en <strong>{maxPrice}</strong>.\n\nDe duur van deze reis met {transportType} is tussen <strong>{minDuration}</strong> en <strong>{maxDuration}</strong>.\n\nDe gemiddelde ticketprijs voor {transportType} {formattedTrip2} is <strong>{meanPrice}</strong>.\n\nWat de reistijd betreft, duurt het ongeveer <strong>{meanDuration}</strong>.',
  },
  [SPECIFIC_COMPANIES_TEXT_1[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_1[1]}`,
    defaultMessage:
      'Ce trajet en {transportType} {companyName} {formattedTrip1} part de la gare de {originStations}.\n\nIl arrivera ensuite à {destination} dans la gare de {destinationStations}.',
    en: 'This {transportType} {companyName} trip {formattedTrip1} departs from {originStations} station.\n\nIt will then arrive at {destination} at {destinationStations} station.',
    es: 'Este viaje en {transportType} {companyName} {formattedTrip1} sale de la estación de {originStations}.\n\nLuego llegará a {destination} en la estación de {destinationStations}.',
    it: 'Questo viaggio in {transportType} {companyName} {formattedTrip1} parte dalla stazione di {originStations}.\n\nArriverà quindi a {destination} alla stazione di {destinationStations}.',
    de: 'Diese {transportType} {companyName} Fahrt {formattedTrip1} startet vom Bahnhof {originStations}.\n\nAnschließend kommt sie in {destination} am Bahnhof {destinationStations} an.',
    nl: 'Deze {transportType} {companyName} reis {formattedTrip1} vertrekt vanaf station {originStations}.\n\nHet arriveert vervolgens in {destination} op station {destinationStations}.',
  },
  [SPECIFIC_COMPANIES_TEXT_1[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_1[2]}`,
    defaultMessage:
      'Le premier de ces trajets en {companyName} {formattedTrip3} part chaque jour à <strong>{firstDepartureTime}</strong>',
    en: 'The first of these trips with {companyName} {formattedTrip3} departs each day at <strong>{firstDepartureTime}</strong>',
    es: 'El primero de estos viajes con {companyName} {formattedTrip3} sale cada día a <strong>{firstDepartureTime}</strong>',
    it: 'Il primo di questi viaggi con {companyName} {formattedTrip3} parte ogni giorno alle <strong>{firstDepartureTime}</strong>',
    de: 'Die erste dieser Fahrten mit {companyName} {formattedTrip3} fährt jeden Tag um <strong>{firstDepartureTime}</strong> ab',
    nl: 'De eerste van deze reizen met {companyName} {formattedTrip3} vertrekt elke dag om <strong>{firstDepartureTime}</strong>',
  },
  [SPECIFIC_COMPANIES_TEXT_2[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_2[0]}`,
    defaultMessage:
      "Pour voyager en {transportType} {companyName} pas cher, comptez sur un billet à <strong>{minPrice}</strong> pour ce trajet <strong>{formattedTrip2}</strong>.\n\nEn fonction de la date de votre réservation de billet sur Tictactrip, le prix du trajet {formattedTrip1} pourrait avoir augmenté jusqu'à <strong>{maxPrice}</strong>.\n\nBon à savoir : le billet moyen est à <strong>{meanPrice}</strong>.\n\nEt vous avez la possibilité d'estimer le prix des billets avec notre outil ci-dessus !",
    en: 'For cheap {transportType} {companyName} travel, expect a ticket priced at <strong>{minPrice}</strong> for this {formattedTrip2} journey.\n\nDepending on your booking date on Tictactrip, the price for {formattedTrip1} could have increased up to <strong>{maxPrice}</strong>.\n\nGood to know: the average ticket price is <strong>{meanPrice}</strong>.\n\nAnd you can estimate ticket prices with our tool above!',
    es: 'Para viajar barato en {transportType} {companyName}, espere un boleto con un precio de <strong>{minPrice}</strong> para este viaje {formattedTrip2}.\n\nSegún la fecha de reserva en Tictactrip, el precio para {formattedTrip1} podría haber aumentado hasta <strong>{maxPrice}</strong>.\n\nBueno saber: el precio promedio del boleto es <strong>{meanPrice}</strong>.\n\n¡Y puedes estimar los precios de los boletos con nuestra herramienta arriba!',
    it: 'Per viaggiare economicamente con {transportType} {companyName}, aspettati un biglietto al prezzo di <strong>{minPrice}</strong> per questo viaggio {formattedTrip2}.\n\nIn base alla data di prenotazione su Tictactrip, il prezzo per {formattedTrip1} potrebbe essere aumentato fino a <strong>{maxPrice}</strong>.\n\nDa sapere: il prezzo medio del biglietto è di <strong>{meanPrice}</strong>.\n\nE puoi stimare i prezzi dei biglietti con il nostro strumento qui sopra!',
    de: 'Für günstiges Reisen mit {transportType} {companyName} erwarten Sie ein Ticketpreis von <strong>{minPrice}</strong> für diese {formattedTrip2} Reise.\n\nAbhängig vom Buchungsdatum auf Tictactrip könnte der Preis für {formattedTrip1} bis auf <strong>{maxPrice}</strong> gestiegen sein.\n\nWissenswert: Der durchschnittliche Ticketpreis beträgt <strong>{meanPrice}</strong>.\n\nUnd Sie können die Ticketpreise mit unserem oben stehenden Tool abschätzen!',
    nl: 'Voor goedkoop reizen met {transportType} {companyName}, verwacht een ticketprijs van <strong>{minPrice}</strong> voor deze {formattedTrip2} reis.\n\nAfhankelijk van uw boekingsdatum op Tictactrip, zou de prijs voor {formattedTrip1} kunnen zijn gestegen tot <strong>{maxPrice}</strong>.\n\nGoed om te weten: de gemiddelde ticketprijs is <strong>{meanPrice}</strong>.\n\nEn u kunt de ticketprijzen schatten met onze tool hierboven!',
  },
  [SPECIFIC_COMPANIES_TEXT_2[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_2[1]}`,
    defaultMessage:
      'Le <strong>temps de trajet {formattedTrip2}</strong> est généralement compris entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.\n\nCe {transportType} {companyName} part de {originStations} et arrive à {destinationStations}.',
    en: 'The <strong>travel time {formattedTrip2}</strong> is usually between <strong>{minDuration}</strong> and <strong>{maxDuration}</strong>.\n\nThis {transportType} {companyName} departs from {originStations} and arrives at {destinationStations}.',
    es: 'El <strong>tiempo de viaje {formattedTrip2}</strong> generalmente está entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>.\n\nEste {transportType} {companyName} sale de {originStations} y llega a {destinationStations}.',
    it: 'Il <strong>tempo di viaggio {formattedTrip2}</strong> è di solito compreso tra <strong>{minDuration}</strong> e <strong>{maxDuration}</strong>.\n\nQuesto {transportType} {companyName} parte da {originStations} e arriva a {destinationStations}.',
    de: 'Die <strong>Reisezeit {formattedTrip2}</strong> liegt normalerweise zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>.\n\nDieser {transportType} {companyName} fährt von {originStations} ab und kommt in {destinationStations} an.',
    nl: 'De <strong>reistijd {formattedTrip2}</strong> is meestal tussen <strong>{minDuration}</strong> en <strong>{maxDuration}</strong>.\n\nDeze {transportType} {companyName} vertrekt van {originStations} en komt aan in {destinationStations}.',
  },
  [SPECIFIC_COMPANIES_TEXT_2[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_2[2]}`,
    defaultMessage:
      "Le tout premier départ en <strong> {transportType} {companyName} {formattedTrip1}</strong> de la journée s'effectue à <strong>{firstDepartureTime}</strong> et le dernier est normalement à <strong>{lastDepartureTime}</strong>.\n\nLa durée moyenne de ce voyage est de <strong>{meanDuration}</strong>.",
    en: 'The very first departure in <strong> {transportType} {companyName} {formattedTrip1}</strong> of the day is at <strong>{firstDepartureTime}</strong> and the last one is typically at <strong>{lastDepartureTime}</strong>.\n\nThe average duration of this trip is <strong>{meanDuration}</strong>.',
    es: 'El primer viaje en <strong> {transportType} {companyName} {formattedTrip1}</strong> del día es a las <strong>{firstDepartureTime}</strong> y el último suele ser a las <strong>{lastDepartureTime}</strong>.\n\nLa duración promedio de este viaje es de <strong>{meanDuration}</strong>.',
    it: "Il primo viaggio in <strong> {transportType} {companyName} {formattedTrip1}</strong> della giornata è alle <strong>{firstDepartureTime}</strong> e l'ultimo è tipicamente alle <strong>{lastDepartureTime}</strong>.\n\nLa durata media di questo viaggio è di <strong>{meanDuration}</strong>.",
    de: 'Die allererste Abfahrt in <strong> {transportType} {companyName} {formattedTrip1}</strong> des Tages ist um <strong>{firstDepartureTime}</strong> und die letzte ist normalerweise um <strong>{lastDepartureTime}</strong>.\n\nDie durchschnittliche Reisedauer dieser Fahrt beträgt <strong>{meanDuration}</strong>.',
    nl: 'Het allereerste vertrek in <strong> {transportType} {companyName} {formattedTrip1}</strong> van de dag is om <strong>{firstDepartureTime}</strong> en de laatste is meestal om <strong>{lastDepartureTime}</strong>.\n\nDe gemiddelde reisduur van deze reis is <strong>{meanDuration}</strong>.',
  },
  [SPECIFIC_COMPANIES_TEXT_3[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_3[0]}`,
    defaultMessage:
      'Vous avez choisi de voyager en {transportType} {companyName} ?\n\nEn moyenne, le temps de trajet <strong>{formattedTrip2}</strong> est de <strong>{meanDuration}</strong>, allant de <strong>{minDuration}</strong> à <strong>{maxDuration}</strong> au maximum.\n\nLe prix du billet de {transportType} {companyName} {formattedTrip1} est compris entre <strong>{minPrice}</strong> et <strong>{maxPrice}</strong>.\n\nLe <strong>prix {formattedTrip3}</strong> moyen est de <strong>{meanPrice}</strong>.',
    en: "You've chosen to travel with {transportType} {companyName}?\n\nOn average, the travel time <strong>{formattedTrip2}</strong> is <strong>{meanDuration}</strong>, ranging from <strong>{minDuration}</strong> to <strong>{maxDuration}</strong> at most.\n\nThe ticket price for {transportType} {companyName} {formattedTrip1} ranges between <strong>{minPrice}</strong> and <strong>{maxPrice}</strong>.\n\nThe average <strong>{formattedTrip3} price</strong> is <strong>{meanPrice}</strong>.",
    es: '¿Has elegido viajar en {transportType} {companyName}?\n\nEn promedio, el tiempo de viaje <strong>{formattedTrip2}</strong> es de <strong>{meanDuration}</strong>, variando de <strong>{minDuration}</strong> a <strong>{maxDuration}</strong> como máximo.\n\nEl precio del boleto de {transportType} {companyName} {formattedTrip1} varía entre <strong>{minPrice}</strong> y <strong>{maxPrice}</strong>.\n\nEl <strong>precio {formattedTrip3}</strong> promedio es de <strong>{meanPrice}</strong>.',
    it: 'Hai scelto di viaggiare con {transportType} {companyName}?\n\nIn media, il tempo di viaggio <strong>{formattedTrip2}</strong> è di <strong>{meanDuration}</strong>, variando da <strong>{minDuration}</strong> a <strong>{maxDuration}</strong> al massimo.\n\nIl prezzo del biglietto per {transportType} {companyName} {formattedTrip1} varia tra <strong>{minPrice}</strong> e <strong>{maxPrice}</strong>.\n\nIl <strong>prezzo {formattedTrip3}</strong> medio è di <strong>{meanPrice}</strong>.',
    de: 'Sie haben sich für eine Reise mit {transportType} {companyName} entschieden?\n\nIm Durchschnitt beträgt die Reisezeit <strong>{formattedTrip2}</strong> <strong>{meanDuration}</strong>, maximal <strong>{minDuration}</strong> bis <strong>{maxDuration}</strong>.\n\nDer Ticketpreis für {transportType} {companyName} {formattedTrip1} liegt zwischen <strong>{minPrice}</strong> und <strong>{maxPrice}</strong>.\n\nDer durchschnittliche <strong>{formattedTrip3}-Preis</strong> beträgt <strong>{meanPrice}</strong>.',
    nl: 'Je hebt ervoor gekozen om te reizen met {transportType} {companyName}?\n\nGemiddeld genomen is de reistijd <strong>{formattedTrip2}</strong> <strong>{meanDuration}</strong>, variërend van <strong>{minDuration}</strong> tot <strong>{maxDuration}</strong>.\n\nDe ticketprijs voor {transportType} {companyName} {formattedTrip1} varieert tussen <strong>{minPrice}</strong> en <strong>{maxPrice}</strong>.\n\nDe gemiddelde <strong>{formattedTrip3}-prijs</strong> is <strong>{meanPrice}</strong>.',
  },
  [SPECIFIC_COMPANIES_TEXT_3[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_3[1]}`,
    defaultMessage:
      'Votre {transportType} {companyName} {formattedTrip1} est au départ de {originStations}.\n\nVous arriverez ensuite en gare de {destinationStations}.',
    en: 'Your {transportType} {companyName} {formattedTrip1} departs from {originStations}.\n\nYou will then arrive at {destinationStations}.',
    es: 'Tu {transportType} {companyName} {formattedTrip1} sale de {originStations}.\n\nLlegarás después a {destinationStations}.',
    it: 'Il tuo {transportType} {companyName} {formattedTrip1} parte da {originStations}.\n\nArriverai poi a {destinationStations}.',
    de: 'Ihr {transportType} {companyName} {formattedTrip1} fährt ab {originStations} ab.\n\nSie kommen dann am Bahnhof {destinationStations} an.',
    nl: 'Uw {transportType} {companyName} {formattedTrip1} vertrekt vanuit {originStations}.\n\nU zult vervolgens aankomen op station {destinationStations}.',
  },
  [SPECIFIC_COMPANIES_TEXT_3[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_3[2]}`,
    defaultMessage:
      'Le tout premier départ journalier du <strong> {transportType} {companyName} {formattedTrip1}</strong> a lieu chaque jour à <strong>{firstDepartureTime}</strong> alors que le dernier sera à <strong>{lastDepartureTime}</strong>.',
    en: 'The first daily departure of <strong> {transportType} {companyName} {formattedTrip1}</strong> is at <strong>{firstDepartureTime}</strong> every day, while the last one is at <strong>{lastDepartureTime}</strong>.',
    es: 'La primera salida diaria de <strong> {transportType} {companyName} {formattedTrip1}</strong> es a las <strong>{firstDepartureTime}</strong> todos los días, mientras que la última es a las <strong>{lastDepartureTime}</strong>.',
    it: "La prima partenza giornaliera di <strong> {transportType} {companyName} {formattedTrip1}</strong> è alle <strong>{firstDepartureTime}</strong> ogni giorno, mentre l'ultima è alle <strong>{lastDepartureTime}</strong>.",
    de: 'Die erste tägliche Abfahrt von <strong> {transportType} {companyName} {formattedTrip1}</strong> ist jeden Tag um <strong>{firstDepartureTime}</strong>, während die letzte um <strong>{lastDepartureTime}</strong> ist.',
    nl: 'De eerste dagelijkse vertrek van <strong> {transportType} {companyName} {formattedTrip1}</strong> is om <strong>{firstDepartureTime}</strong> elke dag, terwijl de laatste om <strong>{lastDepartureTime}</strong> is.',
  },
  [SPECIFIC_COMPANIES_TEXT_4[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_4[0]}`,
    defaultMessage:
      "Pour un aller {formattedTrip1} en {transportType} {companyName}, vous disposez d'un itinéraire {formattedTrip3} dont le prix est compris entre <strong>{minPrice}</strong> et <strong>{maxPrice}</strong>. Pour réaliser ce déplacement, comptez un temps de trajet {formattedTrip2} compris entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>.",
    en: 'For a one-way {formattedTrip1} in {transportType} {companyName}, you have an itinerary {formattedTrip3} with prices ranging from <strong>{minPrice}</strong> to <strong>{maxPrice}</strong>. For this journey, expect a travel time of {formattedTrip2} ranging from <strong>{minDuration}</strong> to <strong>{maxDuration}</strong>.',
    es: 'Para un viaje de ida {formattedTrip1} en {transportType} {companyName}, tienes un itinerario {formattedTrip3} con precios que van desde <strong>{minPrice}</strong> hasta <strong>{maxPrice}</strong>. Para este viaje, espera un tiempo de viaje {formattedTrip2} que va desde <strong>{minDuration}</strong> hasta <strong>{maxDuration}</strong>.',
    it: 'Per un viaggio di sola andata {formattedTrip1} in {transportType} {companyName}, hai un itinerario {formattedTrip3} con prezzi che vanno da <strong>{minPrice}</strong> a <strong>{maxPrice}</strong>. Per questo viaggio, aspettati un tempo di viaggio {formattedTrip2} che va da <strong>{minDuration}</strong> a <strong>{maxDuration}</strong>.',
    de: 'Für eine einfache Fahrt {formattedTrip1} mit {transportType} {companyName} haben Sie eine Route {formattedTrip3} mit Preisen zwischen <strong>{minPrice}</strong> und <strong>{maxPrice}</strong>. Für diese Reise erwarten Sie eine Reisezeit {formattedTrip2} von <strong>{minDuration}</strong> bis <strong>{maxDuration}</strong>.',
    nl: 'Voor een enkele reis {formattedTrip1} met {transportType} {companyName}, heb je een reisroute {formattedTrip3} met prijzen variërend van <strong>{minPrice}</strong> tot <strong>{maxPrice}</strong>. Voor deze reis, verwacht een reistijd {formattedTrip2} variërend van <strong>{minDuration}</strong> tot <strong>{maxDuration}</strong>.',
  },
  [SPECIFIC_COMPANIES_TEXT_4[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_4[1]}`,
    defaultMessage:
      "Si vous souhaitez une estimation du prix moyen d'un billet {companyName} {formattedTrip2} en {transportType}, il est généralement de <strong>{meanPrice}</strong> pour <strong>{meanDuration}</strong> de temps de trajet moyen.",
    en: 'If you want an estimate of the average price for a {companyName} {formattedTrip2} ticket in {transportType}, it is generally <strong>{meanPrice}</strong> for a <strong>{meanDuration}</strong> average travel time.',
    es: 'Si desea una estimación del precio medio de un billete {companyName} {formattedTrip2} en {transportType}, generalmente es de <strong>{meanPrice}</strong> para un tiempo de viaje promedio de <strong>{meanDuration}</strong>.',
    it: 'Se desideri una stima del prezzo medio di un biglietto {companyName} {formattedTrip2} in {transportType}, è generalmente di <strong>{meanPrice}</strong> per un tempo di viaggio medio di <strong>{meanDuration}</strong>.',
    de: 'Wenn Sie eine Schätzung des Durchschnittspreises für ein {companyName} {formattedTrip2}-Ticket in {transportType} möchten, beträgt dieser in der Regel <strong>{meanPrice}</strong> für eine durchschnittliche Reisezeit von <strong>{meanDuration}</strong>.',
    nl: 'Als je een schatting wilt van de gemiddelde prijs voor een {companyName} {formattedTrip2} ticket in {transportType}, is het over het algemeen <strong>{meanPrice}</strong> voor een gemiddelde reistijd van <strong>{meanDuration}</strong>.',
  },
  [SPECIFIC_COMPANIES_TEXT_4[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_4[2]}`,
    defaultMessage:
      'Votre {transportType} {companyName} part initialement de {originStations} et arrive à {destinationStations}.',
    en: 'Your {transportType} {companyName} initially departs from {originStations} and arrives at {destinationStations}.',
    es: 'Su {transportType} {companyName} sale inicialmente de {originStations} y llega a {destinationStations}.',
    it: 'Il tuo {transportType} {companyName} parte inizialmente da {originStations} e arriva a {destinationStations}.',
    de: 'Ihr {transportType} {companyName} fährt zunächst von {originStations} ab und kommt in {destinationStations} an.',
    nl: 'Uw {transportType} {companyName} vertrekt in eerste instantie vanuit {originStations} en komt aan in {destinationStations}.',
  },
  [SPECIFIC_COMPANIES_TEXT_4[3]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_4[3]}`,
    defaultMessage:
      'Votre premier {transportType} {companyName} <strong> {formattedTrip1}</strong> de la journée part à <strong>{firstDepartureTime}, </strong> alors que le dernier {transportType} {companyName} sera à quai pour un départ à <strong>{lastDepartureTime}</strong>.',
    en: 'Your first {transportType} {companyName} <strong> {formattedTrip1}</strong> of the day departs at <strong>{firstDepartureTime}, </strong> while the last {transportType} {companyName} will be ready for departure at <strong>{lastDepartureTime}</strong>.',
    es: 'Su primer {transportType} {companyName} <strong> {formattedTrip1}</strong> del día parte a las <strong>{firstDepartureTime}, </strong> mientras que el último {transportType} {companyName} estará listo para la salida a las <strong>{lastDepartureTime}</strong>.',
    it: "Il vostro primo {transportType} {companyName} <strong> {formattedTrip1}</strong> del giorno parte alle <strong>{firstDepartureTime}, </strong> mentre l'ultimo {transportType} {companyName} sarà pronto per la partenza alle <strong>{lastDepartureTime}</strong>.",
    de: 'Ihr erster {transportType} {companyName} <strong> {formattedTrip1}</strong> des Tages fährt um <strong>{firstDepartureTime}, </strong> während der letzte {transportType} {companyName} bereit ist zur Abfahrt um <strong>{lastDepartureTime}</strong>.',
    nl: 'Uw eerste {transportType} {companyName} <strong> {formattedTrip1}</strong> van de dag vertrekt om <strong>{firstDepartureTime}, </strong> terwijl de laatste {transportType} {companyName} klaar staat voor vertrek om <strong>{lastDepartureTime}</strong>.',
  },
  [SPECIFIC_COMPANIES_TEXT_5[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_5[0]}`,
    defaultMessage:
      "Oui, vous avez la possibilité de monter à bord d'un {transportType} {companyName} pour effectuer le trajet <strong>{formattedTrip2}</strong>.",
    en: 'Yes, you have the option to board a {transportType} {companyName} to make the journey <strong>{formattedTrip2}</strong>.',
    es: 'Sí, tienes la opción de abordar un {transportType} {companyName} para realizar el viaje <strong>{formattedTrip2}</strong>.',
    it: 'Sì, hai la possibilità di salire a bordo di un {transportType} {companyName} per fare il viaggio <strong>{formattedTrip2}</strong>.',
    de: 'Ja, Sie haben die Möglichkeit, mit einem {transportType} {companyName} die Reise <strong>{formattedTrip2}</strong> zu machen.',
    nl: 'Ja, u heeft de mogelijkheid om aan boord te gaan van een {transportType} {companyName} om de reis <strong>{formattedTrip2}</strong> te maken.',
  },
  [SPECIFIC_COMPANIES_TEXT_5[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_5[1]}`,
    defaultMessage:
      "Vous voulez connaître le prix du billet {formattedTrip1} en {transportType} {companyName} ? Celui-ci se trouve dans la fourchette entre <strong>{minPrice}</strong> et <strong>{maxPrice}</strong>. Pensez à utiliser notre outil de comparaison des prix selon votre date de départ juste ci-dessus. C'est bien pratique !",
    en: "Want to know the price of the {formattedTrip1} ticket on {transportType} {companyName}? It ranges between <strong>{minPrice}</strong> and <strong>{maxPrice}</strong>. Make sure to use our price comparison tool based on your departure date just above. It's really handy!",
    es: '¿Quieres conocer el precio del billete {formattedTrip1} en {transportType} {companyName}? Este se encuentra en el rango entre <strong>{minPrice}</strong> y <strong>{maxPrice}</strong>. Asegúrate de utilizar nuestra herramienta de comparación de precios según tu fecha de salida justo arriba. ¡Es muy práctico!',
    it: "Vuoi conoscere il prezzo del biglietto {formattedTrip1} su {transportType} {companyName}? Si trova nell'intervallo tra <strong>{minPrice}</strong> e <strong>{maxPrice}</strong>. Assicurati di utilizzare il nostro strumento di confronto prezzi in base alla tua data di partenza qui sopra. È davvero utile!",
    de: 'Möchten Sie den Preis für das Ticket {formattedTrip1} bei {transportType} {companyName} erfahren? Dieser liegt zwischen <strong>{minPrice}</strong> und <strong>{maxPrice}</strong>. Verwenden Sie unser Preisvergleichstool basierend auf Ihrem Abreisedatum direkt oben. Es ist wirklich praktisch!',
    nl: 'Wilt u de prijs van het ticket {formattedTrip1} weten bij {transportType} {companyName}? Dit varieert tussen <strong>{minPrice}</strong> en <strong>{maxPrice}</strong>. Gebruik onze prijsvergelijkingstool op basis van uw vertrekdatum direct hierboven. Het is erg handig!',
  },
  [SPECIFIC_COMPANIES_TEXT_5[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_5[2]}`,
    defaultMessage:
      'La durée de votre voyage {formattedTrip2} en {transportType} {companyName} est comprise entre <strong>{minDuration}</strong> et <strong>{maxDuration}</strong>. Le lieu de départ du {transportType} est situé à {originStations}. Ce {transportType} vous déposera à {destinationStations}.',
    en: 'The duration of your {formattedTrip2} journey by {transportType} {companyName} ranges from <strong>{minDuration}</strong> to <strong>{maxDuration}</strong>. The departure point of the {transportType} is located at {originStations}. This {transportType} will drop you off at {destinationStations}.',
    es: 'La duración de tu viaje {formattedTrip2} en {transportType} {companyName} varía entre <strong>{minDuration}</strong> y <strong>{maxDuration}</strong>. El punto de salida del {transportType} está ubicado en {originStations}. Este {transportType} te dejará en {destinationStations}.',
    it: 'La durata del tuo viaggio {formattedTrip2} in {transportType} {companyName} varia da <strong>{minDuration}</strong> a <strong>{maxDuration}</strong>. Il punto di partenza del {transportType} è situato a {originStations}. Questo {transportType} ti lascerà a {destinationStations}.',
    de: 'Die Dauer Ihrer Reise {formattedTrip2} mit {transportType} {companyName} beträgt zwischen <strong>{minDuration}</strong> und <strong>{maxDuration}</strong>. Der Abfahrtsort des {transportType} befindet sich in {originStations}. Dieser {transportType} bringt Sie nach {destinationStations}.',
    nl: 'De duur van uw reis {formattedTrip2} met {transportType} {companyName} varieert van <strong>{minDuration}</strong> tot <strong>{maxDuration}</strong>. Het vertrekpunt van de {transportType} bevindt zich in {originStations}. Deze {transportType} brengt u naar {destinationStations}.',
  },
  [SPECIFIC_COMPANIES_TEXT_5[3]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_5[3]}`,
    defaultMessage:
      'Vous êtes plutôt matinal(e) ? Le tout premier trajet en {transportType} {companyName} part à <strong>{firstDepartureTime}</strong>. Mais si vous préférez voyager de nuit ou partir en fin de journée, le tout dernier départ a lieu à <strong>{lastDepartureTime}</strong>.',
    en: 'Are you an early bird? The very first {transportType} {companyName} trip departs at <strong>{firstDepartureTime}</strong>. If you prefer traveling at night or later in the day, the last departure is at <strong>{lastDepartureTime}</strong>.',
    es: '¿Eres madrugador/a? El primer viaje en {transportType} {companyName} parte a las <strong>{firstDepartureTime}</strong>. Pero si prefieres viajar de noche o salir más tarde en el día, el último trayecto es a las <strong>{lastDepartureTime}</strong>.',
    it: "Sei un mattiniero? Il primo viaggio in {transportType} {companyName} parte alle <strong>{firstDepartureTime}</strong>. Se preferisci viaggiare di notte o partire più tardi durante la giornata, l'ultimo partenza è alle <strong>{lastDepartureTime}</strong>.",
    de: 'Sind Sie ein Frühaufsteher? Die erste {transportType} {companyName} Abfahrt ist um <strong>{firstDepartureTime}</strong>. Wenn Sie lieber nachts reisen oder später am Tag abreisen möchten, ist die letzte Abfahrt um <strong>{lastDepartureTime}</strong>.',
    nl: "Ben je een vroege vogel? De allereerste {transportType} {companyName} vertrekt om <strong>{firstDepartureTime}</strong>. Als je liever 's nachts reist of later op de dag vertrekt, vertrekt de laatste om <strong>{lastDepartureTime}</strong>.",
  },
  [SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[0]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[0]}`,
    defaultMessage:
      'En temps normal, vous aurez le choix entre <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} par jour.',
    en: "Normally, you'll have the choice of <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} per day.",
    es: 'Normalmente, tendrás la opción de <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} por día.',
    it: 'Normalmente, avrai la scelta di <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} al giorno.',
    de: 'Normalerweise haben Sie die Wahl zwischen <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} pro Tag.',
    nl: 'Normaal gesproken heb je de keuze uit <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} per dag.',
  },
  [SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[1]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[1]}`,
    defaultMessage:
      'En règle général, il y a chaque jour <strong>{dailyTrips} {transportType} {companyName} {formattedTrip2}</strong>.',
    en: 'Generally, there are <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} daily.',
    es: 'Generalmente, hay <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} diarios.',
    it: 'In generale, ci sono <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2} al giorno.',
    de: 'Im Allgemeinen gibt es täglich <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2}.',
    nl: 'Over het algemeen zijn er dagelijks <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2}.',
  },
  [SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[2]]: {
    id: `${searchScope}.${SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[2]}`,
    defaultMessage:
      'Chaque jour, vous avez le choix entre <strong>{dailyTrips} {transportType} {companyName} {formattedTrip2}</strong>.',
    en: 'Every day, you have the choice between <strong>{dailyTrips}</strong> {transportType} {companyName} {formattedTrip2}.',
    es: 'Cada día, tienes la opción entre <strong>{dailyTrips} {transportType} {companyName} {formattedTrip2}</strong>.',
    it: 'Ogni giorno, hai la scelta tra <strong>{dailyTrips} {transportType} {companyName} {formattedTrip2}</strong>.',
    de: 'Jeden Tag haben Sie die Wahl zwischen <strong>{dailyTrips} {transportType} {companyName} {formattedTrip2}</strong>.',
    nl: 'Elke dag heb je de keuze tussen <strong>{dailyTrips} {transportType} {companyName} {formattedTrip2}</strong>.',
  },

  bestCompanyTitle: {
    id: `${searchScope}.bestCompanyTitle`,
    defaultMessage:
      '<span class="highlight">Meilleure compagnie de {transportType}</span> pour&nbsp;{origin}&nbsp;-&nbsp;{destination}',
    en: '<span class="highlight">Best {transportType} company</span> for {origin} - {destination}',
    es: '<span class="highlight">Mejor compañía de {transportType}</span> para {origin} - {destination}',
    it: '<span class="highlight">Migliore compagnia di {transportType}</span> per {origin} - {destination}',
    de: '<span class="highlight">Beste {transportType} Gesellschaft</span> für {origin} - {destination}',
    nl: '<span class="highlight">Beste {transportType} bedrijf</span> voor {origin} - {destination}',
  },
  averageCheapestCompany: {
    id: `${searchScope}.averageCheapestCompany`,
    defaultMessage:
      'En moyenne le {transportType} le moins cher est : {companyName}',
    en: 'On average, the cheapest {transportType} is: {companyName}',
    es: 'En promedio, el {transportType} más barato es: {companyName}',
    it: 'In media, il {transportType} più economico è: {companyName}',
    de: 'Im Durchschnitt ist der günstigste {transportType}: {companyName}',
    nl: 'Gemiddeld is de goedkoopste {transportType}: {companyName}',
  },
  fastestCompany: {
    id: `${searchScope}.fastestCompany`,
    defaultMessage:
      'Le {transportType} {companyName} est le plus rapide pour faire le trajet {origin} - {destination}',
    en: 'The {transportType} {companyName} is the fastest for the {origin} - {destination} route',
    es: 'El {transportType} {companyName} es el más rápido para la ruta {origin} - {destination}',
    it: 'Il {transportType} {companyName} è il più veloce per la tratta {origin} - {destination}',
    de: 'Der {transportType} {companyName} ist der schnellste für die Strecke {origin} - {destination}',
    nl: 'De {transportType} {companyName} is de snelste voor de route {origin} - {destination}',
  },
  mostFlexibleCompany: {
    id: `${searchScope}.mostFlexibleCompany`,
    defaultMessage:
      'Vous aurez plus de flexibilité avec les {transportType} {companyName} car ils ont plus de fréquence',
    en: 'You will have more flexibility with {companyName} {transportType} because they run more frequently',
    es: 'Tendrás más flexibilidad con {transportType} {companyName} porque tienen más frecuencia',
    it: 'Avrai più flessibilità con {companyName} {transportType} perché hanno maggiore frequenza',
    de: 'Sie haben mehr Flexibilität mit {companyName} {transportType}, da sie häufiger fahren',
    nl: 'Je hebt meer flexibiliteit met {companyName} {transportType} omdat ze vaker rijden',
  },
};

export default defineMessages(searchMessages);
