import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const CirclesContainer = styled.div`
  ${tw`absolute h-full`};
  width: 95%;
`;

const DefaultCircle = styled.div`
  ${tw`absolute rounded-full`};
  height: 30vw;
  width: 30vw;
  border: 6vw solid white;
`;

const Circle1 = styled(DefaultCircle)`
  top: 20%;
  left: -10%;
`;

const Circle2 = styled(DefaultCircle)`
  top: -10%;
  right: -23%;
`;

const Circle3 = styled(DefaultCircle)`
  top: 65%;
  right: 10%;
`;

const Circles = () => (
  <CirclesContainer>
    <Circle1 />
    <Circle2 />
    <Circle3 />
  </CirclesContainer>
);

export { Circles };
