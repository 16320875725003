import { NAVIGATION } from '../containers/App/constants';
import {
  ACCESS_TOKEN,
  API_URL,
  BASIC_REQUEST_HEADERS,
  HTTP,
  HTTP_STATUS_CODES,
  REFRESH_TOKEN,
  TOKEN_EXPIRED_ERROR_KEY,
} from '../helpers/constants';
import { Sentry } from '../libs/sentry';
import history from '../utils/history';
import { disabledErrorKeys } from './constants';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === HTTP.STATUS_CODES.RESET_CONTENT) {
    return null;
  }
  if (response.status === HTTP.STATUS_CODES.NO_CONTENT) {
    return HTTP.STATUS_CODES.NO_CONTENT;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param {Response} response A response from a network request
 * @param {string} url The URL we want to request
 * @param {object} options The options we want to pass to "fetch"
 * @param {number} retry Number of retry
 * @return {Response|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response, url, options, retry) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === HTTP_STATUS_CODES.BAD_GATEWAY) {
    await delay(1000);

    return requestRaw(url, options, retry);
  }

  let error;
  try {
    const { errorKey, errorMessage, fields } = await response.json();

    if (errorKey && errorKey === TOKEN_EXPIRED_ERROR_KEY) {
      const res = await fetch(`${API_URL}/auth`, {
        method: HTTP.METHODS.PUT,
        headers: {
          ...BASIC_REQUEST_HEADERS,
          authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          refresh: `Refresh ${localStorage.getItem(REFRESH_TOKEN)}`,
        },
      });
      if (res.status === 200 && retry > 0) {
        const { refreshToken, token } = await res.json();
        localStorage.setItem(ACCESS_TOKEN, token);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);

        return requestRaw(
          url,
          {
            ...options,
            headers: { ...options.headers, authorization: `Bearer ${token}` },
          },
          retry - 1,
        );
      } else {
        history.push(NAVIGATION.LOGIN);
      }
    }

    error = await formatError(response, errorKey, errorMessage, fields);
  } catch (e) {
    error = new Error(e);
    error.response = response;
    error.capturedBySentry = true;
  }

  if (isPrerender(window) && isErrorIgnored(error)) {
    throw error;
  }

  Sentry.withScope((scope) => {
    scope.setExtra('status', response.status);
    scope.setExtra('url', response.url);
    scope.setExtra('type', response.type);
    scope.setExtra('fields', error.fields);
    Sentry.captureException(error);
  });

  throw error;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @description Formats error thrown using error key if it exists
 * @param {Response} response
 * @returns {Promise<Error>}
 */
const formatError = async (response, errorKey, errorMessage, fields) => {
  let error = new Error(`Unexpected error ${response.status}`);

  if (errorKey) {
    error = new Error(errorKey);
    error.errorKey = errorKey;
    error.errorMessage = errorMessage;
  }
  if (fields) {
    error.fields = fields;
  }

  error.response = response;
  error.capturedBySentry = true;

  return error;
};

/**
 * @description Requests a URL, returning a promise
 * @param  {string} url - The URL we want to request
 * @param  {object} [options] - The options we want to pass to "fetch"*
 * @param {number} retry Number of retry
 * @return {Response} - The response
 */
export async function requestRaw(url, options, retry = 1) {
  return fetch(url, options).then((res) =>
    checkStatus(res, url, options, retry),
  );
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param {number} retry Number of retry
 * @return {object}           The response data
 */
export default async function request(url, options, retry = 1) {
  try {
    const response = await fetch(url, options)
      .then((res) => checkStatus(res, url, options, retry))
      .then(parseJSON);

    return response;
  } catch (error) {
    if (error?.message === 'Failed to fetch') {
      await delay(1000);
      return request(url, options, retry);
    }

    throw error;
  }
}

export const isPrerender = ({ navigator }) =>
  // We are relying on PreRender's documentation
  // https://docs.prerender.io/article/7-faq
  /^.*Prerender \(\+https:\/\/github\.com\/prerender\/prerender\).*$/.test(
    navigator.userAgent,
  );

export const isErrorIgnored = ({ errorKey }) =>
  !!errorKey && disabledErrorKeys.includes(errorKey);
