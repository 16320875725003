import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyNotFoundPage = lazy(() =>
  import(/* webpackChunkName: 'NotFoundPage' */ './index'),
);

export default function NotFoundPage() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyNotFoundPage />
    </Suspense>
  );
}
