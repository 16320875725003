import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyPassengersInfo = lazy(
  () => import(/* webpackChunkName: 'PassengersInfo2' */ './index'),
);

export default function PassengersInfo(props) {
  return (
    <div>
      <Suspense fallback={<LazyLoading />}>
        <LazyPassengersInfo {...props} />
      </Suspense>
    </div>
  );
}
