import { EPassengerCategory } from '../../common/types';
import { dayjs } from '../../libs/day';
import { PASSENGER_CATEGORY_RANGES, PASSENGERS_DATE_FORMAT } from './constants';

/**
 * @description Checks whether the date of birth selected for the passenger is valid
 * @param {string} birthdate The passenger birthdate
 * @returns {boolean}
 */
export const isPassengerBirthDateValid = (birthdate: string): boolean => {
  const date: dayjs.Dayjs = dayjs(birthdate, PASSENGERS_DATE_FORMAT);

  // The date must be valid and in the past
  if (
    !date.isValid() ||
    date.isAfter(dayjs()) ||
    date.isBefore('1900-01-01', 'year')
  ) {
    return false;
  }

  return true;
};

/**
 * @description Checks whether the identity document expiration date of the passenger is valid
 * @param {string} expirationDate
 * @returns {boolean}
 */
export const isPassengerIdendityDocumentExpirationDateValid = (
  expirationDate: string,
): boolean => {
  const date: dayjs.Dayjs = dayjs(expirationDate, PASSENGERS_DATE_FORMAT);

  // The date must be valid and in the future
  if (!date.isValid() || date.isBefore(dayjs())) {
    return false;
  }

  return true;
};

/**
 * @description Gets the age of the passenger from their birthdate.
 * @param {string} birthdate The passenger's birthdate in the format "'DD/MM/YYYY".
 * @returns {number | undefined} The passenger's age in years, or undefined if the birthdate is not valid.
 */
export const getPassengerAge = (birthdate: string): number | undefined => {
  if (!isPassengerBirthDateValid(birthdate)) {
    return;
  }

  return dayjs().diff(dayjs(birthdate, PASSENGERS_DATE_FORMAT), 'year');
};

export const getPassengerCategoryFromAge = (
  age: number,
): EPassengerCategory => {
  if (age <= PASSENGER_CATEGORY_RANGES[EPassengerCategory.YOUNG].max) {
    return EPassengerCategory.YOUNG;
  } else if (age <= PASSENGER_CATEGORY_RANGES[EPassengerCategory.ADULT].max) {
    return EPassengerCategory.ADULT;
  }

  return EPassengerCategory.SENIOR;
};

export const getDefaultAgeByCategory = (
  category: EPassengerCategory,
): number | undefined => {
  switch (category) {
    case EPassengerCategory.ADULT:
      return 30;

    case EPassengerCategory.SENIOR:
      return 60;

    default:
      return undefined;
  }
};
