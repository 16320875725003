/*
 *
 * Results reducer
 *
 */

import { fromJS } from 'immutable';
import { OUTBOUND, RETURN } from '../../containers/App/constants';
import {
  CART_ID,
  DEFAULT_SELECTED_TRIP,
  REPLACE_TRIPS,
  RESET_TRIPS,
  SELECT_TRIP,
  SET_CART_ID,
} from './constants';

const initialSelectedTrip = {
  outboundTrip: DEFAULT_SELECTED_TRIP,
  returnTrip: DEFAULT_SELECTED_TRIP,
};

export const initialState = fromJS({
  selectedTrip: initialSelectedTrip,
  [CART_ID]: undefined,
});

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_TRIPS:
      if ([OUTBOUND, RETURN].includes(action.direction)) {
        return state.setIn(
          ['selectedTrip', action.direction],
          fromJS(DEFAULT_SELECTED_TRIP),
        );
      }

      return state.set('selectedTrip', fromJS(initialSelectedTrip));

    case SELECT_TRIP:
      return state.setIn(
        ['selectedTrip', action.direction],
        fromJS(action.trip),
      );
    case REPLACE_TRIPS: {
      const newState = state
        .set('selectedTrip', fromJS(action.trips))
        .setIn(
          ['selectedTrip', 'outboundTrip', 'id'],
          state.getIn(['selectedTrip', 'outboundTrip', 'id']),
        );
      if (state.getIn(['selectedTrip', 'returnTrip'])) {
        return newState.setIn(
          ['selectedTrip', 'returnTrip', 'id'],
          state.getIn(['selectedTrip', 'returnTrip', 'id']),
        );
      }
      return newState;
    }
    case SET_CART_ID:
      return state.set(CART_ID, action.cartId);

    default:
      return state;
  }
}

export default cartReducer;
