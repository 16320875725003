import { ETransportType } from '../../common/types';
import { IResultsTrips } from '../../services/results/types';

export enum EResultsFilterType {
  STOPOVERS = 'stopovers',
  TIMES = 'times',
  DEPARTURE_STATIONS = 'departureStations',
  ARRIVAL_STATIONS = 'arrivalStations',
  COMPANIES = 'companies',
  MAXIMUM_PRICE = 'maximumPrice',
  MAXIMUM_DURATION = 'maximumDuration',
}

export enum EStopsFilter {
  DIRECT = 'STOPS_FILTER_DIRECT',
  ONE_STOP = 'STOPS_FILTER_ONE_STOP',
  SAME_STATIONS = 'STOPS_FILTER_SAME_STATIONS',
}

export enum ETimesFilter {
  NIGHT = 'TIME_FILTER_NIGHT',
  MORNING = 'TIME_FILTER_MORNING',
  AFTERNOON = 'TIME_FILTER_AFTERNOON',
  EVENING = 'TIME_FILTER_EVENING',
}

export enum ETimeOfDay {
  EARLY = 0,
  MORNING = 6,
  AFTERNOON = 12,
  EVENING = 18,
  DAY_END = 24,
}

export interface ITimeRange {
  // minimum hour (include)
  start: ETimeOfDay;
  // maximum hour (exclude)
  end: ETimeOfDay;
}

export type TimesFilterRanges = Record<ETimesFilter, ITimeRange>;

export interface IUnsharedFilters {
  [EResultsFilterType.ARRIVAL_STATIONS]: string[];
  [EResultsFilterType.COMPANIES]: string[];
  [EResultsFilterType.DEPARTURE_STATIONS]: string[];
  [EResultsFilterType.MAXIMUM_DURATION]: number | null;
  [EResultsFilterType.MAXIMUM_PRICE]: number | null;
}

export interface IResultsFilters {
  [ETransportType.TRAIN]: IUnsharedFilters;
  [ETransportType.BUS]: IUnsharedFilters;
  [ETransportType.CARPOOLING]: IUnsharedFilters;
  [ETransportType.MULTIMODAL]: IUnsharedFilters;

  [EResultsFilterType.STOPOVERS]: Record<EStopsFilter, boolean>;
  [EResultsFilterType.TIMES]: Record<ETimesFilter, boolean>;
}

export interface IResultsSummaryRange {
  min: number | null;
  max: number | null;
}

export interface IResultsDynamicFilter {
  name: string;
  isAvailable: boolean;
}

export interface IResultsSummary {
  priceRange: IResultsSummaryRange;
  durationMinutesRange: IResultsSummaryRange;
  carbonFootprintGRange: IResultsSummaryRange;
  departureTimeRange: IResultsSummaryRange;
  scoreRange: IResultsSummaryRange;
  companies: IResultsDynamicFilter[];
  departureStations: IResultsDynamicFilter[];
  arrivalStations: IResultsDynamicFilter[];
  // Represents whether there are trips in a time slot
  departureTimes: Record<ETimesFilter, boolean>;
  stopovers: Record<EStopsFilter, boolean>;
}

export type IResultsSummaries = {
  [value in ETransportType]: IResultsSummary;
} & { all: IResultsSummary };

export type UseResultsFilterContext = {
  numberOfActivatedFilters: Record<ETransportType, number>;
  filteredResults: IResultsTrips;
  filters: IResultsFilters;
  summaries: IResultsSummaries;
  toggleStopover: (mode: EStopsFilter) => void;
  toggleTime: (mode: ETimesFilter) => void;
  setTimeFilterFromMinimumHour: (hour: number) => void;
  toggleDeparetureStation: (
    transportType: ETransportType,
    station: string,
  ) => void;
  toggleArrivalStation: (
    transportType: ETransportType,
    station: string,
  ) => void;
  toggleCompany: (transportType: ETransportType, company: string) => void;
  setMaximumPrice: (transportType: ETransportType, price: number) => void;
  setMaximumDuration: (transportType: ETransportType, duration: number) => void;
  resetFilters: () => void;
  setFilters: React.Dispatch<React.SetStateAction<IResultsFilters>>;
};
