import { defineMessages } from 'react-intl';
import { EPassengerTitle } from '../../../common/types';
import {
  CANCEL_ORDER,
  CONTACT_SUPPORT,
  CONTACT_SUPPORT_LINK,
  CURRENT_ORDERS,
  CUSTOMER_EMAIL,
  DOWNLOAD_TICKETS,
  EDIT_ORDER,
  NO_DISCOUNT_CARD,
  OPEN_ORDER_DETAILS,
  ORDER_DETAILS,
  ORDER_ID,
  ORDER_ID_COPIED,
  ordersScope,
  PAGE_TITLE,
  PASSENGER,
  PASSENGERS,
  PAST_ORDERS,
  REVIEW,
  TOTAL_PRICE,
} from '../constants';

export const ordersMessages = {
  [PAGE_TITLE]: {
    id: `${ordersScope}.${PAGE_TITLE}`,
    defaultMessage: 'Mes billets',
    en: 'My orders',
    it: 'I miei biglietti',
    es: 'Mis pedidos',
    de: 'Meine Tickets',
    nl: 'Mijn tickets',
  },
  [CUSTOMER_EMAIL]: {
    id: `${ordersScope}.${CUSTOMER_EMAIL}`,
    defaultMessage: 'Référence client : ',
    en: 'Customer reference: ',
    it: 'Riferimento del cliente: ',
    es: 'Referencia del cliente: ',
    de: 'Kundenreferenz: ',
    nl: 'Klant referentie: ',
  },
  [CONTACT_SUPPORT]: {
    id: `${ordersScope}.${CONTACT_SUPPORT}`,
    defaultMessage: 'Un souci avec ma commande :',
    en: 'A problem with my order:',
    it: 'Un problema con il mio ordine:',
    es: 'Un problema con mi pedido:',
    de: 'Ein Problem mit meiner Bestellung:',
    nl: 'Een probleem met mijn bestelling:',
  },
  [CONTACT_SUPPORT_LINK]: {
    id: `${ordersScope}.${CONTACT_SUPPORT_LINK}`,
    defaultMessage: 'contacter le service client',
    en: 'contact the support',
    it: 'contattare il servizio clienti',
    es: 'contactar con el servicio de atención al cliente',
    de: 'den Kundenservice kontaktieren',
    nl: 'contact opnemen met klantenservice',
  },
  [CURRENT_ORDERS]: {
    id: `${ordersScope}.${CURRENT_ORDERS}`,
    defaultMessage: 'Trajets à venir',
    en: 'Upcoming trips',
    it: 'Prossimi itinerari',
    es: 'Próximas rutas',
    de: 'Zukünftige Fahrten',
    nl: 'Komende routes',
  },
  [PAST_ORDERS]: {
    id: `${ordersScope}.${PAST_ORDERS}`,
    defaultMessage: 'Trajets effectués',
    en: 'Past trips',
    it: 'Viaggi effettuati',
    es: 'Viajes realizados',
    de: 'Durchgeführte Fahrten',
    nl: 'Gemaakte reizen',
  },
  [ORDER_ID]: {
    id: `${ordersScope}.${ORDER_ID}`,
    defaultMessage: 'n° de commande : ',
    en: 'order number: ',
    it: "numero d'ordine:",
    es: 'número de pedido: ',
    de: 'Bestellnummer: ',
    nl: 'bestelnummer: ',
  },
  [OPEN_ORDER_DETAILS]: {
    id: `${ordersScope}.${OPEN_ORDER_DETAILS}`,
    defaultMessage: 'Voir le détail du voyage',
    en: 'See order details',
    it: 'Vedi i dettagli del viaggio',
    es: 'Ver los detalles del viaje',
    de: 'Reisedetails anzeigen',
    nl: 'Zie de details van de reis',
  },
  [DOWNLOAD_TICKETS]: {
    id: `${ordersScope}.${DOWNLOAD_TICKETS}`,
    defaultMessage: 'Télécharger mes billets',
    en: 'Download my tickets',
    it: 'Scarica i miei biglietti',
    es: 'Descargar mis entradas',
    de: 'Meine Tickets herunterladen',
    nl: 'Mijn tickets downloaden',
  },
  [TOTAL_PRICE]: {
    id: `${ordersScope}.${TOTAL_PRICE}`,
    defaultMessage: 'Prix total :',
    en: 'Total price:',
    it: 'Prezzo totale:',
    es: 'Precio total:',
    de: 'Gesamtpreis:',
    nl: 'Totale prijs:',
  },
  [REVIEW]: {
    id: `${ordersScope}.${REVIEW}`,
    defaultMessage: 'Laisser un avis',
    en: 'Post a review',
    it: 'Lascia un commento',
    es: 'Deja un comentario',
    de: 'Eine Meinung hinterlassen',
    nl: 'Laat een reactie achter',
  },
  [ORDER_ID_COPIED]: {
    id: `${ordersScope}.${ORDER_ID_COPIED}`,
    defaultMessage: 'Copié !',
    en: 'Copied!',
    it: 'Ricevuto!',
    es: '¡Copiado!',
    de: 'Kopiert!',
    nl: 'Gekopieerd!',
  },
  [EDIT_ORDER]: {
    id: `${ordersScope}.${EDIT_ORDER}`,
    defaultMessage: 'Modifier la réservation',
    en: 'Modify the order',
    it: 'Modifica della prenotazione',
    es: 'Cambiar la reserva',
    de: 'Buchung ändern',
    nl: 'Wijzig reservering',
  },
  [CANCEL_ORDER]: {
    id: `${ordersScope}.${CANCEL_ORDER}`,
    defaultMessage: 'Annuler la réservation',
    en: 'Cancel the order',
    it: 'Annullamento della prenotazione',
    es: 'Cancelar la reserva',
    de: 'Buchung stornieren',
    nl: 'Reservering annuleren',
  },
  [ORDER_DETAILS]: {
    id: `${ordersScope}.${ORDER_DETAILS}`,
    defaultMessage: 'Détail du trajet',
    en: 'Travel detail',
    it: 'Détail du trajet',
    es: 'Detalles de la ruta',
    de: 'Detail der Fahrt',
    nl: 'Details van de route',
  },
  [PASSENGER]: {
    id: `${ordersScope}.${PASSENGER}`,
    defaultMessage: 'Passager',
    en: 'Passenger',
    it: 'Passeggero',
    es: 'Pasajeros',
    de: 'Passagier',
    nl: 'Passagier',
  },
  [PASSENGERS]: {
    id: `${ordersScope}.${PASSENGERS}`,
    defaultMessage: 'Passagers',
    en: 'Passengers',
    it: 'Passeggero',
    es: 'Pasajeros',
    de: 'Passagiere',
    nl: 'Passagier',
  },
  [NO_DISCOUNT_CARD]: {
    id: `${ordersScope}.${NO_DISCOUNT_CARD}`,
    defaultMessage: 'Pas de carte de réduction',
    en: 'No discount card',
    it: 'Nessuna carta sconti',
    es: 'Sin tarjeta de descuento',
    de: 'Keine Ermäßigungskarte',
    nl: 'Geen kortingskaart',
  },
  [EPassengerTitle.MR]: {
    id: `${ordersScope}.${EPassengerTitle.MR}`,
    defaultMessage: 'M.',
    en: 'Mr.',
    it: 'Sig.',
    es: 'Sr.',
    de: 'Herr.',
    nl: 'Mr.',
  },
  [EPassengerTitle.MS]: {
    id: `${ordersScope}.${EPassengerTitle.MS}`,
    defaultMessage: 'Mme.',
    en: 'Ms.',
    it: 'Sig.ra',
    es: 'Sra.',
    de: 'Frau.',
    nl: 'Mevrouw.',
  },
  [EPassengerTitle.MX]: {
    id: `${ordersScope}.${EPassengerTitle.MX}`,
    defaultMessage: 'Mx.',
    en: 'Mx.',
    it: 'Mx.',
    es: 'Mx.',
    de: 'Mx.',
    nl: 'Mx.',
  },

  LOGOUT: {
    id: `${ordersScope}.LOGOUT`,
    defaultMessage: 'Se déconnecter',
    en: 'Logout',
    it: 'Disconnessionea',
    es: 'Desconectando',
    de: 'Sich abmelden',
    nl: 'Loskoppelen',
  },
  logoutInProgress: {
    id: `${ordersScope}.logoutInProgress`,
    defaultMessage: 'Déconnexion en cours',
    en: 'Logout in progress',
    es: 'Cierre de sesión en curso',
    it: 'Disconnessione in corso',
    de: 'Abmeldung läuft',
    nl: 'Uitloggen bezig',
  },
};

export default defineMessages(ordersMessages);
