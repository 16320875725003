/*
 * Results Messages
 *
 * This contains all the text for the Results container.
 */

import { defineMessages } from 'react-intl';
import { ENotAvailableReason, ETripType } from '../../../common/types';
import { EResultsSortingMode } from '../../../hooks/useResults/types';
import {
  EResultsFilterType,
  EStopsFilter,
} from '../../../hooks/useResultsFilter/types';

export const resultsScope = 'app.containers.Results';

export const resultsMessages = {
  sort: {
    id: `${resultsScope}.sort`,
    defaultMessage: 'Trier par',
    en: 'Sort by',
    it: 'Ordina per',
    es: 'Ordenar por',
    de: 'Sortieren nach',
    nl: 'Sorteer op',
  },
  filter: {
    id: `${resultsScope}.filter`,
    defaultMessage: 'Filtrer',
    en: 'Filter',
    it: 'Filtro',
    es: 'Filtro',
    de: 'Filtern',
    nl: 'Filter',
  },
  [ETripType.OUTBOUND]: {
    id: `${resultsScope}.${ETripType.OUTBOUND}`,
    defaultMessage: 'Aller : ',
    en: 'Outbound : ',
    it: 'Andata : ',
    es: 'Salida : ',
    de: 'Gehen : ',
    nl: 'Uitgaand : ',
  },
  [ETripType.RETURN]: {
    id: `${resultsScope}.${[ETripType.RETURN]}`,
    defaultMessage: 'Retour : ',
    en: 'Return : ',
    it: 'Ritorno : ',
    es: 'Vuelta : ',
    de: 'Zurück : ',
    nl: 'Terug : ',
  },
  noFilterAvailable: {
    id: `${resultsScope}.noFilterAvailable`,
    defaultMessage: 'Aucun filtre disponible.',
    en: 'No filter available.',
    it: 'Nessun filtro disponibile.',
    es: 'No hay filtros disponibles.',
    de: 'Kein Filter verfügbar.',
    nl: 'Geen filter beschikbaar.',
  },
  [EResultsSortingMode.TIME]: {
    id: `${resultsScope}.${[EResultsSortingMode.TIME]}`,
    defaultMessage: 'Heure de départ',
    en: 'Departure time',
    it: 'Orario di partenza',
    es: 'Hora de salida',
    de: 'Abfahrtszeit',
    nl: 'Vertrektijd',
  },
  [EResultsSortingMode.SCORE]: {
    id: `${resultsScope}.${[EResultsSortingMode.SCORE]}`,
    defaultMessage: 'Moins cher et + rapide',
    en: 'Cheaper and faster',
    it: 'Meno caro e più veloce',
    es: 'Más barato y más rápido',
    de: 'Billiger und schneller',
    nl: 'Goedkoper en sneller',
  },

  [EResultsSortingMode.PRICE]: {
    id: `${resultsScope}.${[EResultsSortingMode.PRICE]}`,
    defaultMessage: 'Le moins cher',
    en: 'Cheapest',
    it: 'Il meno costoso',
    es: 'El más barato',
    de: 'Am billigsten',
    nl: 'Goedkoopste',
  },
  [EResultsSortingMode.DURATION]: {
    id: `${resultsScope}.${[EResultsSortingMode.DURATION]}`,
    defaultMessage: 'Le plus rapide',
    en: 'Fastest',
    it: 'Il più veloce',
    es: 'El más rápido',
    de: 'Der schnellste',
    nl: 'Snelste',
  },
  [EResultsSortingMode.CARBON_FOOTPRINT]: {
    id: `${resultsScope}.${[EResultsSortingMode.CARBON_FOOTPRINT]}`,
    defaultMessage: 'Le plus écologique',
    en: 'Most ecological',
    it: 'Il più ecologico',
    es: 'El más ecológico',
    de: 'Am ökologischsten',
    nl: 'Meest ecologisch',
  },

  [`${EResultsFilterType.TIMES}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.TIMES}_FILTER`,
    defaultMessage: 'Horaire de départ',
    en: 'Departure Time',
    it: 'Orario di partenza',
    es: 'Hora de salida',
    de: 'Abfahrtszeit',
    nl: 'Vertrektijd',
  },
  [`${EResultsFilterType.STOPOVERS}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.STOPOVERS}_FILTER`,
    defaultMessage: 'Correspondances',
    en: 'Connections',
    it: 'Corrispondenze',
    es: 'Conexiones',
    de: 'Verbindungen',
    nl: 'Overstappen',
  },
  [`${EResultsFilterType.MAXIMUM_PRICE}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.MAXIMUM_PRICE}_FILTER`,
    defaultMessage: 'Prix',
    en: 'Price',
    it: 'Prezzo',
    es: 'Precio',
    de: 'Preis',
    nl: 'Prijs',
  },
  [`${EResultsFilterType.MAXIMUM_DURATION}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.MAXIMUM_DURATION}_FILTER`,
    defaultMessage: 'Durée',
    en: 'Duration',
    it: 'Durata',
    es: 'Duración',
    de: 'Dauer',
    nl: 'Duur',
  },
  [`${EResultsFilterType.DEPARTURE_STATIONS}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.DEPARTURE_STATIONS}_FILTER`,
    defaultMessage: 'Station de départ',
    en: 'Departure station',
    it: 'Stazione di partenza',
    es: 'Estación de salida',
    de: 'Abfahrtsstation',
    nl: 'Vertrekstation',
  },
  [`${EResultsFilterType.ARRIVAL_STATIONS}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.ARRIVAL_STATIONS}_FILTER`,
    defaultMessage: "Station d'arrivée",
    en: 'Arrival station',
    it: 'Stazione di arrivo',
    es: 'Estación de llegada',
    de: 'Ankunftsstation',
    nl: 'Aankomststation',
  },
  [`${EResultsFilterType.COMPANIES}_FILTER`]: {
    id: `${resultsScope}.${EResultsFilterType.COMPANIES}_FILTER`,
    defaultMessage: 'Compagnies',
    en: 'Companies',
    it: 'Compagnie',
    es: 'Compañías',
    de: 'Unternehmen',
    nl: 'Bedrijven',
  },
  TIME_FILTER_NIGHT: {
    id: `${resultsScope}.TIME_FILTER_NIGHT`,
    defaultMessage: 'Nuit (00h - 06h)',
    en: 'Night (12am - 6am)',
    it: 'Notte (00:00 - 06:00)',
    es: 'Noche (00:00 - 06:00)',
    de: 'Nacht (00:00 - 06:00 Uhr)',
    nl: 'Nacht (00:00 - 06:00 uur)',
  },
  TIME_FILTER_MORNING: {
    id: `${resultsScope}.TIME_FILTER_MORNING`,
    defaultMessage: 'Matin (06h - 12h)',
    en: 'Morning (6am - 12pm)',
    it: 'Mattina (06:00 - 12:00)',
    es: 'Mañana (06:00 - 12:00)',
    de: 'Morgen (06:00 - 12:00 Uhr)',
    nl: 'Ochtend (06:00 - 12:00 uur)',
  },
  TIME_FILTER_AFTERNOON: {
    id: `${resultsScope}.TIME_FILTER_AFTERNOON`,
    defaultMessage: 'Après-midi (12h - 18h)',
    en: 'Afternoon (12pm - 6pm)',
    it: 'Pomeriggio (12:00 - 18:00)',
    es: 'Tarde (12:00 - 18:00)',
    de: 'Nachmittag (12:00 - 18:00 Uhr)',
    nl: 'Middag (12:00 - 18:00 uur)',
  },
  TIME_FILTER_EVENING: {
    id: `${resultsScope}.TIME_FILTER_EVENING`,
    defaultMessage: 'Soir (18h - 00h)',
    en: 'Evening (6pm - midnight)',
    it: 'Sera (18:00 - 00:00)',
    es: 'Noche (18:00 - 00:00)',
    de: 'Abend (18:00 - 00:00 Uhr)',
    nl: 'Avond (18:00 - 00:00 uur)',
  },

  [EStopsFilter.DIRECT]: {
    id: `${resultsScope}.${EStopsFilter.DIRECT}`,
    defaultMessage: 'Aucune',
    en: 'None',
    it: 'Nessuno',
    es: 'Ninguno',
    de: 'Keine',
    nl: 'Geen',
  },
  [EStopsFilter.ONE_STOP]: {
    id: `${resultsScope}.${EStopsFilter.ONE_STOP}`,
    defaultMessage: 'Une seule',
    en: 'One only',
    it: 'Solo uno',
    es: 'Sólo uno',
    de: 'Nur eins',
    nl: 'Slechts één',
  },
  [EStopsFilter.SAME_STATIONS]: {
    id: `${resultsScope}.${EStopsFilter.SAME_STATIONS}`,
    defaultMessage: 'Dans la même gare',
    en: 'In the same station',
    it: 'Nella stessa stazione',
    es: 'En la misma estación',
    de: 'Im gleichen Bahnhof',
    nl: 'Op dezelfde station',
  },
  selectTripFrom: {
    id: `${resultsScope}.selectTripFrom`,
    defaultMessage: 'Choisir ce trajet dès',
    en: 'Select this trip from',
    it: 'Seleziona questo viaggio da',
    es: 'Seleccionar este viaje desde',
    de: 'Wähle diese Fahrt ab',
    nl: 'Kies deze reis vanaf',
  },
  selectedTrip: {
    id: `${resultsScope}.selectedTrip`,
    defaultMessage:
      '{count, plural, one {Trajet sélectionné} other {Trajets sélectionnés}}',
    en: '{count, plural, one {Selected Trip} other {Selected Trips}}',
    it: '{count, plural, one {Viaggio selezionato} other {Viaggi selezionati}}',
    es: '{count, plural, one {Viaje seleccionado} other {Viajes seleccionados}}',
    de: '{count, plural, one {Ausgewählte Fahrt} other {Ausgewählte Fahrten}}',
    nl: '{count, plural, one {Geselecteerde reis} other {Geselecteerde reizen}}',
  },
  validate: {
    id: `${resultsScope}.validate`,
    defaultMessage: 'Valider',
    en: 'Validate',
    it: 'Convalidare',
    es: 'Validar',
    de: 'Bestätigen',
    nl: 'Valideren',
  },
  totalPrice: {
    id: `${resultsScope}.totalPrice`,
    defaultMessage: 'Total commande',
    en: 'Total order',
    it: 'Totale ordine',
    es: 'Total pedido',
    de: 'Gesamtbestellung',
    nl: 'Totaal bestelling',
  },
  total: {
    id: `${resultsScope}.total`,
    defaultMessage: 'Total :',
    en: 'Total:',
    it: 'Totale:',
    es: 'Total:',
    de: 'Gesamt:',
    nl: 'Totaal:',
  },
  numberOfPassengers: {
    id: `${resultsScope}.numberOfPassengers`,
    defaultMessage:
      '{count, plural, =0 {0 passager} one {1 passager} other {{count} passagers}}',
    en: '{count, plural, =0 {0 passengers} one {1 passenger} other {{count} passengers}}',
    it: '{count, plural, =0 {0 passeggero} one {1 passeggero} other {{count} passeggeri}}',
    es: '{count, plural, =0 {0 pasajero} one {1 pasajero} other {{count} pasajeros}}',
    de: '{count, plural, =0 {0 Passagier} one {1 Passagier} other {{count} Passagiere}}',
    nl: '{count, plural, =0 {0 passagier} one {1 passagier} other {{count} passagiers}}',
  },
  tripSummary: {
    id: `${resultsScope}.tripSummary`,
    defaultMessage: 'Récapitulatif de votre voyage',
    en: 'Trip summary',
    it: 'Riepilogo del viaggio',
    es: 'Resumen del viaje',
    de: 'Zusammenfassung der Reise',
    nl: 'Samenvatting van uw reis',
  },
  outboundTripSummary: {
    id: `${resultsScope}.outboundTripSummary`,
    defaultMessage: 'Récapitulatif de votre aller',
    en: 'Summary of your outbound',
    it: 'Riepilogo del tuo andata',
    es: 'Resumen de su ida',
    de: 'Zusammenfassung Ihrer Hinreise',
    nl: 'Samenvatting van uw heenreis',
  },
  editOutbound: {
    id: `${resultsScope}.editOutbound`,
    defaultMessage: 'Modifier mon aller',
    en: 'Modify outbound',
    it: 'Modifica andata',
    es: 'Modificar mi ida',
    de: 'Hinflug ändern',
    nl: 'Wijzig mijn heenreis',
  },
  editReturn: {
    id: `${resultsScope}.editReturn`,
    defaultMessage: 'Modifier mon retour',
    en: 'Modify return',
    it: 'Modifica ritorno',
    es: 'Modificar mi vuelta',
    de: 'Rückflug ändern',
    nl: 'Wijzig mijn terugreis',
  },
  selectTripForDetails: {
    id: `${resultsScope}.selectTripForDetails`,
    defaultMessage: 'Sélectionner un trajet pour afficher le détail du voyage',
    en: 'Select a trip to view trip details',
    it: 'Seleziona un viaggio per visualizzare i dettagli del viaggio',
    es: 'Seleccione un viaje para ver los detalles del viaje',
    de: 'Wählen Sie eine Fahrt aus, um Details der Fahrt anzuzeigen',
    nl: 'Selecteer een reis om reisdetails te bekijken',
  },
  multipleConnections: {
    id: `${resultsScope}.multipleConnections`,
    defaultMessage: 'corresp.',
    en: 'connections',
    it: 'corrispondenze',
    es: 'conexiones',
    de: 'Verbindungen',
    nl: 'verbindingen',
  },
  oneConnection: {
    id: `${resultsScope}.oneConnection`,
    defaultMessage: 'corresp.',
    en: 'connection',
    it: 'corrispondenza',
    es: 'conexión',
    de: 'Verbindung',
    nl: 'verbinding',
  },
  resetFilters: {
    id: `${resultsScope}.resetFilters`,
    defaultMessage: 'Réinitialiser',
    en: 'Reset',
    it: 'Reset',
    es: 'Restablecer',
    de: 'Zurücksetzen',
    nl: 'Resetten',
  },
  applyFilters: {
    id: `${resultsScope}.applyFilters`,
    defaultMessage: 'Afficher {numberOfResults} trajets',
    en: 'Show {numberOfResults} trips',
    it: 'Mostra {numberOfResults} viaggi',
    es: 'Mostrar {numberOfResults} viajes',
    de: 'Fahrten {numberOfResults} anzeigen',
    nl: 'Reizen {numberOfResults} tonen',
  },
  noTripAvailableWithFilters: {
    id: `${resultsScope}.noTripAvailableWithFilters`,
    defaultMessage: 'Aucun trajet',
    en: 'No routes',
    it: 'Nessun percorso',
    es: 'Sin rutas',
    de: 'Keine Routen',
    nl: 'Geen routes',
  },
  filters: {
    id: `${resultsScope}.filters`,
    defaultMessage: 'Filtres',
    en: 'Filters',
    it: 'Filtri',
    es: 'Filtros',
    de: 'Filter',
    nl: 'Filters',
  },
  smartestTag: {
    id: `${resultsScope}.smartestTag`,
    defaultMessage: 'Le + malin',
    en: 'Smartest',
    it: 'Il più furbo',
    es: 'El más astuto',
    de: 'Der Klügste',
    nl: 'De slimste',
  },
  cheapestTag: {
    id: `${resultsScope}.cheapestTag`,
    defaultMessage: 'Le moins cher',
    en: 'Cheapest',
    it: 'Il meno costoso',
    es: 'El más barato',
    de: 'Der Günstigste',
    nl: 'De goedkoopste',
  },
  fastestTag: {
    id: `${resultsScope}.fastestTag`,
    defaultMessage: 'Le + rapide',
    en: 'Fastest',
    it: 'Il più veloce',
    es: 'El más rápido',
    de: 'Der Schnellste',
    nl: 'De snelste',
  },
  mostEcologicalTag: {
    id: `${resultsScope}.mostEcologicalTag`,
    defaultMessage: 'Le plus écologique',
    en: 'Most ecological',
    it: 'Il più ecologico',
    es: 'El más ecológico',
    de: 'Am ökologischsten',
    nl: 'Meest ecologisch',
  },
  noResults: {
    id: `${resultsScope}.noResults`,
    defaultMessage:
      "Mince, nous n'avons pas trouvé de résultats pour ce trajet.",
    en: "Oops, we didn't find any results for this trip.",
    it: 'Ops, non abbiamo trovato risultati per questo viaggio.',
    es: 'Vaya, no hemos encontrado resultados para este viaje.',
    de: 'Hoppla, wir haben keine Ergebnisse für diese Fahrt gefunden.',
    nl: 'Oeps, we hebben geen resultaten gevonden voor deze reis.',
  },
  noResults2: {
    id: `${resultsScope}.noResults2`,
    defaultMessage:
      'Il est possible que les trajets proposés par nos partenaires ne soient pas encore disponibles. En attendant, vous pouvez ',
    en: 'The trips offered by our partners may not be available yet. In the meantime, you can ',
    it: 'I viaggi offerti dai nostri partner potrebbero non essere ancora disponibili. Nel frattempo, puoi ',
    es: 'Es posible que los viajes ofrecidos por nuestros socios aún no estén disponibles. Mientras tanto, puedes volver a ',
    de: 'Die Fahrten, die von unseren Partnern angeboten werden, sind möglicherweise noch nicht verfügbar. In der Zwischenzeit können Sie Ihre ',
    nl: 'De reizen die door onze partners worden aangeboden, zijn mogelijk nog niet beschikbaar. Ondertussen kunt u uw ',
  },
  noResults3: {
    id: `${resultsScope}.noResults3`,
    defaultMessage: 'relancer votre recherche.',
    en: 'relaunch your search.',
    it: 'ripetere la ricerca.',
    es: 'realizar tu búsqueda.',
    de: 'Suche erneut starten.',
    nl: 'zoekopdracht opnieuw starten.',
  },
  suggestionWait: {
    id: `${resultsScope}.suggestionWait`,
    defaultMessage: 'Attendez ! 👋',
    en: 'Wait! 👋',
    it: 'Aspetta! 👋',
    es: '¡Espera! 👋',
    de: 'Warten Sie! 👋',
    nl: 'Wacht even! 👋',
  },
  tripTotal: {
    id: `${resultsScope}.tripTotal`,
    defaultMessage: `{isReturnTrip, select,
      true {Retour pour {passengerCount, number} {passengerCount, plural,
        one {passager}
        other {passagers}
      } : }
      other {Aller pour {passengerCount, number} {passengerCount, plural,
        one {passager}
        other {passagers}
      } : }
    }`,
    en: `{isReturnTrip, select,
      true {Return for {passengerCount, number} {passengerCount, plural,
        one {passenger}
        other {passengers}
      }: }
      other {Outbound for {passengerCount, number} {passengerCount, plural,
        one {passenger}
        other {passengers}
      }: }
    }`,
    it: `{isReturnTrip, select,
      true {Ritorno per {passengerCount, number} {passengerCount, plural,
        one {passeggero}
        other {passeggeri}
      }: }
      other {Andata per {passengerCount, number} {passengerCount, plural,
        one {passeggero}
        other {passeggeri}
      }: }
    }`,
    es: `{isReturnTrip, select,
      true {Regreso para {passengerCount, number} {passengerCount, plural,
        one {pasajero}
        other {pasajeros}
      }: }
      other {Ida para {passengerCount, number} {passengerCount, plural,
        one {pasajero}
        other {pasajeros}
      }: }
    }`,
    de: `{isReturnTrip, select,
      true {Rückkehr für {passengerCount, number} {passengerCount, plural,
        one {Passagier}
        other {Passagiere}
      }: }
      other {Hinfahrt für {passengerCount, number} {passengerCount, plural,
        one {Passagier}
        other {Passagiere}
      }: }
    }`,
    nl: `{isReturnTrip, select,
      true {Terugreis voor {passengerCount, number} {passengerCount, plural,
        one {passagier}
        other {passagiers}
      }: }
      other {Heenreis voor {passengerCount, number} {passengerCount, plural,
        one {passagier}
        other {passagiers}
      }: }
    }`,
  },
  suggestion: {
    id: `${resultsScope}.suggestion`,
    defaultMessage:
      'On a trouvé ça, c’est pas exactement votre recherche mais ça peut vous plaire !',
    en: "We found this, it's not exactly what you were looking for but you might like it!",
    it: 'Abbiamo trovato questo, non è esattamente ciò che stavate cercando ma potrebbe piacervi!',
    es: 'Encontramos esto, no es exactamente lo que estabas buscando pero ¡te podría gustar!',
    de: 'Wir haben das gefunden, es entspricht nicht genau Ihrer Suche, aber es könnte Ihnen gefallen!',
    nl: 'We hebben dit gevonden, het is niet precies wat u zocht maar u zou het leuk kunnen vinden!',
  },
  bus: {
    id: `${resultsScope}.bus`,
    defaultMessage: 'Bus',
    en: 'Bus',
    it: 'Autobus',
    es: 'Autobús',
    de: 'Bus',
    nl: 'Bus',
  },
  train: {
    id: `${resultsScope}.train`,
    defaultMessage: 'Train',
    en: 'Train',
    it: 'Treno',
    es: 'Tren',
    de: 'Zug',
    nl: 'Trein',
  },
  carpooling: {
    id: `${resultsScope}.carpooling`,
    defaultMessage: "Covoit'",
    en: 'Carpooling',
    it: 'Carpooling',
    es: 'Compartir coche',
    de: 'Fahrgemeinschaften',
    nl: 'Carpool',
  },
  multimodal: {
    id: `${resultsScope}.multimodal`,
    defaultMessage: 'Combiné',
    en: 'Mixed',
    it: 'Combinato',
    es: 'Combinado',
    de: 'Kombiniert',
    nl: 'Gecombineerd',
  },
  step: {
    id: `${resultsScope}.step`,
    defaultMessage: ' escale, ',
    en: 'stop',
    it: 'scalo, ',
    es: ' escala ',
    de: ' zwischenstopp ',
    nl: ' tussenstop ',
  },
  passenger: {
    id: `${resultsScope}.passenger`,
    defaultMessage: 'passager',
    en: 'passenger',
    es: 'pasajero',
    it: 'passeggero',
    de: 'Passagier',
    nl: 'passagier',
  },
  passengers: {
    id: `${resultsScope}.passengers`,
    defaultMessage: 'passagers',
    en: 'passengers',
    it: 'passeggero',
    es: 'pasajeros',
    de: 'Passagiere',
    nl: 'passagiers',
  },
  [ENotAvailableReason.FULL]: {
    id: `${resultsScope}.FULL`,
    defaultMessage: 'Complet',
    en: 'Unavailable',
    it: 'Completo',
    es: 'Completo',
    de: 'Vollständig',
    nl: 'Volledig',
  },
  [ENotAvailableReason.CANCELLED]: {
    id: `${resultsScope}.CANCELLED`,
    defaultMessage: 'Annulé',
    en: 'Canceled',
    it: 'Annullato',
    es: 'Cancelado',
    de: 'Storniert',
    nl: 'Geannuleerd',
  },
  [ENotAvailableReason.NOT_SELLABLE]: {
    id: `${resultsScope}.NOT_SELLABLE`,
    defaultMessage: 'Non réservable',
    en: 'Not bookable',
    it: 'Non prenotabile',
    es: 'No reservable',
    de: 'Niet boekbaar',
    nl: 'niet te reserveren',
  },
  [ENotAvailableReason.NOT_OPEN_TO_SALE]: {
    id: `${resultsScope}.NOT_OPEN_TO_SALE`,
    defaultMessage: 'Bientôt disponible',
    en: 'Available soon',
    it: 'Disponibile presto',
    es: 'Próximamente disponible',
    de: 'Demnächst verfügbar',
    nl: 'Binnenkort beschikbaar',
  },
  searchExpiredHeader: {
    id: `${resultsScope}.searchExpiredHeader`,
    defaultMessage: 'Mince, votre recherche vient d’expirer',
    en: 'Oops, your search has expired',
    it: 'Ops, la tua ricerca è scaduta',
    es: '¡Vaya!, tu búsqueda ha expirado',
    de: 'Hoppla, Ihre Suche ist abgelaufen',
    nl: 'Oeps, uw zoekopdracht is verlopen',
  },
  searchExpiredText: {
    id: `${resultsScope}.searchExpiredText`,
    defaultMessage: 'Relancez la recherche pour afficher les résultats.',
    en: 'Reload the search to display the results.',
    it: 'Rilancia la ricerca per visualizzare i risultati.',
    es: 'Vuelve a cargar la búsqueda para mostrar los resultados.',
    de: 'Starten Sie die Suche erneut, um die Ergebnisse anzuzeigen.',
    nl: 'Vernieuw de zoekopdracht om de resultaten weer te geven.',
  },
  retrySearch: {
    id: `${resultsScope}.retrySearch`,
    defaultMessage: "C'est (re)parti !",
    en: 'Here we go again!',
    it: 'Ecco si ricomincia!',
    es: '¡Aquí vamos de nuevo!',
    de: "Los geht's (wieder)!",
    nl: 'Daar gaan we weer!',
  },
  date: {
    id: `${resultsScope}.date`,
    defaultMessage: 'Date :',
    en: 'Date :',
    it: 'Data :',
    es: 'Fecha :',
    de: 'Datum :',
    nl: 'Datum :',
  },

  tripDetails: {
    id: `${resultsScope}.tripDetails`,
    defaultMessage: 'Détail de votre trajet',
    en: 'Trip details',
    it: 'Dettagli del viaggio',
    es: 'Detalles del viaje',
    de: 'Reisedetails',
    nl: 'Reisdetails',
  },

  tripDetailDirection: {
    id: `${resultsScope}.tripDetailDirection`,
    defaultMessage: `{isReturnTrip, select,
      true {Détail de votre trajet retour}
      other {Détail de votre trajet aller}
    }`,
    en: `{isReturnTrip, select,
      true {Return trip details}
      other {Outbound trip details}
    }`,
    it: `{isReturnTrip, select,
      true {Dettagli del tuo viaggio di ritorno}
      other {Dettagli del tuo viaggio di andata}
    }`,
    es: `{isReturnTrip, select,
      true {Detalles de su viaje de regreso}
      other {Detalles de su viaje de ida}
    }`,
    de: `{isReturnTrip, select,
      true {Details der Rückreise}
      other {Details der Hinreise}
    }`,
    nl: `{isReturnTrip, select,
      true {Details van uw terugreis}
      other {Details van uw heenreis}
    }`,
  },

  departure: {
    id: `${resultsScope}.departure`,
    defaultMessage: 'Départ :',
    en: 'Departure :',
    it: 'Partenza :',
    es: 'Salida :',
    de: 'Abfahrt :',
    nl: 'Vertrek :',
  },
  arrival: {
    id: `${resultsScope}.arrival`,
    defaultMessage: 'Arrivée :',
    en: 'Arrival:',
    it: 'Arrivo:',
    es: 'Llegada:',
    de: 'Ankunft:',
    nl: 'Aankomst:',
  },
  incompleteOrder: {
    id: `${resultsScope}.incompleteOrder`,
    defaultMessage:
      'Votre commande n’est pas totalement terminée. Pour éviter de faire une partie à pied, pensez à réserver {count, plural, one {votre trajet} other {vos trajets}} en {transportType}.',
    en: 'Your order is not fully complete. To avoid walking a part of the way, consider booking {count, plural, one {your carpooling journey} other {your carpooling journeys}}.',
    it: 'Il tuo ordine non è completamente completo. Per evitare di percorrere una parte a piedi, considera di prenotare {count, plural, one {il tuo viaggio} other {i tuoi viaggi}} in carpooling.',
    es: 'Tu pedido no está completamente completo. Para evitar caminar una parte del camino, considera reservar {count, plural, one {tu viaje} other {tus viajes}} en coche compartido.',
    de: 'Ihre Bestellung ist nicht vollständig abgeschlossen. Um einen Teil des Weges zu vermeiden, empfehlen wir, {count, plural, one {Ihre Fahrt} other {Ihre Fahrten}} per Mitfahrgelegenheit zu buchen.',
    nl: 'Uw bestelling is niet volledig voltooid. Om een deel van de weg te vermijden, overweeg om {count, plural, one {uw carpoolreis} other {uw carpoolreizen}} te boeken en een stuk te voet te vermijden.',
  },
  redirectionModalTitle: {
    id: `${resultsScope}.redirectionModalTitle`,
    defaultMessage:
      '{count, plural, one {Votre trajet en {company} {transportType} est encore à réserver.} other {Vos trajets en {company} {transportType} sont encore à réserver.}}',
    en: '{count, plural, one {Your {company} carpool journey is still to be reserved.} other {Your {company} carpool journeys are still to be reserved.}}',
    it: '{count, plural, one {Il tuo viaggio in {transportType} {company} è ancora da prenotare.} other {I tuoi viaggi in {transportType} {company} sono ancora da prenotare.}}',
    es: '{count, plural, one {Tu viaje compartido en {transportType} {company} aún está pendiente de reserva.} other {Tus viajes compartidos en {transportType} {company} aún están pendientes de reserva.}}',
    de: '{count, plural, one {Ihre {transportType} Fahrt mit {company} muss noch reserviert werden.} other {Ihre {transportType} Fahrten mit {company} müssen noch reserviert werden.}}',
    nl: '{count, plural, one {Uw {transportType} reis met {company} moet nog gereserveerd worden.} other {Uw {transportType} reizen met {company} moeten nog gereserveerd worden.}}',
  },
  bookMyJourney: {
    id: `${resultsScope}.bookMyJourney`,
    defaultMessage: 'Réserver mon trajet',
    en: 'Book my journey',
    it: 'Prenota il mio viaggio',
    es: 'Reservar mi viaje',
    de: 'Meine Reise buchen',
    nl: 'Mijn reis boeken',
  },
  resultsInfoBannerTitle: {
    id: `${resultsScope}.resultsInfoBannerTitle`,
    defaultMessage: 'L’info pratique à propos des prix.',
    en: 'Practical information about prices.',
    it: 'Informazioni pratiche sui prezzi.',
    es: 'Información práctica sobre precios.',
    de: 'Praktische Informationen zu den Preisen.',
    nl: 'Praktische informatie over prijzen.',
  },
  resultsInfoBannerText: {
    id: `${resultsScope}.resultsInfoBannerText`,
    defaultMessage:
      'Nous cherchons toujours les meilleurs trajets possibles, <b>certains peuvent avoir des prix surévalués à cette étape</b>. C’est rare mais ça arrive, ils s’actualisent ensuite.',
    en: 'We are always looking for the best possible routes, <b>some may have overpriced prices at this stage</b>. It is rare but it happens, they are then updated.',
    it: 'Stiamo sempre cercando i percorsi migliori possibili, <b>alcuni possono avere prezzi troppo alti in questa fase</b>. È raro ma accade, poi vengono aggiornati.',
    es: 'Siempre estamos buscando las mejores rutas posibles, <b>algunas pueden tener precios sobrevalorados en esta etapa</b>. Es raro pero sucede, luego se actualizan.',
    de: 'Wir suchen immer nach den bestmöglichen Routen, <b>einige können in diesem Stadium überbewertete Preise haben</b>. Es ist selten, aber es passiert, dann werden sie aktualisiert.',
    nl: 'We zijn altijd op zoek naar de best mogelijke routes, <b>sommige kunnen op dit moment te duur zijn</b>. Het is zeldzaam maar het gebeurt, ze worden dan bijgewerkt.',
  },
  pricesIncrease: {
    id: `${resultsScope}.pricesIncrease`,
    defaultMessage: 'Les prix augmentent, ne tardez pas.',
    en: "Prices are increasing, don't delay.",
    it: 'I prezzi stanno aumentando, non indugiare.',
    es: 'Los precios están subiendo, no te demores.',
    de: 'Die Preise steigen, warten Sie nicht.',
    nl: 'De prijzen stijgen, wacht niet.',
  },
  pricesDecrease: {
    id: `${resultsScope}.pricesDecrease`,
    defaultMessage: "Prix en baisse, ça c'est chouette !",
    en: "Prices are decreasing, that's cool!",
    it: 'I prezzi stanno diminuendo, è fantastico!',
    es: 'Los precios están bajando, ¡eso está genial!',
    de: 'Preise sinken, das ist toll!',
    nl: 'Prijzen dalen, dat is gaaf!',
  },
  verification: {
    id: `${resultsScope}.verification`,
    defaultMessage: 'Vérification...',
    en: 'Verification...',
    it: 'Verifica...',
    es: 'Verificación...',
    de: 'Überprüfung...',
    nl: 'Verificatie...',
  },
  discountCardAdded: {
    id: `${resultsScope}.discountCardAdded`,
    defaultMessage: 'Votre carte de réduction a bien été ajoutée.',
    en: 'Your discount card has been successfully added.',
    it: 'La tua carta sconto è stata aggiunta con successo.',
    es: 'Se ha añadido correctamente tu tarjeta de descuento.',
    de: 'Ihre Rabattkarte wurde erfolgreich hinzugefügt.',
    nl: 'Uw kortingskaart is succesvol toegevoegd.',
  },
  noFilteredResults: {
    id: `${resultsScope}.noFilteredResults`,
    defaultMessage:
      "Aucun résultat n'est disponible à cause des filtres appliqués.",
    en: 'No results are available due to applied filters.',
    it: 'Nessun risultato disponibile a causa dei filtri applicati.',
    es: 'No hay resultados disponibles debido a los filtros aplicados.',
    de: 'Aufgrund der angewendeten Filter sind keine Ergebnisse verfügbar.',
    nl: 'Geen resultaten beschikbaar door toegepaste filters.',
  },
  resetAllFilters: {
    id: `${resultsScope}.resetAllFilters`,
    defaultMessage: 'Réinitialiser les filtres',
    en: 'Reset filters',
    it: 'Reimposta filtri',
    es: 'Restablecer filtros',
    de: 'Filter zurücksetzen',
    nl: 'Filters resetten',
  },
  extraPathMessage: {
    id: `${resultsScope}.extraPathMessages`,
    defaultMessage: 'Pas les trajets que vous vous attentiez à voir?',
    en: 'Not the trips you were expecting to see?',
    it: 'Non sono i viaggi che ti aspettavi di vedere?',
    es: '¿No son los viajes que esperabas ver?',
    de: 'Nicht die Fahrten, die Sie erwartet haben?',
    nl: 'Niet de reizen die u verwachtte te zien?',
  },
  extraPathButton: {
    id: `${resultsScope}.extraPathButton`,
    defaultMessage:
      'Cliquez ici pour calculer de nouvelles alternatives pour ce trajet',
    en: 'Click here to calculate new alternatives for this trip',
    it: 'Clicca qui per calcolare nuove alternative per questo viaggio',
    es: 'Haz clic aquí para calcular nuevas alternativas para este viaje',
    de: 'Klicken Sie hier, um neue Alternativen für diese Fahrt zu berechnen',
    nl: 'Klik hier om nieuwe alternatieven voor deze reis te berekenen',
  },
  pathEnriched: {
    id: `${resultsScope}.pathEnriched`,
    defaultMessage:
      'Message reçu, revenez dans une quinzaine de minutes découvrir vos nouveaux trajets',
    en: 'Message received, come back in fifteen minutes to discover your new trips',
    it: 'Messaggio ricevuto, torna tra quindici minuti per scoprire i tuoi nuovi viaggi',
    es: 'Mensaje recibido, vuelve en quince minutos para descubrir tus nuevos viajes',
    de: 'Nachricht erhalten, kommen Sie in fünfzehn Minuten zurück, um Ihre neuen Fahrten zu entdecken',
    nl: 'Bericht ontvangen, kom over vijftien minuten terug om uw nieuwe reizen te ontdekken',
  },
};

export default defineMessages(resultsMessages);
