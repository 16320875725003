/*
 * About Messages
 *
 * This contains all the text for the About container.
 */

import { defineMessages } from 'react-intl';
import {
  STATION_NAME,
  STATION_SITEMAP_BEST_STATIONS,
  STATION_SITEMAP_FIRST_TEXT_CONTENT,
  STATION_SITEMAP_HEADER_TITLE,
  STATION_SITEMAP_SECOND_TEXT_CONTENT,
  STATION_SITEMAP_THIRD_TEXT_CONTENT,
} from '../constants';

export const stationSitemapScope = 'app.containers.StationSitemap';

export const stationSitemapMessages = {
  [STATION_SITEMAP_HEADER_TITLE]: {
    id: `${stationSitemapScope}.${STATION_SITEMAP_HEADER_TITLE}`,
    defaultMessage:
      'Découvrez les gares ferroviaires et routières desservies par Tictactrip',
    en: 'Discover the train and bus stations served by Tictactrip',
    es: 'Descubre las estaciones de tren y autobús atendidas por Tictactrip',
    it: 'Scopri le stazioni ferroviarie e degli autobus servite da Tictactrip',
    de: 'Entdecken Sie die von Tictactrip bedienten Bahnhöfe und Busbahnhöfe',
    nl: 'Ontdek de trein- en busstations die worden bediend door Tictactrip',
  },
  [STATION_SITEMAP_FIRST_TEXT_CONTENT]: {
    id: `${stationSitemapScope}.${STATION_SITEMAP_FIRST_TEXT_CONTENT}`,
    defaultMessage:
      "Retrouvez ici la liste de l'ensemble des Gares Ferroviaires et gares Routières desservies par les services de Tictactrip.",
    en: 'Find here the list of all the railway stations and bus stations served by Tictactrip services.',
    es: 'Encuentra aquí la lista de todas las estaciones de tren y autobús atendidas por los servicios de Tictactrip.',
    it: "Trova qui l'elenco di tutte le stazioni ferroviarie e degli autobus servite dai servizi di Tictactrip.",
    de: 'Finden Sie hier die Liste aller Bahnhöfe und Busbahnhöfe, die von den Dienstleistungen von Tictactrip bedient werden.',
    nl: 'Vind hier de lijst van alle treinstations en busstations die worden bediend door Tictactrip-services.',
  },
  [STATION_SITEMAP_SECOND_TEXT_CONTENT]: {
    id: `${stationSitemapScope}.${STATION_SITEMAP_SECOND_TEXT_CONTENT}`,
    defaultMessage:
      "Une question concernant votre gare de départ ou gare d'arrivée ? Un doute sur votre voyage ?",
    en: 'A question about your departure or arrival station? A doubt about your trip?',
    es: '¿Una pregunta sobre tu estación de salida o llegada? ¿Dudas sobre tu viaje?',
    it: 'Una domanda sulla tua stazione di partenza o di arrivo? Un dubbio sul tuo viaggio?',
    de: 'Eine Frage zu Ihrem Abfahrts- oder Ankunftsbahnhof? Ein Zweifel an Ihrer Reise?',
    nl: 'Een vraag over je vertrek- of aankomststation? Twijfels over je reis?',
  },
  [STATION_SITEMAP_THIRD_TEXT_CONTENT]: {
    id: `${stationSitemapScope}.${STATION_SITEMAP_THIRD_TEXT_CONTENT}`,
    defaultMessage:
      'Toutes les informations sur nos gares se trouvent ici. Adresse de la gare, prochains départs en gare, compagnies de transport desservant la gare et bien plus encore !',
    en: 'All information about our stations can be found here. Station address, upcoming station departures, transportation companies serving the station and much more!',
    es: 'Toda la información sobre nuestras estaciones está aquí. Dirección de la estación, próximas salidas en la estación, compañías de transporte que sirven la estación ¡y mucho más!',
    it: 'Tutte le informazioni sulle nostre stazioni si trovano qui. Indirizzo della stazione, prossime partenze in stazione, compagnie di trasporto che servono la stazione e molto altro!',
    de: 'Alle Informationen zu unseren Bahnhöfen finden Sie hier. Bahnhofsadresse, bevorstehende Bahnhofsabfahrten, Verkehrsunternehmen, die den Bahnhof bedienen, und vieles mehr!',
    nl: 'Alle informatie over onze stations is hier te vinden. Stationadres, aankomende station vertrekken, transportbedrijven die het station bedienen en nog veel meer!',
  },
  [STATION_SITEMAP_BEST_STATIONS]: {
    id: `${stationSitemapScope}.${STATION_SITEMAP_BEST_STATIONS}`,
    defaultMessage: 'Meilleures gares',
    en: 'Top stations',
    es: 'Mejores estaciones',
    it: 'Migliori stazioni',
    de: 'Top-Bahnhöfe',
    nl: 'Topstations',
  },
  [STATION_NAME]: {
    id: `${stationSitemapScope}.${STATION_NAME}`,
    defaultMessage: 'Gare {stationName}',
    en: 'Station {stationName}',
    es: 'Estación {stationName}',
    it: 'Stazione {stationName}',
    de: 'Bahnhof {stationName}',
    nl: 'Station {stationName}',
  },
};

export default defineMessages(stationSitemapMessages);
