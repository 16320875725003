import { defineMessages } from 'react-intl';

export const nextDayScope = 'app.components.nextDay';

export const nextDayMessages = {
  day: {
    id: `${nextDayScope}.day`,
    defaultMessage: 'jour',
    en: 'day',
    it: 'giorno',
    es: 'día',
    de: 'tag',
    nl: 'dag',
  },
};

export default defineMessages(nextDayMessages);
