import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyHowItWorks = lazy(
  () => import(/* webpackChunkName: 'HowItWorks' */ './index'),
);

export default function HowItWorks() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyHowItWorks />
    </Suspense>
  );
}
