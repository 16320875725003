import { NAVIGATION } from '../../containers/App/constants';
import { useApp } from '../../contexts/App';
import { isMobileApp } from '../../helpers/app';

export type useFooterContext = {
  isReduced: boolean;
  isHidden: boolean;
  isHiddenOnMobile: boolean;
  areLinksObfuscated: boolean;
};

export type UseFooterProps = {
  userAgent: string;
  currentPage: string;
};

export const PAGES_WITH_HIDDEN_FOOTER_ON_MOBILE = [NAVIGATION.INFO];

export const useFooter = (props: UseFooterProps): useFooterContext => {
  const { currentPage, userAgent } = props;
  const { isInFunnel, partner } = useApp();
  const isPartnerLead = !!partner;
  const isMobileApplication = isMobileApp(userAgent);

  return {
    isReduced: isInFunnel || isPartnerLead,
    isHidden: isMobileApplication,
    isHiddenOnMobile: PAGES_WITH_HIDDEN_FOOTER_ON_MOBILE.includes(currentPage),
    areLinksObfuscated: currentPage !== NAVIGATION.HOME,
  };
};
