import { homepageScope } from '../constants';

export const homePageDestinationsMessages = {
  destinationsBannerSubTitle: {
    id: `${homepageScope}.destinationsBannerSubTitle`,
    defaultMessage:
      'Direction la Bretagne ou la Provence, le bout de l’Europe ou la ville d’à côté. Qu’importe votre destination et la complexité pour s’y rendre, on vous accompagne de l’idée à l’arrivée.',
    en: "Heading to Brittany or Provence, the edge of Europe or the nearby city. No matter your destination or the complexity of getting there, we'll accompany you from the idea to the arrival.",
    it: "Direzione Bretagna o Provenza, l'estremità dell'Europa o la città vicina. Non importa la tua destinazione o la complessità per arrivarci, ti accompagneremo dall'idea all'arrivo.",
    es: 'Rumbo a Bretaña o Provenza, el borde de Europa o la ciudad cercana. No importa su destino o la complejidad de llegar allí, lo acompañaremos desde la idea hasta la llegada.',
    de: 'Richtung Bretagne oder Provence, zum Rand Europas oder zur nahegelegenen Stadt. Egal, wohin Ihre Reise geht oder wie komplex sie ist - wir begleiten Sie von der Idee bis zur Ankunft.',
    nl: 'Op weg naar Bretagne of de Provence, de rand van Europa of de nabijgelegen stad. Ongeacht uw bestemming of de complexiteit van de reis, wij begeleiden u van het idee tot de aankomst.',
  },

  destinationsBannerTitle: {
    id: `${homepageScope}.destinationsBannerTitle`,
    defaultMessage: 'Réservez maintenant, partez demain.',
    en: 'Book now, leave tomorrow.',
    it: 'Prenota ora, parti domani.',
    es: 'Reserva ahora, sal mañana.',
    de: 'Jetzt buchen, morgen abreisen.',
    nl: 'Boek nu, vertrek morgen.',
  },
  destinationsCardFrom: {
    id: `${homepageScope}.destinationsCardFrom`,
    defaultMessage: 'À partir de',
    en: 'From',
    es: 'Desde',
    it: 'Da',
    de: 'Ab',
    nl: 'Vanaf',
  },

  destinationsHeaderTitle1: {
    id: `${homepageScope}.destinationsHeaderTitle1`,
    defaultMessage: 'Vous ',
    en: 'You ',
    es: 'Puedes ',
    de: 'Sie ',
    it: 'Puoi ',
    nl: 'U ',
  },
  destinationsHeaderTitle2: {
    id: `${homepageScope}.destinationsHeaderTitle2`,
    defaultMessage:
      'pouvez partir partout, <span class="highlight">(vraiment) partout.</span>',
    en: 'can depart from anywhere, <span class="highlight">(really) anywhere.</span>',
    es: 'partir desde cualquier lugar, <span class="highlight">(de verdad) cualquier lugar.</span>',
    de: 'können von überall aus abfahren, <span class="highlight">(wirklich) überall.</span>',
    it: 'partire da qualsiasi luogo, <span class="highlight">(davvero) da qualsiasi luogo.</span>',
    nl: 'kunt overal, <span class="highlight">(echt) overal vandaan vertrekken.</span>',
  },
};
