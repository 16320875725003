/*
 * Redirect Messages
 *
 * This contains all the text for the Redirect container.
 */

import { defineMessages } from 'react-intl';

export const redirectScope = 'app.containers.Redirect';

export const redirectMessages = {
  redirect: {
    id: `${redirectScope}.redirect`,
    defaultMessage:
      'Vous allez être redirigé sur le site de {company}, ne quittez pas cet écran. À très vite !',
    en: 'You will be redirected to the {company} website, do not leave this screen. See you soon!',
    it: 'Verrai reindirizzato al sito di {company}, non lasciare questa schermata. A presto!',
    es: 'Serás redirigido al sitio web de {company}, no abandones esta pantalla. ¡Hasta pronto!',
    de: 'Sie werden auf die {company}-Website weitergeleitet. Verlassen Sie diese Seite nicht. Bis bald!',
    nl: 'Je wordt doorgestuurd naar de {company}-website, verlaat dit scherm niet. Tot snel!',
  },
};

export default defineMessages(redirectMessages);
