import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../containers/App/constants';
import { ChevronIcon } from '../../images/icons/chevron';
import { EuroCoinIcon } from '../../images/icons/euroCoin';
import { TicketIcon } from '../../images/icons/ticket';
import { UsersIcon } from '../../images/icons/users';
import { ReactComponent as WowIcon } from '../../images/illustrated/wow-72.svg';
import ImageTexture from '../../images/textures/texture-green.png';
import { colors } from '../../styles/constants';
import history from '../../utils/history';
import Button from '../Forms/Button';
import { Icon24 } from '../Icon';
import { navbarMessages } from './messages';

const Container = styled.div`
  ${tw`p-medium2 pt-huge relative`}
  background: url(${ImageTexture});
`;

const Title = styled.span<{
  customStyle?: FlattenSimpleInterpolation;
}>`
  ${tw`text-title-xxlarge font-kyrial text-center block mb-big`}
  ${({ customStyle }) => customStyle}
`;

const Wow = styled(WowIcon)`
  ${tw`absolute`}
  width: 68px;
  right: var(--size-medium2);
  top: 0;
  transform: rotate(-11.28deg);
`;

const ChevronStyle = css`
  transform: rotate(180deg);
`;

type BuyAdvantageCardProps = {
  onClick?: () => void;
  titleStyle?: FlattenSimpleInterpolation;
};

export const BuyAdvantageCard = (props: BuyAdvantageCardProps) => {
  return (
    <Container>
      <Wow />
      <Title customStyle={props.titleStyle}>
        <FormattedMessage {...navbarMessages.travelMorePayLess} />
      </Title>

      <CardAdvantages />

      <Button
        css={css`
          ${tw`w-full`}
        `}
        data-testid="NavbarBuyDiscountCardButton"
        onClick={() => {
          history.push(NAVIGATION.BUY_A_DISCOUNT_CARD);
          props.onClick?.();
        }}
        rightIcon={
          <ChevronIcon color={colors.white} customStyle={ChevronStyle} />
        }
        size="MEDIUM"
        variant="PRIMARY"
      >
        <FormattedMessage {...navbarMessages.buyAdvantageCard} />
      </Button>
    </Container>
  );
};

const CardAdvantagesContainer = styled.div`
  ${tw`flex flex-col mb-medium2`}
  gap: var(--size-medium)
`;

const CardAdvantages = () => {
  return (
    <CardAdvantagesContainer>
      <CardAdvantage
        icon={
          <EuroCoinIcon
            color={colors['secondary-400']}
            customStyle={css`
              ${Icon24}
            `}
          />
        }
        text={<FormattedMessage {...navbarMessages.upTo30PercentOff} />}
      />
      <CardAdvantage
        icon={
          <TicketIcon
            color={colors['secondary-400']}
            customStyle={css`
              ${Icon24}
            `}
          />
        }
        text={<FormattedMessage {...navbarMessages.validOnMaxTickets} />}
      />
      <CardAdvantage
        icon={
          <UsersIcon
            color={colors['secondary-400']}
            customStyle={css`
              ${Icon24}
            `}
          />
        }
        text={<FormattedMessage {...navbarMessages.forAllAges} />}
      />
    </CardAdvantagesContainer>
  );
};

const CardAdvantageContainer = styled.div`
  ${tw`flex `}
  gap: var(--size-small);
`;

const CardAdvantageIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const CardAdvantageText = styled.span`
  ${tw`text-p-small leading-p-small`}
`;

type CardAdvantageProps = {
  text: ReactNode;
  icon: ReactNode;
};

const CardAdvantage = (props: CardAdvantageProps) => {
  return (
    <CardAdvantageContainer>
      <CardAdvantageIconWrapper>{props.icon}</CardAdvantageIconWrapper>

      <CardAdvantageText>{props.text}</CardAdvantageText>
    </CardAdvantageContainer>
  );
};
