import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

interface IconProps {
  color?: string;
  customStyle?: FlattenSimpleInterpolation;
}

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const CloseIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L1 13M1 1L13 13"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SVG>
);
