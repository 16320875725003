import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const LinkedinIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.995 20L20 19.9992V12.6642C20 9.07583 19.2275 6.31167 15.0325 6.31167C13.0158 6.31167 11.6625 7.41833 11.11 8.4675H11.0517V6.64667H7.07417V19.9992H11.2158V13.3875C11.2158 11.6467 11.5458 9.96333 13.7017 9.96333C15.8258 9.96333 15.8575 11.95 15.8575 13.4992V20H19.995Z"
      fill={color}
    />
    <path d="M0.33 6.6475H4.47667V20H0.33V6.6475Z" fill={color} />
    <path
      d="M2.40167 0C1.07583 0 0 1.07583 0 2.40167C0 3.7275 1.07583 4.82583 2.40167 4.82583C3.7275 4.82583 4.80333 3.7275 4.80333 2.40167C4.8025 1.07583 3.72667 0 2.40167 0Z"
      fill={color}
    />
  </SVG>
);
