import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

interface IconProps {
  color?: string;
  customStyle?: FlattenSimpleInterpolation;
}

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const ChevronIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="14"
    viewBox="0 0 8 14"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13L1 7L7 1"
      stroke={color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SVG>
);
