import { defineMessages } from 'react-intl';
import { ETripType } from '../../common/types';

export const tripRecapScope = 'app.containers.tripRecapScope';

export const tripRecapMessages = {
  [ETripType.OUTBOUND]: {
    id: `${tripRecapScope}.${ETripType.OUTBOUND}`,
    defaultMessage: 'Aller : ',
    en: 'Outbound : ',
    it: 'Andata : ',
    es: 'Salida : ',
    de: 'Gehen : ',
    nl: 'Uitgaand : ',
  },
  [ETripType.RETURN]: {
    id: `${tripRecapScope}.${[ETripType.RETURN]}`,
    defaultMessage: 'Retour : ',
    en: 'Return : ',
    it: 'Ritorno : ',
    es: 'Vuelta : ',
    de: 'Zurück : ',
    nl: 'Terug : ',
  },
};

export default defineMessages(tripRecapMessages);
