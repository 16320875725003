import PropTypes from 'prop-types';
import { API_URL } from '../../helpers/constants';

export const STATION = 'station';
export const URL_STOP_GROUPS = `${API_URL}/stopGroups`;
export const URL_SNCF_DEPARTURES = 'openDataSncfDepartures';
export const URL_SNCF_ARRIVALS = 'openDataSncfArrivals';
export const URL_PROVIDER_ID = 'providerId';

export const STATION_INFOS = 'stationInfos';
export const STATION_DEPARTURES = 'stationDepartures';
export const STATION_ARRIVALS = 'stationArrivals';
export const LOADING = 'isLoading';

export const FETCH_STATION_INFOS = 'app/Station/FETCH_STATION_INFOS';
export const UPDATE_STATION_INFOS = 'app/Station/UPDATE_STATION_INFOS';
export const UPDATE_STATION_DEPARTURES =
  'app/Station/UPDATE_STATION_DEPARTURES';
export const UPDATE_STATION_ARRIVALS = 'app/Station/UPDATE_STATION_ARRIVALS';
export const SET_LOADING_STATUS = 'app/Station/SET_LOADING_STATUS';

export const STATION_HEADER_TITLE = 'StationHeaderTitle';
export const USEFUL_INFOS = 'UsefulInfos';
export const USEFUL_INFOS_CAPTION = 'UsefulInfosCaption';
export const RAILWAY = 'Train';
export const HIGHWAY = 'Bus';
export const RAILWAY_HIGHWAY = 'Train/Bus';
export const ADDRESS = 'Address';
export const DESTINATIONS_EXAMPLE = 'Destinations';
export const STATION_PROVIDERS = 'StationProviders';
export const JOURNEYS_PER_DAY = 'JourneysPerDay';
export const FIRST_LAST_DEPARTURE = 'FirstLastDeparture';
export const DATA_UNAVAILABLE = 'DataUnavailable';

export const USEFUL_INFOS_SECTION_TITLE = 'UsefulInfosSectionTitle';
export const NEED_INFOS_ABOUT = 'NeedInfosAbout';
export const RIGHT_PLACE = 'RightPlace';
export const STATION_ADDRESS = 'StationAddress';
export const SERVICED_STATIONS = 'ServicedStations';
export const PROVIDERS = 'Providers';
export const TRIPS_PER_DAY = 'TripPerDay';
export const FIRST_DEPARTURE = 'FirstDeparture';
export const LAST_DEPARTURE = 'LastDeparture';
export const NEXT_DEPARTURES_AND_ARRIVALS = 'NextDeparturesAndArrivals';

export const DEPARTURES_ARRIVALS_SECTION_TITLE =
  'DeparturesArrivalsSectionTitle';
export const DEPARTURES_TAB_TITLE = 'DeparturesTitle';
export const ARRIVALS_TAB_TITLE = 'ArrivalsTitle';
export const SHOW_MORE_RESULTS = 'ShowMoreResults';
export const SHOW_LESS_RESULTS = 'ShowLessResults';
export const DEPARTURES_TAB = 'DeparturesTab';
export const ARRIVALS_TAB = 'ArrivalsTab';
export const TRIP_IDENTIFIER = 'TripIdentifier';

export const MAPBOX_SECTION_TITLE = 'MapboxSectionTitle';
export const WHERE_IS_STATION = 'WhereIsStation';

export const PROVIDERS_SECTION_TITLE = 'ProvidersSectionTitle';
export const PROVIDERS_SECTION_INTRO = 'ProvidersSectionIntro';
export const PROVIDERS_CONTENT = 'ProvidersContent';
export const PROVIDERS_CONTENT_FIRST_DEPARTURE =
  'ProvidersContentFirstDeparture';
export const PROVIDERS_CONTENT_FIRST_ARRIVAL = 'ProvidersContentFirstArrival';
export const PROVIDERS_CONTENT_LAST_DEPARTURE = 'ProvidersContentLastDeparture';
export const PROVIDERS_CONTENT_LAST_ARRIVAL = 'ProvidersContentLastArrival';
export const PROVIDERS_CONTENT_TRAINS_PER_DAY = 'ProvidersContentTrainsPerDay';

export const USEFUL_INFOS_NAV_TITLE = 'UsefulInfosNavTitle';
export const KEY_INFORMATIONS_NAV_SUBTITLE = 'KeyInformationsNavSubtitle';
export const DEPARTURES_ARRIVALS_NAV_SUBTITLE = 'DeparturesArrivalsNavSubtitle';
export const MAPBOX_NAV_SUBTITLE = 'MapboxNavSubtitle';
export const PROVIDERS_NAV_TITLE = 'ProvidersNavTitle';
export const STATION_CHANGE_NAV_TITLE = 'StationChangeNavTitle';

export const STATION_CHANGE_SECTION_TITLE = 'StationChangeSectionTitle';
export const SWITCH_TO_ANOTHER_STATION_TITLE = 'SwitchToAnotherStationTitle';
export const SWITCH_TO_ANOTHER_STATION_TEXT = 'SwitchToAnotherStationText';
export const FROM_CURRENT_STATION_TO_ANOTHER_HEADER =
  'FromCurrentStationToAnotherHeader';
export const FROM_CURRENT_STATION_TO_ANOTHER_CONTENT =
  'FromCurrentStationToAnotherContent';
export const ITINERARY_SOLUTIONS = 'ItinerarySolutions';

export const CLOSE_STATIONS_TITLE = 'CloseStationsTitle';

export const providersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    firstArrival: PropTypes.string,
    firstDeparture: PropTypes.any,
    lastArrival: PropTypes.string,
    lastDeparture: PropTypes.any,
    provider: PropTypes.string,
    trainsPerDay: PropTypes.number,
  }),
);

export const closeStopGroupsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    newId: PropTypes.string,
    pageId: PropTypes.string,
    uniqueName: PropTypes.string,
  }),
);

export const stationInfosPropTypes = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  closeStopGroups: closeStopGroupsPropTypes,
  firstArrival: PropTypes.string,
  firstDeparture: PropTypes.string,
  gpuid: PropTypes.string,
  journeysPerDay: PropTypes.number,
  lastArrival: PropTypes.string,
  lastDeparture: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  providers: providersPropTypes,
  providersName: PropTypes.arrayOf(PropTypes.string),
  servicedDestinations: PropTypes.arrayOf(PropTypes.string),
  stopGroupType: PropTypes.arrayOf(PropTypes.string),
});

export const stationDeparturesPropTypes = PropTypes.shape({
  departures: PropTypes.arrayOf(
    PropTypes.shape({
      arrivalDateTime: PropTypes.string,
      baseArrivalDateTime: PropTypes.string,
      baseDepartureDateTime: PropTypes.string,
      commercialMode: PropTypes.string,
      dataFreshness: PropTypes.string,
      departureDateTime: PropTypes.string,
      description: PropTypes.string,
      direction: PropTypes.string,
      headsign: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      network: PropTypes.string,
      physicalMode: PropTypes.string,
      tripShortName: PropTypes.string,
    }),
  ),
  disruptions: PropTypes.arrayOf(PropTypes.any),
});

export const stationArrivalsPropTypes = PropTypes.shape({
  arrivals: PropTypes.arrayOf(
    PropTypes.shape({
      arrivalDateTime: PropTypes.string,
      baseArrivalDateTime: PropTypes.string,
      baseDepartureDateTime: PropTypes.string,
      commercialMode: PropTypes.string,
      dataFreshness: PropTypes.string,
      departureDateTime: PropTypes.string,
      description: PropTypes.string,
      direction: PropTypes.string,
      headsign: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      network: PropTypes.string,
      physicalMode: PropTypes.string,
      tripShortName: PropTypes.string,
    }),
  ),
  disruptions: PropTypes.arrayOf(
    PropTypes.shape({
      applicationPeriods: PropTypes.arrayOf(
        PropTypes.shape({
          begin: PropTypes.string,
          end: PropTypes.string,
        }),
      ),
      disruptionId: PropTypes.string,
      effect: PropTypes.string,
      impactId: PropTypes.string,
      impactedObjects: PropTypes.arrayOf(
        PropTypes.shape({
          impactedStops: PropTypes.arrayOf(
            PropTypes.shape({
              amendedArrivalTime: PropTypes.string,
              amendedDepartureTime: PropTypes.string,
              arrivalStatus: PropTypes.string,
              baseArrivalTime: PropTypes.string,
              baseDepartureTime: PropTypes.string,
              cause: PropTypes.string,
              departureStatus: PropTypes.string,
              isDetour: PropTypes.bool,
              stationName: PropTypes.string,
              stopTimeEffect: PropTypes.string,
            }),
          ),
          ptObject: PropTypes.shape({
            embeddedType: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
            quality: PropTypes.number,
            tripId: PropTypes.string,
            tripName: PropTypes.string,
          }),
        }),
      ),
      name: PropTypes.string,
      status: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
});
