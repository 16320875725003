import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const TwitterIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    fill="none"
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      transform="translate(-484.000000, -6412.000000)"
    >
      <g
        fill={color}
        fillRule="nonzero"
        transform="translate(0.000000, 5632.000000)"
      >
        <g transform="translate(470.000000, 764.000000)">
          <g>
            <g transform="translate(14.000000, 16.000000)">
              <path d="M23,2.24799897 C22.1534708,2.62947504 21.2451573,2.88818774 20.290806,3.00368926 C21.2652635,2.41024382 22.0111863,1.46895613 22.3647179,0.350785224 C21.4506543,0.900353839 20.4416968,1.29941121 19.366633,1.51573768 C18.5057476,0.581750044 17.281221,0 15.9230162,0 C13.3173224,0 11.2045886,2.14862639 11.2045886,4.79713509 C11.2045886,5.17276346 11.2462667,5.5396394 11.3267669,5.89046285 C7.4060121,5.69018887 3.929361,3.77982737 1.60248628,0.876963061 C1.19573888,1.58441942 0.964347981,2.40875324 0.964347981,3.28869747 C0.964347981,4.95353225 1.79794902,6.42248838 3.06272559,7.28190836 C2.28948087,7.25561284 1.56223621,7.03928637 0.925563604,6.67971049 L0.925563604,6.7396398 C0.925563604,9.0636588 2.55251561,11.0032588 4.70978384,11.4447024 C4.31453646,11.5528656 3.89775473,11.6127949 3.46657919,11.6127949 C3.16190386,11.6127949 2.86726286,11.5821041 2.57837186,11.5236271 C3.17911631,13.4310839 4.9210685,14.8181723 6.98494605,14.8562014 C5.37092217,16.1424649 3.33579467,16.9069076 1.12534824,16.9069076 C0.744494681,16.9069076 0.369353545,16.8835168 0,16.8411306 C2.0883057,18.2048664 4.56753688,19 7.23215888,19 C15.9115914,19 20.6559128,11.688815 20.6559128,5.3481943 L20.6400909,4.72700098 C21.5670826,4.05459256 22.3690398,3.20977271 23,2.24799897 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);
