import { EIsoCountryCodes } from './iso-country-codes';

export enum ETransportType {
  TRAIN = 'train',
  BUS = 'bus',
  MULTIMODAL = 'multimodal',
  CARPOOLING = 'carpooling',
}

export enum ENotAvailableReason {
  CANCELLED = 'CANCELLED',
  FULL = 'FULL',
  NOT_SELLABLE = 'NOT_SELLABLE',
  NOT_OPEN_TO_SALE = 'NOT_OPEN_TO_SALE',
}

export enum ETripType {
  OUTBOUND = 'outboundTrip',
  RETURN = 'returnTrip',
}

export enum EPassengerTitle {
  MR = 'MR',
  MS = 'MS',
  MX = 'MX',
}

export enum EPassengerCategory {
  ADULT = 'adults',
  SENIOR = 'seniors',
  YOUNG = 'youths',
}

export enum ETicketStatusStatus {
  LOCKED = 'LOCKED',
  CANCELED = 'CANCELED',
  BOOKED = 'BOOKED',
  ERRORED = 'ERRORED',
  ERRORED_AFTER_BOOKED = 'ERRORED_AFTER_BOOKED',
  REFUNDED = 'REFUNDED',
}

export enum EOrderTicketCancellationStatus {
  ACCEPTED = 'ACCEPTED',
  CREATED = 'CREATED',
  ERRORED = 'ERRORED',
  INPROGRESS = 'INPROGRESS',
  REFUNDED = 'REFUNDED',
}

export enum EBookingClass {
  'FIRST_CLASS' = '1_ST_CLASS',
  'SECOND_CLASS' = '2_ND_CLASS',
  'BERTHS_IN_1ST_CLASS' = 'BERTHS_IN_1ST_CLASS',
  'BERTHS_IN_2ND_CLASS' = 'BERTHS_IN_2ND_CLASS',
  'RECLINING_SEATS' = 'RECLINING_SEATS',
}

interface IVoucherDetails {
  voucher: string;
  expireDateUTCUnix: number;
  amountCents: number;
  usableOn: string;
}

export interface ICustomer {
  id?: string;
  mail: string;
  title: EPassengerTitle;
  firstName: string;
  lastName: string;
  birthdate: string;
  phoneNumber?: string;
  //orders?: Order[];
  language?: string;
}

interface TClientPlace {
  id?: number;
  city: string;
  country: string;
  station?: string;
  lat?: number;
  long?: number;
}

export interface IClientSegment {
  id: string;
  company: string;
  mean: ETransportType;
  origin: TClientPlace;
  destination: TClientPlace;
  priceCents: number;
  feeCents: number;
  includedProviderFeeCents: number;
  departureUTC: number;
  arrivalUTC: number;
  originOffset: string;
  destinationOffset: string;
  durationMinutes: number;
  isBookable: boolean;
  bookingStatus?: ETicketStatusStatus;
  redirectionLink: string;
  conditions?: string[];
  segmentProviderId?: number;
  conditionsName?: string;
  fareName?: string;
  bookingClass?: string;
  vehicleIdentifier?: string;
  cancellationStatus?: EOrderTicketCancellationStatus;
  voucherDetails?: IVoucherDetails;
  companyProviderName?: string;
  companyId?: number;
  isCustomerCancellationEnabled?: boolean;
  refundedAmountCents?: number;
  eTicketUrl?: string | null;
  cancellationConditions?: string[];
  availableSeats?: number | null;
}

export interface IClientTrip {
  id: string;
  uuid?: string;
  origin: TClientPlace;
  destination: TClientPlace;
  priceCents: number;
  feeCents: number;
  paidPrice: number;
  departureUTC: number;
  arrivalUTC: number;
  originOffset: string;
  destinationOffset: string;
  durationMinutes: number;
  companies: string[];
  mean: ETransportType;
  segments: IClientSegment[];
  co2g: number;
  vehicleIdentifier?: string;
}

export type NeverProps<T> = {
  [K in keyof T]?: never;
};

export interface TClientCustomer {
  mail: string;
  lastName: string;
  firstName: string;
  title: EPassengerTitle;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
}

export interface IDiscountCard {
  cardCode: string;
  id: string;
  isIdRequired: boolean;
  label: string;
  visibility: number;
}
export interface TClientPassenger {
  id: number;
  lastName: string;
  firstName: string;
  category: EPassengerCategory;
  discountCards: IDiscountCard[];
  title: EPassengerTitle;
  isCustomer: boolean;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  age: number;
  discountCardId?: string;
}

export enum EPaymentStatus {
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  INPROGRESS = 'INPROGRESS',
  ONHOLD_PARTNER = 'ONHOLD_PARTNER',
  PENDING_RISK = 'PENDING_RISK',
}

export interface IIdentityDocument {
  type: EIdentityDocumentType;
  documentIdentifier: string;
  /** YYYY-MM-DD */
  expirationDate: string;
  countryCode: EIsoCountryCodes;
}

export enum EIdentityDocumentType {
  IDENTITY_CARD = 'IDENTITY_CARD',
  PASSPORT = 'PASSPORT',
}
