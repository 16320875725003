import '../../animations/CSSTransition/slideRight.css';
import { Fragment, ReactNode, useState } from 'react';
import { FaSkiing } from 'react-icons/fa';
import {
  PiBagBold,
  PiCarSimpleBold,
  PiHouseBold,
  PiPersonSimpleHikeBold,
} from 'react-icons/pi';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../containers/App/constants';
import { useAuth } from '../../contexts/Auth';
import { ChatIcon } from '../../images/icons/chat';
import { ChevronIcon } from '../../images/icons/chevron';
import { ChevronDownIcon } from '../../images/icons/chevronDown';
import { CloseIcon } from '../../images/icons/close';
import { FaceWinkIcon } from '../../images/icons/faceWink';
import { LeafIcon } from '../../images/icons/leaf';
import { NotificationIcon } from '../../images/icons/notification';
import { SearchIcon } from '../../images/icons/search';
import { TicketIcon } from '../../images/icons/ticket';
import { UserIcon } from '../../images/icons/user';
import { UsersIcon } from '../../images/icons/users';
import { WalletIcon } from '../../images/icons/wallet';
import ImageTexture from '../../images/textures/texture-green.png';
import { colors, screens } from '../../styles/constants';
import history from '../../utils/history';
import Button from '../Forms/Button';
import { Icon18, Icon24 } from '../Icon';
import { BuyAdvantageCard } from './BuyAdvantageCard';
import { SecondaryDrawerSections } from './config';
import { DrawerOverlay } from './DrawerOverlay';
import { navbarMessages } from './messages';

const Container = styled.div`
  ${tw`fixed bg-white w-full`}
  z-index: 999;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;

  @media (min-width: ${screens.md}) {
    max-width: 325px;
  }
`;

const Wrapper = styled.div`
  ${tw`w-full h-full relative flex flex-col overflow-y-auto`}
`;

const ChevronStyle = css`
  transform: rotate(180deg);
`;

const CloseButton = styled.button`
  ${tw`p-0`}
`;

const HeaderTitleWrapper = styled.div`
  ${tw`flex justify-between items-start`}
  gap: var(--size-small)
`;

const NavigationLinksWrapper = styled.div`
  ${tw`flex flex-col mt-big px-medium mb-huge `}
  gap: var(--size-medium);
`;

const ScrollableWrapper = styled.div`
  ${tw``}
  flex: 1;
`;

const FooterContainer = styled.footer`
  ${tw`bg-white `}
`;

type PrimaryDrawerProps = {
  onClose: () => void;
  onOpenSecondaryDrawer: (section: SecondaryDrawerSections) => void;
  isOpen: boolean;
  isMobileApp: boolean;
};

export const PrimaryDrawer = (props: PrimaryDrawerProps) => {
  const [isGoodDealSubMenuOpen, setIsGoodDealSubMenuOpen] = useState(false);

  return (
    <Fragment>
      <DrawerOverlay
        customStyle={css`
          z-index: 998;
        `}
        isOpen={props.isOpen}
        onClose={props.onClose}
      />

      <CSSTransition
        appear
        classNames="slideRight"
        in={props.isOpen}
        timeout={250}
        unmountOnExit
      >
        <Container data-testid="NavbarPrimaryDrawerView">
          <Wrapper>
            <Header onClose={props.onClose} />
            <ScrollableWrapper>
              <NavigationLinksWrapper>
                <NavigationLink
                  href={NAVIGATION.ORDERS}
                  icon={
                    <TicketIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={props.onClose}
                  text={
                    <FormattedMessage {...navbarMessages.yourReservations} />
                  }
                  type="link"
                />
                <NavigationLink
                  $hiddenOnDesktop
                  $hiddenOnMobile
                  href="/"
                  icon={
                    <UsersIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  text={'Profils voyageurs'}
                  type="link"
                />
                <NavigationLink
                  href={NAVIGATION.BUY_A_DISCOUNT_CARD}
                  icon={
                    <WalletIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={props.onClose}
                  text={<FormattedMessage {...navbarMessages.discountCards} />}
                  type="link"
                />
                <NavigationLink
                  href={NAVIGATION.GIVE_YOUR_FEEDBACK}
                  icon={
                    <FaceWinkIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={props.onClose}
                  text={<FormattedMessage {...navbarMessages.giveFeedback} />}
                  type="link"
                />
                <NavigationLink
                  href={NAVIGATION.ABOUT}
                  icon={
                    <UserIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={props.onClose}
                  openInNewPage={props.isMobileApp}
                  text={<FormattedMessage {...navbarMessages.about} />}
                  type="link"
                />
                <NavigationLink
                  href={NAVIGATION.HOW_IT_WORKS}
                  icon={
                    <LeafIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={props.onClose}
                  openInNewPage={props.isMobileApp}
                  text={<FormattedMessage {...navbarMessages.howItWorks} />}
                  type="link"
                />
                <NavigationLink
                  href={NAVIGATION.SUPPORT}
                  icon={
                    <UsersIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  openInNewPage
                  text={
                    <FormattedMessage {...navbarMessages.customerService} />
                  }
                  type="link"
                />
                <NavigationLink
                  $isParentSubMenuOpen={isGoodDealSubMenuOpen}
                  icon={
                    <NotificationIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={() =>
                    setIsGoodDealSubMenuOpen(!isGoodDealSubMenuOpen)
                  }
                  text={
                    <div>
                      <FormattedMessage {...navbarMessages.goodDeal} />
                      <span
                        style={{
                          marginLeft: '15px',
                          padding: '2px 10px',
                          background: colors['secondary400'],
                          color: colors.white,
                          borderRadius: '20px',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                        }}
                      >
                        <FormattedMessage {...navbarMessages.itsNew} />
                      </span>
                    </div>
                  }
                  type="button"
                />
                {isGoodDealSubMenuOpen && (
                  <Fragment>
                    <NavigationLink
                      $isSubMenuItem
                      href={NAVIGATION.AFFILIATION_BOOKING_COM}
                      icon={
                        <PiHouseBold
                          color={colors['secondary-400']}
                          style={{ fontSize: '24px' }}
                        />
                      }
                      isExternalLink
                      openInNewPage
                      text={
                        <FormattedMessage
                          {...navbarMessages.affiliationAccommodations}
                        />
                      }
                      type="link"
                    />
                    <NavigationLink
                      $isSubMenuItem
                      href={NAVIGATION.AFFILIATION_RENTAL_CARS}
                      icon={
                        <PiCarSimpleBold
                          color={colors['secondary-400']}
                          style={{ fontSize: '24px' }}
                        />
                      }
                      isExternalLink
                      openInNewPage
                      text={
                        <FormattedMessage
                          {...navbarMessages.affiliationCarRental}
                        />
                      }
                      type="link"
                    />
                    <NavigationLink
                      $isSubMenuItem
                      href={NAVIGATION.AFFILIATION_FUNBOOKER}
                      icon={
                        <PiPersonSimpleHikeBold
                          color={colors['secondary-400']}
                          style={{ fontSize: '24px' }}
                        />
                      }
                      isExternalLink
                      openInNewPage
                      text={
                        <FormattedMessage
                          {...navbarMessages.affiliationActivities}
                        />
                      }
                      type="link"
                    />
                    <NavigationLink
                      $isSubMenuItem
                      href={NAVIGATION.AFFILIATION_SKISET}
                      icon={
                        <FaSkiing
                          color={colors['secondary-400']}
                          style={{ fontSize: '24px' }}
                        />
                      }
                      isExternalLink
                      openInNewPage
                      text={
                        <FormattedMessage {...navbarMessages.affiliationSki} />
                      }
                      type="link"
                    />
                    <NavigationLink
                      $isSubMenuItem
                      href={NAVIGATION.AFFILIATION_NANNYBAG}
                      icon={
                        <PiBagBold
                          color={colors['secondary-400']}
                          style={{ fontSize: '24px' }}
                        />
                      }
                      isExternalLink
                      openInNewPage
                      text={
                        <FormattedMessage
                          {...navbarMessages.affiliationBagages}
                        />
                      }
                      type="link"
                    />
                  </Fragment>
                )}
                <NavigationLink
                  href={NAVIGATION.HELP_CENTER}
                  icon={
                    <SearchIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  isExternalLink
                  openInNewPage
                  text={<FormattedMessage {...navbarMessages.helpCenter} />}
                  type="link"
                />
                <NavigationLink
                  $hiddenOnDesktop
                  icon={
                    <ChatIcon
                      color={colors['secondary-400']}
                      customStyle={css`
                        ${Icon24}
                      `}
                    />
                  }
                  onClick={() => props.onOpenSecondaryDrawer('languages')}
                  text={
                    <FormattedMessage {...navbarMessages.languagesSection} />
                  }
                  type="button"
                />
              </NavigationLinksWrapper>

              <FooterContainer>
                <BuyAdvantageCard
                  onClick={() => {
                    props.onClose();
                  }}
                />
              </FooterContainer>
            </ScrollableWrapper>
          </Wrapper>
        </Container>
      </CSSTransition>
    </Fragment>
  );
};
const HeaderContainer = styled.header`
  ${tw`bg-white sticky`}
  top:0;
`;
const HeaderWrapper = styled.div`
  ${tw`p-medium2 flex flex-col`}

  gap: var(--size-medium);
  background: url(${ImageTexture});

  padding-top: calc(env(safe-area-inset-top) + var(--size-medium2));
`;

const LoginButtonStyle = css`
  &:hover {
    path {
      stroke: ${colors.white};
    }
  }
`;

const HeaderTitle = styled.span`
  ${tw`text-title-xxlarge-m font-kyrial`}
`;

const HeaderTitleName = styled.span`
  ${tw`text-primary-400`}
`;

type HeaderProps = {
  onClose: () => void;
};

const Header = (props: HeaderProps) => {
  const { user } = useAuth();

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderTitleWrapper>
          <HeaderTitle>
            <FormattedMessage {...navbarMessages.welcome} />
            {user ? (
              <HeaderTitleName> {user.firstName}.</HeaderTitleName>
            ) : (
              <Fragment>.</Fragment>
            )}
          </HeaderTitle>

          <CloseButton
            data-testid="NavbarPrimaryDrawerCloseButton"
            onClick={props.onClose}
          >
            <CloseIcon color={colors['secondary-400']} customStyle={Icon18} />
          </CloseButton>
        </HeaderTitleWrapper>

        {!user && (
          <Button
            css={LoginButtonStyle}
            data-testid="NavbarPrimaryDrawerLoginButton"
            onClick={() => {
              history.push(NAVIGATION.LOGIN);
              props.onClose();
            }}
            rightIcon={
              <ChevronIcon
                color={colors['secondary-400']}
                customStyle={ChevronStyle}
              />
            }
            size="MEDIUM"
            variant="SECONDARY"
          >
            <FormattedMessage {...navbarMessages.login} />
          </Button>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

const NavigationLinkContainer = styled(Link)<{
  $hiddenOnMobile?: boolean;
  $hiddenOnDesktop?: boolean;
  $isSubMenuItem?: boolean;
}>`
  border-bottom: 1px solid;
  gap: var(--size-medium);
  text-decoration: none;
  ${tw`p-0 flex items-center border-grey-300 pb-small`}

  ${({ $isSubMenuItem }) => $isSubMenuItem && tw`ml-2 border-b-1`}

  ${({ $hiddenOnMobile }) =>
    $hiddenOnMobile &&
    css`
      @media (max-width: ${screens.lg}) {
        display: none;
      }
    `}

  ${({ $hiddenOnDesktop }) =>
    $hiddenOnDesktop &&
    css`
      @media (min-width: ${screens.lg}) {
        display: none;
      }
    `}
`;

const NavigationLinkText = styled.span`
  ${tw`text-textLight w-full text-p-medium-m leading-p-medium-m`}
  text-align: start;
`;

const NavigationLinkChevronStyle = css`
  ${Icon18}
  transition: transform 0.3s ease-out;

  ${NavigationLinkContainer}:hover & {
    transform: translateX(-4px);
  }
`;

const NavigationLinkChevronDownStyle = css`
  ${Icon18}
`;

const NavigationLinkChevronWrapper = styled.span`
  ${ChevronStyle}
  z-index: -1;
`;

type NavigationLinkProps = {
  text: ReactNode;
  icon: ReactNode;
  $hiddenOnMobile?: boolean;
  $hiddenOnDesktop?: boolean;
  $isSubMenuItem?: boolean;
  $isParentSubMenuOpen?: boolean;
  onClick?: () => void;
} & (
  | {
      type: 'link';
      href: string;
      openInNewPage?: boolean;
      isExternalLink?: boolean;
    }
  | { type: 'button'; onClick: () => void }
);

const NavigationLink = (props: NavigationLinkProps) => {
  const isButton = props.type === 'button';

  return (
    <NavigationLinkContainer
      $hiddenOnDesktop={props.$hiddenOnDesktop}
      $hiddenOnMobile={props.$hiddenOnMobile}
      $isSubMenuItem={props.$isSubMenuItem || false}
      as={isButton ? 'button' : props.isExternalLink ? 'a' : undefined}
      href={
        props.type === 'link' && props.isExternalLink ? props.href : undefined
      }
      onClick={props.onClick}
      target={
        isButton
          ? undefined
          : props.openInNewPage === true
          ? '_blank'
          : undefined
      }
      to={
        props.type === 'link' && !props.isExternalLink ? props.href : undefined
      }
    >
      {props.icon}

      <NavigationLinkText>{props.text}</NavigationLinkText>

      <NavigationLinkChevronWrapper>
        {props.$isParentSubMenuOpen ? (
          <ChevronDownIcon
            color={colors['secondary-400']}
            customStyle={NavigationLinkChevronDownStyle}
          />
        ) : (
          <ChevronIcon
            color={colors['secondary-400']}
            customStyle={NavigationLinkChevronStyle}
          />
        )}
      </NavigationLinkChevronWrapper>
    </NavigationLinkContainer>
  );
};
