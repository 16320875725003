/*
 * Payment Messages
 *
 * This contains all the text for the Payment container.
 */

import { defineMessages } from 'react-intl';
import { CLOSE } from './constants';

export const newModalScope = 'app.components.NewModal';

export const newModalMessage = {
  [CLOSE]: {
    id: `${newModalScope}.${CLOSE}`,
    defaultMessage: 'Fermer',
    en: 'Close',
    it: 'Chiudi',
    es: 'Cerrar',
    de: 'Schließen',
    nl: 'Sluiten',
  },
};

export default defineMessages(newModalMessage);
