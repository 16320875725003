import { defineMessages } from 'react-intl';

export const trustBoxScope = 'app.components.TrustBox';

export const trustBoxMessages = {
  clientReviews: {
    id: `${trustBoxScope}.clientReviews`,
    defaultMessage: 'Avis client',
    en: 'Client reviews',
    it: 'Opinione del cliente',
    es: 'Opinión del cliente',
    de: 'Kundenmeinung',
    nl: 'Beoordelingen van klanten',
  },
  review: {
    id: `${trustBoxScope}.review`,
    defaultMessage: '<strong>4.7</strong> sur 1146 avis',
    en: '<strong>4.7</strong> out of 1146 reviews',
    it: '<strong>4,7</strong> su 1146 recensioni',
    es: '<strong>4,7</strong> sobre 1146 opiniones',
    de: '<strong>4,7</strong> von 1146 Bewertungen',
    nl: '<strong>4,7</strong> uit 1146 beoordelingen',
  },
};

export default defineMessages(trustBoxMessages);
