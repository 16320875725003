import { createContext, useContext, useState } from 'react';
import { ETripType } from '../../common/types';
import { BASIC_REQUEST_HEADERS, HTTP, URL_CART } from '../../helpers/constants';
import { getDeviceId } from '../../helpers/devices';
import { Sentry } from '../../libs/sentry';
import request from '../../utils/request';
import { ISearchParameters } from '../Search/types';
import { DEFAULT_CART_TRIPS } from './constants';
import {
  ICart,
  ICartContext,
  ICartTrip,
  ICartTrips,
  ICartTripsIdentifier,
} from './types';

export const CartContext = createContext<ICartContext>({
  cartTrips: DEFAULT_CART_TRIPS,
  addTripToCart: () => {},
  addTripsToCart: () => {},
  createCart: async () => {
    return null;
  },
  getCart: () => {
    return null;
  },
});

interface CartProviderProps {
  children: React.ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cartTrips, setCartTrips] = useState<ICartTrips>(DEFAULT_CART_TRIPS);

  /**
   * @description Add a trip to the cart
   * @param {ICartTrip} trip The trip to add
   * @param {ETripType} direction The direction of the trip to be added (outbound or return trip)
   */
  function addTripToCart(trip: ICartTrip, direction: ETripType): void {
    setCartTrips((currentTrips) => {
      return {
        ...currentTrips,
        [direction]: trip,
      };
    });
  }

  /**
   * @description Create a cart and return it
   * @param {ISearchParameters} searchParameters
   * @param {ICartTripsIdentifier} identifiers The identifiers of the trips of the cart
   * @param {string} partnerId The partner Id
   * @param {string} partnerExternalId The partner external id
   * @returns {Promise<ICart | null>} The created cart or null if there was an error
   */
  async function createCart(
    searchParameters: ISearchParameters,
    identifiers: ICartTripsIdentifier,
    partnerId?: string,
    partnerExternalId?: string,
  ): Promise<ICart | null> {
    try {
      const deviceId = getDeviceId();

      const { cart } = await request(URL_CART, {
        method: HTTP.METHODS.POST,
        headers: BASIC_REQUEST_HEADERS,
        body: JSON.stringify({
          outboundTripId: identifiers.outboundTrip,
          returnTripId: identifiers.returnTrip,
          partnerId,
          partnerExternalId,
          passengers: searchParameters.passengers.map((passenger) => ({
            title: passenger.title || undefined,
            firstName: passenger.firstName || undefined,
            lastName: passenger.lastName || undefined,
            birthDay: undefined,
            birthMonth: undefined,
            birthYear: undefined,
            birthdate: passenger.birthdate || undefined,
            age: passenger.age,
            discountCards: passenger.discountCards.map(({ id }) => ({ id })),
          })),
          deviceId,
        }),
      });

      return cart;
    } catch (error) {
      if (!error.capturedBySentry) {
        Sentry.captureException(error);
      }

      return null;
    }
  }
  /**
   * @description Retrieve the cart information
   * @param cartId
   * @returns
   */
  async function getCart(cartId: string, signal?: AbortSignal): Promise<ICart> {
    const { cart }: { cart: ICart } = await request(`${URL_CART}/${cartId}`, {
      method: HTTP.METHODS.GET,
      headers: BASIC_REQUEST_HEADERS,
      signal: signal,
    });

    return cart;
  }

  const value: ICartContext = {
    cartTrips: cartTrips,
    addTripToCart,
    addTripsToCart: setCartTrips,
    createCart,
    getCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCart = (): ICartContext => {
  const context = useContext(CartContext);

  if (!context) {
  }

  return context;
};
