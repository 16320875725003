import { FormattedMessage } from 'react-intl';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import NewsletterForm from '../../../containers/Newsletter/View/NewsletterForm';
import Nodi from '../../../images/illustrations/nodi.webp';
import { screens } from '../../../styles/constants';
import messages from '../messages';

const Container = styled.div<NewsletterProps>`
  ${tw`w-full`}

  ${({ customStyle }) => customStyle}
`;

const Title = styled.h3`
  ${tw`m-0 ml- p-0 text-p-medium leading-p-medium`}

  @media (min-width:${screens.lg}) {
    margin-left: 75px;
  }
`;

const FormWrapper = styled.div`
  ${tw`relative`}
`;

const NodiImage = styled.img`
  ${tw`absolute l-0 hidden lg:block`}
  top: -57px;
  width: 63px;
`;

type NewsletterProps = {
  customStyle?: FlattenSimpleInterpolation;
};

const Newsletter = (props: NewsletterProps) => {
  return (
    <Container {...props}>
      <Title>
        <FormattedMessage {...messages.theBestPlansToSave} />
      </Title>

      <FormWrapper>
        <NodiImage alt="Nodi" src={Nodi} />
        <NewsletterForm inputPlaceholder="p.balekani@gmail.com" />
      </FormWrapper>
    </Container>
  );
};

export default Newsletter;
