import PropTypes from 'prop-types';
import Bounce from './Bounce';
import Wrapper from './Wrapper';

const Loader = ({ desktop, mobile, white }) => (
  <Wrapper desktop={desktop} mobile={mobile}>
    <Bounce white={white} />
  </Wrapper>
);

Loader.propTypes = {
  desktop: PropTypes.number,
  mobile: PropTypes.number,
  white: PropTypes.bool,
};

export default Loader;
