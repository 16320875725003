import { homepageScope } from '../constants';

export const homePageTravelListMessages = {
  travelListTitle: {
    id: `${homepageScope}.travelListTitle`,
    defaultMessage: '12 destinations populaires en France',
    en: '12 popular destinations in France',
    it: '12 destinazioni popolari in Francia',
    es: '12 destinos populares en Francia',
    de: '12 beliebte Reiseziele in Frankreich',
    nl: '12 populaire bestemmingen in Frankrijk',
  },
};
