import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyCity = lazy(() =>
  import(/* webpackChunkName: 'City' */ './index'),
);

export default function City(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyCity {...props} />
    </Suspense>
  );
}
