import React, { MouseEventHandler } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import Button from '../../components/Forms/Button';
import { P1 } from '../../components/Typographies/Paragraph';
import { H4 } from '../../components/Typographies/Title';
import SadNodiImage from '../../images/illustrations/sad-nodi-reduce.webp';
import SadNodiImage2x from '../../images/illustrations/sad-nodi-reduce-2x.webp';
import SadNodiImage3x from '../../images/illustrations/sad-nodi-reduce-3x.webp';

const Container = styled.div<{ customStyle?: FlattenSimpleInterpolation }>`
  ${tw`rounded-30 border mx-auto flex flex-col items-center text-center p-medium2 mt-huge`}
  ${({ customStyle }) => customStyle}
`;

const DefaultImage = styled.div`
  ${tw`rounded-15 mb-medium2`}
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-image: url(${SadNodiImage});

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${SadNodiImage2x});
  }

  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
    background-image: url(${SadNodiImage3x});
  }
`;

const StyledP1 = styled(P1)`
  ${tw`my-medium`}
`;

type ErrorMessageTemplateProps = {
  image?: React.ReactNode;
  title: React.ReactNode;
  paragraph: React.ReactNode;
  buttonContent: React.ReactNode;
  customStyle?: FlattenSimpleInterpolation;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
};

export const ErrorMessageTemplate = (props: ErrorMessageTemplateProps) => {
  const {
    buttonContent,
    image = <DefaultImage />,
    paragraph,
    title,
    customStyle,
    onButtonClick,
  } = props;

  return (
    <Container customStyle={customStyle}>
      {image}

      <H4>{title}</H4>

      <StyledP1>{paragraph}</StyledP1>

      <Button onClick={onButtonClick} variant="PRIMARY">
        {buttonContent}
      </Button>
    </Container>
  );
};
