import { API_URL, BASIC_REQUEST_HEADERS } from '../../helpers/constants';
import request from '../../utils/request';
import {
  UpdateUserPassengerPayload,
  User,
  UserPassenger,
  UserPassengerCreationAttributes,
} from './types';

export const getUser = async (token: string): Promise<User> => {
  return request(`${API_URL}/users/me`, {
    method: 'GET',
    headers: {
      ...BASIC_REQUEST_HEADERS,
      authorization: `Bearer ${token}`,
    },
  });
};

export const getUserPassengers = async (
  token: string,
): Promise<UserPassenger[]> => {
  return request(`${API_URL}/users/me/passengers`, {
    method: 'GET',
    headers: {
      ...BASIC_REQUEST_HEADERS,
      authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserPassenger = async (
  token: string,
  payload: UpdateUserPassengerPayload,
): Promise<UserPassenger[]> => {
  return request(`${API_URL}/users/me/passengers/${payload.passengerId}`, {
    method: 'PUT',
    headers: {
      ...BASIC_REQUEST_HEADERS,
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.updatedPassenger),
  });
};

export const createUserPassenger = async (
  token: string,
  passenger: UserPassengerCreationAttributes,
): Promise<UserPassenger[]> => {
  return request(`${API_URL}/users/me/passengers`, {
    method: 'POST',
    headers: {
      ...BASIC_REQUEST_HEADERS,
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(passenger),
  });
};

export const deleteUserPassenger = async (
  token: string,
  passengerId: UserPassenger['id'],
): Promise<UserPassenger[]> => {
  return request(`${API_URL}/users/me/passengers/${passengerId}`, {
    method: 'DELETE',
    headers: {
      ...BASIC_REQUEST_HEADERS,
      authorization: `Bearer ${token}`,
    },
  });
};
