import { createContext, useReducer } from 'react';
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  BASIC_REQUEST_HEADERS,
  URL_ORDER_TICKETS,
} from '../../helpers/constants';
import request from '../../utils/request';

/**
 * @description The initial state
 */
export const initialState = {
  isModalVoucherOpen: false,
  voucherDetails: {
    voucher: null,
    expireDateUTCUnix: null,
    amountCents: null,
    usableOn: null,
  },

  cancellation: {
    segmentId: null,
    conditions: null,
    isCancellationModalOpen: false,
    isLoading: false,
  },
  edition: {
    isEditionModalOpen: false,
  },
};

const TicketCancellationContext = createContext(initialState);

/**
 * @description The reducer for the ticket cancellation context
 * @param {object} state The initial state
 * @param {*} action The action to trigger
 * @returns {object} The state updated
 */
export function TicketCancellationReducer(state, action) {
  switch (action.type) {
    case 'setModalVoucherDetails':
      return {
        ...state,
        voucherDetails: action.voucherDetails,
      };
    case 'setIsModalVoucherOpen':
      return {
        ...state,
        isModalVoucherOpen: action.isModalVoucherOpen,
      };

    case 'setCancellationConditions':
      return {
        ...state,
        cancellation: {
          ...state.cancellation,
          conditions: action.cancellationConditions,
        },
      };

    case 'setIsLoading':
      return {
        ...state,
        cancellation: {
          ...state.cancellation,
          isLoading: action.isLoading,
        },
      };

    case 'setIsCancellationModalOpen':
      return {
        ...state,
        cancellation: {
          ...state.cancellation,
          isCancellationModalOpen: action.isCancellationModalOpen,
        },
      };
    case 'setIsEditionModalOpen':
      return {
        ...state,
        edition: {
          ...state.edition,
          isEditionModalOpen: action.isEditionModalOpen,
        },
      };
    case 'setSegmentId':
      return {
        ...state,
        cancellation: {
          ...state.cancellation,
          segmentId: action.segmentId,
        },
      };
    default:
      return state;
  }
}

const TicketCancellationProvider = (props) => {
  const [state, dispatch] = useReducer(TicketCancellationReducer, initialState);

  /**
   * @description Set the voucher details
   * @param {object} voucherDetails The voucher details
   * @return {void}
   */
  const setModalVoucherDetails = (voucherDetails) => {
    dispatch({ type: 'setModalVoucherDetails', voucherDetails });
  };

  /**
   * @description Reset the voucher details
   * @return {void}
   */
  const initVoucherDetails = () => {
    dispatch({
      type: 'setModalVoucherDetails',
      voucherDetails: initialState.voucherDetails,
    });
  };

  /**
   * @description Set whether the voucher modal is open.
   * @param {bool} isModalVoucherOpen Represents if the modal is open or not
   * @returns {void}
   */
  const setIsModalVoucherOpen = (isModalVoucherOpen) => {
    dispatch({
      type: 'setIsModalVoucherOpen',
      isModalVoucherOpen: isModalVoucherOpen,
    });
  };

  /**
   * @description fetch the cancellation conditions for an orderTicket
   * @param {number} orderTicketId the orderTicket id
   * @returns {void}
   */
  const setCancellationConditions = async (orderTicketId) => {
    let cancellationConditions = null;

    if (orderTicketId) {
      const authorization = localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`;

      cancellationConditions = await request(
        `${URL_ORDER_TICKETS}/${orderTicketId}/cancellation/conditions`,
        {
          method: 'GET',
          headers: {
            ...BASIC_REQUEST_HEADERS,
            authorization,
          },
        },
      );
    }

    dispatch({ type: 'setCancellationConditions', cancellationConditions });
  };

  /**
   * @description Cancel a booking
   * @param {number} orderTicketId The orderTicket id to cancelled
   * @param {fuction()} getOrder Function that refresh the order informations
   * @returns {void}
   */
  const cancelBooking = async (orderTicketId, getOrder) => {
    try {
      setIsLoading(true);

      const authorization = localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`;

      const response = await request(
        `${URL_ORDER_TICKETS}/${orderTicketId}/cancellation`,
        {
          method: 'PUT',
          headers: {
            ...BASIC_REQUEST_HEADERS,
            authorization,
          },
        },
      );

      setIsLoading(false);

      if (response?.id) {
        getOrder();
        closeCancellationModal();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * @description Sets the boolean that represent if there is a response from the cancellation query
   * @param {boolean} isLoading
   */
  const setIsLoading = (isLoading) => {
    dispatch({ type: 'setIsLoading', isLoading });
  };

  /**
   * @description Allow to open and close the cancellation modal
   * @param {boolean} isCancellationModalOpen
   */
  const setIsCancellationModalOpen = (isCancellationModalOpen) => {
    dispatch({ type: 'setIsCancellationModalOpen', isCancellationModalOpen });
  };

  /**
   * @description Allow to open and close the edition modal
   * @param {boolean} isEditionModalOpen
   */
  const setIsEditionModalOpen = (isEditionModalOpen) => {
    dispatch({ type: 'setIsEditionModalOpen', isEditionModalOpen });
  };

  /**
   * @description Set the id of the segment we want to cancel
   * @param {number} segmentId The id of the segment we want to cancel
   */
  const setSegmentId = (segmentId) => {
    dispatch({ type: 'setSegmentId', segmentId });
  };

  /**
   * @description retrieves and displays the cancellation conditions of a segment in a modal
   * @param {object} segment the segment to be cancelled
   */
  const openCancellationModal = (segment) => {
    setCancellationConditions(Number(segment.id));
    setSegmentId(segment.id);
    setIsCancellationModalOpen(true);
  };

  /**
   * @description retrieves and displays the edition conditions of a segment in a modal
   * @param {object} segment the segment to be cancelled
   */
  const openEditionModal = (segment) => {
    setCancellationConditions(Number(segment.id));
    setSegmentId(segment.id);
    setIsEditionModalOpen(true);
  };

  /**
   * @description close the cancellation modal
   */
  const closeCancellationModal = () => {
    setIsCancellationModalOpen(false);
    setSegmentId(null);
    setCancellationConditions(null);
  };

  /**
   * @description close the edition modal
   */
  const closeEditionModal = () => {
    setIsEditionModalOpen(false);
    setSegmentId(null);
    setCancellationConditions(null);
  };

  const value = {
    ...state,
    setModalVoucherDetails,
    initVoucherDetails,
    setIsModalVoucherOpen,
    cancelBooking,
    openCancellationModal,
    openEditionModal,
    closeEditionModal,
    closeCancellationModal,
  };

  return <TicketCancellationContext.Provider value={value} {...props} />;
};

export { TicketCancellationContext, TicketCancellationProvider };
