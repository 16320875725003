import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import H2 from '../../../components/H3';
import NewModal from '../../../components/NewModal';
import ImageTicket from '../../../images/ticket.svg';
import messages from '../messages';

const Container = styled.div`
  ${tw`w-full flex flex-col items-center text-center`}
  gap:2rem;
`;

const Title = styled(H2)`
  margin: 0;
`;

const Image = styled.img`
  ${tw`max-w-xxs`}
`;

export const SuccessModal = ({ intl, isOpen }) => {
  return (
    <NewModal
      intl={intl}
      isOpen={isOpen}
      main={
        <Container>
          <Title>
            <FormattedMessage {...messages.messageReceived} />
          </Title>
          <Image src={ImageTicket} />

          <p>
            <FormattedMessage {...messages.messageSentConfirmation} />
          </p>
          <p>
            <FormattedMessage {...messages.seeYouSoon} />
          </p>
        </Container>
      }
      onClose={() => (window.location.href = '/')}
    />
  );
};

SuccessModal.propTypes = {
  intl: intlShape,
  isOpen: PropTypes.bool,
};
