import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import Button from '../../../components/Forms/Button';
import Input, { FocusInputStyle } from '../../../components/Forms/Input';
import { P2 } from '../../../components/Typographies/Paragraph';
import { ChevronIcon } from '../../../images/icons/chevron';
import { screens } from '../../../styles/constants';
import messages from '../messages';
import { useNewsletterForm } from './NewsletterForm.hook';

const InputCss = css`
  ${tw`md:rounded-br-none md:rounded-tr-none md:border-r-0 md:border-0 md:focus:border-0 focus:shadow-none`}
`;

const Form = styled.form`
  ${tw`flex flex-col 
  md:bg-white md:flex-row md:rounded-30
  md:border md:border-solid md:border-grey-300 
  `}
  gap:var(--size-medium);

  @media (min-width: ${screens.md}) {
    gap: 0;

    ${FocusInputStyle}
  }
  input {
    ${tw`w-full`}
  }
  button {
    ${tw`whitespace-no-wrap md:w-full`}
  }
`;

const ChevronStyle = css`
  transform: rotate(180deg);
`;

type NewsletterFormProps = {
  inputId?: string;
  inputPlaceholder?: string;
  buttonId?: string;
};

const NewsletterForm = (props: NewsletterFormProps) => {
  const { buttonId, inputId, inputPlaceholder } = props;
  const { email, onEmailChange, onSubmit, status } = useNewsletterForm();
  const isLoading = status === 'LOADING';

  if (status === 'ERROR') {
    return (
      <div>
        <FormattedMessage {...messages.newsletterError} />
      </div>
    );
  }

  if (status === 'SUCCESS') {
    return (
      <div>
        <P2>
          <FormattedMessage {...messages.newsletterThanks} /> !
          <br />
          <FormattedMessage {...messages.newsletterSuccess} />
        </P2>
      </div>
    );
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Input
        customStyle={InputCss}
        id={inputId}
        onChange={(e) => onEmailChange(e.target.value)}
        placeholder={inputPlaceholder}
        required
        type="email"
        value={email}
      />

      <Button
        disabled={isLoading}
        id={buttonId}
        isLoading={isLoading}
        rightIcon={<ChevronIcon customStyle={ChevronStyle} />}
        variant="PRIMARY"
      >
        <FormattedMessage {...messages.newsletterSubmit} />
      </Button>
    </Form>
  );
};

export default NewsletterForm;
