import styled from 'styled-components';
import tw from 'tailwind.macro';

const P0 = styled.p`
  ${tw`text-p-large-m leading-p-large-m
        md:text-p-large md:leading-p-large
  `}
`;

const P1 = styled.p`
  ${tw`text-p-medium-m leading-p-medium-m
        md:text-p-medium md:leading-p-medium
  `}
`;

const P2 = styled.p`
  ${tw`text-p-small-m leading-p-small-m
        md:text-p-small md:leading-p-small
  `}
`;

const P3 = styled.p`
  ${tw`text-p-xsmall-m leading-p-xsmall-m
        md:text-p-xsmall md:leading-p-xsmall
  `}
`;

export { P0, P1, P2, P3 };
