export const PASSENGERS_INFO_HEADER_RECAP_TEST_ID =
  'passengers-info-header-recao';
export const PASSENGERS_INFO_FIRST_NAME_INPUT_TEST_ID =
  'passengers-info-first-name-input';
export const PASSENGERS_INFO_LAST_NAME_INPUT_TEST_ID =
  'passengers-info-last-name-input';
export const PASSENGERS_INFO_BIRTHDATE_INPUT_TEST_ID =
  'passengers-birthdate-input';
export const PASSENGERS_INFO_TITLE_INPUT_TEST_ID = 'passengers-title-input';
export const PASSENGERS_INFO_FORM_UPDATE_DISCOUNT_CARD_BUTTON_TEST_ID =
  'passengers-form-update-discount-card-button';
export const PASSENGERS_INFO_FORM_UPDATE_DISCOUNT_CARD_INPUT_TEST_ID =
  'passengers-form-update-discount-card-input';
export const PASSENGERS_INFO_PASSENGER_INPUTS_TEST_ID =
  'passengers-form-passenger-inputs';
export const PASSENGERS_INFO_OPEN_PASSENGERS_MODAL_BUTTON_TEST_ID =
  'passengers-form-open-passengers-modal-button';
export const PASSENGERS_INFO_PASSENGERS_MODAL_TEST_ID =
  'passengers-form-passengers-modal';
export const PASSENGERS_INFO_REMOVE_PASSENGER_MODAL_BUTTON_TEST_ID =
  'passengers-form-remove-passenger-modal-button';
export const PASSENGERS_INFO_ADD_PASSENGER_MODAL_BUTTON_TEST_ID =
  'passengers-form-add-passenger-modal-button';

export const PASSENGERS_INFO_CUSTOMER_ID_SELECT_TEST_ID =
  'passengers-info-customer-id-select';
export const PASSENGERS_INFO_CUSTOMER_MAIL_INPUT_TEST_ID =
  'passengers-info-customer-mail-input';
export const PASSENGERS_INFO_CUSTOMER_CONFIRM_MAIL_CHECKBOX_TEST_ID =
  'passengers-info-customer-confirm-mail-checkbox';
export const PASSENGERS_INFO_CUSTOMER_SUGGESTED_MAIL_TEST_ID =
  'passengers-info-customer-suggested-mail';
export const PASSENGERS_INFO_CUSTOMER_PHONE_NUMBER_INPUT_ID =
  'passengers-info-customer-mail-input';
export const PASSENGERS_INFO_CUSTOMER_NEWSLETTER_CHECKBOX_TEST_ID =
  'passengers-info-customer-newsletter-checkbox';
export const PASSENGERS_INFO_CUSTOMER_TERMS_CHECKBOX_TEST_ID =
  'passengers-info-customer-terms-checkbox';

export const FORM_OTHER_CUSTOMER_OPTION_ID = -1;

export const PASSENGERS_INFO_FORM_ID = 'passengers-info-form';

export const DISCOUNT_CARD_INPUT_SEPARATOR = '-';

export const SNCF_DISCOUNT_CARD_LENGTH = 17;
export const SNCF_DISCOUNT_CARD_START_NUMBER = '290901';
