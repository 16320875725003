import PropTypes from 'prop-types';
export const SELECT_TRIP = 'app/common/Cart/SELECT_TRIP';
export const RESET_TRIPS = 'app/common/Cart/RESET_TRIPS';
export const REPLACE_TRIPS = 'app/common/Cart/REPLACE_TRIPS';
export const SET_CART_ID = 'app/common/Cart/SET_CART_ID';
export const DEFAULT_SELECTED_TRIP = null;

export const CART_ID = 'cartId';

export const cartPlacePropTypes = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  lat: PropTypes.number,
  long: PropTypes.number,
  station: PropTypes.string,
});

export const cartSegmentPropTypes = PropTypes.shape({
  arrivalUTC: PropTypes.number,
  company: PropTypes.string,
  departureUTC: PropTypes.number,
  destination: cartPlacePropTypes,
  destinationOffset: PropTypes.string,
  durationMinutes: PropTypes.number,
  feeCents: PropTypes.number,
  id: PropTypes.string,
  includedProviderFeeCents: PropTypes.number,
  isBookable: PropTypes.bool,
  origin: cartPlacePropTypes,
  originOffset: PropTypes.string,
  priceCents: PropTypes.number,
  redirectionLink: PropTypes.string,
});

export const cartTripPropTypes = PropTypes.shape({
  arrivalUTC: PropTypes.number,
  available: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.string),
  departureUTC: PropTypes.number,
  destination: cartPlacePropTypes,
  destinationOffset: PropTypes.string,
  durationMinutes: PropTypes.number,
  id: PropTypes.string,
  isFullyBookable: PropTypes.bool,
  isPartiallyBookable: PropTypes.bool,
  mean: PropTypes.string,
  origin: cartPlacePropTypes,
  originOffset: PropTypes.string,
  priceCents: PropTypes.number,
  score: PropTypes.number,
  segments: PropTypes.arrayOf(cartSegmentPropTypes),
});

export const cartPropTypes = PropTypes.shape({
  outboundTrip: cartTripPropTypes,
  returnTrip: cartTripPropTypes,
});
