/**
 * Faq Messages
 *
 * This contains all the text for the Faq component.
 */
import { defineMessages } from 'react-intl';

export const faqScope = 'app.containers.Faq';

export const faqMessages = {
  metaDescription: {
    id: `${faqScope}.metaDescription`,
    defaultMessage:
      'Tictactrip est là pour vous aider à voyager mieux, plus facilement et sans prise de tête. Nos FAQs vont faciliter vos voyages !',
    en: 'Tictactrip is here to help you travel better, easier and without a headache. Our FAQs will make your trips easier!',
    es: 'Tictactrip está aquí para ayudarte a viajar mejor, más fácil y sin dolor de cabeza. ¡Nuestras FAQs harán que tus viajes sean más fáciles!',
    it: 'Tictactrip è qui per aiutarti a viaggiare meglio, più facilmente e senza mal di testa. Le nostre FAQ renderanno i tuoi viaggi più facili!',
    de: 'Tictactrip ist hier, um Ihnen zu helfen, besser, einfacher und ohne Kopfschmerzen zu reisen. Unsere FAQs machen Ihre',
    nl: 'Tictactrip is hier om u te helpen beter, gemakkelijker en zonder hoofdpijn te reizen. Onze FAQs maken uw reizen gemakkelijker!',
  },
  header: {
    id: `${faqScope}.header`,
    defaultMessage: 'Aide de Tictactrip (FAQ)',
    en: 'Tictactrip support (FAQ)',
    es: 'Soporte de Tictactrip (FAQ)',
    it: 'Supporto di Tictactrip (FAQ)',
    de: 'Tictactrip-Support (FAQ)',
    nl: 'Tictactrip-ondersteuning (FAQ)',
  },
  faqTitle: {
    id: `${faqScope}.faqTitle`,
    defaultMessage: 'FAQ Tictactrip, la foire aux questions est ouverte ! 🥳',
    en: 'FAQ Tictactrip: Frequently Asked Questions are open! 🥳',
    es: 'FAQ Tictactrip: ¡Preguntas frecuentes abiertas! 🥳',
    it: 'FAQ Tictactrip: le domande frequenti sono aperte! 🥳',
    de: 'FAQ Tictactrip: Häufig gestellte Fragen sind geöffnet! 🥳',
    nl: 'FAQ Tictactrip: Veelgestelde vragen zijn geopend! 🥳',
  },
  faqDescription: {
    id: `${faqScope}.faqDescription`,
    defaultMessage: `Vous avez une question bien précise ou êtes seulement à la recherche d’informations complémentaires ?

    Notre FAQ : Frequently Asked Question, vous donnera toutes les réponses que vous cherchez!
    
    Vous n’avez pas reçu vos billets ? Vous souhaitez nous contacter ? Vous vous demandez comment fonctionne le site Tictactrip ?
    
    Toutes vos questions méritent une réponse.`,
    en: 'In this space, we offer you a set of information to help you better when you use our services.',
    es: 'En este espacio, le ofrecemos un conjunto de información para ayudarlo mejor cuando use nuestros servicios.',
    it: 'In questo spazio, ti offriamo una serie di informazioni per aiutarti meglio quando usi i nostri servizi.',
    de: 'In diesem Raum bieten wir Ihnen eine Reihe von Informationen, um Ihnen besser zu helfen, wenn Sie unsere Dienste nutzen.',
    nl: 'In deze ruimte bieden we u een reeks informatie om u beter te helpen bij het gebruik van onze diensten.',
  },
  topQuestions: {
    id: `${faqScope}.topQuestions`,
    defaultMessage: 'Questions les plus fréquentes',
    en: 'Top questions',
    es: 'Preguntas frecuentes más frecuentes',
    it: 'Domande frequenti',
    de: 'Top-Fragen',
    nl: 'Topvragen',
  },
  checkAnswer: {
    id: `${faqScope}.checkAnswer`,
    defaultMessage: 'Voir la réponse',
    en: 'See the answer',
    es: 'Ver la respuesta',
    it: 'Vedi la risposta',
    de: 'Antwort sehen',
    nl: 'Bekijk het antwoord',
  },
  home: {
    id: `${faqScope}.home`,
    defaultMessage: 'Accueil',
    en: 'Home',
    es: 'Inicio',
    it: 'Home',
    de: 'Zuhause',
    nl: 'Thuis',
  },
  buyTicket: {
    id: `${faqScope}.buyTicket`,
    defaultMessage: 'Réserver votre trajet',
    en: 'Book your trip',
    es: 'Reserva tu viaje',
    it: 'Prenota il tuo viaggio',
    de: 'Buchen Sie Ihre Reise',
    nl: 'Boek je reis',
  },
  listCategories: {
    id: `${faqScope}.listCategories`,
    defaultMessage: 'Catégories',
    en: 'Categories',
    es: 'Categorías',
    it: 'Categorie',
    de: 'Kategorien',
    nl: 'Categorieën',
  },
  returnToFaq: {
    id: `${faqScope}.returnToFaq`,
    defaultMessage: "Retour à l'accueil",
    en: 'Return to home',
    es: 'Volver a inicio',
    it: 'Torna a casa',
    de: 'Zurück zum Start',
    nl: 'Terug naar huis',
  },
  returnToCategory: {
    id: `${faqScope}.returnToCategory`,
    defaultMessage: 'Précédent',
    en: 'Previous',
    es: 'Anterior',
    it: 'Precedente',
    de: 'Zurück',
    nl: 'Vorige',
  },
  articlesEmpty: {
    id: `${faqScope}.articlesEmpty`,
    defaultMessage: "Il n'y a pas encore d'articles ici",
    en: 'This category has no articles to show',
    es: 'Esta categoría no tiene artículos para mostrar',
    it: 'Questa categoria non ha articoli da mostrare',
    de: 'Diese Kategorie hat keine Artikel zu zeigen',
    nl: 'Deze categorie heeft geen artikelen om te tonen',
  },
  articles: {
    id: `${faqScope}.articles`,
    defaultMessage: 'Liste des questions',
    en: 'Articles list',
    es: 'Lista de preguntas',
    it: 'Elenco degli articoli',
    de: 'Artikelliste',
    nl: 'Lijst met artikelen',
  },
  typeNotFound: {
    id: `${faqScope}.typeNotFound`,
    defaultMessage: `L'élément que vous essayez de créer ne correspond à aucun type présent dans
    nos modèles.
    Vous ne pouvez utiliser que les types suivants : [title, subtitle, body, img].`,
    en: `The element you are trying to create does not correspond to any type present in
    our models.
    You can only use the following types: [title, subtitle, body, img].`,
    es: `El elemento que estás intentando crear no corresponde a ningún tipo presente en
    nuestros modelos.
    Solo puedes usar los siguientes tipos: [title, subtitle, body, img].`,
    it: `L'elemento che stai cercando di creare non corrisponde a nessun tipo presente in
    nostri modelli.
    Puoi utilizzare solo i seguenti tipi: [title, subtitle, body, img].`,
    de: `Das Element, das Sie zu erstellen versuchen, entspricht keinem in
    unseren Modellen vorhandenen Typ.
    Sie können nur die folgenden Typen verwenden: [title, subtitle, body, img].`,
    nl: `Het element dat je probeert te maken, komt niet overeen met een type dat aanwezig is in
    onze modellen.
    Je kunt alleen de volgende typen gebruiken: [title, subtitle, body, img].`,
  },
  tictactripTeam: {
    id: `${faqScope}.tictactripTeam`,
    defaultMessage: "L'équipe Tictactrip",
    en: 'Tictactrip Team',
    es: 'Equipo de Tictactrip',
    it: 'Squadra di Tictactrip',
    de: 'Tictactrip-Team',
    nl: 'Tictactrip-team',
  },
  editedTime: {
    id: `${faqScope}.editedTime`,
    defaultMessage: 'Mise à jour le',
    en: 'Updated time:',
    es: 'Tiempo actualizado:',
    it: 'Ora aggiornata:',
    de: 'Aktualisierte Zeit:',
    nl: 'Bijgewerkte tijd:',
  },
  relatedQuestions: {
    id: `${faqScope}.relatedQuestions`,
    defaultMessage: 'Questions liées',
    en: 'Related questions',
    es: 'Preguntas relacionadas',
    it: 'Domande correlate',
    de: 'Verwandte Fragen',
    nl: 'Gerelateerde vragen',
  },
  askQuestion: {
    id: `${faqScope}.askQuestion`,
    defaultMessage: 'Vous ne trouvez pas de réponse à votre question ?',
    en: "You don't find answers?",
    es: '¿No encuentras respuestas?',
    it: 'Non trovi risposte?',
    de: 'Sie finden keine Antworten?',
    nl: 'Vind je geen antwoorden?',
  },
  imageNotFound: {
    id: `${faqScope}.imageNotFound`,
    defaultMessage: "Cette image n'existe pas",
    en: "This picture doesn't exist",
    es: 'Esta imagen no existe',
    it: 'Questa immagine non esiste',
    de: 'Dieses Bild existiert nicht',
    nl: 'Deze afbeelding bestaat niet',
  },
};

export default defineMessages(faqMessages);
