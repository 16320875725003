import { forwardRef } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import { colors } from '../../../styles/constants';
import {
  getMobilePlatform,
  IOS_PLATFORM,
} from '../../AppPromotionBanner/utils';
import { IComponentSizes } from '../constants';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  customStyle?: FlattenSimpleInterpolation;
  componentSize?: IComponentSizes;
  displayOutline?: boolean;
}

export const FocusInputStyle = css`
  &:focus,
  &:focus-within {
    ${tw`shadow-input border-primary-400`}
  }
`;

export const InputStyle = css<InputProps>`
  ${tw` bg-white rounded-30 px-medium py-medium2 border border-solid border-grey-300 outline-none text-text font-bold
    focus:border-primary-400
  `}

  ${FocusInputStyle}

  ${({ displayOutline }) =>
    displayOutline &&
    css`
      ${tw`border-primary-400 `}
      box-shadow: 0 0 0 1px ${colors['primary-400']};
    `}

  padding-top: 18px;
  padding-bottom: 18px;

  ::placeholder,
  input::placeholder {
    color: ${colors.placeHolders};
  }

  ${({ disabled }) => disabled && tw`bg-grey50 text-placeHolders`}

  ${({ customStyle }) => customStyle}
`;

const Input = styled.input`
  ${InputStyle}
`;

type CheckboxRadioInputProps = {
  isRounded?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckboxRadioInputStyled = styled.input<CheckboxRadioInputProps>`
  ${tw`cursor-pointer border border-solid border-grey-300`}
  ${({ isRounded }) => (isRounded ? tw`rounded-full` : tw`rounded-5`)}
  width: 24px;
  height: 24px;
  appearance: none;

  &:checked {
    ${tw`border-0`}
    background-color: ${colors.secondary400};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  &:disabled {
    ${tw`bg-grey-300`}
    opacity: 0.84;
    cursor: not-allowed;
  }
`;

export const CheckboxRadioInput = forwardRef<
  HTMLInputElement,
  CheckboxRadioInputProps
>((props: CheckboxRadioInputProps, ref) => (
  <CheckboxRadioInputStyled
    {...props}
    ref={ref}
    onFocus={(e) => {
      if (getMobilePlatform() === IOS_PLATFORM) {
        (e.target as HTMLInputElement).scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }

      props.onFocus?.(e);
    }}
  >
    {props.children}
  </CheckboxRadioInputStyled>
));

export default Input;
