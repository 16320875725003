import { lazy, Suspense } from 'react';
import LazyLoading from '../../../../../components/LazyLoading';

export const LazyApiStatus = lazy(() =>
  import(/* webpackChunkName: 'Status' */ './index'),
);

export default function ApiStatus(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyApiStatus {...props} />
    </Suspense>
  );
}
