/*
 * CompanyPage Messages
 *
 * This contains all the text for the Company Page container.
 */

import { defineMessages } from 'react-intl';
import { ETransportType } from '../../../common/types';
import {
  DESTINATIONS,
  FAQ,
  HISTORY,
  NEWS_FEED,
  SERVICE_ACCESSIBILITY,
  SERVICE_AIR_CONDITIONING,
  SERVICE_ANIMAL,
  SERVICE_BIKE,
  SERVICE_LUGGAGE,
  SERVICE_MEAL,
  SERVICE_MEDIA,
  SERVICE_POWER,
  SERVICE_RESTROOM,
  SERVICE_WIFI,
  SERVICES,
} from '../constants';

export const companyScope = 'app.containers.Company';

export const companyMessages = {
  header: {
    id: `${companyScope}.header`,
    defaultMessage: 'Company Page',
    en: 'Company Page',
    es: 'Página de la empresa',
    it: 'Pagina aziendale',
    de: 'Unternehmensseite',
    nl: 'Bedrijfspagina',
  },
  [DESTINATIONS]: {
    id: `${companyScope}.${DESTINATIONS}`,
    defaultMessage: 'Découvrir',
    en: 'Discover',
    es: 'Descubrir',
    it: 'Scoprire',
    de: 'Entdecken',
    nl: 'Ontdekken',
  },
  [HISTORY]: {
    id: `${companyScope}.${HISTORY}`,
    defaultMessage: 'En savoir plus',
    en: 'Learn more',
    es: 'Más información',
    it: 'Maggiori informazioni',
    de: 'Mehr erfahren',
    nl: 'Meer informatie',
  },
  [SERVICES]: {
    id: `${companyScope}.${SERVICES}`,
    defaultMessage: 'Services à bord',
    en: 'Onboard Services',
    es: 'Servicios a bordo',
    it: 'Servizi a bordo',
    de: 'Dienstleistungen an Bord',
    nl: 'Diensten aan boord',
  },
  [FAQ]: {
    id: `${companyScope}.${FAQ}`,
    defaultMessage: 'FAQ de la compagnie',
    en: 'Company FAQ',
    es: 'Preguntas frecuentes de la empresa',
    it: "Domande frequenti dell'azienda",
    de: 'FAQ des Unternehmens',
    nl: 'Veelgestelde vragen van het bedrijf',
  },
  [NEWS_FEED]: {
    id: `${companyScope}.${NEWS_FEED}`,
    defaultMessage: 'Actualités de la compagnie',
    en: 'Company News Feed',
    es: 'Noticias de la empresa',
    it: "News feed dell'azienda",
    de: 'Unternehmensnachrichten',
    nl: 'Bedrijfsnieuwsfeed',
  },
  [SERVICE_POWER]: {
    id: `${companyScope}.${SERVICE_POWER}`,
    defaultMessage: 'Prises',
    en: 'Power Outlets',
    es: 'Enchufes',
    it: 'Prese di corrente',
    de: 'Steckdosen',
    nl: 'Stopcontacten',
  },
  [SERVICE_ACCESSIBILITY]: {
    id: `${companyScope}.${SERVICE_ACCESSIBILITY}`,
    defaultMessage: 'Accessibilité',
    en: 'Accessibility',
    es: 'Accesibilidad',
    it: 'Accessibilità',
    de: 'Barrierefreiheit',
    nl: 'Toegankelijkheid',
  },
  [SERVICE_AIR_CONDITIONING]: {
    id: `${companyScope}.${SERVICE_AIR_CONDITIONING}`,
    defaultMessage: 'Clim',
    en: 'Air Conditioning',
    es: 'Aire acondicionado',
    it: 'Aria condizionata',
    de: 'Klimaanlage',
    nl: 'Airconditioning',
  },
  [SERVICE_ANIMAL]: {
    id: `${companyScope}.${SERVICE_ANIMAL}`,
    defaultMessage: 'Animaux',
    en: 'Animals',
    es: 'Animales',
    it: 'Animali',
    de: 'Tiere',
    nl: 'Dieren',
  },
  [SERVICE_BIKE]: {
    id: `${companyScope}.${SERVICE_BIKE}`,
    defaultMessage: 'Vélo',
    en: 'Bike',
    es: 'Bicicleta',
    it: 'Bicicletta',
    de: 'Fahrrad',
    nl: 'Fiets',
  },
  [SERVICE_LUGGAGE]: {
    id: `${companyScope}.${SERVICE_LUGGAGE}`,
    defaultMessage: 'Bagages',
    en: 'Luggage',
    es: 'Equipaje',
    it: 'Bagagli',
    de: 'Gepäck',
    nl: 'Bagage',
  },
  [SERVICE_MEAL]: {
    id: `${companyScope}.${SERVICE_MEAL}`,
    defaultMessage: 'Restauration',
    en: 'Meal Service',
    es: 'Servicio de comidas',
    it: 'Servizio pasti',
    de: 'Verpflegung',
    nl: 'Maaltijdservice',
  },
  [SERVICE_MEDIA]: {
    id: `${companyScope}.${SERVICE_MEDIA}`,
    defaultMessage: 'Media',
    en: 'Media',
    es: 'Medios de comunicación',
    it: 'Media',
    de: 'Medien',
    nl: 'Media',
  },
  [SERVICE_WIFI]: {
    id: `${companyScope}.${SERVICE_WIFI}`,
    defaultMessage: 'Wifi',
    en: 'Wifi',
    es: 'Wifi',
    it: 'Wifi',
    de: 'Wifi',
    nl: 'Wifi',
  },
  [SERVICE_RESTROOM]: {
    id: `${companyScope}.${SERVICE_RESTROOM}`,
    defaultMessage: 'WC',
    en: 'Restroom',
    es: 'Baño',
    it: 'Bagno',
    de: 'WC',
    nl: 'Toilet',
  },
  seeAlso: {
    id: `${companyScope}.seeAlso`,
    defaultMessage: 'À voir aussi',
    en: 'See also',
    es: 'Ver también',
    it: 'Vedi anche',
    de: 'Siehe auch',
    nl: 'Zie ook',
  },
  website: {
    id: `${companyScope}.website`,
    defaultMessage: 'Site ',
    en: 'Website ',
    es: 'Sitio ',
    it: 'Sito ',
    de: 'Website ',
    nl: 'Website ',
  },
  companyPageTitle: {
    id: `${companyScope}.companyPageTitle`,
    defaultMessage:
      'Tous vos {transportType} <span class="highlighted">au même endroit.</span>',
    en: 'All your {transportType} <span class="highlighted">in one place.</span>',
    es: 'Todos tus {transportType} <span class="highlighted">en un solo lugar.</span>',
    it: 'Tutti i tuoi {transportType} <span class="highlighted">in un unico posto.</span>',
    de: 'Alle deine {transportType} <span class="highlighted">an einem Ort.</span>',
    nl: 'Al uw {transportType} <span class="highlighted">op één plek.</span>',
  },
  companyPageSubtitle: {
    id: `${companyScope}.companyPageSubtitle`,
    defaultMessage: 'Réservez vos billets de {transportType} parmi :',
    en: 'Book your {transportType} tickets among:',
    es: 'Reserve sus billetes de {transportType} entre:',
    it: 'Prenota i tuoi biglietti {transportType} tra:',
    de: 'Buchen Sie Ihre {transportType}-Tickets unter:',
    nl: 'Boek uw {transportType}-tickets tussen:',
  },
  [`plural-${ETransportType.BUS}`]: {
    id: `${companyScope}.plural-${ETransportType.BUS}`,
    defaultMessage: 'bus',
    en: 'bus',
    es: 'autobús',
    it: 'autobus',
    de: 'Bus',
    nl: 'bus',
  },
  [`plural-${ETransportType.TRAIN}`]: {
    id: `${companyScope}.plural-${ETransportType.TRAIN}`,
    defaultMessage: 'trains',
    en: 'trains',
    es: 'trenes',
    it: 'treni',
    de: 'Züge',
    nl: 'treinen',
  },
  [`plural-${ETransportType.CARPOOLING}`]: {
    id: `${companyScope}.plural-${ETransportType.CARPOOLING}`,
    defaultMessage: 'covoiturages',
    en: 'carpooling',
    es: 'coche compartido',
    it: 'car pooling',
    de: 'Mitfahrgelegenheiten',
    nl: 'carpoolen',
  },
};

export default defineMessages(companyMessages);
