export enum TransportId {
  PLANE = 'PLANE',
  TGV = 'TRAIN_TGV',
  INTERCITES = 'TRAIN_INTERCITES',
  THERMAL_CAR = 'THERMAL_CAR',
  ELECTRIC_CAR = 'ELECTRIC_CAR',
  COACH = 'COACH',
  BIKE_OR_WALK = 'BIKE_OR_WALK',
  ELECTRIC_BIKE_OR_SCOOTER = 'ELECTRIC_BIKE_OR_SCOOTER',
  THERMAL_BUS = 'THERMAL_BUS',
  TRAMWAY = 'TRAMWAY',
  METRO = 'METRO',
  SCOOTER_OR_LIGHT_MOTORCYCLES = 'SCOOTER_OR_LIGHT_MOTORCYCLES',
  MOTORCYCLES = 'MOTORCYCLES',
  RER_OR_TRANSILIEN = 'RER_OR_TRANSILIEN',
  TER = 'TER',
  ELECTRIC_BUS = 'ELECTRIC_BUS',
  GNV_BUS = 'GNV_BUS',
}

type ECV = { id: number; value: number; _comment?: string };

export interface TransportMode {
  name: string;
  ecvs?: {
    display: { min?: number; max?: number };
    empreinteId?: number;
    subtitle?: string;
    ecv: ECV[];
  }[];
  ecv?: ECV[];
  emoji: string;
  subtitle?: string;
  carpool?: boolean;
  total?: number;
}
