import { defineMessages } from 'react-intl';

export const funnelscope = 'app.components.Funnel';

export const funnelMessages = {
  passenger: {
    id: `${funnelscope}.passenger`,
    defaultMessage: 'passager',
    en: 'passenger',
    es: 'pasajero',
    it: 'passeggero',
    de: 'Passagier',
    nl: 'passagier',
  },
  passengers: {
    id: `${funnelscope}.passengers`,
    defaultMessage: 'passagers',
    en: 'passengers',
    it: 'passeggero',
    es: 'pasajeros',
    de: 'Passagiere',
    nl: 'passagiers',
  },
};

export default defineMessages(funnelMessages);
