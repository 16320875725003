import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';
import Loader from '../Loader';
import { PLEASE_WAIT } from './constants';
import messages from './messages';

export const buttonSubmitStyle = css`
  ${tw`flex justify-center items-center
  	p-def md:p-0 md:px-def  
    rounded-lg
  	text-h6 text-white text-center no-underline
  	bg-primary400 hover:bg-primary500
    h-abs6`};

  font-weight: 700;
  transition: background-color 150ms ease;
`;

const StyledButton = styled.button`
  ${buttonSubmitStyle};
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;

  ${({ disabled }) =>
    disabled &&
    tw`cursor-not-allowed hover:bg-primaryLight100 bg-primaryLight100`};
`;

/**
 * @typedef {Object} SubmitButtonProps
 * @property {JSX.Element} children
 * @property {boolean} [isDisabled] Whether the button is disabled
 * @property {boolean} [isLoading] Whether to display a loading indicator
 * @property {Object} [loadingMessage] Message to display when loading
 */

/**
 * @description Display a submit button with a loading indicator
 * @param {SubmitButtonProps} props
 * @returns {JSX.Element}
 */
function SubmitButton({
  children,
  isDisabled,
  isLoading,
  loadingMessage,
  ...props
}) {
  return (
    <StyledButton disabled={isDisabled || isLoading} type="submit" {...props}>
      {isLoading ? (
        <Fragment>
          <Loader desktop={10} mobile={10} />
          <FormattedMessage {...loadingMessage} />
        </Fragment>
      ) : (
        children
      )}
    </StyledButton>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.object,
};

SubmitButton.defaultProps = {
  loadingMessage: messages[PLEASE_WAIT],
};

export default SubmitButton;
