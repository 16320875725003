export const paymentLinkScope = 'app.containers.PaymentLink';

export const PAGE_TITLE = 'pageTitle';
export const PAYMENT_LINK_CARD_TITLE = 'paymentLinkCardTitle';
export const PAYMENT_LINK_CARD_CONTENT = 'paymentLinkCardContent';

export const PAYMENT_FORM_REF = 'paymentFormRef';
export const PAYMENT_FORM_PRICE = 'paymentFormPrice';
export const PAYMENT_FORM_TITLE = 'paymentFormTitle';
export const PAYMENT_FORM_LASTNAME = 'paymentFormLastName';
export const PAYMENT_FORM_FIRSTNAME = 'paymentFormFirstName';
export const PAYMENT_FORM_CUSTOMERID = 'paymentFormCustomerId';
export const PAYMENT_FORM_GENERATE = 'paymentFormGenerate';

export const SELECT_TITLE_MS = 'selectTitleMs';
export const TITLE_MR = 'MR';
export const SELECT_TITLE_MR = 'selectTitleMr';
export const TITLE_MS = 'MS';

export const INPUT_REF_PLACEHOLDER = 'inputRefPlaceHolder';
export const INPUT_PRICE_PLACEHOLDER = 'inputPricePlaceHolder';
export const INPUT_LASTNAME_PLACEHOLDER = 'inputLastNamePlaceHolder';
export const INPUT_FIRSTNAME_PLACEHOLDER = 'inpuFirstNamePlaceHolder';
export const INPUT_CUSTOMERID_PLACEHOLDER = 'inputCustomerIdPlaceHolder';

export const RESET_PAYMENT_LINK_FORM = 'resetPaymentLinkForm';

export const PAYMENT_LINK_COPY_LINK = 'paymentLinkCopyLink';
export const PAYMENT_LINK_GENERATED_LINK = 'paymentLinkGeneratedLink';

export const PAYLINE_BASE_URL = 'https://payment-2.payline.com/services/token/';
export const PAYLINE_SIMULATE_VISIT = '/state/current';
export const PAYLINE_SIMULATE_CLICK = '/paymentRequest';
export const PAYLINE_PAYMENT_LINK_URL =
  'https://payment.payline.com/payByMailHook?token=';
