import { defineMessages } from 'react-intl';
import { EPassengerCategory } from '../../common/types';

export const travelSearchBarScope = 'app.components.TravelSearchBar';

export const travelSearchBarMessages = {
  formTitle: {
    id: `${travelSearchBarScope}.formTitle`,
    defaultMessage: 'Le voyage démarre maintenant',
    en: 'Your journey starts now',
    it: 'Il tuo viaggio inizia ora',
    es: 'Tu viaje comienza ahora',
    de: 'Deine Reise beginnt jetzt',
    nl: 'Je reis begint nu',
  },
  addReturn: {
    id: `${travelSearchBarScope}.addReturn`,
    defaultMessage: 'Ajouter un retour',
    en: 'Add Return',
    it: 'Aggiungi un ritorno',
    es: 'Agregar un retorno',
    de: 'Rückkehr hinzufügen',
    nl: 'Voeg een retour toe',
  },
  validate: {
    id: `${travelSearchBarScope}.validate`,
    defaultMessage: 'Valider',
    en: 'Validate',
    it: 'Confermare',
    es: 'Validar',
    de: 'Bestätigen',
    nl: 'Valideren',
  },
  search: {
    id: `${travelSearchBarScope}.search`,
    defaultMessage: 'Rechercher',
    en: 'Search',
    it: 'Ricerca',
    es: 'Buscar',
    de: 'Suchen',
    nl: 'Zoeken',
  },
  departureCity: {
    id: `${travelSearchBarScope}.departureCity`,
    defaultMessage: 'Ville de départ',
    en: 'Departure city',
    it: 'Città di partenza',
    es: 'Ciudad de salida',
    de: 'Abflugstadt',
    nl: 'Vertrekstad',
  },
  arrivalCity: {
    id: `${travelSearchBarScope}.arrivalCity`,
    defaultMessage: "Ville d'arrivée",
    en: 'Arrival city',
    it: 'Città di arrivo',
    es: 'Ciudad de llegada',
    de: 'Ankunftsstadt',
    nl: 'Aankomststad',
  },
  mostFrequent: {
    id: `${travelSearchBarScope}.mostFrequent`,
    defaultMessage: 'Les plus fréquentes',
    en: 'Most frequent',
    it: 'Più frequenti',
    es: 'Más frecuentes',
    de: 'Am häufigsten',
    nl: 'Meest voorkomende',
  },
  citiesPanelDepartureOverlayTitle: {
    id: `${travelSearchBarScope}.citiesPanelDepartureOverlayTitle`,
    defaultMessage: 'D’où partez-vous ?',
    en: 'Where are you departing from?',
    it: 'Da dove stai partendo?',
    es: '¿Desde dónde estás saliendo?',
    de: 'Wo starten Sie?',
    nl: 'Waar vertrekt u?',
  },
  citiesPanelArrivalOverlayTitle: {
    id: `${travelSearchBarScope}.citiesPanelArrivalOverlayTitle`,
    defaultMessage: 'Où allez-vous ?',
    en: 'Where are you going?',
    it: 'Dove stai andando?',
    es: '¿A dónde vas?',
    de: 'Wo gehst du hin?',
    nl: 'Waar ga je naartoe?',
  },
  citiesPanelNoResults: {
    id: `${travelSearchBarScope}.citiesPanelNoResults`,
    defaultMessage: 'Aucun résultat trouvé',
    en: 'No results found',
    it: 'Nessun risultato trovato',
    es: 'No se encontraron resultados',
    de: 'Keine Ergebnisse gefunden',
    nl: 'Geen resultaten gevonden',
  },
  datesPanelDepartureOverlayTitle: {
    id: `${travelSearchBarScope}.datesPanelDepartureOverlayTitle`,
    defaultMessage: 'Quand partez-vous ?',
    en: 'When are you leaving?',
    it: 'Quando partite?',
    es: '¿Cuándo te vas?',
    de: 'Wann fährst du ab?',
    nl: 'Wanneer vertrek je?',
  },
  datesPanelArrivalOverlayTitle: {
    id: `${travelSearchBarScope}.datesPanelArrivalOverlayTitle`,
    defaultMessage: 'Quand revenez-vous ?',
    en: 'When are you coming back?',
    it: 'Quando tornate?',
    es: '¿Cuándo vuelves?',
    de: 'Wann kommst du zurück?',
    nl: 'Wanneer kom je terug?',
  },
  datesPanelTimePickerTitle: {
    id: `${travelSearchBarScope}.datesPanelTimePickerTitle`,
    defaultMessage: 'À partir de',
    en: 'From',
    it: 'A partire dalle',
    es: 'A partir de las',
    de: 'Ab',
    nl: 'Vanaf',
  },

  passengersPaneOverlayTitle: {
    id: `${travelSearchBarScope}.passengersPaneOverlayTitle`,
    defaultMessage: 'Passagers',
    en: 'Passengers',
    it: 'Passeggeri',
    es: 'Pasajeros',
    de: 'Passagiere',
    nl: 'Passagiers',
  },
  passengersPanelAge: {
    id: `${travelSearchBarScope}.passengersPanelAge`,
    defaultMessage: 'Âge',
    en: 'Age',
    it: 'Età',
    es: 'Edad',
    de: 'Alter',
    nl: 'Leeftijd',
  },
  passengersPanelPassengerAge: {
    id: `${travelSearchBarScope}.passengersPanelPassengerAge`,
    defaultMessage: `{age, plural,
      =0 {0 an}
      one {1 an}
      other {{age} ans}
  }`,
    fr: `{age, plural,
      =0 {0 an}
      one {1 an}
      other {{age} ans}
  }`,
    en: `{age, plural,
      =0 {0 year}
      one {1 year}
      other {{age} years}
  }`,
    it: `{age, plural,
      =0 {0 anno}
      one {1 anno}
      other {{age} anni}
  }`,
    es: `{age, plural,
      =0 {0 año}
      one {1 año}
      other {{age} años}
  }`,
    de: `{age, plural,
      =0 {0 Jahr}
      one {1 Jahr}
      other {{age} Jahre}
  }`,
    nl: `{age, plural,
      =0 {0 jaar}
      one {1 jaar}
      other {{age} jaar}
  }`,
  },
  passengersPanelDiscountCard: {
    id: `${travelSearchBarScope}.passengersPanelDiscountCard`,
    defaultMessage: 'Carte de réduction',
    en: 'Discount card',
    it: 'Carta sconto',
    es: 'Tarjeta de descuento',
    de: 'Rabattkarte',
    nl: 'Kortingskaart',
  },

  passengersCount: {
    id: `${travelSearchBarScope}.passengersCount`,
    defaultMessage:
      '{count, plural, =0 {0 passager} one {1 passager} other {{count} passagers}}',
    en: '{count, plural, =0 {0 passengers} one {1 passenger} other {{count} passengers}}',
    it: '{count, plural, =0 {0 passeggero} one {1 passeggero} other {{count} passeggeri}}',
    es: '{count, plural, =0 {0 pasajero} one {1 pasajero} other {{count} pasajeros}}',
    de: '{count, plural, =0 {0 Passagier} one {1 Passagier} other {{count} Passagiere}}',
    nl: '{count, plural, =0 {0 passagier} one {1 passagier} other {{count} passagiers}}',
  },
  cardsCount: {
    id: `${travelSearchBarScope}.cardCount`,
    defaultMessage:
      '{count, plural, =0 {0 carte} one {1 carte} other {{count} cartes}}',
    en: '{count, plural, =0 {0 cards} one {1 card} other {{count} cards}}',
    it: '{count, plural, =0 {0 carta} one {1 carta} other {{count} carte}}',
    es: '{count, plural, =0 {0 tarjeta} one {1 tarjeta} other {{count} tarjetas}}',
    de: '{count, plural, =0 {0 Karte} one {1 Karte} other {{count} Karten}}',
    nl: '{count, plural, =0 {0 kaart} one {1 kaart} other {{count} kaarten}}',
  },
  [`passengers-${EPassengerCategory.ADULT}-count`]: {
    id: `${travelSearchBarScope}.passengers-${EPassengerCategory.ADULT}-count`,
    defaultMessage: '{count, plural, one {1 adulte} other {{count} adultes}}',
    en: '{count, plural, one {1 adult} other {{count} adults}}',
    it: '{count, plural, one {1 adulto} other {{count} adulti}}',
    es: '{count, plural, one {1 adulto} other {{count} adultos}}',
    de: '{count, plural, one {1 Erwachsener} other {{count} Erwachsene}}',
    nl: '{count, plural, one {1 volwassene} other {{count} volwassenen}}',
  },

  [`passengers-${EPassengerCategory.YOUNG}-count`]: {
    id: `${travelSearchBarScope}.passengers-${EPassengerCategory.YOUNG}-count`,
    defaultMessage: '{count, plural, one {1 jeune} other {{count} jeunes}}',
    en: '{count, plural, one {1 youth} other {{count} youths}}',
    it: '{count, plural, one {1 giovane} other {{count} giovani}}',
    es: '{count, plural, one {1 joven} other {{count} jóvenes}}',
    de: '{count, plural, one {1 Jugendlicher} other {{count} Jugendliche}}',
    nl: '{count, plural, one {1 jongere} other {{count} jongeren}}',
  },
  [`passengers-${EPassengerCategory.SENIOR}-count`]: {
    id: `${travelSearchBarScope}.passengers-${EPassengerCategory.SENIOR}-count`,
    defaultMessage: '{count, plural, one {1 sénior} other {{count} séniors}}',
    en: '{count, plural, one {1 senior} other {{count} seniors}}',
    it: '{count, plural, one {1 anziano} other {{count} anziani}}',
    es: '{count, plural, one {1 sénior} other {{count} séniores}}',
    de: '{count, plural, one {1 Senior} other {{count} Senioren}}',
    nl: '{count, plural, one {1 senior} other {{count} senioren}}',
  },

  [`passengersPanelCategory-${EPassengerCategory.ADULT}`]: {
    id: `${travelSearchBarScope}.passengersPanelCategory-${EPassengerCategory.ADULT}`,
    defaultMessage: 'Adulte',
    en: 'Adult',
    it: 'Adulto',
    es: 'Adulto',
    de: 'Erwachsene',
    nl: 'Volwassene',
  },
  [`passengersPanelCategory-${EPassengerCategory.ADULT}-age`]: {
    id: `${travelSearchBarScope}.passengersPanelCategory-${EPassengerCategory.ADULT}-age`,
    defaultMessage: '{category} (28-59 ans)',
    en: '{category} (28-59 years)',
    it: '{category} (28-59 anni)',
    es: '{category} (28-59 años)',
    de: '{category} (28-59 Jahre)',
    nl: '{category} (28-59 jaar)',
  },

  [`passengersPanelCategory-${EPassengerCategory.YOUNG}`]: {
    id: `${travelSearchBarScope}.passengersPanelCategory-${EPassengerCategory.YOUNG}`,
    defaultMessage: 'Jeune',
    en: 'Young',
    fr: 'Jeune',
    it: 'Giovane',
    es: 'Joven',
    de: 'Jung',
    nl: 'Jong',
  },
  [`passengersPanelCategory-${EPassengerCategory.YOUNG}-age`]: {
    id: `${travelSearchBarScope}.passengersPanelCategory-${EPassengerCategory.YOUNG}-age`,
    defaultMessage: '{category} (0-27 ans)',
    en: '{category} (0-27 years)',
    fr: '{category} (0-27 ans)',
    it: '{category} (0-27 anni)',
    es: '{category} (0-27 años)',
    de: '{category} (0-27 Jahre)',
    nl: '{category} (0-27 jaar)',
  },

  [`passengersPanelCategory-${EPassengerCategory.SENIOR}`]: {
    id: `${travelSearchBarScope}.passengersPanelCategory-${EPassengerCategory.SENIOR}`,
    defaultMessage: 'Sénior',
    en: 'Senior',
    it: 'Sénior',
    es: 'Sénior',
    de: 'Sénior',
    nl: 'Sénior',
  },
  [`passengersPanelCategory-${EPassengerCategory.SENIOR}-age`]: {
    id: `${travelSearchBarScope}.passengersPanelCategory-${EPassengerCategory.SENIOR}-age`,
    defaultMessage: '{category} (60+ ans)',
    en: '{category} (60+ years)',
    it: '{category} (60+ anni)',
    es: '{category} (60+ años)',
    de: '{category} (60+ Jahre)',
    nl: '{category} (60+ jaar)',
  },

  discountCardsPanelOverlayTitle: {
    id: `${travelSearchBarScope}.discountCardsPanelOverlayTitle`,
    defaultMessage: 'Ajouter une carte de réduction',
    en: 'Add Discount Card',
    it: 'Aggiungi una carta sconto',
    es: 'Agregar una tarjeta de descuento',
    de: 'Rabattkarte hinzufügen',
    nl: 'Voeg een kortingskaart toe',
  },

  discountCardsPanelNoDiscountCard: {
    id: `${travelSearchBarScope}.discountCardsPanelNoDiscountCard`,
    defaultMessage: 'Aucune carte avantage',
    en: 'No advantage card',
    it: 'Nessuna carta vantaggio',
    es: 'Sin tarjeta de ventaja',
    de: 'Keine Vorteilskarte',
    nl: 'Geen voordeelkaart',
  },
  discountCardSuccessfullyAdded: {
    id: `${travelSearchBarScope}.discountCardSuccessfullyAdded`,
    defaultMessage: 'Votre carte de réduction a bien été ajoutée.',
    en: 'Your discount card has been successfully added.',
    it: 'La tua carta sconto è stata aggiunta correttamente.',
    es: 'Tu tarjeta de descuento ha sido añadida correctamente.',
    de: 'Ihre Rabattkarte wurde erfolgreich hinzugefügt.',
    nl: 'Uw kortingskaart is succesvol toegevoegd.',
  },
  discountCardWillBeTakenIntoAccountLater: {
    id: `${travelSearchBarScope}.discountCardWillBeTakenIntoAccountLater`,
    defaultMessage: 'Elle sera prise en compte au moment du paiement.',
    en: 'It will be taken into account at the time of payment.',
    it: 'Sarà preso in considerazione al momento del pagamento.',
    es: 'Se tendrá en cuenta en el momento del pago.',
    de: 'Es wird bei der Zahlung berücksichtigt.',
    nl: 'Het wordt bij de betaling in aanmerking genomen.',
  },
  discountCardSuccessfullyRemoved: {
    id: `${travelSearchBarScope}.discountCardSuccessfullyRemoved`,
    defaultMessage: 'Votre carte de réduction a bien été retirée',
    en: 'Your discount card has been successfully removed',
    it: 'La tua carta sconto è stata rimossa con successo',
    es: 'Tu tarjeta de descuento ha sido eliminada correctamente',
    de: 'Ihre Rabattkarte wurde erfolgreich entfernt',
    nl: 'Uw kortingskaart is succesvol verwijderd',
  },
  selectAtLeastOnePassenger: {
    id: `${travelSearchBarScope}.selectAtLeastOnePassenger`,
    defaultMessage: 'Veuillez sélectionner au moins un passager',
    en: 'Please select at least one passenger',
    it: 'Si prega di selezionare almeno un passeggero',
    es: 'Por favor, seleccione al menos un pasajero',
    de: 'Bitte wählen Sie mindestens einen Fahrgast aus',
    nl: 'Selecteer alstublieft minimaal één passagier',
  },
  passengerLimitHasBeenReached: {
    id: `${travelSearchBarScope}.passengerLimitHasBeenReached`,
    defaultMessage:
      'La limite de passagers (9) est atteinte. Pour dépasser ce nombre, contactez-nous ou créez plusieurs commandes. Sinon partez sans eux (on plaisante bien-sûr).',
    en: 'The passenger limit (9) has been reached. To exceed this number, please contact us or create multiple orders. Otherwise, depart without them (just kidding, of course).',
    it: 'Il limite dei passeggeri (9) è stato raggiunto. Per superare questo numero, contattaci o crea più ordini. Altrimenti, parti senza di loro (scherziamo, ovviamente).',
    es: 'Se ha alcanzado el límite de pasajeros (9). Para superar este número, contáctenos o cree múltiples pedidos. De lo contrario, parta sin ellos (solo estamos bromeando, por supuesto).',
    de: 'Die Passagiergrenze (9) wurde erreicht. Um diese Anzahl zu überschreiten, kontaktieren Sie uns bitte oder erstellen Sie mehrere Bestellungen. Andernfalls fahren Sie ohne sie fort (natürlich nur ein Scherz).',
    nl: 'Het maximale aantal passagiers (9) is bereikt. Neem contact met ons op of maak meerdere bestellingen om dit aantal te overschrijden. Anders vertrek zonder hen (we maken natuurlijk een grapje).',
  },
};

export default defineMessages(travelSearchBarMessages);
