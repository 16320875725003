/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
import { deTranslationMessages } from './translations/de';
import { enTranslationMessages } from './translations/en';
import { esTranslationMessages } from './translations/es';
import { frTranslationMessages } from './translations/fr';
import { itTranslationMessages } from './translations/it';
import { nlTranslationMessages } from './translations/nl';

const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const frLocaleData = require('react-intl/locale-data/fr');
const enLocaleData = require('react-intl/locale-data/en');
const esLocaleData = require('react-intl/locale-data/es');
const itLocaleData = require('react-intl/locale-data/it');
const deLocaleData = require('react-intl/locale-data/de');
const nlLocaleData = require('react-intl/locale-data/nl');

addLocaleData(frLocaleData);
addLocaleData(enLocaleData);
addLocaleData(esLocaleData);
addLocaleData(itLocaleData);
addLocaleData(deLocaleData);
addLocaleData(nlLocaleData);

const DEFAULT_LOCALE = 'fr';

// prettier-ignore
const appLocales = [
  'fr',
  'en',
  'it',
  'es',
  'de',
  'nl'
];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, frTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  fr: formatTranslationMessages('fr', frTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages),
  it: formatTranslationMessages('it', itTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  nl: formatTranslationMessages('nl', nlTranslationMessages),
};

export {
  appLocales,
  DEFAULT_LOCALE,
  formatTranslationMessages,
  translationMessages,
};
