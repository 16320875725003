import { API_URL } from '../../helpers/constants';
import { EFunnelStep } from './types';

export const PARTNER_ID_URL_PARAMETER = 'partnerId';
export const FUNNEL_STEPS_PROGRESS = [
  EFunnelStep.RESULTS,
  EFunnelStep.INFO,
  EFunnelStep.PAYMENT,
];

export const clickIdToReferer = {
  gclid: 'GADS',
  msclkid: 'BING',
};

export const URL_GET_PARTNER = `${API_URL}/partners`;
