/*
 * LegalMention Messages
 *
 * This contains all the text for the LegalMention container.
 */

import { defineMessages } from 'react-intl';

export const termsOfSalesScope = 'app.containers.TermsOfSales';

export const termsOfSalesMessages = {
  metaTitle: {
    id: `${termsOfSalesScope}.metaTitle`,
    defaultMessage: 'Conditions générales de vente',
    en: 'Terms and conditions of sale',
    es: 'Condiciones generales de venta',
    it: 'Condizioni generali di vendita',
    de: 'Allgemeine Verkaufsbedingungen',
    nl: 'Algemene verkoopvoorwaarden',
  },
  metaDescription: {
    id: `${termsOfSalesScope}.metaDescription`,
    defaultMessage:
      'Conditions générales de vente de Tictactrip, la 1ère plateforme de réservation de train, bus et covoiturage.',
    en: 'Tictactrip Terms and Conditions of Sale. The first platform for booking train, bus and carpooling.',
    es: 'Condiciones generales de venta de Tictactrip, la primera plataforma para reservar trenes, autobuses y coches compartidos.',
    it: 'Condizioni generali di vendita di Tictactrip, la prima piattaforma per la prenotazione di treni, autobus e carpooling.',
    de: 'Allgemeine Verkaufsbedingungen von Tictactrip, der ersten Plattform für die Buchung von Zügen, Bussen und Mitfahrgelegenheiten.',
    nl: 'Algemene verkoopvoorwaarden van Tictactrip, het eerste platform voor het boeken van treinen, bussen en carpoolen.',
  },
  header: {
    id: `${termsOfSalesScope}.header`,
    defaultMessage: 'Conditions Générales de Vente',
    en: 'Terms and Conditions of Sale',
    es: 'Condiciones Generales de Venta',
    it: 'Condizioni Generali di Vendita',
    de: 'Allgemeine Verkaufsbedingungen',
    nl: 'Algemene verkoopvoorwaarden',
  },
  termsTitle1: {
    id: `${termsOfSalesScope}.termsTitle1`,
    defaultMessage: '1. Qui sommes-nous ?',
    en: '1. Who are we?',
    es: '1. ¿Quiénes somos?',
    it: '1. Chi siamo?',
    de: '1. Wer sind wir?',
    nl: '1. Wie zijn wij?',
  },
  firstContentText1: {
    id: `${termsOfSalesScope}.firstContentText1`,
    defaultMessage: `Le site www.tictactrip.eu est édité et géré par Tictactrip, société privée sous la forme juridique SAS (Société par actions simplifiées) à caractère commercial, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 822 961 652 (ci-après dénommée « Tictactrip » ou « nous », « notre » ou « nos »).

    Le siège social de l’entreprise se trouve au 22 rue Popincourt 75011 Paris.

    Société immatriculée au régime de TVA intracommunautaire n° FR34822961652.

    Société immatriculée à Atout France n° IM075180044.

    Le responsable éditorial est Simon Robain, le président.

    Le site est hébergé par l’entreprise Amazon Web Service (AWS), à Burlington Plaza, Burlington Rd, Dublin 4, Ireland.
    Le site web de l’hébergeur étant : https://aws.amazon.com/fr/websites/

    Tictactrip propose sur son site www.tictactrip.eu (dénommé « site ») de comparer les offres bus, train et covoiturage pour un trajet déterminé. Il est ensuite possible de réserver les billets de train, bus ou covoiturage directement sur le site de Tictactrip (ou « service de réservation »). Tictactrip fait office d'intermédiaire pour le compte des opérateurs partenaires. Tictactrip ne s'occupe pas de la partie transport mais se charge uniquement de fournir un service de réservation, conformément aux conditions décrites dans les conditions générales.

    Toute personne, physique ou morale majeure aux yeux de la loi peut se permettre d'utiliser le service de réservation (personne dénommée, « utilisateur », « client », « vous », « votre » ou « vos »).

    L’utilisation de ce service est régie par les conditions générales de vente décrites sur cette page et à la politique de confidentialité (ou « conditions générales »). Lors de votre accès au site pour comparer et réserver votre trajet, vous reconnaissez et approuvez nos conditions générales. Dans le cas contraire, n'utilisez pas ce service. Vous acceptez également que les informations relatives à la conclusion ou la mise en œuvre du contrat avec Tictactrip vous soient expédiées par courrier électronique.`,

    en: `The website www.tictactrip.eu is published and managed by Tictactrip, a private company under the legal form of SAS (Simplified Joint Stock Company) for commercial purposes, registered with the Paris Trade and Companies Registry under number 822 961 652 (hereinafter referred to as "Tictactrip" or "we," "our," or "us").

  You also agree that information related to the conclusion or implementation of the contract with Tictactrip will be sent to you by email.`,
    es: `El sitio www.tictactrip.eu es editado y gestionado por Tictactrip, una sociedad privada bajo la forma jurídica de SAS (Sociedad por Acciones Simplificada) con fines comerciales, inscrita en el registro mercantil de París con el número 822 961 652 (en adelante denominada "Tictactrip" o "nosotros", "nuestro" o "nuestros").

  También acepta que la información relacionada con la conclusión o implementación del contrato con Tictactrip le sea enviada por correo electrónico.`,
    it: `Il sito www.tictactrip.eu è edito e gestito da Tictactrip, una società privata sotto la forma giuridica di SAS (Società per Azioni Semplificata) a carattere commerciale, iscritta presso il registro delle imprese di Parigi con il numero 822 961 652 (di seguito denominata "Tictactrip" o "noi", "nostro" o "nostri").

  Accetti anche che le informazioni relative alla conclusione o all'attuazione del contratto con Tictactrip ti vengano inviate per posta elettronica.`,
    de: `Die Website www.tictactrip.eu wird von Tictactrip herausgegeben und verwaltet, einem privaten Unternehmen in der Rechtsform einer SAS (Vereinfachte Aktiengesellschaft) für kommerzielle Zwecke, eingetragen im Handels- und Gesellschaftsregister von Paris unter der Nummer 822 961 652 (im Folgenden als "Tictactrip" oder "wir", "unser" oder "uns" bezeichnet).

  Sie stimmen auch zu, dass Ihnen Informationen zum Vertragsabschluss oder zur Umsetzung des Vertrags mit Tictactrip per E-Mail zugesandt werden.`,
    nl: `De website www.tictactrip.eu wordt uitgegeven en beheerd door Tictactrip, een privébedrijf onder de rechtsvorm van een SAS (Vereenvoudigde naamloze vennootschap) voor commerciële doeleinden, ingeschreven bij de Kamer van Koophandel van Parijs onder nummer 822 961 652 (hierna aangeduid als "Tictactrip" of "wij", "onze" of "ons").

  U stemt er ook mee in dat informatie met betrekking tot de totstandkoming of uitvoering van de overeenkomst met Tictactrip per e-mail aan u wordt verzonden.`,
  },
  termsTitle2: {
    id: `${termsOfSalesScope}.termsTitle2`,
    defaultMessage: '2. Les opérateurs',
    en: '2. The Operators',
    es: '2. Los Operadores',
    it: '2. Gli Operatori',
    de: '2. Die Betreiber',
    nl: '2. De exploitanten',
  },
  termsTitle2sub1: {
    id: `${termsOfSalesScope}.termsTitle2sub1`,
    defaultMessage: '2.1. Réservation avec Tictactrip',
    en: '2.1. Reservation with Tictactrip',
    es: '2.1. Reserva con Tictactrip',
    it: '2.1. Prenotazione con Tictactrip',
    de: '2.1. Buchung mit Tictactrip',
    nl: '2.1. Reservering bij Tictactrip',
  },
  secondContentText1: {
    id: `${termsOfSalesScope}.secondContentText1`,
    defaultMessage: `Les trajets comparés et réservés sur le site de Tictactrip sont proposés par plusieurs compagnies de train, bus et covoiturage, (dénommés « opérateurs »). Tictactrip propose à la vente leurs billets de train, bus et covoiturage par le biais de notre comparateur et service de réservation de billets (appelés également « titres de transport »).

    Tictactrip ne propose à la réservation que des titres de transport vendus par les opérateurs partenaires uniquement. Ainsi, les titres de transports vendus par d’autres compagnies ne seront pas affichés au moment de votre recherche.
  Tictactrip ne propose à la réservation que des titres de transport vendus par les opérateurs partenaires uniquement. Ainsi, les titres de transports vendus par d’autres compagnies ne seront pas affichés au moment de votre recherche.

  Les billets ou titres de transport obtenus grâce au comparateur et au service de réservation sont soumis à des conditions fixées par l’opérateur choisi lors de votre commande. Ainsi, vous pourrez prendre connaissance de ces conditions de vente lors de la commande, avant de procéder à votre paiement.`,
    en: `The journeys compared and booked on the Tictactrip website are offered by several train, bus, and carpooling companies (referred to as "operators"). Tictactrip sells their train, bus, and carpooling tickets through our ticket comparison and reservation service (also called "transport tickets").

  Tictactrip only offers reservations for transport tickets sold by partner operators. Therefore, transport tickets sold by other companies will not be displayed when you search.

  The tickets or transport tickets obtained through the comparison and reservation service are subject to conditions set by the chosen operator during your order. Thus, you can familiarize yourself with these sales conditions when placing the order, before proceeding with payment.`,
    es: `Los viajes comparados y reservados en el sitio web de Tictactrip son ofrecidos por varias compañías de trenes, autobuses y coche compartido (denominadas "operadores"). Tictactrip vende sus boletos de tren, autobús y coche compartido a través de nuestro servicio de comparación y reserva de boletos (también llamados "títulos de transporte").

  Tictactrip solo ofrece reservas para títulos de transporte vendidos por operadores asociados. Por lo tanto, los títulos de transporte vendidos por otras compañías no se mostrarán al realizar la búsqueda.

  Los boletos o títulos de transporte obtenidos a través del servicio de comparación y reserva están sujetos a condiciones establecidas por el operador elegido durante su pedido. Así, podrá conocer estas condiciones de venta al realizar el pedido, antes de proceder al pago.`,
    it: `I viaggi comparati e prenotati sul sito di Tictactrip sono offerti da diverse compagnie ferroviarie, autobus e carpooling (denominate "operatori"). Tictactrip vende i loro biglietti per treni, autobus e carpooling attraverso il nostro servizio di confronto e prenotazione biglietti (chiamato anche "titoli di trasporto").

  Tictactrip offre solo prenotazioni per titoli di trasporto venduti esclusivamente da operatori partner. Pertanto, i titoli di trasporto venduti da altre compagnie non verranno visualizzati durante la ricerca.

  I biglietti o i titoli di trasporto ottenuti tramite il servizio di confronto e prenotazione sono soggetti a condizioni stabilite dall'operatore scelto durante il tuo ordine. Pertanto, potrai prendere visione di queste condizioni di vendita al momento dell'ordine, prima di procedere al pagamento.`,
    de: `Die auf der Website von Tictactrip verglichenen und gebuchten Fahrten werden von mehreren Zug-, Bus- und Mitfahrgelegenheitsunternehmen angeboten (als "Betreiber" bezeichnet). Tictactrip verkauft ihre Zug-, Bus- und Mitfahrgelegenheitskarten über unseren Ticketvergleichs- und Reservierungsservice (auch "Transporttickets" genannt).

  Tictactrip bietet nur Reservierungen für Transporttickets an, die von Partnerbetreibern verkauft werden. Daher werden Transporttickets, die von anderen Unternehmen verkauft werden, bei Ihrer Suche nicht angezeigt.

  Die über den Vergleichs- und Reservierungsdienst erhaltenen Tickets oder Transporttickets unterliegen den Bedingungen, die vom gewählten Betreiber während Ihrer Bestellung festgelegt wurden. Sie können sich daher bei der Bestellung, vor der Zahlung, über diese Verkaufsbedingungen informieren.`,
    nl: `De reizen die op de Tictactrip-website worden vergeleken en geboekt, worden aangeboden door verschillende trein-, bus- en carpoolbedrijven (ook "operators" genoemd). Tictactrip biedt hun trein-, bus- en carpoolingtickets te koop aan via onze dienst voor het vergelijken en boeken van tickets (ook "tickets" genoemd).

  Tictactrip biedt alleen tickets aan die door partneroperators worden verkocht. Daarom worden tickets die door andere bedrijven worden verkocht, niet weergegeven op het moment van uw zoekopdracht.

    Les billets ou titres de transport obtenus grâce au comparateur et au service de réservation sont soumis à des conditions fixées par l’opérateur choisi lors de votre commande. Ainsi, vous pourrez prendre connaissance de ces conditions de vente lors de la commande, avant de procéder à votre paiement.`,
  },
  termsTitle2sub2: {
    id: `${termsOfSalesScope}.termsTitle2sub2`,
    defaultMessage: '2.2. Réservation via des compagnies non partenaires',
    en: '2.2. Reservation via Non-Partner Companies',
    es: '2.2. Reserva a través de Compañías No Asociadas',
    it: '2.2. Prenotazione tramite Compagnie non Partner',
    de: '2.2. Buchung über Nicht-Partnerunternehmen',
    nl: '2.2. Reservering via Niet-geaffilieerde Bedrijven',
  },
  secondContentText2: {
    id: `${termsOfSalesScope}.secondContentText2`,
    defaultMessage: `Lors de votre recherche de voyage à l’aide du comparateur Tictactrip, nous pouvons afficher un lien vous redirigeant vers les sites des compagnies non partenaires avec Tictactrip, sites qui vous permettront d’acheter le billet correspondant à votre voyage et d’y effectuer les réservations souhaitées.

    L’ensemble des contrats concernant les titres de transport achetés sur les sites des compagnies partenaires sont traités entre vous et le/les compagnies partenaires et seuls les conditions générales de ces compagnies feront foi. Ainsi, pour toute réclamation, adressez vous directement à ces prestataires tiers.`,
    en: `When searching for a journey using the Tictactrip comparator, we may display a link redirecting you to the websites of non-partner companies with Tictactrip, sites that will allow you to purchase the ticket corresponding to your journey and make the desired reservations.

  All contracts concerning transport tickets purchased on the websites of partner companies are handled between you and the partner companies, and only the general conditions of these companies will prevail. Therefore, for any claim, please contact these third-party providers directly.`,
    es: `Al buscar un viaje utilizando el comparador Tictactrip, podemos mostrar un enlace que lo redirige a los sitios web de compañías no asociadas con Tictactrip, sitios que le permitirán comprar el boleto correspondiente a su viaje y realizar las reservas deseadas.

  Todos los contratos relacionados con los títulos de transporte comprados en los sitios web de las compañías asociadas se gestionan entre usted y las compañías asociadas, y solo las condiciones generales de estas compañías prevalecerán. Por lo tanto, para cualquier reclamo, comuníquese directamente con estos proveedores de terceros.`,
    it: `Durante la ricerca di un viaggio tramite il comparatore Tictactrip, potremmo mostrare un collegamento che ti reindirizza ai siti delle compagnie non partner con Tictactrip, siti che ti consentiranno di acquistare il biglietto corrispondente al tuo viaggio e di effettuare le prenotazioni desiderate.

  Tutti i contratti relativi ai titoli di trasporto acquistati sui siti delle compagnie partner sono gestiti tra te e le compagnie partner, e solo le condizioni generali di queste compagnie prevarranno. Pertanto, per qualsiasi reclamo, contatta direttamente questi fornitori terzi.`,
    de: `Bei der Suche nach einer Reise mit dem Tictactrip-Vergleich können wir einen Link anzeigen, der Sie zu den Websites von Nicht-Partnerunternehmen mit Tictactrip weiterleitet, Websites, die es Ihnen ermöglichen, das Ticket für Ihre Reise zu kaufen und die gewünschten Reservierungen vorzunehmen.

  Alle Verträge über Transporttickets, die auf den Websites von Partnerunternehmen gekauft wurden, werden zwischen Ihnen und den Partnerunternehmen abgewickelt, und nur die allgemeinen Bedingungen dieser Unternehmen haben Vorrang. Daher wenden Sie sich für jegliche Ansprüche bitte direkt an diese Drittanbieter.`,
    nl: `Bij het zoeken naar een reis met behulp van de Tictactrip-vergelijker kunnen we een link weergeven die u doorverwijst naar de websites van niet-geaffilieerde bedrijven bij Tictactrip, sites waarmee u het ticket voor uw reis kunt kopen en de gewenste reserveringen kunt maken.

  Alle contracten met betrekking tot transporttickets die zijn gekocht op de websites van partnerbedrijven worden afgehandeld tussen u en de partnerbedrijven, en alleen de algemene voorwaarden van deze bedrijven zullen van kracht zijn. Daarom kunt u voor eventuele claims rechtstreeks contact opnemen met deze externe dienstverleners.`,
  },
  termsTitle3: {
    id: `${termsOfSalesScope}.termsTitle3`,
    defaultMessage: '3. Le service de réservation et son fonctionnement',
    en: '3. The Booking Service and Its Operation',
    es: '3. El Servicio de Reservas y su Funcionamiento',
    it: '3. Il Servizio di Prenotazione e il suo Funzionamento',
    de: '3. Der Buchungsservice und seine Funktionsweise',
    nl: '3. De Reserveringsservice en hoe het werkt',
  },
  thirdContentText1: {
    id: `${termsOfSalesScope}.thirdContentText1`,
    defaultMessage:
      'Le service a pour objectif de vous procurer les informations souhaitées concernant les titres de transport vendus par les compagnies, et vous donne également l’occasion d’effectuer des réservations pour acheter ces titres de transport.',
    en: 'The service aims to provide you with the desired information regarding the transport tickets sold by the companies and also gives you the opportunity to make reservations to purchase these transport tickets.',
    es: 'El servicio tiene como objetivo proporcionarle la información deseada sobre los títulos de transporte vendidos por las compañías y también le brinda la oportunidad de realizar reservas para comprar estos títulos de transporte.',
    it: "Il servizio ha lo scopo di fornirti le informazioni desiderate sui titoli di trasporto venduti dalle compagnie e ti offre anche l'opportunità di effettuare prenotazioni per acquistare questi titoli di trasporto.",
    de: 'Der Service soll Ihnen die gewünschten Informationen zu den von den Unternehmen verkauften Transporttickets liefern und Ihnen auch die Möglichkeit geben, Reservierungen zur Kauf dieser Transporttickets vorzunehmen.',
    nl: 'Het doel van de service is om u de gewenste informatie te verstrekken over de door de bedrijven verkochte transporttickets en geeft u ook de mogelijkheid om reserveringen te maken voor de aankoop van deze transporttickets.',
  },
  termsTitle3sub1: {
    id: `${termsOfSalesScope}.termsTitle3sub1`,
    defaultMessage: '3.1. Le client',
    en: '3.1. The Customer',
    es: '3.1. El Cliente',
    it: '3.1. Il Cliente',
    de: '3.1. Der Kunde',
    nl: '3.1. De Klant',
  },

  thirdContentText2: {
    id: `${termsOfSalesScope}.thirdContentText2`,
    defaultMessage: `Vous avez la possibilité (à votre guise) de vous enregistrer sur le site à l’aide d’un compte personnel (ou « le Compte ») afin d’utiliser le service permettant de réserver vos titres de transports mais il est également possible d’utiliser le service sans être enregistré.
    Tictactrip se réserve le droit de s’opposer à la création d’un compte, mais également restreindre l’accès au service de réservation pour toute raison valable.

    Lorsque vous enregistrez vos informations personnelles au moment de créer votre compte, il est primordial de s’assurer que les données obligatoires demandées soient exactes et complètes. Il vous incombe également de mettre à jour régulièrement ces données.

    Lors de la création de votre compte, vous devez définir un mot de passe, et serez alors seul responsable de votre mot de passe et de son utilisation. De plus, vous prenez l’engagement de ne pas le divulguer et de le garder secret. En conséquence, si votre mot de passe est perdu ou volé, en cas de conséquence dommageable, vous serez tenu pour seul responsable de la perte ou du vol, et vous engagez à contacter Tictactrip le plus rapidement possible.

    L’utilisation de scripts, robots (ou toute utilisation automatisée) du site Tictactrip est formellement interdite et pourra mener à une suspension de votre compte.
    Vous devez également vous assurer de l’exactitude de l’adresse email fournie par vos soins et que les emails envoyés par Tictactrip soient autorisés par votre opérateur internet et veiller à ce qu’ils ne soient pas automatiquement redirigés dans le dossier spam.`,
    en: `You have the option (at your discretion) to register on the site using a personal account (or "the Account") to use the service for booking your transport tickets, but it is also possible to use the service without being registered.
    Tictactrip reserves the right to oppose the creation of an account, but also to restrict access to the reservation service for any valid reason.

    When you register your personal information when creating your account, it is essential to ensure that the mandatory data requested is accurate and complete. It is also your responsibility to regularly update this information.

    When creating your account, you must set a password and will be solely responsible for your password and its use. Moreover, you commit not to disclose it and to keep it secret. Consequently, if your password is lost or stolen, in the event of harmful consequences, you will be held solely responsible for the loss or theft and commit to contacting Tictactrip as soon as possible.

    The use of scripts, robots (or any automated use) on the Tictactrip site is strictly prohibited and may lead to the suspension of your account.
    You must also ensure the accuracy of the email address provided by you and that emails sent by Tictactrip are authorized by your internet service provider and ensure that they are not automatically redirected to the spam folder.`,
    es: `Usted tiene la opción (a su discreción) de registrarse en el sitio mediante una cuenta personal (o "la Cuenta") para utilizar el servicio de reserva de sus títulos de transporte, pero también es posible utilizar el servicio sin estar registrado.
    Tictactrip se reserva el derecho de oponerse a la creación de una cuenta, pero también de restringir el acceso al servicio de reserva por cualquier razón válida.

    Al registrar su información personal al crear su cuenta, es esencial asegurarse de que los datos obligatorios solicitados sean precisos y completos. También es su responsabilidad actualizar regularmente esta información.

    Al crear su cuenta, debe establecer una contraseña y será el único responsable de su contraseña y su uso. Además, se compromete a no divulgarla y a mantenerla en secreto. En consecuencia, si su contraseña se pierde o se roba, en caso de consecuencias perjudiciales, usted será considerado el único responsable de la pérdida o el robo y se compromete a ponerse en contacto con Tictactrip lo antes posible.

    El uso de scripts, robots (o cualquier uso automatizado) en el sitio web de Tictactrip está estrictamente prohibido y puede dar lugar a la suspensión de su cuenta.
    También debe asegurarse de que la dirección de correo electrónico proporcionada por usted sea precisa y de que los correos electrónicos enviados por Tictactrip estén autorizados por su proveedor de servicios de Internet y asegurarse de que no se redirijan automáticamente a la carpeta de spam.`,

    it: `Avete la possibilità (a vostra discrezione) di registrarti sul sito utilizzando un account personale (o "l'Account") per utilizzare il servizio di prenotazione dei vostri titoli di trasporto, ma è anche possibile utilizzare il servizio senza essere registrati.
    Tictactrip si riserva il diritto di opporsi alla creazione di un account, ma anche di limitare l'accesso al servizio di prenotazione per qualsiasi motivo valido.

    Quando registrate le vostre informazioni personali al momento di creare il vostro account, è essenziale assicurarsi che i dati obbligatori richiesti siano precisi e completi. È anche vostra responsabilità aggiornare regolarmente queste informazioni.

    Al momento della creazione del vostro account, dovete definire una password e sarete voi i soli responsabili della vostra password e del suo utilizzo. Inoltre, vi impegnate a non divulgarla e a mantenerla segreta. Di conseguenza, se la vostra password viene persa o rubata, in caso di conseguenze dannose, sarete ritenuti i soli responsabili della perdita o del furto e vi impegnate a contattare Tictactrip il prima possibile.

    L'uso di script, robot (o qualsiasi uso automatizzato) sul sito Tictactrip è strettamente proibito e può portare alla sospensione del vostro account.
    Dovete anche assicurarvi che l'indirizzo email fornito da voi sia preciso e che gli email inviati da Tictactrip siano autorizzati dal vostro fornitore di servizi internet e accertarvi che non siano indirizzati automaticamente nella cartella dello spam.`,
    de: `Sie haben die Möglichkeit (nach Belieben), sich auf der Website mit einem persönlichen Konto (oder "dem Konto") zu registrieren, um den Service zur Buchung Ihrer Transporttickets zu nutzen, es ist jedoch auch möglich, den Service ohne Registrierung zu nutzen.
    Tictactrip behält sich das Recht vor, der Kontoerstellung zu widersprechen, aber auch den Zugang zum Buchungsservice aus jedem gültigen Grund einzuschränken.

    Wenn Sie Ihre persönlichen Informationen bei der Erstellung Ihres Kontos registrieren, ist es wichtig sicherzustellen, dass die obligatorischen Daten genau und vollständig sind. Es liegt auch in Ihrer Verantwortung, diese Informationen regelmäßig zu aktualisieren.

    Bei der Erstellung Ihres Kontos müssen Sie ein Passwort festlegen und sind dann allein verantwortlich für Ihr Passwort und seine Verwendung. Darüber hinaus verpflichten Sie sich, es nicht weiterzugeben und geheim zu halten. Folglich werden Sie im Falle eines Verlusts oder Diebstahls Ihres Passworts im Falle von schädlichen Folgen als allein verantwortlich für den Verlust oder Diebstahl betrachtet und verpflichten sich, Tictactrip so schnell wie möglich zu kontaktieren.

    Die Verwendung von Skripten, Robotern (oder jeglicher automatisierten Verwendung) auf der Tictactrip-Website ist ausdrücklich untersagt und kann zur Sperrung Ihres Kontos führen.
    Sie müssen auch sicherstellen, dass die von Ihnen bereitgestellte E-Mail-Adresse genau ist und dass von Tictactrip gesendete E-Mails von Ihrem Internetdienstanbieter autorisiert sind und sicherstellen, dass sie nicht automatisch in den Spam-Ordner umgeleitet werden.`,

    nl: `U hebt de mogelijkheid (naar uw goeddunken) om u op de site te registreren met een persoonlijk account (of "het Account") om de service te gebruiken voor het boeken van uw vervoerstickets, maar het is ook mogelijk om de service te gebruiken zonder geregistreerd te zijn.
    Tictactrip behoudt zich het recht voor om bezwaar te maken tegen de aanmaak van een account, maar ook om de toegang tot de reserveringsservice om geldige redenen te beperken.

    Wanneer u uw persoonlijke informatie registreert bij het aanmaken van uw account, is het essentieel om ervoor te zorgen dat de vereiste verplichte gegevens nauwkeurig en volledig zijn. Het is ook uw verantwoordelijkheid om deze informatie regelmatig bij te werken.

    Bij het aanmaken van uw account moet u een wachtwoord instellen en bent u als enige verantwoordelijk voor uw wachtwoord en het gebruik ervan. Bovendien verbindt u zich ertoe het niet bekend te maken en geheim te houden. Als gevolg daarvan, als uw wachtwoord verloren gaat of wordt gestolen, in geval van schadelijke gevolgen, wordt u beschouwd als de enige verantwoordelijke voor het verlies of de diefstal en verbindt u zich ertoe Tictactrip zo snel mogelijk te contacteren.

    Het gebruik van scripts, robots (of elke geautomatiseerde gebruik) op de Tictactrip-website is uitdrukkelijk verboden en kan leiden tot de opschorting van uw account.
    U moet er ook voor zorgen dat het door u verstrekte e-mailadres correct is en dat e-mails die door Tictactrip zijn verzonden zijn goedgekeurd door uw internetprovider en ervoor zorgen dat ze niet automatisch worden doorgestuurd naar de spammap.`,
  },
  termsTitle3sub2: {
    id: `${termsOfSalesScope}.termsTitle3sub2`,
    defaultMessage: '3.2. Les passagers',
    en: '3.2. Passengers',
    es: '3.2. Pasajeros',
    it: '3.2. Passeggeri',
    de: '3.2. Passagiere',
    nl: '3.2. Passagiers',
  },

  thirdContentText3: {
    id: `${termsOfSalesScope}.thirdContentText3`,
    defaultMessage: `Vous pouvez également vous procurer des billets de transport pour plusieurs personnes (ou « passagers », individuellement « passager »). Ainsi, vous avez la possibilité d’ajouter directement sur votre compte le nombre de passagers souhaité. À partir du moment où vous ajoutez un passager, vous devez vous assurer que le passager accepte que nous puissions utiliser ses données.
    Vous êtes également entièrement responsable des informations personnelles que vous nous fournissez.
    Ces données sont indispensables afin que l’opérateur puisse éditer les Titres de transport. Tictactrip ne pourra être tenu pour responsable en cas d’édition d’un billet qui comporte une ou plusieurs informations erronées que vous aurez fournies.

    Tictactrip vous rappelle que l’ensemble des cartes de fidélité et/ou de réduction sont strictement personnelles. Par conséquent, elles doivent être utilisées pour les passagers correspondants aux informations fournies. Vous retrouverez l’ensemble de ces règles dans la section des conditions générales de vente liées aux Opérateurs.`,
    en: `You can also purchase tickets for several people (or "passengers", individually "passenger"). In this way, you can add the desired number of passengers directly to your account. From the moment you add a passenger, you must ensure that the passenger agrees to our use of his or her data.
    You are also entirely responsible for the personal information you provide.
    This information is essential for the operator to be able to issue tickets. Tictactrip cannot be held responsible if a ticket is issued containing one or more items of incorrect information provided by you.

    Tictactrip reminds you that all loyalty and/or discount cards are strictly personal. Consequently, they must be used for passengers corresponding to the information provided. You will find all these rules in the section of the general conditions of sale relating to Operators.`,
    es: `También puede comprar billetes para varias personas (o "pasajeros", individualmente "pasajero"). De este modo, puede añadir directamente a su cuenta el número de pasajeros que desee. Desde el momento en que añade un pasajero, debe asegurarse de que éste acepta que utilicemos sus datos.
    Además, usted es el único responsable de la información personal que facilite.
    Estos datos son esenciales para que el operador pueda emitir billetes. Tictactrip no se hace responsable si se emite un billete que contenga uno o más datos incorrectos proporcionados por usted.

    Tictactrip le recuerda que todas las tarjetas de fidelidad y/o de descuento son estrictamente personales. Por consiguiente, deben utilizarse para los pasajeros correspondientes a la información facilitada. Encontrará todas estas normas en la sección de las condiciones generales de venta relativa a los Operadores.`,
    it: `È anche possibile acquistare biglietti per più persone (o "passeggeri", singolarmente "passeggero"). In questo modo, è possibile aggiungere il numero desiderato di passeggeri direttamente al proprio account. Dal momento in cui aggiungete un passeggero, dovete assicurarvi che il passeggero accetti che i suoi dati vengano utilizzati.
    L'utente è inoltre interamente responsabile delle informazioni personali fornite.
    Questi dati sono essenziali per consentire all'operatore di emettere i Biglietti. Tictactrip non può essere ritenuta responsabile se viene emesso un Biglietto contenente una o più informazioni errate fornite dall'utente.

    Tictactrip ricorda che tutte le carte fedeltà e/o di sconto sono strettamente personali. Di conseguenza, devono essere utilizzate per i passeggeri corrispondenti alle informazioni fornite. Tutte queste regole sono riportate nella sezione delle condizioni generali di vendita relativa agli Operatori.`,
    de: `Sie können auch Fahrkarten für mehrere Personen (oder "Passagiere", einzeln "Fahrgast") erwerben. So haben Sie die Möglichkeit, die gewünschte Anzahl an Passagieren direkt auf Ihrem Konto hinzuzufügen. Ab dem Zeitpunkt, an dem Sie einen Fahrgast hinzufügen, müssen Sie sicherstellen, dass der Fahrgast damit einverstanden ist, dass wir seine Daten verwenden dürfen.
    Sie sind auch voll verantwortlich für die persönlichen Daten, die Sie uns zur Verfügung stellen.
    Diese Daten sind unerlässlich, damit der Betreiber die Fahrscheine ausstellen kann. Tictactrip kann nicht haftbar gemacht werden, wenn ein Fahrschein ausgestellt wird, der eine oder mehrere falsche Informationen enthält, die Sie angegeben haben.

    Tictactrip erinnert Sie daran, dass alle Treue- und/oder Rabattkarten streng persönlich sind. Folglich müssen sie für die Passagiere verwendet werden, die den angegebenen Informationen entsprechen. Alle diese Regeln finden Sie im Abschnitt der Allgemeinen Geschäftsbedingungen in Bezug auf Reiseveranstalter.`,
    nl: `Je kunt ook tickets kopen voor meerdere personen (of "passagiers", individueel "passagier"). Op deze manier kun je het gewenste aantal passagiers direct aan je account toevoegen. Vanaf het moment dat je een passagier toevoegt, moet je ervoor zorgen dat de passagier accepteert dat we zijn of haar gegevens mogen gebruiken.
    Je bent ook volledig verantwoordelijk voor de persoonlijke gegevens die je verstrekt.
    Deze gegevens zijn essentieel voor de touroperator om Tickets te kunnen uitgeven. Tictactrip kan niet aansprakelijk worden gesteld indien een ticket wordt uitgegeven dat een of meer door u verstrekte onjuiste gegevens bevat.

    Tictactrip herinnert u eraan dat alle klantenkaarten en/of kortingskaarten strikt persoonlijk zijn. Bijgevolg moeten ze worden gebruikt voor passagiers die overeenkomen met de verstrekte informatie. U vindt al deze regels in het gedeelte van de algemene verkoopvoorwaarden dat betrekking heeft op de Operators.`,
  },
  termsTitle3sub3: {
    id: `${termsOfSalesScope}.termsTitle3sub3`,
    defaultMessage: '3.3. Titres de transport et sélection',
    en: '3.3. Transport Tickets and Selection',
    es: '3.3. Billetes de transporte y selección',
    it: '3.3. Biglietti di trasporto e selezione',
    de: '3.3. Fahrkarten und Auswahl',
    nl: '3.3. Vervoerstickets en selectie',
  },
  thirdContentText4: {
    id: `${termsOfSalesScope}.thirdContentText4`,
    defaultMessage: `Tictactrip s’efforce de soigner la présentation, mais également la description lors des résultats de vos recherches présentées, dans un souci constant d’information. Malheureusement, malgré nos efforts et compte tenu du fait que les prix indiqués au moment d’une recherche sont fournis par les compagnies, il nous est impossible de garantir l’exactitude de toutes les informations contenus dans notre service de comparaison et réservation.
    Cela vaut également pour les horaires, les tarifs et les informations fournies en temps réel par les opérateurs.
    Étant donné que chaque opérateur possède un processus de tarification différent, il est possible que les prix puissent évoluer à tout instant, tout particulièrement entre le moment où les prix vous sont affichés et leur sélection avant le paiement.

    Tictactrip propose à la vente les billets proposés et autorisés à la vente par les Opérateurs. Ainsi, il est possible que d’autres produits puissent être présentés, cependant, ils seront affichés comme « indisponibles ».`,
    en: `Tictactrip makes every effort to present and describe the results of your search in the most informative way possible. Unfortunately, in spite of our efforts and given that the prices indicated at the time of a search are provided by the companies, it is impossible for us to guarantee the accuracy of all the information contained in our comparison and booking service.
    This also applies to timetables, fares and real-time information provided by operators.
    As each operator has a different pricing process, it is possible that prices may change at any time, especially between the time the prices are displayed to you and their selection prior to payment.

    Tictactrip offers for sale the tickets proposed and authorized for sale by the Operators. It is therefore possible that other products may be presented, however, they will be displayed as "unavailable".`,
    es: `Tictactrip hace todo lo posible para que no sólo la presentación, sino también la descripción de los resultados de sus búsquedas sea lo más informativa posible. Lamentablemente, a pesar de nuestros esfuerzos y dado que los precios indicados en el momento de una búsqueda son facilitados por las compañías, nos resulta imposible garantizar la exactitud de toda la información contenida en nuestro servicio de comparación y reserva.
    Esto también se aplica a los horarios, tarifas e información en tiempo real proporcionada por los operadores.
    Como cada operador tiene un proceso de fijación de precios diferente, es posible que los precios cambien en cualquier momento, especialmente entre el momento en que se le muestran los precios y su selección antes del pago.

    Tictactrip pone a la venta los billetes propuestos y autorizados para la venta por los Operadores. Por lo tanto, es posible que se presenten otros productos, sin embargo, se mostrarán como "no disponibles".`,
    it: `Tictactrip si impegna al massimo per garantire che non solo la presentazione, ma anche la descrizione dei risultati delle ricerche siano il più possibile informative. Purtroppo, nonostante i nostri sforzi e dato che i prezzi indicati al momento della ricerca sono forniti dalle compagnie, è impossibile per noi garantire l'accuratezza di tutte le informazioni contenute nel nostro servizio di confronto e prenotazione.
    Ciò vale anche per gli orari, le tariffe e le informazioni in tempo reale fornite dagli operatori.
    Poiché ogni operatore ha un processo di determinazione dei prezzi diverso, è possibile che i prezzi cambino in qualsiasi momento, in particolare tra il momento in cui i prezzi vengono visualizzati all'utente e la loro selezione prima del pagamento.

    Tictactrip offre in vendita i biglietti proposti e autorizzati dagli Operatori. È pertanto possibile che vengano presentati altri prodotti, che tuttavia saranno visualizzati come "non disponibili".`,
    de: `Tictactrip bemüht sich um eine sorgfältige Präsentation und Beschreibung Ihrer Suchergebnisse, um Ihnen die bestmögliche Information zu bieten. Leider können wir trotz unserer Bemühungen und aufgrund der Tatsache, dass die Preise, die zum Zeitpunkt der Suche angezeigt werden, von den Fluggesellschaften angegeben werden, nicht für die Richtigkeit aller Informationen in unserem Vergleichs- und Buchungsservice garantieren.
    Dies gilt auch für Fahrpläne, Preise und Echtzeitinformationen, die von den Betreibern bereitgestellt werden.
    Da jeder Anbieter ein anderes Preisgestaltungsverfahren anwendet, können sich die Preise jederzeit ändern, insbesondere zwischen dem Zeitpunkt, an dem Ihnen die Preise angezeigt werden, und der Auswahl der Preise vor der Bezahlung.

    Tictactrip bietet Tickets zum Verkauf an, die von den Betreibern angeboten und zum Verkauf freigegeben wurden. Es ist daher möglich, dass andere Produkte angeboten werden, diese werden jedoch als "nicht verfügbar" angezeigt.`,
    nl: `Tictactrip stelt alles in het werk om ervoor te zorgen dat niet alleen de presentatie, maar ook de beschrijving van de resultaten van uw zoekopdrachten zo informatief mogelijk is. Ondanks onze inspanningen en gezien het feit dat de prijzen die op het moment van de zoekopdracht worden aangegeven, door de maatschappijen worden verstrekt, is het voor ons helaas onmogelijk om de juistheid van alle informatie in onze vergelijkings- en boekingsservice te garanderen.
    Dit geldt ook voor dienstregelingen, tarieven en realtime-informatie die door de maatschappijen wordt verstrekt.
    Aangezien elke touroperator een ander prijsbeleid voert, is het mogelijk dat prijzen op elk moment veranderen, met name tussen het moment dat de prijzen aan u worden getoond en de selectie ervan voorafgaand aan de betaling.

    Tictactrip biedt de tickets te koop aan die door de exploitanten worden voorgesteld en geautoriseerd. Het is dus mogelijk dat er andere producten worden aangeboden, die echter als "niet beschikbaar" worden weergegeven.`,
  },
  termsTitle3sub4: {
    id: `${termsOfSalesScope}.termsTitle3sub4`,
    defaultMessage: '3.4. Modalités de paiement',
    en: '3.4. Payment Terms',
    es: '3.4. Condiciones de pago',
    it: '3.4. Termini di pagamento',
    de: '3.4. Zahlungsbedingungen',
    nl: '3.4. Betalingsvoorwaarden',
  },
  thirdContentText5: {
    id: `${termsOfSalesScope}.thirdContentText5`,
    defaultMessage: `Titulaire ou non d’un compte Tictactrip, il vous sera demandé à l’issue de votre commande de payer la totalité de la somme correspondant au prix des Titres de transport ajoutés dans votre Panier. Néanmoins, vous avez la possibilité de retirer mais un Titre de transport du panier d’achat librement.

    Les moyens de paiement autorisés sur notre site sont les suivants :

    Carte Bancaire (Carte Bleue, Visa, Mastercard ou American Express) ;
    PayPal ;

    L’ensemble des paiements effectués sur le site de Tictactrip seront conclus en Euro TTC uniquement.

    Avant de pouvoir procéder au paiement, vous êtes dans l’obligation d’approuver les conditions générales de vente. Il en est de même pour les conditions générales relatives aux Opérateurs qui commercialisent les Titres de Transport que vous souhaitez acheter.

    Le tunnel de paiement sera entièrement sécurisé à l’aide des protocoles suivants : TLS 1.0 / 1.1 / 1.2 (SSL). Cette technologie variera en fonction du navigateur utilisé.

    La commande sera estimée définitive au moment de l’envoi au Client d’une confirmation stipulant que Tictactrip a bien accepté la commande. Cette confirmation sera envoyée par courrier électronique après avoir reçu l’intégralité de la somme due dans la commande. Tictactrip se réserve la possibilité de refuser ou d’annuler toute commande d’un membre qui présenterait un litige apparu lors d’un paiement lié à une commande antérieure.

    Dans le cas où vous décideriez de régler votre commande de titres de transport à l’aide d’une carte de crédit ou de débit étrangère, les frais de change seront à votre charge. Les modifications et/ou remboursement de es titres de transport pourront également par la suite se voir additionnés les frais de change.

    À la suite d’un paiement effectué, il vous est possible de choisir, en cochant une case, si vous souhaitez que votre carte bancaire soit enregistrée de manière sécurisée dans votre compte, pour faciliter votre prochain achat, ou non. Il vous sera par ailleurs possible de consulter votre listes de cartes bancaires enregistrées, mais aussi bien de facilement supprimer celles-ci dans les réglages et préférences de votre compte.`,
    en: `Whether or not you have a Tictactrip account, you will be required to pay the full amount corresponding to the price of the Transport Tickets added to your Cart at the end of your order. However, you have the option to freely remove a Transport Ticket from the shopping cart.

    The authorized payment methods on our site are as follows:
    
    Credit Card (Carte Bleue, Visa, Mastercard, or American Express);
    PayPal;
    All payments made on the Tictactrip site will be in Euros, including VAT.
    
    Before proceeding with the payment, you are obligated to approve the general terms and conditions of sale. The same applies to the general terms and conditions related to the Operators who market the Transport Tickets you wish to purchase.
    
    The payment tunnel will be entirely secure using the following protocols: TLS 1.0 / 1.1 / 1.2 (SSL). This technology will vary depending on the browser used.
    
    The order will be considered final at the time Tictactrip sends the Customer a confirmation stating that Tictactrip has accepted the order. This confirmation will be sent by email after receiving the full amount due in the order. Tictactrip reserves the right to refuse or cancel any order from a member who presents a dispute arising from a payment related to a previous order.
    
    In the event that you choose to pay for your transport tickets using a foreign credit or debit card, the exchange fees will be at your expense. Changes and/or refunds for these transport tickets may also subsequently incur additional exchange fees.
    
    After making a payment, you can choose, by checking a box, whether you want your credit card to be securely stored in your account to facilitate your next purchase or not. You will also be able to view your list of registered credit cards and easily delete them in the settings and preferences of your account.`,
    es: `Titular o no de una cuenta Tictactrip, se le pedirá al finalizar su pedido que pague la totalidad de la suma correspondiente al precio de los Títulos de transporte añadidos a su Carrito. Sin embargo, tiene la posibilidad de retirar un Título de transporte del carrito de compras libremente.

    Los medios de pago autorizados en nuestro sitio son los siguientes:
    
    Tarjeta bancaria (Carte Bleue, Visa, Mastercard o American Express);
    PayPal;
    Todos los pagos realizados en el sitio de Tictactrip se realizarán en euros IVA incluido.
    
    Antes de poder proceder al pago, está obligado a aprobar las condiciones generales de venta. Lo mismo se aplica a las condiciones generales relacionadas con los Operadores que comercializan los Títulos de transporte que desea comprar.
    
    El túnel de pago será completamente seguro mediante los siguientes protocolos: TLS 1.0 / 1.1 / 1.2 (SSL). Esta tecnología variará según el navegador utilizado.
    
    La orden se considerará definitiva en el momento en que Tictactrip envíe al Cliente una confirmación de que Tictactrip ha aceptado el pedido. Esta confirmación se enviará por correo electrónico después de recibir la totalidad del importe debido en el pedido. Tictactrip se reserva el derecho de rechazar o cancelar cualquier pedido de un miembro que presente una disputa surgida durante un pago relacionado con un pedido anterior.
    
    En el caso de que decida pagar su pedido de títulos de transporte con una tarjeta de crédito o débito extranjera, los gastos de cambio correrán a su cargo. Los cambios y/o reembolsos de estos títulos de transporte también pueden conllevar posteriormente gastos adicionales de cambio.
    
    Después de realizar un pago, tiene la posibilidad de elegir, marcando una casilla, si desea que su tarjeta bancaria se registre de manera segura en su cuenta para facilitar su próxima compra o no. También podrá consultar su lista de tarjetas bancarias registradas y eliminarlas fácilmente en la configuración y preferencias de su cuenta.`,
    it: `Titolare o meno di un account Tictactrip, vi sarà richiesto al termine del vostro ordine di pagare l'intera somma corrispondente al prezzo dei Titoli di trasporto aggiunti nel vostro Carrello. Tuttavia, avete la possibilità di rimuovere liberamente un Titolo di trasporto dal carrello degli acquisti.

    I metodi di pagamento autorizzati sul nostro sito sono i seguenti:
    
    Carta di credito (Carte Bleue, Visa, Mastercard o American Express);
    PayPal;
    Tutti i pagamenti effettuati sul sito di Tictactrip saranno conclusi in Euro, IVA inclusa.
    
    Prima di poter procedere al pagamento, siete obbligati ad approvare le condizioni generali di vendita. Lo stesso vale per le condizioni generali relative agli Operatori che commercializzano i Titoli di Trasporto che desiderate acquistare.
    
    Il tunnel di pagamento sarà completamente sicuro grazie ai seguenti protocolli: TLS 1.0 / 1.1 / 1.2 (SSL). Questa tecnologia varierà a seconda del browser utilizzato.
    
    L'ordine sarà considerato definitivo al momento dell'invio al Cliente di una conferma che Tictactrip ha accettato l'ordine. Questa conferma sarà inviata via e-mail dopo aver ricevuto l'intero importo dovuto nell'ordine. Tictactrip si riserva il diritto di rifiutare o annullare qualsiasi ordine di un membro che presenti una controversia derivante da un pagamento relativo a un ordine precedente.
    
    Nel caso decidiate di pagare il vostro ordine di titoli di trasporto con una carta di credito o di debito straniera, le spese di cambio saranno a vostro carico. Le modifiche e/o i rimborsi per questi titoli di trasporto potrebbero comportare in seguito costi aggiuntivi di cambio.
    
    Dopo aver effettuato un pagamento, avete la possibilità di scegliere, spuntando una casella, se desiderate che la vostra carta di credito sia registrata in modo sicuro nel vostro account per facilitare il vostro prossimo acquisto o meno. Avrete inoltre la possibilità di visualizzare il vostro elenco di carte di credito registrate e eliminarle facilmente nelle impostazioni e preferenze del vostro account.`,
    de: `Ob Sie nun ein Tictactrip-Konto haben oder nicht, Sie werden am Ende Ihrer Bestellung aufgefordert, den Gesamtbetrag entsprechend dem Preis der im Warenkorb hinzugefügten Transporttickets zu bezahlen. Sie haben jedoch die Möglichkeit, ein Transportticket frei aus dem Einkaufswagen zu entfernen.

    Die auf unserer Website zugelassenen Zahlungsmethoden sind wie folgt:
    
    Kreditkarte (Carte Bleue, Visa, Mastercard oder American Express);
    PayPal;
    Alle auf der Tictactrip-Website getätigten Zahlungen erfolgen ausschließlich in Euro inklusive Mehrwertsteuer.
    
    Bevor Sie mit der Zahlung fortfahren können, sind Sie verpflichtet, den Allgemeinen Geschäftsbedingungen zuzustimmen. Das Gleiche gilt für die Allgemeinen Geschäftsbedingungen der Betreiber, die die von Ihnen zu kaufenden Transporttickets vermarkten.
    
    Der Zahlungstunnel wird vollständig mit den folgenden Protokollen gesichert: TLS 1.0 / 1.1 / 1.2 (SSL). Diese Technologie variiert je nach verwendeten Browser.
    
    Die Bestellung gilt als endgültig, wenn Tictactrip dem Kunden eine Bestätigung sendet, dass Tictactrip die Bestellung akzeptiert hat. Diese Bestätigung wird per E-Mail gesendet, nachdem der vollständige Betrag in der Bestellung eingegangen ist. Tictactrip behält sich das Recht vor, jede Bestellung eines Mitglieds abzulehnen oder zu stornieren, das einen Streitfall aufgrund einer Zahlung im Zusammenhang mit einer vorherigen Bestellung vorlegt.
    
    Falls Sie sich entscheiden, Ihre Transporttickets mit einer ausländischen Kredit- oder Debitkarte zu bezahlen, gehen die Wechselkosten zu Ihren Lasten. Änderungen und/oder Rückerstattungen für diese Transporttickets können ebenfalls nachträglich zusätzliche Wechselgebühren verursachen.
    
    Nach erfolgter Zahlung können Sie durch Ankreuzen eines Kästchens auswählen, ob Ihre Kreditkarte sicher in Ihrem Konto gespeichert werden soll, um Ihren nächsten Kauf zu erleichtern, oder nicht. Sie können auch Ihre Liste der registrierten Kreditkarten einsehen und diese einfach in den Einstellungen und Präferenzen Ihres Kontos löschen.`,

    nl: `Al dan niet houder van een Tictactrip-account, wordt u aan het einde van uw bestelling gevraagd het volledige bedrag te betalen dat overeenkomt met de prijs van de toegevoegde vervoersbewijzen in uw Winkelwagen. U heeft echter de mogelijkheid om vrijelijk een vervoersbewijs uit het winkelwagentje te verwijderen.

    De geautoriseerde betaalmethoden op onze site zijn als volgt:
    
    Creditcard (Carte Bleue, Visa, Mastercard of American Express);
    PayPal;
    Alle betalingen die op de Tictactrip-site worden gedaan, worden uitsluitend in euro's, inclusief btw, afgerond.
    
    Voordat u verder kunt gaan met de betaling, bent u verplicht de algemene verkoopvoorwaarden goed te keuren. Hetzelfde geldt voor de algemene voorwaarden met betrekking tot de exploitanten die de vervoersbewijzen verkopen die u wilt kopen.
    
    De betalingstunnel zal volledig beveiligd zijn met behulp van de volgende protocollen: TLS 1.0 / 1.1 / 1.2 (SSL). Deze technologie zal variëren afhankelijk van de gebruikte browser.
    
    De bestelling wordt als definitief beschouwd op het moment dat Tictactrip de klant een bevestiging stuurt waarin staat dat Tictactrip de bestelling heeft geaccepteerd. Deze bevestiging wordt per e-mail verzonden nadat het volledige bedrag in de bestelling is ontvangen. Tictactrip behoudt zich het recht voor om elke bestelling van een lid te weigeren of te annuleren dat een geschil heeft aangaande een betaling die verband houdt met een eerdere bestelling.
    
    In het geval dat u besluit uw vervoersbewijzen te betalen met een buitenlandse creditcard of betaalpas, zijn de wisselkosten voor uw rekening. Wijzigingen en/of terugbetalingen voor deze vervoersbewijzen kunnen ook nadien extra wisselkosten met zich meebrengen.
    
    Na een voltooide betaling kunt u, door een vakje aan te vinken, kiezen of u wilt dat uw creditcard veilig wordt opgeslagen in uw account om uw volgende aankoop te vergemakkelijken, of niet. U kunt ook uw lijst met geregistreerde creditcards eenvoudig bekijken en verwijderen in de instellingen en voorkeuren van uw account.`,
  },
  termsTitle3sub5: {
    id: `${termsOfSalesScope}.termsTitle3sub5`,
    defaultMessage: '3.5. Titres de transport et retrait',
    en: '3.5. Transport Tickets and Pickup',
    es: '3.5. Billetes de transporte y recogida',
    it: '3.5. Biglietti di trasporto e ritiro',
    de: '3.5. Fahrkarten und Abholung',
    nl: '3.5. Vervoerstickets en ophalen',
  },
  thirdContentText6: {
    id: `${termsOfSalesScope}.thirdContentText6`,
    defaultMessage: `À la suite de votre paiement et de la confirmation de votre commande passée, Tictactrip vous procure l’ensemble complet des informations nécessaires au retrait de vos titres de transport. Ceux-ci vous seront fournis dans les meilleurs délais et au plus tard dans les 72h.

     Dans le cas où les titres de transport n’auraient pas été délivrés dans un délai de 72 heures suivant la confirmation de commande, pour une raison autre que la force majeure ou l’action même du client, la vente pourra se conclure sur demande écrite de la part du client. Les sommes engagées par notre part vous seront restituées dans un délai maximum de 14 jours à compter de la date de réception de la demande écrite nous notifiant de cette résolution.

     Lors de la commande, vous serez informés du format d’émission de votre billet de transport. Suite à cette commande, vous recevrez par email des instructions détaillées concernant l’impression et l’utilisation du titre de transport. Ces informations peuvent également se trouver dans le panier lors de l’achat. Veuillez suivre ces instructions scrupuleusement. Parmi les formats proposés sur le site de Tictactrip, nous pouvons retrouver :

     - Le E-billet : il s’agit d’un titre de transport à imprimer sur une feuille aux dimensions A4. Ce titre de transport est contenu dans un fichier au format PDF qui vous est fourni par Tictactrip par courrier électronique, ou bien par les opérateurs, dans le cas où ils vous enverraient directement les titres de transport. Sauf indication contraire contenue dans l’email de confirmation, chaque e-billet doit être impérativement imprimé. Si l’email de confirmation le stipule, le e-billet peut être montré à l’aide de votre mobile. Si votre e-billet est montré depuis votre mobile, il est possible que certains opérateurs acceptent de montrer ce e-billet au format PDF, et que d’autres vous obligent de montrer un format appelé m-billet, principalement conçu pour mobile et ce, à l’aide d’une application mobile propre à l’opérateur.

     - Le billet dit “classique” ou billet dit “cartonné”, également appelé par la SNCF “billet électronique” : il s’agit d’un titre de transport à retirer dans une borne libre-service dans une gare équipée, ou dans un guichet d’une gare dès lors qu’il est ouvert. Pour le retirer, il est impératif de connaître son numéro de dossier SNCF et le nom que vous avez fourni lors de la commande, qui est associé au dossier (ou, le cas échéant, des numéros et des noms associés). Tictactrip (ou les opérateurs quand ces derniers envoient directement les titres de transport aux clients) vous enverra ces informations (à savoir le nom et le numéro de dossier) par email. En cas de non présentation du titre de transport ou de non retrait du titre de transport, le voyage sera considéré comme un voyage réalisé sans titre de transport. Il est fortement recommandé de se présenter au guichet ou à une borne libre-service convenablement à l’avance afin de pouvoir retirer vos titres de transport, et ce, particulièrement en heure de pointe. Si votre billet classique a été volé, oublié ou perdu, pour pouvoir voyager, il est impératif de passer commande d’un nouveau titre de transport.

     Afin d’obtenir de plus amples informations concernant les titres de transport fournis par les opérateurs et leur typologie, nous vous conseillons de vous référer aux conditions générales de vente émises par les opérateurs.

     Avant de monter à bord, soyez sûrs d’être en possession de vos titres de transport. Si vous n’êtes pas apte à présenter votre titre de transport valable pour le service utilisé, vous devrez vous acquitter du montant correspondant ainsi que de toute amende qui pourrait vous être infligée, à bord.`,
    en: `Following your payment and the confirmation of your placed order, Tictactrip provides you with the complete set of information necessary for the retrieval of your transport tickets. These will be provided to you promptly and at the latest within 72 hours.

     In the event that the transport tickets have not been issued within a period of 72 hours following the order confirmation, for a reason other than force majeure or the customer's own action, the sale may be concluded upon the written request of the customer. The amounts committed by us will be refunded to you within a maximum period of 14 days from the date of receipt of the written request notifying us of this resolution.
     
     During the order, you will be informed of the issuance format of your transport ticket. Following this order, you will receive detailed instructions by email regarding the printing and use of the transport ticket. These instructions may also be found in the cart during the purchase. Please follow these instructions carefully. Among the formats offered on the Tictactrip website, we can find:
     
     The E-ticket: This is a transport ticket to be printed on an A4-sized sheet. This transport ticket is contained in a PDF file provided to you by Tictactrip by email, or by the operators, in case they send you the transport tickets directly. Unless otherwise indicated in the confirmation email, each e-ticket must be printed. If the confirmation email specifies, the e-ticket can be shown using your mobile. If your e-ticket is shown from your mobile, it is possible that some operators accept showing this e-ticket in PDF format, and others require you to show a format called m-ticket, mainly designed for mobile use, using a mobile application specific to the operator.
     
     The so-called "classic" ticket or so-called "cardboard" ticket, also called by the SNCF "electronic ticket": This is a transport ticket to be collected from a self-service kiosk in an equipped station, or from a ticket counter in a station when it is open. To collect it, it is imperative to know its SNCF file number and the name you provided when ordering, which is associated with the file (or, if applicable, associated numbers and names). Tictactrip (or the operators when they send the transport tickets directly to customers) will send you this information (namely the name and file number) by email. In case of non-presentation of the transport ticket or non-collection of the transport ticket, the journey will be considered as a journey carried out without a transport ticket. It is strongly recommended to present yourself at the counter or at a self-service kiosk well in advance in order to collect your transport tickets, especially during peak hours. If your classic ticket has been stolen, forgotten, or lost, to be able to travel, it is imperative to order a new transport ticket.
     
     To obtain more information regarding the transport tickets provided by the operators and their typology, we advise you to refer to the general terms and conditions issued by the operators.
     
     Before boarding, make sure you have your transport tickets with you. If you are unable to present your valid transport ticket for the service used, you will have to pay the corresponding amount as well as any fine that may be imposed on board.`,
    es: `Después de su pago y la confirmación de su pedido, Tictactrip le proporciona el conjunto completo de información necesario para la obtención de sus boletos de transporte. Estos le serán proporcionados de manera rápida y a más tardar en un plazo de 72 horas.

     En caso de que los boletos de transporte no se hayan emitido dentro de un período de 72 horas después de la confirmación del pedido, por una razón que no sea de fuerza mayor o la acción misma del cliente, la venta podrá concluirse a solicitud escrita del cliente. Los montos comprometidos por nuestra parte le serán reembolsados en un plazo máximo de 14 días a partir de la fecha de recepción de la solicitud escrita que nos notifique esta resolución.
     
     Durante el pedido, se le informará sobre el formato de emisión de su boleto de transporte. Después de este pedido, recibirá instrucciones detalladas por correo electrónico sobre la impresión y el uso del boleto de transporte. Estas instrucciones también pueden encontrarse en el carrito durante la compra. Siga estas instrucciones cuidadosamente. Entre los formatos ofrecidos en el sitio web de Tictactrip, podemos encontrar:
     
     El E-ticket: Este es un boleto de transporte para imprimir en una hoja de tamaño A4. Este boleto de transporte está contenido en un archivo PDF que Tictactrip le proporciona por correo electrónico, o por los operadores, en caso de que le envíen los boletos de transporte directamente. A menos que se indique lo contrario en el correo electrónico de confirmación, cada E-ticket debe imprimirse obligatoriamente. Si el correo electrónico de confirmación lo especifica, el E-ticket se puede mostrar usando su móvil. Si su E-ticket se muestra desde su móvil, es posible que algunos operadores acepten mostrar este E-ticket en formato PDF y que otros le exijan mostrar un formato llamado M-ticket, diseñado principalmente para el uso móvil, mediante una aplicación móvil específica del operador.
     
     El boleto llamado "clásico" o boleto llamado "cartón", también llamado por la SNCF "boleto electrónico": Este es un boleto de transporte que se recoge en un quiosco de autoservicio en una estación equipada, o en un mostrador de una estación cuando está abierto. Para recogerlo, es imprescindible conocer su número de expediente SNCF y el nombre que proporcionó al realizar el pedido, que está asociado al expediente (o, si corresponde, números y nombres asociados). Tictactrip (o los operadores cuando envían los boletos de transporte directamente a los clientes) le enviarán esta información (nombre y número de expediente) por correo electrónico. En caso de no presentación del boleto de transporte o no recogida del boleto de transporte, el viaje se considerará como realizado sin boleto de transporte. Se recomienda encarecidamente presentarse en el mostrador o en un quiosco de autoservicio con suficiente antelación para poder recoger sus boletos de transporte, especialmente durante las horas pico. Si su boleto clásico ha sido robado, olvidado o perdido, para poder viajar es imprescindible pedir un nuevo boleto de transporte.
     
     Para obtener más información sobre los boletos de transporte proporcionados por los operadores y su tipología, le recomendamos que consulte las condiciones generales de venta emitidas por los operadores.
     
     Antes de abordar, asegúrese de tener sus boletos de transporte con usted. Si no puede presentar su boleto de transporte válido para el servicio utilizado, deberá pagar el monto correspondiente y cualquier multa que pueda imponérsele a bordo.`,
    it: `Dopo il pagamento e la conferma dell'ordine effettuato, Tictactrip fornisce l'insieme completo delle informazioni necessarie per il ritiro dei tuoi biglietti di trasporto. Questi ti saranno forniti tempestivamente e al più tardi entro 72 ore.

     Nel caso in cui i biglietti di trasporto non siano stati emessi entro un periodo di 72 ore dalla conferma dell'ordine, per una ragione diversa dalla forza maggiore o dall'azione stessa del cliente, la vendita potrà concludersi su richiesta scritta del cliente. Gli importi impegnati dalla nostra parte ti saranno rimborsati entro un periodo massimo di 14 giorni dalla data di ricezione della richiesta scritta che ci notifica questa risoluzione.
     
     Durante l'ordine, sarai informato del formato di emissione del tuo biglietto di trasporto. A seguito di questo ordine, riceverai per email istruzioni dettagliate riguardanti la stampa e l'uso del titolo di trasporto. Queste informazioni possono essere trovate anche nel carrello durante l'acquisto. Segui attentamente queste istruzioni. Tra i formati offerti sul sito web di Tictactrip, possiamo trovare:
     
     L'E-ticket: Questo è un titolo di trasporto da stampare su un foglio di dimensioni A4. Questo titolo di trasporto è contenuto in un file PDF fornito da Tictactrip via email o dagli operatori, nel caso in cui ti inviino direttamente i titoli di trasporto. Salvo diversa indicazione nell'email di conferma, ogni e-ticket deve essere necessariamente stampato. Se l'email di conferma lo specifica, l'e-ticket può essere mostrato usando il tuo cellulare. Se il tuo e-ticket viene mostrato dal tuo cellulare, è possibile che alcuni operatori accettino di mostrare questo e-ticket in formato PDF e che altri richiedano di mostrare un formato chiamato m-ticket, principalmente progettato per l'uso su dispositivi mobili, utilizzando un'applicazione mobile specifica dell'operatore.
     
     Il cosiddetto biglietto "classico" o il cosiddetto biglietto "cartonato", chiamato anche dalla SNCF "biglietto elettronico": Questo è un titolo di trasporto da ritirare da un distributore automatico in una stazione attrezzata o da uno sportello di una stazione quando è aperto. Per ritirarlo, è essenziale conoscere il numero del file SNCF e il nome che hai fornito al momento dell'ordine, che è associato al file (o, se del caso, numeri e nomi associati). Tictactrip (o gli operatori quando inviano direttamente i titoli di trasporto ai clienti) ti invierà queste informazioni (cioè il nome e il numero di file) via email. In caso di mancata presentazione del titolo di trasporto o di mancato ritiro del titolo di trasporto, il viaggio sarà considerato come un viaggio effettuato senza titolo di trasporto. Si raccomanda vivamente di presentarsi allo sportello o a un distributore automatico in anticipo per poter ritirare i tuoi titoli di trasporto, soprattutto durante le ore di punta. Se il tuo biglietto classico è stato rubato, dimenticato o perso, per poter viaggiare è essenziale ordinare un nuovo titolo di trasporto.
     
     Per ottenere ulteriori informazioni sui titoli di trasporto forniti dagli operatori e sulla loro tipologia, ti consigliamo di fare riferimento alle condizioni generali di vendita emesse dagli operatori.
     
     Prima di salire a bordo, assicurati di avere con te i tuoi titoli di trasporto. Se non sei in grado di presentare il tuo titolo di trasporto valido per il servizio utilizzato, dovrai pagare l'importo corrispondente e qualsiasi multa che potrebbe essere inflitta a bordo.`,
    de: `Nach Ihrer Zahlung und der Bestätigung Ihrer getätigten Bestellung stellt Ihnen Tictactrip alle erforderlichen Informationen zur Abholung Ihrer Fahrkarten zur Verfügung. Diese werden Ihnen so schnell wie möglich und spätestens innerhalb von 72 Stunden bereitgestellt.

     Falls die Fahrkarten nicht innerhalb von 72 Stunden nach Bestellbestätigung ausgeliefert wurden, aus Gründen, die nicht höherer Gewalt oder dem Handeln des Kunden selbst zuzurechnen sind, kann der Verkauf auf schriftliche Anfrage des Kunden abgeschlossen werden. Die von unserer Seite getätigten Zahlungen werden Ihnen innerhalb von maximal 14 Tagen ab dem Datum des Erhalts des schriftlichen Antrags, der uns über diese Auflösung benachrichtigt, zurückerstattet.
     
     Bei der Bestellung werden Sie über das Ausgabeformat Ihres Fahrscheins informiert. Nach Abschluss dieser Bestellung erhalten Sie per E-Mail detaillierte Anweisungen zur Druck- und Verwendung des Fahrscheins. Diese Informationen können auch im Warenkorb während des Kaufs angezeigt werden. Bitte folgen Sie diesen Anweisungen sorgfältig. Unter den auf der Tictactrip-Website angebotenen Formaten finden sich:
     
     Das E-Ticket: Dies ist ein Fahrschein zum Ausdrucken auf einem Blatt im A4-Format. Dieser Fahrschein ist in einer PDF-Datei enthalten, die Ihnen von Tictactrip per E-Mail zur Verfügung gestellt wird oder von den Betreibern, falls sie Ihnen die Fahrscheine direkt zusenden. Sofern im Bestätigungsemail nichts anderes angegeben ist, muss jedes E-Ticket unbedingt ausgedruckt werden. Wenn das Bestätigungsemail es angibt, kann das E-Ticket mit Ihrem Mobilgerät gezeigt werden. Wenn Ihr E-Ticket von Ihrem Mobilgerät aus gezeigt wird, ist es möglich, dass einige Betreiber akzeptieren, dieses E-Ticket im PDF-Format zu zeigen, während andere verlangen, dass Sie ein Format namens M-Ticket zeigen, das hauptsächlich für Mobilgeräte konzipiert ist und dies mit einer mobilen App des Betreibers.
     
     Das sogenannte "klassische" Ticket oder das sogenannte "kartonierte" Ticket, auch von der SNCF als "elektronisches Ticket" bezeichnet: Dies ist ein Fahrschein, der an einem ausgestatteten Selbstbedienungsterminal an einem Bahnhof oder an einem Schalter eines geöffneten Bahnhofs abgeholt werden muss. Um ihn abzuholen, ist es unerlässlich, die SNCF-Dossiernummer und den bei der Bestellung angegebenen Namen zu kennen, der mit dem Dossier verbunden ist (oder gegebenenfalls Nummern und Namen zugeordnet sind). Tictactrip (oder die Betreiber, wenn sie die Fahrscheine direkt an die Kunden senden) sendet Ihnen diese Informationen (Name und Dossiernummer) per E-Mail. Bei Nichtvorlage des Fahrscheins oder Nichtabholung des Fahrscheins wird die Reise als Reise ohne gültigen Fahrschein betrachtet. Es wird dringend empfohlen, sich rechtzeitig am Schalter oder an einem geeigneten Selbstbedienungsterminal zu melden, um Ihre Fahrscheine abzuholen, insbesondere während der Stoßzeiten. Wenn Ihr klassisches Ticket gestohlen, vergessen oder verloren wurde, müssen Sie zwingend einen neuen Fahrschein bestellen, um reisen zu können.
     
     Um weitere Informationen zu den von den Betreibern bereitgestellten Fahrscheinen und ihrer Typologie zu erhalten, empfehlen wir Ihnen, die von den Betreibern herausgegebenen Allgemeinen Geschäftsbedingungen zu konsultieren.
     
     Bevor Sie an Bord gehen, stellen Sie sicher, dass Sie im Besitz Ihrer Fahrscheine sind. Wenn Sie nicht in der Lage sind, einen gültigen Fahrschein für den genutzten Service vorzuzeigen, müssen Sie den entsprechenden Betrag sowie eventuelle Strafen, die an Bord verhängt werden könnten, bezahlen.`,
    nl: `Na uw betaling en bevestiging van uw geplaatste bestelling, voorziet Tictactrip u van alle nodige informatie voor het ophalen van uw vervoersbewijzen. Deze worden u zo snel mogelijk en uiterlijk binnen 72 uur verstrekt.

     Indien de vervoersbewijzen niet binnen 72 uur na de bestelbevestiging zijn afgeleverd, om redenen anders dan overmacht of de handeling van de klant zelf, kan de verkoop worden afgesloten op schriftelijk verzoek van de klant. De door onze kant gemaakte kosten worden binnen maximaal 14 dagen na de datum van ontvangst van het schriftelijke verzoek tot ontbinding aan u terugbetaald.
     
     Bij het plaatsen van de bestelling wordt u geïnformeerd over het uitgifteformaat van uw vervoersbewijs. Na het plaatsen van de bestelling ontvangt u per e-mail gedetailleerde instructies over het afdrukken en gebruiken van het vervoersbewijs. Deze informatie kan ook in het winkelmandje tijdens de aankoop worden weergegeven. Volg deze instructies nauwkeurig op. Onder de op de Tictactrip-website aangeboden formaten vinden we:
     
     Het E-ticket: dit is een vervoersbewijs om af te drukken op een vel van het A4-formaat. Dit vervoersbewijs zit in een PDF-bestand dat u door Tictactrip per e-mail wordt verstrekt of door de exploitanten, indien zij de vervoersbewijzen rechtstreeks naar u zouden sturen. Tenzij anders vermeld in de bevestigingse-mail, moet elk E-ticket absoluut worden afgedrukt. Als de bevestigingse-mail dit aangeeft, kan het E-ticket worden getoond met behulp van uw mobiele telefoon. Als uw E-ticket wordt getoond vanaf uw mobiele telefoon, is het mogelijk dat sommige exploitanten dit E-ticket in PDF-formaat accepteren, en dat anderen vereisen dat u een formaat genaamd M-ticket toont, voornamelijk ontworpen voor mobiel gebruik en dit met behulp van een mobiele app van de exploitant.
     
     Het zogenaamde "klassieke" ticket of het zogenaamde "kartonnen" ticket, ook wel door de SNCF aangeduid als "elektronisch ticket": dit is een vervoersbewijs dat moet worden opgehaald bij een zelfbedieningsterminal op een uitgerust station of bij een loket van een geopend station. Om het op te halen, is het essentieel om het SNCF-dossiernummer te kennen en de naam die u heeft opgegeven bij de bestelling, die is gekoppeld aan het dossier (of indien van toepassing, aan nummers en namen). Tictactrip (of de exploitanten wanneer zij de vervoersbewijzen rechtstreeks naar de klanten sturen) stuurt u deze informatie (naam en dossiernummer) per e-mail. Bij niet-presentatie van het vervoersbewijs of het niet ophalen ervan wordt de reis beschouwd als een reis zonder geldig vervoersbewijs. Het wordt sterk aanbevolen om ruim van tevoren naar het loket of een geschikte zelfbedieningsterminal te gaan om uw vervoersbewijzen op te halen, vooral tijdens de spitsuren. Als uw klassieke ticket gestolen, vergeten of verloren is, moet u een nieuw vervoersbewijs bestellen om te kunnen reizen.
     
     Om meer informatie te verkrijgen over de vervoersbewijzen die door de exploitanten worden verstrekt en hun typologie, raden wij u aan de algemene verkoopvoorwaarden van de exploitanten te raadplegen.
     
     Voordat u aan boord gaat, zorg ervoor dat u uw vervoersbewijzen bij u heeft. Als u niet in staat bent om een geldig vervoersbewijs voor de gebruikte dienst te tonen, moet u het overeenkomstige bedrag betalen, evenals eventuele boetes die aan boord kunnen worden opgelegd.`,
  },
  termsTitle3sub6: {
    id: `${termsOfSalesScope}.termsTitle3sub6`,
    defaultMessage: '3.6 Frais de Service',
    en: '3.6 Service Fees',
    es: '3.6 Tarifas de servicio',
    it: '3.6 Commissioni di servizio',
    de: '3.6 Servicegebühren',
    nl: '3.6 Servicekosten',
  },

  thirdContentText7: {
    id: `${termsOfSalesScope}.thirdContentText7`,
    defaultMessage: `Dans le cadre de la création d'itinéraires uniques et exclusifs, Tictactrip se réserve le droit d'appliquer des frais de service sur certains trajets.
    
    Prenons pour exemple un trajet combinant 2 compagnies de transport différentes. La hauteur des frais de services appliqués par Tictactrip s'évalue en fonction du prix des billets.
    
    Ces frais de services permettent à Tictactrip de se rémunérer sur les ventes de billets et donc d'offrir un service optimal à ses clients.
    
    Tictactrip propose également des niveaux tarifaires différents selon le niveau de service que le client aura sélectionné. (cf article 3.7)

    Pour tout type d’annulation demandé par le client,  les frais de service ne sont pas remboursables.
    Exception : Si nos partenaires de transport annulent un ou l'ensemble de votre trajet les frais de service seront remboursés.`,
    en: `In the context of creating unique and exclusive itineraries, Tictactrip reserves the right to apply service fees to certain routes.

    Take, for example, a journey combining 2 different transport companies. The amount of service fees applied by Tictactrip is assessed based on the ticket prices.
    
    These service fees allow Tictactrip to earn revenue from ticket sales and, in turn, provide optimal service to its customers.
    
    Tictactrip also offers different tariff levels depending on the service level selected by the customer (see Article 3.7).
    
    For any cancellation requested by the customer, service fees are non-refundable.
    Exception: If our transport partners cancel all or part of your journey, service fees will be refunded.`,
    es: `En el marco de la creación de itinerarios únicos y exclusivos, Tictactrip se reserva el derecho de aplicar tarifas de servicio en ciertos trayectos.

    Tomemos como ejemplo un trayecto que combina 2 compañías de transporte diferentes. La cuantía de las tarifas de servicio aplicadas por Tictactrip se evalúa en función del precio de los billetes.
    
    Estas tarifas de servicio permiten a Tictactrip obtener ingresos por la venta de billetes y, por lo tanto, ofrecer un servicio óptimo a sus clientes.
    
    Tictactrip también ofrece diferentes niveles tarifarios según el nivel de servicio que el cliente haya seleccionado (consulte el artículo 3.7).
    
    Para cualquier tipo de cancelación solicitada por el cliente, las tarifas de servicio no son reembolsables.
    Excepción: si nuestros socios de transporte cancelan todo o parte de su trayecto, las tarifas de servicio serán reembolsadas.`,
    it: `Nel contesto della creazione di itinerari unici ed esclusivi, Tictactrip si riserva il diritto di applicare tariffe di servizio su determinati percorsi.

    Prendiamo ad esempio un percorso che combina 2 diverse compagnie di trasporto. L'importo delle tariffe di servizio applicate da Tictactrip è valutato in base al prezzo dei biglietti.
    
    Queste tariffe di servizio consentono a Tictactrip di remunerarsi sulle vendite dei biglietti e quindi di offrire un servizio ottimale ai suoi clienti.
    
    Tictactrip propone anche diversi livelli tariffari a seconda del livello di servizio che il cliente avrà selezionato (vedi articolo 3.7).
    
    Per qualsiasi tipo di annullamento richiesto dal cliente, le tariffe di servizio non sono rimborsabili.
    Eccezione: se i nostri partner di trasporto annullano tutto o parte del tuo percorso, le tariffe di servizio verranno rimborsate.`,
    de: `Im Rahmen der Erstellung einzigartiger und exklusiver Reiserouten behält sich Tictactrip das Recht vor, auf bestimmten Strecken Servicegebühren zu erheben.

    Nehmen wir als Beispiel eine Reise, die 2 verschiedene Verkehrsunternehmen kombiniert. Die Höhe der von Tictactrip erhobenen Servicegebühren richtet sich nach dem Preis der Tickets.
    
    Diese Servicegebühren ermöglichen es Tictactrip, sich auf den Ticketverkäufen zu vergüten und seinen Kunden einen optimalen Service zu bieten.
    
    Tictactrip bietet auch unterschiedliche Tarife je nach dem vom Kunden ausgewählten Serviceniveau an (siehe Artikel 3.7).
    
    Für jede Art von Stornierung, die vom Kunden beantragt wird, sind die Servicegebühren nicht erstattungsfähig.
    Ausnahme: Wenn unsere Transportpartner eine oder alle Teile Ihrer Reise stornieren, werden die Servicegebühren erstattet.`,
    nl: `
    In het kader van het creëren van unieke en exclusieve routes behoudt Tictactrip zich het recht voor om servicekosten in rekening te brengen voor bepaalde reizen.
    
    Laten we als voorbeeld een reis nemen waarbij 2 verschillende vervoersbedrijven worden gecombineerd. De hoogte van de servicekosten die Tictactrip in rekening brengt, wordt bepaald door de ticketprijzen.
    
    Deze servicekosten stellen Tictactrip in staat om zich te vergoeden voor de verkoop van tickets en zo een optimale service aan zijn klanten te bieden.
    
    Tictactrip biedt ook verschillende tariefniveaus aan, afhankelijk van het serviceniveau dat de klant heeft geselecteerd (zie artikel 3.7).
    
    Voor elk type annulering dat door de klant wordt aangevraagd, zijn de servicekosten niet restitueerbaar.
    Uitzondering: Als onze transportpartners een deel of het geheel van uw reis annuleren, worden de servicekosten wel vergoed.`,
  },
  termsTitle3sub7: {
    id: `${termsOfSalesScope}.termsTitle3sub7`,
    defaultMessage:
      '3.7 Tarifs des billets Tictactrip et offres de services supplémentaires',
    en: '3.7 Tictactrip Ticket Prices and Additional Service Offers',
    es: '3.7 Tarifas de boletos Tictactrip y ofertas de servicios adicionales',
    it: '3.7 Tariffe dei biglietti Tictactrip e offerte di servizi aggiuntivi',
    de: '3.7 Tictactrip Ticketpreise und zusätzliche Serviceangebote',
    nl: '3.7 Tictactrip Ticketprijzen en aanvullende serviceaanbiedingen',
  },

  thirdContentText8: {
    id: `${termsOfSalesScope}.thirdContentText8`,
    defaultMessage: `Lors de l’achat de votre billet, Tictactrip vous propose différents niveaux de service afin d’être pris en charge selon vos besoins.
    
    Nous vous proposons donc des options, dès lorsque vous avez réalisé la sélection de l’une d’entre elles, cela modifiera la portée et les conditions de votre réservation ainsi que son prix total. Vous disposez donc d’un moyen efficace de répondre à vos éventuels besoins en matière de flexibilité de réservation. Celles-ci pouvant être agrémentées de services liés aux voyages, modifiées ou annulées à l’aide de nos services supplémentaires mais aussi de juger de la nécessité du traitement prioritaire de vos demandes par notre service client.
    
    <strong>1. Options de service des billets Tictactrip.</strong>
    
    En tant que condition préalable à l'achèvement de la réservation et à l'exécution du contrat de service entre vous et nous, il vous sera proposé, dans le cadre de la procédure de réservation, de choisir l'une des options de de tarifs de billets et services que nous vous proposerons. Chaque Tarif de billet Tictactrip représente l'ensemble spécifique de conditions générales contraignantes en vertu desquelles nous vous procurons un service client, des services supplémentaires et du traitement des remboursements correspondants aux modifications/changements de réservation. (Ci-après les **services supplémentaires spécifiques** « Zen & Relax ») ;  des services supplémentaires spécifiques consistant à recevoir en plus de l’email de confirmation, un sms confirmant également le trajet à venir, un accès prioritaire au service client, l’annulation et le remboursement du billet selon les conditions des transporteurs, l’accélération du processus dans la demande de remboursement et d’annulation d’un billet, la modification de la réservation (hors annulation et remboursement) pour 10€ ; d'autres Services supplémentaires (Ci-après dénommés les **Services supplémentaires standards** « Le bon deal ») ; sont compris dans les services supplémentaires standards la réception de l’email de confirmation, l’accès au service client, l’annulation et le remboursement du billet selon les conditions des transporteurs, nous vous facturons l’accélération du processus dans la demande de remboursement et d’annulation d’un billet pour 10€  et la modification de la réservation (hors annulation et remboursement) pour 15€ pour couvrir nos frais de gestion.

    Veuillez noter que : les différentes contreparties pour le tarif du billet sélectionné seront incluses dans les frais de nos Services dans le cadre du prix total, comme cela vous sera clairement indiqué avant votre sélection finale du Tarif de billet Tictactrip ;
    <ul>
      <li>La sélection du tarif du billet ne modifie en rien nos droits et obligations réciproques envers les termes du contrat de service ;</li>
      <li>Vous ne pouvez pas modifier votre sélection du tarif du billet après avoir effectué votre réservation. Toutefois, vous pouvez acheter une offre de service soumise à des conditions générales plus favorables concernant notre service client ou nos services supplémentaires standards. Veuillez toujours prendre en compte le fait que nous sommes susceptibles de ne pas pouvoir vous fournir des services supplémentaires standards si vous en faites la demande moins de 24 heures avant le départ du premier transport de votre réservation ;</li>
      <li>Les conditions générales de nos prestations de services supplémentaires spécifiques ne sont plus modifiables une fois votre réservation finalisée et il n'existe aucune possibilité d'y déroger.</li>
    </ul>    
    
    <strong>2. Conditions générales spécifiques des tarifs de billets et niveaux de service chez Tictactrip.</strong>
    
    Vous pouvez choisir un des tarifs et option de services de billets ci-dessous :
    
    <table style="max-width: 900px; width: 100%">
      <thead>
        <tr>
          <th title="Field #1">Niveau de service</th>
          <th title="Field #2">Le bon deal</th>
          <th title="Field #3">Zen &amp; Relax</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Email de confirmation</td>
          <td style="text-align: center">inclus</td>
          <td style="text-align: center">inclus</td>
        </tr>
        <tr>
          <td>SMS de confirmation</td>
          <td style="text-align: center">non inclus</td>
          <td style="text-align: center">inclus</td>
        </tr>
        <tr>
          <td>Service client</td>
          <td style="text-align: center">inclus</td>
          <td style="text-align: center">inclus</td>
        </tr>
        <tr>
          <td>Accès prioritaire au traitement d’une demande de support</td>
          <td style="text-align: center">non inclus</td>
          <td style="text-align: center">inclus</td>
        </tr>
        <tr>
          <td>Actions d’après-vente (hors annulation et remboursement)</td>
          <td style="text-align: center">15€</td>
          <td style="text-align: center">10€</td>
        </tr>
        <tr>
          <td>Annulation et remboursement</td>
          <td style="text-align: center">inclus</td>
          <td style="text-align: center">inclus</td>
        </tr>
        <tr>
          <td>Accélération du processus d’annulation et de remboursement</td>
          <td style="text-align: center">10€</td>
          <td style="text-align: center">inclus</td>
        </tr>
      </tbody>
    </table>

    <strong>3. Conditions générales des tarifs de billets achetés chez Tictactrip</strong>

    Veuillez noter que :
    <ul>
      <li>Un billet peut être soumis de la part du transporteur à une augmentation du tarif entre le moment de la réservation et du paiement. Vous serez toujours notifié de cette augmentation.</li>
      <li>Les conditions d’annulation et de remboursement des billets sont celles de nos compagnies de transport partenaires. Le remboursement n’est donc pas possible dans tous les cas.</li>
      <li>Il vous sera toujours demandé une confirmation d’annulation par mail. Une réponse est attendue dans les 24h. Passé ce délai nous tiendrons compte de la demande initiale et votre billet sera annulé.</li>
      <li>Vous bénéficiez d’un service client pour toutes questions ou demande d’informations à propos de votre réservation dans les conditions de l’option de service que vous aurez sélectionné au préalable.</li>
      <li>Les actions d’après-vente concernent les changements de date, de nom, de trajet, l’ajout de passagers (hors annulation et remboursement).</li>
      <li>L’accélération du processus d’annulation et de remboursement permet d’organiser de manière prioritaire la procédure d’annulation et de remboursement auprès de nos partenaires. Dans la limite des conditions et du temps de traitement des transporteurs.</li>
    </ul>
    `,
    en: `<p>When purchasing your ticket, Tictactrip offers you various service levels to meet your needs.</p>

    <p>We provide you with options once you have made your selection, and this will modify the scope and conditions of your reservation as well as its total price. This gives you an effective way to address your potential reservation flexibility needs. These options may include travel-related services, modifications or cancellations using our additional services, and assessing the need for priority processing of your requests by our customer service.</p>
    
    <strong>1. Tictactrip Ticket Service Options.</strong>
    
    <p>As a prerequisite for completing the reservation and executing the service contract between you and us, you will be offered, as part of the booking process, to choose one of the ticket fare and service options we propose. Each Tictactrip Ticket Fare represents the specific set of binding terms under which we provide you with customer service, additional services, and corresponding refund processing for reservation changes. (Hereinafter referred to as <strong>specific additional services</strong> "Zen & Relax"); specific additional services include receiving, in addition to the confirmation email, an SMS confirming the upcoming journey, priority access to customer service, cancellation and refund of the ticket according to carrier conditions, expedited processing in the refund and cancellation request for €10; other Additional Services (Hereinafter referred to as <strong>standard additional services</strong> "The Good Deal"); standard additional services include receiving the confirmation email, access to customer service, cancellation and refund of the ticket according to carrier conditions, we charge €10 for expedited processing in the refund and cancellation request, and €15 for modifying the reservation (excluding cancellation and refund) to cover our administrative costs.</p>
    
    <p>Please note that: the various benefits for the selected ticket fare will be included in the fees for our services as part of the total price, as clearly indicated to you before your final selection of the Tictactrip Ticket Fare;
    <ul>
      <li>Selecting the ticket fare does not modify our reciprocal rights and obligations under the terms of the service contract;</li>
      <li>You cannot modify your ticket fare selection after completing your reservation. However, you can purchase a service offer subject to more favorable general conditions regarding our customer service or standard additional services. Always keep in mind that we may not be able to provide you with standard additional services if you request them less than 24 hours before the departure of the first transport in your reservation;</li>
      <li>The general conditions of our specific additional services are no longer modifiable once your reservation is finalized, and there is no possibility of derogation.</li>
    </ul></p>
    
    <strong>2. Specific General Conditions of Tictactrip Ticket Fares and Service Levels.</strong>
    
    <p>You can choose one of the ticket fares and service options below:</p>
    
    <table style="max-width: 900px; width: 100%">
      <thead>
        <tr>
          <th title="Field #1">Service Level</th>
          <th title="Field #2">The Good Deal</th>
          <th title="Field #3">Zen &amp; Relax</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Email Confirmation</td>
          <td style="text-align: center">Included</td>
          <td style="text-align: center">Included</td>
        </tr>
        <tr>
          <td>SMS Confirmation</td>
          <td style="text-align: center">Not Included</td>
          <td style="text-align: center">Included</td>
        </tr>
        <tr>
          <td>Customer Service</td>
          <td style="text-align: center">Included</td>
          <td style="text-align: center">Included</td>
        </tr>
        <tr>
          <td>Priority Access to Support Request Processing</td>
          <td style="text-align: center">Not Included</td>
          <td style="text-align: center">Included</td>
        </tr>
        <tr>
          <td>After-Sales Actions (excluding cancellation/refund)</td>
          <td style="text-align: center">€15</td>
          <td style="text-align: center">€10</td>
        </tr>
        <tr>
          <td>Cancellation and Refund</td>
          <td style="text-align: center">Included</td>
          <td style="text-align: center">Included</td>
        </tr>
        <tr>
          <td>Acceleration of Cancellation and Refund Process</td>
          <td style="text-align: center">€10</td>
          <td style="text-align: center">Included</td>
        </tr>
      </tbody>
    </table>
    
    <strong>3. General Conditions for Tictactrip Ticket Fares Purchased.</strong>
    
    <p>Please note that:
<ul>
  <li>A ticket may be subject to an increase in the fare by the carrier between the time of booking and payment. You will always be notified of this increase.</li>
  <li>The cancellation and refund conditions for tickets are those of our partner transport companies. Refund is therefore not possible in all cases.</li>
  <li>You will always be asked for a cancellation confirmation by email. A response is expected within 24 hours. After this period, we will consider the initial request, and your ticket will be canceled.</li>
  <li>You have access to customer service for any questions or information requests about your reservation, according to the conditions of the service option you have selected in advance.</li>
  <li>After-sales actions concern changes to the date, name, route, and the addition of passengers (excluding cancellation and refund).</li>
  <li>The acceleration of the cancellation and refund process allows for the prioritized organization of the cancellation and refund procedure with our partners, within the conditions and processing time limits of the transport companies.</li>
</ul></p>

    `,
    es: `<p>Al comprar su boleto, Tictactrip le ofrece diferentes niveles de servicio para satisfacer sus necesidades.
    
    Por lo tanto, le ofrecemos opciones. Tan pronto como haya realizado la selección de una de ellas, modificará el alcance y las condiciones de su reserva, así como su precio total. Por lo tanto, tiene un medio efectivo para satisfacer sus posibles necesidades de flexibilidad en la reserva. Estas opciones pueden incluir servicios relacionados con los viajes, modificarse o cancelarse mediante nuestros servicios adicionales, así como juzgar la necesidad de priorizar el tratamiento de sus solicitudes por parte de nuestro servicio al cliente.
    
    <strong>1. Opciones de servicio de boletos Tictactrip.</strong>
    
    Como requisito previo para completar la reserva y ejecutar el contrato de servicio entre usted y nosotros, se le ofrecerá, como parte del procedimiento de reserva, elegir una de las opciones de tarifas de boletos y servicios que le ofreceremos. Cada Tarifa de boleto Tictactrip representa el conjunto específico de condiciones generales vinculantes bajo las cuales le proporcionamos servicio al cliente, servicios adicionales y el tratamiento de reembolsos correspondientes a modificaciones/cambios en la reserva. (En adelante, los **servicios adicionales específicos** "Zen & Relax"); los servicios adicionales específicos consisten en recibir, además del correo electrónico de confirmación, un SMS que también confirma el próximo viaje, acceso prioritario al servicio al cliente, cancelación y reembolso del boleto según las condiciones de los transportistas, aceleración del proceso en la solicitud de reembolso y cancelación de un boleto, modificación de la reserva (excluyendo cancelación y reembolso) por 10€; otros Servicios adicionales (En adelante, los **Servicios adicionales estándar** "La buena oferta"); se incluyen en los servicios adicionales estándar la recepción del correo electrónico de confirmación, acceso al servicio al cliente, cancelación y reembolso del boleto según las condiciones de los transportistas, le cobramos la aceleración del proceso en la solicitud de reembolso y cancelación de un boleto por 10€ y la modificación de la reserva (excluyendo cancelación y reembolso) por 15€ para cubrir nuestros gastos de gestión.

    Tenga en cuenta que: las diferentes contrapartes para la tarifa del boleto seleccionado estarán incluidas en las tarifas de nuestros Servicios como parte del precio total, como se le indicará claramente antes de su selección final de la Tarifa de boleto Tictactrip;
    <ul>
      <li>La selección de la tarifa del boleto no modifica en absoluto nuestros derechos y obligaciones mutuas en virtud de los términos del contrato de servicio;</li>
      <li>No puede modificar su selección de la tarifa del boleto después de haber realizado su reserva. Sin embargo, puede comprar una oferta de servicio sujeta a condiciones generales más favorables con respecto a nuestro servicio al cliente o nuestros servicios adicionales estándar. Tenga siempre en cuenta que es posible que no podamos proporcionarle servicios adicionales estándar si hace la solicitud menos de 24 horas antes de la salida del primer transporte de su reserva;</li>
      <li>Las condiciones generales de nuestros servicios adicionales específicos ya no son modificables una vez que su reserva esté finalizada y no hay posibilidad de excepción.</li>
    </ul>    
    
    <strong>2. Condiciones generales específicas de las tarifas de boletos y niveles de servicio en Tictactrip.</strong>
    
    Puede elegir una de las tarifas y opciones de servicios de boletos a continuación:
    
    <table style="max-width: 900px; width: 100%">
      <thead>
        <tr>
          <th title="Field #1">Nivel de servicio</th>
          <th title="Field #2">La buena oferta</th>
          <th title="Field #3">Zen &amp; Relax</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Correo electrónico de confirmación</td>
          <td style="text-align: center">incluido</td>
          <td style="text-align: center">incluido</td>
        </tr>
        <tr>
          <td>SMS de confirmación</td>
          <td style="text-align: center">no incluido</td>
          <td style="text-align: center">incluido</td>
        </tr>
        <tr>
          <td>Servicio al cliente</td>
          <td style="text-align: center">incluido</td>
          <td style="text-align: center">incluido</td>
        </tr>
        <tr>
          <td>Acceso prioritario al procesamiento de una solicitud de soporte</td>
          <td style="text-align: center">no incluido</td>
          <td style="text-align: center">incluido</td>
        </tr>
        <tr>
          <td>Acciones postventa (excluyendo cancelación y reembolso)</td>
          <td style="text-align: center">15€</td>
          <td style="text-align: center">10€</td>
        </tr>
        <tr>
          <td>Cancelación y reembolso</td>
          <td style="text-align: center">incluido</td>
          <td style="text-align: center">incluido</td>
        </tr>
        <tr>
    <td>Aceleración del proceso de cancelación y reembolso</td>
    <td style="text-align: center">10€</td>
    <td style="text-align: center">incluido</td>
</tr>
</tbody>
</table>

<strong>3. Condiciones generales de las tarifas de boletos comprados en Tictactrip</strong>

Tenga en cuenta que:
<ul>
    <li>Un billete puede estar sujeto a un aumento de tarifa por parte del transportista entre el momento de la reserva y el pago. Siempre se le notificará de este aumento.</li>
    <li>Las condiciones de cancelación y reembolso de los billetes son las de nuestras compañías de transporte asociadas. Por lo tanto, el reembolso no es posible en todos los casos.</li>
    <li>Siempre se le pedirá una confirmación de cancelación por correo electrónico. Se espera una respuesta en 24 horas. Pasado este plazo, tendremos en cuenta la solicitud inicial y su boleto se cancelará.</li>
    <li>Usted cuenta con un servicio al cliente para cualquier pregunta o solicitud de información sobre su reserva en las condiciones de la opción de servicio que haya seleccionado previamente.</li>
    <li>Las acciones postventa se refieren a cambios de fecha, nombre, ruta, la adición de pasajeros (excluyendo cancelación y reembolso).</li>
    <li>La aceleración del proceso de cancelación y reembolso permite organizar de manera prioritaria el procedimiento de cancelación y reembolso con nuestros socios. Dentro de las condiciones y el tiempo de procesamiento de los transportistas.</li>
</ul>
`,
    it: `L'acquisto del tuo biglietto con Tictactrip ti offre diversi livelli di servizio per essere assistito in base alle tue esigenze.

    Ti offriamo opzioni, appena hai effettuato la selezione di una di esse, ciò modificherà la portata e le condizioni della tua prenotazione, nonché il suo prezzo totale. Hai quindi un modo efficace per rispondere alle tue eventuali esigenze di flessibilità nella prenotazione. Queste opzioni possono essere arricchite con servizi legati ai viaggi, modificate o annullate attraverso i nostri servizi aggiuntivi e puoi anche valutare la necessità del trattamento prioritario delle tue richieste da parte del nostro servizio clienti.
    
    <strong>1. Opzioni di servizio per i biglietti Tictactrip.</strong>
    
    Come condizione preliminare per la conclusione della prenotazione e per l'esecuzione del contratto di servizio tra te e noi, ti sarà proposto, nell'ambito della procedura di prenotazione, di scegliere una delle opzioni di tariffe dei biglietti e dei servizi che ti proporremo. Ogni Tariffa del biglietto Tictactrip rappresenta l'insieme specifico di condizioni generali vincolanti in base alle quali ti forniamo assistenza clienti, servizi aggiuntivi e il relativo trattamento dei rimborsi per le modifiche/cambiamenti della prenotazione. (Di seguito i **servizi aggiuntivi specifici** "Zen & Relax"); altri servizi aggiuntivi specifici consistono nel ricevere, oltre all'e-mail di conferma, anche un SMS che conferma il percorso a venire, un accesso prioritario al servizio clienti, l'annullamento e il rimborso del biglietto secondo le condizioni dei trasportatori, l'accelerazione del processo nella richiesta di rimborso e annullamento di un biglietto, la modifica della prenotazione (esclusa l'annullamento e il rimborso) per 10€; altri Servizi aggiuntivi (Di seguito denominati i **Servizi aggiuntivi standard** "L'affare giusto"); sono inclusi nei servizi aggiuntivi standard la ricezione dell'e-mail di conferma, l'accesso al servizio clienti, l'annullamento e il rimborso del biglietto secondo le condizioni dei trasportatori, ti addebitiamo l'accelerazione del processo nella richiesta di rimborso e annullamento di un biglietto per 10€ e la modifica della prenotazione (esclusa l'annullamento e il rimborso) per 15€ per coprire le nostre spese di gestione.
    
    Si prega di notare che: le diverse controparti per la tariffa del biglietto selezionato saranno incluse nei costi dei nostri servizi nel totale del prezzo, come ti verrà chiaramente indicato prima della tua selezione finale della Tariffa del biglietto Tictactrip;
    
    <ul>
        <li>La selezione della tariffa del biglietto non modifica in alcun modo i nostri diritti e doveri reciproci ai sensi dei termini del contratto di servizio;</li>
        <li>Non è possibile modificare la selezione della tariffa del biglietto dopo aver effettuato la prenotazione. Tuttavia, puoi acquistare un'offerta di servizio soggetta a condizioni generali più favorevoli riguardanti il nostro servizio clienti o i nostri servizi aggiuntivi standard. Tieni sempre presente che potremmo non essere in grado di fornirti servizi aggiuntivi standard se ne fai richiesta meno di 24 ore prima della partenza del primo trasporto della tua prenotazione;</li>
        <li>Le condizioni generali delle nostre prestazioni di servizi aggiuntivi specifici non sono più modificabili una volta che la tua prenotazione è finalizzata e non esiste alcuna possibilità di derogare.</li>
    </ul>
    
    <strong>2. Condizioni generali specifiche delle tariffe e dei livelli di servizio presso Tictactrip.</strong>
    
    Puoi scegliere una delle tariffe e delle opzioni di servizio per i biglietti qui sotto:
    
    <table style="max-width: 900px; width: 100%">
        <thead>
            <tr>
                <th title="Campo #1">Livello di servizio</th>
                <th title="Campo #2">L'affare giusto</th>
                <th title="Campo #3">Zen & Relax</th>
            </tr>
            </thead>
    <tbody>
        <tr>
            <td>Email di conferma</td>
            <td style="text-align: center">incluso</td>
            <td style="text-align: center">incluso</td>
        </tr>
        <tr>
            <td>SMS di conferma</td>
            <td style="text-align: center">non incluso</td>
            <td style="text-align: center">incluso</td>
        </tr>
        <tr>
            <td>Servizio clienti</td>
            <td style="text-align: center">incluso</td>
            <td style="text-align: center">incluso</td>
        </tr>
        <tr>
            <td>Accesso prioritario al trattamento di una richiesta di supporto</td>
            <td style="text-align: center">non incluso</td>
            <td style="text-align: center">incluso</td>
        </tr>
        <tr>
            <td>Azioni post-vendita (esclusa annullamento e rimborso)</td>
            <td style="text-align: center">15€</td>
            <td style="text-align: center">10€</td>
        </tr>
        <tr>
            <td>Annullamento e rimborso</td>
            <td style="text-align: center">incluso</td>
            <td style="text-align: center">incluso</td>
        </tr>
        <tr>
            <td>Accelerazione del processo di annullamento e rimborso</td>
            <td style="text-align: center">10€</td>
            <td style="text-align: center">incluso</td>
        </tr>
    </tbody>
</table>

<strong>3. Condizioni generali dei biglietti acquistati presso Tictactrip</strong>

Si prega di notare che:
<ul>
    <li>Un biglietto può essere soggetto da parte del vettore a un aumento della tariffa tra il momento della prenotazione e del pagamento. Sarai sempre informato di questo aumento;</li>
    <li>Le condizioni di annullamento e rimborso dei biglietti sono quelle dei nostri partner di trasporto. Il rimborso non è quindi possibile in tutti i casi;</li>
    <li>Ti sarà sempre richiesta una conferma di annullamento via email. Una risposta è attesa entro 24 ore. Trascorso questo periodo, terremo conto della richiesta iniziale e il tuo biglietto sarà annullato;</li>
    <li>Hai accesso a un servizio clienti per tutte le domande o richieste di informazioni sulla tua prenotazione nelle condizioni dell'opzione di servizio che avrai selezionato in precedenza;</li>
    <li>Le azioni post-vendita riguardano i cambi di data, di nome, di percorso, l'aggiunta di passeggeri (esclusi annullamento e rimborso);</li>
    <li>L'accelerazione del processo di annullamento e rimborso consente di organizzare in modo prioritario la procedura di annullamento e rimborso con i nostri partner. Nei limiti delle condizioni e dei tempi di elaborazione dei vettori.</li>
</ul>

    `,
    de: `Beim Kauf Ihres Tickets bietet Ihnen Tictactrip verschiedene Servicelevels, um Ihren Anforderungen gerecht zu werden.

    Wir bieten Ihnen daher Optionen an. Sobald Sie die Auswahl einer dieser Optionen getroffen haben, ändert sich der Umfang und die Bedingungen Ihrer Reservierung sowie deren Gesamtpreis. Sie haben also eine effektive Möglichkeit, auf Ihre eventuellen Bedürfnisse hinsichtlich der Flexibilität bei der Reservierung zu reagieren. Diese können durch mit Reisen verbundene Dienstleistungen ergänzt, geändert oder mithilfe unserer zusätzlichen Dienstleistungen storniert werden. Sie können auch beurteilen, ob eine priorisierte Bearbeitung Ihrer Anfragen durch unseren Kundenservice notwendig ist.
    
    <strong>1. Serviceoptionen für Tictactrip-Tickets.</strong>
    
    Als Voraussetzung für den Abschluss der Reservierung und die Ausführung des Servicevertrags zwischen Ihnen und uns wird Ihnen im Rahmen des Reservierungsvorgangs eine der von uns angebotenen Tarif- und Serviceoptionen vorgeschlagen. Jedes Tictactrip-Ticket repräsentiert den spezifischen Satz verbindlicher Allgemeiner Geschäftsbedingungen, unter denen wir Ihnen Kundenservice, zusätzliche Dienstleistungen und die entsprechende Abwicklung von Rückerstattungen im Zusammenhang mit Änderungen der Reservierung bieten. (Im Folgenden die **spezifischen Zusatzleistungen** "Zen & Relax"); Zusätzliche spezifische Dienstleistungen umfassen den Erhalt einer SMS neben der Bestätigungs-E-Mail, eine SMS zur Bestätigung der bevorstehenden Reise, bevorzugten Zugang zum Kundenservice, Stornierung und Rückerstattung des Tickets gemäß den Bedingungen der Transportunternehmen, Beschleunigung des Prozesses bei der Anfrage von Rückerstattung und Stornierung eines Tickets, Änderung der Reservierung (außer Stornierung und Rückerstattung) für 10€. Weitere Zusatzleistungen (im Folgenden als **Standardzusatzleistungen** "Das gute Angebot" bezeichnet) sind im Preis der Standardzusatzleistungen enthalten: Empfang der Bestätigungsemail, Zugang zum Kundenservice, Stornierung und Rückerstattung des Tickets gemäß den Bedingungen der Transportunternehmen. Wir berechnen Ihnen die Beschleunigung des Prozesses bei der Anfrage von Rückerstattung und Stornierung eines Tickets für 10€ und die Änderung der Reservierung (außer Stornierung und Rückerstattung) für 15€, um unsere Verwaltungskosten zu decken.
    
    Bitte beachten Sie, dass die verschiedenen Gegenleistungen für die ausgewählte Ticketrate in den Kosten unserer Dienstleistungen im Gesamtpreis enthalten sind, wie Ihnen vor Ihrer endgültigen Auswahl des Tictactrip-Tickettarifs klar mitgeteilt wird;
    
    <ul>
        <li>Die Auswahl des Tickettarifs ändert nichts an unseren gegenseitigen Rechten und Pflichten gemäß den Bedingungen des Servicevertrags;</li>
        <li>Sie können Ihre Auswahl des Tickettarifs nach Abschluss Ihrer Reservierung nicht mehr ändern. Sie können jedoch ein Serviceangebot mit günstigeren Allgemeinen Geschäftsbedingungen für unseren Kundenservice oder unsere Standardzusatzleistungen kaufen. Beachten Sie bitte immer, dass wir Ihnen möglicherweise keine Standardzusatzleistungen anbieten können, wenn Sie diese weniger als 24 Stunden vor Abfahrt des ersten Transports Ihrer Reservierung anfordern;</li>
        <li>Die Allgemeinen Geschäftsbedingungen für unsere spezifischen Zusatzleistungen können nach Abschluss Ihrer Reservierung nicht mehr geändert werden, und es besteht keine Möglichkeit einer Ausnahme.</li>
    </ul>
    
    <strong>2. Spezifische Allgemeine Geschäftsbedingungen für Ticketpreise und Servicelevels bei Tictactrip.</strong>
    
    Sie können einen der folgenden Tickettarife und Serviceoptionen auswählen:
    
    <table style="max-width: 900px; width: 100%">
        <thead>
            <tr>
                <th title="Feld #1">Servicelevel</th>
                <th title="Feld #2">Das gute Angebot</th>
                <th title="Feld #3">Zen & Relax</th>
            </tr>
            </thead>
    <tbody>
        <tr>
            <td>Bestätigungs-E-Mail</td>
            <td style="text-align: center">inklusive</td>
            <td style="text-align: center">inklusive</td>
        </tr>
        <tr>
            <td>Bestätigungs-SMS</td>
            <td style="text-align: center">nicht inbegriffen</td>
            <td style="text-align: center">inklusive</td>
        </tr>
        <tr>
            <td>Kundenservice</td>
            <td style="text-align: center">inklusive</td>
            <td style="text-align: center">inklusive</td>
        </tr>
        <tr>
            <td>Priorisierter Zugang zur Bearbeitung einer Supportanfrage</td>
            <td style="text-align: center">nicht inbegriffen</td>
            <td style="text-align: center">inklusive</td>
        </tr>
        <tr>
            <td>Nachverkaufsaktionen (außer Stornierung und Rückerstattung)</td>
            <td style="text-align: center">15€</td>
            <td style="text-align: center">10€</td>
        </tr>
        <tr>
            <td>Stornierung und Rückerstattung</td>
            <td style="text-align: center">inklusive</td>
            <td style="text-align: center">inklusive</td>
        </tr>
        <tr>
            <td>Beschleunigung des Prozesses zur Stornierung und Rückerstattung</td>
            <td style="text-align: center">10€</td>
            <td style="text-align: center">inklusive</td>
        </tr>
    </tbody>
</table>

<strong>3. Allgemeine Geschäftsbedingungen für bei Tictactrip gekaufte Tickettarife</strong>

Bitte beachten Sie:
<ul>
    <li>Ein Ticket kann vom Transportunternehmen zwischen dem Zeitpunkt der Reservierung und der Zahlung einer Preiserhöhung unterliegen. Sie werden immer über diese Erhöhung informiert;</li>
    <li>Die Bedingungen für Stornierung und Rückerstattung von Tickets entsprechen denen unserer Transportpartner. Eine Rückerstattung ist daher nicht in allen Fällen möglich;</li>
    <li>Ihnen wird immer eine Stornierungsbestätigung per E-Mail angefordert. Eine Antwort wird innerhalb von 24 Stunden erwartet. Nach Ablauf dieser Frist berücksichtigen wir die ursprüngliche Anfrage, und Ihr Ticket wird storniert;</li>
    <li>Sie haben Zugang zu einem Kundenservice für alle Fragen oder Informationsanfragen zu Ihrer Reservierung unter den Bedingungen der zuvor ausgewählten Serviceoption;</li>
    <li>Die Nachverkaufsaktionen betreffen Änderungen von Datum, Name, Route sowie das Hinzufügen von Passagieren (ausgeschlossen Stornierung und Rückerstattung);</li>
    <li>Die Beschleunigung des Prozesses zur Stornierung und Rückerstattung ermöglicht eine vorrangige Organisation des Stornierungs- und Rückerstattungsverfahrens bei unseren Partnern. Dies erfolgt innerhalb der Bedingungen und Bearbeitungszeiten der Transportunternehmen.</li>
</ul>

    `,
    nl: `Bij het kopen van uw ticket biedt Tictactrip verschillende serviceniveaus aan om aan uw behoeften te voldoen.

    Wij bieden u daarom opties aan. Zodra u een van deze opties heeft geselecteerd, zal dit de reikwijdte en de voorwaarden van uw reservering en de totale prijs wijzigen. U heeft dus een effectieve manier om te reageren op uw eventuele behoeften met betrekking tot de flexibiliteit van uw reservering. Deze kunnen worden aangevuld met reisgerelateerde diensten, gewijzigd of geannuleerd met behulp van onze aanvullende services. U kunt ook beoordelen of het noodzakelijk is om de prioritaire behandeling van uw verzoeken door onze klantenservice te overwegen.
    
    <strong>1. Serviceopties voor Tictactrip-tickets.</strong>
    
    Als voorwaarde voor het voltooien van de reservering en de uitvoering van het servicecontract tussen u en ons, wordt u tijdens het reserveringsproces gevraagd een van de tarief- en serviceopties te kiezen die wij aanbieden. Elk Tictactrip-ticket vertegenwoordigt de specifieke reeks bindende algemene voorwaarden waaronder wij u klantenservice, aanvullende diensten en de verwerking van terugbetalingen bieden die overeenkomen met wijzigingen in de reservering. (Hierna de **specifieke aanvullende services** "Zen & Relax"); Specifieke aanvullende diensten omvatten het ontvangen van een sms naast de bevestigingsmail, een sms ter bevestiging van de aankomende reis, voorrangstoegang tot de klantenservice, annulering en terugbetaling van het ticket volgens de voorwaarden van de vervoerders, versnelling van het proces bij het aanvragen van terugbetaling en annulering van een ticket, wijziging van de reservering (uitgezonderd annulering en terugbetaling) voor 10€; andere standaard aanvullende services (hierna aangeduid als de **standaard aanvullende services** "Het goede aanbod"); zijn inbegrepen in de standaard aanvullende services: ontvangst van de bevestigingsmail, toegang tot de klantenservice, annulering en terugbetaling van het ticket volgens de voorwaarden van de vervoerders, wij brengen u kosten in rekening voor het versnellen van het proces bij het aanvragen van terugbetaling en annulering van een ticket voor 10€ en wijziging van de reservering (uitgezonderd annulering en terugbetaling) voor 15€ om onze beheerskosten te dekken.
    
    Houd er rekening mee dat: de verschillende tegenprestaties voor het geselecteerde tickettarief zijn opgenomen in de kosten van onze diensten in de totale prijs, zoals duidelijk aan u wordt meegedeeld voor uw uiteindelijke selectie van het Tictactrip-tickettarief;
    
    <ul>
        <li>De selectie van het tickettarief verandert niets aan onze wederzijdse rechten en verplichtingen volgens de voorwaarden van het servicecontract;</li>
        <li>U kunt uw selectie van het tickettarief niet wijzigen nadat u uw reservering heeft gemaakt. U kunt echter een serviceaanbieding kopen onder gunstigere algemene voorwaarden voor onze klantenservice of onze standaard aanvullende diensten. Houd er altijd rekening mee dat we mogelijk geen standaard aanvullende diensten kunnen leveren als u deze minder dan 24 uur voor het vertrek van het eerste vervoermiddel van uw reservering aanvraagt;</li>
        <li>De algemene voorwaarden van onze specifieke aanvullende diensten kunnen niet meer worden gewijzigd nadat uw reservering is voltooid, en er is geen mogelijkheid om hiervan af te wijken.</li>
    </ul>
    
    <strong>2. Specifieke algemene voorwaarden voor tickettarieven en serviceniveaus bij Tictactrip.</strong>
    
    U kunt een van de onderstaande tarieven en ticketopties kiezen:
    
    <table style="max-width: 900px; width: 100%">
        <thead>
            <tr>
                <th title="Veld #1">Serviceniveau</th>
                <th title="Veld #2">Het goede aanbod</th>
                <th title="Veld #3">Zen & Relax</th>
            </tr>
            </thead>
    <tbody>
        <tr>
            <td>E-mailbevestiging</td>
            <td style="text-align: center">inbegrepen</td>
            <td style="text-align: center">inbegrepen</td>
        </tr>
        <tr>
            <td>SMS-bevestiging</td>
            <td style="text-align: center">niet inbegrepen</td>
            <td style="text-align: center">inbegrepen</td>
        </tr>
        <tr>
            <td>Klantenservice</td>
            <td style="text-align: center">inbegrepen</td>
            <td style="text-align: center">inbegrepen</td>
        </tr>
        <tr>
            <td>Voorrangstoegang tot de verwerking van een ondersteuningsverzoek</td>
            <td style="text-align: center">niet inbegrepen</td>
            <td style="text-align: center">inbegrepen</td>
        </tr>
        <tr>
            <td>Naverkoopacties (uitgezonderd annulering en terugbetaling)</td>
            <td style="text-align: center">15€</td>
            <td style="text-align: center">10€</td>
        </tr>
        <tr>
            <td>Annulering en terugbetaling</td>
            <td style="text-align: center">inbegrepen</td>
            <td style="text-align: center">inbegrepen</td>
        </tr>
        <tr>
            <td>Versnelling van het proces van annulering en terugbetaling</td>
            <td style="text-align: center">10€</td>
            <td style="text-align: center">inbegrepen</td>
        </tr>
    </tbody>
</table>

<strong>3. Algemene voorwaarden voor bij Tictactrip gekochte tickettarieven</strong>

Gelieve op te merken:
<ul>
    <li>Een ticket kan door de vervoerder worden onderworpen aan een tariefstijging tussen het moment van reservering en betaling. U wordt altijd op de hoogte gesteld van deze verhoging;</li>
    <li>De annulerings- en terugbetalingsvoorwaarden van tickets zijn die van onze partnervervoerders. Terugbetaling is dus niet in alle gevallen mogelijk;</li>
    <li>U wordt altijd gevraagd om een annuleringsbevestiging per e-mail. Een antwoord wordt binnen 24 uur verwacht. Na deze termijn houden we rekening met het oorspronkelijke verzoek, en uw ticket wordt geannuleerd;</li>
    <li>U heeft toegang tot klantenservice voor alle vragen of informatieverzoeken met betrekking tot uw reservering onder de voorwaarden van de eerder geselecteerde serviceoptie;</li>
    <li>De naverkoopacties betreffen wijzigingen van datum, naam, route, toevoeging van passagiers (uitgezonderd annulering en terugbetaling);</li>
    <li>De versnelling van het proces van annulering en terugbetaling stelt ons in staat om het annulerings- en terugbetalingsproces bij onze partners met voorrang te organiseren. Dit binnen de voorwaarden en verwerkingstijden van de vervoerders.</li>
</ul>
    `,
  },
  termsTitle4: {
    id: `${termsOfSalesScope}.termsTitle4`,
    defaultMessage: '4. Responsabilité',
    en: '4. Liability',
    es: '4. Responsabilidad',
    it: '4. Responsabilità',
    de: '4. Haftung',
    nl: '4. Aansprakelijkheid',
  },

  fourthContentText1: {
    id: `${termsOfSalesScope}.fourthContentText1`,
    defaultMessage:
      'Les services de transport commandés depuis notre service de réservation vous seront procurés par les Opérateurs (nos compagnies de transport partenaires). Les conditions générales de ces opérateurs s’appliqueront et encadreront votre utilisation de leur service de transport une fois vos Titres de transports commandés et ce, même depuis notre service de réservation. Nous vous recommandons donc de vous informer des conditions générales de l’Opérateur pertinent pour votre voyage avant de réserver vos titres de transport.',
    en: `
      The transport services ordered through our booking service will be provided by the Operators (our partner transport companies). The terms and conditions of these operators will apply and govern your use of their transport service once your transport tickets are ordered, even through our booking service. We recommend that you familiarize yourself with the general terms and conditions of the relevant Operator for your journey before booking your transport tickets.

      You are informed that you will be financially responsible for your purchases and order(s) made through our booking service and your account. Also, for any reasonable and foreseeable losses that Tictactrip may incur solely due to your violation of our General Terms and Conditions, or due to your negligence when using our platform and booking service (including if you deliberately or negligently allow others to use your account on your behalf).

      Tictactrip acts as a travel agency, on behalf of its partner transport companies. Tictactrip does not provide any transport services but only a comparison, combination, and booking service.`,
    es: `
      Los servicios de transporte solicitados a través de nuestro servicio de reserva serán proporcionados por los Operadores (nuestras compañías de transporte asociadas). Los términos y condiciones de estos operadores se aplicarán y regirán su uso del servicio de transporte una vez que se hayan solicitado sus títulos de transporte, incluso a través de nuestro servicio de reserva. Le recomendamos que se informe sobre los términos y condiciones generales del Operador relevante para su viaje antes de reservar sus títulos de transporte.

      Se le informa que será responsable financieramente de sus compras y pedidos realizados a través de nuestro servicio de reserva y su cuenta. Además, de cualquier pérdida razonable y previsible que Tictactrip pueda sufrir solo debido a su violación de nuestras Condiciones Generales, o debido a su negligencia al usar nuestra plataforma y servicio de reserva (incluido si permite deliberada o negligentemente que otras personas usen su cuenta en su nombre).

      Tictactrip actúa como una agencia de viajes, en nombre de sus compañías de transporte asociadas. Tictactrip no proporciona servicios de transporte sino solo un servicio de comparación, combinación y reserva.`,
    it: `
      I servizi di trasporto ordinati attraverso il nostro servizio di prenotazione saranno forniti dagli Operatori (le nostre compagnie di trasporto partner). I termini e le condizioni di questi operatori si applicheranno e disciplineranno l'uso del loro servizio di trasporto una volta che i vostri titoli di trasporto saranno ordinati, anche attraverso il nostro servizio di prenotazione. Vi consigliamo di informarvi sui termini e le condizioni generali dell'Operatore pertinente per il vostro viaggio prima di prenotare i titoli di trasporto.

      Siete informati che sarete finanziariamente responsabili per i vostri acquisti e ordini effettuati attraverso il nostro servizio di prenotazione e il vostro account. Inoltre, per qualsiasi perdita ragionevole e prevedibile che Tictactrip potrebbe subire unicamente a causa della vostra violazione delle nostre Condizioni Generali, o a causa della vostra negligenza nell'uso della nostra piattaforma e del nostro servizio di prenotazione (anche se avete deliberatamente o negligentemente permesso ad altre persone di utilizzare il vostro account per conto vostro).

      Tictactrip agisce come agenzia di viaggi, per conto delle sue compagnie di trasporto partner. Tictactrip non fornisce servizi di trasporto ma solo un servizio di confronto, combinazione e prenotazione.`,
    nl: `
      De transportdiensten die zijn besteld via onze boekingsservice worden verzorgd door de Operators (onze partnervervoersmaatschappijen). De algemene voorwaarden van deze operators zijn van toepassing en regelen uw gebruik van hun transportdienst zodra uw vervoerstickets zijn besteld, zelfs via onze boekingsservice. We raden u daarom aan om op de hoogte te zijn van de algemene voorwaarden van de relevante Operator voor uw reis voordat u uw vervoerstickets boekt.

      U wordt ervan op de hoogte gesteld dat u financieel verantwoordelijk zult zijn voor uw aankopen en bestellingen die zijn gedaan via onze boekingsservice en uw account. Ook voor eventuele redelijke en voorzienbare verliezen die Tictactrip zou kunnen lijden uitsluitend als gevolg van uw schending van onze Algemene Voorwaarden, of als gevolg van uw nalatigheid bij het gebruik van ons platform en onze boekingsservice (inclusief als u opzettelijk of nalatig anderen toestaat uw account namens u te gebruiken).

      Tictactrip treedt op als reisbureau, namens zijn partnervervoersmaatschappijen. Tictactrip biedt dan ook geen vervoersdiensten aan, maar uitsluitend een vergelijkings-, combinatie- en boekingsservice.`,
  },
  termsTitle5: {
    id: `${termsOfSalesScope}.termsTitle5`,
    defaultMessage: '5. Droit de rétractation',
    en: '5. Right of Withdrawal',
    es: '5. Derecho de desistimiento',
    it: '5. Diritto di recesso',
    de: '5. Widerrufsrecht',
    nl: '5. Herroepingsrecht',
  },
  fiveContentText1: {
    id: `${termsOfSalesScope}.fiveContentText1`,
    defaultMessage:
      'Il vous est communiqué qu’en vertu de la législation applicable aux consommateurs, les Titres de transport que nous vendons ne sont en aucun cas soumis à l’utilisation du droit de rétractation.',
    en: 'It is communicated to you that, under the applicable consumer legislation, the Transport Tickets we sell are in no way subject to the use of the right of withdrawal.',
    es: 'Se le informa que, según la legislación aplicable a los consumidores, los Títulos de transporte que vendemos no están en modo alguno sujetos al uso del derecho de desistimiento.',
    it: "Si comunica che, ai sensi della legislazione applicabile ai consumatori, i Titoli di trasporto che vendiamo non sono in alcun modo soggetti all'uso del diritto di recesso.",
    de: 'Es wird Ihnen mitgeteilt, dass gemäß der geltenden Verbrauchergesetzgebung die von uns verkauften Transporttickets in keiner Weise dem Widerrufsrecht unterliegen.',
    nl: 'U wordt meegedeeld dat volgens de toepasselijke consumentenwetgeving de door ons verkochte Vervoerstickets op geen enkele manier onderhevig zijn aan het recht op herroeping.',
  },
  termsTitle6: {
    id: `${termsOfSalesScope}.termsTitle6`,
    defaultMessage: '6. Liens Hypertexte',
    en: '6. Hyperlinks',
    es: '6. Hipervínculos',
    it: '6. Collegamenti ipertestuali',
    de: '6. Hyperlinks',
    nl: '6. Hyperlinks',
  },
  sixContentText1: {
    id: `${termsOfSalesScope}.sixContentText1`,
    defaultMessage:
      'Le site de Tictactrip contient des liens hypertextes à destination d’autres sites internet et applications de tiers. Ces sites internet ne sont dans un aucun cas, sous le contrôle et la responsabilité de Tictactrip. Les contenus de ces sites n’engagent alors en rien la responsabilité de Tictactrip, qui met en avant ces liens pour convenance. Un lien n’implique en aucun cas, que Tictactrip recommande, soit affilié ou parraine le(s) site(s) en question.',
    en: 'The Tictactrip site contains hyperlinks to other websites and third-party applications. These websites are in no way under the control and responsibility of Tictactrip. The contents of these sites do not, therefore, engage the responsibility of Tictactrip, which highlights these links for convenience. A link in no way implies that Tictactrip recommends, is affiliated with, or sponsors the site(s) in question.',
    es: 'El sitio de Tictactrip contiene hipervínculos a otros sitios web y aplicaciones de terceros. Estos sitios web no están en modo alguno bajo el control y la responsabilidad de Tictactrip. El contenido de estos sitios no compromete en modo alguno la responsabilidad de Tictactrip, que destaca estos enlaces por conveniencia. Un enlace no implica de ninguna manera que Tictactrip recomiende, esté afiliado o patrocine el(los) sitio(s) en cuestión.',
    it: 'Il sito Tictactrip contiene collegamenti ipertestuali ad altri siti web e applicazioni di terze parti. Questi siti web non sono in alcun modo sotto il controllo e la responsabilità di Tictactrip. I contenuti di questi siti non impegnano, quindi, la responsabilità di Tictactrip, che evidenzia questi collegamenti per convenienza. Un collegamento non implica in alcun modo che Tictactrip raccomandi, sia affiliato o sponsorizzi il(i) sito(i) in questione.',
    de: 'Die Tictactrip-Website enthält Hyperlinks zu anderen Websites und Anwendungen von Drittanbietern. Diese Websites stehen in keiner Weise unter der Kontrolle und Verantwortung von Tictactrip. Die Inhalte dieser Websites verpflichten Tictactrip daher in keiner Weise, die diese Links aus Bequemlichkeit hervorhebt. Ein Link bedeutet in keiner Weise, dass Tictactrip die betreffende(n) Website(s) empfiehlt, damit verbunden ist oder sponsert.',
    nl: 'De Tictactrip-site bevat hyperlinks naar andere websites en applicaties van derden. Deze websites vallen op geen enkele manier onder de controle en verantwoordelijkheid van Tictactrip. De inhoud van deze sites verbindt Tictactrip dan ook op geen enkele manier, wat deze links voor het gemak benadrukt. Een link impliceert op geen enkele manier dat Tictactrip de betreffende site(s) aanbeveelt, erbij is aangesloten of sponsort.',
  },
  termsTitle7: {
    id: `${termsOfSalesScope}.termsTitle7`,
    defaultMessage: '7. Droit applicable',
    en: '7. Applicable Law',
    es: '7. Ley Aplicable',
    it: '7. Legge Applicabile',
    de: '7. Anwendbares Recht',
    nl: '7. Toepasselijk Recht',
  },

  sevenContentText1: {
    id: `${termsOfSalesScope}.sevenContentText1`,
    defaultMessage:
      'Les Conditions Générales ici présentes, sont soumises au droit français, sans préjudice des dispositions impératives de la loi du pays du domicile du consommateur.',
    en: "These General Terms and Conditions are subject to French law, without prejudice to the mandatory provisions of the law of the consumer's country of residence.",
    es: 'Estas Condiciones Generales están sujetas a la ley francesa, sin perjuicio de las disposiciones imperativas de la ley del país de residencia del consumidor.',
    it: 'Queste Condizioni Generali sono soggette alla legge francese, fatti salvi i provvedimenti imperativi della legge del paese di residenza del consumatore.',
    de: 'Diese Allgemeinen Geschäftsbedingungen unterliegen französischem Recht, ohne dass die zwingenden Bestimmungen des Rechts des Wohnsitzlandes des Verbrauchers beeinträchtigt werden.',
    nl: 'Deze Algemene Voorwaarden vallen onder het Franse recht, onverminderd de dwingende bepalingen van de wet van het land van de consumentenwoonplaats.',
  },
  termsTitle8: {
    id: `${termsOfSalesScope}.termsTitle8`,
    defaultMessage: '8. Litiges',
    en: '8. Disputes',
    es: '8. Disputas',
    it: '8. Controversie',
    de: '8. Streitigkeiten',
    nl: '8. Geschillen',
  },
  heightContentText1: {
    id: `${termsOfSalesScope}.heightContentText1`,
    defaultMessage: `Tous litiges qui pourraient avoir lieu, en lien avec l’utilisation du site de Tictactrip et par rapport aux conditions général de ce même site, concernant, leur validité, exécution, résiliation, conséquences et répercussions, devront être dans un premier temps communiquer directement à l’équipe de Tictactrip afin d’y trouver une solution amiable.
    Dans le cas où aucune solution ne serait trouvée, le litige sera présenté devant les tribunaux compétents dans les conditions du droit commun.`,
    en: `Any disputes that may arise in connection with the use of the Tictactrip website and regarding the general conditions of this website, concerning their validity, execution, termination, consequences, and repercussions, must first be communicated directly to the Tictactrip team to find an amicable solution.
  In the event that no solution is found, the dispute will be brought before the competent courts in accordance with common law.`,
    es: `Cualquier disputa que pueda surgir en relación con el uso del sitio web de Tictactrip y en relación con las condiciones generales de este mismo sitio web, en lo que respecta a su validez, ejecución, terminación, consecuencias y repercusiones, deberá comunicarse primero directamente al equipo de Tictactrip para encontrar una solución amistosa.
  En caso de que no se encuentre ninguna solución, la disputa se presentará ante los tribunales competentes de acuerdo con el derecho común.`,
    it: `Eventuali dispute che potrebbero sorgere in relazione all'uso del sito Tictactrip e riguardo alle condizioni generali dello stesso sito, riguardanti la loro validità, esecuzione, risoluzione, conseguenze e ripercussioni, devono prima essere comunicate direttamente al team di Tictactrip per trovare una soluzione amichevole.
  Nel caso in cui non si trovi alcuna soluzione, la controversia sarà portata davanti ai tribunali competenti secondo le norme di diritto comune.`,
    de: `Alle Streitigkeiten, die im Zusammenhang mit der Nutzung der Tictactrip-Website und in Bezug auf die allgemeinen Bedingungen dieser Website, hinsichtlich ihrer Gültigkeit, Ausführung, Kündigung, Folgen und Auswirkungen auftreten können, müssen zunächst direkt dem Team von Tictactrip mitgeteilt werden, um eine einvernehmliche Lösung zu finden.
  Wenn keine Lösung gefunden wird, wird der Streit vor den zuständigen Gerichten gemäß dem allgemeinen Recht vorgelegt.`,
    nl: `Alle geschillen die zouden kunnen ontstaan in verband met het gebruik van de Tictactrip-website en met betrekking tot de algemene voorwaarden van deze website, met betrekking tot hun geldigheid, uitvoering, beëindiging, gevolgen en repercussies, moeten in eerste instantie rechtstreeks aan het Tictactrip-team worden gemeld om een vriendelijke oplossing te vinden.
  Indien er geen oplossing wordt gevonden, zal het geschil worden voorgelegd aan de bevoegde rechtbanken overeenkomstig het gemeen recht.`,
  },

  termsTitle9: {
    id: `${termsOfSalesScope}.termsTitle9`,
    defaultMessage: '9. Données et preuves ',
    en: '9. Data and Evidence',
    es: '9. Datos y Pruebas',
    it: '9. Dati e Prove',
    de: '9. Daten und Beweise',
    nl: '9. Gegevens en Bewijs',
  },
  nineContentText1: {
    id: `${termsOfSalesScope}.nineContentText1`,
    defaultMessage:
      'Il est expressément décrit que les données que Tictactrip détient au sein de ses systèmes d’information ont caractère de force probante par rapport aux achats et commandes passées, demandes, et tout autres cas de figures en relation à l’utilisation même du site de Tictactrip. Ces données pourront alors être réutilisées en tant que moyens de preuves et ce, notamment en justice, au même titre que tout document écrit.',
    en: 'It is expressly stated that the data that Tictactrip holds within its information systems have probative value with regard to purchases and orders placed, requests, and any other cases related to the use of the Tictactrip site. This data can then be reused as a means of evidence, particularly in court, on an equal footing with any written document.',
    es: 'Se establece expresamente que los datos que Tictactrip posee dentro de sus sistemas de información tienen carácter de fuerza probatoria con respecto a las compras y pedidos realizados, solicitudes y cualquier otro caso relacionado con el uso del sitio de Tictactrip. Estos datos pueden ser reutilizados como medios de prueba, especialmente en la corte, al igual que cualquier documento escrito.',
    it: "Si specifica espressamente che i dati detenuti da Tictactrip all'interno dei suoi sistemi informativi hanno valore probatorio per quanto riguarda gli acquisti e gli ordini effettuati, le richieste e qualsiasi altro caso legato all'uso del sito di Tictactrip. Questi dati possono quindi essere riutilizzati come mezzi di prova, in particolare in tribunale, alla pari di qualsiasi documento scritto.",
    de: 'Es wird ausdrücklich darauf hingewiesen, dass die Daten, die Tictactrip in seinen Informationssystemen hat, einen Beweiswert für getätigte Einkäufe und Bestellungen, Anfragen und alle anderen Fälle im Zusammenhang mit der Nutzung der Tictactrip-Website haben. Diese Daten können dann als Beweismittel wiederverwendet werden, insbesondere vor Gericht, ebenso wie jedes schriftliche Dokument.',
    nl: 'Het wordt uitdrukkelijk vermeld dat de gegevens die Tictactrip in zijn informatiesystemen heeft, bewijskracht hebben met betrekking tot aankopen en bestellingen, verzoeken en alle andere gevallen met betrekking tot het gebruik van de Tictactrip-website. Deze gegevens kunnen dan worden hergebruikt als bewijsmiddel, met name in de rechtbank, op gelijke voet met elk schriftelijk document.',
  },
  ODbL: {
    id: `${termsOfSalesScope}.ODbL`,
    defaultMessage: `
      Tictactrip utilise les versions des jeux de données communiqués par les producteurs suivants :
        - Région Provence-Alpes-Côte-d’Azur
      Ces données sont disponibles sur transport.data.gouv.fr sous la licence « Open Database Licence » (ODbL).`,
    en: `
    Tictactrip uses versions of datasets provided by the following producers:
      - Région Provence-Alpes-Côte-d'Azur
    These data are available on transport.data.gouv.fr under the "Open Database Licence" (ODbL).`,
    es: `
    Tictactrip utiliza versiones de conjuntos de datos proporcionados por los siguientes productores:
      - Région Provence-Alpes-Côte-d'Azur
    Estos datos están disponibles en transport.data.gouv.fr bajo la "Open Database Licence" (ODbL).`,
    it: `
    Tictactrip utilizza versioni di set di dati fornite dai seguenti produttori:
      - Région Provence-Alpes-Côte-d'Azur
    Questi dati sono disponibili su transport.data.gouv.fr sotto la "Open Database Licence" (ODbL).`,
    de: `
    Tictactrip verwendet Versionen von Datensätzen, die von den folgenden Produzenten bereitgestellt werden:
      - Région Provence-Alpes-Côte-d'Azur
    Diese Daten sind auf transport.data.gouv.fr unter der "Open Database Licence" (ODbL) verfügbar.`,
    nl: `
    Tictactrip gebruikt versies van datasets die worden geleverd door de volgende producenten:
      - Région Provence-Alpes-Côte-d'Azur
    Deze gegevens zijn beschikbaar op transport.data.gouv.fr onder de "Open Database Licence" (ODbL).`,
  },
  termsTitle10: {
    id: `${termsOfSalesScope}.termsTitle10`,
    defaultMessage: '10. Acceptation des conditions générales',
    en: '10. Acceptance of the General Terms and Conditions',
    es: '10. Aceptación de las Condiciones Generales',
    it: '10. Accettazione delle Condizioni Generali',
    de: '10. Akzeptanz der Allgemeinen Geschäftsbedingungen',
    nl: '10. Acceptatie van de Algemene Voorwaarden',
  },

  tenContentText1: {
    id: `${termsOfSalesScope}.tenContentText1`,
    defaultMessage: ` Vous reconnaissez avoir eu connaissance au préalable de votre achat, des présentes Conditions Générales de vente, et ce, de manière lisible et compréhensible.
    Le fait pour toute personne de commander sur le site de Tictactrip vaut acceptation complète des présentes Conditions Générales de vente et obligation du paiement de la commande.
    Les Conditions Générales peuvent être amenées à changer. La version qui sera applicable à votre commande sera celle en vigueur à la date de votre achat. `,
    en: 'You acknowledge that you have prior knowledge of these General Terms of Sale, in a readable and understandable manner, before making your purchase. The act of ordering on the Tictactrip website constitutes full acceptance of these General Terms of Sale and an obligation to pay for the order. The General Terms and Conditions may be subject to change. The version applicable to your order will be the one in effect on the date of your purchase.',
    es: 'Reconoce haber tenido conocimiento previo de su compra, de las presentes Condiciones Generales de Venta, de manera legible y comprensible. El acto de realizar un pedido en el sitio web de Tictactrip implica la aceptación completa de las presentes Condiciones Generales de Venta y la obligación de pagar el pedido. Las Condiciones Generales pueden estar sujetas a cambios. La versión aplicable a su pedido será la que esté en vigor en la fecha de su compra.',
    it: "Riconosci di aver avuto conoscenza preventiva del tuo acquisto, delle presenti Condizioni Generali di Vendita, in modo leggibile e comprensibile. Il fatto che una persona effettui un ordine sul sito di Tictactrip costituisce accettazione completa delle presenti Condizioni Generali di Vendita e obbligo di pagamento dell'ordine. Le Condizioni Generali possono essere soggette a modifiche. La versione applicabile al tuo ordine sarà quella in vigore alla data del tuo acquisto.",
    de: 'Sie erkennen an, dass Sie im Voraus von Ihrem Kauf Kenntnis genommen haben, von den vorliegenden Allgemeinen Verkaufsbedingungen, auf eine lesbare und verständliche Weise. Die Bestellung auf der Tictactrip-Website gilt als vollständige Akzeptanz dieser Allgemeinen Verkaufsbedingungen und Verpflichtung zur Zahlung der Bestellung. Die Allgemeinen Geschäftsbedingungen können Änderungen unterliegen. Die Version, die für Ihre Bestellung gilt, ist diejenige, die zum Zeitpunkt Ihres Kaufs in Kraft ist.',
    nl: 'U erkent dat u voorafgaand aan uw aankoop op de hoogte bent gesteld van deze Algemene Verkoopvoorwaarden, op een leesbare en begrijpelijke manier. Het plaatsen van een bestelling op de Tictactrip-website houdt in dat u de volledige aanvaarding van deze Algemene Verkoopvoorwaarden en de verplichting om te betalen voor de bestelling impliceert. De Algemene Voorwaarden kunnen onderhevig zijn aan wijzigingen. De versie die van toepassing is op uw bestelling, is degene die van kracht is op de datum van uw aankoop.',
  },
  termsTitle11: {
    id: `${termsOfSalesScope}.termsTitle11`,
    defaultMessage: '11. Propriété intellectuelle',
    en: '11. Intellectual Property',
    es: '11. Propiedad Intelectual',
    it: '11. Proprietà Intellettuale',
    de: '11. Geistiges Eigentum',
    nl: '11. Intellectueel Eigendom',
  },
  elevenContentText1: {
    id: `${termsOfSalesScope}.elevenContentText1`,
    defaultMessage:
      'L’accès au site ne vous donne aucun droit de propriété intellectuelle par rapport au présent site de Tictactrip. Vous n’avez donc aucun droit de modifier, représenter, transmettre, reproduire ou encore publier dans quelque cas de figure et par quelque façon que ce soit, tout ou partie du présent site de Tictactrip. Le fait de déroger à cela, pourrait être l’objet d’un recours ou d’action en contrefaçon. L’usage de n’importe quel système ou logiciel utilisé (scrapping ou connexion direct à notre API) dans le but d’extraire des données purement appartenantes à Tictactrip, est interdit sauf autorisation écrite préalable de Tictactrip.',
    en: 'Access to the site does not give you any intellectual property rights with respect to the Tictactrip site. You therefore have no right to modify, represent, transmit, reproduce, or publish in any case and in any way whatsoever, all or part of the Tictactrip site. The violation of this could be the subject of legal action or infringement proceedings. The use of any system or software (scraping or direct connection to our API) with the aim of extracting data purely belonging to Tictactrip is prohibited without prior written authorization from Tictactrip.',
    es: 'El acceso al sitio no le otorga ningún derecho de propiedad intelectual con respecto al sitio de Tictactrip. Por lo tanto, no tiene derecho a modificar, representar, transmitir, reproducir o publicar en ningún caso y de ninguna manera, todo o parte del sitio de Tictactrip. El incumplimiento de esto podría ser objeto de una acción legal o de un proceso por infracción. El uso de cualquier sistema o software (scraping o conexión directa a nuestra API) con el objetivo de extraer datos puramente pertenecientes a Tictactrip está prohibido sin la autorización previa por escrito de Tictactrip.',
    it: "L'accesso al sito non ti conferisce alcun diritto di proprietà intellettuale rispetto al sito di Tictactrip. Pertanto, non hai il diritto di modificare, rappresentare, trasmettere, riprodurre o pubblicare in nessun caso e in nessun modo, tutto o parte del sito di Tictactrip. La violazione di ciò potrebbe essere oggetto di azioni legali o procedimenti per violazione. L'uso di qualsiasi sistema o software (scraping o connessione diretta alla nostra API) con lo scopo di estrarre dati appartenenti esclusivamente a Tictactrip è vietato senza l'autorizzazione scritta preventiva di Tictactrip.",
    de: 'Der Zugriff auf die Website verleiht Ihnen keine geistigen Eigentumsrechte an der Tictactrip-Website. Sie haben daher kein Recht, Teile oder das gesamte Material dieser Tictactrip-Website in irgendeiner Weise zu modifizieren, darzustellen, zu übertragen, zu reproduzieren oder zu veröffentlichen. Ein Verstoß dagegen könnte zu rechtlichen Schritten oder Klagen wegen Urheberrechtsverletzung führen. Die Verwendung von Systemen oder Software (Scraping oder direkte Verbindung zu unserer API), um ausschließlich Tictactrip gehörende Daten zu extrahieren, ist ohne vorherige schriftliche Genehmigung von Tictactrip untersagt.',
    nl: 'Toegang tot de website verleent u geen intellectuele eigendomsrechten met betrekking tot de Tictactrip-website. U heeft dus geen recht om delen of het geheel van de Tictactrip-website op enigerlei wijze te wijzigen, te tonen, over te dragen, te reproduceren of te publiceren. Een schending hiervan kan leiden tot juridische stappen of procedures wegens inbreuk op het auteursrecht. Het gebruik van elk systeem of software (scraping of rechtstreekse verbinding met onze API) om uitsluitend tot Tictactrip behorende gegevens te extraheren, is verboden zonder voorafgaande schriftelijke toestemming van Tictactrip.',
  },
};

export default defineMessages(termsOfSalesMessages);
