import { fromJS } from 'immutable';
import {
  CURRENT_SLIDE,
  DATA,
  LOCKED,
  NEXT_SLIDE,
  PREVIOUS_SLIDE,
  SET_CURRENT_SLIDE,
  SET_DATA,
  UNLOCK,
} from './constants';

export const initialState = fromJS({
  [CURRENT_SLIDE]: 0,
  [DATA]: [],
  [LOCKED]: false,
});

function largeTravelSliderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return state.set(DATA, fromJS(action.data));
    case NEXT_SLIDE:
      return state
        .set(LOCKED, true)
        .set(
          CURRENT_SLIDE,
          (state.get(CURRENT_SLIDE) + 1) % state.get(DATA).size,
        );
    case PREVIOUS_SLIDE:
      return state
        .set(LOCKED, true)
        .set(
          CURRENT_SLIDE,
          (state.get(CURRENT_SLIDE) + (state.get(DATA).size - 1)) %
            state.get(DATA).size,
        );
    case UNLOCK:
      return state.set(LOCKED, false);
    case SET_CURRENT_SLIDE:
      return state.set(CURRENT_SLIDE, action.next);
    default:
      return state;
  }
}

export default largeTravelSliderReducer;
