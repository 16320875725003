/*
 * Results Messages
 *
 * This contains all the text for the Results container.
 */

import { defineMessages } from 'react-intl';

export const ratingScope = 'app.components.Rating';

export const ratingMessages = {
  ratingTitle: {
    id: `${ratingScope}.ratingTitle`,
    defaultMessage: 'Satisfaction',
    en: 'Satisfacción',
    es: 'Satisfacción',
    it: 'Soddisfazione',
    de: 'Zufriedenheit',
    nl: 'Tevredenheid',
  },
  ratingSubTitle: {
    id: `${ratingScope}.ratingSubTitle`,
    defaultMessage: 'Vous pouvez noter notre service !',
    en: 'You can rate our service!',
    es: 'Puede valorar nuestro servicio.',
    it: 'Potete valutare il nostro servizio!',
    de: 'Sie können unseren Service bewerten!',
    nl: 'Je kunt onze service beoordelen!',
  },
  ratingButton: {
    id: `${ratingScope}.ratingButton`,
    defaultMessage: 'Valider la note',
    en: 'Validate the grade',
    es: 'Validar la nota',
    it: 'Convalidare il voto',
    de: 'Die Note bestätigen',
    nl: 'Het cijfer valideren',
  },
  ratingThanks: {
    id: `${ratingScope}.ratingThanks`,
    defaultMessage:
      'Merci pour votre avis, Tictactrip innove sans cesse afin de vous proposer les meilleurs trajets !',
    en: 'Thank you for your feedback, Tictactrip is constantly innovating to offer you the best routes!',
    es: '¡Gracias por tu opinión, Tictactrip innova constantemente para ofrecerte los mejores trayectos!',
    it: 'Grazie per il tuo parere, Tictactrip innova costantemente per offrirti i migliori percorsi!',
    de: 'Vielen Dank für Ihr Feedback, Tictactrip innoviert ständig, um Ihnen die besten Strecken anzubieten!',
    nl: 'Bedankt voor uw feedback, Tictactrip innoveert voortdurend om u de beste routes aan te bieden!',
  },
};

export default defineMessages(ratingMessages);
