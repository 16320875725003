import { lazy, Suspense } from 'react';
import { injectIntl } from 'react-intl';
import LazyLoading from '../../components/LazyLoading';

export const LazyPrivacy = injectIntl(
  lazy(() => import(/* webpackChunkName: 'Privacy' */ './index')),
);

export default function Privacy() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyPrivacy />
    </Suspense>
  );
}
