import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { getCurrentRootPage } from '../../helpers';
import { useFooter } from './footer.hook';
import End from './View/End';
import Labels from './View/Labels';
import SocialNetworks from './View/SocialNetworks';
import UsefulLinks from './View/UsefulLinks';
import Welcome from './View/Welcome';

const Container = styled.footer<{
  isHiddenOnMobile: boolean;
}>`
  ${tw`flex flex-col bg-white`}
  gap: var(--size-medium2);
  padding-top: calc(var(--size-huge2) + var(--size-medium2));
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${({ isHiddenOnMobile }) => isHiddenOnMobile && tw`hidden lg:flex`}
`;

const LabelsStyle = css`
  ${tw`lg:hidden`}
`;

const Footer = () => {
  const { areLinksObfuscated, isHidden, isHiddenOnMobile, isReduced } =
    useFooter({
      userAgent: navigator.userAgent,
      currentPage: getCurrentRootPage(window.location.pathname),
    });

  if (isHidden) {
    return null;
  }

  return (
    <Container isHiddenOnMobile={isHiddenOnMobile}>
      <SocialNetworks
        areLinksObfuscated={areLinksObfuscated}
        isReduced={isReduced}
      />
      <Welcome isReduced={isReduced} />
      <UsefulLinks
        areLinksObfuscated={areLinksObfuscated}
        isReduced={isReduced}
      />
      <Labels customStyle={LabelsStyle} isReduced={isReduced} />
      <End areLinksObfuscated={areLinksObfuscated} isReduced={isReduced} />
    </Container>
  );
};

export default Footer;
