import PropTypes from 'prop-types';
import { API_URL } from '../../helpers/constants';

export const STATION_SITEMAP = 'stationSitemap';
export const URL_STOP_GROUPS = `${API_URL}/stopGroups`;

export const STATIONS = 'stations';
export const LOADING = 'isLoading';

export const FETCH_STATIONS = 'app/StationSitemap/FETCH_STATIONS';
export const SET_STATIONS = 'app/StationSitemap/SET_STATIONS';
export const SET_LOADING_STATUS = 'app/StationSitemap/SET_LOADING_STATUS';

export const STATION_SITEMAP_HEADER_TITLE = 'StationSitemapHeaderTitle';
export const STATION_SITEMAP_FIRST_TEXT_CONTENT =
  'StationSitemapFirstTextContent';
export const STATION_SITEMAP_SECOND_TEXT_CONTENT =
  'StationSitemapSecondTextContent';
export const STATION_SITEMAP_THIRD_TEXT_CONTENT =
  'StationSitemapThirdTextContent';
export const STATION_SITEMAP_BEST_STATIONS = 'StationSitemapBestStations';
export const STATION_NAME = 'StationName';

export const META_TITLE =
  'Gares Routières et Ferroviaire en Europe : Toutes les informations ! [Liste Complète]';
export const META_DESCRIPTION =
  'Adresse de la gare, horaires des trains, prochains départs... Découvrez toutes les informations sur vos gares ferroviaires et gares routières en Europe !';

export const BEST_STATIONS = [
  'g|FRpargarly@u09ty5',
  'g|FRpamo3va_@u09tug',
  'g|FRparsaila@u09whc',
  'g|FRparis___@u09wj9',
  'g|FRpariberc@u09ty4',
  'g|FRlyopardi@u05kqd',
  'g|FRlyonperr@u05kmb',
  'g|FRborsaije@ezzx52',
  'g|FRmagaroch@spey67',
  'g|FRlilleuro@u140jf',
  'g|FRstrasbou@u0ts26',
  'g|FRnantes__@gbqusg',
  'g|FRrennes__@gbwc9v',
  'g|FRgreunigi@u0h159',
  'g|FRrourivdr@u0bc2c',
];

export const WHITELIST_COUNTRY = {
  fr: 'France',
  be: 'Belgique',
  es: 'Espagne',
  it: 'Italie',
  lu: 'Luxembourg',
  ch: 'Suisse',
};

export const stationsPropTypes = PropTypes.arrayOf(
  PropTypes.exact({
    city: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
    pageId: PropTypes.string,
    region: PropTypes.string,
    transportTypes: PropTypes.arrayOf(PropTypes.string),
  }),
);

export const stationsCountriesPropTypes = PropTypes.shape({
  [WHITELIST_COUNTRY]: PropTypes.shape(stationsPropTypes),
});
