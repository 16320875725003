import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

const InputStyleValue = css`
  ${tw`relative w-full
  pl-def
  border border-solid border-grey200 rounded-def
  outline-none z-1
  focus:border-2 focus:border-terciary500`};
  height: 61px;

  &:focus {
    padding-left: 19px;
  }
`;

const Input = styled.input`
  ${InputStyleValue}
`;

export { Input as default, InputStyleValue };
