import { defineMessages } from 'react-intl';

export const howItWorksScope = 'app.containers.HowItWorks';

export const howItWorksMessages = {
  title: {
    id: `${howItWorksScope}.title`,
    defaultMessage: "L'app avec 3T, ",
    en: 'The app with 3T, ',
    it: "L'app con 3T, ",
    es: 'La aplicación con 3T, ',
    de: 'Die App mit 3T, ',
    nl: 'De app met 3T, ',
  },
  title2: {
    id: `${howItWorksScope}.title2`,
    defaultMessage: 'pour faire 3X plus.',
    en: 'to do 3X more.',
    it: 'per fare 3 volte di più.',
    es: 'para hacer 3 veces más.',
    de: 'um das Dreifache zu tun.',
    nl: 'om 3 keer meer te doen.',
  },
  planeCarOverrated: {
    id: `${howItWorksScope}.planeCarOverrated`,
    defaultMessage:
      "L'avion & les voitures individuelles, c'est surcôtées. Voyagez en France et en Europe sans concessions sur vos convictions, vos destinations ou votre budget.",
    en: 'Planes & individual cars are overrated. Travel in France and Europe without compromising your convictions, destinations, or budget.',
    it: 'Gli aerei e le auto individuali sono sopravvalutati. Viaggia in Francia ed Europa senza compromettere le tue convinzioni, le tue destinazioni o il tuo budget.',
    es: 'Los aviones y los coches individuales están sobrevalorados. Viaja por Francia y Europa sin comprometer tus convicciones, destinos o presupuesto.',
    de: 'Flugzeuge & Einzelautos sind überbewertet. Reisen Sie in Frankreich und Europa, ohne Ihre Überzeugungen, Ziele oder Ihr Budget zu beeinträchtigen.',
    nl: "Vliegtuigen & individuele auto's zijn overschat. Reis in Frankrijk en Europa zonder compromissen te sluiten over uw overtuigingen, bestemmingen of budget.",
  },
  theBigStepForward: {
    id: `${howItWorksScope}.theBigStepForward`,
    defaultMessage:
      'Le grand pas en avant du petit train. Avec ses potes le bus et le covoit, ils sont pas venus pour plaisanter chez Tictactrip.',
    en: "The big step forward for the little train. With its buddies the bus and carpooling, they're not here to joke around at Tictactrip.",
    it: 'Il grande passo avanti del piccolo treno. Con i suoi amici il bus e il carpooling, non sono qui per scherzare su Tictactrip.',
    es: 'El gran paso adelante del pequeño tren. Con sus amigos el autobús y el coche compartido, no están aquí para bromear en Tictactrip.',
    de: 'Der große Schritt nach vorne für den kleinen Zug. Mit seinen Kumpels dem Bus und der Mitfahrgelegenheit sind sie nicht hier, um bei Tictactrip herumzualbern.',
    nl: 'De grote stap voorwaarts voor de kleine trein. Met zijn vrienden de bus en carpooling, zijn ze niet hier om grappen te maken bij Tictactrip.',
  },
  theLargestTransportCatalogue: {
    id: `${howItWorksScope}.largestTransportCatalogue`,
    defaultMessage: 'Le plus grand catalogue de transport.',
    en: 'The largest transport catalogue.',
    it: 'Il catalogo di trasporti più grande.',
    es: 'El catálogo de transporte más grande.',
    de: 'Der größte Transportkatalog.',
    nl: 'De grootste transportcatalogus.',
  },
  prioritizeTrainOrBus: {
    id: `${howItWorksScope}.prioritizeTrainOrBus`,
    defaultMessage:
      'Privilégier le train ou le bus est une question de possibilités. Nous proposons le plus grand nombre de possibilités de transporteurs (SNCF, Ouigo, Renfe, Trenitalia...) et de destinations.',
    en: 'Prioritizing train or bus is a matter of possibilities. We offer the largest number of carrier options (SNCF, Ouigo, Renfe, Trenitalia...) and destinations.',
    it: "Dare la priorità al treno o all'autobus è una questione di possibilità. Offriamo il maggior numero di opzioni di vettori (SNCF, Ouigo, Renfe, Trenitalia...) e destinazioni.",
    es: 'Priorizar el tren o el autobús es una cuestión de posibilidades. Ofrecemos el mayor número de opciones de transportistas (SNCF, Ouigo, Renfe, Trenitalia...) y destinos.',
    de: 'Die Priorisierung von Zug oder Bus ist eine Frage der Möglichkeiten. Wir bieten die größte Anzahl von Optionen für Transportunternehmen (SNCF, Ouigo, Renfe, Trenitalia...) und Ziele.',
    nl: 'Het prioriteren van de trein of bus is een kwestie van mogelijkheden. We bieden de grootste verscheidenheid aan vervoersopties (SNCF, Ouigo, Renfe, Trenitalia...) en bestemmingen.',
  },
  nowYouHaveChoice: {
    id: `${howItWorksScope}.nowYouHaveChoice`,
    defaultMessage: 'Maintenant, vous avez le choix.',
    en: 'Now, you have the choice.',
    it: 'Ora, hai la scelta.',
    es: 'Ahora, tienes la elección.',
    de: 'Jetzt hast du die Wahl.',
    nl: 'Nu heb je de keuze.',
  },
  expandingTransportPartners: {
    id: `${howItWorksScope}.expandingTransportPartners`,
    defaultMessage:
      'En multipliant les partenaires de transports et en améliorant notre technologie nous proposons le plus large éventail de possibilités.',
    en: 'By expanding transport partners and improving our technology, we offer the widest range of possibilities.',
    it: 'Espandendo i partner di trasporto e migliorando la nostra tecnologia, offriamo la più ampia gamma di possibilità.',
    es: 'Al expandir los socios de transporte y mejorar nuestra tecnología, ofrecemos la más amplia gama de posibilidades.',
    de: 'Durch die Erweiterung der Transportpartner und die Verbesserung unserer Technologie bieten wir die breiteste Palette von Möglichkeiten.',
    nl: 'Door het uitbreiden van transportpartners en het verbeteren van onze technologie bieden we het breedste scala aan mogelijkheden.',
  },
  whatChangesForYou: {
    id: `${howItWorksScope}.whatChangesForYou`,
    defaultMessage: "Qu'est-ce que ça change pour vous ?",
    en: 'What changes for you?',
    it: 'Cosa cambia per te?',
    es: '¿Qué cambia para ti?',
    de: 'Was ändert sich für dich?',
    nl: 'Wat verandert er voor jou?',
  },
  withoutTictactrip: {
    id: `${howItWorksScope}.withoutTictactrip`,
    defaultMessage: 'Sans Tictactrip',
    en: 'Without Tictactrip',
    it: 'Senza Tictactrip',
    es: 'Sin Tictactrip',
    de: 'Ohne Tictactrip',
    nl: 'Zonder Tictactrip',
  },
  fewTransportCompanies: {
    id: `${howItWorksScope}.fewTransportCompanies`,
    defaultMessage: 'Une ou peu de compagnies de transports',
    en: 'One or few transport companies',
    it: 'Una o poche compagnie di trasporto',
    es: 'Una o pocas compañías de transporte',
    de: 'Ein oder wenige Transportunternehmen',
    nl: 'Een of weinig transportbedrijven',
  },
  fewDestinations: {
    id: `${howItWorksScope}.fewDestinations`,
    defaultMessage: 'Peu voire très peu de destinations',
    en: 'Few or very few destinations',
    it: 'Pochi o pochissime destinazioni',
    es: 'Pocas o muy pocas destinos',
    de: 'Wenige oder sehr wenige Ziele',
    nl: 'Weinig of heel weinig bestemmingen',
  },
  singleTransportOption: {
    id: `${howItWorksScope}.singleTransportOption`,
    defaultMessage: 'Une option de transport',
    en: 'A transport option',
    it: "Un'opzione di trasporto",
    es: 'Una opción de transporte',
    de: 'Eine Transportoption',
    nl: 'Een transportoptie',
  },
  multipleAppsToDownload: {
    id: `${howItWorksScope}.multipleAppsToDownload`,
    defaultMessage: 'Plusieurs applications à télécharger',
    en: 'Multiple apps to download',
    it: 'Diverse app da scaricare',
    es: 'Múltiples aplicaciones para descargar',
    de: 'Mehrere Apps zum Herunterladen',
    nl: 'Meerdere apps om te downloaden',
  },
  customerServiceNotCustomerCentric: {
    id: `${howItWorksScope}.customerServiceNotCustomerCentric`,
    defaultMessage: 'Un service client plus service que client',
    en: 'A customer service more service than customer',
    it: 'Un servizio clienti più servizio che cliente',
    es: 'Un servicio al cliente más servicio que cliente',
    de: 'Ein Kundenservice, der mehr Service als Kunde ist',
    nl: 'Een klantenservice die meer gericht is op service dan op de klant',
  },
  withTictactrip: {
    id: `${howItWorksScope}.withTictactrip`,
    defaultMessage: 'Avec Tictactrip',
    en: 'With Tictactrip',
    it: 'Con Tictactrip',
    es: 'Con Tictactrip',
    de: 'Mit Tictactrip',
    nl: 'Met Tictactrip',
  },
  allCarriersOnOneApp: {
    id: `${howItWorksScope}.allCarriersOnOneApp`,
    defaultMessage: 'Tous les transporteurs sur une application',
    en: 'All carriers on one app',
    it: "Tutti i vettori su un'app",
    es: 'Todos los transportistas en una aplicación',
    de: 'Alle Transportunternehmen in einer App',
    nl: 'Alle vervoerders op één app',
  },
  allDestinationsOnOneApp: {
    id: `${howItWorksScope}.allDestinationsOnOneApp`,
    defaultMessage: 'Toutes les destinations sur une application',
    en: 'All destinations on one app',
    it: "Tutte le destinazioni su un'app",
    es: 'Todos los destinos en una aplicación',
    de: 'Alle Ziele in einer App',
    nl: 'Alle bestemmingen op één app',
  },
  allTransportOptions: {
    id: `${howItWorksScope}.allTransportOptions`,
    defaultMessage: 'Toutes les options de transports : train, bus, covoit',
    en: 'All transport options: train, bus, carpooling',
    it: 'Tutte le opzioni di trasporto: treno, autobus, carpooling',
    es: 'Todas las opciones de transporte: tren, autobús, coche compartido',
    de: 'Alle Transportoptionen: Zug, Bus, Mitfahrgelegenheit',
    nl: 'Alle vervoersopties: trein, bus, carpoolen',
  },
  bestPricesAndRoutes: {
    id: `${howItWorksScope}.bestPricesAndRoutes`,
    defaultMessage: 'Les meilleurs prix et trajets proposés',
    en: 'Best prices and routes offered',
    it: 'Migliori prezzi e percorsi offerti',
    es: 'Mejores precios y rutas ofrecidas',
    de: 'Beste Preise und Routen angeboten',
    nl: 'Beste prijzen en routes aangeboden',
  },
  humanCustomerService: {
    id: `${howItWorksScope}.humanCustomerService`,
    defaultMessage: 'Un service client avec des (vrais) humains',
    en: 'Customer service with (real) humans',
    it: 'Servizio clienti con (veri) umani',
    es: 'Servicio al cliente con (verdaderos) humanos',
    de: 'Kundenservice mit (echten) Menschen',
    nl: 'Klantenservice met (echte) mensen',
  },
  takingCareOfEverything: {
    id: `${howItWorksScope}.takingCareOfEverything`,
    defaultMessage: "On s'occupe de vous, de tout et même du reste.",
    en: 'We take care of you, of everything, and even more.',
    it: 'Ci occupiamo di te, di tutto e anche di più.',
    es: 'Nos ocupamos de ti, de todo, e incluso más.',
    de: 'Wir kümmern uns um dich, um alles und noch mehr.',
    nl: 'Wij zorgen voor jou, voor alles, en zelfs meer.',
  },
  simpleForYou: {
    id: `${howItWorksScope}.simpleForYou`,
    defaultMessage:
      "C'est très simple pour vous, pas si simple pour nous. Personne (ou presque) ne souhaite vendre du train en France.",
    en: "It's very simple for you, not so simple for us. Almost no one wants to sell trains in France.",
    it: 'È molto semplice per te, non così semplice per noi. Quasi nessuno vuole vendere treni in Francia.',
    es: 'Es muy simple para ti, no tan simple para nosotros. Casi nadie quiere vender trenes en Francia.',
    de: 'Es ist sehr einfach für Sie, nicht so einfach für uns. Fast niemand möchte Züge in Frankreich verkaufen.',
    nl: 'Het is heel eenvoudig voor jou, niet zo eenvoudig voor ons. Bijna niemand wil treinen verkopen in Frankrijk.',
  },
  terrestrialTransportChallenge: {
    id: `${howItWorksScope}.terrestrialTransportChallenge`,
    defaultMessage:
      'Autant dire que vendre des transports terrestres en France est un parcours du combattant.',
    en: 'Selling terrestrial transport in France is a real challenge.',
    it: 'Vendere trasporti terrestri in Francia è una vera sfida.',
    es: 'Vender transporte terrestre en Francia es un verdadero desafío.',
    de: 'Den Landverkehr in Frankreich zu verkaufen, ist eine echte Herausforderung.',
    nl: 'Het verkopen van terrestrisch transport in Frankrijk is een echte uitdaging.',
  },
  carriersResistant: {
    id: `${howItWorksScope}.carriersResistant`,
    defaultMessage:
      "Les transporteurs d'abord : récalcitrants pour certains à nous proposer leur catalogue. Les acteurs en situation de monopole (la SNCF) par exemple, sont plutôt motivés à nous décourager.",
    en: 'First the carriers: some are reluctant to offer us their catalog. Monopoly players (such as SNCF), for example, are more motivated to discourage us.',
    it: 'Prima i vettori: alcuni sono riluttanti ad offrirci il loro catalogo. I giocatori del monopolio (come la SNCF), ad esempio, sono più motivati a scoraggiarci.',
    es: 'Primero los transportistas: algunos son reacios a ofrecernos su catálogo. Los jugadores del monopolio (como la SNCF), por ejemplo, están más motivados para desalentarnos.',
    de: 'Zuerst die Transportunternehmen: Einige zögern, uns ihren Katalog anzubieten. Monopolakteure (wie die SNCF) sind zum Beispiel eher motiviert, uns zu entmutigen.',
    nl: 'Eerst de vervoerders: sommigen zijn terughoudend om ons hun catalogus aan te bieden. Monopoliespelers (zoals de SNCF) zijn bijvoorbeeld meer gemotiveerd om ons te ontmoedigen.',
  },
  technologyChallenge: {
    id: `${howItWorksScope}.technologyChallenge`,
    defaultMessage:
      "La technologie ensuite : pour proposer les trajets intéressants niveau prix, durée et logique de voyage on s'est creusé les méninges.",
    en: 'Then comes technology: to offer interesting trips in terms of price, duration, and travel logic, we really put our brains to the test.',
    it: 'Poi viene la tecnologia: per offrire viaggi interessanti in termini di prezzo, durata e logica di viaggio, abbiamo davvero messo alla prova i nostri cervelli.',
    es: 'Luego viene la tecnología: para ofrecer viajes interesantes en términos de precio, duración y lógica de viaje, realmente pusimos a prueba nuestros cerebros.',
    de: 'Dann kommt die Technologie: Um interessante Reisen in Bezug auf Preis, Dauer und Reise-Logik anzubieten, haben wir wirklich unser Gehirn angestrengt.',
    nl: 'Dan komt de technologie: om interessante reizen aan te bieden qua prijs, duur en reislogica, hebben we echt ons brein op de proef gesteld.',
  },
  researchAndDevelopment: {
    id: `${howItWorksScope}.researchAndDevelopment`,
    defaultMessage: "Notre algo c'est pas le bingo. C'est 3 ans de R&D.",
    en: "Our algorithm isn't a game of chance. It's 3 years of R&D.",
    it: "Il nostro algoritmo non è un gioco d'azzardo. Sono 3 anni di R&S.",
    es: 'Nuestro algoritmo no es un juego de azar. Son 3 años de I+D.',
    de: 'Unser Algorithmus ist kein Glücksspiel. Es sind 3 Jahre Forschung und Entwicklung.',
    nl: 'Ons algoritme is geen kansspel. Het zijn 3 jaar R&D.',
  },
  seamlessTravelExperience: {
    id: `${howItWorksScope}.seamlessTravelExperience`,
    defaultMessage:
      "On a cherché à construire l'expérience de voyage la plus fluide possible.",
    en: "We've sought to build the smoothest travel experience possible.",
    it: "Abbiamo cercato di costruire l'esperienza di viaggio più fluida possibile.",
    es: 'Hemos buscado construir la experiencia de viaje más fluida posible.',
    de: 'Wir haben versucht, das reibungsloseste Reiseerlebnis möglich zu machen.',
    nl: 'We hebben geprobeerd om de meest naadloze reiservaring mogelijk te maken.',
  },
};

export default defineMessages(howItWorksMessages);
