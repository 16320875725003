import PropTypes from 'prop-types';

export const faqPropTypes = PropTypes.shape({
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priority: PropTypes.number,
  question: PropTypes.string,
});

export const faqsPropTypes = PropTypes.arrayOf(faqPropTypes);
