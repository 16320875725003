import { fromJS } from 'immutable';
import {
  CURRENT_NAVIGATION,
  CURRENT_ORDERS,
  CUSTOMER,
  LOADING,
  ORDERS,
  SET_CURRENT_NAVIGATION,
  SET_LOADING_STATUS,
  UPDATE_CUSTOMER_INFO,
  UPDATE_CUSTOMER_ORDERS,
} from './constants';

export const initialState = fromJS({
  [LOADING]: true,
  [CURRENT_NAVIGATION]: CURRENT_ORDERS,
  [CUSTOMER]: {},
  [ORDERS]: [],
});

function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return state.set(LOADING, fromJS(action.loadingStatus));
    case SET_CURRENT_NAVIGATION:
      return state.set(CURRENT_NAVIGATION, fromJS(action.tab));
    case UPDATE_CUSTOMER_ORDERS:
      return state.set(ORDERS, fromJS(action.orders));
    case UPDATE_CUSTOMER_INFO:
      return state.set(CUSTOMER, fromJS(action.customer));
    default:
      return state;
  }
}

export default ordersReducer;
