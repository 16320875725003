/*
 *
 * Payment reducer
 *
 */

import { fromJS } from 'immutable';
import { LINK_DIRECTION_FROM } from '../../helpers/constants';
import {
  ADD_EMPTY_EDITABLE_CONTENT,
  CITY_TO_MATCH_LINK,
  COMPANIES_DATA,
  CURRENT_PAGE_TYPE,
  DELETE_EDITABLE_CONTENT,
  DESTINATION_CITY,
  EDITABLE_CONTENT,
  FROM_DESTINATION_LINKS,
  FROM_ORIGIN_LINKS,
  LINKS,
  LOADING,
  ORIGIN_CITY,
  RATINGS,
  RESET_STATE,
  SET_CITY,
  SET_COMPANIES_DATA,
  SET_CURRENT_PAGE_TYPE,
  SET_EDITABLE_CONTENT,
  SET_LINKS,
  SET_LOADING,
  SET_RATINGS,
  SET_SHOULD_REDIRECT_TO_THE_MAIN_PAGE,
  SET_TRIP_DATA,
  SHOULD_REDIRECT_TO_MAIN_PAGE,
  TO_DESTINATION_LINKS,
  TO_ORIGIN_LINKS,
  TRIP_DATA,
} from './constants';
import { updateTripData } from './utils';

export const initialState = fromJS({
  [ORIGIN_CITY]: {},
  [DESTINATION_CITY]: {},
  [TRIP_DATA]: {},
  [LOADING]: true,
  [LINKS]: {
    [FROM_ORIGIN_LINKS]: [],
    [TO_ORIGIN_LINKS]: [],
    [FROM_DESTINATION_LINKS]: [],
    [TO_DESTINATION_LINKS]: [],
  },
  [EDITABLE_CONTENT]: [],
  [RATINGS]: {},
  [CURRENT_PAGE_TYPE]: null,
  [SHOULD_REDIRECT_TO_MAIN_PAGE]: false,
  [COMPANIES_DATA]: null,
});

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANIES_DATA:
      return state.set(COMPANIES_DATA, action.companiesData);
    case SET_SHOULD_REDIRECT_TO_THE_MAIN_PAGE:
      return state.set(
        SHOULD_REDIRECT_TO_MAIN_PAGE,
        action.shouldRedirectToMainPage,
      );
    case SET_CURRENT_PAGE_TYPE:
      return state.set(CURRENT_PAGE_TYPE, action.pageType);
    case SET_CITY:
      return state.set(action.cityType, fromJS(action.city));
    case SET_TRIP_DATA: {
      const updatedTripData = updateTripData(action.tripData);
      return state.set(TRIP_DATA, fromJS(updatedTripData));
    }
    case SET_LOADING:
      return state.set(LOADING, action.value);
    case SET_LINKS: {
      const formattedLinks = action.links.map((link) => {
        const from = action.direction === LINK_DIRECTION_FROM;
        const origin = from
          ? state.get(CITY_TO_MATCH_LINK[action.linkType]).toJS()
          : formatLink(link);
        const destination = from
          ? formatLink(link)
          : state.get(CITY_TO_MATCH_LINK[action.linkType]).toJS();

        return { origin, destination };
      });

      return state.setIn([LINKS, action.linkType], fromJS(formattedLinks));
    }

    case SET_EDITABLE_CONTENT:
      return state.set(
        EDITABLE_CONTENT,
        fromJS(action.content.slice().sort((a, b) => a.priority - b.priority)),
      );

    case ADD_EMPTY_EDITABLE_CONTENT:
      const editableContent = state.get(EDITABLE_CONTENT).toJS();

      const priority =
        editableContent.length > 0
          ? editableContent
              .slice()
              .sort((a, b) => b.priority - a.priority)
              .at(0).priority + 1
          : 0;

      const emptyEditableContent = fromJS({
        content: { title: '', body: '' },
        isNew: true,
        priority,
      });

      return state.update(EDITABLE_CONTENT, (editableContents) =>
        editableContents.push(emptyEditableContent),
      );

    case DELETE_EDITABLE_CONTENT:
      return state.update(EDITABLE_CONTENT, (editableContents) =>
        editableContents.filter(
          (editableContent) =>
            editableContent.get('priority') !== action.priority,
        ),
      );

    case SET_RATINGS:
      return state.set(RATINGS, fromJS(action.ratings));

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

const formatLink = (link) => {
  const [name, region, country] = link.local_name.split(',');
  return {
    name,
    slug: link.unique_name,
    region,
    country,
    id: link.city_id,
  };
};

export default searchReducer;
