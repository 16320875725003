import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyAbout = lazy(() =>
  import(/* webpackChunkName: 'About' */ './index'),
);

export default function About() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyAbout />
    </Suspense>
  );
}
