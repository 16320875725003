/*
 * Results Messages
 *
 * This contains all the text for the Results container.
 */

import { defineMessages } from 'react-intl';

export const passengersScope = 'app.components.Passengers';

export const passengersMessages = {
  passenger: {
    id: `${passengersScope}.passenger`,
    defaultMessage: 'passager',
    en: 'passenger',
    it: 'passeggero',
    es: 'pasajero',
    de: 'passagier',
    nl: 'passagier',
  },
  passengers: {
    id: `${passengersScope}.passengers`,
    defaultMessage: 'passagers',
    en: 'passengers',
    it: 'passeggeri',
    es: 'pasajeros',
    de: 'passagiere',
    nl: 'passagiers',
  },
  editPassengers: {
    id: `${passengersScope}.editPassengers`,
    defaultMessage: 'Modifier les passagers',
    en: 'Modify the passengers',
    it: 'Modificare i passeggeri',
    es: 'Modificación de los pasajeros',
    de: 'Ändern von Passagieren',
    nl: 'Verander passagiers',
  },
  submitPassenger: {
    id: `${passengersScope}.submitPassenger`,
    defaultMessage: 'Valider les passagers',
    en: 'Submit passengers',
    it: 'Convalida dei passeggeri',
    es: 'Validación de pasajeros',
    de: 'Passagiere validieren',
    nl: 'Passagiers valideren',
  },
};

export default defineMessages(passengersMessages);
