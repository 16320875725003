/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';
import { homePageAppMessages } from './App/messages';
import { homePageConceptMessages } from './Concept/messages';
import { homepageScope } from './constants';
import { homePageDestinationsMessages } from './Destinations/messages';
import { homePageFAQMessages } from './FAQ/messages';
import { homePageHighlightMessages } from './Highlight/messages';
import { homePageReviewMessages } from './Review/messages';
import { homePageTravelListMessages } from './TravelList/messages';

export const homepageMessages = {
  ...homePageAppMessages,
  ...homePageConceptMessages,
  ...homePageDestinationsMessages,
  ...homePageHighlightMessages,
  ...homePageReviewMessages,
  ...homePageTravelListMessages,
  ...homePageFAQMessages,
  companiesTitle1: {
    id: `${homepageScope}.companiesTitle1`,
    defaultMessage: 'Tous vos trajets',
    en: 'All your trips',
    it: 'Tutti i tuoi viaggi',
    es: 'Todos tus viajes',
    de: 'Alle Ihre Reisen',
    nl: 'Al uw reizen',
  },
  companiesTitle2: {
    id: `${homepageScope}.companiesTitle2`,
    defaultMessage: 'au même endroit.',
    en: 'in one place.',
    it: 'in un unico posto.',
    es: 'en un solo lugar.',
    de: 'an einem Ort.',
    nl: 'op één plek.',
  },
  companiesText: {
    id: `${homepageScope}.companiesText`,
    defaultMessage: 'Pour faire de votre voyage, une promenade de santé.',
    en: 'To make your trip a health walk.',
    it: 'Per rendere il tuo viaggio una passeggiata salutare.',
    es: 'Para convertir su viaje en un paseo saludable.',
    de: 'Um Ihre Reise zu einem gesunden Spaziergang zu machen.',
    nl: 'Om van uw reis een gezonde wandeling te maken.',
    fr: 'Pour faire de votre voyage, une promenade de santé.',
  },
};

export default defineMessages(homepageMessages);
