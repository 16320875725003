import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import RoundShadowBox from '../../../components/RoundShadowBox';
import { colors } from '../../../styles/constants';
import { TYPE_OF_REQUEST_FIELD } from '../constants';
import messages from '../messages';
import { FormRow, InputTitle } from '.';

const ShadowSelect = styled(RoundShadowBox)`
  ${tw`md:max-w-abs1 mt-abs1 py-abs1 px-def flex flex-col justify-center font-bold cursor-pointer`}
  min-height: 61px;
  font-size: 1.6rem;
  ${({ isChecked }) => isChecked && ` background: ${colors.terciary500};`}
`;

const CustomSelectLabel = styled.label`
  & input {
    display: none;
  }
`;

const CustomSelect = ({ id, isChecked, onClick }) => {
  return (
    <CustomSelectLabel>
      <ShadowSelect isChecked={isChecked} onClick={() => onClick()}>
        <FormattedMessage {...messages[id]} />
      </ShadowSelect>
    </CustomSelectLabel>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const SubjectFields = ({ selectedSubject, setSelectedSubject }) => {
  return (
    <React.Fragment>
      <FormRow>
        <label>
          <InputTitle>
            <FormattedMessage {...messages.contactSubjectFieldsLabel} />
          </InputTitle>
        </label>
        {TYPE_OF_REQUEST_FIELD.map((subject, index) => (
          <CustomSelect
            key={index}
            id={subject.messageId}
            isChecked={index === selectedSubject}
            onClick={() => setSelectedSubject(index)}
          />
        ))}
      </FormRow>

      <FormRow>
        <label>
          <InputTitle>
            <FormattedMessage {...messages.toBeNoted} />
          </InputTitle>
          <p style={{ marginTop: '1rem' }}>
            👉{' '}
            <FormattedHTMLMessage
              {...messages[
                `toBeNoted.${TYPE_OF_REQUEST_FIELD[selectedSubject].messageId}`
              ]}
            />
          </p>
        </label>
      </FormRow>
    </React.Fragment>
  );
};

SubjectFields.propTypes = {
  selectedSubject: PropTypes.number.isRequired,
  setSelectedSubject: PropTypes.func.isRequired,
};
