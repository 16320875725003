import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Icon12 } from '../../../components/Icon';
import IconUrlClose from '../../../images/iconClose.svg';
import { colors } from '../../../styles/constants';
import messages from '../messages';

const ImageUploaderContainer = styled.div`
  ${tw`w-full
pl-def
border border-solid border-grey200 rounded-def
flex justify-center items-center p-abs1 text-center
relative
`}
  min-height:12rem;
  color: #a9a9a9;

  ${({ isDragActive }) =>
    isDragActive &&
    `border-color: ${colors.terciary500};
border-width: 2px;
`}
`;

const ContainerPreview = styled.div`
  ${tw`w-full my-abs2`}
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RemoveFile = styled.img`
  ${Icon12}
`;

const RemoveFileContainer = styled.button`
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e9;
  border-radius: 50%;
`;

const FileContainer = styled.div`
  ${tw`flex w-full justify-between p-abs1  border border-solid border-terciary500 rounded-def`}

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
`;

export const FileUploader = ({ files, setFiles }) => {
  return (
    <React.Fragment>
      <ImageUploaderContainer>
        <FileInput
          accept="image/*,application/pdf"
          multiple
          onChange={(e) => {
            if (!e.target.files) {
              return;
            }

            setFiles((currentFiles) => [...currentFiles, ...e.target.files]);
          }}
          type="file"
        />

        <p>
          <FormattedMessage {...messages.importImageOrPDFPlaceholder} />
        </p>
      </ImageUploaderContainer>

      {files.length > 0 && (
        <ContainerPreview>
          {files.map((file, fileIndex) => (
            <FileContainer key={fileIndex}>
              <p>{file.name}</p>
              <RemoveFileContainer
                onClick={(e) => {
                  e.preventDefault();
                  setFiles((currentFiles) =>
                    currentFiles.filter((file, index) => index !== fileIndex),
                  );
                }}
              >
                <RemoveFile src={IconUrlClose} />
              </RemoveFileContainer>
            </FileContainer>
          ))}
        </ContainerPreview>
      )}
    </React.Fragment>
  );
};

FileUploader.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  setFiles: PropTypes.func.isRequired,
};
