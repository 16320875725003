import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyPaymentLink = lazy(() =>
  import(/* webpackChunkName: 'PaymentLink' */ './index'),
);

export default function PaymentLink(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyPaymentLink {...props} />
    </Suspense>
  );
}
