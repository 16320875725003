/*
 * DestinationCardSlider Messages
 *
 * This contains all the text for the DestinationCardSlider container.
 */

import { defineMessages } from 'react-intl';

export const destinationCardScope = 'app.containers.DestinationCardSlider';

export const destinationCardMessages = {
  priceFrom: {
    id: `${destinationCardScope}.priceFrom`,
    defaultMessage: 'À partir de ',
    en: 'From ',
    it: 'Da ',
    es: 'Desde ',
    de: 'Ab ',
    nl: 'Van ',
  },
};

export default defineMessages(destinationCardMessages);
