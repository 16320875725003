import PropTypes from 'prop-types';
import { destinationCardsPropTypes } from '../Company/constants';

export const SET_CHILDREN_LINKS = 'app/City/SET_CHILDREN_LINKS';
export const GET_CHILDREN_LINKS = 'app/City/GET_CHILDREN_LINKS';
export const SET_CITY_DATA = 'app/City/SET_CITY_DATA';

export const DESTINATIONS = 'destinations';
export const DESCRIPTION = 'description';
export const SERVICES = 'services';
export const CHILDREN_LINKS = 'childrenLinks';
export const LINKS_FROM = 'linksFrom';
export const LINKS_TO = 'linksTo';
export const CITY_DATA = 'cityData';

export const CHILDREN_LINKS_NUMBER = 12;

export const VALUE_SERVICES = 'VALUE_SERVICES';
export const PRICE_SERVICES = 'PRICE_SERVICES';
export const OTHER_SERVICES = 'OTHER_SERVICES';

export const SERVICE_PRICE_BEER = 'beerPrice';
export const SERVICE_PRICE_COKE = 'cokePrice';
export const SERVICE_PRICE_CAPPUCINO = 'cappucinoPrice';
export const SERVICE_PRICE_MEAL = 'mealPrice';
export const SERVICE_PRICE_ROOM = 'roomPrice';
export const SERVICE_FREE_WIFI = 'freeWifi';
export const SERVICE_SPACE = 'space';
export const SERVICE_POWER = 'power';
export const SERVICE_STARTUP_SCORE = 'startupScore';
export const SERVICE_FEMALE_FRIENDLY = 'femaleFriendly';
export const SERVICE_WALKABILITY = 'walkability';
export const SERVICE_SAFETY = 'safety';
export const SERVICE_ENGLISH = 'englishSpeaking';
export const SERVICE_AIR_QUALITY = 'airQuality';
export const SERVICE_PEACE = 'peace';
export const SERVICE_FRIENDLY = 'friendly';
export const SERVICE_HUMIDITY = 'humidity';
export const SERVICE_LIVING_COST = 'costOfLiving';
export const SERVICE_NIGHTLIFE = 'nightlife';
export const SERVICE_HEALTH_CARE = 'healthCare';
export const SERVICE_FUN = 'fun';
export const SERVICE_TIPPING = 'tipping';
export const SERVICE_LIFE_QUALITY = 'qualityOfLife';
export const SERVICE_INTERNET = 'internet';
export const SERVICE_COST = 'cost';
export const SERVICE_HAPPINESS = 'happiness';
export const SERVICE_GAY_FRIENDLY = 'gayFriendly';
export const SERVICE_UBER = 'uber';
export const SERVICE_GENDER_RATIO = 'genderRatio';

export const cityServicePropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    emoji: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
      PropTypes.string,
    ]),
  }),
);

export const citySiblingsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ name: PropTypes.string, path: PropTypes.string }),
);

export const cityPropTypes = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  description: PropTypes.string,
  destinationCards: destinationCardsPropTypes,
  done: PropTypes.bool,
  fullname: PropTypes.string,
  id: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.string,
  metaTitle: PropTypes.string,
  path: PropTypes.string,
  searchHeader: PropTypes.shape({
    backgroundImage: PropTypes.string,
    logoImage: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }),
  services: PropTypes.shape({
    other: cityServicePropTypes,
    prices: cityServicePropTypes,
    values: cityServicePropTypes,
  }),
  siblings: citySiblingsPropTypes,
  slug: PropTypes.string,
});

export const cityChildrenPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    destination: PropTypes.string,
    origin: PropTypes.string,
    path: PropTypes.string,
  }),
);

export const childrenLinksPropTypes = PropTypes.exact({
  [LINKS_FROM]: cityChildrenPropTypes,
  [LINKS_TO]: cityChildrenPropTypes,
});
