import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

export const roundShadowBoxStyle = css`
  ${tw`w-full 
    rounded-lg shadow-medium`};
`;

const RoundShadowBox = styled.div`
  ${roundShadowBoxStyle};
`;

export default RoundShadowBox;
