import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import { css } from 'styled-components';
import { Link } from '../../components/Link';
import { NAVIGATION } from '../../containers/App/constants';
import messages from './messages';
import { ErrorMessageTemplate } from './messageTemplate';

const refreshPage = () => window.location.reload();

const LinkCustomStyle = css`
  font-size: inherit;
  line-height: inherit;
`;

const ErrorMessage = (props: FallbackProps) => {
  if (/ChunkLoadError/.test(props.error.name)) {
    return (
      <ErrorMessageTemplate
        buttonContent={<FormattedMessage {...messages.refreshButton} />}
        onButtonClick={refreshPage}
        paragraph={
          <React.Fragment>
            <FormattedMessage {...messages.newVersionMessage} />
            <br />
            <FormattedMessage {...messages.updateInstructions} />
          </React.Fragment>
        }
        title={<FormattedMessage {...messages.newVersionTitle} />}
      />
    );
  }

  return (
    <ErrorMessageTemplate
      buttonContent={<FormattedMessage {...messages.refreshButton} />}
      onButtonClick={refreshPage}
      paragraph={
        <React.Fragment>
          <FormattedMessage {...messages.errorMessage} />
          <br />
          <FormattedMessage
            {...messages.errorMessage2}
            values={{
              contactUs: (
                <Link customStyle={LinkCustomStyle} href={NAVIGATION.SUPPORT}>
                  <FormattedMessage {...messages.contactUs} />
                </Link>
              ),
            }}
          />
        </React.Fragment>
      }
      title={<FormattedMessage {...messages.errorTitle} />}
    />
  );
};

export default ErrorMessage;
