import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyMountain = lazy(() =>
  import(/* webpackChunkName: 'Mountain' */ './index'),
);

export default function Mountain(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyMountain {...props} />
    </Suspense>
  );
}
