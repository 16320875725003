import PropTypes from 'prop-types';
import { API_URL } from '../../helpers/constants';
export const SET_CURRENT_FIELD = 'app/TravelSearchBar/SET_CURRENT_FIELD';
export const UPDATE_PLACE_QUERY = 'app/TravelSearchBar/UPDATE_PLACE_QUERY';
export const LOAD_AUTOCOMPLETE = 'app/TravelSearchBar/LOAD_AUTOCOMPLETE';
export const LOAD_AUTOCOMPLETE_SUCCESS =
  'app/TravelSearchBar/LOAD_AUTOCOMPLETE_SUCCESS';
export const LOAD_AUTOCOMPLETE_ERROR =
  'app/TravelSearchBar/LOAD_AUTOCOMPLETE_ERROR';
export const LOAD_POPULAR = 'app/TravelSearchBar/LOAD_POPULAR';
export const LOAD_DISCOUNT_CARDS_SUCCESS =
  'app/TravelSearchBar/LOAD_DISCOUNT_CARDS_SUCCESS';
export const LOAD_DISCOUNT_CARDS_ERROR =
  'app/TravelSearchBar/LOAD_DISCOUNT_CARDS_ERROR';
export const LOAD_AUTOCOMPLETE_LOADING =
  'app/TravelSearchBar/LOAD_AUTOCOMPLETE_LOADING';

export const URL_AUTOCOMPLETE = `${API_URL}/cities/autocomplete/?q=`;
export const URL_POPULAR = `${API_URL}/cities/popular`;
export const URL_POPULAR_FROM = `${API_URL}/cities/popular/from/`;
export const URL_POPULAR_TO = `${API_URL}/cities/popular/to/`;
export const API_PLACES_NUMBER = '/7';
export const URL_DISCOUNT_CARDS = `${API_URL}/reductionCards`;

export const SUGGESTION_CLICKED = 'app/TravelSearchBar/SUGGESTION_CLICKED';
export const SWITCH_PLACES = 'app/TravelSearchBar/SWITCH_PLACES';

export const NAVIGATE = 'app/TravelSearchBar/NAVIGATE';
export const SET_DATE = 'app/TravelSearchBar/SET_DATE';

export const DEBOUNCE_API_DELAY = 300;
export const BLUR_TIMEOUT = 170;
export const ORIGIN = 'origin';
export const DESTINATION = 'destination';
export const FIELD_DONE = 'app/TravelSearchBar/FIELD_DONE';
export const INITIAL = 'app/TravelSearchBar/INITIAL';

export const ARROW_UP = 'ArrowUp';
export const ARROW_DOWN = 'ArrowDown';
export const ARROW_RIGHT = 'ArrowRight';
export const ARROW_LEFT = 'ArrowLeft';
export const ENTER = 'Enter';
export const TAB = 'Tab';

export const DEPARTURE_DATE = 'departureDate';
export const RETURN_DATE = 'returnDate';
export const ORIGIN_PLACE_INPUT_PLACEHOLDER = 'originPlaceInputPlaceholder';
export const DESTINATION_PLACE_INPUT_PLACEHOLDER =
  'destinationPlaceInputPlaceholder';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const DAY_CLICKED = 'app/TravelSearchBar/DAY_CLICKED';
export const HOUR_CLICKED = 'app/TravelSearchBar/HOUR_CLICKED';
export const UPDATE_DATE_FOCUS = 'app/TravelSearchBar/UPDATE_DATE_FOCUS';
export const CLEAR_RETURN_DATE = 'app/TravelSearchBar/CLEAR_RETURN_DATE';
export const PREVIOUS_MONTH = 'app/TravelSearchBar/PREVIOUS_MONTH';
export const NEXT_MONTH = 'app/TravelSearchBar/NEXT_MONTH';

export const PASSENGERS = 'app/TravelSearchBar/PASSENGERS';
export const UPDATE_PASSENGER_FOCUS =
  'app/TravelSearchBar/UPDATE_PASSENGER_FOCUS';
export const UPDATE_FOCUS_AFTER_DELETE =
  'app/TravelSearchBar/UPDATE_FOCUS_AFTER_DELETE';
export const LOAD_DISCOUNT_CARDS = 'app/TravelSearchBar/LOAD_DISCOUNT_CARDS';
export const HIDE_API_CARDS_LIST = 'app/TravelSearchBar/HIDE_API_CARDS_LIST';
export const SET_CITY = 'app/TravelSearchBar/SET_CITY';
export const CLEAR_CURRENT_SELECTION =
  'app/TravelSearchBar/CLEAR_CURRENT_SELECTION';

export const refPropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);
