export const getDeviceId = (): number | undefined => {
  try {
    const id = window.localStorage.getItem('deviceId');

    if (id === null) {
      return undefined;
    }

    const deviceId = +id;

    return isNaN(deviceId) ? undefined : deviceId;
  } catch (error) {
    return undefined;
  }
};
