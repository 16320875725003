import { defineMessages } from 'react-intl';
import {
  ADDRESS,
  ARRIVALS_TAB_TITLE,
  CLOSE_STATIONS_TITLE,
  DATA_UNAVAILABLE,
  DEPARTURES_ARRIVALS_NAV_SUBTITLE,
  DEPARTURES_ARRIVALS_SECTION_TITLE,
  DEPARTURES_TAB_TITLE,
  DESTINATIONS_EXAMPLE,
  FIRST_DEPARTURE,
  FIRST_LAST_DEPARTURE,
  FROM_CURRENT_STATION_TO_ANOTHER_CONTENT,
  FROM_CURRENT_STATION_TO_ANOTHER_HEADER,
  HIGHWAY,
  ITINERARY_SOLUTIONS,
  JOURNEYS_PER_DAY,
  KEY_INFORMATIONS_NAV_SUBTITLE,
  LAST_DEPARTURE,
  MAPBOX_NAV_SUBTITLE,
  MAPBOX_SECTION_TITLE,
  NEED_INFOS_ABOUT,
  NEXT_DEPARTURES_AND_ARRIVALS,
  PROVIDERS,
  PROVIDERS_CONTENT,
  PROVIDERS_CONTENT_FIRST_ARRIVAL,
  PROVIDERS_CONTENT_FIRST_DEPARTURE,
  PROVIDERS_CONTENT_LAST_ARRIVAL,
  PROVIDERS_CONTENT_LAST_DEPARTURE,
  PROVIDERS_CONTENT_TRAINS_PER_DAY,
  PROVIDERS_NAV_TITLE,
  PROVIDERS_SECTION_INTRO,
  PROVIDERS_SECTION_TITLE,
  RAILWAY,
  RAILWAY_HIGHWAY,
  RIGHT_PLACE,
  SERVICED_STATIONS,
  SHOW_LESS_RESULTS,
  SHOW_MORE_RESULTS,
  STATION_ADDRESS,
  STATION_CHANGE_NAV_TITLE,
  STATION_CHANGE_SECTION_TITLE,
  STATION_HEADER_TITLE,
  STATION_PROVIDERS,
  SWITCH_TO_ANOTHER_STATION_TEXT,
  SWITCH_TO_ANOTHER_STATION_TITLE,
  TRIP_IDENTIFIER,
  TRIPS_PER_DAY,
  USEFUL_INFOS,
  USEFUL_INFOS_CAPTION,
  USEFUL_INFOS_NAV_TITLE,
  USEFUL_INFOS_SECTION_TITLE,
  WHERE_IS_STATION,
} from '../constants';

export const stationMessagesScope = 'app.containers.Station';

export const stationMessages = {
  [STATION_HEADER_TITLE]: {
    id: `${stationMessagesScope}.${STATION_HEADER_TITLE}`,
    defaultMessage: 'Découvrez la Gare',
    en: 'Discover the Station',
  },
  [RAILWAY]: {
    id: `${stationMessagesScope}.${RAILWAY}`,
    defaultMessage: 'Ferroviaire :',
    en: 'Railway',
  },
  [HIGHWAY]: {
    id: `${stationMessagesScope}.${HIGHWAY}`,
    defaultMessage: 'Routière :',
    en: 'Highway',
  },
  [RAILWAY_HIGHWAY]: {
    id: `${stationMessagesScope}.${RAILWAY_HIGHWAY}`,
    defaultMessage: 'Ferroviaire & Routière :',
    en: 'Railway & Highway:',
  },
  [USEFUL_INFOS]: {
    id: `${stationMessagesScope}.${USEFUL_INFOS}`,
    defaultMessage: 'Toutes les informations utiles pour votre voyage à',
    en: 'Useful informations for your trip at',
  },
  [USEFUL_INFOS_CAPTION]: {
    id: `${stationMessagesScope}.${USEFUL_INFOS_CAPTION}`,
    defaultMessage: 'Informations utiles pour votre voyage',
    en: 'Useful informations for your trip',
  },
  [ADDRESS]: {
    id: `${stationMessagesScope}.${ADDRESS}`,
    defaultMessage: 'Adresse',
    en: 'Address',
  },
  [DESTINATIONS_EXAMPLE]: {
    id: `${stationMessagesScope}.${DESTINATIONS_EXAMPLE}`,
    defaultMessage: 'Exemples de destinations desservies',
    en: 'Example destinations served',
  },
  [STATION_PROVIDERS]: {
    id: `${stationMessagesScope}.${STATION_PROVIDERS}`,
    defaultMessage: 'Compagnies desservants la gare',
    en: '',
  },
  [JOURNEYS_PER_DAY]: {
    id: `${stationMessagesScope}.${JOURNEYS_PER_DAY}`,
    defaultMessage: 'Nombre de trajets/jour',
    en: 'Average number of journey per day',
  },
  [FIRST_LAST_DEPARTURE]: {
    id: `${stationMessagesScope}.${FIRST_LAST_DEPARTURE}`,
    defaultMessage: 'Premier | dernier départ',
    en: 'First | last departure',
  },
  [DATA_UNAVAILABLE]: {
    id: `${stationMessagesScope}.${DATA_UNAVAILABLE}`,
    defaultMessage: 'Donnée indisponible',
    en: 'Data unavailable',
  },
  [USEFUL_INFOS_SECTION_TITLE]: {
    id: `${stationMessagesScope}.${USEFUL_INFOS_SECTION_TITLE}`,
    defaultMessage: 'Informations importantes concernant {stationName}',
    en: 'Essential informations about {stationName}',
  },
  [USEFUL_INFOS_NAV_TITLE]: {
    id: `${stationMessagesScope}.${USEFUL_INFOS_NAV_TITLE}`,
    defaultMessage: 'Informations utiles',
    en: 'Essential information',
  },
  [NEED_INFOS_ABOUT]: {
    id: `${stationMessagesScope}.${NEED_INFOS_ABOUT}`,
    defaultMessage: 'Besoin de renseignements concernant {stationName} ?',
    en: 'Need informations about {stationName}?',
  },
  [RIGHT_PLACE]: {
    id: `${stationMessagesScope}.${RIGHT_PLACE}`,
    defaultMessage: 'Vous êtes au bon endroit !',
    en: 'You are in the right place!',
  },
  [STATION_ADDRESS]: {
    id: `${stationMessagesScope}.${STATION_ADDRESS}`,
    defaultMessage: `{stationName}, gare de {cityName}, se situe au <strong>{stationAddress}.</strong> 
      C'est pratique d'avoir l'adresse de sa gare de départ ou d'arrivée à {cityName}, non ?`,
    en: `{stationName}, a station of {cityName}, is at <strong>{stationAddress}.</strong>
      It's handy to have the address of your departure or arrival station in {cityName}, isn't it?`,
  },
  [SERVICED_STATIONS]: {
    id: `${stationMessagesScope}.${SERVICED_STATIONS}`,
    defaultMessage:
      'Sachez également que {stationName} est en liaison direct avec les villes de <strong>{servicedStations}, et plus encore.</strong>',
    en: 'You should also know that {stationName} is directly linked to the cities of <strong>{servicedStations}, and more.</strong>',
  },
  [PROVIDERS]: {
    id: `${stationMessagesScope}.${PROVIDERS}`,
    defaultMessage:
      "Au départ et à l'arrivée de {stationName}, vous pourrez voyager en {stationTransport} avec <strong>{providers}.</strong>",
    en: 'You can travel from and to {stationName} by {stationTransport} with <strong><{providers}.</strong>',
  },
  [TRIPS_PER_DAY]: {
    id: `${stationMessagesScope}.${TRIPS_PER_DAY}`,
    defaultMessage:
      '{stationName} enregistre <strong>{tripNumber} trajets par jour.</strong>',
    en: '{stationName} records <strong>{tripNumber} trips per day.</strong>',
  },
  [FIRST_DEPARTURE]: {
    id: `${stationMessagesScope}.${FIRST_DEPARTURE}`,
    defaultMessage:
      '<strong>Le premier départ {stationName}</strong> quotidien est à <strong>{firstDeparture}</strong>.',
    en: '<strong>The first daily departure from {stationName}</strong> is at <strong>{firstDeparture}</strong>.',
  },
  [LAST_DEPARTURE]: {
    id: `${stationMessagesScope}.${LAST_DEPARTURE}`,
    defaultMessage:
      '<strong>Le dernier départ {stationName}</strong> quotidien est à <strong>{lastDeparture}</strong>.',
    en: '<strong>The last daily departure from {stationName}</strong> is at <strong>{lastDeparture}</strong>.',
  },
  [NEXT_DEPARTURES_AND_ARRIVALS]: {
    id: `${stationMessagesScope}.${NEXT_DEPARTURES_AND_ARRIVALS}`,
    defaultMessage:
      'Si vous souhaitez connaitre les prochains départs et prochaines arrivées à {stationName}, vous allez apprécier le tableau ci-dessous.',
    en: 'If you want to know the next departures and arrivals at {stationName}, you will appreciate the table below.',
  },
  [KEY_INFORMATIONS_NAV_SUBTITLE]: {
    id: `${stationMessagesScope}.${KEY_INFORMATIONS_NAV_SUBTITLE}`,
    defaultMessage: 'Informations importantes',
    en: 'Important information',
  },
  [DEPARTURES_ARRIVALS_NAV_SUBTITLE]: {
    id: `${stationMessagesScope}.${DEPARTURES_ARRIVALS_NAV_SUBTITLE}`,
    defaultMessage: 'Départs / Arrivées',
    en: 'Departures / Arrivals',
  },
  [MAPBOX_NAV_SUBTITLE]: {
    id: `${stationMessagesScope}.${MAPBOX_NAV_SUBTITLE}`,
    defaultMessage: 'Où est la gare ?',
    en: 'Where is the station?',
  },
  [DEPARTURES_ARRIVALS_SECTION_TITLE]: {
    id: `${stationMessagesScope}.${DEPARTURES_ARRIVALS_SECTION_TITLE}`,
    defaultMessage: 'Départs et Arrivées de {stationName}',
    en: 'Departures and Arrivals of {stationName}',
  },
  [DEPARTURES_TAB_TITLE]: {
    id: `${stationMessagesScope}.${DEPARTURES_TAB_TITLE}`,
    defaultMessage: 'Départs',
    en: 'Departures',
  },
  [ARRIVALS_TAB_TITLE]: {
    id: `${stationMessagesScope}.${ARRIVALS_TAB_TITLE}`,
    defaultMessage: 'Arrivées',
    en: 'Arrivals',
  },
  [SHOW_MORE_RESULTS]: {
    id: `${stationMessagesScope}.${SHOW_MORE_RESULTS}`,
    defaultMessage: 'Voir plus de résultats ({nbResults})',
    en: 'Show more results',
  },
  [SHOW_LESS_RESULTS]: {
    id: `${stationMessagesScope}.${SHOW_LESS_RESULTS}`,
    defaultMessage: 'Voir moins de résultats',
    en: 'Show less results',
  },
  [TRIP_IDENTIFIER]: {
    id: `${stationMessagesScope}.${TRIP_IDENTIFIER}`,
    defaultMessage: '{commercialMode} N°{tripShortName}',
    en: '{commercialMode} N°{tripShortName}',
  },
  [MAPBOX_SECTION_TITLE]: {
    id: `${stationMessagesScope}.${MAPBOX_SECTION_TITLE}`,
    defaultMessage: 'Où est {stationName} ?',
    en: 'Where is {stationName}?',
  },
  [WHERE_IS_STATION]: {
    id: `${stationMessagesScope}.${WHERE_IS_STATION}`,
    defaultMessage:
      'Comme indiqué plus haut, {stationName} se trouve au {stationAddress}. Voici la géolocalisation de {stationName}.',
    en: 'As mentioned before, {stationName} is located at {stationAddress}. Here is the geolocation of {stationName}.',
  },
  [PROVIDERS_NAV_TITLE]: {
    id: `${stationMessagesScope}.${PROVIDERS_NAV_TITLE}`,
    defaultMessage: 'Les compagnies de transport',
    en: 'Transport companies ',
  },
  [PROVIDERS_SECTION_TITLE]: {
    id: `${stationMessagesScope}.${PROVIDERS_SECTION_TITLE}`,
    defaultMessage: 'Les compagnies de transport de {stationName}',
    en: 'Transport companies of {stationName}',
  },
  [PROVIDERS_SECTION_INTRO]: {
    id: `${stationMessagesScope}.${PROVIDERS_SECTION_INTRO}`,
    defaultMessage:
      'Découvrez dès à présent les informations relatives aux voyages à {stationName} avec les différentes compagnies de transport.',
    en: 'Find out more about travelling to {stationName} with the different transport companies.',
  },
  [PROVIDERS_CONTENT]: {
    id: `${stationMessagesScope}.${PROVIDERS_CONTENT}`,
    defaultMessage:
      'Voyagez simplement en <strong>{providerName} {stationName}</strong> !',
    en: 'Simply travel by <strong>{providerName} {stationName}</strong> !',
  },
  [PROVIDERS_CONTENT_FIRST_DEPARTURE]: {
    id: `${stationMessagesScope}.${PROVIDERS_CONTENT_FIRST_DEPARTURE}`,
    defaultMessage:
      'Le <strong>premier départ</strong> en <strong>{providerName} {stationName}</strong> est à <strong>{firstDeparture}</strong>.',
    en: 'The <strong>first departure</strong> of <strong>{providerName} {stationName}</strong> is at <strong>{firstDeparture}</strong>.',
  },
  [PROVIDERS_CONTENT_FIRST_ARRIVAL]: {
    id: `${stationMessagesScope}.${PROVIDERS_CONTENT_FIRST_ARRIVAL}`,
    defaultMessage:
      'La <strong>première arrivée</strong> en <strong>{providerName} {stationName}</strong> est à <strong>{firstArrival}</strong>.',
    en: 'The <strong>first arrival</strong> of <strong>{providerName} {stationName}</strong> is at <strong>{firstArrival}</strong>.',
  },
  [PROVIDERS_CONTENT_LAST_DEPARTURE]: {
    id: `${stationMessagesScope}.${PROVIDERS_CONTENT_LAST_DEPARTURE}`,
    defaultMessage:
      'Le <strong>dernier départ</strong> en <strong>{providerName} {stationName}</strong> est à <strong>{lastDeparture}</strong>.',
    en: 'The <strong>last departure</strong> of <strong>{providerName} {stationName}</strong> is at <strong>{lastDeparture}</strong>.',
  },
  [PROVIDERS_CONTENT_LAST_ARRIVAL]: {
    id: `${stationMessagesScope}.${PROVIDERS_CONTENT_LAST_ARRIVAL}`,
    defaultMessage:
      'La <strong>dernière arrivée</strong> en <strong>{providerName} {stationName}</strong> est à <strong>{lastArrival}</strong>.',
    en: 'The <strong>last arrival</strong> of <strong>{providerName} {stationName}</strong> is at <strong>{lastArrival}</strong>.',
  },
  [PROVIDERS_CONTENT_TRAINS_PER_DAY]: {
    id: `${stationMessagesScope}.${PROVIDERS_CONTENT_TRAINS_PER_DAY}`,
    defaultMessage:
      'Chaque jour, <strong>{trainsPerDay}</strong> trajets <strong>{providerName}</strong> passent par <strong>{stationName}</strong>.',
    en: 'Every day, <strong>{trainsPerDay}</strong> trips <strong>{providerName}</strong> pass through <strong>{stationName}</strong>.',
  },
  [STATION_CHANGE_NAV_TITLE]: {
    id: `${stationMessagesScope}.${STATION_CHANGE_NAV_TITLE}`,
    defaultMessage: 'Correspondances disponibles',
    en: 'Available connections',
  },
  [STATION_CHANGE_SECTION_TITLE]: {
    id: `${stationMessagesScope}.${STATION_CHANGE_SECTION_TITLE}`,
    defaultMessage: 'Vos changements de la gare {stationName}',
    en: 'Your connections from the station {stationName}',
  },
  [SWITCH_TO_ANOTHER_STATION_TITLE]: {
    id: `${stationMessagesScope}.${SWITCH_TO_ANOTHER_STATION_TITLE}`,
    defaultMessage: 'Les changements de {stationName} à une autre gare',
    en: 'Connections from {stationName} to another station',
  },
  [SWITCH_TO_ANOTHER_STATION_TEXT]: {
    id: `${stationMessagesScope}.${SWITCH_TO_ANOTHER_STATION_TEXT}`,
    defaultMessage: `<p>Vous avez opté pour un voyage avec une escale à <strong>{stationName}</strong> mais votre prochain transport part d'une autre gare proche de <strong>{stationName}</strong>.<p>
    <p>Aucun souci, il est très simple de se déplacer entre les gares de <strong>{cityName}</strong>.<p>
    <p>Découvrez comment !</p>`,
    en: `<p>You have opted for a trip with a stopover in  <strong>{stationName}</strong> but your next transport leaves from another station near <strong>{stationName}</strong>.<p>
    <p>No problem, it is very easy to move between the stations of <strong>{cityName}</strong>.<p>
    <p>Discover how!</p>`,
  },
  [FROM_CURRENT_STATION_TO_ANOTHER_HEADER]: {
    id: `${stationMessagesScope}.${FROM_CURRENT_STATION_TO_ANOTHER_HEADER}`,
    defaultMessage:
      'Comment aller de {currentStationName} à {closeStationName}',
    en: 'How to go from {currentStationName} to {closeStationName}',
  },
  [FROM_CURRENT_STATION_TO_ANOTHER_CONTENT]: {
    id: `${stationMessagesScope}.${FROM_CURRENT_STATION_TO_ANOTHER_CONTENT}`,
    defaultMessage:
      "<strong>{currentStationName} {closeStationName}</strong> c'est tout simple ! Découvrez simplement toutes les solutions pour vous rendre de <strong>{currentStationName}</strong> à <strong>{closeStationName}</strong>.",
    en: "<strong>{currentStationName} {closeStationName}</strong> it's very simple! Discover all trips solutions to go from <strong>{currentStationName}</strong> to <strong>{closeStationName}</strong>.",
  },
  [ITINERARY_SOLUTIONS]: {
    id: `${stationMessagesScope}.${ITINERARY_SOLUTIONS}`,
    defaultMessage: "Mes solutions d'itinéraire.",
    en: 'My itinerary solutions.',
  },
  [CLOSE_STATIONS_TITLE]: {
    id: `${stationMessagesScope}.${CLOSE_STATIONS_TITLE}`,
    defaultMessage: 'Les gares à proximité',
    en: 'Nearby stations',
  },
};

export default defineMessages(stationMessages);
