/*
 * MountainPage Messages
 *
 * This contains all the text for the Mountain Page container.
 */

import { defineMessages } from 'react-intl';
import {
  MAP,
  STATION_ACTIVITIES_CHILDREN,
  STATION_ACTIVITIES_CULTURE,
  STATION_ACTIVITIES_EVENTS,
  STATION_ACTIVITIES_FACILITIES,
  STATION_ACTIVITIES_FAMILIES,
  STATION_ACTIVITIES_SPORT,
  STATION_DISTANCE_KM,
  STATION_DURATION_MINUTES,
  STATION_FEATURE_BUDGET,
  STATION_FEATURE_COVID,
  STATION_FEATURE_POPULATION,
  STATION_FEATURE_VIEW,
  STATION_FEATURE_WINTER,
  STATION_LABEL_CULTURE,
  STATION_LABEL_FAMILY,
  STATION_LABEL_FAMILY_PLUS,
  STATION_LABEL_FRIENDS,
  STATION_LABEL_NATURE,
  STATION_LABEL_PEACE,
  STATION_LABEL_SPORT,
  STATION_PRICE_CENTS,
} from '../constants';

export const mountainScope = 'app.containers.Mountain';

export const mountainMessages = {
  subtitle: {
    id: `${mountainScope}.subtitle`,
    defaultMessage: 'Pour vous, Tictactrip déplace les montagnes !',
    en: 'Tictactrip moves mountains for you !',
    es: 'Tictactrip puede mover montañas por usted.',
    it: 'Tictactrip può spostare le montagne per voi!',
    de: 'Für Sie versetzt Tictactri Berge! ',
    nl: 'Tictactrip kan bergen voor je verzetten!',
  },
  origin: {
    id: `${mountainScope}.origin`,
    defaultMessage: "D'où partez vous ?",
    en: 'Where do you come from ?',
    es: '¿De dónde partes?',
    it: 'Da dove si parte?',
    de: 'Von wo aus starten Sie?',
    nl: 'Waar begin je? ',
  },
  destination: {
    id: `${mountainScope}.destination`,
    defaultMessage: 'Où allez vous ?',
    en: 'Where are you going ?',
    es: '¿Adónde vas?',
    it: 'Dove stai andando?',
    de: 'Wohin gehen Sie?',
    nl: 'Waar ga je heen?',
  },
  [MAP]: {
    id: `${mountainScope}.${MAP}`,
    defaultMessage: 'La carte interactive',
    en: 'Interactive map',
    es: 'El mapa interactivo',
    it: 'La mappa interattiva',
    de: 'Die interaktive Karte',
    nl: 'De interactieve kaart',
  },
  mapSubtitle: {
    id: `${mountainScope}.mapSubtitle`,
    defaultMessage:
      'Selectionnez votre <strong>région de départ</strong>, et découvrez les stations facilement accessibles depuis chez vous !',
    en: 'Select your <strong>departure region</strong>, and discover the resorts that are easily accessible from your home!',
    es: 'Seleccione su <strong>región de salida</strong> y descubra los complejos turísticos más cercanos a su domicilio.',
    it: 'Selezionate la vostra <strong>regione di partenza</strong> e scoprite i resort a poca distanza da casa vostra!',
    de: 'Wählen Sie Ihre <strong>Abfahrtsregion</strong> aus und entdecken Sie die von zu Hause aus leicht erreichbaren Urlaubsorte!',
    nl: 'Selecteer je <strong>vertrekregio</strong>, en ontdek de resorts binnen handbereik van je huis!',
  },
  stationTableInfoCaption: {
    id: `${mountainScope}.stationTableInfoCaption`,
    defaultMessage: 'Informations sur la station',
    en: 'Station general info',
    es: 'Información sobre la estación',
    it: 'Informazioni sulla stazione',
    de: 'Informationen über die Station',
    nl: 'Informatie over het station',
  },
  [STATION_DISTANCE_KM]: {
    id: `${mountainScope}.${STATION_DISTANCE_KM}`,
    defaultMessage: 'Distance moyenne',
    en: 'Distance',
    es: 'Distancia media',
    it: 'Distanza media',
    de: 'Durchschnittliche Entfernung',
    nl: 'Gemiddelde afstand',
  },
  [STATION_PRICE_CENTS]: {
    id: `${mountainScope}.${STATION_PRICE_CENTS}`,
    defaultMessage: 'Prix moyen',
    en: 'Price',
    es: 'Precio medio',
    it: 'Prezzo medio',
    de: 'Durchschnittlicher Preis',
    nl: 'Gemiddelde prijs',
  },
  [STATION_DURATION_MINUTES]: {
    id: `${mountainScope}.${STATION_DURATION_MINUTES}`,
    defaultMessage: 'Durée moyenne',
    en: 'Duration',
    es: 'Duración media',
    it: 'Durata media',
    de: 'Durchschnittliche Dauer',
    nl: 'Gemiddelde duur',
  },
  stationCTA: {
    id: `${mountainScope}.stationCTA`,
    defaultMessage: 'Y aller !',
    en: "Let's go !",
    es: '¡Vamos!',
    it: 'Andiamo!',
    de: "Los geht's!",
    nl: 'Laten we gaan!',
  },
  interviewCTA: {
    id: `${mountainScope}.interviewCTA`,
    defaultMessage: "Découvrir l'interview",
    en: 'Go to the interview',
    es: 'Ir a la entrevista',
    it: "Vai all'intervista",
    de: 'Zum Interview gehen',
    nl: 'Ga naar het interview',
  },
  stationInfoHeader: {
    id: `${mountainScope}.stationInfoHeader`,
    defaultMessage: 'Informations de transport',
    en: 'Transport information',
    es: 'Información de transporte',
    it: 'Informazioni sul trasporto',
    de: 'Transportinformationen',
    nl: 'Vervoersinformatie',
  },
  stationFeaturesHeader: {
    id: `${mountainScope}.stationFeaturesHeader`,
    defaultMessage: 'Caractéristiques',
    en: 'Features',
    es: 'Características',
    it: 'Caratteristiche',
    de: 'Merkmale',
    nl: 'Kenmerken',
  },
  stationActivitiesHeader: {
    id: `${mountainScope}.stationActivitiesHeader`,
    defaultMessage: 'Activités',
    en: 'Activities',
    es: 'Actividades',
    it: 'Attività',
    de: 'Aktivitäten',
    nl: 'Activiteiten',
  },
  stationTransportsHeader: {
    id: `${mountainScope}.stationTransportsHeader`,
    defaultMessage: "Pour s'y rendre",
    en: 'Transports',
    es: 'Cómo llegar',
    it: 'Mezzi di trasporto',
    de: 'Anreise',
    nl: 'Vervoer',
  },
  [STATION_ACTIVITIES_CHILDREN]: {
    id: `${mountainScope}.${STATION_ACTIVITIES_CHILDREN}`,
    defaultMessage: "Garde d'enfants",
    en: 'Child care',
    es: 'Cuidado de niños',
    it: "Assistenza all'infanzia",
    de: 'Kinderbetreuung',
    nl: 'Kinderopvang',
  },
  [STATION_ACTIVITIES_CULTURE]: {
    id: `${mountainScope}.${STATION_ACTIVITIES_CULTURE}`,
    defaultMessage: 'Activités culturelles',
    en: 'Cultural activities',
    es: 'Actividades culturales',
    it: 'Attività culturali',
    de: 'Kulturelle Aktivitäten',
    nl: 'Culturele activiteiten',
  },
  [STATION_ACTIVITIES_EVENTS]: {
    id: `${mountainScope}.${STATION_ACTIVITIES_EVENTS}`,
    defaultMessage: 'Événements',
    en: 'Events',
    es: 'Eventos',
    it: 'Eventi',
    de: 'Veranstaltungen',
    nl: 'Evenementen',
  },
  [STATION_ACTIVITIES_FAMILIES]: {
    id: `${mountainScope}.${STATION_ACTIVITIES_FAMILIES}`,
    defaultMessage: 'En famille',
    en: 'Family activities',
    es: 'Con la familia',
    it: 'Con la famiglia',
    de: 'In der Familie',
    nl: 'Met de familie',
  },
  [STATION_ACTIVITIES_SPORT]: {
    id: `${mountainScope}.${STATION_ACTIVITIES_SPORT}`,
    defaultMessage: 'Activités sportives',
    en: 'Sporting activities',
    es: 'Actividades deportivas',
    it: 'Attività sportive',
    de: 'Sportliche Aktivitäten',
    nl: 'Sport',
  },
  [STATION_ACTIVITIES_FACILITIES]: {
    id: `${mountainScope}.${STATION_ACTIVITIES_FACILITIES}`,
    defaultMessage: 'Infrastructures',
    en: 'Facilities',
    es: 'Infraestructura',
    it: 'Infrastrutture',
    de: 'Infrastruktur',
    nl: 'Infrastructuur',
  },
  [STATION_FEATURE_BUDGET]: {
    id: `${mountainScope}.${STATION_FEATURE_BUDGET}`,
    defaultMessage: 'Budget',
    en: 'Budget',
    es: 'Presupuesto',
    it: 'Bilancio',
    de: 'Budget',
    nl: 'Budget',
  },
  [STATION_FEATURE_VIEW]: {
    id: `${mountainScope}.${STATION_FEATURE_VIEW}`,
    defaultMessage: 'Panoramas',
    en: 'Views',
    es: 'Número de visitantes',
    it: 'Numero di visitatori',
    de: 'Besucherzahlen',
    nl: 'Bezoekersaantallen',
  },
  [STATION_FEATURE_POPULATION]: {
    id: `${mountainScope}.${STATION_FEATURE_POPULATION}`,
    defaultMessage: 'Fréquentation',
    en: 'People density',
    es: 'Densidad de personas',
    it: 'Densità di persone',
    de: 'Menschenanzahl',
    nl: 'Bevolkingsdichtheid',
  },
  [STATION_FEATURE_WINTER]: {
    id: `${mountainScope}.${STATION_FEATURE_WINTER}`,
    defaultMessage: 'Hiver & Été',
    en: 'Winter resort',
    es: 'Invierno y verano',
    it: 'Inverno e estate',
    de: 'Winter & Sommer',
    nl: 'Winter & Zomer',
  },
  [STATION_FEATURE_COVID]: {
    id: `${mountainScope}.${STATION_FEATURE_COVID}`,
    defaultMessage: 'Mesures COVID',
    en: 'Covid-proof',
    es: 'Medidas COVID',
    it: 'Misure COVID',
    de: 'COVID-Messungen',
    nl: 'COVID-metingen',
  },
  [STATION_LABEL_SPORT]: {
    id: `${mountainScope}.${STATION_LABEL_SPORT}`,
    defaultMessage: 'Vacances sportives',
    en: 'Sports holidays',
    es: 'Vacaciones deportivas',
    it: 'Vacanze sportive',
    de: 'Sportliche Ferien',
    nl: 'Sportvakanties',
  },
  [STATION_LABEL_FAMILY]: {
    id: `${mountainScope}.${STATION_LABEL_FAMILY}`,
    defaultMessage: 'Vacances en famille',
    en: 'Family holidays',
    es: 'Vacaciones en familia',
    it: 'Vacanze in famiglia',
    de: 'Urlaub mit der Familie',
    nl: 'Vakantie met het gezin',
  },
  [STATION_LABEL_FAMILY_PLUS]: {
    id: `${mountainScope}.${STATION_LABEL_FAMILY_PLUS}`,
    defaultMessage: 'Vacances en famille (label <strong>Famille Plus</strong>)',
    en: 'Family holidays (label <strong>Famille Plus</strong>)',
    es: 'Vacaciones en familia (etiqueta <strong>Famille Plus</strong>)',
    it: 'Vacanze in famiglia (marchio <strong>Famille Plus</strong>)',
    de: 'Urlaub mit der Familie (Label <strong>Familie Plus</strong>)',
    nl: 'Gezinsvakanties (label <strong>Famille Plus</strong>)',
  },
  [STATION_LABEL_FRIENDS]: {
    id: `${mountainScope}.${STATION_LABEL_FRIENDS}`,
    defaultMessage: 'Vacances entre amis',
    en: 'Friends holidays',
    es: 'Vacaciones con amigos',
    it: 'Vacanze con gli amici',
    de: 'Urlaub mit Freunden',
    nl: 'Vakantie met vrienden',
  },
  [STATION_LABEL_PEACE]: {
    id: `${mountainScope}.${STATION_LABEL_PEACE}`,
    defaultMessage: 'Vacances reposantes',
    en: 'Peaceful holidays',
    es: 'Vacaciones relajantes',
    it: 'Vacanze rilassanti',
    de: 'Erholsame Ferien',
    nl: 'Ontspannende vakantie',
  },
  [STATION_LABEL_CULTURE]: {
    id: `${mountainScope}.${STATION_LABEL_CULTURE}`,
    defaultMessage: 'Vacances culturelles',
    en: 'Cultural holidays',
    es: 'Fiestas culturales',
    it: 'Vacanze culturali',
    de: 'Kultureller Urlaub',
    nl: 'Culturele feestdagen',
  },
  [STATION_LABEL_NATURE]: {
    id: `${mountainScope}.${STATION_LABEL_NATURE}`,
    defaultMessage: 'Vacances nature',
    en: 'Nature holidays',
    es: 'Vacaciones en la naturaleza',
    it: 'Vacanze nella natura',
    de: "Natururlaub'.",
    nl: 'Vakantie in de natuur',
  },
};

export default defineMessages(mountainMessages);
