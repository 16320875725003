import { useState } from 'react';
import { BASIC_REQUEST_HEADERS, HTTP } from '../../../helpers/constants';
import { Sentry } from '../../../libs/sentry';
import request from '../../../utils/request';
import { URL_NEWSLETTER } from '../constants';

export const useNewsletterForm = () => {
  const [email, setEmail] = useState<string>('');
  const [status, setStatus] = useState<'ERROR' | 'SUCCESS' | 'LOADING' | null>(
    null,
  );

  const onSubmit = async () => {
    try {
      setStatus('LOADING');

      await request(URL_NEWSLETTER, {
        method: HTTP.METHODS.POST,
        headers: BASIC_REQUEST_HEADERS,
        body: JSON.stringify({
          name: '',
          email,
        }),
      });

      setStatus('SUCCESS');
    } catch (error) {
      if (!error.capturedBySentry) {
        Sentry.captureException(error);
      }

      setStatus('ERROR');
    }
  };

  return { email, onSubmit, onEmailChange: setEmail, status };
};
