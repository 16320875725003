import { defineMessages } from 'react-intl';
import {
  DESTINATION_PLACE_INPUT_PLACEHOLDER,
  ORIGIN_PLACE_INPUT_PLACEHOLDER,
} from '../constants';

export const tsbScope = 'app.containers.TravelSearchBar';

export const tsbMessages = {
  header: {
    id: `${tsbScope}.header`,
    defaultMessage: 'Réservez vos billets de train, bus et covoiturage.',
    en: 'Book your train, bus and carpool tickets.',
    es: 'Reserve sus billetes de tren, autobús y coche compartido.',
    de: 'Buchen Sie Ihre Zug-, Bus- und Mitfahrgelegenheits-Tickets.',
    it: 'Prenota i tuoi biglietti del treno, autobus e carpooling.',
    nl: 'Boek uw trein-, bus- en carpooltickets.',
  },
  subHeader: {
    id: `${tsbScope}.subHeader`,
    defaultMessage:
      'Se déplacer devrait toujours être simple, agréable et abordable. <br />Bienvenue chez Tictactrip.',
    en: 'Getting around should always be simple, enjoyable, and affordable. <br />Welcome to Tictactrip.',
    es: 'Moverse siempre debería ser sencillo, agradable y económico. <br />Bienvenido a Tictactrip.',
    de: 'Mobilität sollte immer einfach, angenehm und erschwinglich sein. <br />Willkommen bei Tictactrip.',
    it: 'Spostarsi dovrebbe sempre essere semplice, piacevole ed economico. <br />Benvenuti in Tictactrip.',
    nl: 'Reizen zou altijd eenvoudig, leuk en betaalbaar moeten zijn. <br />Welkom bij Tictactrip.',
  },
  originPlaceResultsHeader: {
    id: `${tsbScope}.originPlaceResultsHeader`,
    defaultMessage: 'Choisissez votre lieu de départ',
    en: 'Choose your origin city',
    it: 'Scegliere la località di partenza',
    es: 'Elija su ubicación de partida',
    de: 'Wählen Sie Ihren Startort',
    nl: 'Kies uw startlocatie',
  },
  destinationPlaceResultsHeader: {
    id: `${tsbScope}.destinationPlaceResultsHeader`,
    defaultMessage: 'Choisissez votre lieu ',
    en: 'Choose your place of ',
    it: 'Scegliete la vostra posizione ',
    es: 'Elija su ubicación ',
    de: 'Wählen Sie Ihren Ort ',
    nl: 'Kies uw locatie ',
  },
  destinationPlaceResultsHeaderGreen: {
    id: `${tsbScope}.destinationPlaceResultsHeaderGreen`,
    defaultMessage: "d'arrivée",
    en: 'arrival',
    it: 'di arrivo',
    es: 'de llegada',
    de: 'der Ankunft',
    nl: 'van aankomst',
  },
  inputsHeader: {
    id: `${tsbScope}.inputsHeader`,
    defaultMessage: 'Quel est votre trajet ?',
    en: 'Where do you want to go ?',
    it: 'Qual è il vostro percorso?',
    es: '¿Dónde quiere ir?',
    de: 'Was ist Ihre Route?',
    nl: 'Wat is je route?',
  },
  [ORIGIN_PLACE_INPUT_PLACEHOLDER]: {
    id: `${tsbScope}.${ORIGIN_PLACE_INPUT_PLACEHOLDER}`,
    defaultMessage: 'Saisissez votre ville de départ',
    en: 'Enter your origin city',
    it: 'Inserire la città di partenza',
    es: 'Introduzca su ciudad de salida',
    de: 'Geben Sie Ihre Abreisestadt ein',
    nl: 'Vul uw stad van vertrek in',
  },
  [DESTINATION_PLACE_INPUT_PLACEHOLDER]: {
    id: `${tsbScope}.${DESTINATION_PLACE_INPUT_PLACEHOLDER}`,
    defaultMessage: "Saisissez votre ville d'arrivée",
    en: 'Enter your destination city',
    it: 'Inserire la città di arrivo',
    es: 'Introduzca su ciudad de llegada',
    de: 'Geben Sie Ihre Ankunftsstadt ein',
    nl: 'Vul uw stad van aankomst in',
  },
  departureDateInputPlaceholder: {
    id: `${tsbScope}.departureDateInputPlaceholder`,
    defaultMessage: 'Choisissez votre de date de départ',
    en: 'Choose your departure date',
    it: 'Scegliete la data di partenza',
    es: 'Elija su fecha de salida',
    de: 'Wählen Sie Ihr Abreisedatum',
    nl: 'Kies uw vertrekdatum',
  },
  returnDateInputPlaceholder: {
    id: `${tsbScope}.returnDateInputPlaceholder`,
    defaultMessage: 'Choisissez votre date de retour',
    en: 'Choose your return date',
    it: 'Scegliere la data di rientro',
    es: 'Elija su fecha de regreso',
    de: 'Wählen Sie Ihr Rückreisedatum',
    nl: 'Kies uw terugkeerdatum',
  },
  departureCalendarHeader: {
    id: `${tsbScope}.departureCalendarHeader`,
    defaultMessage: 'Choisissez votre date de départ',
    en: 'Choose your departure date',
    it: 'Scegliete la data di partenza',
    es: 'Elija su fecha de salida',
    de: 'Wählen Sie Ihr Abreisedatum',
    nl: 'Kies uw vertrekdatum',
  },
  returnCalendarHeader: {
    id: `${tsbScope}.returnCalendarHeader`,
    defaultMessage: 'Choisissez votre date de ',
    en: 'Choose your date of ',
    it: 'Scegliete la data di ',
    es: 'Elija su fecha de ',
    de: 'Wählen Sie Ihr Datum von ',
    nl: 'Kies uw datum van ',
  },
  returnCalendarHeaderGreen: {
    id: `${tsbScope}.returnCalendarHeaderGreen`,
    defaultMessage: 'retour',
    en: 'return',
    it: 'ritorno',
    es: 'volver',
    de: 'rückreise',
    nl: 'terug',
  },
  defaultResultsHeader: {
    id: `${tsbScope}.defaultResultsHeader`,
    defaultMessage: 'Trouvez votre trajet sur Tictactrip',
    en: 'Find your journey with Tictactrip',
    it: 'Trova il tuo viaggio su Tictactrip',
    es: 'Encuentra tu viaje en Tictactrip',
    de: 'Finden Sie Ihre Fahrt auf Tictactrip',
    nl: 'Vind uw reis op Tictactrip',
  },
  submitStartDate: {
    id: `${tsbScope}.submitStartDate`,
    defaultMessage: 'Choisir cette date de départ',
    en: 'Select this departure date',
    it: 'Scegliere questa data di partenza',
    es: 'Elija esta fecha de salida',
    de: 'Dieses Abreisedatum wählen',
    nl: 'Kies deze vertrekdatum',
  },
  submitReturnDate: {
    id: `${tsbScope}.submitReturnDate`,
    defaultMessage: 'Choisir cette date de retour',
    en: 'Select this return date',
    it: 'Scegliere questa data di ritorno',
    es: 'Elija esta fecha de regreso',
    de: 'Dieses Datum für die Rückkehr wählen',
    nl: 'Kies deze inleverdatum',
  },
  clearReturnDate: {
    id: `${tsbScope}.clearReturnDate`,
    defaultMessage: 'Ne pas choisir de billet retour',
    en: 'One way only',
    it: 'Non scegliere un biglietto di andata e ritorno',
    es: 'No elija un billete de vuelta',
    de: 'Kein Rückflugticket wählen',
    nl: 'Kies geen retourticket',
  },
  timePickerHeader: {
    id: `${tsbScope}.timePickerHeader`,
    defaultMessage: 'À partir de',
    en: 'Leaving at',
    it: 'Partenza a',
    es: 'Desde',
    de: 'Ab',
    nl: 'Van',
  },
  passengersHeader: {
    id: `${tsbScope}.passengersHeader`,
    defaultMessage: 'Dites nous qui voyage',
    en: 'Who is travelling',
    it: 'Diteci chi è in viaggio',
    es: 'Díganos quién viaja',
    de: 'Sagen Sie uns, wer reist',
    nl: 'Vertel ons wie er reist',
  },
  passengers: {
    id: `${tsbScope}.passengers`,
    defaultMessage: 'Passagers',
    en: 'Passengers',
    it: 'Passeggeri',
    es: 'Pasajeros',
    de: 'Passagiere',
    nl: 'Passagiers',
  },
  passengerYoungSelect: {
    id: `${tsbScope}.passengerYoungSelect`,
    defaultMessage: 'Jeune (0-27)',
    en: 'Youth (0-27)',
    it: 'Giovani (0-27)',
    es: 'Jóvenes (0-27)',
    de: 'Jugendlicher (0-27)',
    nl: 'Jong (0-27)',
  },
  passengerAdultSelect: {
    id: `${tsbScope}.passengerAdultSelect`,
    defaultMessage: 'Adulte (28-59)',
    en: 'Adult (28-59)',
    it: 'Adulti (28-59)',
    es: 'Adulto (28-59)',
    de: 'Erwachsener (28-59)',
    nl: 'Volwassene (28-59)',
  },
  passengerSeniorSelect: {
    id: `${tsbScope}.passengerSeniorSelect`,
    defaultMessage: 'Senior (60+)',
    en: 'Senior (60+)',
    it: 'Senior (60+)',
    es: 'Senior (60+)',
    de: 'Senior (60+)',
    nl: 'Senior (60+)',
  },
  youths: {
    id: `${tsbScope}.youths`,
    defaultMessage: 'jeune',
    en: 'Youth',
    it: 'giovane',
    es: 'joven',
    de: 'junge',
    nl: 'jonge',
  },
  adults: {
    id: `${tsbScope}.adults`,
    defaultMessage: 'adulte',
    en: 'adult',
    it: 'adulto',
    es: 'adultos',
    de: 'erwachsener',
    nl: 'volwassene',
  },
  seniors: {
    id: `${tsbScope}.seniors`,
    defaultMessage: 'senior',
    en: 'Senior',
    it: 'senior',
    es: 'senior',
    de: 'senior',
    nl: 'senior',
  },
  card: {
    id: `${tsbScope}.card`,
    defaultMessage: 'carte',
    en: 'card',
    it: 'carta',
    es: 'tarjeta',
    de: 'karte',
    nl: 'kaart',
  },
  cards: {
    id: `${tsbScope}.cards`,
    defaultMessage: 'cartes',
    en: 'cards',
    es: 'tarjetas',
    it: 'carte',
    de: 'karten',
    nl: 'kaarten',
  },
  addPassengers: {
    id: `${tsbScope}.addPassengers`,
    defaultMessage: 'Ajouter un autre passager',
    en: 'Add another passenger',
    it: 'Aggiungere un altro passeggero',
    es: 'Añadir otro pasajero',
    de: 'Einen weiteren Mitreisenden hinzufügen',
    nl: 'Nog een passagier toevoegen',
  },
  editAge: {
    id: `${tsbScope}.editAge`,
    defaultMessage: 'Âge du passager',
    en: 'Age of the passenger',
    it: 'Età del passeggero',
    es: 'Edad del pasajero',
    de: 'Alter des Passagiers',
    nl: 'Leeftijd van de passagier',
  },
  submit: {
    id: `${tsbScope}.submit`,
    defaultMessage: 'Rechercher',
    en: 'Search',
    it: 'Ricerca',
    es: 'Buscar en',
    de: 'Suche',
    nl: 'Zoeken',
  },
  addDiscountCard: {
    id: `${tsbScope}.addDiscountCard`,
    defaultMessage: 'Carte de réduction',
    en: 'Discount Card',
    es: 'Tarjeta de descuento',
    it: 'Carta sconto',
    de: 'Ermäßigungskarte',
    nl: 'Kortingskaart',
  },
  submitEditPassenger: {
    id: `${tsbScope}.submitEditPassenger`,
    defaultMessage: 'Valider ce passager',
    en: 'Submit passenger',
    it: 'Convalidare questo passeggero',
    es: 'Valide a este pasajero',
    de: 'Diesen Passagier bestätigen',
    nl: 'Valideer deze passagier',
  },
  submitPassenger: {
    id: `${tsbScope}.submitPassenger`,
    defaultMessage: 'Valider les passagers',
    en: 'Submit passengers',
    it: 'Convalida dei passeggeri',
    es: 'Validación de pasajeros',
    de: 'Passagiere validieren',
    nl: 'Passagiers valideren',
  },
  noResults: {
    id: `${tsbScope}.noResults`,
    defaultMessage: 'Aucun résultat trouvé',
    en: 'No results found',
    it: 'Nessun risultato trovato',
    es: 'No se han encontrado resultados',
    de: 'Keine Ergebnisse gefunden',
    nl: 'Geen resultaten gevonden',
  },

  adultPassenger: {
    id: `${tsbScope}.adultPassenger`,
    defaultMessage: '{count, plural,=0 {adulte} one {adulte} other {adultes}}',
    en: '{count, plural, =0 {adult} one {adult} other {adults}}',
    it: '{count, plural, =0 {adulto} one {adulto} other {adulti}}',
    es: '{count, plural, =0 {adulto} one {adulto} other {adultos}}',
    de: '{count, plural, =0 {erwachsener} one {erwachsener} other {erwachsene}}',
    nl: '{count, plural, =0 {volwassene} one {volwassene} other {volwassenen}}',
  },
  youngPassenger: {
    id: `${tsbScope}.youngPassenger`,
    defaultMessage: '{count, plural, =0 {jeune} one {jeune} other {jeunes}}',
    en: '{count, plural, =0 {young}  one {young} other {young}}',
    it: '{count, plural, =0 {giovane} one {giovane} other {giovani}}',
    es: '{count, plural, =0 {joven} one {joven} other {jóvenes}}',
    de: '{count, plural, =0 {junge} one {junge} other {jugendliche}}',
    nl: '{count, plural, =0 {jonge} one {jonge} other {jongeren}}',
  },
  seniorPassenger: {
    id: `${tsbScope}.seniorPassenger`,
    defaultMessage: '{count, plural, =0 {senior} one {senior} other {seniors}}',
    en: '{count, plural, =0 {senior} one {senior} other {seniors}}',
    it: '{count, plural, =0 {senior} one {senior} other {anziani}}',
    es: '{count, plural, =0 {senior} one {senior} other {personas mayores}}',
    de: '{count, plural, =0 {senior} one {senior} other {senioren}}',
    nl: '{count, plural, =0 {senior} one {senior} other {senioren}}',
  },
  cardPassenger: {
    id: `${tsbScope}.cardPassenger`,
    defaultMessage: '{count, plural, =0 {carte} one {carte} other {cartes}}',
    en: '{count, plural, =0 {card}  one {card} other {cards}}',
    it: '{count, plural, =0 {carta} one {carta} other {carte}}',
    es: '{count, plural, =0 {tarjeta} one {tarjeta} other {tarjetas}}',
    de: '{count, plural, =0 {karte} one {karte} other {Karten}}',
    nl: '{count, plural, =0 {kaart} one {kaart} other {kaarten}}',
  },
  editPassengerOrAddCard: {
    id: `${tsbScope}.editPassengerOrAddCard`,
    defaultMessage: 'Modifier le passager',
    en: 'Edit the passenger',
    it: 'Modificare il passeggero',
    es: 'Editar el pasajero',
    de: 'Bearbeiten Sie den Passagier',
    nl: 'Bewerk de passagier',
  },
  discountCardInfo: {
    id: `${tsbScope}.discountCardInfo`,
    defaultMessage:
      'Le numéro de carte {discountCardLabel} vous sera demandé au moment de la commande.',
    en: 'The {discountCardLabel} card number will be requested at the time of the order.',
    it: "Il numero della carta {discountCardLabel} sarà richiesto al momento dell'ordine.",
    es: 'El número de tarjeta {discountCardLabel} se solicitará en el momento del pedido.',
    de: 'Die {discountCardLabel} Kartennummer wird bei der Bestellung abgefragt.',
    nl: 'Het nummer van de {discountCardLabel} kaart zal worden gevraagd op het moment van de bestelling.',
  },
};

export default defineMessages(tsbMessages);
