import { homepageScope } from '../constants';

export const homePageHighlightMessages = {
  highlightCountries: {
    id: `${homepageScope}.highlightCountries`,
    defaultMessage: 'Pays en Europe',
    en: 'Countries in Europe',
    es: 'Países en Europa',
    fr: 'Pays en Europe',
    de: 'Länder in Europa',
    it: 'Paesi in Europa',
    nl: 'Landen in Europa',
  },
  highlightServedCities: {
    id: `${homepageScope}.highlightServedCities`,
    defaultMessage: 'Villes desservies',
    en: 'Served cities',
    es: 'Ciudades atendidas',
    de: 'Bediente Städte',
    it: 'Città servite',
    nl: 'Bediende steden',
  },
  highlightPartnerCompanies: {
    id: `${homepageScope}.highlightPartnerCompanies`,
    defaultMessage: 'Compagnies partenaires',
    en: 'Partner companies',
    it: 'Compagnie partner',
    es: 'Empresas asociadas',
    de: 'Partnerunternehmen',
    nl: 'Partnerbedrijven',
  },
};
