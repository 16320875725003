import { css } from 'styled-components';
import tw from 'tailwind.macro';

export const Icon12 = css`
  ${tw`h-icon-12 w-icon-12`}
`;

export const Icon18 = css`
  ${tw`h-icon-18 w-icon-18`}
`;

export const Icon24 = css`
  ${tw`h-icon-24 w-icon-24`}
`;

export const Icon36 = css`
  ${tw`h-icon-36 w-icon-36`}
`;

export const Icon40 = css`
  ${tw`h-icon-40 w-icon-40`}
`;

export const Icon60 = css`
  ${tw`h-icon-60 w-icon-60`}
`;
