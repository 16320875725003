import { ETransportType } from '../../common/types';

export enum SearchPageType {
  TRAIN = 'trajet-train',
  BUS = 'trajet-bus',
  CARPOOLING = 'trajet-covoiturage',
  GENERIC = 'generic',
}

export type DynamicSearchPageInformation = {
  price: {
    min: number;
    max: number;
  } | null;
  duration: {
    min: number;
    max: number;
  } | null;
  dailyTripsPerDay: number;
  companies: Array<{
    fullname: string;
    transportType: string;
  }>;
  minNbStops: number;
  pageType: SearchPageType;
  transportType: ETransportType | null;
};

export type GetFormattedTripMessagesResponse = {
  formattedTrip1Message: string;
  formattedTrip2Message: string;
  formattedTrip3Message: string;
};
