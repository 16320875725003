import { defineMessages } from 'react-intl';
import { TransportId } from '../../services/co2/types';
import { TripComparatorTabs } from './types';

export const tripComparatorScope = 'app.components.tripComparator';

export const tripComparatorMessages = {
  [TripComparatorTabs.CHEAP_TICKETS]: {
    id: `${tripComparatorScope}.${TripComparatorTabs.CHEAP_TICKETS}`,
    defaultMessage: 'Payer ses billets moins cher',
    en: 'Pay for tickets less',
    it: 'Paga i biglietti meno',
    es: 'Pagar menos por los billetes',
    de: 'Weniger für Tickets bezahlen',
    nl: 'Betaal minder voor tickets',
  },
  [TripComparatorTabs.REDUCE_EMISSIONS]: {
    id: `${tripComparatorScope}.${TripComparatorTabs.REDUCE_EMISSIONS}`,
    defaultMessage: 'Réduire ses émissions',
    en: 'Reduce emissions',
    it: 'Ridurre le emissioni',
    es: 'Reducir emisiones',
    de: 'Emissionen reduzieren',
    nl: 'Verminder emissies',
  },
  [TripComparatorTabs.HAVE_FUN]: {
    id: `${tripComparatorScope}.${TripComparatorTabs.HAVE_FUN}`,
    defaultMessage: 'Se taper une barre',
    en: 'Have a good time',
    it: 'Divertirsi',
    es: 'Pasarlo bien',
    de: 'Eine gute Zeit haben',
    nl: 'Een leuke tijd hebben',
  },
  insteadOf: {
    id: `${tripComparatorScope}.insteadOf`,
    defaultMessage: "plutôt qu'en",
    en: 'instead of',
    it: 'anziché',
    es: 'en lugar de',
    de: 'anstatt',
    nl: 'in plaats van',
  },
  insteadOf2: {
    id: `${tripComparatorScope}.insteadOf2`,
    defaultMessage: 'plutôt que',
    en: 'instead of',
    it: 'anziché',
    es: 'en lugar de',
    de: 'anstatt',
    nl: 'in plaats van',
  },
  emitLessCO2: {
    id: `${tripComparatorScope}.emitLessCO2`,
    defaultMessage: "J'émets {percentage} de CO2 en moins",
    en: 'I emit {percentage} less CO2',
    it: 'Emitto il {percentage} in meno di CO2',
    es: 'Emito un {percentage} menos de CO2',
    de: 'Ich emittiere {percentage} weniger CO2',
    nl: 'Ik stoot {percentage} minder CO2 uit',
  },
  emitMoreCO2: {
    id: `${tripComparatorScope}.emitMoreCO2`,
    defaultMessage: "J'émets {percentage} de CO2 en plus",
    en: 'I emit {percentage} more CO2',
    it: 'Emetto il {percentage} di CO2 in più',
    es: 'Emito un {percentage} más de CO2',
    de: 'Ich stoße {percentage} mehr CO2 aus',
    nl: 'Ik stoot {percentage} meer CO2 uit',
  },
  shareResults: {
    id: `${tripComparatorScope}.shareResults`,
    defaultMessage:
      'Partagez vos résultats, donnez envie de voyagez plus et polluer moins. Pour réserver vos billets ça se passe en haut, à vous de jouer.',
    en: "Share your results, inspire others to travel more and pollute less. To book your tickets, it's at the top; it's your turn to play.",
    it: 'Condividi i tuoi risultati, ispira gli altri a viaggiare di più e inquinare di meno. Per prenotare i tuoi biglietti, è in alto; tocca a te.',
    es: 'Comparte tus resultados, inspira a otros a viajar más y contaminar menos. Para reservar tus boletos, está en la parte superior; te toca a ti.',
    de: 'Teile deine Ergebnisse, inspiriere andere, mehr zu reisen und weniger zu verschmutzen. Um Ihre Tickets zu buchen, geht es oben; jetzt sind Sie dran.',
    nl: 'Deel je resultaten, inspireer anderen om meer te reizen en minder te vervuilen. Om je tickets te boeken, ga naar boven; nu is het aan jou.',
  },
  shareGoodNews: {
    id: `${tripComparatorScope}.shareGoodNews`,
    defaultMessage: 'Envie de partager la bonne nouvelle ?',
    en: 'Feel like sharing the good news?',
    it: 'Ti va di condividere la buona notizia?',
    es: '¿Te gustaría compartir las buenas noticias?',
    de: 'Lust, die gute Nachricht zu teilen?',
    nl: 'Wil je het goede nieuws delen?',
  },
  [TransportId.TGV]: {
    id: `${tripComparatorScope}.${TransportId.TGV}`,
    defaultMessage: 'en train',
    en: 'by train',
    it: 'in treno',
    es: 'en tren',
    de: 'mit dem Zug',
    nl: 'met de trein',
  },
  [TransportId.THERMAL_CAR]: {
    id: `${tripComparatorScope}.${TransportId.THERMAL_CAR}`,
    defaultMessage: 'en voiture',
    en: 'by car',
    it: 'in auto',
    es: 'en coche',
    de: 'mit dem Auto',
    nl: 'met de auto',
  },
  [TransportId.THERMAL_BUS]: {
    id: `${tripComparatorScope}.${TransportId.THERMAL_BUS}`,
    defaultMessage: 'en bus',
    en: 'by bus',
    it: 'in autobus',
    es: 'en autobús',
    de: 'mit dem Bus',
    nl: 'met de bus',
  },
  [TransportId.PLANE]: {
    id: `${tripComparatorScope}.${TransportId.PLANE}`,
    defaultMessage: 'en avion',
    en: 'by plane',
    it: 'in aereo',
    es: 'en avión',
    de: 'mit dem Flugzeug',
    nl: 'met het vliegtuig',
  },
  moreLaughter: {
    id: `${tripComparatorScope}.moreLaughter`,
    defaultMessage: 'Vous allez rire au moins {percentage} de plus',
    en: "You'll laugh at least {percentage} more",
    it: 'Riderai almeno {percentage} di più',
    es: 'Te reirás al menos un {percentage} más',
    de: 'Du wirst mindestens {percentage} mehr lachen',
    nl: 'Je zult minstens {percentage} meer lachen',
  },
  withTictactrip: {
    id: `${tripComparatorScope}.withTictactrip`,
    defaultMessage: 'Avec Tictactrip',
    en: 'With Tictactrip',
    it: 'Con Tictactrip',
    es: 'Con Tictactrip',
    de: 'Mit Tictactrip',
    nl: 'Met Tictactrip',
  },
  travelingFromTo: {
    id: `${tripComparatorScope}.travelingFromTo`,
    defaultMessage: 'En voyageant de {originCity} à {destinationCity}',
    en: 'Traveling from {originCity} to {destinationCity}',
    it: 'Viaggiando da {originCity} a {destinationCity}',
    es: 'Viajando de {originCity} a {destinationCity}',
    de: 'Reisen von {originCity} nach {destinationCity}',
    nl: 'Reizen van {originCity} naar {destinationCity}',
  },
  bookingTrip: {
    id: `${tripComparatorScope}.bookingTrip`,
    defaultMessage: 'En réservant mon trajet',
    en: 'By booking my trip',
    it: 'Prenotando il mio viaggio',
    es: 'Al reservar mi viaje',
    de: 'Indem ich meine Reise buche',
    nl: 'Door mijn reis te boeken',
  },
  share: {
    id: `${tripComparatorScope}.share`,
    defaultMessage: 'Partager',
    en: 'Share',
    it: 'Condividi',
    es: 'Compartir',
    de: 'Teilen',
    nl: 'Delen',
  },
  download: {
    id: `${tripComparatorScope}.download`,
    defaultMessage: 'Télécharger',
    en: 'Download',
    it: 'Scarica',
    es: 'Descargar',
    de: 'Herunterladen',
    nl: 'Downloaden',
  },
  complainingAboutPrices: {
    id: `${tripComparatorScope}.complainingAboutPrices`,
    defaultMessage: "Vous connaissez quelqu'un qui se plaint des prix ?",
    en: 'Do you know someone who complains about prices?',
    it: 'Conosci qualcuno che si lamenta dei prezzi?',
    es: '¿Conoces a alguien que se queja de los precios?',
    de: 'Kennen Sie jemanden, der sich über die Preise beschwert?',
    nl: 'Ken je iemand die klaagt over de prijzen?',
  },
  shareToStopComplaining: {
    id: `${tripComparatorScope}.shareToStopComplaining`,
    defaultMessage:
      "Partagez-lui l'info pour qu'il arrête de se plaindre . Ensuite, pour réserver vos billets ça se passe en haut, à vous de jouer.",
    en: "Share the info with them to stop complaining. Then, to book your tickets, it's at the top; it's your turn to play.",
    it: "Condividi l'informazione con lui affinché smetta di lamentarsi. Poi, per prenotare i tuoi biglietti, è in alto; ora tocca a te.",
    es: 'Comparta la información con él para que deje de quejarse. Luego, para reservar sus boletos, está en la parte superior; ahora le toca a usted.',
    de: 'Teilen Sie ihm die Informationen mit, damit er aufhört zu jammern. Dann, um Ihre Tickets zu buchen, geht es oben; jetzt sind Sie dran.',
    nl: 'Deel de informatie met hem zodat hij stopt met klagen. Dan, om je tickets te boeken, ga naar boven; nu is het aan jou.',
  },
  saveMoneyOnTrip: {
    id: `${tripComparatorScope}.saveMoneyOnTrip`,
    defaultMessage: "J'économise jusqu'à {amount} sur mon trajet",
    en: 'I save up to {amount} on my trip',
    it: 'Risparmio fino a {amount} sul mio viaggio',
    es: 'Ahorro hasta {amount} en mi viaje',
    de: 'Ich spare bis zu {amount} auf meiner Reise',
    nl: 'Ik bespaar tot {amount} op mijn reis',
  },
  saveMoneyPercentage: {
    id: `${tripComparatorScope}.saveMoneyPercentage`,
    defaultMessage: "Soit jusqu'à {percentage} moins cher",
    en: 'Up to {percentage} cheaper',
    it: 'Fino al {percentage} in meno',
    es: 'Hasta un {percentage} más barato',
    de: 'Bis zu {percentage} günstiger',
    nl: 'Tot {percentage} goedkoper',
  },
  spendMoreOnTrip: {
    id: `${tripComparatorScope}.spendMoreOnTrip`,
    defaultMessage: "Je dépense jusqu'à {amount} de plus sur mon trajet",
    en: 'I spend up to {amount} more on my trip',
    it: 'Spendo fino a {amount} in più sul mio viaggio',
    es: 'Gasto hasta {amount} más en mi viaje',
    de: 'Ich gebe bis zu {amount} mehr für meine Reise aus',
    nl: 'Ik geef tot {amount} meer uit aan mijn reis',
  },
  spendMorePercentage: {
    id: `${tripComparatorScope}.spendMorePercentage`,
    defaultMessage: "Soit jusqu'à {percentage} plus cher",
    en: 'Up to {percentage} more expensive',
    it: 'Fino al {percentage} in più',
    es: 'Hasta un {percentage} más caro',
    de: 'Bis zu {percentage} teurer',
    nl: 'Tot {percentage} duurder',
  },
  daysBeforeInsteadOf: {
    id: `${tripComparatorScope}.daysBeforeInsteadOf`,
    defaultMessage: 'jours avant au lieu de',
    en: 'days before instead of',
    it: 'giorni prima invece di',
    es: 'días antes en lugar de',
    de: 'Tage vorher statt',
    nl: 'dagen ervoor in plaats van',
  },
  moneySavingTip: {
    id: `${tripComparatorScope}.moneySavingTip`,
    defaultMessage:
      "Tiens, j'ai trouvé une petite astuce pour payer moins cher tes billets de transport, c'est GÉ-NIAL ✌🏻",
    en: "Hey, I found a little trick to pay less for your transport tickets, it's AWESOME ✌🏻",
    it: 'Ehi, ho trovato un piccolo trucco per pagare meno i tuoi biglietti dei trasporti, è FANTASTICO ✌🏻',
    es: 'Oye, encontré un pequeño truco para pagar menos por tus billetes de transporte, ¡es GENIAL ✌🏻',
    de: 'Hey, ich habe einen kleinen Trick gefunden, um weniger für deine Transporttickets zu zahlen, es ist GENIAL ✌🏻',
    nl: 'Hé, ik heb een klein trucje gevonden om minder te betalen voor je vervoerstickets, het is GEWELDIG ✌🏻',
  },
  carbonEmissionTip: {
    id: `${tripComparatorScope}.carbonEmissionTip`,
    defaultMessage:
      "Tiens, j'ai trouvé une petite astuce pour réduire les émissions carbone liées à tes voyages, c'est GÉ-NIAL ✌🏻",
    en: "Hey, I found a little trick to reduce the carbon emissions from your travels, it's AWESOME ✌🏻",
    it: 'Ehi, ho trovato un piccolo trucco per ridurre le emissioni di carbonio dei tuoi viaggi, è FANTASTICO ✌🏻',
    es: 'Oye, encontré un pequeño truco para reducir las emisiones de carbono de tus viajes, ¡es GENIAL ✌🏻',
    de: 'Hey, ich habe einen kleinen Trick gefunden, um die Kohlenstoffemissionen deiner Reisen zu reduzieren, es ist GENIAL ✌🏻',
    nl: 'Hé, ik heb een klein trucje gevonden om de koolstofemissies van je reizen te verminderen, het is GEWELDIG ✌🏻',
  },
  launchSearch: {
    id: `${tripComparatorScope}.launchSearch`,
    defaultMessage: 'Lancer une recherche',
    en: 'Launch a search',
    it: 'Avviare una ricerca',
    es: 'Iniciar una búsqueda',
    de: 'Eine Suche starten',
    nl: 'Een zoekopdracht starten',
  },
  tripStatisticsNotAvailable: {
    id: `${tripComparatorScope}.tripStatisticsNotAvailable`,
    defaultMessage:
      'Les statistiques de ce voyage ne sont pas encore disponibles',
    en: 'The statistics for this trip are not yet available',
    it: 'Le statistiche di questo viaggio non sono ancora disponibili',
    es: 'Las estadísticas de este viaje aún no están disponibles',
    de: 'Die Statistiken für diese Reise sind noch nicht verfügbar',
    nl: 'De statistieken voor deze reis zijn nog niet beschikbaar',
  },
  statisticsUnavailable: {
    id: `${tripComparatorScope}.statisticsUnavailable`,
    defaultMessage: 'Statistiques indisponibles',
    en: 'Statistics unavailable',
    it: 'Statistiche non disponibili',
    es: 'Estadísticas no disponibles',
    de: 'Statistiken nicht verfügbar',
    nl: 'Statistieken niet beschikbaar',
  },
  loading: {
    id: `${tripComparatorScope}.loading`,
    defaultMessage: 'Loading...',
    en: 'Loading...',
    it: 'Caricamento in corso...',
    es: 'Cargando...',
    de: 'Laden...',
    nl: 'Bezig met laden...',
  },
};

export default defineMessages(tripComparatorMessages);
