import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import Cell from '../Cell';
import Loader from '../Loader';
import RoundShadowBox from '../RoundShadowBox';
import Row from '../Row';
import { lazyLoadingTestId } from './constants';

const Main = styled(RoundShadowBox)`
  ${tw`w-auto flex flex-col items-center
    my-abs10 mx-auto shadow-none
    p-def
    md:p-abs10`}
`;

const LoaderWrapper = styled.div`
  ${tw`m-auto pl-abs2`};
  @media (max-width: 768px) {
    padding-top: -0.5rem;
  }
`;

const LazyLoading = () => (
  <Row data-testid={lazyLoadingTestId}>
    <Cell width={12}>
      <Main>
        <LoaderWrapper>
          <Loader desktop={15} mobile={10} />
        </LoaderWrapper>
      </Main>
    </Cell>
  </Row>
);

export default LazyLoading;
