import { FormattedMessage } from 'react-intl';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../../containers/App/constants';
import { screens } from '../../../styles/constants';
import { Link } from '../../Link';
import messages from '../messages';

export const Container = styled.div<{
  customStyle?: FlattenSimpleInterpolation;
}>`
  ${tw`px-medium2`}

  @media (min-width: ${screens.md}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--size-medium2);
    grid-row-gap: var(--size-medium2);
  }

  ${({ customStyle }) => customStyle}
`;

export const Wrapper = styled.div`
  grid-column-start: 2;
  grid-column-end: 12;
`;

export const StyledLink = styled(Link)<{
  customStyle?: FlattenSimpleInterpolation;
}>`
  ${tw`text-p-xsmall leading-p-xsmall font-bold`}

  ${({ customStyle }) => customStyle}
`;

export type Links = Array<{
  href: string;
  children: React.ReactNode;
  name: string;
  isObfuscated: boolean;
}>;

export const ESSENTIAL_LINKS: Links = [
  {
    href: NAVIGATION.ORDERS,
    children: <FormattedMessage {...messages.yourReservations} />,
    name: '1',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.TTA,
    children: <FormattedMessage {...messages.saveMax} />,
    name: '2',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.MOUNTAIN,
    children: <FormattedMessage {...messages.interactiveMap} />,
    name: '3',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.BLOG,
    children: <FormattedMessage {...messages.travelBlog} />,
    name: '4',
    isObfuscated: false,
  },
];

export const HELP_LINKS: Links = [
  {
    href: NAVIGATION.HELP_CENTER,
    children: <FormattedMessage {...messages.helpCenter} />,
    name: '1',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.SUPPORT,
    children: <FormattedMessage {...messages.contactUs} />,
    name: '2',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.FAQ,
    children: <FormattedMessage {...messages.faq} />,
    name: '3',
    isObfuscated: false,
  },
];

export const TICTACTRIP_LINKS: Links = [
  {
    href: NAVIGATION.ABOUT,
    children: <FormattedMessage {...messages.aboutUs} />,
    name: '1',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.HOW_IT_WORKS,
    children: <FormattedMessage {...messages.howItWorks} />,
    name: '2',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.PARTNERS,
    children: <FormattedMessage {...messages.becomeAPartner} />,
    name: '3',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.JOBS,
    children: <FormattedMessage {...messages.jobs} />,
    name: '4',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.PRESS,
    children: <FormattedMessage {...messages.press} />,
    name: '5',
    isObfuscated: false,
  },
];

export const TRAIN_COMPANY_LINKS: Links = [
  {
    href: `${NAVIGATION.COMPANY}/train`,
    children: <FormattedMessage {...messages.trains} />,
    name: '1',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/train/sncf`,
    children: 'SNCF',
    name: '2',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.COMPANY}/train/sncf/ouigo`,
    children: 'Ouigo',
    name: '3',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.COMPANY}/train/thalys`,
    children: 'Thalys',
    name: '4',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/train/sncf/inoui`,
    children: 'inOui',
    name: '5',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/train/sncf/idtgv`,
    children: 'IDTGV',
    name: '6',
    isObfuscated: true,
  },
];

export const BUS_COMPANY_LINKS: Links = [
  {
    href: `${NAVIGATION.COMPANY}/bus`,
    children: <FormattedMessage {...messages.bus} />,
    name: '1',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/bus/ouibus`,
    children: 'Ouibus',
    name: '2',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/bus/flixbus`,
    children: 'Flixbus',
    name: '3',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.COMPANY}/carpooling/blablacar/blablabus`,
    children: 'Blablabus',
    name: '4',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.COMPANY}/shuttle/ski/altibus`,
    children: 'Altibus',
    name: '5',
    isObfuscated: true,
  },
];

export const CARPOOLING_COMPANY_LINKS: Links = [
  {
    href: `${NAVIGATION.COMPANY}/carpooling`,
    children: <FormattedMessage {...messages.carpooling} />,
    name: '1',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/carpooling/blablacar`,
    children: 'Blablacar',
    name: '2',
    isObfuscated: false,
  },
];

export const COMPARATOR_LINKS: Links = [
  {
    href: `${NAVIGATION.COMPANY}/comparator`,
    children: <FormattedMessage {...messages.comparator} />,
    name: '1',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/comparator/oui-sncf`,
    children: 'Oui.SNCF',
    name: '2',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/comparator/oui-sncf/voyages-sncf`,
    children: 'Voyages-sncf',
    name: '3',
    isObfuscated: true,
  },
  {
    href: `${NAVIGATION.COMPANY}/comparator/trainline`,
    children: 'Trainline',
    name: '4',
    isObfuscated: true,
  },
];

export const FREQUENT_DESTINATION_LINKS: Links = [
  {
    href: `${NAVIGATION.SEARCH}/paris/lyon`,
    children: 'Paris - Lyon ',
    name: '1',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.SEARCH}/paris/marseille`,
    children: 'Paris - Marseille',
    name: '2',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.SEARCH}/paris/toulouse`,
    children: 'Paris - Toulouse',
    name: '3',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.SEARCH}/paris/bruxelles`,
    children: 'Lyon - Bruxelles',
    name: '4',
    isObfuscated: false,
  },
  {
    href: `${NAVIGATION.SEARCH}/paris/strasbourg`,
    children: 'Paris - Strasbourg',
    name: '5',
    isObfuscated: false,
  },
];

export const LEGAL_LINKS: Links = [
  {
    href: NAVIGATION.LEGAL,
    children: <FormattedMessage {...messages.legalNotice} />,
    name: '1',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.PRIVACY,
    children: <FormattedMessage {...messages.privacy} />,
    name: '2',
    isObfuscated: false,
  },
  {
    href: NAVIGATION.TERMS_OF_SALE,
    children: <FormattedMessage {...messages.termsAndConditions} />,
    name: '3',
    isObfuscated: false,
  },
];
