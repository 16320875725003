/*
 *
 * Partners reducer
 *
 */

import { fromJS } from 'immutable';
import { ACTIVE_STEP } from './constants';

export const initialState = fromJS({
  network: true,
  partnership: false,
  createWidget: false,
});

function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_STEP:
      if (window.scrollY < 1080)
        return state.set('network', true).set('partnership', false);

      if (window.scrollY > 1080 && window.scrollY < 2650)
        return state
          .set('network', false)
          .set('partnership', true)
          .set('createWidget', false);
      if (window.scrollY > 2650)
        return state
          .set('network', false)
          .set('partnership', false)
          .set('createWidget', true);

      return state
        .set('network', false)
        .set('partnership', false)
        .set('createWidget', false);

    default:
      return state;
  }
}

export default partnersReducer;
