/*
 * LargeTravelSlider Messages
 *
 * This contains all the text for the LargeTravelSlider container.
 */

import { defineMessages } from 'react-intl';

export const largeTravelSliderScope = 'app.containers.LargeTravelSlider';

export const largeTravelSliderMessages = {
  largeTravelSliderHeader: {
    id: `${largeTravelSliderScope}.largeTravelSliderHeader`,
    defaultMessage:
      "En quête d'inspiration ? <br />Laissez-vous séduire par nos idées voyage !",
    en: 'Looking for ideas for a trip ? <br />Discover our travel suggestions!',
    it: 'Siete in cerca di ispirazione?<br /> Lasciatevi ispirare dalle nostre idee di viaggio!',
    es: '¿Busca inspiración?<br /> Eche un vistazo a nuestras ideas de viaje!',
    de: 'Auf der Suche nach Inspiration?<br /> Lassen Sie sich von unseren Reiseideen inspirieren!',
    nl: 'Op zoek naar inspiratie ?<br /> Kijk eens naar onze reisideeën!',
  },
};

export default defineMessages(largeTravelSliderMessages);
