import './typedefs';
import { fromJS } from 'immutable';
import { createContext, useEffect, useReducer } from 'react';
import {
  ADMIN_TOKEN,
  API_URL,
  BASIC_REQUEST_HEADERS,
  EDITABLE_CONTENT_DUPLICATE_ERROR_KEY,
  HTTP,
  UNAUTHORIZED_ROUTE_ERROR_KEY,
  URL_ADMIN_AUTHENTICATION,
  URL_ADMIN_EDITABLE_CONTENT,
  URL_ADMIN_WIDGET_BOOKING,
  URL_ORDER_TICKETS,
} from '../../helpers/constants';
import { Sentry } from '../../libs/sentry';
import request from '../../utils/request';

const initialState = fromJS({
  isAuthenticated: false,
  virtualCreditCard: null,
  ticketCancellation: {
    conditions: null,
    openCancellationModal: false,
    segmentData: null,
    isLoading: false,
  },
});

const AdminContext = createContext(initialState);

function adminReducer(state, action) {
  switch (action.type) {
    case 'login':
      return { ...state, isAuthenticated: true };

    case 'logout':
      return { ...state, isAuthenticated: false };

    case 'setVirtualCreditCard':
      return { ...state, virtualCreditCard: action.virtualCreditCard };

    default:
      return state;
  }
}

const AdminProvider = (props) => {
  const [state, dispatch] = useReducer(adminReducer, initialState);

  useEffect(() => {
    try {
      const adminTokenExists = !!localStorage.getItem(ADMIN_TOKEN);

      if (adminTokenExists) {
        login();
      }
    } catch (e) {
      console.error('Access to local storage denied.');
    }
  }, []);

  const login = (token) => {
    if (token) {
      localStorage.setItem(ADMIN_TOKEN, token);
    }
    dispatch({ type: 'login' });
  };

  const logout = () => {
    localStorage.removeItem(ADMIN_TOKEN);
    dispatch({ type: 'logout' });
  };

  const authenticate = async () => {
    const redirectUrl = await adminRequest(URL_ADMIN_AUTHENTICATION, {
      body: JSON.stringify({
        redirectUri: window.location.toString(),
      }),
    });

    window.location.href = redirectUrl;
  };

  /**
   * @description Get all widget bookings
   * @returns Promise<widgetBookingPropTypes[]>|void
   */
  const getWidgetBooking = async () => {
    try {
      const response = await request(URL_ADMIN_WIDGET_BOOKING, {
        method: 'GET',
        headers: {
          ...BASIC_REQUEST_HEADERS,
          authorization: `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`,
        },
      });

      return response;
    } catch (error) {
      if (error.errorKey === UNAUTHORIZED_ROUTE_ERROR_KEY) {
        logout();
        return;
      }
      Sentry.captureException(error);
    }
  };

  /**
   * @description Post a widget booking
   * @param {widgetBookingPropTypes} body the widget booking to create
   * @returns Promise<widgetBookingPropTypes>|void
   */
  const postWidgetBooking = async (body) => {
    try {
      const response = await request(URL_ADMIN_WIDGET_BOOKING, {
        method: 'POST',
        headers: {
          ...BASIC_REQUEST_HEADERS,
          authorization: `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`,
        },
        body: JSON.stringify(body),
      });

      return response;
    } catch (error) {
      if (error.errorKey === UNAUTHORIZED_ROUTE_ERROR_KEY) {
        logout();
        return;
      }
      Sentry.captureException(error);
      throw error;
    }
  };

  /**
   * @description Patch a widget booking
   * @param {string} widgetBookingId the widget booking id to patch
   * @param {widgetBookingPropTypes} body the data to update
   * @returns Promise<widgetBookingPropTypes>|void
   */
  const patchWidgetBooking = async (widgetBookingId, body) => {
    try {
      const response = await request(
        `${URL_ADMIN_WIDGET_BOOKING}/${widgetBookingId}`,
        {
          method: 'PATCH',
          headers: {
            ...BASIC_REQUEST_HEADERS,
            authorization: `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`,
          },
          body: JSON.stringify(body),
        },
      );

      return response;
    } catch (error) {
      if (error.errorKey === UNAUTHORIZED_ROUTE_ERROR_KEY) {
        logout();
        return;
      }
      Sentry.captureException(error);
      throw error;
    }
  };

  /**
   * @description Delete a widget booking
   * @param {string} widgetBookingId the widget booking id to delete
   * @returns Promise<widgetBookingPropTypes>|void
   */
  const deleteWidgetBooking = async (widgetBookingId) => {
    try {
      const response = await fetch(
        `${URL_ADMIN_WIDGET_BOOKING}/${widgetBookingId}`,
        {
          method: 'DELETE',
          headers: {
            ...BASIC_REQUEST_HEADERS,
            authorization: `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`,
          },
        },
      );

      if (!response.ok) {
        throw response.statusText;
      }

      return response;
    } catch (error) {
      if (error.errorKey === UNAUTHORIZED_ROUTE_ERROR_KEY) {
        logout();
        return;
      }
      Sentry.captureException(error);
    }
  };

  /**
   * @description Makes a request to the `url` and returns its response.
   * Disconnects the administrator in case of an error and redirects him to the home page.
   * Assumes it's a POST request.
   * @param {string} url The url to request.
   * @param {Object} options An object containing any custom settings that you want to apply to the request.
   * @returns {Object}The response dataL
   */
  const adminRequest = async (url, options) => {
    try {
      const response = await request(url, {
        method: 'POST',
        headers: {
          ...BASIC_REQUEST_HEADERS,
          authorization: `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`,
        },
        ...options,
      });

      return response;
    } catch (error) {
      if (error.errorKey === UNAUTHORIZED_ROUTE_ERROR_KEY) {
        logout();
      }
      Sentry.captureException(error);
    }
  };

  const generateVirtualCreditCard = async (orderTicketId) => {
    const virtualCreditCard = await adminRequest(
      `${URL_ORDER_TICKETS}/${orderTicketId}/virtualCard`,
    );

    dispatch({ type: 'setVirtualCreditCard', virtualCreditCard });
  };

  /**
   * @description Sends a POST request to the API to create editable content.
   * @param {CreateEditableContentBody} body The editable content to create.
   * @returns {Promise<EditableContent>} The created editable content.
   */
  const createEditableContent = async (body) => {
    try {
      const response = await request(URL_ADMIN_EDITABLE_CONTENT, {
        method: 'POST',
        headers: {
          ...BASIC_REQUEST_HEADERS,
          authorization: `Bearer ${localStorage.getItem(ADMIN_TOKEN)}`,
        },
        body: JSON.stringify(body),
      });

      return [null, response];
    } catch (error) {
      if (error.errorKey === UNAUTHORIZED_ROUTE_ERROR_KEY) {
        logout();
      }

      if (error.errorKey === EDITABLE_CONTENT_DUPLICATE_ERROR_KEY) {
        return [error, null];
      }

      Sentry.captureException(error);

      return [error, null];
    }
  };

  /**
   * @description Sends a PATCH request to the API to update the editable content with the `id` provided.
   * @param {number} id The id of the editable content to update.
   * @param {UpdateEditableContentBody} body Contains the updated fields.
   * @returns {Promise<EditableContent>} The updated editable content.
   */
  const updateEditableContent = async (id, body) => {
    return adminRequest(`${URL_ADMIN_EDITABLE_CONTENT}/${id}`, {
      body: JSON.stringify(body),
      method: 'PATCH',
    });
  };

  /**
   * @description Sends a DELETE request to the API to delete the editable content with the `id` provided.
   * @param {number} id The id of the editable content to delete.
   * @returns {Promise<void>}
   */
  const deleteEditableContent = async (id) => {
    return adminRequest(`${URL_ADMIN_EDITABLE_CONTENT}/${id}`, {
      method: 'DELETE',
    });
  };

  const URL_COMPANIES = '/companies';
  const URL_SWITCH_COMPANY_VISIBILITY = '/switchvisibilityonsearch';
  const URL_SWITCH_COMPANY_SELF_CANCELLATION =
    '/switchcustomerselfcancellation';

  /**
   * @description Get all companies
   * @returns {object}
   */
  const getAllCompanies = async () => {
    return adminRequest(`${API_URL}${URL_COMPANIES}`, {
      method: HTTP.METHODS.GET,
    });
  };

  /**
   * @description Update if the company is visible on tictactrip or not
   * @param {number} companyId The id of the company we want to update his visibility
   * @returns {object}
   */
  const updateCompanyVisibility = async (companyId) => {
    return adminRequest(
      `${API_URL}${URL_COMPANIES}/${companyId}${URL_SWITCH_COMPANY_VISIBILITY}`,
      {
        method: HTTP.METHODS.PUT,
      },
    );
  };

  /**
   * @description Update if the company is self cancellable on tictactrip or not
   * @param {number} companyId The id of the company we want to update his visibility
   * @returns {object}
   */
  const updateCompanySelfCancellation = async (companyId) => {
    return adminRequest(
      `${API_URL}${URL_COMPANIES}/${companyId}${URL_SWITCH_COMPANY_SELF_CANCELLATION}`,
      {
        method: HTTP.METHODS.PUT,
      },
    );
  };

  /**
   * @description Get all Feature Flags
   * @returns {object}
   */
  const getAllFeatureFlags = async () => {
    return adminRequest(`${API_URL}/featureFlags`, {
      method: HTTP.METHODS.GET,
    });
  };

  /**
   * @description Update a feature flag
   * @returns {object}
   * @param {number} featureId The id of the feature flag to update
   * @param {boolean} active The desired state
   * @returns something
   */
  const updateFeatureFlag = async (featureId, active) =>
    adminRequest(`${API_URL}/featureFlags/${featureId}`, {
      method: HTTP.METHODS.PATCH,
      body: JSON.stringify({ active }),
    });

  /**
   * @description Update if the API is in maintenance mode or not.
   * @returns {object}
   */
  const updateMaintenanceMode = async () =>
    adminRequest(`${API_URL}/apiStatus/maintenance`, {
      method: HTTP.METHODS.PUT,
    });

  const value = {
    ...state,
    login,
    logout,
    authenticate,
    adminRequest,
    getAllCompanies,
    getAllFeatureFlags,
    generateVirtualCreditCard,
    getWidgetBooking,
    postWidgetBooking,
    patchWidgetBooking,
    deleteWidgetBooking,
    updateCompanyVisibility,
    updateCompanySelfCancellation,
    updateMaintenanceMode,
    updateEditableContent,
    updateFeatureFlag,
    createEditableContent,
    deleteEditableContent,
  };

  return <AdminContext.Provider value={value} {...props} />;
};

export { AdminContext, AdminProvider };
