import { lazy, Suspense } from 'react';
import { injectIntl } from 'react-intl';
import LazyLoading from '../../components/LazyLoading';

export const LazyTermsOfSales = injectIntl(
  lazy(() => import(/* webpackChunkName: 'TermsOfSales' */ './index')),
);

export default function TermsOfSales() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyTermsOfSales />
    </Suspense>
  );
}
