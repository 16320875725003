/**
 * @description Returns true if `userAgent` includes the one used for the mobile app, false otherwise.
 * @param {string} userAgent
 * @returns {boolean}
 */
const isMobileApp = (userAgent) => userAgent.includes('@tictactrip/app');

const isWidgetBooking = () => false;

export { isMobileApp, isWidgetBooking };
