import PropTypes from 'prop-types';
import { API_URL } from '../../helpers/constants';

export const SUBSCRIBE_NEWSLETTER = 'app/Newsletter/SUBSCRIBE_NEWSLETTER';
export const UPDATE_NEWSLETTER_NAME = 'app/Newsletter/UPDATE_NEWSLETTER_NAME';
export const UPDATE_NEWSLETTER_EMAIL = 'app/Newsletter/UPDATE_NEWSLETTER_EMAIL';
export const RESPONSE_NEWSLETTER = 'app/Newsletter/RESPONSE_NEWSLETTER';
export const SUCCESS = 'success';
export const ERROR = 'error';

export const URL_NEWSLETTER = `${API_URL}/mail/addToList/newsletter`;

export const newsletterReducerPropTypes = PropTypes.shape({
  newsletterEmail: PropTypes.string,
  newsletterName: PropTypes.string,
  newsletterResponse: PropTypes.string,
});
