const colors = {
  transparent: 'transparent',

  primary500: '#1B1D2C',
  primary400: '#353746',
  primaryLight100: '#92939B',

  secondary500: '#342188',
  secondary400: '#6A49F9',
  secondary300: '#9DA3CE',

  terciary500: '#22E4AB',
  terciary400: '#EDFDF9',

  grey200: '#C4C8DE',
  grey100: '#F5F6FA',
  grey50: '#F6F6F6',

  white: '#FFFFFF',

  brandRed: '#E54910',
  brandYellow: '#EAD039',

  overlay: '#1C1E2D',
  overlayHeader: '#1C1E2D',
  overlayModal: '#1C1E2D',

  loadingGradient1: '#F6F6F6',
  loadingGradient2: '#F0F0F0',

  'toast-error-light': '#FFF0EB',
  'toast-error': '#E54910',
  'toast-warning-light': '#FFF9EA',
  'toast-warning': '#FEC03F',
  'toast-success-light': '#E2FFF7',
  'toast-success': '#1EBF90',
  'green-500': '#22E4AB',

  // Text colors:
  text: '#353746',
  textLight: '#717593',
  textUltraLight: '#9DA3CE',
  placeHolders: '#92939B',
  error: '#E9263D',
  externalLink: '#000EE',
  'primary-400': '#6A49F9',
  'secondary-400': '#353746',
  'primary-500': '#342188',
  'grey-200': '#ECEDF5',
  'grey-300': '#C4C8DE',
};

const columns = 12;
const gutter = 4;
const semiGutter = gutter / 2;

const cells = {};
for (let i = 1; i <= columns; i += 1) {
  cells[`${i}`] = `${(100 / columns) * i}%`;
}
const width = {
  ...cells,
  auto: 'auto',
  px: '1px',
  abs1: '1rem',
  abs3: '3rem',
  abs4: '4rem',
  abs5: '5rem',
  abs6: '6rem',
  abs7: '7rem',
  abs8: '8rem',
  abs10: '10rem',
  abs12: '12rem',
  abs14: '14rem',
  abs16: '16rem',
  abs26: '26rem',
  '1/2': '50%',
  '1/3': '33.33333%',
  '2/3': '66.66667%',
  '1/4': '25%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.66667%',
  '5/6': '83.33333%',
  full: '100%',
  screen: '100vw',
};

const height = {
  auto: 'auto',
  px: '1px',
  abs1: '1rem',
  abs3: '3rem',
  abs4: '4rem',
  abs5: '5rem',
  abs6: '6rem',
  abs7: '7rem',
  abs8: '8rem',
  abs10: '10rem',
  abs12: '12rem',
  abs14: '14rem',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  32: '8rem',
  48: '12rem',
  64: '16rem',
  full: '100%',
  screen: '100vh',
  input: '6rem',
};

const padding = {
  ...cells,
  px: '1px',
  0: '0',
  abs1: '1rem',
  abs2: '2rem',
  abs3: '3rem',
  abs4: '4rem',
  abs5: '5rem',
  abs6: '6rem',
  abs7: '7rem',
  abs8: '8rem',
  abs10: '10rem',
  abs12: '12rem',
  abs14: '14rem',
  gutter: `${gutter}rem`,
  def: `${semiGutter}rem`,
};

const margin = {
  ...cells,
  auto: 'auto',
  px: '1px',
  0: '0',
  abs1: '1rem',
  abs2: '2rem',
  abs3: '3rem',
  abs4: '4rem',
  abs5: '5rem',
  abs6: '6rem',
  abs7: '7rem',
  abs8: '8rem',
  abs9: '9rem',
  abs10: '10rem',
  abs12: '12rem',
  abs14: '14rem',
  gutter: `${gutter}rem`,
  def: `${semiGutter}rem`,
};

const screensInt = {
  xs: 425,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const screens = {
  xs: `${screensInt.xs}px`,
  sm: `${screensInt.sm}px`,
  md: `${screensInt.md}px`,
  lg: `${screensInt.lg}px`,
  xl: `${screensInt.xl}px`,
};

export { colors, height, margin, padding, screens, screensInt, width };
