import PropTypes from 'prop-types';
import { ETransportType } from '../../common/types';
import { destinationPropTypes } from '../../components/DestinationCard';
import { API_URL, URL_DATA } from '../../helpers/constants';
import { SearchPageType } from './types';

export const SET_LOADING = 'app/Search/SET_LOADING';
export const SET_CITY = 'app/Search/SET_CITY';
export const RESET_STATE = 'app/Search/RESET_CITY';
export const GET_CITIES = 'app/Search/GET_CITIES';
export const SET_TRIP_DATA = 'app/Search/SET_TRIP_DATA';
export const GET_LINKS = 'app/Search/GET_LINKS';
export const SET_LINKS = 'app/Search/SET_LINKS';
export const GET_EDITABLE_CONTENT = 'app/Search/GET_EDITABLE_CONTENT';
export const SET_EDITABLE_CONTENT = 'app/Search/SET_EDITABLE_CONTENT';
export const ADD_EMPTY_EDITABLE_CONTENT =
  'app/Search/ADD_EMPTY_EDITABLE_CONTENT';
export const DELETE_EDITABLE_CONTENT = 'app/Search/DELETE_EDITABLE_CONTENT';
export const SET_RATINGS = 'app/Search/SET_RATINGS';
export const SET_CURRENT_PAGE_TYPE = 'app/Search/SET_CURRENT_PAGE_TYPE';
export const SET_SHOULD_REDIRECT_TO_THE_MAIN_PAGE =
  'app/Search/SET_SHOULD_REDIRECT_TO_THE_MAIN_PAGE';
export const SET_COMPANIES_DATA = 'app/Search/SET_COMPANIES_DATA';

export const ORIGIN_CITY = 'originCity';
export const DESTINATION_CITY = 'destinationCity';
export const TRIP_DATA = 'tripData';
export const LOADING = 'loading';
export const FROM_ORIGIN_LINKS = 'fromOriginLinks';
export const TO_ORIGIN_LINKS = 'toOriginLinks';
export const FROM_DESTINATION_LINKS = 'fromDestinationLinks';
export const TO_DESTINATION_LINKS = 'toDestinationLinks';
export const LINKS = 'links';
export const EDITABLE_CONTENT = 'editableContent';
export const RATINGS = 'ratings';
export const CURRENT_PAGE_TYPE = 'currentPageType';
export const SHOULD_REDIRECT_TO_MAIN_PAGE = 'shouldRedirectToMainPage';
export const COMPANIES_DATA = 'companiesData';

export const CITY_TO_MATCH_LINK = {
  [FROM_ORIGIN_LINKS]: ORIGIN_CITY,
  [TO_ORIGIN_LINKS]: ORIGIN_CITY,
  [FROM_DESTINATION_LINKS]: DESTINATION_CITY,
  [TO_DESTINATION_LINKS]: DESTINATION_CITY,
};

export const FAQ = 'FAQ';
export const DESTINATIONS = 'destinations';
export const TABLES = 'tables';

export const URL_CITIES = `${API_URL}/cities`;
export const URL_TRIP_DATA = `${URL_DATA}/trip`;
export const URL_EDITABLE_CONTENT = `${API_URL}/editableContent`;
export const URL_RATINGS = `${API_URL}/ratings/trip`;

export const BUS = 'bus';
export const TRAIN = 'train';
export const CARPOOLING = 'carpooling';
export const MULTIMODAL = 'multimodality';
export const BUS_PLURAL = 'busPlural';
export const TRAIN_PLURAL = 'trainPlural';
export const CARPOOLING_PLURAL = 'carpoolingPlural';
export const MULTIMODAL_PLURAL = 'mutimodalityPlural';

export const SPECIFIC_TABLE_BUS = 'bus';
export const SPECIFIC_TABLE_TRAIN = 'train';
export const SPECIFIC_TABLE_MULTIMODAL = 'multimodal';
export const SPECIFIC_TABLE_CARPOOLING = 'carpooling';

export const EMOJI_BUS = '🚌';
export const EMOJI_TRAIN = '🚄';
export const EMOJI_MULTIMODAL = '🚌 + 🚄';
export const EMOJI_CARPOOLING = '🚗';

export const TRANSPORT_MEANS_EMOJIS = {
  [BUS]: EMOJI_BUS,
  [TRAIN]: EMOJI_TRAIN,
  [MULTIMODAL]: EMOJI_MULTIMODAL,
  [CARPOOLING]: EMOJI_CARPOOLING,
};

export const TRANSPORT_MEANS = [BUS, TRAIN, CARPOOLING];
export const TRANSPORT_MEANS_PLURAL = {
  [BUS]: BUS_PLURAL,
  [TRAIN]: TRAIN_PLURAL,
  [CARPOOLING]: CARPOOLING_PLURAL,
  [MULTIMODAL]: MULTIMODAL_PLURAL,
};

export const MAIN_TABLE_HEADER_DISTANCE = 'mainTableHeaderDistance';
export const MAIN_TABLE_HEADER_PRICE_RANGE = 'mainTableHeaderPriceRange';
export const MAIN_TABLE_HEADER_DURATION = 'mainTableHeaderDuration';
export const MAIN_TABLE_HEADER_TRIP_TYPE = 'mainTableHeaderTripType';

export const SPECIFIC_TABLE_HEADER_PRICE_RANGE =
  'specificTableHeaderPriceRange';
export const SPECIFIC_TABLE_HEADER_DURATION = 'specificTableHeaderDuration';
export const SPECIFIC_TABLE_HEADER_ITINERARY = 'specificTableHeaderItinerary';
export const SPECIFIC_TABLE_HEADER_DEPARTURE = 'specificTableHeaderDeparture';
export const SPECIFIC_TABLE_HEADER_ARRIVAL = 'specificTableHeaderArrival';
export const SPECIFIC_TABLE_HEADER_COMPANIES = 'specificTableHeaderCompanies';
export const SPECIFIC_TABLE_HEADER_FIRST_TRIP = 'specificTableHeaderFirstTrip';
export const SPECIFIC_TABLE_HEADER_LAST_TRIP = 'specificTableHeaderLastTrip';
export const SPECIFIC_TABLE_HEADER_DAILY_TRIPS =
  'specificTableHeaderDailyTrips';
export const SPECIFIC_TABLE_HEADER_ALTERNATIVE =
  'specificTableHeaderAlternative';

export const NOWRAP_TABLE_DATA = [
  SPECIFIC_TABLE_HEADER_PRICE_RANGE,
  SPECIFIC_TABLE_HEADER_DURATION,
  SPECIFIC_TABLE_HEADER_FIRST_TRIP,
  SPECIFIC_TABLE_HEADER_LAST_TRIP,
  SPECIFIC_TABLE_HEADER_DAILY_TRIPS,
];

export const ALL_SPECIFIC_TABLE_HEADERS = [
  SPECIFIC_TABLE_HEADER_PRICE_RANGE,
  SPECIFIC_TABLE_HEADER_DURATION,
  SPECIFIC_TABLE_HEADER_ITINERARY,
  SPECIFIC_TABLE_HEADER_DEPARTURE,
  SPECIFIC_TABLE_HEADER_ARRIVAL,
  SPECIFIC_TABLE_HEADER_COMPANIES,
  SPECIFIC_TABLE_HEADER_FIRST_TRIP,
  SPECIFIC_TABLE_HEADER_LAST_TRIP,
  SPECIFIC_TABLE_HEADER_DAILY_TRIPS,
  SPECIFIC_TABLE_HEADER_ALTERNATIVE,
];

export const GENERAL_TEXT_SENTENCE_1_1 = 'generalTextSentence11';
export const GENERAL_TEXT_SENTENCE_1_2 = 'generalTextSentence12';
export const GENERAL_TEXT_SENTENCE_1_3 = 'generalTextSentence13';
export const GENERAL_TEXT_SENTENCES_2_1 = 'generalTextSentence21';
export const GENERAL_TEXT_SENTENCES_2_2 = 'generalTextSentence22';
export const GENERAL_TEXT_SENTENCES_2_3 = 'generalTextSentence23';
export const GENERAL_TEXT_SENTENCES_2_1_DIRECT = 'generalTextSentence21Direct';
export const GENERAL_TEXT_SENTENCES_2_2_DIRECT = 'generalTextSentence22Direct';
export const GENERAL_TEXT_SENTENCES_2_3_DIRECT = 'generalTextSentence23Direct';
export const GENERAL_TEXT_SENTENCES_3_1 = 'generalTextSentence31';
export const GENERAL_TEXT_SENTENCES_3_2 = 'generalTextSentence32';
export const GENERAL_TEXT_SENTENCES_3_3 = 'generalTextSentence33';
export const SPECIFIC_TEXT_HEADER_1 = 'specificTextHeader1';
export const SPECIFIC_TEXT_HEADER_2 = 'specificTextHeader2';
export const SPECIFIC_TEXT_HEADER_3 = 'specificTextHeader3';
export const SPECIFIC_TEXT_1 = 'specificText1';
export const SPECIFIC_TEXT_2 = 'specificText2';
export const SPECIFIC_TEXT_3 = 'specificText3';
export const SPECIFIC_TEXT_4 = 'specificText4';
export const SPECIFIC_TEXT_5 = 'specificText5';
export const SPECIFIC_TEXT_1_1 = 'specificText11';
export const SPECIFIC_TEXT_2_1 = 'specificText21';
export const SPECIFIC_TEXT_3_1 = 'specificText31';
export const SPECIFIC_TEXT_4_1 = 'specificText41';
export const SPECIFIC_TEXT_5_1 = 'specificText51';
export const SPECIFIC_TEXT_1_2 = 'specificText12';
export const SPECIFIC_TEXT_2_2 = 'specificText22';
export const SPECIFIC_TEXT_3_2 = 'specificText32';
export const SPECIFIC_TEXT_4_2 = 'specificText42';
export const SPECIFIC_TEXT_5_2 = 'specificText52';
export const SPECIFIC_TEXT_4_3 = 'specificText43';
export const SPECIFIC_TEXT_5_3 = 'specificText53';
export const SPECIFIC_TEXT_DAILY_TRIPS_1 = 'specificTextDailyTrips1';
export const SPECIFIC_TEXT_DAILY_TRIPS_2 = 'specificTextDailyTrips2';
export const SPECIFIC_TEXT_DAILY_TRIPS_3 = 'specificTextDailyTrips3';
export const SPECIFIC_TEXT_NO_DATA_INTRO_1 = 'specificTextNoDataIntro1';
export const SPECIFIC_TEXT_NO_DATA_INTRO_2 = 'specificTextNoDataIntro2';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_1 = 'specificTextNoDataItinerary1';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_2 = 'specificTextNoDataItinerary2';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_3 = 'specificTextNoDataItinerary3';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_1 =
  'specificTextNoDataItineraryDistance1';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_2 =
  'specificTextNoDataItineraryDistance2';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_1 =
  'specificTextNoDataItineraryStop1';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_2 =
  'specificTextNoDataItineraryStop2';
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_3 =
  'specificTextNoDataItineraryStop3';
export const SHORT_FAQ_PRICE_1 = 'shortFaqPrice1';
export const SHORT_FAQ_PRICE_2 = 'shortFaqPrice2';
export const SHORT_FAQ_PRICE_3 = 'shortFaqPrice3';
export const SHORT_FAQ_DURATION_1 = 'shortFaqDuration1';
export const SHORT_FAQ_DURATION_2 = 'shortFaqDuration2';
export const SHORT_FAQ_DURATION_3 = 'shortFaqDuration3';
export const SHORT_FAQ_DEPARTURE_1 = 'shortFaqDeparture1';
export const SHORT_FAQ_DEPARTURE_2 = 'shortFaqDeparture2';
export const SHORT_FAQ_DEPARTURE_3 = 'shortFaqDeparture3';
export const SHORT_FAQ_DAILY_TRIPS_1 = 'shortFaqDailyTrips1';
export const SHORT_FAQ_DAILY_TRIPS_2 = 'shortFaqDailyTrips2';
export const SHORT_FAQ_DAILY_TRIPS_3 = 'shortFaqDailyTrips3';
export const COMPANY_VERB_1 = 'companyVerb1';
export const COMPANY_VERB_2 = 'companyVerb2';
export const COMPANY_VERB_3 = 'companyVerb3';
export const COMPANY_VERB_4 = 'companyVerb4';
export const COMPANY_VERB_PLURAL_1 = 'companyVerbPlural1';
export const COMPANY_VERB_PLURAL_2 = 'companyVerbPlural2';
export const COMPANY_VERB_PLURAL_3 = 'companyVerbPlural3';
export const COMPANY_VERB_PLURAL_4 = 'companyVerbPlural4';

const TRIP_CO2_QUOTE_1 = 'reduceCarbonFootprint1';
const TRIP_CO2_QUOTE_2 = 'reduceCarbonFootprint2';
const TRIP_CO2_QUOTE_3 = 'reduceCarbonFootprint3';
export const TRIP_CO2_QUOTES = [
  TRIP_CO2_QUOTE_1,
  TRIP_CO2_QUOTE_2,
  TRIP_CO2_QUOTE_3,
];

export const BEST_TIME_TO_BOOK_QUOTES = [
  'bestTimeToBook1',
  'bestTimeToBook2',
  'bestTimeToBook3',
];

export const SPECIFIC_COMPANIES_TEXT_HEADERS = [
  'specificCompaniesTextHeaders1',
  'specificCompaniesTextHeaders2',
  'specificCompaniesTextHeaders3',
];

export const SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS = [
  'specificCompaniesTextDailyTrips',
  'specificCompaniesTextDailyTrips_1',
  'specificCompaniesTextDailyTrips_2',
];

export const SPECIFIC_COMPANIES_TEXT_1 = [
  'specificCompaniesText1',
  'specificCompaniesText1_1',
  'specificCompaniesText1_2',
  SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[0],
];

export const SPECIFIC_COMPANIES_TEXT_2 = [
  'specificCompaniesText2',
  'specificCompaniesText2_1',
  'specificCompaniesText2_2',
  SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[1],
];

export const SPECIFIC_COMPANIES_TEXT_3 = [
  'specificCompaniesText3',
  'specificCompaniesText3_1',
  'specificCompaniesText3_2',
  SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[2],
];

export const SPECIFIC_COMPANIES_TEXT_4 = [
  'specificCompaniesText4',
  'specificCompaniesText4_1',
  'specificCompaniesText4_2',
  'specificCompaniesText4_3',
  SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[0],
];

export const SPECIFIC_COMPANIES_TEXT_5 = [
  'specificCompaniesText5',
  'specificCompaniesText5_1',
  'specificCompaniesText5_2',
  'specificCompaniesText5_3',
  SPECIFIC_COMPANIES_TEXT_DAILY_TRIPS[1],
];

export const SPECIFIC_COMPANIES_TEXTS = [
  SPECIFIC_COMPANIES_TEXT_1,
  SPECIFIC_COMPANIES_TEXT_2,
  SPECIFIC_COMPANIES_TEXT_3,
  SPECIFIC_COMPANIES_TEXT_4,
  SPECIFIC_COMPANIES_TEXT_5,
];

export const GENERAL_TEXT_SENTENCES_1 = [
  GENERAL_TEXT_SENTENCE_1_1,
  GENERAL_TEXT_SENTENCE_1_2,
  GENERAL_TEXT_SENTENCE_1_3,
];
export const GENERAL_TEXT_SENTENCES_2_DIRECT = [
  GENERAL_TEXT_SENTENCES_2_1_DIRECT,
  GENERAL_TEXT_SENTENCES_2_2_DIRECT,
  GENERAL_TEXT_SENTENCES_2_3_DIRECT,
];
export const GENERAL_TEXT_SENTENCES_2 = [
  GENERAL_TEXT_SENTENCES_2_1,
  GENERAL_TEXT_SENTENCES_2_2,
  GENERAL_TEXT_SENTENCES_2_3,
];
export const GENERAL_TEXT_SENTENCES_3 = [
  GENERAL_TEXT_SENTENCES_3_1,
  GENERAL_TEXT_SENTENCES_3_2,
  GENERAL_TEXT_SENTENCES_3_3,
];
export const SPECIFIC_TEXT_HEADERS = [
  SPECIFIC_TEXT_HEADER_1,
  SPECIFIC_TEXT_HEADER_2,
  SPECIFIC_TEXT_HEADER_3,
];
export const SPECIFIC_TEXTS = [
  SPECIFIC_TEXT_1,
  SPECIFIC_TEXT_2,
  SPECIFIC_TEXT_3,
  SPECIFIC_TEXT_4,
  SPECIFIC_TEXT_5,
];
export const SPECIFIC_TEXTS_TAIL = {
  [SPECIFIC_TEXT_1]: [SPECIFIC_TEXT_1_1, SPECIFIC_TEXT_1_2],
  [SPECIFIC_TEXT_2]: [SPECIFIC_TEXT_2_1, SPECIFIC_TEXT_2_2],
  [SPECIFIC_TEXT_3]: [SPECIFIC_TEXT_3_1, SPECIFIC_TEXT_3_2],
  [SPECIFIC_TEXT_4]: [SPECIFIC_TEXT_4_1, SPECIFIC_TEXT_4_2, SPECIFIC_TEXT_4_3],
  [SPECIFIC_TEXT_5]: [SPECIFIC_TEXT_5_1, SPECIFIC_TEXT_5_2, SPECIFIC_TEXT_5_3],
};
export const SPECIFIC_TEXT_DAILY_TRIPS = [
  SPECIFIC_TEXT_DAILY_TRIPS_1,
  SPECIFIC_TEXT_DAILY_TRIPS_2,
  SPECIFIC_TEXT_DAILY_TRIPS_3,
];
export const SPECIFIC_TEXT_NO_DATA_INTRO = [
  SPECIFIC_TEXT_NO_DATA_INTRO_1,
  SPECIFIC_TEXT_NO_DATA_INTRO_2,
];
export const SPECIFIC_TEXT_NO_DATA_ITINERARIES = [
  SPECIFIC_TEXT_NO_DATA_ITINERARY_1,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_2,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_3,
];
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE = [
  SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_1,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_DISTANCE_2,
];
export const SPECIFIC_TEXT_NO_DATA_ITINERARY_STOPS = [
  SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_1,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_2,
  SPECIFIC_TEXT_NO_DATA_ITINERARY_STOP_3,
];
export const SHORT_FAQ_PRICE_HEADERS = ['priceFaqHeader', 'priceFaqHeader2'];
export const SHORT_FAQ_PRICE = [
  SHORT_FAQ_PRICE_1,
  SHORT_FAQ_PRICE_2,
  SHORT_FAQ_PRICE_3,
];
export const SHORT_FAQ_DURATION_HEADERS = [
  'durationFaqHeader',
  'durationFaqHeader2',
];
export const SHORT_FAQ_DURATION = [
  SHORT_FAQ_DURATION_1,
  SHORT_FAQ_DURATION_2,
  SHORT_FAQ_DURATION_3,
];
export const SHORT_FAQ_DEPQRTURE_HEADERS = [
  'departureFaqHeader',
  'departureFaqHeader2',
];
export const SHORT_FAQ_DEPARTURE = [
  SHORT_FAQ_DEPARTURE_1,
  SHORT_FAQ_DEPARTURE_2,
  SHORT_FAQ_DEPARTURE_3,
];
export const SHORT_FAQ_DAILY_TRIPS_HEADERS = [
  'dailyTripsFaqHeader',
  'dailyTripsFaqHeader2',
];
export const SHORT_FAQ_DAILY_TRIPS = [
  SHORT_FAQ_DAILY_TRIPS_1,
  SHORT_FAQ_DAILY_TRIPS_2,
  SHORT_FAQ_DAILY_TRIPS_3,
];
export const COMPANY_VERBS = [
  COMPANY_VERB_1,
  COMPANY_VERB_2,
  COMPANY_VERB_3,
  COMPANY_VERB_4,
];
export const COMPANY_VERBS_PLURAL = [
  COMPANY_VERB_PLURAL_1,
  COMPANY_VERB_PLURAL_2,
  COMPANY_VERB_PLURAL_3,
  COMPANY_VERB_PLURAL_4,
];

export const FORMATTED_TRIPS_1 = 'formattedTrip1';
export const FORMATTED_TRIPS_2 = 'formattedTrip2';
export const FORMATTED_TRIPS_3 = 'formattedTrip3';
export const FORMATTED_TRIPS_4 = 'formattedTrip4';
export const FORMATTED_TRIPS_5 = 'formattedTrip5';
export const FORMATTED_TRIPS_6 = 'formattedTrip6';
export const FORMATTED_TRIPS = [
  FORMATTED_TRIPS_1,
  FORMATTED_TRIPS_2,
  FORMATTED_TRIPS_3,
  FORMATTED_TRIPS_4,
  FORMATTED_TRIPS_5,
  FORMATTED_TRIPS_6,
];

export const cityPropTypes = PropTypes.exact({
  id: PropTypes.number,
  name: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  slug: PropTypes.string,
  gpuid: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  specialType: PropTypes.string,
  serviced: PropTypes.bool,
  notServicedSince: PropTypes.string,
});

export const specificTableHeadersPropTypes = PropTypes.arrayOf(
  PropTypes.exact({
    legacyId: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.node,
  }),
);

export const specificTableDataPropTypes = PropTypes.arrayOf(
  PropTypes.exact({
    id: PropTypes.string,
    value: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    ),
  }),
);

export const cityLinkPropTypes = PropTypes.shape({
  country: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  region: PropTypes.string,
  slug: PropTypes.string,
});

export const linkPropTypes = PropTypes.shape({
  destination: cityLinkPropTypes,
  origin: cityLinkPropTypes,
});

export const linksPropTypes = PropTypes.exact({
  [FROM_ORIGIN_LINKS]: PropTypes.arrayOf(linkPropTypes),
  [TO_ORIGIN_LINKS]: PropTypes.arrayOf(linkPropTypes),
  [FROM_DESTINATION_LINKS]: PropTypes.arrayOf(linkPropTypes),
  [TO_DESTINATION_LINKS]: PropTypes.arrayOf(linkPropTypes),
});

export const enhancedDestinationCard = PropTypes.exact({
  destinationCard: destinationPropTypes,
  description: PropTypes.string,
});

export const textBlockPropTypes = PropTypes.shape({
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
});
export const SEARCH_PAGE_HEADER_INITIAL_MESSAGE_ID =
  'searchPageInitialHeaderMessage';
export const SEARCH_PAGE_SUBHEADER_INITIAL_MESSAGE_ID =
  'searchPageInitialSubHeaderMessage';

export const FIND_MY_TICKETS_SECTION_ID = 'findMyTicket';
export const PRICE_CALENDAR_SECTION_ID = 'priceCalendar';
export const TRIP_INFORMATION_SECTION_ID = 'tripInformation';

export const SEARCH_PAGE_TYPE_TO_TRANSPORT_TYPE = {
  [SearchPageType.BUS]: ETransportType.BUS,
  [SearchPageType.TRAIN]: ETransportType.TRAIN,
  [SearchPageType.CARPOOLING]: ETransportType.CARPOOLING,
};
