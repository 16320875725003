import { lazy, Suspense } from 'react';
import { injectIntl } from 'react-intl';
import LazyLoading from '../../components/LazyLoading';

export const LazyLegalMention = injectIntl(
  lazy(() => import(/* webpackChunkName: 'LegalMention' */ './index')),
);

export default function LegalMention() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyLegalMention />
    </Suspense>
  );
}
