import { homepageScope } from '../constants';

export const homePageAppMessages = {
  appTitle: {
    id: `${homepageScope}.appTitle`,
    defaultMessage:
      'L’application avec 3T, <br /><span class="highlight">qui fait 3X plus.</span>',
    en: 'The app with 3T, <br /><span class="highlight">that does 3X more.</span>',
    it: "L'applicazione con 3T, <br /><span class='highlight'>che fa 3 volte di più.</span>",
    es: 'La aplicación con 3T, <br /><span class="highlight">que hace 3 veces más.</span>',
    de: 'Die Anwendung mit 3T, <br /><span class="highlight">die 3-mal mehr leistet.</span>',
    nl: 'De app met 3T, <br /><span class="highlight">die 3X meer doet.</span>',
  },
  appSubtitle: {
    id: `${homepageScope}.appSubtitle`,
    defaultMessage:
      'Comparez, combinez, réservez vos trajets en train, bus et covoiturage sur une seule application.',
    en: 'Compare, combine, and book your train, bus, and carpool trips all in one app.',
    it: "Confronta, combina e prenota i tuoi viaggi in treno, autobus e carpooling in un'unica app.",
    es: 'Compara, combina y reserva tus viajes en tren, autobús y coche compartido en una sola aplicación.',
    de: 'Vergleichen, kombinieren und buchen Sie Ihre Zug-, Bus- und Mitfahrgelegenheitsfahrten in einer einzigen App.',
    nl: 'Vergelijk, combineer en boek uw trein-, bus- en carpoolreizen allemaal in één app.',
  },
  appBulletTicket: {
    id: `${homepageScope}.appBulletTicket`,
    defaultMessage: 'Tous vos billets au même endroit',
    en: 'All your tickets in one place',
    it: 'Tutti i tuoi biglietti in un unico posto',
    es: 'Todos tus billetes en un solo lugar',
    de: 'Alle Ihre Tickets an einem Ort',
    nl: 'Al uw tickets op één plek',
  },
  appBulletNoAds: {
    id: `${homepageScope}.appBulletNoAds`,
    defaultMessage: 'Sans pub et options inutiles',
    en: 'No ads or unnecessary options',
    it: 'Nessuna pubblicità o opzioni inutili',
    es: 'Sin publicidad ni opciones innecesarias',
    de: 'Keine Werbung oder unnötigen Optionen',
    nl: 'Geen advertenties of onnodige opties',
    fr: 'Sans pub et options inutiles',
  },
  appBulletCustomerService: {
    id: `${homepageScope}.appBulletCustomerService`,
    defaultMessage: 'Notre super (super) service client',
    en: 'Our super (super) customer service',
    it: 'Il nostro servizio clienti super (super)',
    es: 'Nuestro súper (súper) servicio al cliente',
    de: 'Unser super (super) Kundenservice',
    nl: 'Onze geweldige (geweldige) klantenservice',
    fr: 'Notre super (super) service client',
  },
  appBulletNotification: {
    id: `${homepageScope}.appBulletNotification`,
    defaultMessage: 'Notifications personnalisées',
    en: 'Customized notifications',
    it: 'Notifiche personalizzate',
    es: 'Notificaciones personalizadas',
    de: 'Individuelle Benachrichtigungen',
    nl: 'Aangepaste meldingen',
    fr: 'Notifications personnalisées',
  },
};
