import { useCallback, useMemo } from 'react';
import { EIsoCountryCodes } from '../../common/iso-country-codes';
import { MAXIMUM_PASSENGERS } from '../../common/Passengers/constants';
import {
  EIdentityDocumentType,
  EPassengerCategory,
  EPassengerTitle,
  IDiscountCard,
} from '../../common/types';
import { IPassenger } from '../../contexts/Search/types';
import {
  IUsePassengersManagement,
  UsePassengersManagementProps,
} from './types';
import {
  getDefaultAgeByCategory,
  getPassengerAge,
  getPassengerCategoryFromAge,
} from './utils';

const usePassengersManagement = ({
  passengers,
  setPassengers,
}: UsePassengersManagementProps): IUsePassengersManagement => {
  const canAddPassengers = passengers.length < MAXIMUM_PASSENGERS;

  const addPassengerByCategory = useCallback(
    (category: EPassengerCategory) => {
      if (!canAddPassengers) {
        return;
      }

      setPassengers((currentPassengers) => [
        ...currentPassengers,
        {
          id: Date.now(),
          category,
          age: getDefaultAgeByCategory(category),
          discountCards: [],
          isCustomer: false,
          title: EPassengerTitle.MS,
        },
      ]);
    },
    [canAddPassengers, setPassengers],
  );

  const updatePassenger = useCallback(
    (updatedPassenger: IPassenger) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === updatedPassenger.id ? updatedPassenger : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const removePassenger = useCallback(
    (passengerId: number) => {
      setPassengers((currentPassengers) =>
        currentPassengers.filter((passenger) => passenger.id !== passengerId),
      );
    },
    [setPassengers],
  );

  const addDiscountCard = useCallback(
    (passengerId: number, discountCard: IDiscountCard) => {
      const isIdRequired = discountCard && discountCard.isIdRequired;

      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? {
                ...passenger,
                discountCards: !discountCard ? [] : [discountCard],
                discountCardNumber: isIdRequired
                  ? passenger.discountCardNumber || ''
                  : null,
              }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateDiscountCardNumber = useCallback(
    (passengerId: number, discountCardNumber: string) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? { ...passenger, discountCardNumber }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateTitle = useCallback(
    (passengerId: number, title: EPassengerTitle) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId ? { ...passenger, title } : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateFirstName = useCallback(
    (passengerId: number, firstName: string) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? { ...passenger, firstName }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateLastName = useCallback(
    (passengerId: number, lastName: string) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId ? { ...passenger, lastName } : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateBirthdate = useCallback(
    (passengerId: number, birthdate: string) => {
      const updatedAge = getPassengerAge(birthdate);
      let updatedCategory: EPassengerCategory;

      if (updatedAge) {
        updatedCategory = getPassengerCategoryFromAge(updatedAge);
      }

      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? {
                ...passenger,
                birthdate,
                age: updatedAge,
                category: updatedCategory ?? EPassengerCategory.ADULT,
              }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateIdentityDocumentCountryCode = useCallback(
    (passengerId: number, countryCode: EIsoCountryCodes) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? {
                ...passenger,
                identityDocument: {
                  ...passenger.identityDocument,
                  countryCode: countryCode,
                },
              }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateIdentityDocumentIdentifier = useCallback(
    (passengerId: number, identifier: string) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? {
                ...passenger,
                identityDocument: {
                  ...passenger.identityDocument,
                  documentIdentifier: identifier,
                },
              }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateIdentityDocumentExpirationDate = useCallback(
    (passengerId: number, expirationDate: string) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? {
                ...passenger,
                identityDocument: {
                  ...passenger.identityDocument,
                  expirationDate: expirationDate,
                },
              }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  const updateIdentityDocumentType = useCallback(
    (passengerId: number, documentType: EIdentityDocumentType) => {
      setPassengers((currentPassengers) =>
        currentPassengers.map((passenger) =>
          passenger.id === passengerId
            ? {
                ...passenger,
                identityDocument: {
                  ...passenger.identityDocument,
                  type: documentType,
                },
              }
            : passenger,
        ),
      );
    },
    [setPassengers],
  );

  return useMemo(
    () => ({
      maximumNumberOfPassengers: MAXIMUM_PASSENGERS,
      canAddPassengers,
      addPassengerByCategory,
      updatePassenger,
      removePassenger,
      addDiscountCard,
      updateDiscountCardNumber,
      updateTitle,
      updateFirstName,
      updateLastName,
      updateBirthdate,
      updateIdentityDocumentCountryCode,
      updateIdentityDocumentIdentifier,
      updateIdentityDocumentExpirationDate,
      updateIdentityDocumentType,
    }),
    [
      addDiscountCard,
      addPassengerByCategory,
      canAddPassengers,
      removePassenger,
      updateBirthdate,
      updateDiscountCardNumber,
      updateFirstName,
      updateIdentityDocumentCountryCode,
      updateIdentityDocumentExpirationDate,
      updateIdentityDocumentIdentifier,
      updateIdentityDocumentType,
      updateLastName,
      updatePassenger,
      updateTitle,
    ],
  );
};

export default usePassengersManagement;
