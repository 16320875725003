import { City } from '../../services/cities/types';
import { PriceHistories } from '../../services/priceHistory/types';

export type TripComparatorProps = TripComparatorWidgetProps & {
  onDisplayedTabChange?: (displayedTab: TripComparatorTabs) => void;
};

export type TripComparatorWidgetProps = {
  originCity: City;
  destinationCity: City;
  distanceAsCrowFlies: number;
  onCheapTicketsContextChange?: (
    context: TripComparatorCheapTicketsContext,
  ) => void;
};

export type TripComparatorCheapTicketsContext = {
  priceHistories: PriceHistories;
  availableValues: string[];
  hasPriceData: boolean;
  isLoading: boolean;
};

export enum TripComparatorTabs {
  CHEAP_TICKETS = 'CHEAP_TICKETS',
  REDUCE_EMISSIONS = 'REDUCE_EMISSIONS',
  HAVE_FUN = 'HAVE_FUN',
}
