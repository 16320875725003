/*
 *
 * City reducer
 *
 */
import { fromJS } from 'immutable';
import {
  CHILDREN_LINKS,
  CITY_DATA,
  LINKS_FROM,
  LINKS_TO,
  SET_CHILDREN_LINKS,
  SET_CITY_DATA,
} from './constants';

export const initialState = fromJS({
  [CITY_DATA]: {},
  [CHILDREN_LINKS]: {
    [LINKS_FROM]: [],
    [LINKS_TO]: [],
  },
});

function cityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CITY_DATA:
      return state.set(CITY_DATA, fromJS(action.cityData));
    case SET_CHILDREN_LINKS: {
      const currentCity = state.getIn([CITY_DATA, 'city']);
      const formattedLinksFrom = action.linksFrom.map((link) => ({
        origin: currentCity,
        destination: link.local_name.split(',')[0],
        path: `/search/${action.validCitySlug}/${link.unique_name}`,
      }));
      const formattedLinksTo = action.linksTo.map((link) => ({
        origin: link.local_name.split(',')[0],
        destination: currentCity,
        path: `/search/${link.unique_name}/${action.validCitySlug}`,
      }));
      return state
        .setIn([CHILDREN_LINKS, LINKS_FROM], fromJS(formattedLinksFrom))
        .setIn([CHILDREN_LINKS, LINKS_TO], fromJS(formattedLinksTo));
    }
    default:
      return state;
  }
}

export default cityReducer;
