import { fromJS } from 'immutable';
import {
  LOADING,
  SET_LOADING_STATUS,
  STATION_ARRIVALS,
  STATION_DEPARTURES,
  STATION_INFOS,
  UPDATE_STATION_ARRIVALS,
  UPDATE_STATION_DEPARTURES,
  UPDATE_STATION_INFOS,
} from './constants';

export const initialState = fromJS({
  [STATION_INFOS]: {},
  [STATION_DEPARTURES]: {},
  [STATION_ARRIVALS]: {},
  [LOADING]: true,
});

function stationReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STATION_INFOS:
      return state.set(STATION_INFOS, fromJS(action.stationInfos));

    case UPDATE_STATION_DEPARTURES:
      return state.set(STATION_DEPARTURES, fromJS(action.stationDepartures));

    case UPDATE_STATION_ARRIVALS:
      return state.set(STATION_ARRIVALS, fromJS(action.stationArrivals));

    case SET_LOADING_STATUS:
      return state.set(LOADING, fromJS(action.loadingStatus));

    default:
      return state;
  }
}

export default stationReducer;
