import { defineMessages } from 'react-intl';

export const tripScope = 'component.trip.messages';

export const tripMessages = {
  multipleConnections: {
    id: `${tripScope}.multipleConnections`,
    defaultMessage: 'corresp.',
    en: 'connections',
    it: 'corrispondenze',
    es: 'conexiones',
    de: 'Verbindungen',
    nl: 'verbindingen',
  },
  oneConnection: {
    id: `${tripScope}.oneConnection`,
    defaultMessage: 'corresp.',
    en: 'connection',
    it: 'corrispondenza',
    es: 'conexión',
    de: 'Verbindung',
    nl: 'verbinding',
  },
  direct: {
    id: `${tripScope}.direct`,
    defaultMessage: 'Direct',
    en: 'Direct',
    it: 'Diretto',
    es: 'Directo',
    de: 'Direkt',
    nl: 'Direct',
  },
  oneStop: {
    id: `${tripScope}.oneStop`,
    defaultMessage: 'corresp',
    en: 'stop',
    it: 'scalo',
    es: 'escala',
    de: 'zwischenstopp',
    nl: 'tussenstop',
  },
  multipleStops: {
    id: `${tripScope}.multipleStops`,
    defaultMessage: 'corresp',
    en: 'stops',
    it: 'scalo',
    es: 'escalas',
    de: 'zwischenstopps',
    nl: 'tussenstops',
  },
  night: {
    id: `${tripScope}.night`,
    defaultMessage: 'Nuit',
    en: 'Night',
    it: 'Notte',
    es: 'Noche',
    de: 'Nacht',
    nl: 'Nacht',
  },
};

export default defineMessages(tripMessages);
