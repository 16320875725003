import { defineMessages } from 'react-intl';

export const searchHeaderScope = 'app.components.searchHeader';

export const searchHeaderMessages = {
  fileUploadSuccess: {
    id: `${searchHeaderScope}.fileUploadSuccess`,
    defaultMessage: 'Votre fichier a été téléchargé avec succès !',
    en: 'Your file has been uploaded successfully!',
    es: '¡Su archivo ha sido subido con éxito!',
    it: 'Il tuo file è stato caricato con successo!',
    de: 'Ihre Datei wurde erfolgreich hochgeladen!',
    nl: 'Uw bestand is succesvol geüpload!',
  },
  unexpectedError: {
    id: `${searchHeaderScope}.unexpectedError`,
    defaultMessage: 'Erreur inattendue',
    en: 'Unexpected error',
    es: 'Error inesperado',
    it: 'Errore imprevisto',
    de: 'Unerwarteter Fehler',
    nl: 'Onverwachte fout',
  },
  noKeepIt: {
    id: `${searchHeaderScope}.noKeepIt`,
    defaultMessage: 'Non, la garder.',
    en: 'No, keep it.',
    es: 'No, déjalo.',
    it: 'No, mantienilo.',
    de: 'Nein, behalten.',
    nl: 'Nee, houd het.',
  },
  deleteImage: {
    id: `${searchHeaderScope}.deleteImage`,
    defaultMessage: "Supprimer l'image",
    en: 'Delete image',
    es: 'Eliminar imagen',
    it: 'Elimina immagine',
    de: 'Bild löschen',
    nl: 'Afbeelding verwijderen',
  },
  deleteHeaderImageConfirmation: {
    id: `${searchHeaderScope}.deleteHeaderImageConfirmation`,
    defaultMessage:
      "Vous êtes sur le point de supprimer l'image d'en-tête de la page de destination actuelle. Êtes-vous sûr?",
    en: "You're about to delete the header image of the current page destination. Are you sure?",
    es: 'Está a punto de eliminar la imagen de encabezado de la página de destino actual. ¿Estás seguro?',
    it: "Stai per eliminare l'immagine di intestazione della pagina di destinazione corrente. Sei sicuro?",
    de: 'Sie sind dabei, das Header-Bild der aktuellen Zielseite zu löschen. Bist du sicher?',
    nl: 'U staat op het punt de headerafbeelding van de huidige bestemmingspagina te verwijderen. Weet je het zeker?',
  },
  yesDelete: {
    id: `${searchHeaderScope}.yesDelete`,
    defaultMessage: 'Oui, supprimez !',
    en: 'Yes, delete!',
    es: '¡Sí, eliminar!',
    it: 'Sì, elimina!',
    de: 'Ja, löschen!',
    nl: 'Ja, verwijderen!',
  },
  preview: {
    id: `${searchHeaderScope}.preview`,
    defaultMessage: 'Aperçu',
    en: 'Preview',
    es: 'Vista previa',
    it: 'Anteprima',
    de: 'Vorschau',
    nl: 'Voorbeeld',
  },
  updateImage: {
    id: `${searchHeaderScope}.updateImage`,
    defaultMessage: "Mettre à jour l'image",
    en: 'Update image',
    es: 'Actualizar imagen',
    it: 'Aggiorna immagine',
    de: 'Bild aktualisieren',
    nl: 'Afbeelding bijwerken',
  },
  save: {
    id: `${searchHeaderScope}.save`,
    defaultMessage: 'Sauvegarder',
    en: 'Save',
    es: 'Guardar',
    it: 'Salvare',
    de: 'Speichern',
    nl: 'Opslaan',
  },
};

export default defineMessages(searchHeaderMessages);
