import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../../containers/App/constants';
import applePayLogo from '../../../images/applePayLogo.svg';
import TictactripLogo from '../../../images/logoTextBlack.svg';
import ImageTexture from '../../../images/textures/texture-green.png';
import threeDSecureLogo from '../../../images/threeDSecureLogo.svg';
import { screens } from '../../../styles/constants';
import { Container, LEGAL_LINKS, StyledLink, Wrapper } from './common';
import Newsletter from './Newsletter';
import TrustBox from './TrustBox';

const StyledContainer = styled(Container)`
  ${tw`py-medium mt-huge lg:mt-medium lg:py-big`}
  background: url(${ImageTexture}),
  linear-gradient(97.78deg, #f5f4ec 0.37%, #edfdf9 92.55%);
`;

const StyledWrapper = styled(Wrapper)`
  ${tw`flex flex-col-reverse  justify-between flex-wrap lg:flex-row lg:items-center`}
  gap: var(--size-medium2)
`;

const Logo = styled.img`
  width: 106px;
`;

const NewsletterStyle = css`
  ${tw`lg:hidden `}
  max-width: 80%;
`;

const TrustBoxStyle = css`
  ${tw`flex-row`}
  gap: var(--size-small);
`;

const TopTrustBoxStyle = css`
  ${TrustBoxStyle}
  ${tw`hidden lg:flex`}
`;

const BottomTrustBoxStyle = css`
  ${TrustBoxStyle}
  ${tw`lg:hidden`}
`;

type EndProps = {
  isReduced: boolean;
  areLinksObfuscated: boolean;
};

const End = (props: EndProps) => {
  const { areLinksObfuscated, isReduced } = props;

  return (
    <StyledContainer>
      {isReduced === false && (
        <StyledWrapper style={{ justifyContent: 'end' }}>
          <TrustBox customStyle={TopTrustBoxStyle} />
        </StyledWrapper>
      )}

      <StyledWrapper>
        <Logo alt="Tictactrip Logo" src={TictactripLogo} />
        <Links
          areLinksObfuscated={areLinksObfuscated}
          isReduced={isReduced}
          openInNewWindow={isReduced}
        />
        <MobileAppLinks
          areLinksObfuscated={areLinksObfuscated}
          customStyle={
            isReduced
              ? css`
                  ${tw`lg:hidden`}
                `
              : null
          }
        />
        <TrustBox customStyle={BottomTrustBoxStyle} />
        <Newsletter customStyle={NewsletterStyle} />
      </StyledWrapper>
    </StyledContainer>
  );
};

const LinksContainer = styled.div<LinksProps>`
  ${tw`hidden lg:flex lg:items-center text-p-xsmall leading-p-xsmall font-bold`}
  gap: var(--size-medium2);

  ${({ isReduced }) =>
    isReduced &&
    css`
      ${tw``}
      @media (min-width: ${screens.lg}) {
        gap: var(--size-huge2);
      }
    `}
`;

const LinksImage = styled.img`
  width: 47px;
  height: fit-content;
`;

type LinksProps = {
  isReduced: boolean;
  openInNewWindow: boolean;
  areLinksObfuscated: boolean;
};

const Links = (props: LinksProps) => {
  return (
    <LinksContainer {...props}>
      {LEGAL_LINKS.map((link) => (
        <StyledLink
          key={link.name}
          href={link.href}
          isObfuscated={link.isObfuscated || props.areLinksObfuscated}
          target={props.openInNewWindow ? '_blank' : undefined}
        >
          {link.children}
        </StyledLink>
      ))}

      <PaymentLogos />
    </LinksContainer>
  );
};

export const PaymentLogos = () => {
  return (
    <React.Fragment>
      <LinksImage alt="Apple pay logo" src={applePayLogo} />
      <LinksImage alt="3D secure logo" src={threeDSecureLogo} />
    </React.Fragment>
  );
};

const MobileAppLinksContainer = styled.div<
  Pick<MobileAppLinksProps, 'customStyle'>
>`
  ${tw`flex flex-wrap `}
  gap: var(--size-medium2);

  ${({ customStyle }) => customStyle}
`;

const StoreImage = styled.img`
  width: 140px;
`;

type MobileAppLinksProps = {
  customStyle?: FlattenSimpleInterpolation;
  areLinksObfuscated: boolean;
};

export const MobileAppLinks = (props: MobileAppLinksProps) => {
  return (
    <MobileAppLinksContainer customStyle={props.customStyle}>
      <StyledLink
        href={NAVIGATION.ANDROID_APP_LINK}
        isObfuscated={props.areLinksObfuscated}
        rel="noreferrer"
        target="_blank"
      >
        <StoreImage
          alt="Téléchargez l'application Tictactrip sur google play"
          src="/assets/footer/google-play-90h.webp"
        />
      </StyledLink>

      <StyledLink
        href={NAVIGATION.IOS_APP_LINK}
        isObfuscated={props.areLinksObfuscated}
        rel="noreferrer"
        target="_blank"
      >
        <StoreImage
          alt="Téléchargez l'application Tictactrip sur l'app store"
          src="/assets/footer/app-store-90h.webp"
        />
      </StyledLink>
    </MobileAppLinksContainer>
  );
};

export default End;
