/*
 * Results Messages
 *
 * This contains all the text for the Results container.
 */

import { defineMessages } from 'react-intl';

export const expandScope = 'app.components.Expand';

export const expandMessages = {
  details: {
    id: `${expandScope}.details`,
    defaultMessage: 'Détails',
    en: 'Details',
    es: 'Detalles',
    it: 'Dettagli',
    de: 'Einzelheiten',
    nl: 'Details',
  },
};

export default defineMessages(expandMessages);
