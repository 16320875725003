import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import Circle from '../Circle';

const Container = styled.div`
  ${tw`absolute h-full w-full`};
`;

const Circle1 = styled(Circle)`
  ${tw`hidden md:block`};
  top: 31%;
  left: 4%;
`;

const Circle2 = styled(Circle)`
  ${tw`hidden md:block`};
  top: -10%;
  left: 11%;
`;

const Circle3 = styled(Circle)`
  ${tw`hidden md:block`};
  top: -13%;
  left: 50%;
`;

const Circle4 = styled(Circle)`
  ${tw`hidden md:block`};
  top: -12%;
  left: 80%;
`;

const Circle5 = styled(Circle)`
  ${tw`hidden md:block`};
  top: 45%;
  left: 95%;
`;

const Circles = () => (
  <Container>
    <Circle1 />
    <Circle2 />
    <Circle3 />
    <Circle4 />
    <Circle5 />
  </Container>
);

export default Circles;
