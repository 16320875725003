/*
 * Mobile app header Messages
 *
 * This contains all the text for the Mobile app header component.
 */
import { defineMessages } from 'react-intl';

const MOBILEHEADER = 'mobileHeader';

export const mobileHeaderMessages = {
  myOrders: {
    id: `${MOBILEHEADER}.myOrders`,
    defaultMessage: 'Mes billets',
    en: 'My orders',
    it: 'I miei biglietti',
    es: 'Mis entradas',
    de: 'Meine Tickets',
    nl: 'Mijn tickets',
  },
  search: {
    id: `${MOBILEHEADER}.search`,
    defaultMessage: 'Recherche',
    en: 'Search',
    it: 'Ricerca',
    es: 'Busque',
    de: 'Suche',
    nl: 'Zoeken',
  },
};

export default defineMessages(mobileHeaderMessages);
