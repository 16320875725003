import styled from 'styled-components';
import tw from 'tailwind.macro';

export const InputTitle = styled.span`
  ${tw`font-bold`}
`;

export const FormRow = styled.div`
  ${tw`mb-abs2`}
`;
