import * as jwt from 'jsonwebtoken';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AuthService,
  loginWithOrderId,
  logout,
  sendMagicLink,
  verifyMagicLink,
} from '../../services/auth';
import {
  AuthenticationResponse,
  LoginWithOrderIdPayload,
  VerifyMagicLinkPayload,
} from '../../services/auth/types';
import { getUser } from '../../services/users';
import { User } from '../../services/users/types';
import { IAuthContext } from './types';

export const AuthContext = createContext<IAuthContext>({
  user: null,
  isLoading: false,
  accessToken: null,
  login: async () => {},
  logout: async () => {},
  sendMagicLink: async () => null,
  verifyMagicLink: async () => {},
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    AuthService.getUserToken(),
  );
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(!!accessToken);

  const invalidateUserAuth = useCallback(() => {
    AuthService.removeUserAuthentication();
    setUser(null);
    setAccessToken(null);
  }, []);

  const loadUser = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true);
        const user = await getUser(token);

        setUser(user);
      } catch (error) {
        invalidateUserAuth();
      } finally {
        setIsLoading(false);
      }
    },
    [invalidateUserAuth],
  );

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    loadUser(accessToken);
  }, [loadUser, accessToken]);

  const handleAuthResponse = useCallback(
    async (response: AuthenticationResponse) => {
      AuthService.setUserAuthentication(response);

      setAccessToken(response.token);
    },
    [],
  );

  const handleLogin = useCallback(
    async (payload: LoginWithOrderIdPayload) => {
      const response = await loginWithOrderId(payload);
      handleAuthResponse(response);

      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'login',
        transaction_id: payload.orderId,
        user_data: {
          user_id: jwt.decode(response.refreshToken).customerId,
        },
      });
    },
    [handleAuthResponse],
  );

  const handleLogout = useCallback(async () => {
    await logout();

    invalidateUserAuth();
  }, [invalidateUserAuth]);

  const handleVerifyMagicLink = useCallback(
    async (payload: VerifyMagicLinkPayload) => {
      const response = await verifyMagicLink(payload);
      handleAuthResponse(response);
    },
    [handleAuthResponse],
  );

  const value: IAuthContext = useMemo(
    () => ({
      user,
      isLoading,
      accessToken,
      login: handleLogin,
      logout: handleLogout,
      sendMagicLink: sendMagicLink,
      verifyMagicLink: handleVerifyMagicLink,
    }),
    [
      accessToken,
      handleLogin,
      handleLogout,
      handleVerifyMagicLink,
      isLoading,
      user,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
