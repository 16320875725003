/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import {
  COLORS,
  FUNNEL_STEP,
  PARTNER_CONFIG,
  PARTNER_EXTERNAL_ID,
  PARTNER_ID,
  PRIMARY_COLOR,
  SEARCH_URL,
  SECONDARY_COLOR,
  SET_COLORS,
  SET_FUNNEL_STEP,
  SET_PARTNER_CONFIG,
  SET_PARTNER_EXTERNAL_ID,
  SET_PARTNER_ID,
  SET_PRIMARY_COLOR,
  SET_SEARCH_URL,
  SET_SECONDARY_COLOR,
  STEP_NONE,
} from './constants';

// The initial state of the App
const initialState = fromJS({
  [FUNNEL_STEP]: STEP_NONE,
  [SEARCH_URL]: '',
  [PARTNER_ID]: undefined,
  [PARTNER_CONFIG]: {},
  [COLORS]: { [PRIMARY_COLOR]: null, [SECONDARY_COLOR]: null },
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FUNNEL_STEP:
      return state.set(FUNNEL_STEP, action.step);
    case SET_SEARCH_URL:
      return state.set(SEARCH_URL, action.url);
    case SET_PARTNER_ID:
      return state.set(PARTNER_ID, action.partnerId);
    case SET_PARTNER_EXTERNAL_ID:
      return state.set(PARTNER_EXTERNAL_ID, action.partnerExternalId);
    case SET_PARTNER_CONFIG: {
      return state.set(PARTNER_CONFIG, fromJS(action.config));
    }
    case SET_PRIMARY_COLOR:
      return state.setIn([COLORS, PRIMARY_COLOR], action.color);
    case SET_SECONDARY_COLOR:
      return state.setIn([COLORS, SECONDARY_COLOR], action.color);
    case SET_COLORS:
      return state
        .setIn([COLORS, PRIMARY_COLOR], action.theme[PRIMARY_COLOR])
        .setIn([COLORS, SECONDARY_COLOR], action.theme[SECONDARY_COLOR]);
    default:
      return state;
  }
}

export default appReducer;
