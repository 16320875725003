/*
 * CityPage Messages
 *
 * This contains all the text for the City Page container.
 */

import { defineMessages } from 'react-intl';
import {
  DESCRIPTION,
  DESTINATIONS,
  SERVICE_AIR_QUALITY,
  SERVICE_ENGLISH,
  SERVICE_FEMALE_FRIENDLY,
  SERVICE_FREE_WIFI,
  SERVICE_FRIENDLY,
  SERVICE_FUN,
  SERVICE_GAY_FRIENDLY,
  SERVICE_GENDER_RATIO,
  SERVICE_HAPPINESS,
  SERVICE_INTERNET,
  SERVICE_LIFE_QUALITY,
  SERVICE_NIGHTLIFE,
  SERVICE_PEACE,
  SERVICE_POWER,
  SERVICE_PRICE_BEER,
  SERVICE_PRICE_CAPPUCINO,
  SERVICE_PRICE_COKE,
  SERVICE_PRICE_MEAL,
  SERVICE_PRICE_ROOM,
  SERVICE_SAFETY,
  SERVICE_TIPPING,
  SERVICE_UBER,
  SERVICE_WALKABILITY,
  SERVICES,
} from '../constants';

export const cityScope = 'app.containers.City';

export const cityMessages = {
  subtitle: {
    id: `${cityScope}.subtitle`,
    defaultMessage: 'Découvrez {city} au plus bas prix',
    en: 'Discover {city} at the lowest price',
    es: 'Descubre {city} al precio más bajo',
    it: 'Scopri {city} al prezzo più basso',
    de: 'Entdecken Sie {city} zum besten Preis',
    nl: 'Ontdek {city} tegen de laagste prijs',
  },
  [DESTINATIONS]: {
    id: `${cityScope}.${DESTINATIONS}`,
    defaultMessage: 'Découvrir',
    en: 'Discover',
    es: 'Descubrir',
    it: 'Scoprire',
    de: 'Entdecken',
    nl: 'Ontdekken',
  },
  [DESCRIPTION]: {
    id: `${cityScope}.${DESCRIPTION}`,
    defaultMessage: 'En savoir plus',
    en: 'Learn more',
    es: 'Más información',
    it: 'Ulteriori informazioni',
    de: 'Mehr erfahren',
    nl: 'Meer informatie',
  },
  [SERVICES]: {
    id: `${cityScope}.${SERVICES}`,
    defaultMessage: 'Informations',
    en: 'Info',
    es: 'Información',
    it: 'Informazioni',
    de: 'Info',
    nl: 'Informatie',
  },
  infoHeader: {
    id: `${cityScope}.infoHeader`,
    defaultMessage: "Plus d'information sur ",
    en: 'More information about',
    es: 'Más información sobre',
    it: 'Ulteriori informazioni su',
    de: 'Mehr Informationen über',
    nl: 'Meer informatie over',
  },
  misc: {
    id: `${cityScope}.misc`,
    defaultMessage: 'À savoir',
    en: 'Good to know',
    es: 'Bueno saber',
    it: 'Da sapere',
    de: 'Wissenswertes',
    nl: 'Goed om te weten',
  },
  childrenLinksHeader: {
    id: `${cityScope}.childrenLinksHeader`,
    defaultMessage: 'Vous devriez aimer ',
    en: 'You might like',
    es: 'Te podría gustar',
    it: 'Potrebbe piacerti',
    de: 'Das könnte Ihnen gefallen',
    nl: 'Misschien vind je dit leuk',
  },
  siblingsFromHeader: {
    id: `${cityScope}.siblingsFromHeader`,
    defaultMessage: 'Trajets pas cher depuis ',
    en: 'Cheap trips from',
    es: 'Viajes económicos desde',
    it: 'Viaggi economici da',
    de: 'Günstige Reisen von',
    nl: 'Goedkope reizen vanaf',
  },
  siblingsToHeader: {
    id: `${cityScope}.siblingsToHeader`,
    defaultMessage: 'Trajets pas cher vers ',
    en: 'Cheap trips to',
    es: 'Viajes económicos a',
    it: 'Viaggi economici per',
    de: 'Günstige Reisen nach',
    nl: 'Goedkope reizen naar',
  },
  servicesHeader: {
    id: `${cityScope}.servicesHeader`,
    defaultMessage: 'Quelques infos avant le départ',
    en: 'Some info before departure',
    es: 'Algunas informaciones antes de partir',
    it: 'Alcune informazioni prima della partenza',
    de: 'Einige Infos vor der Abreise',
    nl: 'Enkele informatie voor vertrek',
  },
  [SERVICE_PRICE_BEER]: {
    id: `${cityScope}.${SERVICE_PRICE_BEER}`,
    defaultMessage: 'Bière',
    en: 'Beer',
    es: 'Cerveza',
    it: 'Birra',
    de: 'Bier',
    nl: 'Bier',
  },
  [SERVICE_PRICE_COKE]: {
    id: `${cityScope}.${SERVICE_PRICE_COKE}`,
    defaultMessage: 'Soda',
    en: 'Soda',
    es: 'Refresco',
    it: 'Soda',
    de: 'Limonade',
    nl: 'Frisdrank',
  },
  [SERVICE_PRICE_CAPPUCINO]: {
    id: `${cityScope}.${SERVICE_PRICE_CAPPUCINO}`,
    defaultMessage: 'Café',
    en: 'Coffee',
    es: 'Café',
    it: 'Cappuccino',
    de: 'Kaffee',
    nl: 'Koffie',
  },
  [SERVICE_PRICE_MEAL]: {
    id: `${cityScope}.${SERVICE_PRICE_MEAL}`,
    defaultMessage: 'Restaurant',
    en: 'Restaurant',
    es: 'Restaurante',
    it: 'Ristorante',
    de: 'Restaurant',
    nl: 'Restaurant',
  },
  [SERVICE_FREE_WIFI]: {
    id: `${cityScope}.${SERVICE_FREE_WIFI}`,
    defaultMessage: 'Free Wifi',
    en: 'Free Wifi',
    es: 'Wifi gratis',
    it: 'Wifi gratuito',
    de: 'Kostenloses WLAN',
    nl: 'Gratis wifi',
  },
  [SERVICE_POWER]: {
    id: `${cityScope}.${SERVICE_POWER}`,
    defaultMessage: 'Prises',
    en: 'Power',
    es: 'Enchufes',
    it: 'Prese di corrente',
    de: 'Stromversorgung',
    nl: 'Stroomvoorziening',
  },
  [SERVICE_FEMALE_FRIENDLY]: {
    id: `${cityScope}.${SERVICE_FEMALE_FRIENDLY}`,
    defaultMessage: 'Female Friendly',
    en: 'Female Friendly',
    es: 'Amigable para mujeres',
    it: 'Amichevole per le donne',
    de: 'Frauenfreundlich',
    nl: 'Vrouwvriendelijk',
  },
  [SERVICE_WALKABILITY]: {
    id: `${cityScope}.${SERVICE_WALKABILITY}`,
    defaultMessage: 'Piéton',
    en: 'Walkability',
    es: 'Peatonalidad',
    it: 'Pedonalità',
    de: 'Gehfreundlichkeit',
    nl: 'Wandelbaarheid',
  },
  [SERVICE_SAFETY]: {
    id: `${cityScope}.${SERVICE_SAFETY}`,
    defaultMessage: 'Sécurité',
    en: 'Safety',
    es: 'Seguridad',
    it: 'Sicurezza',
    de: 'Sicherheit',
    nl: 'Veiligheid',
  },
  [SERVICE_ENGLISH]: {
    id: `${cityScope}.${SERVICE_ENGLISH}`,
    defaultMessage: 'Anglophone',
    en: 'English speaking',
    es: 'Que habla inglés',
    it: 'Che parla inglese',
    de: 'Englischsprachig',
    nl: 'Engelssprekend',
  },
  [SERVICE_AIR_QUALITY]: {
    id: `${cityScope}.${SERVICE_AIR_QUALITY}`,
    defaultMessage: "Qualité de l'air",
    en: 'Air quality',
    es: 'Calidad del aire',
    it: "Qualità dell'aria",
    de: 'Luftqualität',
    nl: 'Luchtkwaliteit',
  },
  [SERVICE_PEACE]: {
    id: `${cityScope}.${SERVICE_PEACE}`,
    defaultMessage: 'Paix',
    en: 'Peace',
    es: 'Paz',
    it: 'Pace',
    de: 'Frieden',
    nl: 'Vrede',
  },
  [SERVICE_FRIENDLY]: {
    id: `${cityScope}.${SERVICE_FRIENDLY}`,
    defaultMessage: 'Accueil',
    en: 'Welcome',
    es: 'Recepción',
    it: 'Accoglienza',
    de: 'Willkommen',
    nl: 'Welkom',
  },
  [SERVICE_NIGHTLIFE]: {
    id: `${cityScope}.${SERVICE_NIGHTLIFE}`,
    defaultMessage: 'Vie nocturne',
    en: 'Nightlife',
    es: 'Vida nocturna',
    it: 'Vita notturna',
    de: 'Nachtleben',
    nl: 'Nachtleven',
  },
  [SERVICE_FUN]: {
    id: `${cityScope}.${SERVICE_FUN}`,
    defaultMessage: 'Fun',
    en: 'Fun',
    es: 'Diversión',
    it: 'Divertimento',
    de: 'Spaß',
    nl: 'Plezier',
  },
  [SERVICE_TIPPING]: {
    id: `${cityScope}.${SERVICE_TIPPING}`,
    defaultMessage: 'Pourboire',
    en: 'Tipping',
    es: 'Propina',
    it: 'Mancia',
    de: 'Trinkgeld',
    nl: 'Fooi',
  },
  [SERVICE_LIFE_QUALITY]: {
    id: `${cityScope}.${SERVICE_LIFE_QUALITY}`,
    defaultMessage: 'Qualité de vie',
    en: 'Life quality',
    es: 'Calidad de vida',
    it: 'Qualità della vita',
    de: 'Lebensqualität',
    nl: 'Levenskwaliteit',
  },
  [SERVICE_INTERNET]: {
    id: `${cityScope}.${SERVICE_INTERNET}`,
    defaultMessage: 'Internet (vitesse)',
    en: 'Internet speed',
    es: 'Internet (velocidad)',
    it: 'Velocità di Internet',
    de: 'Internetgeschwindigkeit',
    nl: 'Internetsnelheid',
  },
  [SERVICE_HAPPINESS]: {
    id: `${cityScope}.${SERVICE_HAPPINESS}`,
    defaultMessage: 'Bonheur',
    en: 'Happiness',
    es: 'Felicidad',
    it: 'Felicità',
    de: 'Glück',
    nl: 'Geluk',
  },
  [SERVICE_GAY_FRIENDLY]: {
    id: `${cityScope}.${SERVICE_GAY_FRIENDLY}`,
    defaultMessage: 'Gay friendly',
    en: 'Gay friendly',
    es: 'Amigable para la comunidad LGBTQ+',
    it: 'Gay friendly',
    de: 'Gay-freundlich',
    nl: 'Gayvriendelijk',
  },
  [SERVICE_UBER]: {
    id: `${cityScope}.${SERVICE_UBER}`,
    defaultMessage: 'Uber',
    en: 'Uber',
    es: 'Uber',
    it: 'Uber',
    de: 'Uber',
    nl: 'Uber',
  },
  [SERVICE_GENDER_RATIO]: {
    id: `${cityScope}.${SERVICE_GENDER_RATIO}`,
    defaultMessage: 'Ration H/F',
    en: 'Gender ratio',
    es: 'Proporción de género',
    it: 'Rapporto di genere',
    de: 'Geschlechterverhältnis',
    nl: 'Geslachtsverhouding',
  },
  [SERVICE_PRICE_ROOM]: {
    id: `${cityScope}.${SERVICE_PRICE_ROOM}`,
    defaultMessage: 'Chambre',
    en: 'Room',
    es: 'Habitación',
    it: 'Camera',
    de: 'Zimmer',
    nl: 'Kamer',
  },
};

export default defineMessages(cityMessages);
