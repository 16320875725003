import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import IconUrlDino from '../../../images/nodi/nodiWorker.png';
import H2 from '../../H2';
import messages from '../messages';

const Container = styled.div`
  ${tw`w-full flex flex-col items-center text-center`}
  gap:2rem;
`;

const CustomH2 = styled(H2)`
  ${tw`my-0`}
`;

const Image = styled.img`
  ${tw`max-w-xxs`}
`;

/**
 * @description Returns the default message that will be displayed when the API is in maintenance mode.
 * @returns {Node}
 */
const MaintenanceView = () => (
  <Container>
    <Image alt="nodi worker image" src={IconUrlDino} />
    <CustomH2>
      <FormattedMessage {...messages.underMaintenance} />
    </CustomH2>

    <p>
      <FormattedMessage {...messages.apologyMessage} />
    </p>
  </Container>
);

export { MaintenanceView };
