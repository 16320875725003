import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as packageJson from '../../package.json';
import { SENTRY_DSN, SENTRY_ENV } from '../helpers/constants';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  integrations: [new BrowserTracing()],
  release: `front@${packageJson.version}`,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    /ChunkLoadError: Loading chunk \d+ failed\./,
  ],
});

export { Sentry };
