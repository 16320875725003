import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

export const P1Styled = css`
  ${tw`text-p1 leading-p1`}
`;

export const P1 = styled.p`
  ${P1Styled}
`;

export const P1SemiBoldStyled = css`
  ${P1Styled}
  ${tw`font-semibold`}
`;

export const P1SemiBold = styled.p`
  ${P1SemiBoldStyled}
`;

export const P1ItalicStyled = css`
  ${P1Styled}
  ${tw`italic`}
`;

export const P1Italic = styled.p`
  ${P1ItalicStyled}
`;

export const P1UnderlineStyled = css`
  ${P1Styled}
  ${tw`underline`}
`;

export const P1Underline = styled.p`
  ${P1UnderlineStyled}
`;

// P2 :
export const P2Styled = css`
  ${tw`text-p2 leading-p2`}
`;

export const P2 = styled.p`
  ${P2Styled}
`;

export const P2SemiBoldStyled = css`
  ${P2Styled}
  ${tw`font-semibold`}
`;

export const P2SemiBold = styled.p`
  ${P2SemiBoldStyled}
`;

export const P2ItalicStyled = css`
  ${P2Styled}
  ${tw`italic`}
`;

export const P2Italic = styled.p`
  ${P2ItalicStyled}
`;

export const P2UnderlineStyled = css`
  ${P2Styled}
  ${tw`underline`}
`;

export const P2Underline = styled.p`
  ${P2UnderlineStyled}
`;

// P3 :
export const P3Styled = css`
  ${tw`text-p3 leading-p3`}
`;

export const P3 = styled.p`
  ${P3Styled}
`;

export const P3SemiBoldStyled = css`
  ${P3Styled}
  ${tw`font-semibold`}
`;

export const P3SemiBold = styled.p`
  ${P3SemiBoldStyled}
`;

export const P3ItalicStyled = css`
  ${P3Styled}
  ${tw`italic`}
`;

export const P3Italic = styled.p`
  ${P3ItalicStyled}
`;

export const P3UnderlineStyled = css`
  ${P3Styled}
  ${tw`underline`}
`;

export const P3Underline = styled.p`
  ${P3UnderlineStyled}
`;
