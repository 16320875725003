import PropTypes from 'prop-types';

export const SET_LOADING = 'app/Company/SET_LOADING';

export const FAQ = 'FAQ';
export const MANUAL_DATA = 'ManualData';
export const DESTINATIONS = 'destinations';
export const HISTORY = 'history';
export const SERVICES = 'services';
export const NEWS_FEED = 'newsFeed';

export const SERVICE_POWER = 'power';
export const SERVICE_WIFI = 'wifi';
export const SERVICE_MEAL = 'meal';
export const SERVICE_RESTROOM = 'restroom';
export const SERVICE_MEDIA = 'media';
export const SERVICE_AIR_CONDITIONING = 'airConditioning';
export const SERVICE_LUGGAGE = 'luggage';
export const SERVICE_BIKE = 'bike';
export const SERVICE_ANIMAL = 'animal';
export const SERVICE_ACCESSIBILITY = 'accessibility';

export const companyServicesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ id: PropTypes.string, value: PropTypes.bool }),
);

export const destinationCardsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    description: PropTypes.string,
    destinationCard: PropTypes.shape({
      country: PropTypes.string,
      external: PropTypes.bool,
      id: PropTypes.number,
      image: PropTypes.string,
      price: PropTypes.number,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
);

export const siblingsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
  }),
);
