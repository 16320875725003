/*
 * Footer Messages
 *
 * This contains all the text for the footer.
 */

import { defineMessages } from 'react-intl';

export const footerScope = 'app.components.footer';

export const footerMessages = {
  theBestPlansToSave: {
    id: `${footerScope}.theBestPlansToSave`,
    defaultMessage: 'Les meilleurs plans pour économiser.',
    en: 'The best plans to save.',
    it: 'I migliori piani per risparmiare.',
    es: 'Los mejores planes para ahorrar.',
    de: 'Die besten Pläne zum Sparen.',
    nl: 'De beste plannen om te besparen.',
  },
  trusboxReviews: {
    id: `${footerScope}.trusboxReviews`,
    defaultMessage: '4,7 sur 1146 avis',
    en: '4.7 out of 1146 reviews',
    it: '4,7 su 1146 recensioni',
    es: '4,7 de 1146 opiniones',
    de: '4,7 von 1146 Bewertungen',
    nl: '4,7 van de 1146 beoordelingen',
  },
  frequentDestinations: {
    id: `${footerScope}.frequentDestinations`,
    defaultMessage: 'Destinations fréquentes',
    en: 'Frequent destinations',
    it: 'Destinazioni frequenti',
    es: 'Destinos frecuentes',
    de: 'Häufige Ziele',
    nl: 'Veelvoorkomende bestemmingen',
  },
  trainCompanies: {
    id: `${footerScope}.trainCompanies`,
    defaultMessage: 'Compagnies de trains',
    en: 'Train companies',
    it: 'Compagnie ferroviarie',
    es: 'Compañías de trenes',
    de: 'Eisenbahngesellschaften',
    nl: 'Spoorwegmaatschappijen',
  },
  busCompanies: {
    id: `${footerScope}.busCompanies`,
    defaultMessage: 'Compagnies de bus',
    en: 'Bus companies',
    it: 'Compagnie di autobus',
    es: 'Compañías de autobuses',
    de: 'Busunternehmen',
    nl: 'Busmaatschappijen',
  },
  carpoolingCompanies: {
    id: `${footerScope}.carpoolingCompanies`,
    defaultMessage: 'Compagnies de covoiturage',
    en: 'Carpooling companies',
    it: 'Compagnie di carpooling',
    es: 'Compañías de viajes compartidos',
    de: 'Mitfahrgelegenheit Unternehmen',
    nl: 'Carpoolbedrijven',
  },
  transportComparator: {
    id: `${footerScope}.transportComparator`,
    defaultMessage: 'Comparateurs de transports',
    en: 'Transport comparators',
    it: 'Comparatori di trasporti',
    es: 'Comparadores de transporte',
    de: 'Transportvergleiche',
    nl: 'Transportvergelijkers',
  },
  help: {
    id: `${footerScope}.help`,
    defaultMessage: 'Coup de pouce',
    en: 'Help',
    it: 'Aiuto',
    es: 'Ayuda',
    de: 'Hilfe',
    nl: 'Hulp',
  },
  theEssentials: {
    id: `${footerScope}.theEssentials`,
    defaultMessage: "L'essentiel",
    en: 'The essentials',
    it: "L'essenziale",
    es: 'Lo esencial',
    de: 'Das Wesentliche',
    nl: 'Het essentiële',
  },
  welcome: {
    id: `${footerScope}.welcome`,
    defaultMessage: 'Bienvenue !',
    en: 'Welcome!',
    it: 'Benvenuto!',
    es: '¡Bienvenido!',
    de: 'Willkommen!',
    nl: 'Welkom!',
  },
  lowCarbonTransportation: {
    id: `${footerScope}.lowCarbonTransportation`,
    defaultMessage:
      'On est là pour faire du transports bas-carbone le 1er choix.',
    en: "We're here to make low-carbon transportation the first choice.",
    it: 'Siamo qui per rendere il trasporto a basse emissioni il primo scelta.',
    es: 'Estamos aquí para que el transporte de bajas emisiones sea la primera opción.',
    de: 'Wir sind hier, um den kohlenstoffarmen Transport zur ersten Wahl zu machen.',
    nl: 'We zijn hier om duurzaam vervoer de eerste keuze te maken.',
  },
  moreDestination: {
    id: `${footerScope}.moreDestination`,
    defaultMessage:
      'Plus de destinations, plus de transporteurs et moins de galères.',
    en: 'More destinations, more carriers, and fewer hassles.',
    it: 'Più destinazioni, più vettori e meno problemi.',
    es: 'Más destinos, más transportistas y menos problemas.',
    de: 'Mehr Ziele, mehr Transportunternehmen und weniger Ärger.',
    nl: 'Meer bestemmingen, meer vervoerders en minder gedoe.',
  },
  yourReservations: {
    id: `${footerScope}.yourReservations`,
    defaultMessage: 'Vos réservations',
    en: 'Your reservations',
    it: 'Le tue prenotazioni',
    es: 'Tus reservas',
    de: 'Ihre Reservierungen',
    nl: 'Uw reserveringen',
  },
  interactiveMap: {
    id: `${footerScope}.interactiveMap`,
    defaultMessage: 'Carte interactive',
    en: 'Interactive map',
    it: 'Carta interattiva',
    es: 'Mapa interactivo',
    de: 'Interaktive Karte',
    nl: 'Interactieve kaart',
  },
  saveMax: {
    id: `${footerScope}.saveMax`,
    defaultMessage: 'Économiser au maximum',
    en: 'Save a max',
    it: 'Risparmia al massimo',
    es: 'Ahorra al máximo',
    de: 'Maximal sparen',
    nl: 'Maximaal besparen',
  },
  travelBlog: {
    id: `${footerScope}.travelBlog`,
    defaultMessage: 'Blog de voyage',
    en: 'Travel blog',
    it: 'Blog di viaggio',
    es: 'Blog de viajes',
    de: 'Reiseblog',
    nl: 'Reisblog',
  },
  helpCenter: {
    id: `${footerScope}.helpCenter`,
    defaultMessage: "Centre d'aide",
    en: 'Help center',
    it: 'Centro assistenza',
    es: 'Centro de ayuda',
    de: 'Hilfezentrum',
    nl: 'Helpcentrum',
  },
  contactUs: {
    id: `${footerScope}.contactUs`,
    defaultMessage: 'Contactez-nous',
    en: 'Contact Us',
    it: 'Contattaci',
    es: 'Contáctanos',
    de: 'Kontaktiere uns',
    nl: 'Neem contact op met ons',
  },
  faq: {
    id: `${footerScope}.faq`,
    defaultMessage: 'FAQ',
    en: 'FAQ',
    it: 'Questions fréquentes',
    es: 'Preguntas frecuentes',
    de: 'FAQ',
    nl: 'Veelgestelde vragen',
  },
  aboutUs: {
    id: `${footerScope}.aboutUs`,
    defaultMessage: 'À propos de nous',
    en: 'About us',
    it: 'Chi siamo',
    es: 'Sobre nosotros',
    de: 'Über uns',
    nl: 'Over ons',
  },
  howItWorks: {
    id: `${footerScope}.howItWorks`,
    defaultMessage: 'Fonctionnement',
    en: 'How it works',
    it: 'Come funziona',
    es: 'Cómo funciona',
    de: 'Wie es funktioniert',
    nl: 'Hoe het werkt',
  },
  becomeAPartner: {
    id: `${footerScope}.becomeAPartner`,
    defaultMessage: 'Devenez partenaire',
    en: 'Become a partner',
    it: 'Diventa un partner',
    es: 'Conviértete en socio',
    de: 'Partner werden',
    nl: 'Word partner',
  },
  jobs: {
    id: `${footerScope}.jobs`,
    defaultMessage: 'Emplois',
    en: 'Jobs',
    it: 'Lavori',
    es: 'Trabajos',
    de: 'Jobs',
    nl: 'Vacatures',
  },
  press: {
    id: `${footerScope}.press`,
    defaultMessage: 'Presse',
    en: 'Press',
    it: 'Stampa',
    es: 'Prensa',
    de: 'Presse',
    nl: 'Pers',
  },
  trains: {
    id: `${footerScope}.trains`,
    defaultMessage: 'Trains',
    en: 'Trains',
    it: 'Treni',
    es: 'Trenes',
    de: 'Züge',
    nl: 'Treinen',
  },
  bus: {
    id: `${footerScope}.bus`,
    defaultMessage: 'Bus',
    en: 'Bus',
    it: 'Autobus',
    es: 'Autobús',
    de: 'Bus',
    nl: 'Bus',
  },
  carpooling: {
    id: `${footerScope}.carpooling`,
    defaultMessage: 'Covoiturage',
    en: 'Carpooling',
    it: 'Carpooling',
    es: 'Viaje compartido',
    de: 'Mitfahrgelegenheit',
    nl: 'Carpoolen',
  },
  legalNotice: {
    id: `${footerScope}.legalNotice`,
    defaultMessage: 'Mentions légales',
    en: 'Legal notice',
    it: 'Avviso legale',
    es: 'Aviso legal',
    de: 'Impressum',
    nl: 'Juridische informatie',
  },
  privacy: {
    id: `${footerScope}.privacy`,
    defaultMessage: 'Confidentialité',
    en: 'Privacy',
    it: 'Privacy',
    es: 'Privacidad',
    de: 'Datenschutz',
    nl: 'Privacy',
  },
  termsAndConditions: {
    id: `${footerScope}.termsAndConditions`,
    defaultMessage: 'CGV',
    en: 'Terms and conditions',
    it: 'Condizioni generali di vendita',
    es: 'Condiciones generales de venta',
    de: 'Allgemeine Geschäftsbedingungen',
    nl: 'Algemene voorwaarden',
  },
  agenceAgreeeSNCF: {
    id: `${footerScope}.agenceAgreeeSNCF`,
    defaultMessage: 'Agence agréée SNCF',
    en: 'SNCF approved agency',
    it: 'Agenzia approvata SNCF',
    es: 'Agencia aprobada por SNCF',
    de: 'Von der SNCF genehmigte Agentur',
    nl: 'SNCF goedgekeurd agentschap',
  },
  comparator: {
    id: `${footerScope}.comparator`,
    defaultMessage: 'Comparator',
    en: 'Comparator',
    it: 'Comparatore',
    es: 'Comparador',
    de: 'Vergleichsrechner',
    nl: 'Vergelijker',
  },
};

export default defineMessages(footerMessages);
