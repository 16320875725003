import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import H1 from '../../components/H1';
import H3 from '../../components/H3';
import RoundShadowBox from '../../components/RoundShadowBox';
import { Spacer } from '../../components/Spacer';
import {
  PAGE_TITLE,
  PAYMENT_LINK_CARD_CONTENT,
  PAYMENT_LINK_CARD_TITLE,
} from '../PaymentLink/constants';
import { Circles } from '../PaymentLink/View/Circles';
import messages from '../PaymentLink/View/messages';

const MainContainer = styled.div`
  ${tw`relative bg-terciary500 p-def sm:p-abs4 overflow-hidden`};
`;

const Header = styled(H1)`
  ${tw`text-white text-center my-abs3 md:mt-abs8`};
`;

const PaymentLinkCardContainer = styled(RoundShadowBox)`
  ${tw`relative bg-white p-def mx-auto sm:px-abs4 md:px-abs10 z-1 text-center`};
  max-width: 800px;
`;

const PaymentLinkCardTitle = styled(H3)`
  ${tw`text-center mb-abs3`};
`;

const PaymentLinkConfirmation = () => (
  <MainContainer>
    <Circles />
    <Header>
      <FormattedMessage {...messages[PAGE_TITLE]} />
    </Header>
    <PaymentLinkCardContainer>
      <PaymentLinkCardTitle>
        <FormattedMessage {...messages[PAYMENT_LINK_CARD_TITLE]} />
      </PaymentLinkCardTitle>
      <FormattedMessage {...messages[PAYMENT_LINK_CARD_CONTENT]} />
      <Spacer height={4} />
    </PaymentLinkCardContainer>
    <Spacer height={20} />
  </MainContainer>
);

export default PaymentLinkConfirmation;
