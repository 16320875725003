import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyFeatureFlags = lazy(() =>
  import(/* webpackChunkName: 'FeatureFlags' */ './index'),
);

export default function FeatureFlags(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyFeatureFlags {...props} />
    </Suspense>
  );
}
