import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import CloseIconSrc from '../../images/iconClose.svg';
import { colors } from '../../styles/constants';
import { Icon18 } from '../Icon';
import { CLOSE } from './constants';
import messages from './messages';

const { white } = colors;

/**
 * @note The z-index is the same as that of the header to avoid
 * side effects if the current z-index of the header is changed.
 */
const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  inset: 0;
  background-color: rgba(55, 55, 55, 0.55);
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-gap: 2rem;
  padding: 2rem 0;
  background-color: ${white};
  width: 90vw;
  max-height: 95vh;
  overflow: hidden;
  border-radius: 1rem;

  @media (min-width: 768px) {
    max-height: 70vh;
    width: 40vw;
    min-width: 400px;
    max-width: 500px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template: 'header close' / auto 2rem;
  grid-gap: 1rem;
  padding: 0 2rem;
`;

const Close = styled.img`
  ${Icon18}
  cursor: pointer;
  grid-area: close;
`;

const Main = styled.div`
  overflow-y: auto;
  padding: 0 2rem;
`;

/**
 * @typedef {Object} NewModalProps
 * @property {React.ReactNode} [header] The header of the modal.
 * @property {Object} intl The intl object.
 * @property {boolean} isOpen Whether the modal is open or not.
 * @property {React.ReactNode} main The main content of the modal.
 * @property {() => void} onClose The function to call when the close button is clicked.
 */

/**
 * @description Displays a modal with a `header` and a `main` content.
 * @note This component is called `NewModal` because it replaces the `Modal`
 * component currently used in the application. Renaming `Modal` to `LegacyModal`
 * would have required a lot of changes to the code base.
 * @param {NewModalProps} props
 * @returns {JSX.Element}
 */
export function NewModal({ header, intl, isOpen, main, onClose }) {
  /**
   * @description Prevents the body from scrolling when the modal is open.
   */
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  /**
   * @description Handles the click on the container to prevent the modal from closing.
   */
  const onClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <CSSTransition
      appear
      classNames="fadeIn"
      in={isOpen}
      timeout={250}
      unmountOnExit
    >
      <Background isOpen={isOpen} onClick={onClose}>
        <Container onClick={onClick}>
          <Header>
            {header}
            {onClose && (
              <Close
                alt={intl.formatMessage(messages[CLOSE])}
                aria-label={intl.formatMessage(messages[CLOSE])}
                onClick={onClose}
                src={CloseIconSrc}
              />
            )}
          </Header>
          <Main>{main}</Main>
        </Container>
      </Background>
    </CSSTransition>
  );
}

NewModal.propTypes = {
  header: PropTypes.node,
  intl: intlShape,
  isOpen: PropTypes.bool.isRequired,
  main: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

const NewModalIntl = injectIntl(NewModal);

export default NewModalIntl;
