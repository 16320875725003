import { isEmpty } from 'lodash';
import { City } from '../../services/cities/types';
import {
  FORMATTED_TRIPS_1,
  FORMATTED_TRIPS_2,
  FORMATTED_TRIPS_3,
  SEARCH_PAGE_TYPE_TO_TRANSPORT_TYPE,
} from './constants';
import {
  DynamicSearchPageInformation,
  GetFormattedTripMessagesResponse,
  SearchPageType,
} from './types';
import messages from './View/messages';

/**
 * @description Update incorrect information in statistics
 * @note This method is a workaround to avoid displaying minimum prices of 0 euros and must be deleted in 1 year (30/06/2023), by which time all keys will have expired.
 * @param {object} tripData Trip data returned by api
 * @returns {object}
 */
export const updateTripData = (tripData) => {
  if (isEmpty(tripData)) {
    return tripData;
  }
  const percentage = 20;
  const fixMinPrice = (maxPrice) => maxPrice * (percentage / 100);
  const fixMaxPrice = (maxPrice, minPrice) => {
    const updatedMaxPrice = minPrice * 10.3;

    // To avoid having a maximum price too far from reality, the maximum price must not exceed 10.3 * minPrice
    if (maxPrice < updatedMaxPrice) {
      return maxPrice;
    }

    return updatedMaxPrice;
  };

  const updatedTripData = { ...tripData };
  let updatedGeneralMaxPrice;

  Object.keys(updatedTripData.transportInformation).forEach((key) => {
    if (isEmpty(updatedTripData.transportInformation[key])) {
      return;
    }

    const transportInformation = updatedTripData.transportInformation[key];

    if (transportInformation && !transportInformation.length) {
      const { mean, min } =
        updatedTripData.transportInformation[key].bestItinerary.price;
      if (min === 0) {
        updatedTripData.transportInformation[key].bestItinerary.price.min =
          fixMinPrice(mean);
      }

      updatedTripData.transportInformation[key].bestItinerary.price.max =
        fixMaxPrice(
          updatedTripData.transportInformation[key].bestItinerary.price.max,
          updatedTripData.transportInformation[key].bestItinerary.price.min,
        );

      if (
        updatedTripData.priceDurationGeneralInfo.minPrice === 0 ||
        updatedTripData.transportInformation[key].bestItinerary.price.min <
          updatedTripData.priceDurationGeneralInfo.minPrice
      ) {
        updatedTripData.priceDurationGeneralInfo.minPrice =
          updatedTripData.transportInformation[key].bestItinerary.price.min;
      }

      if (
        !updatedGeneralMaxPrice ||
        updatedTripData.transportInformation[key].bestItinerary.price.max >
          updatedGeneralMaxPrice
      ) {
        updatedGeneralMaxPrice =
          updatedTripData.transportInformation[key].bestItinerary.price.max;
      }
    }
  });

  if (updatedGeneralMaxPrice) {
    updatedTripData.priceDurationGeneralInfo.maxPrice = updatedGeneralMaxPrice;
  }

  return updatedTripData;
};

export const canDisplayTripInformationSection = (tripData) => {
  return (
    isEmpty(tripData) === false && tripData.transportInformation.historyData
  );
};

export const doesAlternativePageExist = (payload): boolean => {
  const { destinationCity, isMainPageAllowed, originCity, pageType, tripData } =
    payload;

  const mainPageType = getMainSearchPageType({
    destinationCity,
    originCity,
    tripData,
  });

  const isMainPageType = isMainPageAllowed ? false : mainPageType === pageType;

  if (isMainPageType || mainPageType === SearchPageType.GENERIC) {
    return false;
  }

  // One of the cities must be in France
  if (originCity.countryCode !== 'fr' && destinationCity.countryCode !== 'fr') {
    return false;
  }

  if (isEmpty(tripData) || !tripData.transportInformation.historyData) {
    return false;
  }

  const transportType = SEARCH_PAGE_TYPE_TO_TRANSPORT_TYPE[pageType];
  const transportInformation = tripData.transportInformation[transportType];

  if (
    isEmpty(transportInformation) ||
    (pageType === SearchPageType.BUS && transportInformation.minNbSteps > 1)
  ) {
    return false;
  }

  return true;
};

export const getMainSearchPageType = (payload): SearchPageType => {
  const { destinationCity, originCity, tripData } = payload;

  if (
    (originCity.countryCode !== 'fr' && destinationCity.countryCode !== 'fr') ||
    isEmpty(tripData)
  ) {
    return SearchPageType.GENERIC;
  }

  const {
    bus: busData,
    historyData,
    train: trainData,
  } = tripData.transportInformation;

  const train = hasDataAndDirectTrip(trainData, historyData);
  const bus = hasDataAndDirectTrip(busData, historyData);

  if (train.hasData && bus.hasData && bus.hasDirect) {
    return trainData.bestItinerary.duration.min <
      busData.bestItinerary.duration.min
      ? SearchPageType.TRAIN
      : SearchPageType.BUS;
  }

  if (train.hasData && train.hasDirect) {
    return SearchPageType.TRAIN;
  }

  if (bus.hasData && bus.hasDirect) {
    return SearchPageType.BUS;
  }

  return SearchPageType.GENERIC;
};

const hasDataAndDirectTrip = (
  information,
  hasHistoryData,
): { hasDirect: boolean; hasData: boolean } => {
  if (isEmpty(information)) {
    return { hasDirect: false, hasData: false };
  }

  if (hasHistoryData) {
    return {
      hasData: true,
      hasDirect: information.minNbSteps - 1 === 0,
    };
  }

  return {
    hasDirect: information.some((trip) => trip.hasDirectTrip),
    hasData: false,
  };
};

export const extractSearchPageDynamicInformation = (
  tripData,
  currentPageType,
): DynamicSearchPageInformation => {
  if (isEmpty(tripData)) {
    return null;
  }

  if (
    !tripData.priceDurationGeneralInfo ||
    !tripData.transportInformation.historyData
  ) {
    return {
      price: null,
      duration: null,
      dailyTripsPerDay: null,
      companies: tripData.companiesInvolved,
      minNbStops: tripData.minNbStops,
      pageType: currentPageType,
      transportType: null,
    };
  }

  if (currentPageType === SearchPageType.GENERIC) {
    return {
      price: {
        min: tripData.priceDurationGeneralInfo.minPrice,
        max: tripData.priceDurationGeneralInfo.maxPrice,
      },
      duration: {
        min: tripData.priceDurationGeneralInfo.minDuration / 60,
        max: tripData.priceDurationGeneralInfo.maxDuration / 60 || 90, // todo: Ajouter sur l'api
      },
      dailyTripsPerDay: null,
      companies: tripData.companiesInvolved,
      minNbStops: tripData.minNbStops,
      pageType: currentPageType,
      transportType: null,
    };
  }

  const transportType = SEARCH_PAGE_TYPE_TO_TRANSPORT_TYPE[currentPageType];

  if (!transportType) {
    return null;
  }

  const information = tripData.transportInformation[transportType];
  const { bestItinerary } = information;

  return {
    price: {
      min: bestItinerary.price.min,
      max: bestItinerary.price.max,
    },
    duration: {
      min: bestItinerary.duration.min,
      max: bestItinerary.duration.max,
    },
    dailyTripsPerDay: information.nbPerDay,
    companies: bestItinerary.companies,
    minNbStops: bestItinerary.minNbStops,
    pageType: currentPageType,
    transportType: transportType,
  };
};

export const getFormattedTripMessages = (
  intl: any,
  originCity: City,
  destinationCity: City,
): GetFormattedTripMessagesResponse => {
  return {
    formattedTrip1Message: intl.formatMessage(messages[FORMATTED_TRIPS_1], {
      origin: originCity.name,
      destination: destinationCity.name,
    }),
    formattedTrip2Message: intl.formatMessage(messages[FORMATTED_TRIPS_2], {
      origin: originCity.name,
      destination: destinationCity.name,
    }),
    formattedTrip3Message: intl.formatMessage(messages[FORMATTED_TRIPS_3], {
      origin: originCity.name,
      destination: destinationCity.name,
    }),
  };
};
