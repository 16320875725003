import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyRedirect = lazy(() =>
  import(/* webpackChunkName: 'Redirect' */ './index'),
);

export default function Redirect(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyRedirect {...props} />
    </Suspense>
  );
}
