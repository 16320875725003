import PropTypes from 'prop-types';
import { faqPropTypes } from '../../components/FAQSection/constants';
import { generalTablePropTypes } from '../../components/Tables';

export const SET_MOUNTAIN_DATA = 'app/Mountain/SET_MOUNTAIN_DATA';

export const MAP = 'map';
export const DESTINATIONS = 'destinations';
export const DESCRIPTION = 'description';
export const SERVICES = 'services';
export const CHILDREN_LINKS = 'childrenLinks';
export const LINKS_FROM = 'linksFrom';
export const LINKS_TO = 'linksTo';
export const MOUNTAIN_DATA = 'mountainData';

export const DIRECTION_FROM = 'from';
export const DIRECTION_TO = 'to';
export const MAP_REGION_NAME = 'regionName';

export const MAP_REGIONS_SOURCE = 'regions';
export const MAP_SELECTED_REGION_SOURCE = 'selectedRegion';
export const MAP_REGIONS_FILL_LAYER = 'regionsFill';
export const MAP_SELECTED_REGION_LAYER = 'selectedRegionLayer';

export const STATION_DISTANCE_KM = 'distanceKm';
export const STATION_PRICE_CENTS = 'priceCents';
export const STATION_DURATION_MINUTES = 'durationMinutes';
export const STATION_ACTIVITIES_SPORT = 'activitySport';
export const STATION_ACTIVITIES_CULTURE = 'activityCulture';
export const STATION_ACTIVITIES_EVENTS = 'activityEvents';
export const STATION_ACTIVITIES_FAMILIES = 'activityFamilies';
export const STATION_ACTIVITIES_CHILDREN = 'activityChildren';
export const STATION_ACTIVITIES_FACILITIES = 'activityFacilities';

export const STATION_FEATURE_BUDGET = 'featureBudget';
export const STATION_FEATURE_VIEW = 'featureViw';
export const STATION_FEATURE_POPULATION = 'featurePopulation';
export const STATION_FEATURE_WINTER = 'featureWinter';
export const STATION_FEATURE_COVID = 'featureCovid';

export const STATION_LABEL_SPORT = 'labelSport';
export const STATION_LABEL_FAMILY = 'labelFamily';
export const STATION_LABEL_FAMILY_PLUS = 'labelFamilyPlus';
export const STATION_LABEL_FRIENDS = 'labelFriends';
export const STATION_LABEL_PEACE = 'labelPeace';
export const STATION_LABEL_CULTURE = 'labelCulture';
export const STATION_LABEL_NATURE = 'labelNature';

export const STATION_TRANSPORT_TRAIN = '🚞';
export const STATION_TRANSPORT_BUS = '🚌';
export const STATION_TRANSPORT_MULTIMODAL = '🚞 + 🚌';
export const STATION_TRANSPORT_CARPOOLING = '🚗';

export const STATION_LABELS = {
  [STATION_LABEL_SPORT]: '🏃',
  [STATION_LABEL_FAMILY]: '👪',
  [STATION_LABEL_FAMILY_PLUS]: '👪 +',
  [STATION_LABEL_FRIENDS]: '🎉',
  [STATION_LABEL_PEACE]: '🧘',
  [STATION_LABEL_CULTURE]: '📚',
  [STATION_LABEL_NATURE]: '🌲',
};

export const VALUE_SERVICES = 'VALUE_SERVICES';
export const PRICE_SERVICES = 'PRICE_SERVICES';
export const OTHER_SERVICES = 'OTHER_SERVICES';

export const SERVICE_POWER = 'power';

export const META_TITLE =
  'La carte interactive de Tictactrip, la montagne à portée de train !';
export const META_DESCRIPTION =
  'Une carte interactive pour trouver LA station de montagne qui vous correspond cet été ! Ça vous tente ? C’est simple et ludique, essayez la. 😉';

export const stationFeaturePropTypes = PropTypes.shape({
  emoji: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
});

export const stationPropTypes = PropTypes.shape({
  activities: generalTablePropTypes,
  externalLink: PropTypes.string,
  id: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  region: PropTypes.string,
  searchName: PropTypes.string,
  services: PropTypes.arrayOf(stationFeaturePropTypes),
});

export const destinationPropTypes = PropTypes.shape({
  info: generalTablePropTypes,
  station: stationPropTypes,
  transports: faqPropTypes,
});

export const mountainPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    region: PropTypes.string,
    station: PropTypes.string,
  }),
);
