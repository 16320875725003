import React from 'react';
import { RefCallBack } from 'react-hook-form';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import Input from '.';

const PlaceHolderFocusStyle = css`
  transform: translate(0, -100%);
  ${tw`text-p-xsmall leading-p-xsmall font-normal`}
`;

export const Placeholder = styled.label<{ isFilled: boolean }>`
  ${tw`absolute font-bold text-placeHolders`}
  pointer-events: none;
  left: 24px;
  top: 50%;
  transform: translate(0, -50%);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  ${({ isFilled }) => isFilled && PlaceHolderFocusStyle}
`;

export const Container = styled.div`
  ${tw`relative w-full`}

  &:focus-within ${Placeholder} {
    ${PlaceHolderFocusStyle}
  }
`;

export const FieldStyle = css`
  ${tw`w-full`}

  height: 60px;
  padding-top: 30px;
  padding-bottom: 6px;
  padding-left: 24px;
  padding-right: 24px;

  &::placeholder {
    opacity: 0;
  }

  &:focus::placeholder {
    opacity: 1;
    ${tw`font-bold`};
  }
`;

const Field = styled(Input)`
  ${FieldStyle}
`;

export interface IAnimatedTextField
  extends React.InputHTMLAttributes<HTMLInputElement> {
  placeHolderLabel: React.ReactNode;
  inputRef?: React.MutableRefObject<HTMLInputElement> | RefCallBack;
}

const AnimatedTextField: React.FC<IAnimatedTextField> = ({
  inputRef,
  ...props
}) => {
  return (
    <Container>
      <Field {...props} ref={inputRef} />
      <Placeholder isFilled={!!props.value}>
        {props.placeHolderLabel}
      </Placeholder>
    </Container>
  );
};

export default AnimatedTextField;
