/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const FR = 'fr';
export const EN = 'en';
export const IT = 'it';
export const ES = 'es';
export const DE = 'de';
export const NL = 'nl';

export const momentLocaleMapping = {
  [FR]: 'fr',
  [EN]: 'en-gb',
  [IT]: 'it',
  [ES]: 'es',
  [DE]: 'de',
  [NL]: 'nl',
};
