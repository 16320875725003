/*
 * Trip Simple Messages
 *
 * This contains all the text for the Funnel Header Component
 */

import { defineMessages } from 'react-intl';
import { OUTBOUND, RETURN } from '../../containers/App/constants';

export const tripSimpleScope = 'app.components.tripSimple';

export const tripSimpleMessages = {
  [OUTBOUND]: {
    id: `${tripSimpleScope}.outboundTrip`,
    defaultMessage: 'Aller',
    en: 'Outbound',
    it: 'Andata',
    es: 'Salida',
    de: 'Hinreise',
    nl: 'Uitgaand',
  },
  [RETURN]: {
    id: `${tripSimpleScope}.returnTrip`,
    defaultMessage: 'Retour',
    en: 'Return',
    it: 'Ritorno',
    es: 'Volver',
    de: 'Rückreise',
    nl: 'Terug',
  },
  date: {
    id: `${tripSimpleScope}.date`,
    defaultMessage: '{origin} - {destination} le {date}',
    en: '{origin} - {destination} on {date}',
    it: '{origin} - {destination} il {date}',
    es: '{origin} - {destination} el {date}',
    de: '{origin} - {destination} am {date}',
    nl: '{origin} - {destination} le {date}',
  },
};

export default defineMessages(tripSimpleMessages);
