import { defineMessages } from 'react-intl';

export const appBannerScope = 'app.components.appBanner';

export const appBannerMessages = {
  getInStore: {
    id: `${appBannerScope}.getInStore`,
    defaultMessage: 'Télécharger l’application',
    en: 'Download the application ',
    it: 'Scarica la domanda ',
    es: 'Descargar la solicitud',
    de: 'Download der Bewerbung',
    nl: 'De toepassing downloaden',
  },
  title: {
    id: `${appBannerScope}.title`,
    defaultMessage: 'Passez par là ou par l’app ✌️',
    en: 'Pass through there or through the app ✌️',
    it: "Passa da lì o tramite l'app ✌️",
    es: 'Pasa por ahí o por la aplicación ✌️',
    de: 'Gehen Sie dort vorbei oder durch die App ✌️',
    nl: 'Ga daarlangs of via de app ✌️',
  },
};

export default defineMessages(appBannerMessages);
