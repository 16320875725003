import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import history from '../../utils/history';

const EXTERNAL_LINK_IDENTIFIER_SECURED = 'https://';
const EXTERNAL_LINK_IDENTIFIER = 'http://';

export const LinkExtern = styled.a`
  ${(props) => props.styles};
  text-decoration: none;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
`;

export const LinkIntern = styled(NavLink)`
  ${(props) => props.styles};
  text-decoration: none;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
`;

export const MagicLink = styled.span`
  ${(props) => props.styles};
  text-decoration: none;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
`;

/**
 * @description Checks if url is external
 * @param {string} url
 * @return {boolean}
 */
const isExternalLink = (url) =>
  url.includes(EXTERNAL_LINK_IDENTIFIER_SECURED) ||
  url.includes(EXTERNAL_LINK_IDENTIFIER);

/**
 * @description Handle click for cloaked link
 * @param {boolean} openInNewTab Opens link in a new tab
 * @param {boolean} mustReload Reloads page and refresh data
 * @param {object} event Event propagated on click
 * @returns {void}
 */
const magicLinkHandler = (openInNewTab, mustReload, event, methods) => {
  const attribute = event.currentTarget.getAttribute('data-attr');
  if (openInNewTab) {
    const newWindow = window.open(
      decodeURIComponent(window.atob(attribute)),
      '_blank',
    );
    newWindow.focus();
  } else if (mustReload) {
    window.location.href = decodeURIComponent(window.atob(attribute));
  } else {
    history.push(decodeURIComponent(window.atob(attribute)));
    methods();
  }
};

const AppLink = ({
  url,
  id,
  name,
  styles,
  hidden,
  inFunnelStep,
  activeStyle,
  methods = () => {},
  openInNewTab,
  isCloaked,
  mustReload,
  content,
  nofollow,
}) => {
  if (!isExternalLink(url) && isCloaked) {
    return (
      <MagicLink
        data-attr={window.btoa(encodeURIComponent(url))}
        hidden={hidden && inFunnelStep}
        id={id}
        onClick={(event) =>
          magicLinkHandler(openInNewTab, mustReload, event, methods)
        }
        styles={styles}
      >
        {name || content}
      </MagicLink>
    );
  }

  if (mustReload) {
    return (
      <LinkExtern
        hidden={hidden && inFunnelStep}
        href={url}
        id={id}
        onClick={() => {
          methods();
        }}
        rel={`noopener${nofollow ? ' nofollow' : ''}`}
        styles={styles}
        target={openInNewTab ? '_blank' : null}
      >
        {name || content}
      </LinkExtern>
    );
  }

  return (
    <LinkIntern
      activeStyle={activeStyle}
      hidden={hidden && inFunnelStep}
      id={id}
      onClick={() => {
        methods();
      }}
      styles={styles}
      target={openInNewTab ? '_blank' : null}
      to={url}
    >
      {name || content}
    </LinkIntern>
  );
};

AppLink.propTypes = {
  activeStyle: PropTypes.object,
  content: PropTypes.node,
  hidden: PropTypes.bool,
  id: PropTypes.string,
  inFunnelStep: PropTypes.bool,
  isCloaked: PropTypes.bool,
  methods: PropTypes.func,
  mustReload: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  nofollow: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  styles: PropTypes.array,
  url: PropTypes.string,
};

export default AppLink;
export { isExternalLink, magicLinkHandler };
