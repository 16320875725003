import { defineMessages } from 'react-intl';
import {
  INPUT_CUSTOMERID_PLACEHOLDER,
  INPUT_FIRSTNAME_PLACEHOLDER,
  INPUT_LASTNAME_PLACEHOLDER,
  INPUT_PRICE_PLACEHOLDER,
  INPUT_REF_PLACEHOLDER,
  PAGE_TITLE,
  PAYMENT_FORM_CUSTOMERID,
  PAYMENT_FORM_FIRSTNAME,
  PAYMENT_FORM_GENERATE,
  PAYMENT_FORM_LASTNAME,
  PAYMENT_FORM_PRICE,
  PAYMENT_FORM_REF,
  PAYMENT_FORM_TITLE,
  PAYMENT_LINK_CARD_CONTENT,
  PAYMENT_LINK_CARD_TITLE,
  PAYMENT_LINK_COPY_LINK,
  PAYMENT_LINK_GENERATED_LINK,
  paymentLinkScope,
  RESET_PAYMENT_LINK_FORM,
  SELECT_TITLE_MR,
  SELECT_TITLE_MS,
} from '../constants';

export const stationMessages = {
  [PAGE_TITLE]: {
    id: `${paymentLinkScope}.${PAGE_TITLE}`,
    defaultMessage: 'Lien de paiement',
    en: 'Payment link',
    es: 'Enlace de pago',
    it: 'Link di pagamento',
    de: 'Zahlungslink',
    nl: 'Betalingslink',
  },
  [PAYMENT_LINK_CARD_TITLE]: {
    id: `${paymentLinkScope}.${PAYMENT_LINK_CARD_TITLE}`,
    defaultMessage: 'Confirmation',
    en: 'Confirmation',
    es: 'Confirmación',
    it: 'Conferma',
    de: 'Bestätigung',
    nl: 'Bevestiging',
  },
  [PAYMENT_LINK_CARD_CONTENT]: {
    id: `${paymentLinkScope}.${PAYMENT_LINK_CARD_CONTENT}`,
    defaultMessage: 'Votre choix a bien été pris en compte.',
    en: 'Your choice has been saved.',
    es: 'Su elección ha sido guardada.',
    it: 'La tua scelta è stata salvata.',
    de: 'Ihre Auswahl wurde gespeichert.',
    nl: 'Uw keuze is opgeslagen.',
  },
  [PAYMENT_FORM_REF]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_REF}`,
    defaultMessage: 'Référence',
    en: 'Reference',
    es: 'Referencia',
    it: 'Riferimento',
    de: 'Referenz',
    nl: 'Referentie',
  },
  [INPUT_REF_PLACEHOLDER]: {
    id: `${paymentLinkScope}.${INPUT_REF_PLACEHOLDER}`,
    defaultMessage: 'Saisissez une réference (ex: ACC-568790)',
    en: 'Enter a reference (ex: ACC-568790)',
    es: 'Ingrese una referencia (por ejemplo: ACC-568790)',
    it: 'Inserisci un riferimento (es: ACC-568790)',
    de: 'Geben Sie eine Referenz ein (z.B.: ACC-568790)',
    nl: 'Voer een referentie in (bijv.: ACC-568790)',
  },
  [PAYMENT_FORM_PRICE]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_PRICE}`,
    defaultMessage: 'Prix (euro)',
    en: 'Price (euro)',
    es: 'Precio (euro)',
    it: 'Prezzo (euro)',
    de: 'Preis (Euro)',
    nl: 'Prijs (euro)',
  },
  [INPUT_PRICE_PLACEHOLDER]: {
    id: `${paymentLinkScope}.${INPUT_PRICE_PLACEHOLDER}`,
    defaultMessage: 'Saisissez un prix (ex: 15)',
    en: 'Enter a price (ex: 15)',
    es: 'Ingrese un precio (por ejemplo: 15)',
    it: 'Inserisci un prezzo (es: 15)',
    de: 'Geben Sie einen Preis ein (z.B.: 15)',
    nl: 'Voer een prijs in (bijv.: 15)',
  },
  [PAYMENT_FORM_TITLE]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_TITLE}`,
    defaultMessage: 'Civilité',
    en: 'Title',
    es: 'Título',
    it: 'Titolo',
    de: 'Anrede',
    nl: 'Aanhef',
  },
  [PAYMENT_FORM_FIRSTNAME]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_FIRSTNAME}`,
    defaultMessage: 'Prénom',
    en: 'First name',
    es: 'Nombre',
    it: 'Nome',
    de: 'Vorname',
    nl: 'Voornaam',
  },
  [INPUT_FIRSTNAME_PLACEHOLDER]: {
    id: `${paymentLinkScope}.${INPUT_FIRSTNAME_PLACEHOLDER}`,
    defaultMessage: 'Saisissez un prénom',
    en: 'Enter a first name',
    es: 'Ingrese un nombre',
    it: 'Inserisci un nome',
    de: 'Geben Sie einen Vornamen ein',
    nl: 'Voer een voornaam in',
  },
  [PAYMENT_FORM_LASTNAME]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_LASTNAME}`,
    defaultMessage: 'Nom',
    en: 'Last name',
    es: 'Apellido',
    it: 'Cognome',
    de: 'Nachname',
    nl: 'Achternaam',
  },
  [INPUT_LASTNAME_PLACEHOLDER]: {
    id: `${paymentLinkScope}.${INPUT_LASTNAME_PLACEHOLDER}`,
    defaultMessage: 'Saisissez un nom',
    en: 'Enter a last name',
    es: 'Ingrese un apellido',
    it: 'Inserisci un cognome',
    de: 'Geben Sie einen Nachnamen ein',
    nl: 'Voer een achternaam in',
  },
  [PAYMENT_FORM_CUSTOMERID]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_CUSTOMERID}`,
    defaultMessage: 'Email',
    en: 'Email',
    es: 'Correo electrónico',
    it: 'Email',
    de: 'E-Mail',
    nl: 'E-mail',
  },
  [INPUT_CUSTOMERID_PLACEHOLDER]: {
    id: `${paymentLinkScope}.${INPUT_CUSTOMERID_PLACEHOLDER}`,
    defaultMessage: "Saisissez l'email du client",
    en: 'Enter the customer email',
    es: 'Ingrese el correo electrónico del cliente',
    it: "Inserisci l'email del cliente",
    de: 'Geben Sie die E-Mail-Adresse des Kunden ein',
    nl: 'Voer het e-mailadres van de klant in',
  },
  [PAYMENT_FORM_GENERATE]: {
    id: `${paymentLinkScope}.${PAYMENT_FORM_GENERATE}`,
    defaultMessage: 'Générer un lien',
    en: 'Generate a link',
    es: 'Generar un enlace',
    it: 'Genera un link',
    de: 'Link generieren',
    nl: 'Genereer een link',
  },
  [SELECT_TITLE_MR]: {
    id: `${paymentLinkScope}.${SELECT_TITLE_MR}`,
    defaultMessage: 'M.',
    en: 'Mr.',
    es: 'Sr.',
    it: 'Sig.',
    de: 'Herr',
    nl: 'Dhr.',
  },
  [SELECT_TITLE_MS]: {
    id: `${paymentLinkScope}.${SELECT_TITLE_MS}`,
    defaultMessage: 'Mme.',
    en: 'Ms.',
    es: 'Sra.',
    it: 'Sig.ra',
    de: 'Frau',
    nl: 'Mevr.',
  },
  [PAYMENT_LINK_COPY_LINK]: {
    id: `${paymentLinkScope}.${PAYMENT_LINK_COPY_LINK}`,
    defaultMessage: 'Copier',
    en: 'Copy',
    es: 'Copiar',
    it: 'Copia',
    de: 'Kopieren',
    nl: 'Kopiëren',
  },
  [PAYMENT_LINK_GENERATED_LINK]: {
    id: `${paymentLinkScope}.${PAYMENT_LINK_GENERATED_LINK}`,
    defaultMessage: 'Votre lien généré :',
    en: 'Your generated link:',
    es: 'Su enlace generado:',
    it: 'Il tuo link generato:',
    de: 'Ihr generierter Link:',
    nl: 'Uw gegenereerde link:',
  },
  [RESET_PAYMENT_LINK_FORM]: {
    id: `${paymentLinkScope}.${RESET_PAYMENT_LINK_FORM}`,
    defaultMessage: 'Réinitialiser le formulaire',
    en: 'Reset the form',
    es: 'Restablecer el formulario',
    it: 'Resetta il modulo',
    de: 'Formular zurücksetzen',
    nl: 'Herstel het formulier',
  },
};

export default defineMessages(stationMessages);
