import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

const h3Style = css`
  ${tw`leading-h3-m sm:leading-h3 text-h3-m
  	sm:text-h3`};
  font-weight: 600;
`;

const H3 = styled.h3`
  ${h3Style};
`;

export { H3 as default, h3Style };
