import { homepageScope } from '../constants';

export const homePageFAQMessages = {
  faqHeader1: {
    id: `${homepageScope}.faqHeader1`,
    defaultMessage: 'Comment fonctionne Tictactrip ?',
    en: 'How does Tictactrip work?',
    it: 'Come funziona Tictactrip?',
    es: '¿Cómo funciona Tictactrip?',
    de: 'Wie funktioniert Tictactrip?',
    nl: 'Hoe werkt Tictactrip?',
  },
  faq1: {
    id: `${homepageScope}.faq1`,
    defaultMessage:
      'Tictactrip a été créé avec l’intime conviction qu’il est possible de faire du transport bas-carbone le 1er choix dans bien des cas. Pour se faire nous comparons de nombreuses compagnies de transports en train, en bus et en covoiturage afin de vous proposer les meilleurs résultats et itinéraires. Ainsi vous pouvez comparer, combiner et réserver vos trajets partout en France et en Europe.',
    en: 'Tictactrip was created with the intimate conviction that it is possible to make low-carbon transport the 1st choice in many cases. To do this, we compare many transportation companies by train, bus, and carpool to offer you the best results and itineraries. So you can compare, combine, and book your trips anywhere in France and Europe.',
    it: 'Tictactrip è stato creato con la convinzione intima che sia possibile fare del trasporto a basse emissioni di carbonio la prima scelta in molti casi. Per farlo, confrontiamo molte compagnie di trasporto in treno, autobus e carpooling per offrirti i migliori risultati e itinerari. In questo modo puoi confrontare, combinare e prenotare i tuoi viaggi ovunque in Francia e in Europa.',
    es: 'Tictactrip fue creado con la convicción íntima de que es posible hacer del transporte con bajas emisiones de carbono la primera opción en muchos casos. Para ello, comparamos muchas empresas de transporte en tren, autobús y coche compartido para ofrecerle los mejores resultados e itinerarios. Así que puede comparar, combinar y reservar sus viajes en cualquier lugar de Francia y Europa.',
    de: 'Tictactrip wurde mit der Überzeugung gegründet, dass es in vielen Fällen möglich ist, den klimafreundlichen Transport zur ersten Wahl zu machen. Wir vergleichen dazu viele Transportunternehmen mit Zug, Bus und Mitfahrgelegenheit, um Ihnen die besten Ergebnisse und Routen anzubieten. So können Sie Ihre Reisen überall in Frankreich und Europa vergleichen, kombinieren und buchen.',
    nl: 'Tictactrip is opgericht met de overtuiging dat het mogelijk is om in veel gevallen voor klimaatvriendelijk transport te kiezen. We vergelijken daarom veel transportbedrijven per trein, bus en carpool om u de beste resultaten en routes aan te bieden. Zo kunt u uw reizen overal in Frankrijk en Europa vergelijken, combineren en boeken.',
  },

  faqHeader2: {
    id: `${homepageScope}.faqHeader2`,
    defaultMessage: 'Tictactrip c’est un comparateur de transports sérieux ?',
    en: 'Is Tictactrip a reliable transport comparator?',
    it: 'Tictactrip è un comparatore di trasporti affidabile?',
    es: '¿Es Tictactrip un comparador de transporte confiable?',
    de: 'Ist Tictactrip ein zuverlässiger Transportvergleich?',
    nl: 'Is Tictactrip een betrouwbare transportvergelijker?',
  },
  faq2: {
    id: `${homepageScope}.faq2`,
    defaultMessage:
      'D’abord d’un point de vue légal, nous sommes une agence de voyage agréée. Concrètement, on est aux normes jusqu’au bout des doigts ! Ensuite, nous mettons notre énergie depuis maintenant plus de 5 ans à créer la meilleure plateforme pour comparer, combiner et réserver des trajets en train, bus et covoit. Ce n’est pas un gage juridique de sérieux mais notre engagement, lui, est bel et bien sérieux.',
    en: 'First, from a legal point of view, we are an authorized travel agency. In concrete terms, we comply with all standards! Then, for over 5 years, we have been putting our energy into creating the best platform to compare, combine, and book train, bus, and carpool journeys. This is not a legal guarantee of seriousness, but our commitment is indeed serious.',
    es: 'En primer lugar, desde un punto de vista legal, somos una agencia de viajes autorizada. En términos concretos, ¡cumplimos con todos los estándares! Luego, durante más de 5 años, hemos estado poniendo nuestra energía en crear la mejor plataforma para comparar, combinar y reservar viajes en tren, autobús y coche compartido. Esto no es una garantía legal de seriedad, pero nuestro compromiso es ciertamente serio.',
    it: "Innanzitutto, da un punto di vista legale, siamo un'agenzia di viaggi autorizzata. In termini concreti, rispettiamo tutti gli standard! Poi, da oltre 5 anni, stiamo mettendo la nostra energia nella creazione della migliore piattaforma per confrontare, combinare e prenotare viaggi in treno, autobus e carpooling. Questo non è una garanzia legale di serietà, ma il nostro impegno è sicuramente serio.",
    de: 'Zunächst einmal sind wir aus rechtlicher Sicht eine autorisierte Reiseagentur. Konkret entsprechen wir allen Standards! Seit über 5 Jahren setzen wir uns dafür ein, die beste Plattform zum Vergleich, zur Kombination und zur Buchung von Zug-, Bus- und Fahrgemeinschaftsreisen zu schaffen. Dies ist zwar keine rechtliche Garantie für Seriosität, aber unser Engagement ist in der Tat seriös.',
    nl: 'Eerst en vooral zijn we vanuit een juridisch oogpunt een erkend reisbureau. Concreet voldoen we aan alle normen! Daarnaast hebben we al meer dan 5 jaar lang al onze energie gestoken in het creëren van het beste platform om trein-, bus- en carpoolreizen te vergelijken, combineren en boeken. Dit is geen juridische garantie voor serieusheid, maar onze betrokkenheid is wel degelijk serieus.',
  },

  faqHeader3: {
    id: `${homepageScope}.faqHeader3`,
    defaultMessage:
      'Qu’est-ce que le transport multimodal et l’intermodalité ?',
    en: 'What is multimodal transport and intermodality?',
    es: '¿Qué es el transporte multimodal y la intermodalidad?',
    fr: 'Qu’est-ce que le transport multimodal et l’intermodalité ?',
    de: 'Was ist multimodaler Transport und Intermodalität?',
    it: 'Cosa si intende per trasporto multimodale e intermodalità?',
    nl: 'Wat is multimodaal transport en intermodaliteit?',
  },
  faq3: {
    id: `${homepageScope}.faq3`,
    defaultMessage:
      'En créant Tictactrip nous avons fait un pari : participer à améliorer la desserte de l’ensemble des territoires, même les plus isolés. Pour se faire, nous avons pris la décision de travailler sur des trajets directs mais aussi sur des trajets bien plus complexes. Ces trajets plus complexes regroupent souvent plusieurs compagnies d’un même type de transport (deux trajets en train par exemple) ou plusieurs types de transports. Un exemple concret : se rendre à la montagne demande souvent de prendre le train et une navette en bus.',
    en: 'By creating Tictactrip, we made a bet: to participate in improving the transport service of all territories, even the most isolated. To do this, we decided to work on direct trips but also on much more complex trips. These more complex trips often involve several companies of the same type of transport (for example, two train journeys) or several types of transport. A concrete example: going to the mountains often requires taking the train and a shuttle bus.',
    it: 'Creando Tictactrip abbiamo fatto una scommessa: partecipare al miglioramento della copertura di tutti i territori, anche i più isolati. Per fare ciò, abbiamo deciso di lavorare su viaggi diretti ma anche su viaggi molto più complessi. Questi viaggi più complessi spesso comprendono diverse compagnie dello stesso tipo di trasporto (ad esempio, due viaggi in treno) o diversi tipi di trasporto. Un esempio concreto: andare in montagna spesso richiede di prendere il treno e una navetta bus.',
    es: 'Al crear Tictactrip hicimos una apuesta: participar en la mejora de la cobertura de transporte de todos los territorios, incluso los más aislados. Para hacer esto, decidimos trabajar en viajes directos pero también en viajes mucho más complejos. Estos viajes más complejos a menudo implican varias empresas del mismo tipo de transporte (por ejemplo, dos viajes en tren) o varios tipos de transporte. Un ejemplo concreto: ir a la montaña a menudo requiere tomar el tren y un autobús lanzadera.',
    de: 'Bei der Gründung von Tictactrip haben wir eine Wette abgeschlossen: zur Verbesserung des Transportdienstes aller Gebiete, auch der abgelegensten, beizutragen. Dazu haben wir beschlossen, an Direktreisen, aber auch an viel komplexeren Reisen zu arbeiten. Diese komplexeren Reisen beinhalten oft mehrere Unternehmen desselben Transporttyps (z.B. zwei Zugreisen) oder mehrere Transporttypen. Ein konkretes Beispiel: Eine Reise in die Berge erfordert oft die Fahrt mit dem Zug und einem Shuttlebus.',
    nl: 'Met de oprichting van Tictactrip hebben wij een weddenschap aangegaan: meewerken aan de verbetering van de vervoersdienst in alle gebieden, zelfs de meest geïsoleerde. Daartoe hebben wij besloten om ons niet alleen bezig te houden met rechtstreekse reizen, maar ook met veel complexere reizen. Deze complexere reizen omvatten vaak verschillende ondernemingen van hetzelfde type vervoer (bijvoorbeeld twee treinreizen) of verschillende soorten vervoer. Een concreet voorbeeld: om naar de bergen te gaan moeten vaak de trein en een pendelbus worden genomen.',
  },

  faqHeader4: {
    id: `${homepageScope}.faqHeader4`,
    defaultMessage: 'Qui sont nos partenaires de transports ?',
    en: 'Who are our transport partners?',
    it: 'Chi sono i nostri partner di trasporto?',
    es: '¿Quiénes son nuestros socios de transporte?',
    de: 'Wer sind unsere Verkehrspartner?',
    nl: 'Wie zijn onze transportpartners?',
  },
  faq4: {
    id: `${homepageScope}.faq4`,
    defaultMessage:
      'Nous travaillons aujourd’hui avec plus d’une centaine de partenaires. Des plus connus ou plus insolites, des plus utilisés au moins fréquentés. De la SNCF aux compagnies de bus et de navettes, nous avons fait le choix de proposer un maximum de partenaires pour augmenter le nombre de villes desservies et de combinaisons possibles.',
    en: 'We currently work with over a hundred partners, ranging from the most well-known to the most unusual, the most used to the least frequent. From SNCF to bus and shuttle companies, we have chosen to offer a maximum number of partners to increase the number of cities served and possible combinations.',
    it: 'Attualmente collaboriamo con oltre un centinaio di partner, dai più conosciuti ai più insoliti, dai più usati ai meno frequenti. Dalla SNCF alle compagnie di autobus e navette, abbiamo scelto di offrire il massimo numero di partner per aumentare il numero di città servite e le possibili combinazioni.',
    es: 'Actualmente trabajamos con más de cien socios, desde los más conocidos hasta los más inusuales, desde los más utilizados hasta los menos frecuentes. Desde SNCF hasta compañías de autobuses y shuttle, hemos elegido ofrecer el máximo de socios para aumentar el número de ciudades servidas y las posibles combinaciones.',
    de: 'Wir arbeiten derzeit mit über hundert Partnern zusammen, von den bekanntesten bis zu den ungewöhnlichsten, von den meistgenutzten bis zu den seltensten. Von SNCF bis zu Bus- und Shuttle-Unternehmen haben wir uns dafür entschieden, eine maximale Anzahl von Partnern anzubieten, um die Anzahl der bedienten Städte und möglichen Kombinationen zu erhöhen.',
    nl: 'We werken momenteel samen met meer dan honderd partners, van de meest bekende tot de meest ongebruikelijke, van de meest gebruikte tot de minst frequente. Van SNCF tot bus- en shuttlebedrijven hebben we ervoor gekozen om een ​​maximaal aantal partners aan te bieden om het aantal bediende steden en mogelijke combinaties te vergroten.',
  },

  faqHeader5: {
    id: `${homepageScope}.faqHeader5`,
    defaultMessage: 'Qui s’occupe du remboursement en cas de problème ?',
    en: 'Who is responsible for refunds in case of problems?',
    it: 'Chi è responsabile dei rimborsi in caso di problemi?',
    es: '¿Quién es responsable de los reembolsos en caso de problemas?',
    de: 'Wer ist bei Problemen für Rückerstattungen verantwortlich?',
    nl: 'Wie is verantwoordelijk voor terugbetalingen in geval van problemen?',
  },
  faq5: {
    id: `${homepageScope}.faq5`,
    defaultMessage:
      'On s’occupe de tout. Si vous avez un problème, vous pourrez sans aucun mal le résoudre seul sur notre service, si ce n’est pas le cas vous pouvez nous écrire. Ensemble, rapidement, nous trouvons la solution à votre problème afin de s’assurer que votre voyage se déroule au mieux.',
    en: "We take care of everything. If you have a problem, you can easily solve it on our service by yourself, but if you can't, you can write us. Together, we quickly find the solution to your problem to ensure that your journey goes as smoothly as possible.",
    it: 'Ci occupiamo di tutto. Se hai un problema, puoi risolverlo facilmente sul nostro servizio da solo, ma se non puoi farlo, puoi scriverci. Insieme, troviamo rapidamente la soluzione al tuo problema per assicurarti che il tuo viaggio si svolga nel migliore dei modi.',
    es: 'Nos encargamos de todo. Si tienes un problema, puedes resolverlo fácilmente en nuestro servicio por tu cuenta, pero si no puedes, puedes escribirnos. Juntos, encontramos rápidamente la solución a tu problema para asegurarnos de que tu viaje vaya lo mejor posible.',
    de: 'Wir kümmern uns um alles. Wenn Sie ein Problem haben, können Sie es einfach selbst auf unserem Service lösen, aber wenn nicht, können Sie uns schreiben. Zusammen finden wir schnell die Lösung für Ihr Problem, um sicherzustellen, dass Ihre Reise reibungslos verläuft.',
    nl: 'We regelen alles. Als je een probleem hebt, kun je het gemakkelijk zelf oplossen via onze service, maar als dat niet lukt, kun je ons schrijven. Samen vinden we snel de oplossing voor je probleem om ervoor te zorgen dat je reis zo soepel mogelijk verloopt.',
  },

  faqHeader6: {
    id: `${homepageScope}.faqHeader6`,
    defaultMessage: 'Puis-je utiliser ma carte de réduction sur Tictactrip ?',
    en: 'Can I use my discount card on Tictactrip?',
    it: 'Posso usare la mia carta di sconto su Tictactrip?',
    es: '¿Puedo usar mi tarjeta de descuento en Tictactrip?',
    de: 'Kann ich meine Rabattkarte auf Tictactrip verwenden?',
    nl: 'Kan ik mijn kortingskaart gebruiken op Tictactrip?',
  },
  faq6: {
    id: `${homepageScope}.faq6`,
    defaultMessage:
      'Complètement ! Vous pouvez même les acheter chez nous (et ça, c’est chouette). Nous nous efforçons de mettre à jour notre solution pour prendre en compte toutes les cartes avantages de nos partenaires. ',
    en: "Absolutely! You can even buy them from us (and that's cool). We strive to update our solution to take into account all the advantage cards of our partners.",
    it: 'Assolutamente! Puoi persino acquistarle da noi (e questo è fantastico). Ci sforziamo di aggiornare la nostra soluzione per tenere conto di tutte le carte vantaggio dei nostri partner.',
    es: '¡Completamente! Incluso puedes comprarlas con nosotros (y eso es genial). Nos esforzamos por actualizar nuestra solución para tener en cuenta todas las tarjetas de ventaja de nuestros socios.',
    de: 'Absolut! Du kannst sie sogar bei uns kaufen (und das ist toll). Wir bemühen uns, unsere Lösung zu aktualisieren, um alle Vorteilskarten unserer Partner zu berücksichtigen.',
    nl: 'Absoluut! Je kunt ze zelfs bij ons kopen (en dat is leuk). We streven ernaar onze oplossing bij te werken om rekening te houden met alle voordeelkaarten van onze partners.',
  },
};
