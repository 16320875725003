import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyFaq = lazy(() =>
  import(/* webpackChunkName: 'Faq' */ './index'),
);

export default function Faq(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyFaq {...props} />
    </Suspense>
  );
}
