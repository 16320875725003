import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const TiktokIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    version="1.1"
    viewBox="0 0 256 256"
    width="20pt"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M99.3,256c-18.4,0-35.7-5.9-50.1-17c-2.7-2.1-5.3-4.3-7.7-6.7c-16.8-16.6-25.3-38.8-23.8-62.3
	c1.1-17.8,8.3-35,20.2-48.3c15.8-17.7,37.6-27.5,61.5-27.5c4.1,0,8.2,0.3,12.3,0.9c2.2,0.3,3.8,2.2,3.8,4.4v41
	c0,1.4-0.7,2.8-1.8,3.6c-1.2,0.8-2.6,1.1-4,0.6c-3.3-1.1-6.7-1.6-10.1-1.6c-8.7,0-16.9,3.4-23,9.6c-6.1,6.2-9.3,14.3-9.2,23
	c0.2,11.2,6,21.3,15.7,27c4.4,2.7,9.5,4.2,14.7,4.5c4.1,0.2,8.1-0.3,11.9-1.6c13.2-4.4,22.1-16.6,22.1-30.4l0-170.7
	c0-2.4,2-4.4,4.4-4.4h40.4c2.4,0,4.4,2,4.4,4.4c0,3.7,0.4,7.3,1.1,10.9c2.8,13.9,10.6,26.3,22,35c10.1,7.6,22.1,11.6,34.8,11.7
	c0.4,0,0.9,0,1.3,0.1c2,0.5,3.4,2.3,3.4,4.3v40.2c0,2.4-2,4.4-4.4,4.4c0,0,0,0,0,0c-14.4,0-28.4-2.8-41.6-8.4
	c-5.8-2.4-11.3-5.3-16.5-8.7l0.2,81.6c-0.1,21.5-8.6,41.7-24,56.9c-12.5,12.3-28.2,20.2-45.6,22.8C107.5,255.7,103.4,256,99.3,256z
	 M239,106.5L239,106.5L239,106.5z"
      fill={color}
    />
  </SVG>
);
