import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';
import ArrowBlack from '../../images/arrowRightBlack.svg';
import ArrowWhite from '../../images/arrowRightWhite.svg';
import { colors } from '../../styles/constants';
import AppLink from '../AppLink';
import { h2style } from '../H2';
import H3 from '../H3';
import { h6Style } from '../H6';
import { Icon36 } from '../Icon';
import messages from './messages';

const baseDestinationCardStyle = css`
  ${tw`relative flex flex-no-shrink
    mt-abs4 mr-auto ml-auto
    no-underline
    cursor-pointer`};
  width: 280px;
  height: 378px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 0px rgba(231, 232, 240, 1);
  background-color: ${(props) =>
    props.isYellow ? colors.brandYellow : colors.brandRed};
  color: ${(props) => (props.isYellow ? colors.primary400 : 'white')};

  transition: box-shadow 250ms ease;
  &:hover {
    box-shadow: 0px 8px 24px 0px rgba(231, 232, 240, 1);
  }
  @media (min-width: 768px) {
    width: 320px;
    height: 457px;
  }
`;

const redDestinationCardStyle = css`
  ${baseDestinationCardStyle};
  background-color: ${colors.brandRed};
  color: white;
`;

const yellowDestinationCardStyle = css`
  ${baseDestinationCardStyle};
  background-color: ${colors.brandYellow};
  color: ${colors.primary400};
`;

const CustomHandler = styled.span`
  ${baseDestinationCardStyle};
`;

const Image = styled.div`
  ${tw`absolute`};
  width: 260px;
  height: 300px;
  top: -40px;
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;

  @media (min-width: 768px) {
    width: 280px;
    height: 350px;
  }
`;

const Infos = styled.div`
  ${tw`flex flex-col justify-end
    py-abs3 pl-def
    md:pb-abs5`};
  padding-right: 2.5rem;
`;

const City = styled(H3)`
  ${tw`m-0 mb-abs1`};
`;

const Price = styled.div`
  ${tw`flex items-start`};
`;

const From = styled.span`
  ${h6Style}
  margin: 7px 10px 0 2px;
`;

const PriceValue = styled.span`
  ${h2style}
  ${tw`pl-abs1`};
  margin: unset !important;
  font-size: 3.8rem;
`;

const Arrow = styled.img`
  ${Icon36}
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const isYellow = (country) => country === 'France';

const DestinationCard = ({ customClickHandler, destination, isCloaked }) => {
  if (customClickHandler) {
    return (
      <CustomHandler
        isYellow={isYellow(destination.country)}
        onClick={() => customClickHandler()}
      >
        <Content destination={destination} />
      </CustomHandler>
    );
  }

  return (
    <AppLink
      content={<Content destination={destination} />}
      id={`destinationCard${destination.id}`}
      isCloaked={isCloaked}
      methods={customClickHandler}
      mustReload
      styles={
        isYellow(destination.country)
          ? yellowDestinationCardStyle
          : redDestinationCardStyle
      }
      url={destination.url}
    />
  );
};

const Content = ({ destination }) => (
  <Fragment>
    <Image src={destination.image} />
    <Infos>
      <City>{destination.title}</City>
      <Price>
        <From>
          <FormattedMessage {...messages.priceFrom} />
        </From>
        <PriceValue>{destination.price}€</PriceValue>
      </Price>
    </Infos>
    <Arrow
      alt="Arrow select destination"
      src={isYellow(destination.country) ? ArrowBlack : ArrowWhite}
    />
  </Fragment>
);

const destinationPropTypes = PropTypes.shape({
  country: PropTypes.string,
  external: PropTypes.bool,
  id: PropTypes.number,
  image: PropTypes.string,
  price: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
});

DestinationCard.propTypes = {
  customClickHandler: PropTypes.func,
  destination: destinationPropTypes,
  isCloaked: PropTypes.bool,
};

Content.propTypes = {
  destination: destinationPropTypes,
};

export { DestinationCard as default, destinationPropTypes };
