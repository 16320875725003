import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyPayment = lazy(() =>
  import(/* webpackChunkName: 'Payment' */ './index'),
);

export default function Payment(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyPayment {...props} />
    </Suspense>
  );
}
