import React, { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyResults = lazy(
  () => import(/* webpackChunkName: 'Results' */ '.'),
);

export default function Results(): JSX.Element {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyResults />
    </Suspense>
  );
}
