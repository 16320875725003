import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { margin, width } from '../../styles/constants';

const Cell = styled.div`
  ${tw`relative px-def`};
  width: ${(props) => width[props.width]};
  margin-top: 0;
  margin-right: ${(props) => (props.append ? margin[props.append] : 0)};
  margin-bottom: 0;
  margin-left: ${(props) => (props.prepend ? margin[props.prepend] : 0)};
`;

export default Cell;
