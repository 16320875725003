import { ComponentPropsWithRef } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import { BUTTON_VARIANT_STYLE } from '../Forms/Button';
import { P0 } from '../Typographies/Paragraph';

const StyledLink = styled(P0)<LinkProps>`
  ${BUTTON_VARIANT_STYLE.LINK}
  &:visited {
    ${BUTTON_VARIANT_STYLE.LINK}
  }

  ${({ isObfuscated }) => isObfuscated && tw`cursor-pointer`}

  ${({ customStyle }) => customStyle}
`;

type LinkProps = {
  customStyle?: FlattenSimpleInterpolation;
  isObfuscated?: boolean;
} & ComponentPropsWithRef<'a'>;

export const Link = (props: LinkProps) => {
  if (props.isObfuscated) {
    return (
      <StyledLink
        {...props}
        as="span"
        href={undefined}
        onClick={() => {
          if (props.href) {
            window.open(props.href, props.target || '_self');
          }
        }}
        target={undefined}
      >
        {props.children}
      </StyledLink>
    );
  }

  return <StyledLink as={'a'} {...props} />;
};
