import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  API_URL,
  BASIC_REQUEST_HEADERS,
  REFRESH_TOKEN,
} from '../../helpers/constants';
import { getDeviceId } from '../../helpers/devices';
import request from '../../utils/request';
import {
  AuthenticationResponse,
  LoginWithOrderIdPayload,
  SendMagicLinkPayload,
  VerifyMagicLinkPayload,
} from './types';

export class AuthService {
  static getAdminToken(): string | null {
    try {
      return window.localStorage.getItem(ADMIN_TOKEN);
    } catch (error) {
      return null;
    }
  }

  static getUserToken(): string | null {
    try {
      return window.localStorage.getItem(ACCESS_TOKEN);
    } catch (error) {
      return null;
    }
  }

  static setUserAuthentication(authentication: AuthenticationResponse) {
    localStorage.setItem(ACCESS_TOKEN, authentication.token);
    localStorage.setItem(REFRESH_TOKEN, authentication.refreshToken);
  }

  static removeUserAuthentication() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }
}

export const loginWithOrderId = async (
  payload: Pick<LoginWithOrderIdPayload, 'email' | 'orderId'>,
): Promise<AuthenticationResponse> => {
  const deviceId = getDeviceId();

  return request(`${API_URL}/auth/orderId`, {
    method: 'POST',
    headers: BASIC_REQUEST_HEADERS,
    body: JSON.stringify({ ...payload, deviceId }),
  });
};

export const sendMagicLink = async (
  payload: SendMagicLinkPayload,
): Promise<AuthenticationResponse> => {
  return request(`${API_URL}/auth/magicLink`, {
    method: 'POST',
    headers: BASIC_REQUEST_HEADERS,
    body: JSON.stringify(payload),
  });
};

export const verifyMagicLink = async (
  payload: VerifyMagicLinkPayload,
): Promise<AuthenticationResponse> => {
  const deviceId = getDeviceId();

  return request(`${API_URL}/auth/magicLink/verify`, {
    method: 'POST',
    headers: BASIC_REQUEST_HEADERS,
    body: JSON.stringify({ ...payload, deviceId }),
  });
};

export const logout = async () => {
  await request(`${API_URL}/auth/logout`, {
    method: 'POST',
    headers: {
      ...BASIC_REQUEST_HEADERS,
      authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  });
};
