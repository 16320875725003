import { defineMessages } from 'react-intl';

export const dropzoneScope = 'app.components.dropzone';

export const dropzoneMessages = {
  clickToUpload: {
    id: `${dropzoneScope}.clickToUpload`,
    defaultMessage: '<u>Cliquer pour télécharger</u> ou glisser-déposer',
    en: '<u>Click to upload</u> or drag and drop',
    es: '<u>Haga clic para cargar</u> o arrastre y suelte',
    it: '<u>Fai clic per caricare</u> o trascina e rilascia',
    de: '<u>Klicken Sie zum Hochladen</u> oder ziehen und ablegen',
    nl: '<u>Klik om te uploaden</u> of slepen en neerzetten',
  },
  maxFileSize: {
    id: `${dropzoneScope}.maxFileSize`,
    defaultMessage: 'Taille maximale du fichier {maxFileSize}.',
    en: 'Maximum file size {maxFileSize}.',
    es: 'Tamaño máximo de archivo {maxFileSize}.',
    it: 'Dimensione massima del file {maxFileSize}.',
    de: 'Maximale Dateigröße {maxFileSize}.',
    nl: 'Maximale bestandsgrootte {maxFileSize}.',
  },
};

export default defineMessages(dropzoneMessages);
