import { EPassengerCategory } from '../../common/types';

export const PASSENGERS_DATE_FORMAT = 'DD/MM/YYYY';

export const PASSENGER_CATEGORY_RANGES: Record<
  EPassengerCategory,
  { min: number; max: number }
> = {
  [EPassengerCategory.YOUNG]: { min: 0, max: 27 },
  [EPassengerCategory.ADULT]: { min: 28, max: 59 },
  [EPassengerCategory.SENIOR]: { min: 60, max: Infinity },
};
