import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { Children, PureComponent } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { changeLocale } from '../../containers/LanguageProvider/actions';
import { isCrawler } from '../../helpers';
import { appLocales, DEFAULT_LOCALE } from '../../i18n';
import { dayjs } from '../../libs/day';
import { momentLocaleMapping } from './constants';
import { makeSelectLocale } from './selectors';

/**
 *
 * LanguageProvider
 *
 * @description this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
export class LanguageProvider extends PureComponent {
  /**
   * @description props for this component are related to locale and language
   * @param {object} props
   * @prop {string} locale
   * @prop {element} children
   * @prop {function} selectLocale
   * @prop {object} messages
   */
  constructor(props) {
    super(props);
    if (isCrawler(window.navigator?.userAgent)) {
      this.props.selectLocale(DEFAULT_LOCALE);
    } else {
      const browserLocale = window.navigator?.language?.slice(0, 2);
      if (appLocales.includes(browserLocale))
        this.props.selectLocale(browserLocale);
    }
  }

  render() {
    moment.locale(momentLocaleMapping[this.props.locale]);
    dayjs.locale(momentLocaleMapping[this.props.locale]);

    return (
      <IntlProvider
        key={this.props.locale}
        defaultLocale={DEFAULT_LOCALE}
        locale={this.props.locale}
        messages={this.props.messages[this.props.locale]}
      >
        {Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string,
  messages: PropTypes.object,
  selectLocale: PropTypes.func,
};

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

export function mapDispatchToProps(dispatch) {
  return {
    selectLocale: (locale) => dispatch(changeLocale(locale)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
