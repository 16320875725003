import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const Circle = styled.div`
  ${tw`absolute rounded-full`};
  height: 27px;
  width: 27px;
  border: 7px solid white;
`;

export default Circle;
