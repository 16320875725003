import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

const h1style = css`
  ${tw`leading-h1-m sm:leading-h1 text-h1-m sm:text-h1 px-def my-def`};
  font-weight: 700;
`;

const H1 = styled.h1`
  ${h1style};
`;

export { H1 as default, h1style };
