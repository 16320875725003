import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import ademeLogo from '../../../images/medias/logoAdeme.svg';
import atoutFranceLogo from '../../../images/medias/logoAtoutFrance.png';
import timeForThePlanetLogo from '../../../images/medias/logoTimeForThePlanet.png';
import { Container, Wrapper } from './common';
import { PaymentLogos } from './End';

const StyledWrapper = styled(Wrapper)`
  ${tw`flex items-center flex-wrap mt-medium`}
  gap:var(--size-huge)
`;

const Image = styled.img`
  ${tw``}
  width: 64px;
  height: fit-content;
`;

type LabelsProps = {
  customStyle?: FlattenSimpleInterpolation;
  isReduced: boolean;
};

const Labels = (props: LabelsProps) => {
  return (
    <Container customStyle={props.customStyle}>
      <StyledWrapper>
        {props.isReduced === false ? (
          <React.Fragment>
            <Image alt="Atout France logo" src={atoutFranceLogo} />
            <Image alt="Time For The Planet logo" src={timeForThePlanetLogo} />
            <Image alt="Ademe logo" src={ademeLogo} />
          </React.Fragment>
        ) : (
          <PaymentLogos />
        )}
      </StyledWrapper>
    </Container>
  );
};

export default Labels;
