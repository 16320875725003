import PropTypes from 'prop-types';

const generalTablePropTypes = PropTypes.arrayOf(
  PropTypes.exact({
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    iconSrc: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  }),
);

const tableCardPropTypes = PropTypes.arrayOf(
  PropTypes.exact({
    id: PropTypes.string.isRequired,
    header: PropTypes.oneOfType([
      PropTypes.node.isRequired,
      PropTypes.string.isRequired,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node.isRequired,
      PropTypes.number.isRequired,
      PropTypes.object.isRequired,
    ]),
    iconSrc: PropTypes.oneOfType([
      PropTypes.node.isRequired,
      PropTypes.string.isRequired,
    ]),
    iconAlt: PropTypes.string,
    linesClamping: PropTypes.number,
  }),
);

export { generalTablePropTypes, tableCardPropTypes };
