import { homepageScope } from '../constants';

export const homePageConceptMessages = {
  conceptText: {
    id: `${homepageScope}.conceptText`,
    defaultMessage:
      'Comparez des trajets les plus simples aux plus complexes. Grâce à une technologie unique, vous avez accès aux <strong>meilleurs tarifs et itinéraires en transport bas-carbone.</strong><br /><br />Trains, bus, covoiturage, seuls ou combinés vous pouvez (enfin) décoller sans prendre l’avion.',
    en: 'Compare the simplest to the most complex journeys. Thanks to a unique technology, you have access to the <strong>best low-carbon transport fares and itineraries.</strong><br /><br />Trains, buses, carpooling, alone or combined, you can (finally) take off without taking a plane.',
    es: 'Compara los viajes más simples con los más complejos. Gracias a una tecnología única, tienes acceso a las <strong>mejores tarifas e itinerarios de transporte de bajas emisiones de carbono.</strong><br /><br />Trenes, autobuses, coches compartidos, solos o combinados, puedes (finalmente) despegar sin tomar un avión.',
    de: 'Vergelijk de eenvoudigste tot de meest complexe reizen. Dankzij unieke technologie heb je toegang tot de <strong>beste tarieven en routes voor koolstofarm transport.</strong><br /><br />Treinen, bussen, carpoolen, alleen of gecombineerd, je kunt (eindelijk) weg zonder het vliegtuig te nemen.',
    it: 'Confronta i viaggi più semplici con quelli più complessi. Grazie ad una tecnologia unica, hai accesso ai <strong>migliori tariffe e percorsi di trasporto a basse emissioni di carbonio.</strong><br /><br />Treni, autobus, carpooling, da soli o combinati, puoi (finalmente) decollare senza prendere un aereo.',
    nl: 'Vergelijk de eenvoudigste tot de meest complexe reizen. Dankzij een unieke technologie heeft u toegang tot de <strong>beste tarieven en routes voor vervoer met een lage koolstofuitstoot.</strong><br /><br />Treinen, bussen, carpoolen, alleen of gecombineerd, u kunt (eindelijk) opstijgen zonder een vliegtuig te nemen.',
  },
  conceptTitle1: {
    id: `${homepageScope}.conceptTitle1`,
    defaultMessage: 'Le comparateur de transports ',
    en: 'The <span class="highlight">reinvented</span> transport ',
    it: 'Il comparatore di trasporti ',
    es: 'El comparador de transportes ',
    de: 'Der neu <span class="highlight">erfundene</span> ',
    nl: 'De opnieuw <span class="highlight">uitgevonden</span> ',
  },
  conceptTitle2: {
    id: `${homepageScope}.conceptTitle2`,
    defaultMessage: '<span class="highlight">réinventé.</span>',
    en: 'comparator',
    it: '<span class="highlight">reinventato.</span>',
    es: '<span class="highlight">reinventado.</span>',
    de: 'Transportvergleich.',
    nl: 'transportvergelijker.',
  },
  conceptSubTitle1: {
    id: `${homepageScope}.conceptSubTitle1`,
    defaultMessage: 'Vous pouvez enfin y aller par 4 ',
    en: 'You can finally get there by 4 different .',
    it: 'Finalmente puoi arrivarci per 4 strade ',
    es: 'Finalmente puedes llegar allí por 4 caminos ',
    de: 'Sie können endlich auf 4 verschiedenen Wegen dorthin ',
    nl: 'Je kunt er eindelijk op 4 verschillende manieren ',
  },
  conceptSubTitle2: {
    id: `${homepageScope}.conceptSubTitle2`,
    defaultMessage: 'chemins.',
    en: 'paths.',
    it: 'diverse.',
    es: 'diferentes.',
    de: 'gelangen.',
    nl: 'komen.',
  },

  conceptBulletTravel: {
    id: `${homepageScope}.conceptBulletTravel`,
    defaultMessage: 'Engagé à changer notre façon de voyager',
    en: 'Committing to changing the way we travel',
    it: 'Impegnati a cambiare il modo in cui viaggiamo',
    es: 'Comprometidos a cambiar la forma en que viajamos',
    de: 'Verpflichtet, die Art und Weise zu ändern, wie wir reisen',
    nl: 'Toegewijd aan het veranderen van de manier waarop we reizen',
  },
  conceptBulletLowCarbon: {
    id: `${homepageScope}.conceptBulletLowCarbon`,
    defaultMessage: 'Engagés à faire du transport bas-carbone le 1er choix',
    en: 'Committing to making low-carbon transport the first choice',
    es: 'Compromiso de hacer del transporte de baja emisión la primera opción',
    de: 'Verpflichtung, kohlenstoffarmen Transport zur ersten Wahl zu machen',
    it: 'Impegno a fare del trasporto a bassa emissione la prima scelta',
    nl: 'Toewijding om koolstofneutraal transport de eerste keuze te maken',
  },
  conceptBulletIsolatedAreas: {
    id: `${homepageScope}.conceptBulletIsolatedAreas`,
    defaultMessage: 'Engagés à la desserte des territoires isolés',
    en: 'Commit to serving isolated areas',
    es: 'Compromiso con el servicio a áreas aisladas',
    de: 'Verpflichtung zur Bedienung abgelegener Gebiete',
    it: 'Impegnarsi a servire le aree isolate',
    nl: 'Inzetten op de bediening van afgelegen gebieden',
  },
};
