import moment from 'moment';
import qs from 'qs';
import { RETURN } from '../containers/Results/constants';
import { dayjs } from '../libs/day';

/**
 * @description creates the search url
 * @param {Object} originPlace
 * @param {Object} destinationPlace
 * @param {Object} tmpOriginPlace
 * @param {Object} tmpDestinationPlace
 * @param {String} originInputValue
 * @param {String} destinationInputValue
 * @param {Moment} departureDate
 * @param {Moment} returnDate
 * @param {Number} outboundTime
 * @param {Number} returnTime
 * @param {Array} passengers
 * @return {String} url
 */
export const createSearchUrl = (
  originPlace,
  destinationPlace,
  tmpOriginPlace,
  tmpDestinationPlace,
  originInputValue,
  destinationInputValue,
  departureDate,
  returnDate,
  outboundTime,
  returnTime,
  passengers,
) => {
  const safeOriginValue = originPlace.id
    ? originInputValue
    : tmpOriginPlace.name.city;
  const safeDestinationValue = destinationPlace.id
    ? destinationInputValue
    : tmpDestinationPlace.name.city;
  const safeOriginPlace = originPlace.id ? originPlace : tmpOriginPlace;
  const safeDestinationPlace = destinationPlace.id
    ? destinationPlace
    : tmpDestinationPlace;
  const strOutboundDate = toLocalISOString(departureDate);
  const strReturnDate = returnDate ? toLocalISOString(returnDate) : undefined;
  return qs.stringify(
    {
      origin: safeOriginValue,
      originId: safeOriginPlace.id,
      nfs: formatNearFrom(safeOriginPlace.nearFrom),
      nft: formatNearFrom(safeDestinationPlace.nearFrom),
      destination: safeDestinationValue,
      destinationId: safeDestinationPlace.id,
      outboundDate: strOutboundDate,
      outboundTime,
      returnDate: strReturnDate,
      returnTime,
      passengers: JSON.stringify(
        passengers.map((passenger) =>
          JSON.stringify({
            id: passenger.id,
            age: passenger.age,
            category: passenger.category,
            discountCards: passenger.discountCards,
          }),
        ),
      ),
    },
    { addQueryPrefix: true },
  );
};

/**
 * @description Creates a way to build a /results url depending on the provided direction (defaults to oubtound).
 * @param  {...any} args The arguments needed to the createSearchUrl function.
 * @returns {function((OUTBOUND|RETURN))} A function that create the right URL for a given direction.
 */
export const createDirectedSearchUrl =
  (...args) =>
  (direction) => {
    return direction === RETURN
      ? `/results/return${createSearchUrl(...args)}`
      : `/results${createSearchUrl(...args)}`;
  };

/**
 * @description Creates a search object ready for api to record
 * @param {object} searchObject - formatted params contained in the search url
 * @param {string} partnerId - Source of the search (handle partnerId)
 * @returns {{returnDate: string, origin: {isNearFrom: boolean, city: string}, isOneWay: boolean, destination: {isNearFrom: boolean, city: string}, departureDate: string, passengersNumber: number, source: string}}
 */
export const createRecordSearchObject = (searchObject, partnerId) => {
  let returnDate = null;
  if (searchObject.returnDate) {
    returnDate = moment.utc(searchObject.returnDate).format('DD/MM/YYYY');
  }

  return {
    departureDate: moment.utc(searchObject.outboundDate).format('DD/MM/YYYY'),
    returnDate,
    origin: {
      city: searchObject.originId,
      isNearFrom: !!searchObject.nearFromSource,
    },
    destination: {
      city: searchObject.destinationId,
      isNearFrom: !!searchObject.nearFromTarget,
    },
    isOneWay: !searchObject.returnDate,
    passengersNumber: searchObject.passengers.length,
    partnerId,
  };
};

/**
 * @description check if passengers have already input private informations
 * @param {object} passengers: object of commonPassengers listing all the passengers for the user
 * @returns {boolean}
 */
export const checkPassengersModified = (passengers) =>
  passengers.some(
    (passenger) =>
      passenger.title ||
      passenger.firstName ||
      passenger.lastName ||
      passenger.birthDay ||
      passenger.birthMonth ||
      passenger.birthYear ||
      passenger.mail,
  );

/**
 * @description checks if string contains special characters
 * @param {string} query, string to test
 * @returns {boolean}
 */
export const matchSpecialChar = (query) =>
  /[!@#$%^&*(),.?":{}|<>/]/g.test(query);

/**
 * @description reset the time of the date to 00h00min00s000ms and return the ISOString format
 * @param {(Moment | Date)} date
 * @returns {string}
 */
export const toLocalISOString = (date) => {
  const resetDate = moment.utc([
    moment.parseZone(date).year(),
    moment.parseZone(date).month(),
    moment.parseZone(date).date(),
  ]);
  return resetDate.format();
};

export const formatNearFrom = (nearFrom) => {
  if (nearFrom)
    return JSON.stringify({
      name: nearFrom[0].name,
      lat: nearFrom[0].lat,
      long: nearFrom[0].long,
    });
  return '';
};

/**
 * @description used to display the time ex: 12h30
 * @param {number} timestamp utc
 * @param {string} offset ex: "+0200"
 * @returns {string} formatted time
 */
export const formatTime = (timestamp, offset) =>
  moment.unix(timestamp).utcOffset(offset).format('HH[h]mm');

/**
 * @description used to display the day ex: Mardi 22 Octobre
 * @param {number} timestamp utc
 * @param {string} offset ex: "+0200"
 * @returns {string} formatted date
 */
export const formatDate = (timestamp, offset) => {
  return moment.unix(timestamp).utcOffset(offset).format('ddd DD MMMM');
};

/**
 * @description used to display the date ex: 19/10/2019
 * @param {number} timestamp utc
 * @param {string} offset ex: "+0200"
 * @returns {string} formatted date
 */
export const formatDateShort = (timestamp, offset) =>
  moment.unix(timestamp).utcOffset(offset).format('L');

export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 60);
  const minutes = Math.round(duration % 60);
  return `${hours}h${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const formatRoundedDuration = (duration) => {
  let hours = Math.ceil(duration / 60);

  return `${hours}h`;
};

/**
 * @description Formats a price in cents into a string in euros with two decimals
 * @param {number} rawPrice Price in cents
 * @returns {string}
 */
export const formatPriceCents = (rawPrice) =>
  `${(rawPrice / 100).toFixed(2)} €`;

/**
 * @description Returns the sign of the number in string format : + or -
 * @param {number} price
 * @returns {string} Sign of the number
 */
export const getPriceSign = (price) => (price < 0 ? '-' : '+');

/**
 * @description removes the first occurence of the city name in station name if the latter appears twice at the beginning of the station name
 * @param {string} station, station name with possible duplicate
 * @returns {string} formatted station without duplicates
 */
export const formatStation = (station) => {
  const wordArray = station.match(
    /([a-zA-ZÀ-ÖØ-öø-ÿ]+\S?[a-zA-ZÀ-ÖØ-öø-ÿ]+)|\d+/g,
  );

  if (!wordArray) {
    return station;
  }

  if (wordArray[0] === wordArray[1]) {
    wordArray.shift();
  }
  return wordArray.join(' ');
};

/**
 * @description Computes the duration in minutes between two UTC timestamps in seconds
 * @param {number} previousSegmentUTC, timestamps in seconds of the arrival of the first segment
 * @param {number} nextSegmentUTC, timestamps in seconds of the departure of the next segment
 * @returns {number} Duration in minutes
 */
export const getStopDuration = (previousSegmentUTC, nextSegmentUTC) =>
  (nextSegmentUTC - previousSegmentUTC) / 60;

/**
 * @param {number} priceCents, price in cents
 * @returns {number} Round price in euros
 */
export const getRoundPrice = (priceCents) => Math.round(priceCents / 100);

/**
 * @description Formats a price in euros into a string
 * @param {number} rawPrice, price in euros
 * @returns {string}
 */
export const formatRoundPrice = (rawPrice) => `${rawPrice} €`;

export const getTotalPrice = (data) =>
  Object.values(data).reduce(
    (total, trip) => total + (trip ? trip.paidPrice || trip.priceCents : 0),
    0,
  );

export const getDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  /* if (dist > 1) {
    dist = 1;
  } */
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
};

export const oneTripIsBookable = (cart) =>
  Object.values(cart).some((trip) =>
    trip ? trip.segments.some((segment) => segment.isBookable === true) : false,
  );

/**
 * @description returns all bookable segments in the cart
 * @param {Object} cart
 * @return {Array} bookable segments
 */
export const getBookableSegments = (cart) => {
  const segments = [];
  Object.values(cart).forEach((direction) => {
    if (direction) {
      direction.segments.forEach((segment) => {
        if (segment.isBookable) {
          segments.push(segment);
        }
      });
    }
  });
  return segments;
};

/**
 * @description returns the price of all bookable segments and the fees associated to the trips
 * @param {Object} cart
 * @returns {number} total price in cents
 */
export const getPaidPrice = (cart) =>
  Object.values(cart).reduce(
    (total, direction) =>
      direction ? total + getTripFeeCents(direction) : total + 0,
    0,
  ) +
  getBookableSegments(cart).reduce(
    (count, segment) => count + segment.priceCents,
    0,
  );

/**
 * @description returns feeCents of a trip
 * @param {Object} trip
 * @returns {number} trip fees in cents
 */
export const getTripFeeCents = (trip) =>
  trip.priceCents -
  trip.segments.reduce((subTotal, segment) => subTotal + segment.priceCents, 0);

/**
 * @description return the total number of segments of a travel
 * @param {Object} cart
 * @return {Number}
 */
export const getSegmentsNb = (cart) =>
  Object.values(cart).reduce(
    (count, direction) =>
      direction ? count + direction.segments.length : count + 0,
    0,
  );

/**
 *
 * @param sagaResults {{bus: [{}], train: [{}], carpooling: [{}], multimodal: [{}]}}
 * @returns results {{bus: [{}], train: [{}], carpooling: [{}], multimodal: [{}]}}
 */
export const convertSagaResults = (sagaResults) => {
  const results = {};
  results.bus = sagaResults.bus ? sagaResults.bus : [];
  results.train = sagaResults.train ? sagaResults.train : [];
  results.carpool = sagaResults.carpooling ? sagaResults.carpooling : [];
  results.multimodal = sagaResults.multimodal ? sagaResults.multimodal : [];
  return results;
};

export const convertResults = (resultsLegacy) => {
  const results = {};
  resultsLegacy.forEach((mean) => {
    results[mean.type] = convertByMean(mean);
  });
  return results;
};

const convertByMean = (resultsByMean) => {
  const trips = [];
  resultsByMean.tickets.forEach((ticket) => {
    const trip = cleanTrip(ticket);
    trips.push(trip);
  });
  return trips;
};

const cleanTrip = (trip) => {
  const cleanedTrip = {};
  cleanedTrip.segments = cleanSegments(trip.steps);
  cleanedTrip.origin = cleanPlace(trip.originCity, trip.originStation);
  cleanedTrip.destination = cleanPlace(
    trip.destinationCity,
    trip.destinationStation,
  );
  cleanedTrip.companies = trip.companies;
  cleanedTrip.mean = trip.transportType;
  cleanedTrip.id = trip.id;
  cleanedTrip.priceCents = trip.totalPrice;
  cleanedTrip.durationMinutes = trip.totalDuration;
  cleanedTrip.departureUTC = trip.departureTimestamp;
  cleanedTrip.originOffset = trip.originOffset;
  cleanedTrip.arrivalUTC = trip.arrivalTimestamp;
  cleanedTrip.destinationOffset = trip.destinationOffset;
  // cleanedTrip.warning = trip.warning;
  cleanedTrip.available = !trip.warning.notAvailable;

  return cleanedTrip;
};

const cleanSegments = (steps) => {
  const segments = [];
  steps.forEach((step) => {
    const segment = cleanSegment(step);
    segments.push(segment);
  });
  return segments;
};

const cleanSegment = (step) => {
  const cleanedSegment = {};
  cleanedSegment.origin = cleanPlace(
    step.originCity,
    step.originStation,
    step.fromLat,
    step.fromLong,
  );
  cleanedSegment.destination = cleanPlace(
    step.destinationCity,
    step.destinationStation,
    step.toLat,
    step.toLong,
  );
  cleanedSegment.id = step.raw_id;
  cleanedSegment.durationMinutes = step.duration;
  cleanedSegment.priceCents = step.price;
  cleanedSegment.departureUTC = step.departureTimestamp;
  cleanedSegment.originOffset = step.originOffset;
  cleanedSegment.isBookable = step.isBookable;
  cleanedSegment.arrivalUTC = step.arrivalTimestamp;
  cleanedSegment.destinationOffset = step.destinationOffset;
  cleanedSegment.company = step.company;
  // cleanedSegment.companyId = step.companyId;
  // cleanedSegment.companyFeatures = step.companyFeatures;
  cleanedSegment.redirectionLink = step.redirectionLink;
  cleanedSegment.mean = 'train';
  return cleanedSegment;
};

const cleanPlace = (legacyCity, legacyStation, lat, long) => {
  const [city, region, country] = legacyCity.split(', ');
  return { city, region, country, station: legacyStation, lat, long };
};

/**
 * @description Returns weither or not the 2 given timestamps are on the same day.
 * @param {Object} isNextDayParams
 * @param {number} isNextDayParams.departure A UNIX timestamp matching the departure date.
 * @param {number} isNextDayParams.arrival A UNIX timestamp matching the arrival date.
 * @returns True if both dates are from the same day, false otherwise.
 */
export const isNextDay = ({
  arrivalUTC,
  departureUTC,
  destinationOffset,
  originOffset,
}) => {
  return !dayjs
    .unix(departureUTC)
    .utc()
    .utcOffset(originOffset)
    .isSame(dayjs.unix(arrivalUTC).utc().utcOffset(destinationOffset), 'days');
};

/**
 * @description Convert a price in euros to a price in cents
 * @param {number} price The price in euro
 * @returns {number} The price converted to cents
 */
export const getPriceInCents = (price) => price * 100;
