import { defineMessages } from 'react-intl';

export const priceCalendarScope = 'app.components.priceCalendar';

export const priceCalendarMessages = {
  cheapest: {
    id: `${priceCalendarScope}.cheapest`,
    defaultMessage: 'Le moins cher',
    en: 'The cheapest',
    it: 'Il meno caro',
    es: 'El más barato',
    de: 'Der günstigste',
    nl: 'De goedkoopste',
  },
  today: {
    id: `${priceCalendarScope}.today`,
    defaultMessage: "Aujourd'hui",
    en: 'Today',
    it: 'Oggi',
    es: 'Hoy',
    de: 'Heute',
    nl: 'Vandaag',
  },
};

export default defineMessages(priceCalendarMessages);
