import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Spacer = styled.div`
  height: ${(props) => props.height}rem;
  width: ${(props) => props.width}rem;
`;

const SpacerComponent = ({ height, width }) => (
  <Spacer height={height || 0} width={width || 0} />
);

SpacerComponent.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export { Spacer, SpacerComponent };
