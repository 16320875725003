import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

const h6Style = css`
  ${tw`leading-h6-m sm:leading-h6 text-h6-m m-0
  	sm:text-h6`};
  font-weight: 700;
`;

const H6 = styled.h6`
  ${h6Style};
`;

export { H6 as default, h6Style };
