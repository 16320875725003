import { fromJS } from 'immutable';
import moment from 'moment';
import { createRef } from 'react';
import { matchSpecialChar } from '../../helpers/results';
import {
  ARROW_DOWN,
  ARROW_UP,
  CLEAR_CURRENT_SELECTION,
  CLEAR_RETURN_DATE,
  DAY_CLICKED,
  DESTINATION,
  ENTER,
  FIELD_DONE,
  HIDE_API_CARDS_LIST,
  HOUR_CLICKED,
  INITIAL,
  LOAD_AUTOCOMPLETE_ERROR,
  LOAD_AUTOCOMPLETE_LOADING,
  LOAD_AUTOCOMPLETE_SUCCESS,
  LOAD_DISCOUNT_CARDS_ERROR,
  LOAD_DISCOUNT_CARDS_SUCCESS,
  NAVIGATE,
  ORIGIN,
  PASSENGERS,
  SET_CITY,
  SET_CURRENT_FIELD,
  SET_DATE,
  START_DATE,
  SUGGESTION_CLICKED,
  SWITCH_PLACES,
  TAB,
  UPDATE_DATE_FOCUS,
  UPDATE_FOCUS_AFTER_DELETE,
  UPDATE_PASSENGER_FOCUS,
  UPDATE_PLACE_QUERY,
} from './constants';

export const initialState = fromJS({
  currentField: INITIAL,
  remainingFields: [ORIGIN, DESTINATION, START_DATE, PASSENGERS],
  origin: {
    value: '',
    suggestionsList: [],
    navigationMatch: {},
    focusedSuggestionID: 0,
    tmpSelection: {},
    selectedPlace: {},
  },
  destination: {
    value: '',
    suggestionsList: [],
    navigationMatch: {},
    focusedSuggestionID: 0,
    tmpSelection: {},
    selectedPlace: {},
  },
  departureDate: null,
  returnDate: null,
  departureTime: 0,
  returnTime: 0,
  dateFocus: START_DATE,
  discountCardsList: [],
  autocompleteLoading: false,
  showDiscountCards: false,
  focusedPassengerId: -1,
  isIteratingOverSuggestions: false,
  error: false,
  originPlaceInputRef: createRef(),
  destinationPlaceInputRef: createRef(),
  departureDateInputRef: createRef(),
  returnDateInputRef: createRef(),
  passengersInputRef: createRef(),
});

function travelSearchBarReducer(state = initialState, action) {
  const direction = state.get('currentField');
  switch (action.type) {
    case SET_CITY: {
      if (!matchSpecialChar(action.cityName)) {
        return state
          .setIn([action.direction, 'value'], action.cityName)
          .setIn(
            [action.direction, 'selectedPlace', 'id'],
            action.cityId ? parseInt(action.cityId, 10) : null,
          );
      }

      return state;
    }

    case SET_DATE: {
      if (
        moment(action.date).isValid() &&
        !moment(action.date).isBefore(moment())
      ) {
        return state.set(action.direction, moment.parseZone(action.date));
      }

      return state;
    }

    case UPDATE_PLACE_QUERY:
      return state.setIn([direction, 'value'], action.query);

    case SET_CURRENT_FIELD:
      return state
        .set('currentField', action.newFocus)
        .set('isIteratingOverSuggestions', true);

    case NAVIGATE: {
      if (state.get('isIteratingOverSuggestions') === true) {
        return handleSuggestionsNavigation(state, action.event);
      }
      return state;
    }

    case LOAD_AUTOCOMPLETE_SUCCESS:
      return state
        .setIn(
          [action.direction, 'suggestionsList'],
          fromJS(action.suggestions.slice(0, 8)),
        )
        .setIn(
          [action.direction, 'navigationMatch'],
          fromJS(action.navigationMatch),
        )
        .setIn([action.direction, 'focusedSuggestionID'], 0)
        .setIn(
          [action.direction, 'tmpSelection'],
          fromJS(action.suggestions[0]),
        )
        .set('isIteratingOverSuggestions', true)
        .set('error', false);

    case LOAD_AUTOCOMPLETE_ERROR:
      return state.set('error', action.error);

    case LOAD_DISCOUNT_CARDS_SUCCESS:
      return state
        .set('discountCardsList', action.cardsList)
        .set('error', false)
        .set('showDiscountCards', true);

    case LOAD_AUTOCOMPLETE_LOADING: {
      return state.set('autocompleteLoading', action.value);
    }

    case LOAD_DISCOUNT_CARDS_ERROR:
      return state.set('error', action.error);

    case SUGGESTION_CLICKED: {
      const updatedState = updateTmpSelection(state, direction, action.id);
      const stateUpdated = updateCurrentSelection(updatedState, action.id);
      return autoNextFocus(stateUpdated);
    }

    case SWITCH_PLACES: {
      let newFocus;
      switch (state.get('currentField')) {
        case ORIGIN:
          newFocus = DESTINATION;
          break;
        case DESTINATION:
          newFocus = ORIGIN;
          break;
        default:
          newFocus = state.get('currentField');
      }
      const tmp = state.get('origin');
      return state
        .set('origin', state.get('destination'))
        .set('destination', tmp)
        .set('currentField', newFocus);
    }

    case DAY_CLICKED: {
      return state
        .set('departureDate', action.departureDate)
        .set('returnDate', action.returnDate)
        .setIn(['remainingFields', 2], FIELD_DONE);
      /* if (state.get('currentField') === START_DATE)
        return goToReturnDate(stateUpdated);
      if (!action.returnDate) return stateUpdated;
      return autoNextFocus(stateUpdated); */
    }

    case HOUR_CLICKED: {
      if (state.get('dateFocus') === START_DATE) {
        return state.set('departureTime', action.hour);
      }
      return state.set('returnTime', action.hour);
    }

    case UPDATE_DATE_FOCUS: {
      return state.set(
        'dateFocus',
        !action.dateFocus ? START_DATE : action.dateFocus,
      );
    }

    case CLEAR_RETURN_DATE: {
      const stateUpdated = state.set('returnDate', null).set('returnTime', 0);
      return autoNextFocus(stateUpdated);
    }

    case UPDATE_PASSENGER_FOCUS:
      return state.set(
        'focusedPassengerId',
        action.id === state.get('focusedPassengerId') ? -1 : action.id,
      );

    case UPDATE_FOCUS_AFTER_DELETE:
      return state.get('focusedPassengerId') === action.passengersLength - 1
        ? state.set('focusedPassengerId', action.id - 1)
        : state;

    case HIDE_API_CARDS_LIST: {
      return state.set('showDiscountCards', false);
    }

    case CLEAR_CURRENT_SELECTION: {
      const direction = state.get('currentField');

      // Set the current field as not filled for the auto Next Input feature
      const currentFieldIndex = initialState
        .get('remainingFields')
        .findIndex((field) => field === direction);

      const remainingFields = state
        .get('remainingFields')
        .set(currentFieldIndex, direction);

      return state
        .setIn([direction, 'value'], '')
        .set('remainingFields', remainingFields)
        .setIn([direction, 'selectedPlace'], fromJS({}))
        .setIn([direction, 'tmpSelection'], fromJS({}));
    }

    default:
      return state;
  }
}

const handleSuggestionsNavigation = (state, event) => {
  // fixme : add auto update focus
  const direction = state.get('currentField');
  switch (event.key) {
    case ARROW_DOWN: {
      const suggestionsLength = getSuggestionsLength(
        fromJS(state.getIn([direction, 'suggestionsList'])),
      );
      const newID = incrementFocusedSuggestionID(
        state,
        direction,
        suggestionsLength,
      );
      return updateTmpSelection(state, direction, newID);
    }
    case ARROW_UP: {
      const suggestionsLength = getSuggestionsLength(
        fromJS(state.getIn([direction, 'suggestionsList'])),
      );
      const newID = decrementFocusedSuggestionsID(
        state,
        direction,
        suggestionsLength,
      );
      return updateTmpSelection(state, direction, newID);
    }
    case ENTER: {
      event.preventDefault();
      const stateUpdated = updateCurrentSelection(
        state,
        state.getIn([direction, 'focusedSuggestionID']),
      );
      return autoNextFocus(stateUpdated);
    }
    case TAB: {
      event.preventDefault();
      const stateUpdated = updateCurrentSelection(
        state,
        state.getIn([direction, 'focusedSuggestionID']),
      );
      return autoNextFocus(stateUpdated);
    }

    default: {
      return state;
    }
  }
};

const getSuggestionsLength = (suggestionsList) => {
  let totalLength = suggestionsList.size;
  suggestionsList.map((place) => {
    if (place.get('stations')) {
      totalLength += place.get('stations').size;
    }
    return totalLength;
  });
  return totalLength;
};

const incrementFocusedSuggestionID = (state, direction, suggestionsLength) =>
  (state.getIn([direction, 'focusedSuggestionID']) + 1) % suggestionsLength;

const decrementFocusedSuggestionsID = (state, direction, suggestionsLength) =>
  (state.getIn([direction, 'focusedSuggestionID']) + (suggestionsLength - 1)) %
  suggestionsLength;

const updateTmpSelection = (state, direction, focusedSuggestionID) => {
  const navigationMatchId = state
    .getIn([direction, 'navigationMatch'])
    .toList()
    .get(focusedSuggestionID);
  return state
    .setIn([direction, 'focusedSuggestionID'], focusedSuggestionID)
    .setIn(
      [direction, 'tmpSelection'],
      state.getIn([direction, 'suggestionsList', navigationMatchId]),
    );
};

/**
 * @description automatically switches to next input on desktop.
 * On mobile it closes the modal to show the list of inputs.
 * @param {Immutable.Map} state
 * @return {Immutable.Map}
 */
export const autoNextFocus = (state) => {
  if (window.innerWidth > 768) {
    const currentFocus = state.get('currentField');
    const nextRemaining = state
      .get('remainingFields')
      .map((focus) => (focus === currentFocus ? FIELD_DONE : focus));
    const nextFocus = nextRemaining
      .filter((focus) => focus !== FIELD_DONE)
      .get(0);
    return state
      .set('remainingFields', nextRemaining)
      .set('currentField', nextFocus);
  }
  return state.set('currentField', INITIAL);
};

const updateCurrentSelection = (state, id) => {
  const direction = state.get('currentField');
  if (state.getIn([direction, 'suggestionsList']).size > 0) {
    const hasStation =
      id - state.getIn([direction, 'tmpSelection', 'navigationId']);
    const stationId = hasStation > 0 ? hasStation - 1 : 0;
    const stationName = [
      direction,
      'tmpSelection',
      'stations',
      stationId,
      'name',
    ];
    const inputValue =
      hasStation > 0
        ? state.getIn(stationName)
        : state.getIn([direction, 'tmpSelection', 'name', 'city']);
    return state
      .setIn([direction, 'value'], inputValue)
      .setIn(
        [direction, 'selectedPlace'],
        state.getIn([direction, 'tmpSelection']),
      );
  }
  return state.setIn([direction, 'value'], '');
};

export default travelSearchBarReducer;
