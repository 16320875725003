import PropTypes from 'prop-types';
import { cartPropTypes } from '../../common/Cart/constants';
import { passengerPropTypes } from '../../common/Passengers/constants';
import { API_URL } from '../../helpers/constants';

export const ordersScope = 'app.containers.Orders';

export const ORDERS = 'orders';
export const URL_CUSTOMER = `${API_URL}/customers`;
export const URL_PROVIDER_ID = 'providerId';

export const ORDERS_INFOS = 'stationInfos';
export const LOADING = 'isLoading';
export const CURRENT_NAVIGATION = 'currentNavigation';
export const CURRENT_ORDERS = 'currentOrders';
export const PAST_ORDERS = 'pastOrders';
export const CUSTOMER = 'customer';
export const OPEN_ORDER_DETAIL = 'openOrderDetail';

export const FETCH_ORDERS = 'app/Orders/FETCH_ORDERS';
export const FETCH_CUSTOMER = 'app/Orders/FETCH_CUSTOMER';
export const UPDATE_CUSTOMER_ORDERS = 'app/Orders/UPDATE_CUSTOMER_ORDERS';
export const UPDATE_CUSTOMER_INFO = 'app/Orders/UPDATE_CUSTOMER_INFO';
export const SET_LOADING_STATUS = 'app/Orders/SET_LOADING_STATUS';
export const SET_CURRENT_NAVIGATION = 'app/Orders/SET_CURRENT_NAVIGATION';

export const PAGE_TITLE = 'PageTitle';
export const CONTACT_SUPPORT = 'contactSupport';
export const CUSTOMER_EMAIL = 'customerEmail';
export const CONTACT_SUPPORT_LINK = 'contactSupportLink';
export const RAILWAY = 'Train';
export const HIGHWAY = 'Bus';
export const ADDRESS = 'Address';
export const DESTINATIONS_EXAMPLE = 'Destinations';
export const FIRST_LAST_DEPARTURE = 'FirstLastDeparture';
export const ORDER_ID = 'orderId';
export const OPEN_ORDER_DETAILS = 'openOrderDetails';
export const DOWNLOAD_TICKETS = 'downloadTickets';
export const TOTAL_PRICE = 'totalPrice';
export const REVIEW = 'review';
export const ORDER_ID_COPIED = 'orderIdCopied';
export const EDIT_ORDER = 'editOrder';
export const CANCEL_ORDER = 'cancelOrder';
export const ORDER_DETAILS = 'orderDetails';
export const PASSENGERS = 'passengers';
export const PASSENGER = 'passenger';
export const NO_DISCOUNT_CARD = 'noDiscountCard';

export const RIGHT_PLACE = 'RightPlace';
export const PROVIDERS = 'Providers';
export const NEXT_DEPARTURES_AND_ARRIVALS = 'NextDeparturesAndArrivals';

export const SHOW_MORE_RESULTS = 'ShowMoreResults';

export const DEPARTURES_TAB = 'DeparturesTab';

const orderCustomerPropTypes = PropTypes.shape({
  birthDay: PropTypes.number,
  birthMonth: PropTypes.number,
  birthYear: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  mail: PropTypes.string,
  title: PropTypes.string,
});

const customerContextPropTypes = PropTypes.shape({
  birthdate: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  mail: PropTypes.string,
  phoneNumber: PropTypes.string,
  title: PropTypes.string,
});

const orderPropTypes = PropTypes.shape({
  cart: cartPropTypes,
  cartId: PropTypes.string,
  customer: orderCustomerPropTypes,
  id: PropTypes.string,
  orderDate: PropTypes.string,
  orderStatus: PropTypes.string,
  paidPrice: PropTypes.number,
  passengers: PropTypes.arrayOf(passengerPropTypes),
  paymentStatus: PropTypes.string,
  rawPrice: PropTypes.number,
  totalPrice: PropTypes.number,
});

export { customerContextPropTypes, orderPropTypes };
