/*
 * Mobile app header Messages
 *
 * This contains all the text for the Mobile app header component.
 */
import { defineMessages } from 'react-intl';

export const maintenanceScope = 'app.components.maintenance';

export const MaintenanceMessages = {
  underMaintenance: {
    id: `${maintenanceScope}.underMaintenance`,
    defaultMessage: 'Maintenance en cours',
    en: 'Maintenance in progress',
    it: 'Manutenzione in corso',
    es: 'Mantenimiento en curso',
    de: 'Wartungsarbeiten im Gange',
    nl: 'Onderhoud bezig',
  },
  apologyMessage: {
    id: `${maintenanceScope}.apologyMessage`,
    defaultMessage:
      'Notre service est indisponible pour le moment, nous travaillons à grande vitesse pour retourner sur de bons rails. En attendant, il va falloir patienter un peu.',
    en: 'Our service is currently unavailable, we are working at full speed to get back on track. In the meantime, please be patient.',
    it: 'Il nostro servizio al momento non è disponibile, stiamo lavorando a piena velocità per tornare sulla giusta rotta. Nel frattempo, si prega di avere pazienza.',
    es: 'Nuestro servicio no está disponible en este momento, estamos trabajando a toda velocidad para volver al buen camino. Mientras tanto, por favor tenga paciencia.',
    de: 'Unser Service ist derzeit nicht verfügbar, wir arbeiten mit Hochdruck daran, wieder auf Kurs zu kommen. In der Zwischenzeit bitten wir um Geduld.',
    nl: 'Onze dienst is momenteel niet beschikbaar, we werken hard om weer op het goede spoor te komen. In de tussentijd vragen we om geduld.',
  },
};

export default defineMessages(MaintenanceMessages);
