import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyConfirmation = lazy(
  () => import(/* webpackChunkName: 'Confirmation' */ './index'),
);

export default function Confirmation(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyConfirmation {...props} />
    </Suspense>
  );
}
