import * as PropTypes from 'prop-types';

export const DATA = 'reducer/data';
export const CURRENT_SLIDE = 'reducer/currentSlide';
export const LOCKED = 'reducer/locked';

export const SET_DATA = 'app/LargeTravelSlider/SET_DATA';
export const NEXT_SLIDE = 'app/LargeTravelSlider/NEXT_SLIDE';
export const PREVIOUS_SLIDE = 'app/LargeTravelSlider/PREVIOUS_SLIDE';
export const UNLOCK = 'app/LargeTravelSlider/UNLOCK';
export const SET_CURRENT_SLIDE = 'app/LargeTravelSlider/SET_CURRENT_SLIDE';
export const UNLOCK_TIMER = 600;

export const largeTravelSliderPropTypes = PropTypes.shape({
  id: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
});
