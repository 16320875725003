import { homepageScope } from '../constants';

export const homePageReviewMessages = {
  reviewTitle: {
    id: `${homepageScope}.reviewTitle`,
    defaultMessage:
      'On s’occupe de vous <span class="highlight">comme si on partait ensemble.</span>',
    en: 'We take care of you <span class="highlight">as if we were traveling together.</span>',
    es: 'Cuidamos de ti <span class="highlight">como si viajáramos juntos.</span>',
    it: 'Ci prendiamo cura di te <span class="highlight">come se viaggiassimo insieme.</span>',
    de: 'Wir kümmern uns um dich, <span class="highlight">als ob wir gemeinsam unterwegs wären.</span>',
    nl: 'Wij zorgen voor je <span class="highlight">alsof we samen op reis zijn.</span>',
  },

  reviewReactivityComment: {
    id: `${homepageScope}.reviewReactivityComment`,
    defaultMessage:
      'Je cherchais un service capable de proposer des trajets de plusieurs compagnies en train et en bus et j’ai trouvé ! Expérience fluide, service client au top. Merci et bravo ! <br></br>-Alexandre, 21 mars 2024',
    en: 'I was looking for a service that could offer journeys from multiple train and bus companies and I found it! Smooth experience, top-notch customer service. Thank you and well done! <br></br>-Alexandre, March 21, 2024',
    it: "Stavo cercando un servizio che potesse offrire viaggi da diverse compagnie ferroviarie e autobus e l'ho trovato! Esperienza fluida, servizio clienti di alto livello. Grazie e bravo! <br></br>-Alexandre, 21 marzo 2024",
    es: 'Estaba buscando un servicio que pudiera ofrecer viajes de múltiples compañías de trenes y autobuses ¡y lo encontré! Experiencia fluida, servicio al cliente de primera. ¡Gracias y bien hecho! <br></br>-Alexandre, 21 de marzo de 2024',
    de: 'Ich war auf der Suche nach einem Service, der Fahrten von mehreren Zug- und Busunternehmen anbieten konnte und habe es gefunden! Reibungslose Erfahrung, erstklassiger Kundenservice. Vielen Dank und gut gemacht! <br></br>-Alexandre, 21. März 2024',
    nl: 'Ik was op zoek naar een dienst die reizen kon aanbieden van meerdere trein- en busbedrijven en ik vond het! Soepele ervaring, eersteklas klantenservice. Bedankt en goed gedaan! <br></br>-Alexandre, 21 maart 2024',
  },
  reviewRatings: {
    id: `${homepageScope}.reviewRatings`,
    defaultMessage:
      '<strong>4.4</strong> sur <a href="https://fr.trustpilot.com/review/tictactrip.eu"> <u>767 avis</u> </a>',
    en: '<strong>4.4</strong> out of<a href="https://fr.trustpilot.com/review/tictactrip.eu"> <u>767 reviews</u> </a>',
    it: '<strong>4,4</strong> su <a href="https://fr.trustpilot.com/review/tictactrip.eu"> <u>767 recensioni</u> </a>',
    es: '<strong>4,4</strong> sobre <a href="https://fr.trustpilot.com/review/tictactrip.eu"> <u>767 opiniones</u> </a>',
    de: '<strong>4,4</strong> von <a href="https://fr.trustpilot.com/review/tictactrip.eu"> <u>767 Bewertungen</u> </a>',
    nl: '<strong>4,4</strong> uit <a href="https://fr.trustpilot.com/review/tictactrip.eu"> <u>767 beoordelingen</u> </a>',
  },

  reviewTestimonial1: {
    id: `${homepageScope}.reviewTestimonial1`,
    defaultMessage:
      'Je ne pensais pas trouver un prix si bas pour mon Paris La Rochelle en bus. Le comparateur Tictactrip a ce gros avantage de proposer des escales pour changer de compagnie et donc baisser les prix !',
    en: "I didn't expect to find such a low price for my Paris La Rochelle bus trip. Tictactrip's comparator has the great advantage of offering layovers to change companies and thus lower the prices!",
    it: 'Non mi aspettavo di trovare un prezzo così basso per il mio viaggio in autobus da Parigi a La Rochelle. Il comparatore di Tictactrip ha il grande vantaggio di offrire scali per cambiare compagnie e quindi abbassare i prezzi!',
    es: 'No esperaba encontrar un precio tan bajo para mi viaje en autobús de París a La Rochelle. El comparador de Tictactrip tiene la gran ventaja de ofrecer escalas para cambiar de compañía y así bajar los precios.',
    de: 'Ich hätte nicht erwartet, dass ich für meine Busreise von Paris nach La Rochelle einen so niedrigen Preis finde. Der Vergleich von Tictactrip hat den großen Vorteil, Zwischenstopps anzubieten, um die Unternehmen zu wechseln und damit die Preise zu senken!',
    nl: "Ik had niet verwacht dat ik zo'n lage prijs zou vinden voor mijn busreis van Parijs naar La Rochelle. De vergelijker van Tictactrip heeft het grote voordeel dat er tussenstops worden aangeboden om van bedrijf te veranderen en zo de prijzen te verlagen!",
  },
  reviewTestimonial2: {
    id: `${homepageScope}.reviewTestimonial2`,
    defaultMessage:
      'Hyper pratique de pouvoir combiner les moyens de transports et les transporteurs. Les prix sont plus bas et surtout j’ai pu tout réserver sur la même application, c’est top !',
    en: 'It is so practical to be able to combine different means of transport and transport companies. Prices are lower and I was able to book everything on the same app, it is awesome!',
    it: 'È così pratico poter combinare diversi mezzi di trasporto e compagnie di trasporto. I prezzi sono più bassi e ho potuto prenotare tutto sulla stessa app, è fantastico!',
    es: 'Es muy práctico poder combinar diferentes medios de transporte y compañías de transporte. Los precios son más bajos y pude reservar todo en la misma aplicación, ¡es genial!',
    de: 'Es ist so praktisch, verschiedene Verkehrsmittel und Verkehrsunternehmen kombinieren zu können. Die Preise sind niedriger und ich konnte alles auf derselben App buchen, es ist großartig!',
    nl: 'Het is zo praktisch om verschillende vervoersmiddelen en vervoersbedrijven te kunnen combineren. De prijzen zijn lager en ik kon alles op dezelfde app boeken, het is geweldig!',
  },
  reviewTestimonial3: {
    id: `${homepageScope}.reviewTestimonial3`,
    defaultMessage:
      "Grâce à Tictactrip j'ai pu aller à la montage en train et navette. L'occasion de laisser la voiture à la maison et promouvoir les transports bas-carbone. Simple, rapide et efficace.",
    en: 'Thanks to Tictactrip, I was able to go to the mountains by train and shuttle. A chance to leave the car at home and promote low-carbon transport. Simple, fast, and efficient.',
    fr: "Grâce à Tictactrip j'ai pu aller à la montagne en train et navette. L'occasion de laisser la voiture à la maison et de promouvoir les transports bas-carbone. Simple, rapide et efficace.",
    es: 'Gracias a Tictactrip, pude ir a las montañas en tren y transporte compartido. Una oportunidad para dejar el coche en casa y promover el transporte de bajas emisiones. ¡Simple, rápido y eficiente!',
    de: 'Dank Tictactrip konnte ich mit dem Zug und Shuttle in die Berge fahren. Eine Gelegenheit, das Auto zu Hause zu lassen und den klimafreundlichen Verkehr zu fördern. Einfach, schnell und effizient.',
    it: "Grazie a Tictactrip sono riuscito ad andare in montagna in treno e navetta. Un'occasione per lasciare l'auto a casa e promuovere i trasporti a basse emissioni. Semplice, veloce ed efficiente.",
    nl: 'Dankzij Tictactrip kon ik met de trein en shuttle naar de bergen. Een kans om de auto thuis te laten en milieuvriendelijk transport te bevorderen. Eenvoudig, snel en efficiënt.',
  },
};
