import { createGlobalStyle } from 'styled-components/macro';
import { P1Styled } from './components/P';
import { colors } from './styles/constants';

const GlobalStyle = createGlobalStyle`
:root {
  --primary500: ${colors.primary500};
  --primary400: ${colors.primary400};
  --primaryLight100: ${colors.primaryLight100};

  --secondary500: ${colors.secondary500};
  --secondary400: ${colors.secondary400};
  --secondary300: ${colors.secondary300};

  --terciary500: ${colors.terciary500};
  --terciary400: ${colors.terciary400};

  --grey200: ${colors.grey200};
  --grey100: ${colors.grey100};
  --grey50: ${colors.grey50};

  --text: ${colors.grey50};
  --textLight: ${colors.grey50};
  --textUltraLight: ${colors.grey50};
  --placeHolders: ${colors.grey50};
  --error: ${colors.grey50};
  --externalLink: ${colors.grey50};

  --size-tiny: 4px;
  --size-small: 8px;
  --size-small2: 12px;
  --size-medium: 16px;
  --size-medium2: 24px;
  --size-big: 32px;
  --size-huge: 48px;
  --size-huge2: 80px;
}

  html {
    font-size: 62.5%;
    box-sizing: border-box;
    scroll-behavior: smooth;
    line-height: 1.15;
  }
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'HankenGrotesk', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 2.2rem;
    color: ${colors.primary400};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    display: flex;
    flex-direction: column;
    background-color: ${colors.grey100};
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label,
  a,
  td,
  th, 
  li {
    margin: unset;
    ${P1Styled}
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent; /* 1 */
    border-style: none; /* 1 */
    color: inherit; /* 1 */
    font-size: 1.6rem;
    margin: 0; /* 3 */
  }

input[type="date"]::-webkit-calendar-picker-indicator {
  background-size: 18px;
  padding-right:15px
  }

  button {
    outline: none;
    cursor: pointer;
  }
`;

export default GlobalStyle;
