import { typesOfRequestOptions } from '@tictactrip/zendesk-service/dist/constants';
import { API_URL } from '../../helpers/constants';

export const TYPE_OF_REQUEST_FIELD = [
  {
    messageId: 'editTicketField',
    typeOfRequestOptionId: typesOfRequestOptions.editTrip,
  },
  {
    messageId: 'ticketCancelledByCompanyField',
    typeOfRequestOptionId: typesOfRequestOptions.deletedTrip,
  },
  {
    messageId: 'notReceiveMyTicketsField',
    typeOfRequestOptionId: typesOfRequestOptions.troubleWithTicket,
  },
  {
    messageId: 'notRefundedYetField',
    typeOfRequestOptionId: typesOfRequestOptions.refund,
  },
  {
    messageId: 'otherQuestionField',
    typeOfRequestOptionId: typesOfRequestOptions.other,
  },
];

export const URL_CREATE_SUPPORT_TICKET = `${API_URL}/support/ticket`;
