import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    width: ${(props) => (props.desktop ? `${props.desktop}px` : '100%')};
    height: ${(props) => (props.desktop ? `${props.desktop}px` : '100%')};
  }
  width: ${(props) => (props.mobile ? `${props.mobile}px` : '100%')};
  height: ${(props) => (props.mobile ? `${props.mobile}px` : '100%')};
  ${tw`m-half align-middle inline-flex`}
`;

export default Wrapper;
