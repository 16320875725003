import * as moment from 'moment';
import qs from 'qs';
import { NAVIGATION } from '../containers/App/constants';
import { dayjs } from '../libs/day';
import { PROVIDERS_LOGOS } from './constants';
import { getTotalPrice } from './results';

/**
 * @description checks if the userAgent is identified as a bot or crawler
 * @param {string} userAgent , navigator user-agent
 * @returns {boolean}
 */
const isCrawler = (userAgent) => {
  const crawlerRegex =
    /googlebot|adsbot-google|headless|prerender|Feedfetcher-Google|bingbot|yandex|baiduspider|Facebot|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|slackbot|vkShare|W3C_Validator|bufferbot|redditbot|Applebot|WhatsApp|flipboard|tumblr|bitlybot|Discordbot|qwantify|www.google.com\/webmasters\/tools\/richsnippets|cocon/i;
  return crawlerRegex.test(userAgent);
};

/**
 * @description Returns the current root page. Ex: "/" for homepage, "/company" for "/company/bus/train"
 * @returns {string}
 */
const getCurrentRootPage = (pathname) => pathname.split(/(?=\/)/)[0];

/**
 * @description Computes FAQ json-ld schema
 * @param {{question: string, answer: string}[]} faqs
 * @returns {string}
 */
const jsonLdFaq = (faqs) =>
  JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  });

/**
 * @description Computes product json-ld schema
 * @param {{reviewCount: number, ratings: {min: number, max: number, mean: number}}} product - Product ratings
 * @param {string} name - Name of the product
 * @param {string} description - Description of the product
 * @param {number} price - Price of the product
 * @returns {string}
 */
const jsonLdProduct = (product, name, description, price) =>
  JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name,
    description,
    brand: {
      '@type': 'Organization',
      name: 'Tictactrip',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      reviewCount: product.reviewCount,
      ratingValue: product.ratings.mean,
      worstRating: product.ratings.min,
      bestRating: product.ratings.max,
    },
    offers: {
      '@type': 'Offer',
      url: `${window.location.origin}${window.location.pathname}`,
      priceCurrency: 'EUR',
      price,
      availability: 'http://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: 'Tictactrip',
      },
    },
  });

/**
 * @description Capitalizes the first letter of a string.
 * @param {string} str The string to capitalize.
 * @returns {string} The capitalized string.
 */
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * @description Checks whether an order is already passed (Arrival time in the past)
 * @param order Order
 * @returns {boolean}
 */
const isOrderPassed = (order) => {
  if (order.cart.returnTrip) {
    return dayjs().isAfter(
      dayjs
        .unix(order.cart.returnTrip.arrivalUTC)
        .utcOffset(order.cart.returnTrip.destinationOffset),
    );
  }
  return dayjs().isAfter(
    dayjs
      .unix(order.cart.outboundTrip.arrivalUTC)
      .utcOffset(order.cart.outboundTrip.destinationOffset),
  );
};

const isSegmentPassed = (segment) => {
  return dayjs().isAfter(
    dayjs.unix(segment.arrivalUTC).utcOffset(segment.destinationOffset),
  );
};

/**
 * @description Build pre-filled Airtable Support form link
 * @param {Trips} trips
 * @param {string} orderId
 * @param {string} customerMail
 * @returns {string} support link
 */
const getSupportLink = (trips, orderId, customerMail, partnerId) => {
  const outboundDate = moment
    .unix(trips.outboundTrip.departureUTC)
    .utcOffset(trips.outboundTrip.originOffset)
    .format('YYYY-MM-DD');
  let returnDate = null;
  if (trips.returnTrip) {
    returnDate = moment
      .unix(trips.returnTrip.departureUTC)
      .utcOffset(trips.returnTrip.originOffset)
      .format('YYYY-MM-DD');
  }

  let partner = partnerId;
  if (!partnerId) partner = 'TTT';
  else if (partnerId.includes('WB')) partner = 'WB';

  return (
    NAVIGATION.SUPPORT +
    qs.stringify(
      {
        prefill_orderId: orderId,
        prefill_mail: customerMail,
        prefill_paidPrice: getTotalPrice(trips) / 100,
        prefill_outboundDate: outboundDate,
        prefill_returnDate: returnDate,
        prefill_originCluster: trips.outboundTrip.origin.city,
        prefill_destinationCluster: trips.outboundTrip.destination.city,
        prefill_Partner: partner,
        hide_Partner: true,
      },
      { addQueryPrefix: true },
    )
  );
};

/**
 * @description Returns the matching logo for a provider, null otherwise. This is the main way of pulling a provider's logo, and currently makes use of a provider-to-logo mapping.
 * @param {string} provider The provider's name. Matches the `fullname` column for the `company_provider`s, which is the data provided from the API.
 * @returns The provider's logo as an image (.svg or .png), null if none is found.
 */
const getProviderLogo = (provider) => {
  return PROVIDERS_LOGOS[provider.toLowerCase()] || null;
};

/**
 * @description Checks whether or not the string's beginning contains the word. If so, returns a copy of the string with the word removed, the provided string otherwise.
 * @param {object} truncateStrParams
 * @param {string} truncateStrParams.str The string to check the word against.
 * @param {string} truncateStrParams.word The word to check.
 * @returns {string} A copy of the string with the word removed, or the string itself.
 */
const truncateStrBeg = ({ str, word }) => {
  const caseInsensitiveWord = new RegExp(`^${word}\\s?`, 'gi');

  const isInStr = caseInsensitiveWord.test(str);

  if (!isInStr) {
    return str;
  }

  return str.replace(caseInsensitiveWord, '');
};

export {
  capitalize,
  getCurrentRootPage,
  getProviderLogo,
  getSupportLink,
  isCrawler,
  isOrderPassed,
  isSegmentPassed,
  jsonLdFaq,
  jsonLdProduct,
  truncateStrBeg,
};

export * from './alternativeSegments';
