import { FormattedMessage } from 'react-intl';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import clientReviewsGreen from '../../../images/ClientReviewsGreen.svg';
import messages from '../messages';

const Container = styled.span<TrustBoxProps>`
  ${tw`flex flex-col text-p-small leading-p-small font-bold`}
  ${({ customStyle }) => customStyle}
`;

const Image = styled.img`
  width: 107px;
`;

type TrustBoxProps = {
  customStyle?: FlattenSimpleInterpolation;
};

const TrustBox = (props: TrustBoxProps) => {
  return (
    <Container {...props}>
      <FormattedMessage {...messages.trusboxReviews} />
      <Image alt="4 stars icon" src={clientReviewsGreen} />
    </Container>
  );
};

export default TrustBox;
