import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../types';

const SVG = styled.svg<IconProps>`
  ${({ customStyle }) => customStyle}
`;

export const InstagramIcon: React.FC<IconProps> = ({ color, customStyle }) => (
  <SVG
    customStyle={customStyle}
    height="22px"
    version="1.1"
    viewBox="0 0 22 22"
    width="22px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="D1440/01-01-Homepage"
      stroke="none"
      strokeWidth="1"
      transform="translate(-664.000000, -6410.000000)"
    >
      <g
        fill={color}
        fillRule="nonzero"
        id="Footer"
        transform="translate(0.000000, 5632.000000)"
      >
        <g transform="translate(470.000000, 764.000000)">
          <g transform="translate(180.000000, 0.000000)">
            <g transform="translate(14.000000, 14.000000)">
              <path d="M15.125,0 L6.875,0 C3.078625,0 0,3.078625 0,6.875 L0,15.125 C0,18.921375 3.078625,22 6.875,22 L15.125,22 C18.921375,22 22,18.921375 22,15.125 L22,6.875 C22,3.078625 18.921375,0 15.125,0 Z M19.9375,15.125 C19.9375,17.77875 17.77875,19.9375 15.125,19.9375 L6.875,19.9375 C4.22125,19.9375 2.0625,17.77875 2.0625,15.125 L2.0625,6.875 C2.0625,4.22125 4.22125,2.0625 6.875,2.0625 L15.125,2.0625 C17.77875,2.0625 19.9375,4.22125 19.9375,6.875 L19.9375,15.125 Z"></path>
              <path d="M10.5,5 C7.462625,5 5,7.462625 5,10.5 C5,13.537375 7.462625,16 10.5,16 C13.537375,16 16,13.537375 16,10.5 C16,7.462625 13.537375,5 10.5,5 Z M10.5,13.9375 C8.60525,13.9375 7.0625,12.39475 7.0625,10.5 C7.0625,8.603875 8.60525,7.0625 10.5,7.0625 C12.39475,7.0625 13.9375,8.603875 13.9375,10.5 C13.9375,12.39475 12.39475,13.9375 10.5,13.9375 Z"></path>
              <circle cx="17" cy="5" r="1"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);
