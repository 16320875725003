import styled from 'styled-components';
import tw from 'tailwind.macro';

const H0 = styled.h1`
  ${tw`font-kyrial my-0 mx-0
  text-title-xxxxlarge-m leading-title-xxxxlarge-m 
        md:text-title-xxxxlarge md:leading-title-xxxxlarge`}
`;

const H1 = styled.h1`
  ${tw`font-kyrial my-0 mx-0
  text-title-xxxlarge-m leading-title-xxxlarge-m 
        md:text-title-xxxlarge md:leading-title-xxxlarge`}
`;

const H2 = styled.h2`
  ${tw`font-kyrial my-0 mx-0 
  text-title-xxlarge-m leading-title-xxlarge-m 
        md:text-title-xxlarge md:leading-title-xxlarge`}
`;

const H3 = styled.h3`
  ${tw`font-kyrial  my-0 mx-0 
  text-title-xlarge-m leading-title-xlarge-m 
        md:text-title-xlarge md:leading-title-xlarge`}
`;

const H4 = styled.h4`
  ${tw`font-kyrial  my-0 mx-0 
  text-title-large-m leading-title-large-m 
        md:text-title-large md:leading-title-large`}
`;

const H5 = styled.h5`
  ${tw`font-kyrial  my-0 mx-0 
  text-title-medium-m leading-title-medium-m 
        md:text-title-medium md:leading-title-medium`}
`;

const H6 = styled.h6`
  ${tw`font-kyrial  my-0 mx-0 
  text-title-small-m leading-title-small-m 
        md:text-title-small md:leading-title-small`}
`;

export { H0, H1, H2, H3, H4, H5, H6 };
