import React, { createContext, useContext } from 'react';
import { BASIC_REQUEST_HEADERS, HTTP } from '../../helpers/constants';
import request from '../../utils/request';
import { URL_ORDER } from './constants';
import {
  ICreateOrderResponse,
  IGetOrderResponse,
  IOrderContext,
} from './types';

export const OrderContext = createContext<IOrderContext>({
  createOrder: async () => null,
  getOrder: async () => null,
});

interface OrderProviderProps {
  children: React.ReactNode;
}

export const OrderProvider: React.FC<OrderProviderProps> = ({ children }) => {
  const createOrder = async (
    cartId: string,
    signal?: AbortSignal,
  ): Promise<ICreateOrderResponse> => {
    return await request(URL_ORDER, {
      method: HTTP.METHODS.POST,
      headers: BASIC_REQUEST_HEADERS,
      body: JSON.stringify({ cartId }),
      signal: signal,
    });
  };

  const getOrder = async (orderId: string): Promise<IGetOrderResponse> => {
    return request(`${URL_ORDER}/${orderId}`, {
      method: HTTP.METHODS.GET,
      headers: BASIC_REQUEST_HEADERS,
    });
  };
  const value: IOrderContext = { createOrder, getOrder };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

export const useOrder = () => {
  const context = useContext(OrderContext);

  if (!context) {
  }

  return context;
};
