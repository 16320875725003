import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

const h2style = css`
  ${tw`leading-h2-m sm:leading-h2 text-h2-m text-center
  	px-def
  	mt-abs4 mb-abs3
  	sm:text-h2 sm:mt-abs12 sm:mb-abs8`};
  font-weight: 600;
`;

const H2 = styled.h2`
  ${h2style};
`;

export { H2 as default, h2style };
