import { EPassengerCategory } from '../../common/types';
import { API_URL } from '../../helpers/constants';
import { getDefaultAgeByCategory } from '../../hooks/usePassengersManagement/utils';
import { dayjs } from '../../libs/day';
import { ISearchParameters } from './types';

export const URL_AUTOCOMPLETE = `${API_URL}/cities/autocomplete/`;
export const URL_POPULAR_CITIES = `${API_URL}/cities/popular`;
export const URL_POPULAR_CITIES_FROM = `${API_URL}/cities/popular/from/`;
export const URL_POPULAR_CITIES_TO = `${API_URL}/cities/popular/to/`;
export const URL_DISCOUNT_CARDS = `${API_URL}/reductionCards`;

export const DEFAULT_LIMIT = 7;

export const DEFAULT_SEARCH_PARAMETERS: ISearchParameters = {
  origin: '',
  originId: null,
  destination: '',
  destinationId: null,
  outboundDate: dayjs().format('YYYY-MM-DD'),
  outboundTime: null,
  returnDate: null,
  returnTime: null,
  passengers: [
    {
      id: 0,
      category: EPassengerCategory.ADULT,
      age: getDefaultAgeByCategory(EPassengerCategory.ADULT),
      discountCards: [],
    },
  ],
};
