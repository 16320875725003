import { BASIC_REQUEST_HEADERS } from '../../helpers/constants';
import request from '../../utils/request';
import { URL_CREATE_SUPPORT_TICKET } from './constants';

/**
 * @description Convert a file into a base64 encoded string
 * @param {File} file The file to convert
 * @returns {string}
 */
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * @description Submit the support form
 * @param {Event} event The event object that contains the form data
 * @param {File[]} files The files to submit
 * @param {string} phoneNumber The customer's phone number
 * @param {string} subject The subject of the request
 * @returns {void}
 */
export const handleFormSubmit = async (event, files, phoneNumber, subject) => {
  const base64Files = [];
  for (const file of files) {
    base64Files.push({ base64: await toBase64(file), name: file.name });
  }

  const query = new URLSearchParams(window.location.search);

  try {
    await request(URL_CREATE_SUPPORT_TICKET, {
      method: 'POST',
      headers: {
        ...BASIC_REQUEST_HEADERS,
      },
      body: JSON.stringify({
        email: event.target.email.value,
        phoneNumber,
        orderId: event.target.orderId.value,
        departureDate: event.target.departureDate.value,
        moreInformations:
          event.target.moreInformations.value === ''
            ? undefined
            : event.target.moreInformations.value,
        arrivalCity: query.get('prefill_destinationCluster') ?? undefined,
        departureCity: query.get('prefill_originCluster') ?? undefined,
        subject: subject,
        files: base64Files,
      }),
    });

    return true;
  } catch (error) {
    return false;
  }
};
