import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import tw from 'tailwind.macro';
import Loader from '../../Loader';
import { IComponentSizes } from '../constants';
type TButtonVariant = 'PRIMARY' | 'SECONDARY' | 'TERCIARY' | 'LINK';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant?: TButtonVariant;
  css?: FlattenSimpleInterpolation;
  size?: IComponentSizes;
  isLoading?: boolean;
  loadingMessage?: React.ReactNode;

  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
}

const BUTTON_SIZE_STYLE: Record<IComponentSizes, FlattenSimpleInterpolation> = {
  BIG: css`
    gap: var(--size-small);
    ${tw`px-btn-big-x py-btn-big-y text-p-medium-m leading-p-small`}
  `,
  MEDIUM: css`
    gap: 6px;
    ${tw`px-btn-medium-x py-btn-medium-y text-p-small-m leading-p-xsmall-m`}
  `,
  SMALL: css`
    gap: var(--size-tiny);
    ${tw`px-btn-small-x py-btn-small-y text-p-xsmall-m leading-p-xsmall-m`}
  `,
};

export const BUTTON_VARIANT_STYLE: Record<
  TButtonVariant,
  FlattenSimpleInterpolation
> = {
  PRIMARY: css`
    ${tw`bg-primary-400 text-white 
            hover:bg-primary-500
        `}
  `,
  SECONDARY: css`
    border-width: 1px;
    ${tw`bg-white border-solid border-secondary-400 text-secondary-400
        hover:bg-secondary-400 hover:text-white
      `}
  `,
  TERCIARY: css`
    ${tw`text-primary-400 hover:text-primary-500`}
  `,
  LINK: css`
    ${tw`text-text underline hover:text-primary-400 focus:text-primary-500`}
  `,
};

const BUTTON_VARIANT_LOADING_STYLE: Record<
  TButtonVariant,
  FlattenSimpleInterpolation
> = {
  PRIMARY: css`
    ${tw`bg-primary-300 hover:bg-primary-300 cursor-not-allowed`}
  `,
  SECONDARY: css`cursor-not-allowed`,
  TERCIARY: css`cursor-not-allowed`,
  LINK: css``,
};

const BUTTON_VARIANT_DISABLED_STYLE: Record<
  TButtonVariant,
  FlattenSimpleInterpolation
> = {
  PRIMARY: css`
    ${tw`bg-grey-200 text-grey-300 hover:bg-grey-200`}
  `,
  SECONDARY: css`
    hover: bg-white;
  `,
  TERCIARY: css``,
  LINK: css`
    ${tw`text-grey-300`}
  `,
};

const StyledButton = styled.button<ButtonProps>`
  ${tw`rounded-30 font-bold text-white flex justify-center items-center`}

  ${({ size }) => (size ? BUTTON_SIZE_STYLE[size] : BUTTON_SIZE_STYLE.BIG)}

  ${({ variant }) => variant && BUTTON_VARIANT_STYLE[variant]}
  ${({ isLoading, variant }) =>
    isLoading && variant && BUTTON_VARIANT_LOADING_STYLE[variant]}
  ${({ disabled, variant }) =>
    disabled && variant && BUTTON_VARIANT_DISABLED_STYLE[variant]}


    ${({ css }) => css}
`;

/**
 * @description A button component that accepts various props for customization.
 * @param {Object} props - The props object.
 * @param {React.ReactNode} [props.children] - The children to render inside the button.
 * @param {TButtonVariant} [props.variant] - The variant of the button.
 * @param {FlattenSimpleInterpolation} [props.css] - Custom CSS styling for the button.
 * @param {IComponentSizes} [props.size='BIG'] - The size of the button.
 * @param {boolean} [props.isLoading=false] - Whether the button is in a loading state.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled.
 * @returns {React.FC<ButtonProps>}
 */
const Button: React.FC<ButtonProps> = ({
  buttonRef,
  children,
  isLoading,
  leftIcon,
  loadingMessage,
  onClick,
  rightIcon,
  size = 'BIG',
  ...props
}) => (
  <StyledButton
    size={size}
    {...props}
    ref={buttonRef}
    isLoading={isLoading}
    onClick={(event) => !isLoading && onClick?.(event)}
  >
    {isLoading ? (
      <React.Fragment>
        <Loader desktop={10} mobile={10} />
        {loadingMessage}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {leftIcon}
        {children}
        {rightIcon}
      </React.Fragment>
    )}
  </StyledButton>
);

export default Button;
