import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'sanitize.css/sanitize.css';
import './styles/react_dates_overrides.css';
import './styles/slickCarousel.css';
import './styles/slickCarouselTheme.css';
import { ConnectedRouter } from 'connected-react-router/immutable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StyleSheetManager } from 'styled-components/macro';
import AppBanner from './components/AppPromotionBanner/index';
import configureStore from './configureStore';
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';
import { AdminProvider } from './contexts/Admin';
import { AppProvider } from './contexts/App';
import { AuthProvider } from './contexts/Auth';
import { CartProvider } from './contexts/Cart';
import { OrderProvider } from './contexts/Order';
import { SearchProvider } from './contexts/Search';
import { TicketCancellationProvider } from './contexts/TicketCancellation';
import { isCrawler } from './helpers';
import { translationMessages } from './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import history from './utils/history';
import ScrollToTop from './utils/ScrollToTop';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppProvider>
          <LanguageProvider messages={messages}>
            <ScrollToTop>
              <StyleSheetManager
                disableCSSOMInjection={isCrawler(navigator.userAgent)}
              >
                <AuthProvider>
                  <SearchProvider>
                    <CartProvider>
                      <OrderProvider>
                        <AdminProvider>
                          <TicketCancellationProvider>
                            <AppBanner />
                            <App />
                          </TicketCancellationProvider>
                        </AdminProvider>
                      </OrderProvider>
                    </CartProvider>
                  </SearchProvider>
                </AuthProvider>
              </StyleSheetManager>
            </ScrollToTop>
          </LanguageProvider>
        </AppProvider>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/fr.js'),
        import('intl/locale-data/jsonp/en.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
