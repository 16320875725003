import { defineMessages } from 'react-intl';

export const errorMessageScope = 'app.components.errorMessage';

export const errorMessagemessages = {
  errorTitle: {
    id: `${errorMessageScope}.errorTitle`,
    defaultMessage: "Oups, quelque chose s'est mal passé",
    en: 'Oops, something went wrong',
    it: 'Ops, qualcosa è andato storto',
    es: 'Ups, algo salió mal',
    de: 'Hoppla, da ist etwas schiefgelaufen',
    nl: 'Oeps, er is iets misgegaan',
  },
  errorMessage: {
    id: `${errorMessageScope}.errorMessage`,
    defaultMessage:
      "Nous sommes désolés pour le désagrément, mais il semble qu'il y ait eu un problème inattendu.",
    en: 'We apologize for the inconvenience, but it seems like there was an unexpected issue.',
    it: "Ci scusiamo per l'inconveniente, ma sembra che ci sia stato un problema imprevisto.",
    es: 'Pedimos disculpas por las molestias, pero parece que hubo un problema inesperado.',
    de: 'Wir entschuldigen uns für die Unannehmlichkeiten, aber es scheint, dass ein unerwartetes Problem aufgetreten ist.',
    nl: 'Onze excuses voor het ongemak, maar het lijkt erop dat er een onverwacht probleem is opgetreden.',
  },
  errorMessage2: {
    id: `${errorMessageScope}.errorMessage2`,
    defaultMessage:
      "Si vous avez besoin d'une assistance immédiate, veuillez {contactUs}.",
    en: 'If you require immediate assistance, please {contactUs}.',
    it: 'Se hai bisogno di assistenza immediata, per favore {contactUs}.',
    es: 'Si necesita asistencia inmediata, por favor {contactUs}.',
    de: 'Wenn Sie sofortige Hilfe benötigen, bitte {contactUs}.',
    nl: 'Als u onmiddellijke hulp nodig heeft, neem dan {contactUs}.',
  },
  contactUs: {
    id: `${errorMessageScope}.contactUs`,
    defaultMessage: 'nous contacter',
    en: 'contact us',
    it: 'contattarci',
    es: 'contactarnos',
    de: 'kontaktieren Sie uns',
    nl: 'contact met ons opnemen',
  },
  refreshButton: {
    id: `${errorMessageScope}.refreshButton`,
    defaultMessage: 'Actualiser la page',
    en: 'Refresh Page',
    it: 'Aggiorna la pagina',
    es: 'Actualizar página',
    de: 'Seite aktualisieren',
    nl: 'Vernieuw pagina',
  },
  newVersionTitle: {
    id: `${errorMessageScope}.newVersionTitle`,
    defaultMessage: 'Nouvelle version disponible',
    en: 'New version available',
    it: 'Nuova versione disponibile',
    es: 'Nueva versión disponible',
    de: 'Neue Version verfügbar',
    nl: 'Nieuwe versie beschikbaar',
  },
  newVersionMessage: {
    id: `${errorMessageScope}.newVersionMessage`,
    defaultMessage: "Une version plus récente de l'application est disponible.",
    en: 'There is a newer version of the app available.',
    it: "C'è una nuova versione dell'app disponibile.",
    es: 'Hay una versión más reciente de la aplicación disponible.',
    de: 'Es ist eine neuere Version der App verfügbar.',
    nl: 'Er is een nieuwere versie van de app beschikbaar.',
  },
  updateInstructions: {
    id: `${errorMessageScope}.updateInstructions`,
    defaultMessage:
      "Veuillez rafraîchir l'application pour accéder aux nouvelles fonctionnalités.",
    en: 'Please update to see new features.',
    it: 'Si prega di aggiornare per vedere le nuove funzionalità.',
    es: 'Por favor, actualice para ver las nuevas características.',
    de: 'Bitte aktualisieren Sie, um neue Funktionen zu sehen.',
    nl: 'Update om nieuwe functies te zien.',
  },
};

export default defineMessages(errorMessagemessages);
