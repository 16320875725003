/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage component.
 */
import { defineMessages } from 'react-intl';

export const notFoundPageScope = 'app.containers.NotFoundPage';

export const notFoundPageMessages = {
  header: {
    id: `${notFoundPageScope}.header`,
    defaultMessage: 'Oups !',
    en: 'Oops!',
    es: '¡Ups!',
    it: 'Ops!',
    de: 'Hoppla!',
    nl: 'Oeps!',
  },
  subHeader: {
    id: `${notFoundPageScope}.subHeader`,
    defaultMessage: "🚧 Erreur d'aiguillage sur la ligne 🚧",
    eng: '🚧 Something went wrong 🚧',
    es: '🚧 Algo salió mal 🚧',
    it: '🚧 Qualcosa è andato storto 🚧',
    de: '🚧 Etwas ist schiefgelaufen 🚧',
    nl: '🚧 Er is iets misgegaan 🚧',
  },
};

export default defineMessages(notFoundPageMessages);
