import { API_URL } from '../../helpers/constants';
import request from '../../utils/request';

/**
 * @description Returns true if the API is in maintenance. false otherwise.
 * @returns {boolean}
 */
export const checkMaintenance = async () => {
  const { maintenance } = await request(`${API_URL}/apiStatus/maintenance`);

  return maintenance;
};
