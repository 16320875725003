import styled, { keyframes } from 'styled-components/macro';
import tw from 'tailwind.macro';
import { colors } from '../../styles/constants';

const bounceDelay = keyframes`
  0%, 95%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

const Bounce = styled.div`
  ${tw`bg-terciary500 h-full w-full rounded-full`}
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
  background-color: ${(props) => (props.white ? 'white' : colors.terciary500)};
`;

export default Bounce;
