/**
 * This file contains the messages used by the SubmitButton component.
 */

import { defineMessages } from 'react-intl';
import { PLEASE_WAIT } from './constants';

export const submitButtonScope = 'app.components.SubmitButton';

export const submitButtonMessages = {
  [PLEASE_WAIT]: {
    id: [submitButtonScope, PLEASE_WAIT].join('.'),
    defaultMessage: 'Veuillez patienter',
    en: 'Please wait',
    es: 'Por favor espere',
    it: 'Si prega di attendere',
    de: 'Bitte warten',
    nl: 'Even geduld aub',
  },
};

export default defineMessages(submitButtonMessages);
